<template>

    <v-row class="mb-3">
        <p class="title mt-2">Sehr geehrte*r Teilnehmer*in,</p>
        <p class="title font-weight-regular" style="text-align:justify;">
            Da es sich bei dem Online-Programm um eine wissenschaftliche Studie handelt,
            möchte ich Sie bitten, folgende Fragebögen zu beantworten. Nur auf diese Weise ist es möglich
            festzustellen, wie wir das Online-Programm in Zukunft verbessern können.
        </p>
        <p class="title" style="text-align:justify;">
            Bitte lesen Sie sich die folgenden Fragen genau durch und antworten Sie möglichst spontan.
        </p>
        <p class="caption" style="text-align:justify;">
            <b>Hinweis:</b> Bei @myTabu wird der Begriff <i>Missbrauchsabbildungen</i> verwendet anstatt <i>Kinderpornographie</i>. 
            Einige der folgenden Fragebögen enthalten dennoch den Begriff <i>Kinderpornographie</i>. Der Grund dafür ist, dass diese 
            Fragebögen so entwickelt worden sind und die Begriffe in den Fragebögen nicht geändert werden sollten. Aus dem gleichen Grund werden auch in 
            einigen Fragebögen unterschiedliche Geschlechter anders angesprochen, als Sie es aus dem Online-Programm gewohnt sind.<br><br>
            Beachten Sie, dass Ihre Antworten erst gespeichert werden, wenn Sie alle Fragen vollständig beantwortet haben. 
            Wenn Sie die Befragung vorzeitig verlassen, müssen Sie Ihre Antworten erneut eingeben.
        </p>
    </v-row>
 
</template>

<script>
export default {
    name: 'assessment-start',

    data: () => ({
        //
    }),

    props: [],

    created() {
        //
    },

    computed: {
        //
    },

    methods: {
        //
    }
}
</script>

<style scoped>

</style>