<template>

    <div v-if="loaded">

        <!-- OPEN QUESTIONNAIRES -->

        <v-card
            color="grey lighten-5"
            flat
            height="auto"
            width="100%"
        >

            <v-expansion-panels flat v-model="panel_open">

                <v-expansion-panel>

                    <v-expansion-panel-header color="grey lighten-5">

                        <v-card flat class="py-0 my-0" color="grey lighten-5">

                            <v-card-text class="pl-0 title primary--text py-0 my-0">
                                Unbeantwortete Fragebögen
                            </v-card-text>

                        </v-card>

                        <template v-slot:actions>
                            <v-icon color="primary">$expand</v-icon>
                        </template>
                    </v-expansion-panel-header>

                    <v-divider />

                    <v-expansion-panel-content color="grey lighten-5">

                        <v-card-text class="mx-0 px-0 py-0 my-0">

                            <v-card flat outlined color="grey lighten-5" class="mx-0 px-0">

                                <v-card-text class="mx-0 px-0">

                                    <v-autocomplete
                                        v-model="search_open_questionnaires"
                                        :items="all_clients"
                                        label="Offene Fragebögen nach Klient*in filtern"
                                        prepend-icon="fas fa-search"
                                        chips
                                        deletable-chips
                                        hide-details
                                        small-chips
                                    ></v-autocomplete>

                                </v-card-text>

                            </v-card>

                        </v-card-text>

                        <v-divider class="my-3"/>

                        <v-card-text v-if="filteredOpenPolls.length > 0">
                    
                            <v-alert
                                v-if="show_unfinished_panel_null_alert()"
                                border="left"
                                elevation="1"
                                colored-border
                                prominent
                                type="success"
                            >
                                Sie haben alle Fragebögen ausgefüllt.
                            </v-alert>

                            <div
                                v-if="!show_unfinished_panel_null_alert()"
                            >

                                <div
                                    v-for="task in filteredOpenPolls"
                                    :key="task.id"
                                >

                                    <v-alert
                                        border="left"
                                        elevation="1"
                                        colored-border
                                        prominent
                                        type="error"
                                    >
                                        <v-row align="center">

                                            <v-col cols="auto" class="mr-auto">

                                                <div>
                                                    <small>Fragebogen zu <span v-if="get_client_gender(task.client.user.username) == 1">Klient</span><span v-else>Klientin</span> <b class="primary--text">{{ task.client.user.username}}</b></small>
                                                </div>
                                                
                                                <div class="black--text mt-3">
                                                    <div  v-if="task.assessment_name == 'repeated_supervisor_sample'">
                                                        <b>{{ getReadableTaskName(task.assessment_name) }}</b><small> (Modul {{ task.module_nr}} - Sitzung {{ task.session_nr }} - Lektion {{ task.lession_nr}})</small>
                                                    </div>
                                                    <div v-else>
                                                        <b>{{ getReadableTaskName(task.assessment_name) }}</b><small> (Basis-Datensatz)</small>
                                                    </div>
                                                </div>

                                                <div class="mt-3">
                                                    <small>Der Fragebogen steht seit dem {{ get_converted_time(task.create_time) }} bereit.</small>
                                                </div>

                                            </v-col>

                                            <v-col cols="auto">
                                                <v-btn
                                                    outlined
                                                    color="error"
                                                    @click="goToPollDetails(task)"
                                                >
                                                    Zum Fragebogen
                                                </v-btn>
                                            </v-col>

                                        </v-row>

                                    </v-alert>

                                </div>

                            </div>

                        </v-card-text>

                        <v-card-text v-if="filteredOpenPolls.length == 0">
                            
                            <v-alert
                                border="left"
                                elevation="1"
                                colored-border
                                prominent
                                type="success"
                            >
                                Es liegen keine offenen Fragebögen für Klient*in {{ search_open_questionnaires }} vor.
                            </v-alert>

                        </v-card-text>

                    </v-expansion-panel-content>

                </v-expansion-panel>

            </v-expansion-panels>

        </v-card>

        <v-divider class="my-5"/>

        <!-- ABGESCHLOSSENE FRAGEBÖGEN -->
        <v-card
            color="grey lighten-5"
            flat
            height="auto"
        >

            <v-expansion-panels flat v-model="panel_open">

                <v-expansion-panel>

                    <v-expansion-panel-header color="grey lighten-5">

                        <v-card flat class="py-0 my-0" color="grey lighten-5">

                            <v-card-text class="pl-0 title primary--text py-0 my-0">
                                Beantwortete Fragebögen
                            </v-card-text>

                        </v-card>

                        <template v-slot:actions>
                            <v-icon color="primary">$expand</v-icon>
                        </template>
                    </v-expansion-panel-header>

                    <v-divider />

                    <v-expansion-panel-content color="grey lighten-5">

                        <v-card-text class="mx-0 px-0 py-0 my-0">

                            <v-card flat outlined color="grey lighten-5" class="mx-0 px-0">

                                <v-card-text class="mx-0 px-0">

                                    <v-autocomplete
                                        v-model="search_closed_questionnaires"
                                        :items="all_clients"
                                        label="Ausgefüllte Fragebögen nach Klient*in filtern"
                                        prepend-icon="fas fa-search"
                                        chips
                                        deletable-chips
                                        hide-details
                                        small-chips
                                    ></v-autocomplete>

                                </v-card-text>

                            </v-card>

                        </v-card-text>

                        <v-divider class="my-3"/>

                        <v-card-text v-if="filteredClosedPolls.length > 0">
                
                            <v-alert
                                v-if="show_finished_panel_null_alert()"
                                border="left"
                                elevation="1"
                                colored-border
                                prominent
                                type="success"
                            >
                                Es liegen keine beantworteten Fragebögen vor.
                            </v-alert>

                            <div
                                v-if="!show_finished_panel_null_alert()"
                            >

                                <div
                                    v-for="task in filteredClosedPolls"
                                    :key="task.id"
                                >

                                    <v-alert
                                        border="left"
                                        elevation="1"
                                        colored-border
                                        prominent
                                        type="success"
                                    >
                                        <v-row align="center">

                                            <v-col cols="auto" class="mr-auto">

                                                <div>
                                                    <small>Fragebogen zu <span v-if="get_client_gender(task.client.user.username) == 1">Klient</span><span v-else>Klientin</span> <b class="primary--text">{{ task.client.user.username}}</b></small>
                                                </div>
                                                
                                                <div class="black--text mt-3">
                                                    <div  v-if="task.assessment_name == 'repeated_supervisor_sample'">
                                                        <b>{{ getReadableTaskName(task.assessment_name) }}</b><small> (Modul {{ task.module_nr}} - Sitzung {{ task.session_nr }} - Lektion {{ task.lession_nr}})</small>
                                                    </div>
                                                    <div v-else>
                                                        <b>{{ getReadableTaskName(task.assessment_name) }}</b><small> (Basis-Datensatz)</small>
                                                    </div>
                                                </div>

                                                <div class="mt-3">
                                                    <small>Sie haben den Fragebogen am {{ get_converted_time(task.update_time) }} ausgefüllt.</small>
                                                </div>

                                            </v-col>

                                        </v-row>

                                    </v-alert>

                                </div>

                            </div>

                        </v-card-text>

                        <v-card-text v-if="filteredClosedPolls.length == 0">
                            
                            <v-alert
                                border="left"
                                elevation="1"
                                colored-border
                                prominent
                                type="success"
                            >
                                Es liegen keine beantworteten Fragebögen für Klient*in {{ search_closed_questionnaires }} vor.
                            </v-alert>

                        </v-card-text>

                    </v-expansion-panel-content>

                </v-expansion-panel>

            </v-expansion-panels>

        </v-card>

    </div>

</template>

<script>
import SupervisorApi from '@/_api/supervisor_api'
import DateTimeHelpers from '@/_helpers/date_time_helpers'

export default {
    
    name: 'supervisor_assessment',

    data: () => ({

        loaded: false,

        panel_open: 0,

        unfinished_polls: [],
        finished_polls: [],

        search_open_questionnaires: null,
        search_closed_questionnaires: null,

        all_clients: []


    }),

    created() {

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        SupervisorApi.GetSupervisorInfo().then(() => {

            SupervisorApi.GetUnfinishedPolls().then((resp) => {

                this.unfinished_polls = resp.data

                SupervisorApi.GetFinishedPolls().then((resp) => {

                    this.finished_polls = resp.data

                    if (this.$route.params.search_prop == 'null') {
                        this.search_open_questionnaires = null
                        this.search_closed_questionnaires = null
                    } else {
                        this.search_open_questionnaires = this.$route.params.search_prop
                        this.search_closed_questionnaires = this.$route.params.search_prop
                    }

                    for (var i = 0; i < this.$store.getters['store_supervisor/get_supervisor_clients'].length; i++) {

                        this.all_clients.push(this.$store.getters['store_supervisor/get_supervisor_clients'][i].username)
                    }

                    this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                    this.loaded = true

                })

            })

        })

    },

    computed: {

        filteredOpenPolls() {

            var filtered = []

            if (this.search_open_questionnaires != null) {

                for (var i=0; i < this.unfinished_polls.length; i++) {
                    

                    if (this.unfinished_polls[i].client.user.username === this.search_open_questionnaires) {

                        filtered.push(this.unfinished_polls[i]);

                    }

                }

            } else {

                filtered = this.unfinished_polls

            }

            return filtered
             
        },

        filteredClosedPolls() {

            var filtered = []

            if (this.search_closed_questionnaires != null) {

                for (var i=0; i < this.finished_polls.length; i++) {

                    if (this.finished_polls[i].client.user.username === this.search_closed_questionnaires) {

                        filtered.push(this.finished_polls[i]);

                    }

                }

            } else {

                filtered = this.finished_polls

            }

            return filtered
             
        },

    },

    methods: {

        get_client_gender(username) {

            var client = this.$store.getters['store_supervisor/get_supervisor_clients'].find(e => e.username === username);

            return client.client_gender

        },

        getReadableTaskName(name) {

            var readable = ''

            if (name == 'supervisor_sample') {
                readable = 'Beschreibung des*der Klienten*Klientin'
            } else if (name == 'repeated_supervisor_sample') {
                readable = 'Verlaufsmessung'
            }

            return readable

        },

        openPanelOpen() {

            if (this.panel_open) {
                this.panel_open = false
            } else {
                this.panel_open = true
            }
        },

        openPanelFinished() {

            if (this.panel_finished) {
                this.panel_finished = false
            } else {
                this.panel_finished = true
            }
        },

        show_unfinished_panel_null_alert() {

            var tmp = false;

            if (this.unfinished_polls.length == 0) {

                tmp = true

            } else {

                tmp = false

            }

            return tmp

        },

        show_finished_panel_null_alert() {

            var tmp = false;

            if (this.finished_polls.length == 0) {
                tmp = true
            } else {
                tmp = false
            }

            return tmp
        
        },

        get_converted_time(item) {

            return DateTimeHelpers.convertDatabaseDateTimeString('full', item)
        },

        goToPollDetails(poll) {

            this.$router.push({ name: 'supervisor-assessment-single', params: { module: poll.module_nr, session: poll.session_nr, lection: poll.lession_nr, timepoint: poll.timepoint, step: 0, name: poll.assessment_name, client_user_id: poll.client.user.id, client_id: poll.client.id } })

        },

    },
    
}
</script>

<style scoped>

</style>