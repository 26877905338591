<template>

    <div class="mx-3">

        <v-row>

            <v-col cols="12" sm="12"> 

              <v-card
                  class="mx-auto"
                  outlined
              >

                  <v-card-text>

                    <v-row>

                          <v-row
                              v-for="(item, i) in data.keywordList"
                              :key="i"
                              align="center" 
                              justify="center"
                          >

                          <v-card color="accent" class="my-3 mx-3">

                                <v-card-text class="body-1">
                                {{ item }}
                                <v-btn icon disabled>
                                    <v-icon color="black">far fa-times-circle</v-icon>
                                </v-btn>
                                </v-card-text>
                                                    
                            </v-card>

                          </v-row>

                    </v-row>

                  </v-card-text>

              </v-card>

          </v-col>

      </v-row>

    </div>

</template>

<script>
export default {
    name: 'collect-keywords-form-data',
    props: ['data'],

}
</script>

<style scoped>
.custom-chip {

    display: inline-flex;
    flex-direction: row;
    background-color: #004b8c;

    width: auto;

    height: auto;

    outline: none;

    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-left: 12px;

    margin: 12px;

    line-height: 20px;

    white-space: pre-wrap;

    align-items: center;

    border-radius: 20px;
    font-size: 14px;

    vertical-align: middle;
    text-decoration: none;
    justify-content: center;

}

.drop-main {
    display: flex;
    justify-content: left;
    flex-direction: row;
    flex-wrap: wrap;

    min-width: 100%;
    min-height: 45px;

    padding-top: 12px;
    padding-bottom: 12px;

    background: #EEEEEE;

    border-style: solid;
    border-width: 1px;
    border-radius: 5px !important;
    border-color: rgb(148, 148, 148) !important;
}
</style>
