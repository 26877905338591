<template>
    <div v-if="loaded">

        <v-card 
            color="grey lighten-5"
            flat
            min-height="250px"
            tile
            width="100%"
        >


            <v-card-text class="pb-0">

                <v-btn @click="go_to_acute">ACUTE</v-btn>

                <v-row justify="center" align="center" class="px-3">

                    <div class="body-2">
                        <b>Bitte beachten Sie:</b> Es handelt sich hierbei um eine wissenschaftliche Studie. Die hier dargestellten Daten zum Rückfallrisiko können 
                        <b>keinesfalls alleinige Grundlage</b> für die Einschätzung des aktuellen Rückfallrisikos des*der Klienten*Klientin sein. <i>Sie müssen immer in einem persönlichen
                        Termin diese Angaben überprüfen!</i>
                    </div>

                </v-row>

                <v-row justify="center" align="center" class="px-3">

                    <v-col cols="12" sm="12" md="4">

                    <v-overflow-btn
                        class="my-2"
                        :items="dropdown_client"
                        label="Welche*r Klient*in"
                        dense
                        v-model="dropdown_client_value"
                        @change="update_client_all"
                    ></v-overflow-btn>

                    </v-col>

                </v-row>

            </v-card-text>

            <v-divider />

            <v-tabs
                centered
                grow
                slider-color="primary"
                background-color="grey lighten-5"
                show-arrows
            >
                <v-tab>
                    Acute-2007-SR
                </v-tab>

                <v-tab>
                    CMC
                </v-tab>

                <v-tab>
                    CTE
                </v-tab>

                <v-tab>
                    Blitzlicht
                </v-tab>

                <!-- ACUTE -->
                <v-tab-item>
                    
                    <v-divider />

                    <v-card flat color="grey lighten-5">

                        <v-row justify="center" align="center" class="px-3">

                            <v-col cols="12" sm="4">

                                <v-overflow-btn
                                    v-if="dropdown_timepoint_acute.length >= 2"
                                    background-color="primary"
                                    dark
                                    class="ma-3"
                                    :items="dropdown_timepoint_acute"
                                    label="Messzeitpunkt 1"
                                    hide-no-data
                                    dense
                                    v-model="dropdown_timepoint_acute_one_value"
                                    @change="update_acute"
                                ></v-overflow-btn>

                            </v-col>

                            <v-col cols="12" sm="4">

                                <v-overflow-btn
                                    v-if="dropdown_timepoint_acute.length >= 1"
                                    background-color="accent"
                                    class="ma-3"
                                    :items="dropdown_timepoint_acute"
                                    label="Messzeitpunkt 2"
                                    hide-no-data
                                    dense
                                    v-model="dropdown_timepoint_acute_two_value"
                                    @change="update_acute"
                                ></v-overflow-btn>

                            </v-col>

                        </v-row>

                        <highcharts class="highcharts_acute" :options="chartOptions_acute_bar" :updateArgs="updateArgs" v-if="dropdown_timepoint_acute.length >= 1"></highcharts>

                        <v-alert
                            v-if="dropdown_timepoint_acute.length < 1"
                            border="left"
                            elevation="1"
                            colored-border
                            prominent
                            type="error"
                            class="mx-3"
                        >
                            Es liegen noch keine Daten vor.
                        </v-alert>

                    </v-card>

                </v-tab-item>

                <!-- CMC -->
                <v-tab-item>
                    <v-divider />

                    <v-card flat color="grey lighten-5">

                        <v-row justify="center" align="center" class="px-3">

                            <v-col cols="12" sm="4">

                                <v-overflow-btn
                                    v-if="dropdown_timepoint_cmc.length >= 2"
                                    background-color="primary"
                                    dark
                                    class="ma-3"
                                    :items="dropdown_timepoint_cmc"
                                    label="Messzeitpunkt 1"
                                    hide-no-data
                                    dense
                                    v-model="dropdown_timepoint_cmc_one_value"
                                    @change="update_cmc"
                                ></v-overflow-btn>

                            </v-col>

                            <v-col cols="12" sm="4">

                                <v-overflow-btn
                                    v-if="dropdown_timepoint_cmc.length >= 1"
                                    background-color="accent"
                                    class="ma-3"
                                    :items="dropdown_timepoint_cmc"
                                    label="Messzeitpunkt 2"
                                    hide-no-data
                                    dense
                                    v-model="dropdown_timepoint_cmc_two_value"
                                    @change="update_cmc"
                                ></v-overflow-btn>

                            </v-col>

                        </v-row>

                        <highcharts class="highcharts_cmc" :options="chartOptions_cmc_bar" :updateArgs="updateArgs" v-if="dropdown_timepoint_cmc.length >= 1"></highcharts>

                        <v-alert
                            v-if="dropdown_timepoint_cmc.length < 1"
                            border="left"
                            elevation="1"
                            colored-border
                            prominent
                            type="error"
                            class="mx-3"
                        >
                            Es liegen noch keine Daten vor.
                        </v-alert>

                    </v-card>
                </v-tab-item>

                <!-- CTE -->
                <v-tab-item>

                    <v-divider />

                    <v-card flat color="grey lighten-5">

                        <v-row justify="center" align="center" class="px-3">

                            <v-col cols="12" sm="4">

                                <v-overflow-btn
                                    v-if="dropdown_timepoint_cte.length >= 2"
                                    background-color="primary"
                                    dark
                                    class="ma-3"
                                    :items="dropdown_timepoint_cte"
                                    label="Messzeitpunkt 1"
                                    hide-no-data
                                    dense
                                    v-model="dropdown_timepoint_cte_one_value"
                                    @change="update_cte"
                                ></v-overflow-btn>

                            </v-col>

                            <v-col cols="12" sm="4">

                                <v-overflow-btn
                                    v-if="dropdown_timepoint_cte.length >= 1"
                                    background-color="accent"
                                    class="ma-3"
                                    :items="dropdown_timepoint_cte"
                                    label="Messzeitpunkt 2"
                                    hide-no-data
                                    dense
                                    v-model="dropdown_timepoint_cte_two_value"
                                    @change="update_cte"
                                ></v-overflow-btn>

                            </v-col>

                        </v-row>

                        <highcharts class="highcharts_cmc" :options="chartOptions_cte_bar" :updateArgs="updateArgs" v-if="dropdown_timepoint_cte.length >= 1"></highcharts>

                        <v-alert
                            v-if="dropdown_timepoint_cte.length < 1"
                            border="left"
                            elevation="1"
                            colored-border
                            prominent
                            type="error"
                            class="mx-3"
                        >
                            Es liegen noch keine Daten vor.
                        </v-alert>

                    </v-card>

                </v-tab-item>

                <!-- FLASHLIGHT -->
                <v-tab-item>

                    <v-divider />

                    <v-card flat color="grey lighten-5">

                        <v-row justify="center" align="center" class="px-3">

                            <v-col cols="12" sm="4">

                                <v-overflow-btn
                                    v-if="dropdown_timepoint_flashlight.length >= 2"
                                    background-color="primary"
                                    dark
                                    class="ma-3"
                                    :items="dropdown_timepoint_flashlight"
                                    label="Messzeitpunkt 1"
                                    hide-no-data
                                    dense
                                    v-model="dropdown_timepoint_flashlight_one_value"
                                    @change="update_flashlight"
                                ></v-overflow-btn>

                            </v-col>

                            <v-col cols="12" sm="4">

                                <v-overflow-btn
                                    v-if="dropdown_timepoint_flashlight.length >= 1"
                                    background-color="accent"
                                    class="ma-3"
                                    :items="dropdown_timepoint_flashlight"
                                    label="Messzeitpunkt 2"
                                    hide-no-data
                                    dense
                                    v-model="dropdown_timepoint_flashlight_two_value"
                                    @change="update_flashlight"
                                ></v-overflow-btn>

                            </v-col>

                        </v-row>

                        <highcharts class="highcharts_flashlight" :options="chartOptions_flashlight_1_bar" :updateArgs="updateArgs" v-if="dropdown_timepoint_flashlight.length >= 1"></highcharts>
                        <highcharts class="mt-5" :options="chartOptions_flashlight_2_bar" :updateArgs="updateArgs" v-if="dropdown_timepoint_flashlight.length >= 1"></highcharts>

                        <v-alert
                            v-if="dropdown_timepoint_flashlight.length < 1"
                            border="left"
                            elevation="1"
                            colored-border
                            prominent
                            type="error"
                            class="mx-3"
                        >
                            Es liegen noch keine Daten vor.
                        </v-alert>

                    </v-card>

                </v-tab-item>

            </v-tabs>

        </v-card>

    </div>
</template>

<script>
//import SupervisorApi from '@/_api/supervisor_api'

export default {
    
    name: 'supervisor_risk',

    data: () => ({

        loaded: false,

        dropdown_client: [],
        dropdown_client_value: null,

        updateArgs: [true, true, {duration: 1000}],

        /**
         * ACUTE CHART OPTIONS
         */
        dropdown_timepoint_acute_one_value: null,
        dropdown_timepoint_acute_two_value: null,
        acute_categories: [],
        acute_series_one: [],
        acute_series_two: [],

        chartOptions_acute_bar: {
                
                chart: {
                    type: 'bar',
                    backgroundColor:"#FAFAFA",
                    marginTop: 32,
                    height: 3000
                },

                legend: {
                    enabled: false
                },

                title : {
                    style : {
                        display : 'none'
                    }
                },

                xAxis: {

                    categories: [],

                    title: {
                        text: null
                    },

                    labels: {

                        enabled: true,

                        style: {
                            color: 'black',
                            cursor:"default",
                            fontSize:"14px",
                        },

                    }

                },

                yAxis: [{

                    labels: {
                        enabled: false,
                        style: {
                            color: 'black',
                            cursor:"default",
                            fontSize:"14px",
                        },
                        formatter: function () {

                            var output = ''

                            if (this.value == 0) {
                                return 'Nein'
                            } else if (this.value == 1) {
                                return 'Manchmal'
                            } else if (this.value == 2) {
                                return 'Oft'
                            } else {
                                output = ''
                            }

                            return output
                        }

                    },

                    title: {
                        align:"middle",
                        enabled: false,
                    },

                    tickInterval: 0.5,

                    minorGridLineWidth: 0,
                    gridLineWidth: 0,
                    alternateGridColor: null,

                    max: 2.5,
                    min: -0.5,

                    plotBands: [{
                            from: -0.5,
                            to: 0.5,
                            color: '#C8E6C9',
                        }, {
                            from: 0.5,
                            to: 1.5,
                            color: '#FFE0B2',
                        }, {
                            from: 1.5,
                            to: 2.5,
                            color: '#EF5350',
                        }
                    ]

                },
                {
                    linkedTo: 0,
                    opposite: true,
                    labels: {
                        enabled: false,
                        style: {
                            color: 'black',
                            cursor:"default",
                            fontSize:"14px",
                        },
                        formatter: function () {

                            var output = ''

                            if (this.value == 0) {
                                return 'Nein'
                            } else if (this.value == 1) {
                                return 'Manchmal'
                            } else if (this.value == 2) {
                                return 'Oft'
                            } else {
                                output = ''
                            }

                            return output
                        }

                    },

                    title: {
                        align:"middle",
                        enabled: false,
                    },

                    tickInterval: 0.5,

                    minorGridLineWidth: 0,
                    gridLineWidth: 0,
                    alternateGridColor: null,

                    max: 2.5,
                    min: -0.5,

                    plotBands: [{
                            from: -0.5,
                            to: 0.5,
                            color: '#66BB6A',
                        }, {
                            from: 0.5,
                            to: 1.5,
                            color: '#FFA726',
                        }, {
                            from: 1.5,
                            to: 2.5,
                            color: '#EF5350',
                        }
                    ]
                }],

                plotOptions: {
                    bar: {
                        //cropThreshold: 60,
                        threshold: null,
                        dataLabels: {
                            enabled: false
                        }
                    }
                },

                credits: {
                    enabled: false
                },

                series: [

                    {
                        color: '#004b8c',
                        pointPlacement: -0.1,
                        linkedTo: 'main',
                        data: [],
                        name: ''
                    }, 

                    {
                        color: '#fcb900',
                        name: '',
                        id: 'main',
                        data: [],
                    }

                ],

            },

        /**
         * CMC CHART OPTIONS
         */
        dropdown_timepoint_cmc_one_value: null,
        dropdown_timepoint_cmc_two_value: null,
        cmc_categories: [],
        cmc_series_one: [],
        cmc_series_two: [],

        chartOptions_cmc_bar: {
                
                chart: {
                    type: 'bar',
                    backgroundColor:"#FAFAFA",
                    marginTop: 32,
                    height: 3000
                },

                legend: {
                    enabled: false
                },

                title : {
                    style : {
                        display : 'none'
                    }
                },

                xAxis: {

                    categories: [],

                    title: {
                        text: null
                    },

                    labels: {

                        enabled: true,

                        style: {
                            color: 'black',
                            cursor:"default",
                            fontSize:"14px",
                        },

                    }

                },

                yAxis: [{

                    labels: {
                        enabled: true,
                        style: {
                            color: 'black',
                            cursor:"default",
                            fontSize:"14px",
                        },
                        formatter: function () {

                            var output = ''

                            if (this.value == 0) {
                                return '0 mal'
                            } else if (this.value == 1) {
                                return '1-2 mal'
                            } else if (this.value == 2) {
                                return 'öfter als 2 mal'
                            } else {
                                output = ''
                            }

                            return output
                        }

                    },

                    title: {
                        align:"middle",
                        enabled: false,
                    },

                    tickInterval: 0.5,

                    minorGridLineWidth: 0,
                    gridLineWidth: 0,
                    alternateGridColor: null,

                    max: 2.5,
                    min: -0.5,

                    plotBands: [{
                            from: -0.5,
                            to: 0.5,
                            color: '#FFEBEE',
                        }, {
                            from: 0.5,
                            to: 1.5,
                            color: '#EF9A9A',
                        }, {
                            from: 1.5,
                            to: 2.5,
                            color: '#EF5350',
                        }
                    ]

                },
                {
                    linkedTo: 0,
                    opposite: true,
                    labels: {
                        enabled: true,
                        style: {
                            color: 'black',
                            cursor:"default",
                            fontSize:"14px",
                        },
                        formatter: function () {

                            var output = ''

                            if (this.value == 0) {
                                return '0 mal'
                            } else if (this.value == 1) {
                                return '1-2 mal'
                            } else if (this.value == 2) {
                                return 'öfter als 2 mal'
                            } else {
                                output = ''
                            }

                            return output
                        }

                    },

                    title: {
                        align:"middle",
                        enabled: false,
                    },

                    tickInterval: 0.5,

                    minorGridLineWidth: 0,
                    gridLineWidth: 0,
                    alternateGridColor: null,

                    max: 2.5,
                    min: -0.5,

                    plotBands: [{
                            from: -0.5,
                            to: 0.5,
                            color: '#FFEBEE',
                        }, {
                            from: 0.5,
                            to: 1.5,
                            color: '#EF9A9A',
                        }, {
                            from: 1.5,
                            to: 2.5,
                            color: '#EF5350',
                        }
                    ]
                }],

                plotOptions: {
                    bar: {
                        //cropThreshold: 60,
                        threshold: null,
                        dataLabels: {
                            enabled: false
                        }
                    }
                },

                credits: {
                    enabled: false
                },

                series: [

                    {
                        color: '#004b8c',
                        pointPlacement: -0.1,
                        linkedTo: 'main',
                        data: [],
                        name: ''
                    }, 

                    {
                        color: '#fcb900',
                        name: '',
                        id: 'main',
                        data: [],
                    }

                ],

            },

        /**
         * CTE CHART OPTIONS
         */
        dropdown_timepoint_cte_one_value: null,
        dropdown_timepoint_cte_two_value: null,
        cte_categories: [],
        cte_series_one: [],
        cte_series_two: [],

        chartOptions_cte_bar: {
                
                chart: {
                    type: 'bar',
                    backgroundColor:"#FAFAFA",
                    marginTop: 48,
                    height: 3000
                },

                legend: {
                    enabled: false
                },

                title : {
                    style : {
                        display : 'none'
                    }
                },

                xAxis: {

                    categories: [],

                    title: {
                        text: null
                    },

                    labels: {

                        enabled: true,

                        style: {
                            color: 'black',
                            cursor:"default",
                            fontSize:"14px",
                        },

                    }

                },

                yAxis: [{

                    labels: {
                        enabled: true,
                        style: {
                            color: 'black',
                            cursor:"default",
                            fontSize:"14px",
                        },
                        formatter: function () {

                            var output = ''

                            if (this.value == 0) {
                                return 'Stimme zu'
                            } else if (this.value == 1) {
                                return 'Stimme ein bisschen zu'
                            } else if (this.value == 2) {
                                return 'Stimme nicht zu'
                            } else {
                                output = ''
                            }

                            return output
                        }

                    },

                    title: {
                        align:"middle",
                        enabled: false,
                    },

                    tickInterval: 0.5,

                    minorGridLineWidth: 0,
                    gridLineWidth: 0,
                    alternateGridColor: null,

                    max: 2.5,
                    min: -0.5,

                    plotBands: [{
                            from: -0.5,
                            to: 0.5,
                            color: '#EEEEEE',
                        }, {
                            from: 0.5,
                            to: 1.5,
                            color: '#E0E0E0',
                        }, {
                            from: 1.5,
                            to: 2.5,
                            color: '#BDBDBD',
                        }
                    ]

                },
                {
                    linkedTo: 0,
                    opposite: true,
                    labels: {
                        enabled: true,
                        style: {
                            color: 'black',
                            cursor:"default",
                            fontSize:"14px",
                        },
                        formatter: function () {

                            var output = ''

                            if (this.value == 0) {
                                return 'Stimme zu'
                            } else if (this.value == 1) {
                                return 'Stimme ein bisschen zu'
                            } else if (this.value == 2) {
                                return 'Stimme nicht zu'
                            } else {
                                output = ''
                            }

                            return output
                        }

                    },

                    title: {
                        align:"middle",
                        enabled: false,
                    },

                    tickInterval: 0.5,

                    minorGridLineWidth: 0,
                    gridLineWidth: 0,
                    alternateGridColor: null,

                    max: 2.5,
                    min: -0.5,

                    plotBands: [{
                            from: -0.5,
                            to: 0.5,
                            color: '#EEEEEE',
                        }, {
                            from: 0.5,
                            to: 1.5,
                            color: '#E0E0E0',
                        }, {
                            from: 1.5,
                            to: 2.5,
                            color: '#BDBDBD',
                        }
                    ]
                }],

                plotOptions: {
                    bar: {
                        //cropThreshold: 60,
                        threshold: null,
                        dataLabels: {
                            enabled: false
                        }
                    }
                },

                credits: {
                    enabled: false
                },

                series: [

                    {
                        color: '#004b8c',
                        pointPlacement: -0.1,
                        linkedTo: 'main',
                        data: [],
                        name: ''
                    }, 

                    {
                        color: '#fcb900',
                        name: '',
                        id: 'main',
                        data: [],
                    }

                ],

            },

        /**
         * flashlight_1 ITEM 1 CHART OPTIONS
         */
        dropdown_timepoint_flashlight_one_value: null,
        dropdown_timepoint_flashlight_two_value: null,
        flashlight_1_categories: [],
        flashlight_1_series_one: [],
        flashlight_1_series_two: [],

        chartOptions_flashlight_1_bar: {
                
                chart: {
                    type: 'bar',
                    backgroundColor:"#FAFAFA",
                    marginTop: 48,
                    height: 200
                },

                legend: {
                    enabled: false
                },

                title : {
                    style : {
                        display : 'none'
                    }
                },

                xAxis: {

                    categories: [],

                    title: {
                        text: null
                    },

                    labels: {

                        enabled: true,

                        style: {
                            color: 'black',
                            cursor:"default",
                            fontSize:"14px",
                        },

                    }

                },

                yAxis: [{

                    labels: {
                        enabled: false,
                        style: {
                            color: 'black',
                            cursor:"default",
                            fontSize:"14px",
                        },
                        formatter: function () {

                            var output = ''

                            if (this.value == 1) {
                                return 'stimmt gar nicht'
                            } else if (this.value == 2) {
                                return 'stimmt eher nicht'
                            } else if (this.value == 3) {
                                return 'stimmt teilweise'
                            } else if (this.value == 4) {
                                return 'stimmt eher'
                            } else if (this.value == 5) {
                                return 'stimmt völlig'
                            } else {
                                output = ''
                            }

                            return output
                        }

                    },

                    title: {
                        align:"middle",
                        enabled: false,
                    },

                    tickInterval: 0.5,

                    minorGridLineWidth: 0,
                    gridLineWidth: 0,
                    alternateGridColor: null,

                    max: 5.5,
                    min: 0,

                    plotBands: [
                        {
                            from: 0,
                            to: 1.5,
                            color: '#EEEEEE',
                        }, 
                        {
                            from: 1.5,
                            to: 2.5,
                            color: '#E0E0E0',
                        },
                        {
                            from: 2.5,
                            to: 3.5,
                            color: '#EEEEEE',
                        }, 
                        {
                            from: 3.5,
                            to: 4.5,
                            color: '#E0E0E0',
                        }, 
                        {
                            from: 4.5,
                            to: 5.5,
                            color: '#EEEEEE',
                        },  
                        
                    ]

                },
                {
                    linkedTo: 0,
                    opposite: true,
                    labels: {
                        enabled: true,
                        style: {
                            color: 'black',
                            cursor:"default",
                            fontSize:"14px",
                        },
                        formatter: function () {

                            var output = ''

                            if (this.value == 1) {
                                return 'stimmt gar nicht'
                            } else if (this.value == 2) {
                                return 'stimmt eher nicht'
                            } else if (this.value == 3) {
                                return 'stimmt teilweise'
                            } else if (this.value == 4) {
                                return 'stimmt eher'
                            } else if (this.value == 5) {
                                return 'stimmt völlig'
                            } else {
                                output = ''
                            }

                            return output
                        }

                    },

                    title: {
                        align:"middle",
                        enabled: false,
                    },

                    tickInterval: 0.5,

                    minorGridLineWidth: 0,
                    gridLineWidth: 0,
                    alternateGridColor: null,

                    max: 2.5,
                    min: -0.5,

                    plotBands: [
                        {
                            from: 0,
                            to: 1.5,
                            color: '#EEEEEE',
                        }, 
                        {
                            from: 1.5,
                            to: 2.5,
                            color: '#E0E0E0',
                        },
                        {
                            from: 2.5,
                            to: 3.5,
                            color: '#EEEEEE',
                        }, 
                        {
                            from: 3.5,
                            to: 4.5,
                            color: '#E0E0E0',
                        }, 
                        {
                            from: 4.5,
                            to: 5.5,
                            color: '#EEEEEE',
                        },  
                    ]
                }],

                plotOptions: {
                    bar: {
                        //cropThreshold: 60,
                        threshold: null,
                        dataLabels: {
                            enabled: false
                        }
                    }
                },

                credits: {
                    enabled: false
                },

                series: [

                    {
                        color: '#004b8c',
                        pointPlacement: -0.1,
                        linkedTo: 'main',
                        data: [],
                        name: ''
                    }, 

                    {
                        color: '#fcb900',
                        name: '',
                        id: 'main',
                        data: [],
                    }

                ],

            },

            /**
         * flashlight_2 ITEM2 CHART OPTIONS
         */
        flashlight_2_categories: [],
        flashlight_2_series_one: [],
        flashlight_2_series_two: [],

        chartOptions_flashlight_2_bar: {
                
                chart: {
                    type: 'bar',
                    backgroundColor:"#FAFAFA",
                    marginTop: 48,
                    height: 250
                },

                legend: {
                    enabled: false
                },

                title : {
                    style : {
                        display : 'none'
                    }
                },

                xAxis: {

                    categories: [],

                    title: {
                        text: null
                    },

                    labels: {

                        enabled: true,

                        style: {
                            color: 'black',
                            cursor:"default",
                            fontSize:"14px",
                        },

                    }

                },

                yAxis: [{

                    labels: {
                        enabled: true,
                        style: {
                            color: 'black',
                            cursor:"default",
                            fontSize:"14px",
                        },
                        formatter: function () {

                            var output = ''

                            if (this.value == 1) {
                                return 'sehr gut'
                            } else if (this.value == 2) {
                                return 'gut'
                            } else if (this.value == 3) {
                                return 'eher gut'
                            } else if (this.value == 4) {
                                return 'eher schlecht'
                            } else if (this.value == 5) {
                                return 'schlecht'
                            } else if (this.value == 6) {
                                return 'sehr schlecht'
                            } else {
                                output = ''
                            }

                            return output
                        }

                    },

                    title: {
                        align:"middle",
                        enabled: false,
                    },

                    tickInterval: 0.5,

                    minorGridLineWidth: 0,
                    gridLineWidth: 0,
                    alternateGridColor: null,

                    max: 6.5,
                    min: 0,

                    plotBands: [
                        {
                            from: 0,
                            to: 1.5,
                            color: '#EEEEEE',
                        }, 
                        {
                            from: 1.5,
                            to: 2.5,
                            color: '#E0E0E0',
                        },
                        {
                            from: 2.5,
                            to: 3.5,
                            color: '#EEEEEE',
                        }, 
                        {
                            from: 3.5,
                            to: 4.5,
                            color: '#E0E0E0',
                        }, 
                        {
                            from: 4.5,
                            to: 5.5,
                            color: '#EEEEEE',
                        },
                        {
                            from: 5.5,
                            to: 6.5,
                            color: '#E0E0E0',
                        },   
                        
                    ]

                },
                {
                    linkedTo: 0,
                    opposite: true,
                    labels: {
                        enabled: false,
                        style: {
                            color: 'black',
                            cursor:"default",
                            fontSize:"14px",
                        },
                        formatter: function () {

                            var output = ''

                            if (this.value == 1) {
                                return 'sehr gut'
                            } else if (this.value == 2) {
                                return 'gut'
                            } else if (this.value == 3) {
                                return 'eher gut'
                            } else if (this.value == 4) {
                                return 'eher schlecht'
                            } else if (this.value == 5) {
                                return 'schlecht'
                            } else if (this.value == 6) {
                                return 'sehr schlecht'
                            } else {
                                output = ''
                            }

                            return output
                        }

                    },

                    title: {
                        align:"middle",
                        enabled: false,
                    },

                    tickInterval: 0.5,

                    minorGridLineWidth: 0,
                    gridLineWidth: 0,
                    alternateGridColor: null,

                    max: 2.5,
                    min: -0.5,

                    plotBands: [
                        {
                            from: 0,
                            to: 1.5,
                            color: '#EEEEEE',
                        }, 
                        {
                            from: 1.5,
                            to: 2.5,
                            color: '#E0E0E0',
                        },
                        {
                            from: 2.5,
                            to: 3.5,
                            color: '#EEEEEE',
                        }, 
                        {
                            from: 3.5,
                            to: 4.5,
                            color: '#E0E0E0',
                        }, 
                        {
                            from: 4.5,
                            to: 5.5,
                            color: '#EEEEEE',
                        },
                        {
                            from: 5.5,
                            to: 6.5,
                            color: '#E0E0E0',
                        },   
                    ]
                }],

                plotOptions: {
                    bar: {
                        //cropThreshold: 60,
                        threshold: null,
                        dataLabels: {
                            enabled: false
                        }
                    }
                },

                credits: {
                    enabled: false
                },

                series: [

                    {
                        color: '#004b8c',
                        pointPlacement: -0.1,
                        linkedTo: 'main',
                        data: [],
                        name: ''
                    }, 

                    {
                        color: '#fcb900',
                        name: '',
                        id: 'main',
                        data: [],
                    }

                ],

            },

    


    }),

    created() {

    },

    computed: {

        dropdown_timepoint_acute() {
            return this.risk_data_acute[this.dropdown_client_value].timepoints
        },

        dropdown_timepoint_cte() {
            return this.risk_data_cte[this.dropdown_client_value].timepoints
        },

        dropdown_timepoint_cmc() {
            return this.risk_data_cmc[this.dropdown_client_value].timepoints
        },

        dropdown_timepoint_flashlight() {
            return this.risk_data_flashlight_1[this.dropdown_client_value].timepoints
        },

        risk_data_acute() {
            return this.$store.getters['store_supervisor/get_risk_data'].acute
        },

        risk_data_cte() {
            return this.$store.getters['store_supervisor/get_risk_data'].cte
        },

        risk_data_cmc() {
            return this.$store.getters['store_supervisor/get_risk_data'].cmc
        },

        risk_data_flashlight_1() {
            return this.$store.getters['store_supervisor/get_risk_data'].flashlight_1
        },

        risk_data_flashlight_2() {
            return this.$store.getters['store_supervisor/get_risk_data'].flashlight_2
        },

        update_date() {

            var currentdate = new Date();
            var hours = '00'
            var minutes = '00'
            var seconds = '00'

            if (currentdate.getHours() < 10) {
                hours = '0' + currentdate.getHours()
            } else {
                hours = currentdate.getHours()
            }

            if (currentdate.getMinutes() < 10) {
                minutes = '0' + currentdate.getMinutes()
            } else {
                minutes = currentdate.getMinutes()
            }

            if (currentdate.getSeconds() < 10) {
                seconds = '0' + currentdate.getSeconds()
            } else {
                seconds = currentdate.getSeconds()
            }


            return hours + ":" + minutes + ":" + seconds;

        },

    },

    methods: {

        go_to_acute() {
            this.$router.push({name: 'risk-acute'})
        },

        update_client_all() {

            // series
            try {

                /**
                 * ACUTE
                 */

                // categories
                this.acute_categories = this.$store.getters['store_supervisor/get_risk_data'].acute[this.dropdown_client_value].categories

                // update categories
                this.chartOptions_acute_bar.xAxis.categories = this.acute_categories

                if (this.risk_data_acute[this.dropdown_client_value].timepoints.length <= 0) {

                    this.dropdown_timepoint_acute_two_value = null
                    this.dropdown_timepoint_acute_one_value = null

                } else if (this.risk_data_acute[this.dropdown_client_value].timepoints.length == 1) {

                    this.dropdown_timepoint_acute_two_value = this.risk_data_acute[this.dropdown_client_value].timepoints[0]
                    this.dropdown_timepoint_acute_one_value = null

                    // series
                    //this.acute_series_one = this.$store.getters['store_supervisor/get_risk_data'].acute[this.dropdown_client_value][this.dropdown_timepoint_acute_one_value].slice()
                    this.acute_series_two = this.$store.getters['store_supervisor/get_risk_data'].acute[this.dropdown_client_value][this.dropdown_timepoint_acute_two_value].slice()

                    //this.chartOptions_acute_bar.series[0].data = this.acute_series_one
                    this.chartOptions_acute_bar.series[1].data = this.acute_series_two

                    //this.chartOptions_acute_bar.series[0].name = this.dropdown_timepoint_acute_one_value
                    this.chartOptions_acute_bar.series[1].name = this.dropdown_timepoint_acute_two_value

                } else {

                    this.dropdown_timepoint_acute_two_value = this.risk_data_acute[this.dropdown_client_value].timepoints[0]
                    this.dropdown_timepoint_acute_one_value = this.risk_data_acute[this.dropdown_client_value].timepoints[1]

                    // series
                    this.acute_series_one = this.$store.getters['store_supervisor/get_risk_data'].acute[this.dropdown_client_value][this.dropdown_timepoint_acute_one_value].slice()
                    this.acute_series_two = this.$store.getters['store_supervisor/get_risk_data'].acute[this.dropdown_client_value][this.dropdown_timepoint_acute_two_value].slice()

                    this.chartOptions_acute_bar.series[0].data = this.acute_series_one
                    this.chartOptions_acute_bar.series[1].data = this.acute_series_two

                    this.chartOptions_acute_bar.series[0].name = this.dropdown_timepoint_acute_one_value
                    this.chartOptions_acute_bar.series[1].name = this.dropdown_timepoint_acute_two_value
                    
                }

                /**
                 * CTE
                 */

                // categories
                this.cte_categories = this.$store.getters['store_supervisor/get_risk_data'].cte[this.dropdown_client_value].categories

                // update categories
                this.chartOptions_cte_bar.xAxis.categories = this.cte_categories

                if (this.risk_data_cte[this.dropdown_client_value].timepoints.length <= 0) {

                    this.dropdown_timepoint_cte_two_value = null
                    this.dropdown_timepoint_cte_one_value = null

                } else if (this.risk_data_cte[this.dropdown_client_value].timepoints.length == 1) {

                    this.dropdown_timepoint_cte_two_value = this.risk_data_cte[this.dropdown_client_value].timepoints[0]
                    this.dropdown_timepoint_cte_one_value = null

                    // series
                    //this.cte_series_one = this.$store.getters['store_supervisor/get_risk_data'].cte[this.dropdown_client_value][this.dropdown_timepoint_cte_one_value].slice()
                    this.cte_series_two = this.$store.getters['store_supervisor/get_risk_data'].cte[this.dropdown_client_value][this.dropdown_timepoint_cte_two_value].slice()

                    //this.chartOptions_cte_bar.series[0].data = this.cte_series_one
                    this.chartOptions_cte_bar.series[1].data = this.cte_series_two

                    //this.chartOptions_cte_bar.series[0].name = this.dropdown_timepoint_cte_one_value
                    this.chartOptions_cte_bar.series[1].name = this.dropdown_timepoint_cte_two_value

                } else {

                    this.dropdown_timepoint_cte_two_value = this.risk_data_cte[this.dropdown_client_value].timepoints[0]
                    this.dropdown_timepoint_cte_one_value = this.risk_data_cte[this.dropdown_client_value].timepoints[1]

                    // series
                    this.cte_series_one = this.$store.getters['store_supervisor/get_risk_data'].cte[this.dropdown_client_value][this.dropdown_timepoint_cte_one_value].slice()
                    this.cte_series_two = this.$store.getters['store_supervisor/get_risk_data'].cte[this.dropdown_client_value][this.dropdown_timepoint_cte_two_value].slice()

                    this.chartOptions_cte_bar.series[0].data = this.cte_series_one
                    this.chartOptions_cte_bar.series[1].data = this.cte_series_two

                    this.chartOptions_cte_bar.series[0].name = this.dropdown_timepoint_cte_one_value
                    this.chartOptions_cte_bar.series[1].name = this.dropdown_timepoint_cte_two_value

                }


                /**
                 * CMC
                 */

                // categories
                this.cmc_categories = this.$store.getters['store_supervisor/get_risk_data'].cmc[this.dropdown_client_value].categories

                // update categories
                this.chartOptions_cmc_bar.xAxis.categories = this.cmc_categories

                if (this.risk_data_cmc[this.dropdown_client_value].timepoints.length <= 0) {

                    this.dropdown_timepoint_cmc_two_value = null
                    this.dropdown_timepoint_cmc_one_value = null

                } else if (this.risk_data_cmc[this.dropdown_client_value].timepoints.length == 1) {

                    this.dropdown_timepoint_cmc_two_value = this.risk_data_cmc[this.dropdown_client_value].timepoints[0]
                    this.dropdown_timepoint_cmc_one_value = null

                    // series
                    //this.cmc_series_one = this.$store.getters['store_supervisor/get_risk_data'].cmc[this.dropdown_client_value][this.dropdown_timepoint_cmc_one_value].slice()
                    this.cmc_series_two = this.$store.getters['store_supervisor/get_risk_data'].cmc[this.dropdown_client_value][this.dropdown_timepoint_cmc_two_value].slice()

                    //this.chartOptions_cmc_bar.series[0].data = this.cmc_series_one
                    this.chartOptions_cmc_bar.series[1].data = this.cmc_series_two

                    //this.chartOptions_cmc_bar.series[0].name = this.dropdown_timepoint_cmc_one_value
                    this.chartOptions_cmc_bar.series[1].name = this.dropdown_timepoint_cmc_two_value

                } else {

                    this.dropdown_timepoint_cmc_two_value = this.risk_data_cmc[this.dropdown_client_value].timepoints[0]
                    this.dropdown_timepoint_cmc_one_value = this.risk_data_cmc[this.dropdown_client_value].timepoints[1]

                    // series
                    this.cmc_series_one = this.$store.getters['store_supervisor/get_risk_data'].cmc[this.dropdown_client_value][this.dropdown_timepoint_cmc_one_value].slice()
                    this.cmc_series_two = this.$store.getters['store_supervisor/get_risk_data'].cmc[this.dropdown_client_value][this.dropdown_timepoint_cmc_two_value].slice()

                    this.chartOptions_cmc_bar.series[0].data = this.cmc_series_one
                    this.chartOptions_cmc_bar.series[1].data = this.cmc_series_two

                    this.chartOptions_cmc_bar.series[0].name = this.dropdown_timepoint_cmc_one_value
                    this.chartOptions_cmc_bar.series[1].name = this.dropdown_timepoint_cmc_two_value
                    
                }

                /**
                 * flashlight_1
                 */

                // categories
                this.flashlight_1_categories = this.$store.getters['store_supervisor/get_risk_data'].flashlight_1[this.dropdown_client_value].categories

                // update categories
                this.chartOptions_flashlight_1_bar.xAxis.categories = this.flashlight_1_categories

                if (this.risk_data_flashlight_1[this.dropdown_client_value].timepoints.length <= 0) {

                    this.dropdown_timepoint_flashlight_two_value = null
                    this.dropdown_timepoint_flashlight_one_value = null

                } else if (this.risk_data_flashlight_1[this.dropdown_client_value].timepoints.length == 1) {

                    this.dropdown_timepoint_flashlight_two_value = this.risk_data_flashlight_1[this.dropdown_client_value].timepoints[0]
                    this.dropdown_timepoint_flashlight_one_value = null

                    // series
                    //this.cmc_series_one = this.$store.getters['store_supervisor/get_risk_data'].cmc[this.dropdown_client_value][this.dropdown_timepoint_cmc_one_value].slice()
                    this.flashlight_1_series_two = this.$store.getters['store_supervisor/get_risk_data'].flashlight_1[this.dropdown_client_value][this.dropdown_timepoint_flashlight_two_value].slice()

                    //this.chartOptions_cmc_bar.series[0].data = this.cmc_series_one
                    this.chartOptions_flashlight_1_bar.series[1].data = this.flashlight_1_series_two

                    //this.chartOptions_cmc_bar.series[0].name = this.dropdown_timepoint_cmc_one_value
                    this.chartOptions_flashlight_1_bar.series[1].name = this.dropdown_timepoint_flashlight_two_value

                } else {

                    this.dropdown_timepoint_flashlight_two_value = this.risk_data_flashlight_1[this.dropdown_client_value].timepoints[0]
                    this.dropdown_timepoint_flashlight_one_value = this.risk_data_flashlight_1[this.dropdown_client_value].timepoints[1]

                    // series
                    this.flashlight_1_series_one = this.$store.getters['store_supervisor/get_risk_data'].flashlight_1[this.dropdown_client_value][this.dropdown_timepoint_flashlight_one_value].slice()
                    this.flashlight_1_series_two = this.$store.getters['store_supervisor/get_risk_data'].flashlight_1[this.dropdown_client_value][this.dropdown_timepoint_flashlight_two_value].slice()

                    this.chartOptions_flashlight_1_bar.series[0].data = this.flashlight_1_series_one
                    this.chartOptions_flashlight_1_bar.series[1].data = this.flashlight_1_series_two

                    this.chartOptions_flashlight_1_bar.series[0].name = this.dropdown_timepoint_flashlight_one_value
                    this.chartOptions_flashlight_1_bar.series[1].name = this.dropdown_timepoint_flashlight_two_value
                    
                }

                /**
                 * flashlight_2
                 */

                // categories
                this.flashlight_2_categories = this.$store.getters['store_supervisor/get_risk_data'].flashlight_2[this.dropdown_client_value].categories

                // update categories
                this.chartOptions_flashlight_2_bar.xAxis.categories = this.flashlight_2_categories

                if (this.risk_data_flashlight_2[this.dropdown_client_value].timepoints.length <= 0) {

                    this.dropdown_timepoint_flashlight_two_value = null
                    this.dropdown_timepoint_flashlight_one_value = null

                } else if (this.risk_data_flashlight_2[this.dropdown_client_value].timepoints.length == 1) {

                    this.dropdown_timepoint_flashlight_two_value = this.risk_data_flashlight_2[this.dropdown_client_value].timepoints[0]
                    this.dropdown_timepoint_flashlight_one_value = null

                    // series
                    //this.cmc_series_one = this.$store.getters['store_supervisor/get_risk_data'].cmc[this.dropdown_client_value][this.dropdown_timepoint_cmc_one_value].slice()
                    this.flashlight_2_series_two = this.$store.getters['store_supervisor/get_risk_data'].flashlight_2[this.dropdown_client_value][this.dropdown_timepoint_flashlight_two_value].slice()

                    //this.chartOptions_cmc_bar.series[0].data = this.cmc_series_one
                    this.chartOptions_flashlight_2_bar.series[1].data = this.flashlight_2_series_two

                    //this.chartOptions_cmc_bar.series[0].name = this.dropdown_timepoint_cmc_one_value
                    this.chartOptions_flashlight_2_bar.series[1].name = this.dropdown_timepoint_flashlight_two_value

                } else {

                    this.dropdown_timepoint_flashlight_two_value = this.risk_data_flashlight_2[this.dropdown_client_value].timepoints[0]
                    this.dropdown_timepoint_flashlight_one_value = this.risk_data_flashlight_2[this.dropdown_client_value].timepoints[1]

                    // series
                    this.flashlight_2_series_one = this.$store.getters['store_supervisor/get_risk_data'].flashlight_2[this.dropdown_client_value][this.dropdown_timepoint_flashlight_one_value].slice()
                    this.flashlight_2_series_two = this.$store.getters['store_supervisor/get_risk_data'].flashlight_2[this.dropdown_client_value][this.dropdown_timepoint_flashlight_two_value].slice()

                    this.chartOptions_flashlight_2_bar.series[0].data = this.flashlight_2_series_one
                    this.chartOptions_flashlight_2_bar.series[1].data = this.flashlight_2_series_two

                    this.chartOptions_flashlight_2_bar.series[0].name = this.dropdown_timepoint_flashlight_one_value
                    this.chartOptions_flashlight_2_bar.series[1].name = this.dropdown_timepoint_flashlight_two_value
                    
                }

            } catch (error) {

                console.debug(error)
                
            }

        },

        update_acute() {

            // series
            try {

                // categories
                this.acute_categories = this.$store.getters['store_supervisor/get_risk_data'].acute[this.dropdown_client_value].categories

                // update categories
                this.chartOptions_acute_bar.xAxis.categories = this.acute_categories

                this.acute_series_one = this.$store.getters['store_supervisor/get_risk_data'].acute[this.dropdown_client_value][this.dropdown_timepoint_acute_one_value].slice()
                this.acute_series_two = this.$store.getters['store_supervisor/get_risk_data'].acute[this.dropdown_client_value][this.dropdown_timepoint_acute_two_value].slice()

                this.chartOptions_acute_bar.series[0].data = this.acute_series_one
                this.chartOptions_acute_bar.series[1].data = this.acute_series_two

                this.chartOptions_acute_bar.series[0].name = this.dropdown_timepoint_acute_one_value
                this.chartOptions_acute_bar.series[1].name = this.dropdown_timepoint_acute_two_value

            } catch (error) {

                console.debug(error)
                
            }
            
        },

        update_cte() {
            
            // series
            try {

                // categories
                this.cte_categories = this.$store.getters['store_supervisor/get_risk_data'].cte[this.dropdown_client_value].categories

                // update categories
                this.chartOptions_cte_bar.xAxis.categories = this.cte_categories

                this.cte_series_one = this.$store.getters['store_supervisor/get_risk_data'].cte[this.dropdown_client_value][this.dropdown_timepoint_cte_one_value].slice()
                this.cte_series_two = this.$store.getters['store_supervisor/get_risk_data'].cte[this.dropdown_client_value][this.dropdown_timepoint_cte_two_value].slice()

                this.chartOptions_cte_bar.series[0].data = this.cte_series_one
                this.chartOptions_cte_bar.series[1].data = this.cte_series_two

                this.chartOptions_cte_bar.series[0].name = this.dropdown_timepoint_cte_one_value
                this.chartOptions_cte_bar.series[1].name = this.dropdown_timepoint_cte_two_value

            } catch (error) {

                console.debug(error)
                
            }

        },

        update_cmc() {

            // series
            try {

                // categories
                this.cmc_categories = this.$store.getters['store_supervisor/get_risk_data'].cmc[this.dropdown_client_value].categories

                // update categories
                this.chartOptions_cmc_bar.xAxis.categories = this.cmc_categories

                this.cmc_series_one = this.$store.getters['store_supervisor/get_risk_data'].cmc[this.dropdown_client_value][this.dropdown_timepoint_cmc_one_value].slice()
                this.cmc_series_two = this.$store.getters['store_supervisor/get_risk_data'].cmc[this.dropdown_client_value][this.dropdown_timepoint_cmc_two_value].slice()

                this.chartOptions_cmc_bar.series[0].data = this.cmc_series_one
                this.chartOptions_cmc_bar.series[1].data = this.cmc_series_two

                this.chartOptions_cmc_bar.series[0].name = this.dropdown_timepoint_cmc_one_value
                this.chartOptions_cmc_bar.series[1].name = this.dropdown_timepoint_cmc_two_value

            } catch (error) {

                console.debug(error)
                
            }

        },

        update_flashlight() {

            // series
            try {

                // categories
                this.flashlight_1_categories = this.$store.getters['store_supervisor/get_risk_data'].flashlight_1[this.dropdown_client_value].categories

                // update categories
                this.chartOptions_flashlight_1_bar.xAxis.categories = this.flashlight_1_categories

                this.flashlight_1_series_one = this.$store.getters['store_supervisor/get_risk_data'].flashlight_1[this.dropdown_client_value][this.dropdown_timepoint_flashlight_one_value].slice()
                this.flashlight_1_series_two = this.$store.getters['store_supervisor/get_risk_data'].flashlight_1[this.dropdown_client_value][this.dropdown_timepoint_flashlight_two_value].slice()

                this.chartOptions_flashlight_1_bar.series[0].data = this.flashlight_1_series_one
                this.chartOptions_flashlight_1_bar.series[1].data = this.flashlight_1_series_two

                this.chartOptions_flashlight_1_bar.series[0].name = this.dropdown_timepoint_flashlight_one_value
                this.chartOptions_flashlight_1_bar.series[1].name = this.dropdown_timepoint_flashlight_two_value

                // categories
                this.flashlight_2_categories = this.$store.getters['store_supervisor/get_risk_data'].flashlight_2[this.dropdown_client_value].categories

                // update categories
                this.chartOptions_flashlight_2_bar.xAxis.categories = this.flashlight_2_categories

                this.flashlight_2_series_one = this.$store.getters['store_supervisor/get_risk_data'].flashlight_2[this.dropdown_client_value][this.dropdown_timepoint_flashlight_one_value].slice()
                this.flashlight_2_series_two = this.$store.getters['store_supervisor/get_risk_data'].flashlight_2[this.dropdown_client_value][this.dropdown_timepoint_flashlight_two_value].slice()

                this.chartOptions_flashlight_2_bar.series[0].data = this.flashlight_2_series_one
                this.chartOptions_flashlight_2_bar.series[1].data = this.flashlight_2_series_two

                this.chartOptions_flashlight_2_bar.series[0].name = this.dropdown_timepoint_flashlight_one_value
                this.chartOptions_flashlight_2_bar.series[1].name = this.dropdown_timepoint_flashlight_two_value

            } catch (error) {

                console.debug(error)
                
            }

        }

    },
    
}
</script>

<style scoped>
.v-sheet-offset {
    top: -24px;
    position: relative;
}

.line-chart {
    max-height: 190px !important;
    height: auto;
    position: relative;
}

.highcharts_acute{
    min-height: 800px !important;
}
</style>