<template>

    <v-container>

        <v-row>

            <v-col>
                <v-card
                    class="pa-2"
                    height="100%"
                    outlined
                    tile
                    @click="emergency_red"
                >
                    <v-img
                        class="ma-3 align-end"
                        height="80px"
                        src="/images/main/ambulance-solid-error.png"
                        contain
                    ></v-img>

                    <v-card-text class="title text-center">Ich brauche sofort Hilfe!</v-card-text>

                    <v-card-text class="text-center">
                        Klicken Sie hier, wenn es Ihnen gerade <b>sehr schlecht</b> geht oder Sie befürchten, in den nächsten Stunden eine <b>Straftat</b> zu begehen.
                    </v-card-text>

                </v-card>
            </v-col>

            <v-col>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            color="primary" 
                            @click="exportToPDF" 
                            fab 
                            absolute 
                            right 
                            :disabled="!show_card_self_help"
                            :style="$vuetify.breakpoint.lgAndDown ? 'margin-right: 24px; margin-top: 4px;' : 'margin-right: 112px; margin-top: 4px;'"
                        >
                            <v-icon>fas fa-file-download</v-icon>
                        </v-btn>
                    </template>
                    <span>Laden Sie Ihren Notfallkoffer als PDF-Datei herunter</span>
                </v-tooltip>

                <v-card
                    class="pa-2"
                    height="100%"
                    outlined
                    tile
                    @click="emergency_accent"
                    v-if="show_card_self_help"
                >
                    
                    <v-img
                        class="ma-3 align-center"
                        height="80px"
                        src="/images/main/ambulance-solid-accent.png"
                        contain
                    ></v-img>

                    <v-card-text class="title text-center">Ich versuche mein Problem selbst zu lösen.</v-card-text>

                    <v-card-text class="text-center" v-if="$store.getters['store_client/get_client'].client_group == 1">
                        Klicken Sie hier, wenn es Ihnen gerade nicht gut geht. Genau für diese Situation haben wir im Laufe 
                        des Online-Programms gemeinsam <b>Lösungen</b> entwickelt.
                    </v-card-text>

                    <v-card-text class="text-center" v-if="$store.getters['store_client/get_client'].client_group == 2">
                        Klicken Sie hier, wenn es Ihnen gerade nicht gut geht. Wir haben einige Tipps zusammengestellt, was Sie selbst tun können,
                        damit es Ihnen wieder besser geht.
                    </v-card-text>

                </v-card>

                <v-card
                    class="pa-2"
                    height="100%"
                    outlined
                    tile
                    v-else
                >
                    <v-img
                        class="ma-3 align-center"
                        height="80px"
                        src="/images/main/ambulance-solid-grey.png"
                        contain
                    ></v-img>

                    <v-card-text class="title align-center grey--text text--lighten-1 text-center">Ich versuche mein Problem selbst zu lösen.</v-card-text>

                    <v-card-text class="grey--text text--lighten-1 text-center">
                        Klicken Sie hier, wenn es Ihnen gerade nicht gut geht. Genau für diese Situation haben wir im Laufe 
                        des Online-Programms gemeinsam <b>Lösungen</b> entwickelt.
                    </v-card-text>

                </v-card>
            </v-col>

            <v-col>
                <v-card
                    class="pa-2"
                    height="100%"
                    outlined
                    tile
                    @click="emergency_success"
                >
                    <v-img
                        class="ma-3 align-end"
                        height="80px"
                        src="/images/main/ambulance-solid-success.png"
                        contain
                    ></v-img>

                    <v-card-text class="title text-center">Ich brauche jemanden zum Reden.</v-card-text>

                    <v-card-text class="text-center">
                        Wählen Sie diesen Weg nur, wenn es für Sie kein Problem ist, einige Zeit zu warten bis jemand Ihnen antwortet.
                    </v-card-text>
                </v-card>
            </v-col>

        </v-row>

        <div v-show="always_false">

            <div ref="document">

                <EmergencyToPdf />

            </div>

        </div>

    </v-container>

</template>

<script>
//import ClientApi from '@/_api/client_api'
import _ from 'lodash'

import EmergencyToPdf from '@/components/_client/_emergency/emergency_pdf.vue'

import html2pdf from 'html2pdf.js'

export default {
    name: 'client-emergency',

    data: () => ({
        
        e13: 2,

        always_false: false

    }),

    created() {

    },

    components: {
        EmergencyToPdf
    },

    computed: {

        emergency_data() {

            return this.$store.getters['store_client/get_client_emergency_data']

        },

        show_card_emotion() {

            if (!_.isEmpty(this.emergency_data.m3_s4_l4_t1) || !_.isEmpty(this.emergency_data.m3_s1_l7_t1) || !_.isEmpty(this.emergency_data.m2_s3_l5_t3)) {
                return true
            } else {
                return false
            }

        },

        show_card_control() {
            if (!_.isEmpty(this.emergency_data.m6_s1_l5_t1) || !_.isEmpty(this.emergency_data.m6_s1_l5_t2) || !_.isEmpty(this.emergency_data.m6_s2_l4_t1) || !_.isEmpty(this.emergency_data.m6_s3_l4_t2) || !_.isEmpty(this.emergency_data.m6_s4_l5_t1)) {
                return true
            } else {
                return false
            }
        },

        show_card_crime() {
            if (!_.isEmpty(this.emergency_data.helpful_thought_1) || !_.isEmpty(this.emergency_data.helpful_thought_2) || !_.isEmpty(this.emergency_data.helpful_thought_3) || !_.isEmpty(this.emergency_data.m1_s4_l4_t2)) {
                return true
            } else {
                return false
            }
        },

        show_card_self_help() {

            if (this.$store.getters['store_client/get_client'].client_group == 2) {
                return true
            } else {
                if (this.show_card_emotion || this.show_card_control || this.show_card_crime) {
                    return true
                } else {
                    return false
                }   
            }
            
        }
    
    },

    methods: {

        // pdf2html
        exportToPDF () {
            
            html2pdf(this.$refs.document, {
                margin: 1,
                filename: 'Mein_Notfallkoffer.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { dpi: 300, letterRendering: true, allowTaint: true },
                jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait', lang: 'de' },
                pagebreak: { before: '#page2el' }
            })

        },

        emergency_red() {

            this.$router.push('/client/emergency/error')

        },

        emergency_accent() {

            this.$router.push('/client/emergency/accent')
            
        },

        emergency_success() {

            this.$router.push('/client/emergency/success')
            
        }

    }
}
</script>

<style scoped>

</style>