<template>

    <v-container fluid class="py-0" v-if="post" ref="myNavDrawer">

        <div>

            <!-- Title -->
            <AssessmentStart v-if="$route.params.step == 0" />


            <div v-if="$route.params.step > 0 && $route.params.step <= all_assessments.length && post" >
                <v-row class="mb-5">
                    <p class="headline font-weight-medium mt-2">Fragebogen {{ $route.params.step }} von {{ all_assessments.length }}</p>

                    <v-expansion-panels class="mb-5 mt-1 mx-0" :value="panel">

                        <v-expansion-panel>

                            <v-expansion-panel-header class="title">Anleitung</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                
                                <p class="subtitle-1 font-weight-regular px-0 mx-0" style="text-align:justify;" v-html="assessment_content.fields.instruction">
                              
                                </p>

                            </v-expansion-panel-content>

                        </v-expansion-panel>

                    </v-expansion-panels>
                    
                    <p class="title" v-if="!assessment_content.already_saved"><span><v-icon color="error" class="mr-2">fas fa-exclamation</v-icon></span>
                        Bitte beantworten Sie alle Fragen (Teil {{ current_field_chunk + 1 }} von {{ get_chunks_of_field_verbose_names.length }}).
                    </p>
                    <p class="title" v-else><span><v-icon color="success" class="mr-2">fas fa-check</v-icon></span>
                        Sie haben bereits alle Fragen beantwortet (Teil {{ current_field_chunk + 1 }} von {{ get_chunks_of_field_verbose_names.length }}).
                    </p>
                    <v-progress-linear
                        id="progress"
                        color="primary"
                        height="10"
                        :value="progress_value"
                        class="mt-3 mb-3 mx-0"
                        rounded
                    ></v-progress-linear>
                </v-row>

                <v-row v-if="assessment_content.name == 'aoo'">

                    <v-col cols="12" sm="12">

                        <v-form 
                            ref="form" 
                            v-model="forms_valid[current_field_chunk]"  
                        >

                            <div
                                v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                :key="index"
                            >

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                    v-if="get_chunks_of_field_names[current_field_chunk][index] == 'aoo_1'"
                                >

                                    <v-card-text>

                                        <v-checkbox
                                            v-if="get_chunks_of_field_names[current_field_chunk][index] == 'aoo_1'"
                                            v-model="aoo1_value"
                                            :label="get_chunks_of_field_verbose_names[current_field_chunk][index]"
                                            @change="update_aoo_1_value()"
                                        ></v-checkbox>

                                    </v-card-text>

                                </v-card>

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                    v-if="get_chunks_of_field_names[current_field_chunk][index] == 'aoo_2' && !aoo1_value"
                                >

                                    <v-card-text
                                        class="title primary--text"
                                    >
                                        {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                    </v-card-text>

                                    <v-card-text>

                                        <v-slider
                                            v-model="aoo2_value"
                                            class="align-center my-5"
                                            :max="max"
                                            :min="min"
                                            :rules="[v => v >= 0 || 'Sie müssen einen Wert zwischen 0 und 100 angeben.']"
                                            thumb-label="always"
                                            @input="update_aoo_2_value()"
                                        >

                                            <template v-slot:append>
                                                <div>Jahre</div>
                                            </template>

                                        </v-slider>

                                    </v-card-text>

                                </v-card>

                            </div>

                        </v-form>

                    </v-col>

                </v-row>

                <v-row v-if="assessment_content.name == 'soi'">

                    <v-col cols="12" sm="12">

                        <v-form 
                            ref="form" 
                            v-model="forms_valid[current_field_chunk]"  
                        >


                            <v-card 
                                class="mb-4"
                                width="100%"
                                color="grey lighten-5"
                                v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                :key="index"
                            >

                                <v-card-text
                                    class="title primary--text"
                                >
                                    {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                </v-card-text>

                                <v-card-text>

                                    <v-slider
                                        v-if="get_chunks_of_field_names[current_field_chunk][index] == 'soi_1'"
                                        v-model="soi1_value"
                                        class="align-center my-5"
                                        :max="soi_max"
                                        :min="soi_min"
                                        :rules="[v => v >= 0 || 'Sie müssen einen Wert zwischen 0 und 100 angeben.']"
                                        @change="update_soi_1_value(index)"
                                        thumb-label="always"
                                    >
                                        <template v-slot:append>
                                            <div>Ich muss handeln, um das Verlangen zu befriedigen.</div>
                                        </template>

                                        <template v-slot:prepend>
                                            <div>Das Verlangen ist abwesend.</div>
                                        </template>

                                    </v-slider>

                                    <v-slider
                                        v-if="get_chunks_of_field_names[current_field_chunk][index] == 'soi_2'"
                                        v-model="soi2_value"
                                        class="align-center my-5"
                                        :max="soi_max"
                                        :min="soi_min"
                                        :rules="[v => v >= 0 || 'Sie müssen einen Wert zwischen 0 und 100 angeben.']"
                                        @change="update_soi_2_value(index)"
                                        thumb-label="always"
                                    >
                                        <template v-slot:append>
                                            <div>Ich muss handeln, um das Verlangen zu befriedigen.</div>
                                        </template>

                                        <template v-slot:prepend>
                                            <div>Das Verlangen ist abwesend.</div>
                                        </template>
                                    </v-slider>

                                </v-card-text>
                            </v-card>

                        </v-form>

                    </v-col>

                </v-row>

                <v-row v-if="assessment_content.name == 'phq'">

                    <v-col cols="12" sm="12">

                        <v-form 
                            ref="form" 
                            v-model="forms_valid[current_field_chunk]"  
                        >

                            <v-card 
                                class="mb-4"
                                width="100%"
                                color="grey lighten-5"
                                v-if="current_field_chunk != 5 && current_field_chunk != 6 && current_field_chunk != 8"
                            >
                                <v-card-text 
                                    class="title"
                                >
                                    <div v-if="current_field_chunk == 0">
                                        Wie stark fühlten Sie sich im Verlauf der <i>letzten 4 Wochen</i> durch die folgenden Beschwerden beeinträchtigt? 
                                    </div>
                                    
                                    <div v-if="current_field_chunk == 1">
                                        Wie oft fühlten Sie sich im Verlauf der letzten 2 Wochen durch die folgenden Beschwerden beeinträchtigt? 
                                    </div>

                                    <div v-if="current_field_chunk == 2">
                                        Fragen zum Thema „Angst“.
                                    </div>

                                    <div v-if="current_field_chunk == 3">
                                        Wie oft fühlten Sie sich im Verlauf der <i>letzten 4 Wochen</i> durch die folgenden Beschwerden beeinträchtigt?
                                    </div>

                                    <div v-if="current_field_chunk == 4">
                                        Fragen zum Thema „Essen“. 
                                    </div>

                                    <div v-if="current_field_chunk == 7">
                                        Wie stark fühlten Sie sich im Verlauf der <i>letzten 4 Wochen</i> durch die folgenden Beschwerden beeinträchtigt? 
                                    </div>

                                </v-card-text>
                            </v-card>

                            <div v-if="current_field_chunk == 2">

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 22"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 26 && phq_angst"
                                    >
                                        <v-card-text 
                                            class="title"
                                        >
                                            Denken Sie bitte an Ihren letzten schlimmen Angstanfall. 
                                        </v-card-text>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 26 && phq_angst"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 26 && index != 22 && phq_angst"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>


                                </div>
                            

                            </div>

                            <div v-if="current_field_chunk == 3">

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 37"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 37 && phq_nervous"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                </div>

                            </div>

                            <div v-if="current_field_chunk == 4">

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 44 || index == 45"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 47 && phq_eat"
                                    >
                                        <v-card-text 
                                            class="title"
                                        >
                                            Haben Sie während der letzten 3 Monate öfter eine oder mehrere der folgenden Maßnahmen unternommen, um eine Gewichtszunahme zu vermeiden? 
                                        </v-card-text>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 44 && index != 45 && index != 51 && phq_eat"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 51 && phq_eat && phq_eat_last"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                </div>
                            
                            </div>

                            <div v-if="current_field_chunk == 5">

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 52"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 53 && phq_alc"
                                    >
                                        <v-card-text 
                                            class="title"
                                        >
                                            Ist bei Ihnen im Laufe der <i>letzten 6 Monate</i> mehr als einmal eine der folgenden Situationen eingetreten? 
                                        </v-card-text>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 52 && phq_alc"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                </div>
                            
                            </div>

                            <div v-if="current_field_chunk == 8">

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 69 || index == 71"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 70"
                                    >
                                    
                                       <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                counter="255"
                                                :rules="[v => !!v || 'Sie müssen einen Wert angeben.', v => v.length <= 255 || 'Sie dürfen nicht mehr als 255 Zeichen eingeben.']"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 72 && phq_woman"
                                    >
                                        <v-card-text 
                                            class="title"
                                        >
                                            Fragen zum Thema Monatsblutung, Schwangerschaft und Geburt
                                        </v-card-text>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 69 && index != 70 && index != 71 && phq_woman"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                </div>
                            
                            </div>
                                                        

                            <div v-if="current_field_chunk != 2 && current_field_chunk != 3 && current_field_chunk != 4 && current_field_chunk != 5 && current_field_chunk != 8">
                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >
                                
                                    <v-card-text
                                        class="title primary--text"
                                    >
                                        {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                    </v-card-text>

                                    <div class="mx-3">

                                        <v-radio-group 
                                            v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                            :row="$vuetify.breakpoint.mdAndUp"
                                            :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                            :disabled="assessment_content.already_saved"
                                        >
                                            <v-radio
                                                v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                :key="x"
                                                :label="label[1]"
                                                :value="label[0]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                            ></v-radio>
                                        </v-radio-group>

                                    </div>
                                </v-card>
                            </div>

                        </v-form>

                    </v-col>

                </v-row>

                <v-row v-if="assessment_content.name == 'icd'">

                    <div v-if="assessment_content.fields.field_names.length == 55">

                        <v-col cols="12" sm="12">

                        <v-form 
                            ref="form" 
                            v-model="forms_valid[current_field_chunk]"  
                        >

                            <div v-if="current_field_chunk == 0">

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text 
                                        class="title"
                                    >
                                            Im Folgenden finden Sie einige <b>sexuelle Schwierigkeiten</b>, die häufig vorkommen. Bitte geben Sie zu jeder der folgenden Erfahrungen an, ob Sie diese in Ihrem bisherigen Leben <i>über mehrere Monate hinweg</i> erlebt haben.
                                    </v-card-text>
                                </v-card>

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 0"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 0 && icd_male_1_1"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                </div>
                            
                            </div>

                            <div v-if="current_field_chunk == 1">

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text 
                                        class="title"
                                    >
                                        Im Folgenden finden Sie einige <b>sexuelle Schwierigkeiten</b>, die häufig vorkommen. Bitte geben Sie zu jeder der folgenden Erfahrungen an, ob Sie diese in Ihrem bisherigen Leben <i>über mehrere Monate hinweg</i> erlebt haben.
                                    </v-card-text>
                                </v-card>

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 5"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 5 && icd_male_1_2"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                </div>
                            
                            </div>

                            <div v-if="current_field_chunk == 2">

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text
                                        class="title"
                                    >
                                        Im Folgenden finden Sie einige <b>sexuelle Schwierigkeiten</b>, die häufig vorkommen. Bitte geben Sie zu jeder der folgenden Erfahrungen an, ob Sie diese in Ihrem bisherigen Leben <i>über mehrere Monate hinweg</i> erlebt haben.
                                    </v-card-text>
                                </v-card>

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 10"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 10 && icd_male_1_3"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                </div>
                            
                            </div>

                            <div v-if="current_field_chunk == 3">

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text
                                        class="title"
                                    >
                                        <div>
                                            Im Folgenden finden Sie einige <b>sexuelle Schwierigkeiten</b>, die häufig vorkommen. Bitte geben Sie zu jeder der folgenden Erfahrungen an, ob Sie diese in Ihrem bisherigen Leben <i>über mehrere Monate hinweg</i> erlebt haben.
                                        </div>
                                    </v-card-text>
                                </v-card>

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 15"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 15 && icd_male_1_4"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                </div>
                            
                            </div>

                            <div v-if="current_field_chunk == 4">

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text
                                        class="title"
                                    >
                                        Im Folgenden finden Sie einige <b>sexuelle Schwierigkeiten</b>, die häufig vorkommen. Bitte geben Sie zu jeder der folgenden Erfahrungen an, ob Sie diese in Ihrem bisherigen Leben <i>über mehrere Monate hinweg</i> erlebt haben.
                                    </v-card-text>
                                </v-card>

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 20"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 20 && icd_male_1_5"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                </div>
                            
                            </div>

                            <div v-if="current_field_chunk == 5">

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text
                                        class="title"
                                    >
                                        Es gibt Menschen, die sich <b>einem anderen Geschlecht zugehörig fühlen</b>, als es ihre Geburtsurkunde ausweist. Manchmal verändern sich diese Empfindungen auch im Laufe des Lebens.
                                    </v-card-text>
                                </v-card>

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 27 && index != 30"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 27 && icd_male_2_2a"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                counter="255"
                                                :rules="[v => !!v || 'Sie müssen einen Wert angeben.', v => v.length <= 255 || 'Sie dürfen nicht mehr als 255 Zeichen eingeben.']"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 30 && icd_male_2_4a"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                counter="255"
                                                :rules="[v => !!v || 'Sie müssen einen Wert angeben.', v => v.length <= 255 || 'Sie dürfen nicht mehr als 255 Zeichen eingeben.']"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                </div>
                            
                            </div>

                            <div v-if="current_field_chunk == 6">

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text
                                        class="title"
                                    >
                                        Waren Sie <i>jemals</i> über einen <i>längeren Zeitraum</i> (z. B. mehrere Monate) <i>immer wieder intensiv sexuell erregt</i> (wie es sich durch anhaltende sexuelle Gedanken, Fantasien, dranghafte Bedürfnisse oder Verhaltensweisen zeigt) durch folgende Inhalte:
                                    </v-card-text>
                                </v-card>

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 31"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 31 && index != 34 && icd_male_3_1"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 34 && icd_male_3_1b && icd_male_3_1"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                </div>
                            
                            </div>

                            <div v-if="current_field_chunk == 7">

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text 
                                        class="title"
                                    >
                                        Waren Sie <i>jemals</i> über einen <i>längeren Zeitraum</i> (z. B. mehrere Monate) <i>immer wieder intensiv sexuell erregt</i> (wie es sich durch anhaltende sexuelle Gedanken, Fantasien, dranghafte Bedürfnisse oder Verhaltensweisen zeigt) durch folgende Inhalte:
                                    </v-card-text>
                                </v-card>

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 35"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 35 && index != 38 && icd_male_3_2"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 38 && icd_male_3_2b && icd_male_3_2"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                </div>
                            
                            </div>

                            <div v-if="current_field_chunk == 8">

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text 
                                        class="title"
                                    >
                                        Waren Sie <i>jemals</i> über einen <i>längeren Zeitraum</i> (z. B. mehrere Monate) <i>immer wieder intensiv sexuell erregt</i> (wie es sich durch anhaltende sexuelle Gedanken, Fantasien, dranghafte Bedürfnisse oder Verhaltensweisen zeigt) durch folgende Inhalte:
                                    </v-card-text>
                                </v-card>

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 39"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 39 && index != 42 && icd_male_3_3"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 42 && icd_male_3_3b && icd_male_3_3"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                </div>
                            
                            </div>

                            <div v-if="current_field_chunk == 9">

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text 
                                        class="title"
                                    >
                                        Waren Sie <i>jemals</i> über einen <i>längeren Zeitraum</i> (z. B. mehrere Monate) <i>immer wieder intensiv sexuell erregt</i> (wie es sich durch anhaltende sexuelle Gedanken, Fantasien, dranghafte Bedürfnisse oder Verhaltensweisen zeigt) durch folgende Inhalte:
                                    </v-card-text>
                                </v-card>

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 43"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 43 && index != 46 && icd_male_3_4"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 46 && icd_male_3_4b && icd_male_3_4"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                </div>
                            
                            </div>

                            <div v-if="current_field_chunk == 10">

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text
                                        class="title"
                                    >
                                        Waren Sie <i>jemals</i> über einen <i>längeren Zeitraum</i> (z. B. mehrere Monate) <i>immer wieder intensiv sexuell erregt</i> (wie es sich durch anhaltende sexuelle Gedanken, Fantasien, dranghafte Bedürfnisse oder Verhaltensweisen zeigt) durch folgende Inhalte:
                                    </v-card-text>
                                </v-card>

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 47"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 47 && index != 50 && icd_male_3_5"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 50 && icd_male_3_5b && icd_male_3_5"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                </div>
                            
                            </div>

                            <div v-if="current_field_chunk == 11">

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text
                                        class="title"
                                    >
                                        Waren Sie <i>jemals</i> über einen <i>längeren Zeitraum</i> (z. B. mehrere Monate) <i>immer wieder intensiv sexuell erregt</i> (wie es sich durch anhaltende sexuelle Gedanken, Fantasien, dranghafte Bedürfnisse oder Verhaltensweisen zeigt) durch folgende Inhalte:
                                    </v-card-text>
                                </v-card>

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 51"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 51 && index != 54 && icd_male_3_6"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 54 && icd_male_3_6b && icd_male_3_6"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                </div>
                            
                            </div>

                        </v-form>

                    </v-col>

                    </div>

                    <div v-else>

                        <!-- ICD-11 FEMALE -->

                        <v-col cols="12" sm="12">

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <div v-if="current_field_chunk == 0">

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                    >
                                        <v-card-text
                                            class="title"
                                        >
                                            Im Folgenden finden Sie einige <b>sexuelle Schwierigkeiten</b>, die häufig vorkommen. Bitte geben Sie zu jeder der folgenden Erfahrungen an, ob Sie diese in Ihrem bisherigen Leben <i>über mehrere Monate</i> hinweg erlebt haben.
                                        </v-card-text>
                                    </v-card>

                                    <div
                                        v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                        :key="index"
                                    >

                                        <v-card 
                                            class="mb-4"
                                            width="100%"
                                            color="grey lighten-5"
                                        >
                                        
                                            <v-card-text
                                                class="title primary--text"
                                            >
                                                {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                            </v-card-text>

                                            <div class="mx-3">

                                                <v-radio-group 
                                                    v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                    :row="$vuetify.breakpoint.mdAndUp"
                                                    :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                    :disabled="assessment_content.already_saved"
                                                >
                                                    <v-radio
                                                        v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                        :key="x"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>
                                                </v-radio-group>

                                            </div>
                                        </v-card>

                                    </div>
                                
                                </div>

                                <div v-if="current_field_chunk == 1">

                                        <v-card 
                                            class="mb-4"
                                            width="100%"
                                            color="grey lighten-5"
                                        >
                                            <v-card-text
                                                class="title"
                                            >
                                                Es gibt Menschen, die sich <b>einem anderen Geschlecht zugehörig fühlen</b>, als es ihre Geburtsurkunde ausweist. Manchmal verändern sich diese Empfindungen auch im Laufe des Lebens.
                                            </v-card-text>
                                        </v-card>

                                        <div
                                            v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                            :key="index"
                                        >

                                            <v-card 
                                                class="mb-4"
                                                width="100%"
                                                color="grey lighten-5"
                                                v-if="index != 7 && index != 10"
                                            >
                                            
                                                <v-card-text
                                                    class="title primary--text"
                                                >
                                                    {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                                </v-card-text>

                                                <div class="mx-3">

                                                    <v-radio-group 
                                                        v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                        :row="$vuetify.breakpoint.mdAndUp"
                                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                        :disabled="assessment_content.already_saved"
                                                    >
                                                        <v-radio
                                                            v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                            :key="x"
                                                            :label="label[1]"
                                                            :value="label[0]"
                                                            @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                        ></v-radio>
                                                    </v-radio-group>

                                                </div>
                                            </v-card>

                                            <v-card 
                                                class="mb-4"
                                                width="100%"
                                                color="grey lighten-5"
                                                v-if="index == 7 && icd_female_2_2a"
                                            >
                                            
                                                <v-card-text
                                                    class="title primary--text"
                                                >
                                                    {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                                </v-card-text>

                                                <div class="mx-3">

                                                    <v-text-field
                                                        class="mb-3 pb-3"
                                                        single-line
                                                        v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                        counter="255"
                                                        :rules="[v => !!v || 'Sie müssen einen Wert angeben.', v => v.length <= 255 || 'Sie dürfen nicht mehr als 255 Zeichen eingeben.']"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                                    ></v-text-field>

                                                </div>
                                            </v-card>

                                            <v-card 
                                                class="mb-4"
                                                width="100%"
                                                color="grey lighten-5"
                                                v-if="index == 10 && icd_female_2_4a"
                                            >
                                            
                                                <v-card-text
                                                    class="title primary--text"
                                                >
                                                    {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                                </v-card-text>

                                                <div class="mx-3">

                                                    <v-text-field
                                                        class="mb-3 pb-3"
                                                        single-line
                                                        v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                        counter="255"
                                                        :rules="[v => !!v || 'Sie müssen einen Wert angeben.', v => v.length <= 255 || 'Sie dürfen nicht mehr als 255 Zeichen eingeben.']"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                                    ></v-text-field>

                                                </div>
                                            </v-card>

                                        </div>
                                    
                                    </div>

                                    <div v-if="current_field_chunk == 2">

                                        <v-card 
                                            class="mb-4"
                                            width="100%"
                                            color="grey lighten-5"
                                        >
                                            <v-card-text
                                                class="title"
                                            >
                                                Waren Sie <i>jemals</i> über einen <i>längeren Zeitraum</i> (z. B. mehrere Monate) <i>immer wieder intensiv sexuell erregt</i> (wie es sich durch anhaltende sexuelle Gedanken, Fantasien, dranghafte Bedürfnisse oder Verhaltensweisen zeigt) durch folgende Inhalte:
                                            </v-card-text>
                                        </v-card>

                                        <div
                                            v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                            :key="index"
                                        >

                                            <v-card 
                                                class="mb-4"
                                                width="100%"
                                                color="grey lighten-5"
                                                v-if="index == 11"
                                            >
                                            
                                                <v-card-text
                                                    class="title primary--text"
                                                >
                                                    {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                                </v-card-text>

                                                <div class="mx-3">

                                                    <v-radio-group 
                                                        v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                        :row="$vuetify.breakpoint.mdAndUp"
                                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                        :disabled="assessment_content.already_saved"
                                                    >
                                                        <v-radio
                                                            v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                            :key="x"
                                                            :label="label[1]"
                                                            :value="label[0]"
                                                            @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                        ></v-radio>
                                                    </v-radio-group>

                                                </div>
                                            </v-card>

                                            <v-card 
                                                class="mb-4"
                                                width="100%"
                                                color="grey lighten-5"
                                                v-if="index != 11 && index != 14 && icd_female_3_1"
                                            >
                                            
                                                <v-card-text
                                                    class="title primary--text"
                                                >
                                                    {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                                </v-card-text>

                                                <div class="mx-3">

                                                    <v-radio-group 
                                                        v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                        :row="$vuetify.breakpoint.mdAndUp"
                                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                        :disabled="assessment_content.already_saved"
                                                    >
                                                        <v-radio
                                                            v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                            :key="x"
                                                            :label="label[1]"
                                                            :value="label[0]"
                                                            @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                        ></v-radio>
                                                    </v-radio-group>

                                                </div>
                                            </v-card>

                                            <v-card 
                                                class="mb-4"
                                                width="100%"
                                                color="grey lighten-5"
                                                v-if="index == 14 && icd_female_3_1b && icd_female_3_1"
                                            >
                                            
                                                <v-card-text
                                                    class="title primary--text"
                                                >
                                                    {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                                </v-card-text>

                                                <div class="mx-3">

                                                    <v-radio-group 
                                                        v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                        :row="$vuetify.breakpoint.mdAndUp"
                                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                        :disabled="assessment_content.already_saved"
                                                    >
                                                        <v-radio
                                                            v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                            :key="x"
                                                            :label="label[1]"
                                                            :value="label[0]"
                                                            @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                        ></v-radio>
                                                    </v-radio-group>

                                                </div>
                                            </v-card>

                                        </div>
                                    
                                    </div>

                                    <div v-if="current_field_chunk == 3">

                                        <v-card 
                                            class="mb-4"
                                            width="100%"
                                            color="grey lighten-5"
                                        >
                                            <v-card-text 
                                                class="title"
                                            >
                                                Waren Sie <i>jemals</i> über einen <i>längeren Zeitraum</i> (z. B. mehrere Monate) <i>immer wieder intensiv sexuell erregt</i> (wie es sich durch anhaltende sexuelle Gedanken, Fantasien, dranghafte Bedürfnisse oder Verhaltensweisen zeigt) durch folgende Inhalte:
                                            </v-card-text>
                                        </v-card>

                                        <div
                                            v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                            :key="index"
                                        >

                                            <v-card 
                                                class="mb-4"
                                                width="100%"
                                                color="grey lighten-5"
                                                v-if="index == 15"
                                            >
                                            
                                                <v-card-text
                                                    class="title primary--text"
                                                >
                                                    {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                                </v-card-text>

                                                <div class="mx-3">

                                                    <v-radio-group 
                                                        v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                        :row="$vuetify.breakpoint.mdAndUp"
                                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                        :disabled="assessment_content.already_saved"
                                                    >
                                                        <v-radio
                                                            v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                            :key="x"
                                                            :label="label[1]"
                                                            :value="label[0]"
                                                            @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                        ></v-radio>
                                                    </v-radio-group>

                                                </div>
                                            </v-card>

                                            <v-card 
                                                class="mb-4"
                                                width="100%"
                                                color="grey lighten-5"
                                                v-if="index != 15 && index != 18 && icd_female_3_2"
                                            >
                                            
                                                <v-card-text
                                                    class="title primary--text"
                                                >
                                                    {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                                </v-card-text>

                                                <div class="mx-3">

                                                    <v-radio-group 
                                                        v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                        :row="$vuetify.breakpoint.mdAndUp"
                                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                        :disabled="assessment_content.already_saved"
                                                    >
                                                        <v-radio
                                                            v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                            :key="x"
                                                            :label="label[1]"
                                                            :value="label[0]"
                                                            @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                        ></v-radio>
                                                    </v-radio-group>

                                                </div>
                                            </v-card>

                                            <v-card 
                                                class="mb-4"
                                                width="100%"
                                                color="grey lighten-5"
                                                v-if="index == 18 && icd_female_3_2b && icd_female_3_2b"
                                            >
                                            
                                                <v-card-text
                                                    class="title primary--text"
                                                >
                                                    {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                                </v-card-text>

                                                <div class="mx-3">

                                                    <v-radio-group 
                                                        v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                        :row="$vuetify.breakpoint.mdAndUp"
                                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                        :disabled="assessment_content.already_saved"
                                                    >
                                                        <v-radio
                                                            v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                            :key="x"
                                                            :label="label[1]"
                                                            :value="label[0]"
                                                            @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                        ></v-radio>
                                                    </v-radio-group>

                                                </div>
                                            </v-card>

                                        </div>
                                    
                                    </div>

                                    <div v-if="current_field_chunk == 4">

                                        <v-card 
                                            class="mb-4"
                                            width="100%"
                                            color="grey lighten-5"
                                        >
                                            <v-card-text 
                                                class="title"
                                            >
                                                Waren Sie <i>jemals</i> über einen <i>längeren Zeitraum</i> (z. B. mehrere Monate) <i>immer wieder intensiv sexuell erregt</i> (wie es sich durch anhaltende sexuelle Gedanken, Fantasien, dranghafte Bedürfnisse oder Verhaltensweisen zeigt) durch folgende Inhalte:
                                            </v-card-text>
                                        </v-card>

                                        <div
                                            v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                            :key="index"
                                        >

                                            <v-card 
                                                class="mb-4"
                                                width="100%"
                                                color="grey lighten-5"
                                                v-if="index == 19"
                                            >
                                            
                                                <v-card-text
                                                    class="title primary--text"
                                                >
                                                    {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                                </v-card-text>

                                                <div class="mx-3">

                                                    <v-radio-group 
                                                        v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                        :row="$vuetify.breakpoint.mdAndUp"
                                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                        :disabled="assessment_content.already_saved"
                                                    >
                                                        <v-radio
                                                            v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                            :key="x"
                                                            :label="label[1]"
                                                            :value="label[0]"
                                                            @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                        ></v-radio>
                                                    </v-radio-group>

                                                </div>
                                            </v-card>

                                            <v-card 
                                                class="mb-4"
                                                width="100%"
                                                color="grey lighten-5"
                                                v-if="index != 19 && index != 22 && icd_female_3_3"
                                            >
                                            
                                                <v-card-text
                                                    class="title primary--text"
                                                >
                                                    {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                                </v-card-text>

                                                <div class="mx-3">

                                                    <v-radio-group 
                                                        v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                        :row="$vuetify.breakpoint.mdAndUp"
                                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                        :disabled="assessment_content.already_saved"
                                                    >
                                                        <v-radio
                                                            v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                            :key="x"
                                                            :label="label[1]"
                                                            :value="label[0]"
                                                            @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                        ></v-radio>
                                                    </v-radio-group>

                                                </div>
                                            </v-card>

                                            <v-card 
                                                class="mb-4"
                                                width="100%"
                                                color="grey lighten-5"
                                                v-if="index == 22 && icd_female_3_3b && icd_female_3_3"
                                            >
                                            
                                                <v-card-text
                                                    class="title primary--text"
                                                >
                                                    {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                                </v-card-text>

                                                <div class="mx-3">

                                                    <v-radio-group 
                                                        v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                        :row="$vuetify.breakpoint.mdAndUp"
                                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                        :disabled="assessment_content.already_saved"
                                                    >
                                                        <v-radio
                                                            v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                            :key="x"
                                                            :label="label[1]"
                                                            :value="label[0]"
                                                            @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                        ></v-radio>
                                                    </v-radio-group>

                                                </div>
                                            </v-card>

                                        </div>
                                    
                                    </div>

                                    <div v-if="current_field_chunk == 5">

                                        <v-card 
                                            class="mb-4"
                                            width="100%"
                                            color="grey lighten-5"
                                        >
                                            <v-card-text
                                                class="title"
                                            >
                                                 Waren Sie <i>jemals</i> über einen <i>längeren Zeitraum</i> (z. B. mehrere Monate) <i>immer wieder intensiv sexuell erregt</i> (wie es sich durch anhaltende sexuelle Gedanken, Fantasien, dranghafte Bedürfnisse oder Verhaltensweisen zeigt) durch folgende Inhalte:
                                            </v-card-text>
                                        </v-card>

                                        <div
                                            v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                            :key="index"
                                        >

                                            <v-card 
                                                class="mb-4"
                                                width="100%"
                                                color="grey lighten-5"
                                                v-if="index == 23"
                                            >
                                            
                                                <v-card-text
                                                    class="title primary--text"
                                                >
                                                    {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                                </v-card-text>

                                                <div class="mx-3">

                                                    <v-radio-group 
                                                        v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                        :row="$vuetify.breakpoint.mdAndUp"
                                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                        :disabled="assessment_content.already_saved"
                                                    >
                                                        <v-radio
                                                            v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                            :key="x"
                                                            :label="label[1]"
                                                            :value="label[0]"
                                                            @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                        ></v-radio>
                                                    </v-radio-group>

                                                </div>
                                            </v-card>

                                            <v-card 
                                                class="mb-4"
                                                width="100%"
                                                color="grey lighten-5"
                                                v-if="index != 23 && index != 26 && icd_female_3_4"
                                            >
                                            
                                                <v-card-text
                                                    class="title primary--text"
                                                >
                                                    {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                                </v-card-text>

                                                <div class="mx-3">

                                                    <v-radio-group 
                                                        v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                        :row="$vuetify.breakpoint.mdAndUp"
                                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                        :disabled="assessment_content.already_saved"
                                                    >
                                                        <v-radio
                                                            v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                            :key="x"
                                                            :label="label[1]"
                                                            :value="label[0]"
                                                            @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                        ></v-radio>
                                                    </v-radio-group>

                                                </div>
                                            </v-card>

                                            <v-card 
                                                class="mb-4"
                                                width="100%"
                                                color="grey lighten-5"
                                                v-if="index == 26 && icd_female_3_4b && icd_female_3_4"
                                            >
                                            
                                                <v-card-text
                                                    class="title primary--text"
                                                >
                                                    {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                                </v-card-text>

                                                <div class="mx-3">

                                                    <v-radio-group 
                                                        v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                        :row="$vuetify.breakpoint.mdAndUp"
                                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                        :disabled="assessment_content.already_saved"
                                                    >
                                                        <v-radio
                                                            v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                            :key="x"
                                                            :label="label[1]"
                                                            :value="label[0]"
                                                            @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                        ></v-radio>
                                                    </v-radio-group>

                                                </div>
                                            </v-card>

                                        </div>
                                    
                                    </div>

                                    <div v-if="current_field_chunk == 6">

                                        <v-card 
                                            class="mb-4"
                                            width="100%"
                                            color="grey lighten-5"
                                        >
                                            <v-card-text 
                                                class="title"
                                            >
                                                Waren Sie <i>jemals</i> über einen <i>längeren Zeitraum</i> (z. B. mehrere Monate) <i>immer wieder intensiv sexuell erregt</i> (wie es sich durch anhaltende sexuelle Gedanken, Fantasien, dranghafte Bedürfnisse oder Verhaltensweisen zeigt) durch folgende Inhalte:
                                            </v-card-text>
                                        </v-card>

                                        <div
                                            v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                            :key="index"
                                        >

                                            <v-card 
                                                class="mb-4"
                                                width="100%"
                                                color="grey lighten-5"
                                                v-if="index == 27"
                                            >
                                            
                                                <v-card-text
                                                    class="title primary--text"
                                                >
                                                    {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                                </v-card-text>

                                                <div class="mx-3">

                                                    <v-radio-group 
                                                        v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                        :row="$vuetify.breakpoint.mdAndUp"
                                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                        :disabled="assessment_content.already_saved"
                                                    >
                                                        <v-radio
                                                            v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                            :key="x"
                                                            :label="label[1]"
                                                            :value="label[0]"
                                                            @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                        ></v-radio>
                                                    </v-radio-group>

                                                </div>
                                            </v-card>

                                            <v-card 
                                                class="mb-4"
                                                width="100%"
                                                color="grey lighten-5"
                                                v-if="index != 27 && index != 30 && icd_female_3_5"
                                            >
                                            
                                                <v-card-text
                                                    class="title primary--text"
                                                >
                                                    {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                                </v-card-text>

                                                <div class="mx-3">

                                                    <v-radio-group 
                                                        v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                        :row="$vuetify.breakpoint.mdAndUp"
                                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                        :disabled="assessment_content.already_saved"
                                                    >
                                                        <v-radio
                                                            v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                            :key="x"
                                                            :label="label[1]"
                                                            :value="label[0]"
                                                            @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                        ></v-radio>
                                                    </v-radio-group>

                                                </div>
                                            </v-card>

                                            <v-card 
                                                class="mb-4"
                                                width="100%"
                                                color="grey lighten-5"
                                                v-if="index == 30 && icd_female_3_5b && icd_female_3_5"
                                            >
                                            
                                                <v-card-text
                                                    class="title primary--text"
                                                >
                                                    {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                                </v-card-text>

                                                <div class="mx-3">

                                                    <v-radio-group 
                                                        v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                        :row="$vuetify.breakpoint.mdAndUp"
                                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                        :disabled="assessment_content.already_saved"
                                                    >
                                                        <v-radio
                                                            v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                            :key="x"
                                                            :label="label[1]"
                                                            :value="label[0]"
                                                            @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                        ></v-radio>
                                                    </v-radio-group>

                                                </div>
                                            </v-card>

                                        </div>
                                    
                                    </div>

                                    <div v-if="current_field_chunk == 7">

                                        <v-card 
                                            class="mb-4"
                                            width="100%"
                                            color="grey lighten-5"
                                        >
                                            <v-card-text
                                                class="title"
                                            >
                                                Waren Sie <i>jemals</i> über einen <i>längeren Zeitraum</i> (z. B. mehrere Monate) <i>immer wieder intensiv sexuell erregt</i> (wie es sich durch anhaltende sexuelle Gedanken, Fantasien, dranghafte Bedürfnisse oder Verhaltensweisen zeigt) durch folgende Inhalte:
                                            </v-card-text>
                                        </v-card>

                                        <div
                                            v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                            :key="index"
                                        >

                                            <v-card 
                                                class="mb-4"
                                                width="100%"
                                                color="grey lighten-5"
                                                v-if="index == 31"
                                            >
                                            
                                                <v-card-text
                                                    class="title primary--text"
                                                >
                                                    {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                                </v-card-text>

                                                <div class="mx-3">

                                                    <v-radio-group 
                                                        v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                        :row="$vuetify.breakpoint.mdAndUp"
                                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                        :disabled="assessment_content.already_saved"
                                                    >
                                                        <v-radio
                                                            v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                            :key="x"
                                                            :label="label[1]"
                                                            :value="label[0]"
                                                            @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                        ></v-radio>
                                                    </v-radio-group>

                                                </div>
                                            </v-card>

                                            <v-card 
                                                class="mb-4"
                                                width="100%"
                                                color="grey lighten-5"
                                                v-if="index != 31 && index != 34 && icd_female_3_6"
                                            >
                                            
                                                <v-card-text
                                                    class="title primary--text"
                                                >
                                                    {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                                </v-card-text>

                                                <div class="mx-3">

                                                    <v-radio-group 
                                                        v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                        :row="$vuetify.breakpoint.mdAndUp"
                                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                        :disabled="assessment_content.already_saved"
                                                    >
                                                        <v-radio
                                                            v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                            :key="x"
                                                            :label="label[1]"
                                                            :value="label[0]"
                                                            @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                        ></v-radio>
                                                    </v-radio-group>

                                                </div>
                                            </v-card>

                                            <v-card 
                                                class="mb-4"
                                                width="100%"
                                                color="grey lighten-5"
                                                v-if="index == 34 && icd_female_3_6b && icd_female_3_6"
                                            >
                                            
                                                <v-card-text
                                                    class="title primary--text"
                                                >
                                                    {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                                </v-card-text>

                                                <div class="mx-3">

                                                    <v-radio-group 
                                                        v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                        :row="$vuetify.breakpoint.mdAndUp"
                                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                        :disabled="assessment_content.already_saved"
                                                    >
                                                        <v-radio
                                                            v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                            :key="x"
                                                            :label="label[1]"
                                                            :value="label[0]"
                                                            @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                        ></v-radio>
                                                    </v-radio-group>

                                                </div>
                                            </v-card>

                                        </div>
                                    
                                    </div>

                                </v-form>

                            </v-col>


                    </div>

                </v-row>

                <v-row v-if="assessment_content.name == 'sample_client'">

                    <v-col cols="12" sm="12">

                        <v-form 
                            ref="form" 
                            v-model="forms_valid[current_field_chunk]"  
                        >

                            <div v-if="current_field_chunk == 0">

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text 
                                        class="title"
                                    >
                                        Im Folgenden finden Sie Fragen zu Ihrem bisherigen Lebenslauf sowie Ihrer aktuellen Lebenssituation.
                                    </v-card-text>
                                </v-card>

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 1"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Jahre"
                                                lazy-validation
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :rules="[rules_text_field_numbers.required, rules_text_field_numbers.onlyNumbers, rules_text_field_numbers.notNull]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 3 && sample_client_3b"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 6 && sample_client_5b"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Stunden"
                                                lazy-validation
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :rules="[rules_text_field_numbers.required, rules_text_field_numbers.onlyNumbers, rules_text_field_numbers.notNull]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 8 || index == 9"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Anzahl"
                                                lazy-validation
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :rules="[v => v != null || 'Sie müssen einen Wert angeben.', rules_text_field_numbers.onlyNumbers]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 1 && index != 3 && index != 6 && index != 8 && index != 9"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                </div>

                            </div>

                            <div v-if="current_field_chunk == 1">

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text 
                                        class="title"
                                    >
                                        Nachfolgend finden Sie Fragen zu vorherigen und aktuellen psychotherapeutischen und medikamentösen Behandlungen.
                                    </v-card-text>
                                </v-card>

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 14 && sample_client_12b"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 15 && sample_client_12c"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Monate"
                                                lazy-validation
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :rules="[rules_text_field_numbers.required, rules_text_field_numbers.onlyNumbers, rules_text_field_numbers.notNull]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 18 && sample_client_14b"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Name des/der Medikament(e)"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                counter="255"
                                                :rules="[v => !!v || 'Sie müssen einen Wert angeben.', v => v.length <= 255 || 'Sie dürfen nicht mehr als 255 Zeichen eingeben.']"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 19 && sample_client_14c"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Monate"
                                                lazy-validation
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :rules="[rules_text_field_numbers.required, rules_text_field_numbers.onlyNumbers, rules_text_field_numbers.notNull]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 14 && index != 15 && index != 18 && index != 19"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                </div>

                            </div>

                            <div v-if="current_field_chunk == 2">

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text
                                        class="title"
                                    >
                                        Die folgenden Fragen betreffen vergangene Straftaten. Gefragt wird nach begangenen sexuellen Übergriffen an Kindern. Mit sexuellen Übergriffen ist jede sexuelle Handlung und jede versuchte sexuelle Handlung (Berühren, Streicheln, Küssen, Eindringen in den Körper) vor oder an einem Kind gemeint. Das bedeutet, dass es bei dem sexuellen Übergriff körperlichen Kontakt zu dem Kind gegeben haben kann oder auch nicht (beispielsweise Entblößen des eigenen Körpers vor dem Kind).
                                    </v-card-text>
                                </v-card>

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                </div>

                            </div>

                            <div v-if="current_field_chunk == 3">

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text
                                        class="title"
                                    >
                                    Im Folgenden finden Sie Fragen zu Ihrem bisherigen Lebenslauf sowie Ihrer aktuellen Lebenssituation.
                                    </v-card-text>
                                </v-card>

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 27"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 26"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Jahre"
                                                lazy-validation
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :rules="[rules_text_field_numbers.required, rules_text_field_numbers.onlyNumbers, rules_text_field_numbers.notNull, , rules_text_field_numbers.age]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                </div>

                            </div>

                            <div v-if="current_field_chunk == 4">

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text
                                        class="title"
                                    >
                                    Die folgende Frage bezieht sich auf Ihre <i>aktuelle Verurteilung</i>, also die Tat, wegen der Sie sich gerade in Bewährungshilfe oder Führungsaufsicht befinden.
                                    </v-card-text>
                                </v-card>

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                
                                    <v-card-text
                                        class="title primary--text"
                                    >
                                        29. {{ get_chunks_of_field_verbose_names[current_field_chunk][28] }}
                                    </v-card-text>

                                    <div class="mx-3">

                                        <v-radio-group 
                                            v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][28]]"
                                            :row="$vuetify.breakpoint.mdAndUp"
                                            :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                            :disabled="assessment_content.already_saved"
                                        >
                                            <v-radio
                                                v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][28]"
                                                :key="x"
                                                :label="label[1]"
                                                :value="label[0]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][28], index, label[0])"
                                            ></v-radio>
                                        </v-radio-group>

                                    </div>
                                </v-card>

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text
                                        class="title"
                                    >
                                    Die folgenden Fragen betreffen alle <i>vergangenen Straftaten</i>. Also <i>nicht</i> die Tat, wegen der Sie sich gerade in Bewährungshilfe oder Führungsaufsicht befinden.
                                    </v-card-text>
                                </v-card>

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 29"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Vorstrafen"
                                                lazy-validation
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :rules="[v => v != null || 'Sie müssen einen Wert angeben.', rules_text_field_numbers.onlyNumbers]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 30 || index == 31 || index == 33"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 32 && sample_client_26"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Anzahl"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :rules="[v => !!v || 'Sie müssen einen Wert angeben.', rules_text_field_numbers.onlyNumbers]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 34 && sample_client_27"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Anzahl"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :rules="[v => !!v || 'Sie müssen einen Wert angeben.', rules_text_field_numbers.onlyNumbers]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                </div>

                            </div>

                            <div v-if="current_field_chunk == 5">

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text
                                        class="title"
                                    >
                                    Die folgende Frage bezieht sich auf alle Verurteilungen, also auch die Verurteilung, wegen der Sie sich aktuell in Bewährungs- oder Führungsaufsicht befinden.
                                    </v-card-text>
                                </v-card>

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 35"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 36 && sample_client_28"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Anzahl"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :rules="[v => !!v || 'Sie müssen einen Wert angeben.', rules_text_field_numbers.onlyNumbers]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                </div>

                            </div>

                            <div v-if="current_field_chunk == 6">

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text
                                        class="title"
                                    >
                                    Die folgenden Fragen betreffen alle Sexualstraftaten, die Sie bisher vielleicht begangen haben. Auch die Tat, wegen der Sie aktuell 
                                    in Bewährungs- oder Führungsaufsicht sind. Hier sind allerdings nur solche Straftaten gemeint bei denen ein Kind oder eine erwachsene 
                                    Person Opfer der Sexualstraftat wurden. Dazu gehören alle Sexualstraftaten, die körperlichen Kontakt mit dem Opfer einschließen, also 
                                    Berühren, Küssen, Streicheln, Eindringen in den Körper. Es gehören aber auch Taten dazu bei denen das Opfer nicht berührt wurde, aber 
                                    einem sexuellen Missbrauch ausgesetzt war, z.B. das Entblößen der Geschlechtsteile vor einem Kind oder Erwachsenen, das heimliche 
                                    Beobachten von Personen beim Geschlechtsverkehr, das Herstellen kinderpornografischer Bilder oder Videos und der sexuelle Verkehr 
                                    mit einer toten Person.
                                    </v-card-text>
                                </v-card>

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                    >
                                    
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <v-radio
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                    :label="label[1]"
                                                    :value="label[0]"
                                                    @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                ></v-radio>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                </div>

                            </div>

                        </v-form>

                    </v-col>

                </v-row>

                <!-- HIER DIE NORMALEN FRAGEBÖGEN --- NICHT adaptiv, nur choices -->
                <v-row v-if="assessment_content.name != 'sample_client' && assessment_content.name != 'icd' && assessment_content.name != 'phq' && assessment_content.name != 'soi' && assessment_content.name != 'aoo'">

                    <v-col cols="12" sm="12">

                        <v-form 
                            ref="form" 
                            v-model="forms_valid[current_field_chunk]"  
                        >

                            <v-card 
                                class="mb-4"
                                width="100%"
                                color="grey lighten-5"
                                v-if="assessment_content.name == 'ctq'"
                            >
                                <v-card-text 
                                    class="title"
                                >
                                    Während meiner Kindheit und Jugend ...
                                </v-card-text>
                            </v-card>

                            <v-card 
                                class="mb-4"
                                width="100%"
                                color="grey lighten-5"
                                v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                :key="index"
                            >
                                <v-card-text
                                    class="title primary--text"
                                >
                                    {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                </v-card-text>

                                <div class="mx-3">

                                    <v-radio-group 
                                        v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                        :row="$vuetify.breakpoint.mdAndUp"
                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                        :disabled="assessment_content.already_saved"
                                    >
                                        <v-radio
                                            v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                            :key="x"
                                            :label="label[1]"
                                            :value="label[0]"
                                            @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                        ></v-radio>
                                    </v-radio-group>

                                </div>
                            </v-card>

                        </v-form>

                    </v-col>

                </v-row>

                <!-- Copyright -->
                <v-row>

                    <v-col cols="12" sm="12">
                        <v-row align="center" justify="center">
                            <div class="caption">&copy; {{ get_correct_copyright(assessment_content.name) }}</div>
                        </v-row>
                    </v-col>

                </v-row>

            </div>

            <AssessmentEnd v-if="$route.params.step > all_assessments.length" :timepoint="$route.params.timepoint" />

                
            <!-- Navigation -->
            <v-row class="mb-2 mt-3">
                <v-btn 
                    @click="goBack()"
                    outlined
                    rounded
                    large
                    color="secondary" 
                    class="headline" 
                    style="text-transform: none !important"
                    :disabled="overall_current_position == 0"
                    v-if="current_field_chunk != 0"
                >
                    Zurück
                </v-btn>

                <v-spacer />

                <v-btn 
                    @click="goForward()"
                    outlined
                    rounded
                    large
                    color="primary" 
                    class="headline ml-2" 
                    style="text-transform: none !important"
                >
                    Weiter
                </v-btn>
            </v-row>

        </div>
        
    </v-container>

</template>

<script>
import router from '@/router'

import ClientApi from '@/_api/client_api'

import AssessmentStart from '@/components/_client/_assessment/assessment_start_single'
import AssessmentEnd from '@/components/_client/_assessment/assessment_end_single'


export default {
    name: 'client-assessment-single-questionnaire',

    data: () => ({

        post: false,

        panel: 0,

        forms_valid: [],

        current_field_chunk: 0,

        rules_text_field_numbers: {

            required: value => !!value || "Sie müssen einen Wert angeben.",

            onlyNumbers: value => {
                const pattern = /^[0-9]*$/;
                return (
                    pattern.test(value) ||
                    "Sie müssen eine Zahl eingeben."
                );
            },

            notNull: value => {
                const pattern = /^[1-9][0-9]*$/;
                return (
                    pattern.test(value) ||
                    "Sie müssen eine Zahl größer als 0 angeben."
                );
            },

            age: value => value >= 18 || "Sie müssen einen Wert größer oder gleich 18 Jahre angeben.",

        },

        //aoo
        aoo1_value: false,
        aoo2_value: -1,
        max: 100,
        min: -1,

        //soi
        soi1_value: -1,
        soi2_value: -1,
        soi_min: -1,
        soi_max: 100,

        // phq
        phq_angst: false,
        phq_nervous: false,
        phq_eat: false,
        phq_eat_last: false,
        phq_alc: false,
        phq_woman: false,

        // icd_male
        icd_male_1_1: false,
        icd_male_1_2: false,
        icd_male_1_3: false,
        icd_male_1_4: false,
        icd_male_1_5: false,
        icd_male_2_2a: false,
        icd_male_2_4a: false,
        icd_male_3_1: false,
        icd_male_3_1b: false,
        icd_male_3_2: false,
        icd_male_3_2b: false,
        icd_male_3_3: false,
        icd_male_3_3b: false,
        icd_male_3_4: false,
        icd_male_3_4b: false,
        icd_male_3_5: false,
        icd_male_3_5b: false,
        icd_male_3_6: false,
        icd_male_3_6b: false,

        // icd_female
        icd_female_2_2a: false,
        icd_female_2_4a: false,
        icd_female_3_1: false,
        icd_female_3_1b: false,
        icd_female_3_2: false,
        icd_female_3_2b: false,
        icd_female_3_3: false,
        icd_female_3_3b: false,
        icd_female_3_4: false,
        icd_female_3_4b: false,
        icd_female_3_5: false,
        icd_female_3_5b: false,
        icd_female_3_6: false,
        icd_female_3_6b: false,

        // client_sample
        sample_client_3b: false,
        sample_client_5b: false,
        sample_client_12b: false,
        sample_client_12c: false,
        sample_client_14b: false,
        sample_client_14c: false,
        sample_client_26: false,
        sample_client_27: false,
        sample_client_28: false,
        

    }),

    components: {

        AssessmentStart,
        AssessmentEnd

    },

    created() {

        this.post = false

        this.current_field_chunk = 0

        this.forms_valid = []

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        const data = {

                'module': parseInt(this.$route.params.module),
                'session': parseInt(this.$route.params.session),
                'lection': parseInt(this.$route.params.lection),
                'timepoint': parseInt(this.$route.params.timepoint),
                'name': this.$route.params.name

            }

        ClientApi.GetSingleQuestionnaire(data).then(() => {

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

            this.add_is_validated()

            this.post = true

        })


    },

    watch: {

        'current_field_chunk' () {

            this.$nextTick(() => {

                if (this.current_field_chunk != 0) {
                    this.$vuetify.goTo('#progress');
                    return;
                }

            })

        },

        '$route' () {

            this.current_field_chunk = 0

            this.forms_valid = []

            this.post = false

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

            const data = {

                'module': parseInt(this.$route.params.module),
                'session': parseInt(this.$route.params.session),
                'lection': parseInt(this.$route.params.lection),
                'timepoint': parseInt(this.$route.params.timepoint),
                'name': this.$route.params.name

            }

            ClientApi.GetSingleQuestionnaire(data).then(() => {

                this.$vuetify.goTo(0)

                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                this.add_is_validated()

                this.post = true

            })


        }

    },

    computed: {

        all_assessments() {
            return this.$store.getters['store_assessment/get_all_assessments_in_module']
        },

        assessment_content() {

            return this.$store.getters['store_assessment/get_current_assessment_content']
        },

        overall_current_position() {
           return this.$route.params.step
        },

        progress_value() {

            return ((this.current_field_chunk + 1)/this.get_chunks_of_field_names.length)*100

        },

        get_chunks_of_field_choices () {
            /**
            * Summary. Divide all fields of questionnaire in chuncks of 3 fields
             */

            var final = [];
            var counter = 0;
            var portion = {};

            // do it only if step > 0
            if (this.$route.params.step != 0) {

                if (this.assessment_content.name == 'phq') {

                    var ranges = [
                        [0, 12],    // 1
                        [13, 21],   // 2
                        [22, 36],   // 3 + 4
                        [37, 43],   //5
                        [44, 51],   // 6 + 7 + 8
                        [52, 57],   // 9 + 10
                        [58, 58],   // 11
                        [59, 68],   // 12
                        [69, 77]   // 13 + 14 + 15 + 16 (item 72 - 77 nur Frauen)

                    ];

                    var values = Object.values(this.assessment_content.fields.field_choices);
                    var keys =  Object.keys(this.assessment_content.fields.field_choices);

                    ranges.forEach(function (range) {

                        for (var i = range[0]; i <= range[1]; i++) {

                            portion[keys[i]] = values[i];

                        }

                        final.push(portion);

                        portion = {}

                    });

                } else if(this.assessment_content.name == 'icd') {

                    // MALE
                    if (this.assessment_content.fields.field_names.length == 55) {

                        ranges = [
                            [0, 4],    // 1_1
                            [5, 9],   // 1_2
                            [10, 14],   // 1_3
                            [15, 19],   // 1_4
                            [20, 24],   // 1_5
                            [25, 30],   // 2
                            [31, 34],   // 3_1
                            [35, 38],   // 3_2
                            [39, 42],   // 3_3
                            [43, 46],   // 3_4
                            [47, 50],   // 3_5
                            [51, 54],   // 3_6

                        ];

                    // FEMALE
                    } else {

                        ranges = [
                            [0, 4],    // 1
                            [5, 10],   // 2
                            [11, 14],   // 3_1
                            [15, 18],   // 3_2
                            [19, 22],   // 3_3
                            [23, 26],   // 3_4
                            [27, 30],   // 3_5
                            [31, 34],   // 3_6

                        ];
                    }

                    values = Object.values(this.assessment_content.fields.field_choices);
                    keys =  Object.keys(this.assessment_content.fields.field_choices);

                    ranges.forEach(function (range) {

                        for (var i = range[0]; i <= range[1]; i++) {

                            portion[keys[i]] = values[i];

                        }

                        final.push(portion);

                        portion = {}

                    });

                } else if (this.assessment_content.name == 'sample_client') {

                    ranges = [
                        [0, 10],    // 1
                        [11, 20],   // 2
                        [21, 25],   // 3 + 4
                        [26, 27],
                        [28, 34],
                        [35, 36],
                        [37, 39]

                    ];

                    values = Object.values(this.assessment_content.fields.field_choices);
                    keys =  Object.keys(this.assessment_content.fields.field_choices);

                    ranges.forEach(function (range) {

                        for (var i = range[0]; i <= range[1]; i++) {

                            portion[keys[i]] = values[i];

                        }

                        final.push(portion);

                        portion = {}

                    });

                } else {

                    values = Object.values(this.assessment_content.fields.field_choices);

                    for (var key in this.assessment_content.fields.field_choices) {
                        if (counter !== 0 && counter % 3 === 0) {
                            final.push(portion);
                            portion = {};
                        }
                        
                        portion[key] = values[counter];
                        counter++
                    }

                    final.push(portion);

                    }


                }

            return final;

            
        },

        get_chunks_of_field_names () {
            /**
            * Summary. Divide all fields of questionnaire in chuncks of 3 fields
             */
            
            var final = [];
            var counter = 0;
            var portion = {};

            // do it only if step > 0
            if (this.$route.params.step != 0) {

                if (this.assessment_content.name == 'phq') {

                    var ranges = [
                        [0, 12],    // 1
                        [13, 21],   // 2
                        [22, 36],     //3 + 4
                        [37, 43],   //5
                        [44, 51],   // 6 + 7 + 8
                        [52, 57],   // 9 + 10
                        [58, 58],   // 11
                        [59, 68],   // 12
                        [69, 77]   // 13 + 14 + 15 + 16 (item 72 - 77 nur Frauen)

                    ];

                    var values = Object.values(this.assessment_content.fields.field_names);
                    var keys =  Object.keys(this.assessment_content.fields.field_names);

                    ranges.forEach(function (range) {

                        for (var i = range[0]; i <= range[1]; i++) {

                            portion[keys[i]] = values[i];

                        }

                        final.push(portion);

                        portion = {}

                    });

                } else if(this.assessment_content.name == 'icd') {

                    // MALE
                    if (this.assessment_content.fields.field_names.length == 55) {

                        ranges = [
                            [0, 4],    // 1_1
                            [5, 9],   // 1_2
                            [10, 14],   // 1_3
                            [15, 19],   // 1_4
                            [20, 24],   // 1_5
                            [25, 30],   // 2
                            [31, 34],   // 3_1
                            [35, 38],   // 3_2
                            [39, 42],   // 3_3
                            [43, 46],   // 3_4
                            [47, 50],   // 3_5
                            [51, 54],   // 3_6

                        ];

                    // FEMALE
                    } else {

                        ranges = [
                            [0, 4],    // 1
                            [5, 10],   // 2
                            [11, 14],   // 3_1
                            [15, 18],   // 3_2
                            [19, 22],   // 3_3
                            [23, 26],   // 3_4
                            [27, 30],   // 3_5
                            [31, 34],   // 3_6

                        ];
                    }

                    values = Object.values(this.assessment_content.fields.field_names);
                    keys =  Object.keys(this.assessment_content.fields.field_names);

                    ranges.forEach(function (range) {

                        for (var i = range[0]; i <= range[1]; i++) {

                            portion[keys[i]] = values[i];

                        }

                        final.push(portion);

                        portion = {}

                    });

                } else if (this.assessment_content.name == 'sample_client') {

                    ranges = [
                        [0, 10],    // 1
                        [11, 20],   // 2
                        [21, 25],   // 3 + 4
                        [26, 27],
                        [28, 34],
                        [35, 36],
                        [37, 39]

                    ];

                    values = Object.values(this.assessment_content.fields.field_names);
                    keys =  Object.keys(this.assessment_content.fields.field_names);

                    ranges.forEach(function (range) {

                        for (var i = range[0]; i <= range[1]; i++) {

                            portion[keys[i]] = values[i];

                        }

                        final.push(portion);

                        portion = {}

                    });


                } else {

                    values = Object.values(this.assessment_content.fields.field_names);

                    for (var key in this.assessment_content.fields.field_names) {
                        if (counter !== 0 && counter % 3 === 0) {
                            final.push(portion);
                            portion = {};
                        }
                        
                        portion[key] = values[counter];
                        counter++
                    }

                    final.push(portion);

                    }

                }

            return final;
        },

        get_chunks_of_field_verbose_names () {
            /**
            * Summary. Divide all fields of questionnaire in chuncks of 3 fields
             */
            
            var final = [];
            var counter = 0;
            var portion = {};

            // do it only if step > 0
            if (this.$route.params.step != 0) {

                if (this.assessment_content.name == 'phq') {

                    var ranges = [
                        [0, 12],    // 1
                        [13, 21],   // 2
                        [22, 36],     // 3 + 4
                        [37, 43],   //5
                        [44, 51],   // 6 + 7 + 8
                        [52, 57],   // 9 + 10
                        [58, 58],   // 11
                        [59, 68],   // 12
                        [69, 77]   // 13 + 14 + 15 + 16 (item 72 - 77 nur Frauen)

                    ];

                    var values = Object.values(this.assessment_content.fields.field_verbose_names);
                    var keys =  Object.keys(this.assessment_content.fields.field_verbose_names);

                    ranges.forEach(function (range) {

                        for (var i = range[0]; i <= range[1]; i++) {

                            portion[keys[i]] = values[i];

                        }

                        final.push(portion);

                        portion = {}

                    });

                } else if(this.assessment_content.name == 'icd') {

                    // MALE
                    if (this.assessment_content.fields.field_names.length == 55) {

                        ranges = [
                            [0, 4],    // 1_1
                            [5, 9],   // 1_2
                            [10, 14],   // 1_3
                            [15, 19],   // 1_4
                            [20, 24],   // 1_5
                            [25, 30],   // 2
                            [31, 34],   // 3_1
                            [35, 38],   // 3_2
                            [39, 42],   // 3_3
                            [43, 46],   // 3_4
                            [47, 50],   // 3_5
                            [51, 54],   // 3_6

                        ];

                    // FEMALE
                    } else {

                        ranges = [
                            [0, 4],    // 1
                            [5, 10],   // 2
                            [11, 14],   // 3_1
                            [15, 18],   // 3_2
                            [19, 22],   // 3_3
                            [23, 26],   // 3_4
                            [27, 30],   // 3_5
                            [31, 34],   // 3_6

                        ];
                    }

                    values = Object.values(this.assessment_content.fields.field_verbose_names);
                    keys =  Object.keys(this.assessment_content.fields.field_verbose_names);

                    ranges.forEach(function (range) {

                        for (var i = range[0]; i <= range[1]; i++) {

                            portion[keys[i]] = values[i];

                        }

                        final.push(portion);

                        portion = {}

                    });

                } else if (this.assessment_content.name == 'sample_client') {

                    ranges = [
                        [0, 10],    // 1
                        [11, 20],   // 2
                        [21, 25],   // 3 + 4
                        [26, 27],
                        [28, 34],
                        [35, 36],
                        [37, 39]

                    ];

                    values = Object.values(this.assessment_content.fields.field_verbose_names);
                    keys =  Object.keys(this.assessment_content.fields.field_verbose_names);

                    ranges.forEach(function (range) {

                        for (var i = range[0]; i <= range[1]; i++) {

                            portion[keys[i]] = values[i];

                        }

                        final.push(portion);

                        portion = {}

                    });

                } else {

                    values = Object.values(this.assessment_content.fields.field_verbose_names);

                    for (var key in this.assessment_content.fields.field_verbose_names) {
                        if (counter !== 0 && counter % 3 === 0) {
                            final.push(portion);
                            portion = {};
                        }
                        
                        portion[key] = values[counter];
                        counter++
                    }

                    final.push(portion);

                    }

                }

            return final;
        },

        get_chunks_of_field_data () {
            /**
            * Summary. Divide all fields of questionnaire in chuncks of 3 fields
             */
            
            var final = [];
            var counter = 0;
            var portion = {};

            // do it only if step > 0
            if (this.$route.params.step != 0) {

                if (this.assessment_content.name == 'phq') {

                    var ranges = [
                        [0, 12],    // 1
                        [13, 21],   // 2
                        [22, 36],     // 3 + 4
                        [37, 43],   //5
                        [44, 51],   // 6 + 7 + 8
                        [52, 57],   // 9 + 10
                        [58, 58],   // 11
                        [59, 68],   // 12
                        [69, 77]   // 13 + 14 + 15 + 16 (item 72 - 77 nur Frauen)

                    ];

                    var values = Object.values(this.assessment_content.fields.data);
                    var keys =  Object.keys(this.assessment_content.fields.data);

                    ranges.forEach(function (range) {

                        for (var i = range[0]; i <= range[1]; i++) {

                            portion[keys[i]] = values[i];

                        }

                        final.push(portion);

                        portion = {}

                    });

                } else if(this.assessment_content.name == 'icd') {

                    // MALE
                    if (this.assessment_content.fields.field_names.length == 55) {

                        ranges = [
                            [0, 4],    // 1_1
                            [5, 9],   // 1_2
                            [10, 14],   // 1_3
                            [15, 19],   // 1_4
                            [20, 24],   // 1_5
                            [25, 30],   // 2
                            [31, 34],   // 3_1
                            [35, 38],   // 3_2
                            [39, 42],   // 3_3
                            [43, 46],   // 3_4
                            [47, 50],   // 3_5
                            [51, 54],   // 3_6

                        ];

                    // FEMALE
                    } else {

                        ranges = [
                            [0, 4],    // 1
                            [5, 10],   // 2
                            [11, 14],   // 3_1
                            [15, 18],   // 3_2
                            [19, 22],   // 3_3
                            [23, 26],   // 3_4
                            [27, 30],   // 3_5
                            [31, 34],   // 3_6

                        ];
                    }

                    values = Object.values(this.assessment_content.fields.data);
                    keys =  Object.keys(this.assessment_content.fields.data);

                    ranges.forEach(function (range) {

                        for (var i = range[0]; i <= range[1]; i++) {

                            portion[keys[i]] = values[i];

                        }

                        final.push(portion);

                        portion = {}

                    });

                } else if (this.assessment_content.name == 'sample_client') {

                    ranges = [
                        [0, 10],    // 1
                        [11, 20],   // 2
                        [21, 25],   // 3 + 4
                        [26, 27],
                        [28, 34],
                        [35, 36],
                        [37, 39]

                    ];

                    values = Object.values(this.assessment_content.fields.data);
                    keys =  Object.keys(this.assessment_content.fields.data);

                    ranges.forEach(function (range) {

                        for (var i = range[0]; i <= range[1]; i++) {

                            portion[keys[i]] = values[i];

                        }

                        final.push(portion);

                        portion = {}

                    });

                } else {

                    values = Object.values(this.assessment_content.fields.data);

                    for (var key in this.assessment_content.fields.data) {
                        if (counter !== 0 && counter % 3 === 0) {
                            final.push(portion);
                            portion = {};
                        }
                        
                        portion[key] = values[counter];
                        counter++
                    }

                    final.push(portion);

                    }

            }

            return final;
        },

    },

    methods: {

        get_correct_copyright(name) {

            var txt = ''

            if (name == 'aoo') {

                txt = 'Tozdan & Briken, 2015'

            } else if (name == 'acute') {

                txt = 'Kriminologische Zentralstelle (KrimZ), 2020'
            
            } else if (name == 'bis') {

                txt = 'Meule, Vögele & Kübler, 2011'            
            
            } else if (name == 'cmc') {

                txt = 'Kriminologische Zentralstelle (KrimZ), 2020'
            
            } else if (name == 'cte') {

                txt = 'Kriminologische Zentralstelle (KrimZ), 2020'

             } else if (name == 'ctq') {

                txt = 'Bernstein et al., 2003'
            
            } else if (name == 'cusi') {

                txt = 'Cortoni & Marshall, 2001; deutsche Übersetzung: @myTabu'
            
            } else if (name == 'cvtrq') {

                txt = 'Casey, Day, Howells & Ward, 2007; deutsche Übersetzung: @myTabu'
            
            } else if (name == 'ders') {

                txt = 'Gratz & Roemer, 2004; deutsche Übersetzung: Ehring, Fischer, Schnülle, Bösterling & Tuschen-Caffier, 2008'
            
            } else if (name == 'ekk') {

                txt = 'Mack & Yundina, 2012'
            
            } else if (name == 'esiq') {

                txt = 'Banse, Schmidt & Clabour, 2010  '
            
            } else if (name == 'exsik') {

                txt = 'Tozdan, Jakob, Schuhmann, Budde & Briken, 2015'
            
            } else if (name == 'fsozu') {

                txt = 'Mit freundlicher Genehmigung der Hogrefe Verlag GmbH & Co. KG © Hogrefe Verlag GmbH & Co. KG, Göttingen. Nachdruck und jegliche Art der Vervielfältigung verboten.'
            
            } else if (name == 'flashlight_pre') {

                txt = '@myTabu'
            
            } else if (name == 'flashlight_post') {

                txt = '@myTabu'
            
            } else if (name == 'flexsik') {

                txt = 'Tozdan, Jakob, Schuhmann, Budde & Briken, 2015'
            
            } else if (name == 'hbi') {

                txt = 'Reid, Garos & Carpenter, 2011; deutsche Übersetzung: Klein, Rettenberger, Boom & Briken, 2013'
            
            } else if (name == 'icd') {

                txt = 'Briken, 2019'
            
            } else if (name == 'kvm') {

                txt = 'Feelgood, Schaefer & Hoyer, 2008, https://creativecommons.org/licenses/by-nc-nd/3.0/de/, keine Änderungen vorgenommen.'
            
            } else if (name == 'motsik') {

                txt = 'Tozdan, Jakob, Schuhmann, Budde & Briken, 2015'
            
            } else if (name == 'narq') {

                txt = 'Scherer, Eberle, Boecker, Vöggele, Gauggel & Forkmann, 2013'
            
            } else if (name == 'ors') {

                txt = 'Herzberg, 2002'
            
            } else if (name == 'phq') {

                txt = 'deutsche Übersetzung: Löwe, Spitzer, Zipfel & Herzog, 2002'
            
            } else if (name == 'rcq') {

                txt = 'Rollnick, Heather, Gold & Hall, 1992; deutsche Übersetzung: Hannöver, Thyrian, Hapke, Rumpf, Meyer & John, 2002'
            
            } else if (name == 'sample_client') {

                txt = '@myTabu'
            
            } else if (name == 'sbv') {

                txt = 'Endres & Oranu, 2010'
            
            } else if (name == 'soi') {

                txt = 'Briken, 2010'
            
            } else if (name == 'spsi') {

                txt = '1996, 2002 Multi-Health Systems Inc. All rights reserved. Reproduced only with Permission from MHS – permissions@mhs.com  '
            
            } else if (name == 'ssik') {

                txt = 'Tozdan, Jakob, Schuhmann, Budde & Briken, 2015'
            
            } else if (name == 'supervisor_sample') {

                txt = '@myTabu'
            
            } else if (name == 'repeated_supervisor_sample') {

                txt = '@myTabu'

            } else if (name == 'ucla') {

                txt = 'Bilsky & Hosser, 1998'

            } else if (name == 'utaut') {

                txt = '@myTabu'
            
            } else if (name == 'wai') {

                txt = 'Horvath & Greenberg 1986, 1989; deutsche Übersetzung: Wilmers, Munder, Leonhart, Herzog, Plassmann, Barth & Linster, 2008'
            
            } else if (name == 'wai_sv') {

                txt = 'Horvath & Greenberg 1986, 1989; deutsche Übersetzung: Wilmers, Munder, Leonhart, Herzog, Plassmann, Barth & Linster, 2008'
            
            } else if (name == 'who') {

                txt = 'Bech, 2012; deutsche Übersetzung: Brähler, Mühlan, Albani & Schmidt, 2007'

            } else {

                txt = ''

            }

            return txt

        },

        update_aoo_1_value() {

            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': this.aoo1_value, 'key': 'aoo_1'})

            this.aoo2_value = -1
            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -1, 'key': 'aoo_2'})

        },

        update_aoo_2_value() {

            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': this.aoo1_value, 'key': 'aoo_1'})

            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': this.aoo2_value, 'key': 'aoo_2'})

        },

        update_soi_1_value(index) {

            this.update_current_field_value(this.get_chunks_of_field_names[this.current_field_chunk][index], index, this.soi1_value)

        },

        update_soi_2_value(index) {

            this.update_current_field_value(this.get_chunks_of_field_names[this.current_field_chunk][index], index, this.soi2_value)

        },

        add_is_validated() {

            for (var i = 0; i < this.get_chunks_of_field_names.length; i++) {

                this.forms_valid.push(true)
            }
        },

        goBack() {

            if (this.$route.params.step > 0) {

                if (this.current_field_chunk > 0) {

                    // go next    
                    this.current_field_chunk = this.current_field_chunk - 1

                } else if (this.current_field_chunk == 0) {
            
                    router.push({ name: 'client-polls' })

                }

            }

        },

        goForward() {

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

            if (this.$route.params.step == 0) {

                // show FIRST questionnaire
                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_CONTENT', this.$store.getters['store_assessment/get_all_assessments_in_module'][this.overall_current_position])
                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA', this.$store.getters['store_assessment/get_all_assessments_in_module'][this.overall_current_position].fields.data)
            
                router.push({ name: 'client-polls-single', params: { module: this.$route.params.module, session: this.$route.params.session, lection: this.$route.params.lection, timepoint: this.$route.params.timepoint, name:this.$route.params.name, step: parseInt(this.$route.params.step) + 1 } })


            } else if (this.$route.params.step > 0 && this.$route.params.step < this.all_assessments.length) {

                if (this.current_field_chunk < this.get_chunks_of_field_names.length - 1) {

                    // validate form
                    if (this.$refs.form.validate()) {

                        // go next    
                        this.current_field_chunk = this.current_field_chunk + 1
                        //this.$refs.form.resetValidation()

                    }  else {

                        this.$nextTick(() => {
                            const el = this.$el.querySelector(".v-messages__message:first-of-type");

                            var rect = el.getBoundingClientRect()
                            var scrollTop = window.pageYOffset || document.documentElement.scrollTop
                            var top = rect.top + scrollTop

                            this.$vuetify.goTo(top - 100);
                            return;
                        });
                    }
                    
                } else if (this.current_field_chunk == this.get_chunks_of_field_names.length - 1) {

                    // save current questionnaire
                    if (this.$refs.form.validate()) {

                        const data = {

                            'module': parseInt(this.$route.params.module),
                            'session': parseInt(this.$route.params.session),
                            'lection': parseInt(this.$route.params.lection),
                            'name': this.assessment_content.name

                        }

                        if (this.assessment_content.already_saved == false) {

                            ClientApi.SaveAssessmentData(data).then(() => {

                                const data = {

                                    'module': parseInt(this.$route.params.module),
                                    'session': parseInt(this.$route.params.session),
                                    'lection': parseInt(this.$route.params.lection),
                                    'timepoint': parseInt(this.$route.params.timepoint),
                                    'name': this.assessment_content.name

                                }

                                ClientApi.UpdateOpenAssessments(data).then(() => {
                                
                                    router.push({ name: 'client-polls-single', params: { module: this.$route.params.module, session: this.$route.params.session, lection: this.$route.params.lection, timepoint: this.$route.params.timepoint, name:this.$route.params.name, step: parseInt(this.$route.params.step) + 1 } })

                                })

                            })

                        } else {

                            router.push({ name: 'client-polls-single', params: { module: this.$route.params.module, session: this.$route.params.session, lection: this.$route.params.lection, timepoint: this.$route.params.timepoint, name:this.$route.params.name, step: parseInt(this.$route.params.step) + 1 } })

                        }

                    }  else {

                        this.$nextTick(() => {
                            const el = this.$el.querySelector(".v-messages__message:first-of-type");

                            var rect = el.getBoundingClientRect()
                            var scrollTop = window.pageYOffset || document.documentElement.scrollTop
                            var top = rect.top + scrollTop

                            this.$vuetify.goTo(top - 100);
                            return;
                        });
                    }

                }

            } else if (this.$route.params.step == this.all_assessments.length) {

                if (this.current_field_chunk < this.get_chunks_of_field_names.length - 1) {

                    // validate form
                    if (this.$refs.form.validate()) {

                        // go next    
                        this.current_field_chunk = this.current_field_chunk + 1
                        //this.$refs.form.resetValidation()

                    } else {

                        this.$nextTick(() => {
                            const el = this.$el.querySelector(".v-messages__message:first-of-type");

                            var rect = el.getBoundingClientRect()
                            var scrollTop = window.pageYOffset || document.documentElement.scrollTop
                            var top = rect.top + scrollTop

                            this.$vuetify.goTo(top - 100);
                            return;
                        });
                    }
                    
                } else if (this.current_field_chunk == this.get_chunks_of_field_names.length - 1) {

                    if (this.$refs.form.validate()) {

                        const data = {

                            'module': parseInt(this.$route.params.module),
                            'session': parseInt(this.$route.params.session),
                            'lection': parseInt(this.$route.params.lection),
                            'name': this.assessment_content.name

                        }

                        if (this.assessment_content.already_saved == false) {

                            ClientApi.SaveAssessmentData(data).then(() => {

                                const data = {

                                    'module': parseInt(this.$route.params.module),
                                    'session': parseInt(this.$route.params.session),
                                    'lection': parseInt(this.$route.params.lection),
                                    'timepoint': parseInt(this.$route.params.timepoint),
                                    'name': this.assessment_content.name

                                }

                                ClientApi.UpdateOpenAssessments(data).then(() => {

                                    router.push({ name: 'client-polls-single', params: { module: this.$route.params.module, session: this.$route.params.session, lection: this.$route.params.lection, timepoint: this.$route.params.timepoint, name:this.$route.params.name, step: parseInt(this.$route.params.step) + 1 } })

                                })
                            })

                        } else {

                            router.push({ name: 'client-polls-single', params: { module: this.$route.params.module, session: this.$route.params.session, lection: this.$route.params.lection, timepoint: this.$route.params.timepoint, name:this.$route.params.name, step: parseInt(this.$route.params.step) + 1 } })

                        }

                    }  else {

                        this.$nextTick(() => {
                            const el = this.$el.querySelector(".v-messages__message:first-of-type");

                            var rect = el.getBoundingClientRect()
                            var scrollTop = window.pageYOffset || document.documentElement.scrollTop
                            var top = rect.top + scrollTop

                            this.$vuetify.goTo(top - 100);
                            return;
                        });
                    }

                }

            } else {

                // go to overview
                this.$router.push({ name: 'client-polls'})

            
            }

             this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

        },

        update_current_field_value(field, index, value) {

            // PHQ-D
            if (this.assessment_content.name == 'phq') {

                if (this.current_field_chunk == 2) {

                    // Ja
                    if (index == 22 && value == 1) {

                        this.phq_angst = true

                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                        for (var i = 23; i <= 36; i++) {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                        }

                    // Nein
                    } else if (index == 22 && value == 0) {

                        this.phq_angst = false

                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                        // define alle angst-items to NO
                        //key = get_chunks_of_field_names[current_field_chunk][index]
                        for (i = 23; i <= 36; i++) {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                        }

                    } else {
                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field}) 
                    }

                } else if (this.current_field_chunk == 3) {

                   if (index == 37 && value == 0) {

                        this.phq_nervous = false

                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                        for (i = 38; i <= 43; i++) {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                        }

                    } else if (index == 37 && (value == 1 || value == 2 )) {

                        this.phq_nervous = true

                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                        for (i = 38; i <= 43; i++) {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                        }

                    } else {
                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field}) 
                    } 

                } else if (this.current_field_chunk == 4) {

                    if (index == 44 && value == 0) {

                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                        this.phq_eat = false

                        for (i = 46; i <= 51; i++) {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                        }

                    } else if (index == 45 && value == 0) {

                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                        this.phq_eat = false

                        for (i = 46; i <= 51; i++) {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                        }

                    } else if (index == 44 && value == 1 && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][45]] == 1) {

                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                        this.phq_eat = true

                        for (i = 46; i <= 51; i++) {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                        }

                    } else if (index == 45 && value == 1 && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][44]] == 1) {

                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                        this.phq_eat = true

                        for (i = 46; i <= 51; i++) {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                        }

                    } else {
                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field}) 
                    } 

                    if ((index == 47 && value == 1) || (index == 48 && value == 1) || (index == 49 && value == 1) || (index == 50 && value == 1)) {
                        this.phq_eat_last = true
                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][51]})
                    } else if ((index == 47 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][48]] == 0 && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][49]] == 0 && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][50]] == 0) {
                        this.phq_eat_last = false
                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][51]})
                    } else if ((index == 48 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][47]] == 0 && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][49]] == 0 && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][50]] == 0) {
                        this.phq_eat_last = false
                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][51]})
                    } else if ((index == 49 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][48]] == 0 && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][47]] == 0 && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][50]] == 0) {
                        this.phq_eat_last = false
                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][51]})
                    } else if ((index == 50 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][48]] == 0 && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][49]] == 0 && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][47]] == 0) {
                        this.phq_eat_last = false
                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][51]})
                    }

                } else if (this.current_field_chunk == 5) {

                    if (index == 52 && value == 0) {

                        this.phq_alc = false

                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                        for (i = 53; i <= 57; i++) {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                        }

                    } else if (index == 52 && value == 1) {

                        this.phq_alc = true

                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                        for (i = 53; i <= 57; i++) {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                        }

                    } else {
                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field}) 
                    } 

                } else if (this.current_field_chunk == 8) {

                    if (index == 71) {

                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                        // if male
                        if (this.$store.getters['store_client/get_client'].client_gender == 1) {

                            this.phq_woman = false

                            for (i = 72; i <= 77; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else {

                            this.phq_woman = true

                            for (i = 72; i <= 77; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        }

                    } else if (index == 70) {

                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': value, 'key': field})

                    } else {
                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})
                    }


                } else {
                    this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field}) 
                }

            /**
             * ICD-Screener
             */
            } else if (this.assessment_content.name == 'icd') {

                // male
                if (this.assessment_content.fields.field_names.length == 55) {

                    if (this.current_field_chunk == 0) {

                        if (index == 0 && value == 0) {

                            this.icd_male_1_1 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 1; i <= 4; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if (index == 0 && value == 1) {

                            this.icd_male_1_1 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 1; i <= 4; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})
                        }

                    } else if (this.current_field_chunk == 1) {

                        if (index == 5 && value == 0) {

                            this.icd_male_1_2 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 6; i <= 9; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if (index == 5 && value == 1) {

                            this.icd_male_1_2 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 6; i <= 9; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})
                        }


                    } else if (this.current_field_chunk == 2) {

                        if (index == 10 && value == 0) {

                            this.icd_male_1_3 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 11; i <= 14; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if (index == 10 && value == 1) {

                            this.icd_male_1_3 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 11; i <= 14; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})
                        }


                    } else if (this.current_field_chunk == 3) {

                        if (index == 15 && value == 0) {

                            this.icd_male_1_4 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 16; i <= 19; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if (index == 15 && value == 1) {

                            this.icd_male_1_4 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 16; i <= 19; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})
                        }

                    
                    } else if (this.current_field_chunk == 4) {

                        if (index == 20 && value == 0) {

                            this.icd_male_1_5 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 21; i <= 24; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if (index == 20 && value == 1) {

                            this.icd_male_1_5 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 21; i <= 24; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})
                        }

                    } else if (this.current_field_chunk == 5) {

                        if (index == 26 && value != 4) {

                            this.icd_male_2_2a = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})


                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][27]}) 
                            

                        } else if (index == 26 && value == 4) {

                            this.icd_male_2_2a = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][27]}) 

                        } else if (index == 29 && value != 8) {

                            this.icd_male_2_4a = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})


                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][30]}) 
                            

                        } else if (index == 29 && value == 8) {

                            this.icd_male_2_4a = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][30]}) 

                        } else if (index == 27) {

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': value, 'key': field})

                        } else if (index == 30) {

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': value, 'key': field})
                        
                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})
                        }

                    } else if (this.current_field_chunk == 6) {

                        if (index == 31 && value == 0) {

                            this.icd_male_3_1 = false
                            this.icd_male_3_1b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 32; i <= 34; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if (index == 31 && value == 1) {

                            this.icd_male_3_1 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 32; i <= 34; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if ((index == 32 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][33]] == 0) {

                            this.icd_male_3_1b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][34]}) 
                            

                        } else if ((index == 33 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][32]] == 0) {

                            this.icd_male_3_1b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][34]}) 
                            
                        } else if (index == 32 && value == 1) {

                            this.icd_male_3_1b = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][34]}) 
                           
                        
                        } else if (index == 33 && value == 1) {

                            this.icd_male_3_1b = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][34]}) 
                           
                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})
                        }

                    } else if (this.current_field_chunk == 7) {

                        if (index == 35 && value == 0) {

                            this.icd_male_3_2 = false
                            this.icd_male_3_2b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 36; i <= 38; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if (index == 35 && value == 1) {

                            this.icd_male_3_2 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 36; i <= 38; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if ((index == 36 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][37]] == 0) {

                            this.icd_male_3_2b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][38]}) 
                            

                        } else if ((index == 37 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][36]] == 0) {

                            this.icd_male_3_2b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][38]}) 
                            
                        } else if (index == 36 && value == 1) {

                            this.icd_male_3_2b = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][38]}) 
                           
                        
                        } else if (index == 37 && value == 1) {

                            this.icd_male_3_2b = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][38]}) 
                           
                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})
                        }

                    } else if (this.current_field_chunk == 8) {

                        if (index == 39 && value == 0) {

                            this.icd_male_3_3 = false
                            this.icd_male_3_3b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 40; i <= 42; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if (index == 39 && value == 1) {

                            this.icd_male_3_3 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 40; i <= 42; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if ((index == 40 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][41]] == 0) {

                            this.icd_male_3_3b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][42]}) 
                            

                        } else if ((index == 41 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][40]] == 0) {

                            this.icd_male_3_3b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][42]}) 
                            
                        } else if (index == 40 && value == 1) {

                            this.icd_male_3_3b = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][42]}) 
                           
                        
                        } else if (index == 41 && value == 1) {

                            this.icd_male_3_3b = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][42]}) 
                           
                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})
                        }

                    } else if (this.current_field_chunk == 9) {

                        if (index == 43 && value == 0) {

                            this.icd_male_3_4 = false
                            this.icd_male_3_4b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 44; i <= 46; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if (index == 43 && value == 1) {

                            this.icd_male_3_4 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 44; i <= 46; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if ((index == 44 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][45]] == 0) {

                            this.icd_male_3_4b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][46]}) 
                            

                        } else if ((index == 45 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][44]] == 0) {

                            this.icd_male_3_4b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][46]}) 
                            
                        } else if (index == 44 && value == 1) {

                            this.icd_male_3_4b = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][46]}) 
                           
                        
                        } else if (index == 45 && value == 1) {

                            this.icd_male_3_4b = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][46]}) 
                           
                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})
                        }

                    } else if (this.current_field_chunk == 10) {

                        if (index == 47 && value == 0) {

                            this.icd_male_3_5 = false
                            this.icd_male_3_5b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 48; i <= 50; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if (index == 47 && value == 1) {

                            this.icd_male_3_5 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 48; i <= 50; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if ((index == 48 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][49]] == 0) {

                            this.icd_male_3_5b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][50]}) 
                            

                        } else if ((index == 49 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][48]] == 0) {

                            this.icd_male_3_5b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][50]}) 
                            
                        } else if (index == 48 && value == 1) {

                            this.icd_male_3_5b = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][50]}) 
                           
                        
                        } else if (index == 49 && value == 1) {

                            this.icd_male_3_5b = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][50]}) 
                           
                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})
                        }

                    } else if (this.current_field_chunk == 11) {

                        if (index == 51 && value == 0) {

                            this.icd_male_3_6 = false
                            this.icd_male_3_6b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 52; i <= 54; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if (index == 51 && value == 1) {

                            this.icd_male_3_6 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 52; i <= 54; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if ((index == 52 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][53]] == 0) {

                            this.icd_male_3_6b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][54]}) 
                            

                        } else if ((index == 53 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][52]] == 0) {

                            this.icd_male_3_6b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][54]}) 
                            
                        } else if (index == 52 && value == 1) {

                            this.icd_male_3_6b = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][54]}) 
                           
                        
                        } else if (index == 53 && value == 1) {

                            this.icd_male_3_6b = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][54]}) 
                           
                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})
                        }

                    } else {
                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})
                    }

                // female
                } else {

                    if (this.current_field_chunk == 1) {

                        if (index == 6 && value != 4) {

                            this.icd_female_2_2a = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})


                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][7]}) 
                            

                        } else if (index == 6 && value == 4) {

                            this.icd_female_2_2a = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][7]}) 

                        } else if (index == 9 && value != 8) {

                            this.icd_female_2_4a = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})


                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][10]}) 
                            

                        } else if (index == 9 && value == 8) {

                            this.icd_female_2_4a = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][10]}) 

                        } else if (index == 7) {

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': value, 'key': field})

                        } else if (index == 10) {

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': value, 'key': field})
                        
                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})
                        }

                    } else if (this.current_field_chunk == 2) {

                        if (index == 11 && value == 0) {

                            this.icd_female_3_1 = false
                            this.icd_female_3_1b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 12; i <= 14; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if (index == 11 && value == 1) {

                            this.icd_female_3_1 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 12; i <= 14; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if ((index == 12 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][13]] == 0) {

                            this.icd_female_3_1b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][14]}) 
                            

                        } else if ((index == 13 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][12]] == 0) {

                            this.icd_female_3_1b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][14]}) 
                            
                        } else if (index == 12 && value == 1) {

                            this.icd_female_3_1b = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][14]}) 
                           
                        
                        } else if (index == 13 && value == 1) {

                            this.icd_female_3_1b = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][14]}) 
                           

                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})
                        }

                    } else if (this.current_field_chunk == 3) {

                        if (index == 15 && value == 0) {

                            this.icd_female_3_2 = false
                            this.icd_female_3_2b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 16; i <= 18; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if (index == 15 && value == 1) {

                            this.icd_female_3_2 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 16; i <= 18; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if ((index == 16 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][17]] == 0) {

                            this.icd_female_3_2b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][18]}) 
                            

                        } else if ((index == 17 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][16]] == 0) {

                            this.icd_female_3_2b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][18]}) 
                            
                        } else if (index == 16 && value == 1) {

                            this.icd_female_3_2b = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][18]}) 
                           
                        
                        } else if (index == 17 && value == 1) {

                            this.icd_female_3_2b = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][18]}) 
                           

                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})
                        }

                    } else if (this.current_field_chunk == 4) {

                        if (index == 19 && value == 0) {

                            this.icd_female_3_3 = false
                            this.icd_female_3_3b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 20; i <= 22; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if (index == 19 && value == 1) {

                            this.icd_female_3_3 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 20; i <= 22; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if ((index == 20 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][21]] == 0) {

                            this.icd_female_3_3b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][22]}) 
                            

                        } else if ((index == 21 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][22]] == 0) {

                            this.icd_female_3_3b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][22]}) 
                            
                        } else if (index == 20 && value == 1) {

                            this.icd_female_3_3b = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][22]}) 
                           
                        
                        } else if (index == 21 && value == 1) {

                            this.icd_female_3_3b = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][22]}) 
                           

                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})
                        }

                    } else if (this.current_field_chunk == 5) {

                        if (index == 23 && value == 0) {

                            this.icd_female_3_4 = false
                            this.icd_female_3_4b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 24; i <= 26; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if (index == 23 && value == 1) {

                            this.icd_female_3_4 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 24; i <= 26; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if ((index == 24 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][25]] == 0) {

                            this.icd_female_3_4b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][26]}) 
                            

                        } else if ((index == 25 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][24]] == 0) {

                            this.icd_female_3_4b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][26]}) 
                            
                        } else if (index == 24 && value == 1) {

                            this.icd_female_3_4b = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][26]}) 
                           
                        
                        } else if (index == 25 && value == 1) {

                            this.icd_female_3_4b = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][26]}) 
                           

                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})
                        }

                    } else if (this.current_field_chunk == 6) {

                        if (index == 27 && value == 0) {

                            this.icd_female_3_5 = false
                            this.icd_female_3_5b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 28; i <= 30; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if (index == 27 && value == 1) {

                            this.icd_female_3_5 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 28; i <= 30; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if ((index == 28 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][29]] == 0) {

                            this.icd_female_3_5b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][30]}) 
                            

                        } else if ((index == 29 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][28]] == 0) {

                            this.icd_female_3_5b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][30]}) 
                            
                        } else if (index == 28 && value == 1) {

                            this.icd_female_3_5b = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][30]}) 
                           
                        
                        } else if (index == 29 && value == 1) {

                            this.icd_female_3_5b = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][30]}) 
                           

                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})
                        }

                    } else if (this.current_field_chunk == 7) {

                        if (index == 31 && value == 0) {

                            this.icd_female_3_6 = false
                            this.icd_female_3_6b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 32; i <= 34; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if (index == 31 && value == 1) {

                            this.icd_female_3_6 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            for (i = 32; i <= 34; i++) {
                                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][i]}) 
                            }

                        } else if ((index == 32 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][33]] == 0) {

                            this.icd_female_3_6b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][34]}) 
                            

                        } else if ((index == 33 && value == 0) && this.get_chunks_of_field_data[this.current_field_chunk][this.get_chunks_of_field_names[this.current_field_chunk][32]] == 0) {

                            this.icd_female_3_6b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][34]}) 
                            
                        } else if (index == 32 && value == 1) {

                            this.icd_female_3_6b = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][34]}) 
                           
                        
                        } else if (index == 33 && value == 1) {

                            this.icd_female_3_6b = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][34]}) 
                           
                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})
                        }

                    } else {
                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})
                    }

                }

            /**
             * SAMPLE CLIENT
             */
            } else if (this.assessment_content.name == 'sample_client') {

                if (this.current_field_chunk == 0) {

                    if (index == 2 && value == 0) {

                            this.sample_client_3b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][3]}) 
                    
                    } else if (index == 2 && value == 1) {

                            this.sample_client_3b = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][3]}) 
                    
                    } else if (index == 5 && (value == 1 || value == 2 || value == 7)) {

                            this.sample_client_5b = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][6]}) 
                    
                    } else if (index == 5 && (value != 1 || value != 2 || value != 7)) {

                            this.sample_client_5b = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][6]}) 
                    
                    } else {
                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})
                    }

                } else if (this.current_field_chunk == 1) {

                    if (index == 13 && value == 0) {

                            this.sample_client_12b = false
                            this.sample_client_12c = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][14]}) 
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][15]}) 
                    
                    } else if (index == 13 && value == 1) {

                            this.sample_client_12b = true
                            this.sample_client_12c = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][14]}) 
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][15]})  
                    
                    } else if (index == 17 && value == 0) {

                            this.sample_client_14b = false
                            this.sample_client_14c = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][18]}) 
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][19]})  
                    
                    } else if (index == 17 && value == 1) {

                            this.sample_client_14b = true
                            this.sample_client_14c = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][18]}) 
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][19]})
                    
                    } else if (index == 18) {

                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': value, 'key': field})

                    } else {

                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                    }
                
                } else if (this.current_field_chunk == 4) {

                    if (index == 31 && value == 0) {

                            this.sample_client_26 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][32]}) 
                    
                    } else if (index == 31 && value == 1) {

                            this.sample_client_26 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][32]})  
                    
                    } else if (index == 33 && value == 0) {

                            this.sample_client_27 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][34]}) 
                    
                    } else if (index == 33 && value == 1) {

                            this.sample_client_27 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][34]})

                    } else if (index == 29) {

                        if (value == '0') {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 0, 'key': field})
                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})
                        }
                    
                    } else {

                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                    }

                } else if (this.current_field_chunk == 5) {

                    if (index == 35 && value == 0) {

                            this.sample_client_28 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(0), 'key': this.get_chunks_of_field_names[this.current_field_chunk][36]}) 
                    
                    } else if (index == 35 && value == 1) {

                            this.sample_client_28 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][36]})  

                    } else {

                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                    }

                } else {

                    this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                }

            } else {

               this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field}) 

            }


        },

    }
}
</script>

<style scoped>

</style>