<template>
  
  <v-row v-if="ready">

    <div class="timeline-item">

      <!-- icon and headline -->
      <v-row class="pa-0 ma-0">

          <v-col cols="12" sm="12" class="pa-0">

              <v-row>

                  <v-avatar :v-show="$vuetify.breakpoint.mdAndDown" color="primary" size="50" class="elevation-5" style="top: 70px;">
                    <v-icon medium color="white">fas fa-chart-bar</v-icon>
                  </v-avatar>

                  <div :class="!$vuetify.breakpoint.mdAndDown ? 'timeline-title elevation-5' : 'timeline-title-mobile elevation-5'">

                      <v-avatar v-if="thisFinished" color="success" size="40" class="elevation-5" id="cardbadge">
                          <v-icon small color="white">fas fa-check</v-icon>
                      </v-avatar>

                      <v-avatar v-else color="error" size="40" class="elevation-5" id="cardbadge">
                          <v-icon small color="white">fas fa-pencil-alt</v-icon>
                      </v-avatar>

                      <v-card flat class="pa-3">

                        <v-card flat class="ml-3 mr-3">

                            <div class="title primary--text">Rückblick</div>
                            <div class="headline font-weight-bold">{{ data.title }}</div>
                            <div class="subtitle-1 primary--text">{{ data.subtitle }}</div>
                            
                        </v-card>

                      </v-card>

                  </div>

              </v-row>

          </v-col>

      </v-row>

        <!-- content -->
        <v-row >

          <div class="timeline-content float-right">

            <basic-data v-show="thisShow" :data="data" :id='id' :guided_task_data="guided_task_data" :guided_finished="guided_task_data.revision_finished" />

          </div>

        </v-row>

    </div>

  </v-row>
</template>

<script>
import ClientApi from '@/_api/client_api'

import basicData from '@/components/_client/_intervention/_basicBlocks/basicData.vue'

export default {
    name: 'necessaryData',
    props: ['data', 'id'],
    data () {
        return {

          ready: false,

        }
    },

    created() {

      if (this.data.guided == 'yes' && this.thisFinished) {

            ClientApi.GetGuidedTaskData(this.data.taskID).then(() => {

                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                this.ready = true

            })

        } else {

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

            this.ready = true

        }

    },

    computed: {

        guided_task_data() {

            if (this.data.guided == 'yes' && this.thisFinished && this.ready) {

                if (this.$store.getters['store_lection/get_saved_lection_guided_task_data_by_identifier'](this.data.taskID)) {

                    var tmp = this.$store.getters['store_lection/get_saved_lection_guided_task_data_by_identifier'](this.data.taskID)

                    return tmp.data

                } else {

                    return {}
 
                }

            } else {

                return {}

            }

        },

        thisFinished () {
            var tmp = this.$store.getters['store_lection/get_saved_lection_data']
            return tmp.data[this.id].finished
        },

        thisShow () {
            var tmp = this.$store.getters['store_lection/get_saved_lection_data']
            return tmp.data[this.id].show
        },

        // add colors to css
        cssProps () {
            return {
                '--primary-color': this.$vuetify.theme.primary
            }
        },

    },

    components: {
        basicData
    }
}
</script>

<style scoped>
.timeline-title {
  width: calc(100% - 68px);
  margin-left: 68px;
  position: relative;

  border-style: solid;
  border-width: 1px;
  border-radius: 5px !important;
  border-color: #004b8c !important;
  padding-left: 5px;
}

.timeline-title-mobile {
  width: 100%;
  position: relative;

  border-style: solid;
  border-width: 1px;
  border-radius: 5px !important;
  border-color: #004b8c !important;
  padding-left: 5p
}

#cardbadge { 
  left: -20px;
  top: -20px; 
  position: absolute;
  z-index: 2;
}

#timeline .timeline-item {
  margin-bottom: 10px;
  position: relative;
  width: 100%;
}

#timeline .timeline-item .timeline-content {
  width: calc(100% - 68px);
  margin-left: 68px;
}

#timeline-mobile .timeline-item {
  margin-bottom: 10px;
  position: relative;
  width: 100%;
}

#timeline-mobile .timeline-item .timeline-content {
  width: 100%;
}
</style>


