<template>

    <div class="mx-3">

        <v-row>

            <!-- FIELD Pro -->
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12':'6'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'6'">

                <v-card
                    class="mx-auto"
                    outlined
                    min-height="120px"
                    height="100%"
                >

                    <v-card-text class="text-center headline primary--text font-weight-bold">{{ data.headerPro }}</v-card-text>

                    <v-divider />
                    
                    <v-card-text>

                        <v-row
                            v-for="(item, i) in data.proList"
                            :key="i"
                            align="center" 
                            justify="center"
                        >

                            <v-card :color="getCorrectColor('pro', item)" class="mb-2">
                                <v-card-text class="pa-1 text-center body-1 white--text">
                                    {{ item.assignmentText }}
                                </v-card-text>
                            </v-card>

                        </v-row>

                    </v-card-text>

                </v-card>

            </v-col>

            <!-- FIELD Contra -->
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12':'6'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'6'">

                <v-card
                    class="mx-auto"
                    outlined
                    min-height="120px"
                    height="100%"
                >

                    <v-card-text class="text-center headline primary--text font-weight-bold">{{ data.headerContra }}</v-card-text>

                    <v-divider />

                    <v-card-text>

                        <v-row
                            v-for="(item, i) in data.contraList"
                            :key="i"
                            align="center" 
                            justify="center"
                        >

                            <v-card :color="getCorrectColor('contra', item)" class="mb-2">
                                <v-card-text class="pa-1 text-center body-1 white--text">
                                    {{ item.assignmentText }}
                                </v-card-text>
                            </v-card>

                        </v-row>

                    </v-card-text>

                </v-card>

            </v-col>

        </v-row>

    </div>
    
</template>

<script>
export default {
    name: 'pro-contra-assignment-task-data',

    props: ['data'],

    methods: {

        getCorrectColor(where, item) {

            if (where == 'pro' && item.assignmentType == 'pro') {

                return '#4CAF50'

            } else if (where == 'contra' && item.assignmentType == 'contra') {

                return '#4CAF50'

            } else if (item.assignmentType == 'both') {

                return '#4CAF50'
            
            } else {
                return '#FF5252'
            }

        },

    }
}
</script>

<style scoped>

</style>


