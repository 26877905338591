import axios from 'axios'
import store from '@/store/store'
import router from '@/router'
import store_helpers from '@/_helpers/store_helpers'

import DateTimeHelper from '@/_helpers/date_time_helpers'


function error_handling(err) {

    if (err == 'Error: Network Error') {

        store.dispatch('store_auth/CHANGE_LOGIN_INFO', { login_info_type: "error", login_info: 'Leider ist der Server aktuell nicht erreichbar. Bitte versuchen Sie es in einigen Minuten erneut.'})

        // clear store
        store_helpers.ClearEntireStore()

        // redirect to login
        router.push('/login')

    } else if (err == 'Error: Request failed with status code 403') {

        var err_msg = 'Sie sind nicht berechtigt, diese Seite zu betrachten. Bitte geben Sie ihr Passwort und ihren Benutzernamen ein.'
        store.dispatch('store_auth/CHANGE_LOGIN_INFO', { login_info_type: "info", login_info: err_msg})

        // clear store
        store_helpers.ClearEntireStore()

        // redirect to login
        router.push('/login')

    } else if (err == 'Error: Request failed with status code 500') {

        err_msg = 'Error 500: Internal Server Error.'
        
        store.dispatch('store_auth/CHANGE_LOGIN_INFO', { login_info_type: "error", login_info: err_msg})

        // clear store
        store_helpers.ClearEntireStore()

        // redirect to login
        router.push('/login')
        
    }

}

const GetSupervisorInfo = () => new Promise ((resolve) => {
    /**
   * Summary. Get all necessary data of a client.
   */

   const endpoint = 'supervisor/supervisor-info/'

   axios.get(endpoint)

   .then(resp => {

       store.dispatch('store_supervisor/UPDATE_SUPERVISOR', resp.data.supervisor)
       store.dispatch('store_supervisor/UPDATE_SUPERVISOR_MESSAGES', resp.data.supervisor_messages)
       store.dispatch('store_supervisor/UPDATE_SUPERVISOR_QUESTIONNAIRES', resp.data.supervisor_questionnaires)
       store.dispatch('store_supervisor/UPDATE_SUPERVISOR_CRITICAL_EVENTS', resp.data.supervisor_critical_events)
       store.dispatch('store_supervisor/UPDATE_SUPERVISOR_RISK_ASSESSMENT', resp.data.supervisor_risk_assessment)
       store.dispatch('store_supervisor/UPDATE_OPEN_PAYMENTS', resp.data.open_payments)

       /**
        * Create Messages if necessary
        */
       if (resp.data.supervisor_messages.unread_message_count == 1) {

           store.dispatch('store_main_messages/UPDATE_MSG_TXT', 'Sie haben ' + resp.data.supervisor_messages.unread_message_count + ' neue <a href="/supervisor/messages/null">Nachricht</a>.')
           store.dispatch('store_main_messages/UPDATE_MSG_COLOR', 'error')
           store.dispatch('store_main_messages/UPDATE_MSG_ICON', 'fas fa-exclamation-circle')
           store.dispatch('store_main_messages/UPDATE_MSG_SHOW', true)

       } else if (resp.data.supervisor_messages.unread_message_count > 1) {

           store.dispatch('store_main_messages/UPDATE_MSG_TXT', 'Sie haben ' + resp.data.supervisor_messages.unread_message_count + ' neue <a href="/supervisor/messages/null">Nachrichten</a>.')
           store.dispatch('store_main_messages/UPDATE_MSG_COLOR', 'error')
           store.dispatch('store_main_messages/UPDATE_MSG_ICON', 'fas fa-exclamation-circle')
           store.dispatch('store_main_messages/UPDATE_MSG_SHOW', true)

       } else {
            store.dispatch('store_main_messages/UPDATE_MSG_SHOW', false)
        }

        if (resp.data.supervisor_questionnaires.unfinished_questionnaires_count == 1) {

            store.dispatch('store_main_messages/UPDATE_MSG_Q_TXT', 'Sie haben ' + resp.data.supervisor_questionnaires.unfinished_questionnaires_count + ' nicht bearbeiteten <a href="/supervisor/assessment/null">Fragebogen</a>.')
            store.dispatch('store_main_messages/UPDATE_MSG_Q_COLOR', 'error')
            store.dispatch('store_main_messages/UPDATE_MSG_Q_ICON', 'fas fa-exclamation-circle')
            store.dispatch('store_main_messages/UPDATE_MSG_Q_SHOW', true)
    
        } else if (resp.data.supervisor_questionnaires.unfinished_questionnaires_count > 1) {

            store.dispatch('store_main_messages/UPDATE_MSG_Q_TXT', 'Sie haben ' + resp.data.supervisor_questionnaires.unfinished_questionnaires_count + ' nicht bearbeitete <a href="/supervisor/assessment/null">Fragebögen</a>.')
            store.dispatch('store_main_messages/UPDATE_MSG_Q_COLOR', 'error')
            store.dispatch('store_main_messages/UPDATE_MSG_Q_ICON', 'fas fa-exclamation-circle')
            store.dispatch('store_main_messages/UPDATE_MSG_Q_SHOW', true)
    
        } else {
            store.dispatch('store_main_messages/UPDATE_MSG_Q_SHOW', false)
        }

        if (resp.data.supervisor_critical_events.unread_critical_event_count == 1) {

            store.dispatch('store_main_messages/UPDATE_MSG_CE_TXT', 'Sie haben ' + resp.data.supervisor_critical_events.unread_critical_event_count + ' ungelesenes <a href="/supervisor/critical-event/null">potentiell kritisches Ereignis</a>.')
            store.dispatch('store_main_messages/UPDATE_MSG_CE_COLOR', 'error')
            store.dispatch('store_main_messages/UPDATE_MSG_CE_ICON', 'fas fa-exclamation-circle')
            store.dispatch('store_main_messages/UPDATE_MSG_CE_SHOW', true)
    
        } else if (resp.data.supervisor_critical_events.unread_critical_event_count > 1) {

            store.dispatch('store_main_messages/UPDATE_MSG_CE_TXT', 'Sie haben ' + resp.data.supervisor_critical_events.unread_critical_event_count + ' ungelesene <a href="/supervisor/critical-event/null">potentiell kritische Ereignisse</a>.')
            store.dispatch('store_main_messages/UPDATE_MSG_CE_COLOR', 'error')
            store.dispatch('store_main_messages/UPDATE_MSG_CE_ICON', 'fas fa-exclamation-circle')
            store.dispatch('store_main_messages/UPDATE_MSG_CE_SHOW', true)
    
        } else {
            store.dispatch('store_main_messages/UPDATE_MSG_CE_SHOW', false)
        }

        resolve(resp)

   })

   .catch(err => {

       error_handling(err)

       //reject(err)
   
       })

})

const GetSupervisorAllClientsData = () => new Promise ((resolve) => {
    /**
   * Summary. Get all necessary data of a client.
   */

   const endpoint = 'supervisor/supervisor-all-clients-data/'

   axios.get(endpoint)

   .then(resp => {

       store.dispatch('store_supervisor/UPDATE_SUPERVISOR_CLIENTS', resp.data)

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)
   
    })

})


/**
 * MESSAGES / CHATS
 */
const GetThreadById = (thread_id) => new Promise ((resolve) => {
    /**
   * Summary. Get all finished guided tasks of the coach.
   */

  var endpoint = 'messages-api/get-thread-by-id/' + thread_id + '/'

   axios.get(endpoint)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

const GetUserThreadsDeletedAPI = () => new Promise ((resolve) => {
    /**
   * Summary. Get all necessary data of a client.
   */

  var endpoint = 'messages-api/thread-view-deleted/'

   axios.get(endpoint)

   .then(resp => {

        if (resp.data.length != 0) {
            store.dispatch('store_messages/UPDATE_USER_THREADS_DELETED', DateTimeHelper.sortThreadByMessageDate(resp.data))
        } else {
            store.dispatch('store_messages/UPDATE_USER_THREADS_DELETED', resp.data)
        }

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

const GetUserThreadsInboxAPI = () => new Promise ((resolve) => {
    /**
   * Summary. Get all necessary data of a client.
   */

  var endpoint = 'messages-api/thread-view-inbox/'

   axios.get(endpoint)

   .then(resp => {

        if (resp.data.length != 0) {
            store.dispatch('store_messages/UPDATE_USER_THREADS', DateTimeHelper.sortThreadByMessageDate(resp.data))
        } else {
            store.dispatch('store_messages/UPDATE_USER_THREADS', resp.data)
        }

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

const SendMessage = (data) => new Promise((resolve) => {

    const formData = new FormData();

    formData.append("sent_at", DateTimeHelper.getDateTime());
    formData.append("content", data.newMessageValue);
    formData.append("sender", store.getters['store_supervisor/get_supervisor'].supervisor_user_id);
    formData.append("thread", data.current_thread_id);
    

    formData.append("attachment1", data.attachmentFile1);
    formData.append("attachment2", data.attachmentFile2);
    formData.append("attachment3", data.attachmentFile3);

    var endpoint = 'messages-api/message-view/'

    axios.post(endpoint, formData)
    
    .then(()=>{ 

        resolve()
        
    })

    .catch((error) => {

        error_handling(error)

    })

})

const CreateNewThread = (data) => new Promise((resolve) => {


    var endpoint = 'messages-api/create-new-thread/'

    const formData = new FormData();

    formData.append("sent_at", DateTimeHelper.getDateTime());
    formData.append("content", data.content);
    formData.append("sender", store.getters['store_supervisor/get_supervisor'].supervisor_user_id);
    formData.append("to_users", data.receiver);
    formData.append("subject", data.subject);
    formData.append('is_technical_help', data.is_technical_help);

    formData.append("attachment1", data.attachmentFile1);
    formData.append("attachment2", data.attachmentFile2);
    formData.append("attachment3", data.attachmentFile3);

    axios.post(endpoint, formData)
    
    .then(()=>{ 

        resolve()
        
    })

    .catch((error) => {

        error_handling(error)

    })

})

const ThreadToArchive = (data) => new Promise((resolve) => {

    var endpoint_1 = 'messages-api/user-thread-view/'

    var formData = {

        "deleted": true
    }

    axios.get(endpoint_1)
    
    .then((resp)=>{ 

        var correct_id_user_thread = null

        for (var i = 0; i < resp.data.length; i++) {

            if (resp.data[i].thread == data.current_thread_id) {

                correct_id_user_thread = resp.data[i].id

            }
        }


        var endpoint_2 = 'messages-api/user-thread-view/'  + correct_id_user_thread + '/'

        axios.patch(endpoint_2, formData)

        .then(()=> {

            resolve()

        })

        .catch((error) => {

            error_handling(error)
    
        })
        
    })

    .catch((error) => {

        error_handling(error)

    })

})

const UndoThreadToArchive = (data) => new Promise((resolve) => {

    var endpoint_1 = 'messages-api/user-thread-view/'

    var formData = {

        "deleted": false
    }

    axios.get(endpoint_1)
    
    .then((resp)=>{ 

        var correct_id_user_thread = null

        for (var i = 0; i < resp.data.length; i++) {

            if (resp.data[i].thread == data.current_thread_id) {

                correct_id_user_thread = resp.data[i].id

            }
        }


        var endpoint_2 = 'messages-api/user-thread-view/'  + correct_id_user_thread + '/'

        axios.patch(endpoint_2, formData)

        .then(()=> {

            resolve()

        })

        .catch((error) => {

            error_handling(error)
    
        })
        
    })

    .catch((error) => {

        error_handling(error)

    })

})



const SetThreadAsRead = (data) => new Promise((resolve) => {
    /**
     * Summary. Updates the current Thread as read.
     * */


    var endpoint_1 = 'messages-api/user-thread-view/'

    var formData = {

        "unread": false
    }

    axios.get(endpoint_1)
    
    .then((resp)=>{ 

        var correct_id_user_thread = null

        for (var i = 0; i < resp.data.length; i++) {

            if (resp.data[i].thread == data.current_thread_id) {

                correct_id_user_thread = resp.data[i].id

            }
        }


        var endpoint_2 = 'messages-api/user-thread-view/'  + correct_id_user_thread + '/'

        axios.patch(endpoint_2, formData)

        .then(()=> {

            resolve()

        })

        .catch((error) => {

            error_handling(error)
    
        })
        
    })

    .catch((error) => {

        error_handling(error)

    })


})

/*
*
* ASSESSMENT
*
*/

const GetUnfinishedPolls = () => new Promise ((resolve) => {
    /**
   * Summary. Get all unfinished polls for supervisor.
   */

  var endpoint = 'supervisor/supervisor-polls-unfinished/'

   axios.get(endpoint)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

const GetFinishedPolls = () => new Promise ((resolve) => {
    /**
   * Summary. Get all finished polls for supervisor.
   */

  var endpoint = 'supervisor/supervisor-polls-finished/'

   axios.get(endpoint)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

const GetSingleQuestionnaire = data => new Promise ((resolve) => {


    var endpoint = 'supervisor/get-single-questionnaire/?module_nr=' + data.module + '&session_nr=' + data.session + '&lection_nr=' + data.lection + '&name=' + data.name + '&client_user_id=' + data.client_user_id

    axios.get(endpoint)

    .then(resp => {

        store.dispatch('store_assessment/UPDATE_ALL_ASSESSMENTS_IN_MODULE', resp.data.questionnaires)

        store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_CONTENT', resp.data.questionnaires[0])
        store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA', resp.data.questionnaires[0].fields.data)


        store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

        

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })
})

const UpdateOpenAssessments = data => new Promise ((resolve) => {

    var endpoint = 'supervisor/supervisor-open-assessments-update/' + data.module + '/' + data.session + '/' + data.lection + '/' + data.timepoint + '/' + data.name + '/' + data.client_id

    axios.patch(endpoint)

    .then(resp => {      

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })
})


const SaveAssessmentData = data => new Promise ((resolve) => {

    var endpoint = ''

    if (data.name == 'supervisor_sample') {

        endpoint = 'q-api/sample-supervisor/'

    } else if (data.name == 'repeated_supervisor_sample') {

        endpoint = 'q-api/repeated-sample-supervisor/'

    }

    // formData
    var formData = Object.assign({}, store.getters['store_assessment/get_current_assessment_data'])

    formData['client'] = data.client_id
    formData['created_by'] = store.getters['store_supervisor/get_supervisor'].supervisor_user_id
    formData['client_user_id'] = data.client_user_id
    formData['current_module_nr'] = data.module
    formData['current_session_nr'] = data.session
    formData['current_lesson_nr'] = data.lection

    axios.post(endpoint, formData,)

    .then(resp => {

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })
})

/**
 * EXCLUSION OF CLIENT
 */
const ExcludeClient = data => new Promise ((resolve) => {

    var endpoint = 'q-api/client-exclusion-supervisor/'


    // formData
    var formData = Object.assign({}, store.getters['store_assessment/get_current_assessment_data'])

    formData['client'] = data.client_id
    formData['created_by'] = store.getters['store_supervisor/get_supervisor'].supervisor_user_id
    formData['client_user_id'] = data.client_user_id
    formData['reason_1'] = data.reason_1
    formData['reason_2'] = data.reason_2
    formData['reason_3'] = data.reason_3
    formData['reason_3a'] = data.reason_3a
    formData['reason_3b'] = data.reason_3b
    formData['reason_3c'] = data.reason_3c
    formData['reason_4'] = data.reason_4
    formData['reason_4a'] = data.reason_4a
    formData['reason_5'] = data.reason_5
    formData['reason_6'] = data.reason_6
    formData['reason_7'] = data.reason_7
    formData['reason_8'] = data.reason_8
    formData['reason_9'] = data.reason_9
    formData['reason_9a'] = data.reason_9a
    formData['relation_to_mytabu_1'] = data.relation_to_mytabu_1
    formData['relation_to_mytabu_2'] = data.relation_to_mytabu_2
    formData['relation_to_mytabu_3'] = data.relation_to_mytabu_3

    axios.post(endpoint, formData,)

    .then(resp => {

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })
})

/**
 * HELP PAGES
 */

const GetHelpPages = () => new Promise ((resolve) => {

    var endpoint = 'api-wagtail/pages/?type=myTabu_CMS.MyTabuSupervisorHelpPage&fields=body,tab_title&limit=50'

    axios.get(endpoint)

    .then(resp => {      

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })

})


/**
 * Risk Assessment
 */
const GetRiskAssessmentAcute = (client_id) => new Promise ((resolve) => {


    var endpoint = 'supervisor/get-risk-assessment-acute/' + client_id

    axios.get(endpoint)

    .then(resp => {
       
        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })
})

const GetRiskAssessmentCte = (client_id) => new Promise ((resolve) => {


    var endpoint = 'supervisor/get-risk-assessment-cte/' + client_id

    axios.get(endpoint)

    .then(resp => {
       
        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })
})


const GetRiskAssessmentCmc = (client_id) => new Promise ((resolve) => {


    var endpoint = 'supervisor/get-risk-assessment-cmc/' + client_id

    axios.get(endpoint)

    .then(resp => {
       
        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })
})

const GetRiskAssessmentFlashlightPost = (client_id) => new Promise ((resolve) => {


    var endpoint = 'supervisor/get-risk-assessment-flashlight-post/' + client_id

    axios.get(endpoint)

    .then(resp => {
       
        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })
})

const GetRiskAssessmentFlashlightPre = (client_id) => new Promise ((resolve) => {


    var endpoint = 'supervisor/get-risk-assessment-flashlight-pre/' + client_id

    axios.get(endpoint)

    .then(resp => {
       
        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })
})



const GetRiskAssessments = () => new Promise ((resolve) => {


    var endpoint = 'supervisor/get-risk-assessments/'

    axios.get(endpoint)

    .then(resp => {

        store.dispatch('store_supervisor/UPDATE_RISK_DATA', resp.data)
       
        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })
})


/**
 * CRITICAL EVENTS
 */
const GetCriticalEvents = () => new Promise ((resolve) => {
    /**
   * Summary. Get all finished polls for supervisor.
   */

  var endpoint = 'supervisor/get-critical-events/'

   axios.get(endpoint)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

const UpdateCriticalEvent = data => new Promise ((resolve) => {

    var endpoint = 'supervisor/supervisor-open-critical-event-update/' + data.type + '/' + data.event_id

    axios.patch(endpoint)

    .then(resp => {      

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })
})

/**
 * OPEN PAYMENTS
 */
const GetOpenPayments = () => new Promise ((resolve) => {
    /**
   * Summary. Get all finished polls for supervisor.
   */

  var endpoint = 'supervisor/get-open-payments/'

   axios.get(endpoint)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

const UpdateOpenPaymentsReceived = data => new Promise ((resolve) => {

    var endpoint = 'supervisor/supervisor-open-payments-update-received/' + data.id

    axios.patch(endpoint)

    .then(resp => {      

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)

    })
})

const UpdateOpenPaymentsClientReceived = data => new Promise ((resolve) => {

    var endpoint = 'supervisor/supervisor-open-payments-update-client-received/' + data.id

    axios.patch(endpoint)

    .then(resp => {      

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })
})



export default {

    GetSupervisorInfo,
    GetSupervisorAllClientsData,

    // messages
    GetUserThreadsInboxAPI,
    GetUserThreadsDeletedAPI,
    SendMessage,
    CreateNewThread,
    SetThreadAsRead,
    UndoThreadToArchive,
    ThreadToArchive,
    GetThreadById,

    // assessment
    GetUnfinishedPolls,
    GetFinishedPolls,
    SaveAssessmentData,
    UpdateOpenAssessments,
    GetSingleQuestionnaire,

    // help
    GetHelpPages,

    // risk assessment
    GetRiskAssessmentAcute,
    GetRiskAssessmentCte,
    GetRiskAssessmentCmc,
    GetRiskAssessmentFlashlightPost,
    GetRiskAssessmentFlashlightPre,

    GetRiskAssessments,

    // critical events
    GetCriticalEvents,
    UpdateCriticalEvent,

    // open payments
    GetOpenPayments,
    UpdateOpenPaymentsReceived,
    UpdateOpenPaymentsClientReceived,

    ExcludeClient

}