<template>
    
    <v-card-text class="pa-0">

        <v-img :src="img_http" :class="getClass" v-if="!error" position="center center" :contain="contain"></v-img>

        <div class="errorDiv text-center my-5 py-5" v-if="error">
            <p class="subtitle-2 mx-3 my-3">{{ alt }}</p>
            <p class="overline mx-3 my-3">Das Bild konnte leider nicht geladen werden.</p>
        </div>

    </v-card-text>
    
</template>


<script>
import axios from 'axios'

export default {

  name: 'imageMedia',


  props: ['alt', 'border', 'img_id'],

  data () {
    return {
      
      img_http: '',

      error: true,

      // ToDo: contain als variable im backend!!!!
      contain: false,

    }
  },

  mounted() {

      this.getImgUrl()

  },

  methods: {

      getImgUrl() {

        var endpoint = 'api-wagtail/images/' + this.img_id + '/'

        axios.get(endpoint)

        .then((response)=>{

            var http = axios.defaults.baseURL + response.data.meta.download_url.substring(1)

            this.img_http = http

            this.error = false               
            
        })

        .catch(()=>{

            this.error = true
            
        })
      },

  },

  computed: {

      getClass() {

          if (this.border == 'top') {
              return 'img-top'
          } else if (this.border == 'bottom') {
              return 'img-bottom'
          } else {
              return 'img-all'
          }
      }
      
  },

  method: {

  }

}
</script>

<style>
.img {
    height: auto;
}

.img-top {
    max-width: 100% !important;
    max-height: 100% !important;
    display: inline-block !important;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}

.img-bottom {
    max-width: 100% !important;
    max-height: 100% !important;
    display: inline-block !important;
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.img-all {
    height: 100% !important;
    border-radius: 5px !important;
}

.errorDiv {
    min-height: 100px !important;
    min-width: 100px !important;
    border-style: solid;
    border-width: 1px;
    border-color: #004b8c !important;
    background-color: #EEEEEE);
}
</style>