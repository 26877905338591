<template>
  <div class="mx-3" v-if="ready">

    <v-row>
      <div class="headline primary--text">{{ question }}</div>
    </v-row>

    <v-row>
      <div class="overline mb-3"><b>Tipp:</b> Wählen Sie nur eine Antwortmöglichkeit aus.</div>
    </v-row>

    <v-row no-gutters>

        <v-col 
          :cols="responsiveColumn" 
          v-for="(label, i) in labels" 
          :key="label.name"
        >

          <v-checkbox
              v-model="checkbox[i]"   
              :label="label"
              data-vv-name="checkbox"
              type="checkbox"
              :disabled="thisBtnDisabled"
              class="mt-0 pl-0 mb-2"
              color="accent"
              hide-details
          ></v-checkbox>

        </v-col>

    </v-row>
  </div>
</template>

<script>

export default {
    name: 'multipleChoicePickOneForm',
    props: ['taskID', 'taskChoices', 'question', 'id'],

    data: () => ({

      checkbox: [],
      labels: [],

      ready: true,

      thisBtnDisabled: false,

    }),

    created() {

      this.labels = Object.assign({}, this.taskChoices)

      var labels = []

      // define all checkboxes per default not picked
      for (var i = 0; i < this.taskChoices.length; i++) {

        this.checkbox.push(false)
        labels.push(this.labels[i])

      }

      this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'type', 'val': 'multipleChoicePickOneForm'})
      this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'checkbox', 'val': this.checkbox})
      this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'labels', 'val': this.labels})
      this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'success', 'val': false})
      
    },

    computed: {

        responsiveColumn() {

          if (this.taskChoices.length <= 5) {

            return '12'

          } else {

              if (this.$vuetify.breakpoint.name == 'xs' || this.$vuetify.breakpoint.name == 'sm') {

                return '12'

              } else {

                return '6'

              }

            }
        
        }

    },

    watch: {

      // watch the checkbox-state and update the store immediatly on change
      checkbox: function(val) {

        this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'checkbox', 'val': val})

        var nrTrueCheckboxes = 0

        for (var l = 0; l < this.checkbox.length; l++) {

            if (this.checkbox[l] == true) {

                nrTrueCheckboxes = nrTrueCheckboxes + 1

            }
        }

        if (nrTrueCheckboxes == 1) {

          this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'success', 'val': true})

        } else {

          this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'success', 'val': false})

        }

      }

    },
}   
</script>

<style scoped>
  
</style>