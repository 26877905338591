<template>
    <div> 
        <div 
            v-for="(column, index) in data" 
            :key="column.name"
            id="id"
        > 

            <v-row 
                v-if="column.type == 'additionalExampleInfo'"
            >
                <v-col sm="12">
                    <v-card 
                        raised 
                        :color="getCorrectColor(column.value.text_background_color)"
                    >
                        <v-card-text>   
                            <div 
                                v-html="column.value.text"
                                class="subtitle-1 mb-0" 
                            ></div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <div v-if="column.type == 'exampleMain'">

                <v-row 
                    v-if="column.value.columnType == 'left-image'"
                > 
                    <v-col :sm="$vuetify.breakpoint.smAndDown ? '12':'3'" cols="12">
                        <v-card 
                            :color="getCorrectColor(column.value.img_background_color)" 
                            raised 
                            :height="column.value.height_adjustment"
                        >

                            <v-menu
                                bottom
                                :offset-y="true"
                                :min-width="285"
                                :nudge-left="$vuetify.breakpoint.mdAndUp ? 0 : -15"
                                :nudge-bottom="5"
                                left
                                :attach="'#fooAnchor' + index.toString()"
                                z-index="1"
                            >
                                <template v-slot:activator="{ on, attrs }">

                                    <v-btn
                                        color="primary"
                                        fab
                                        dark
                                        small
                                        absolute
                                        right
                                        class="mt-3"
                                        v-bind="attrs"
                                        v-on="on"
                                        
                                    >
                                        <v-icon>fas fa-headphones-alt</v-icon>
                                    </v-btn>

                                </template>

                                <v-card>
                                    <v-card-text class="pb-0 pt-2 px-2 ma-0">
                                        <audio @play="onPlayAudioListener" @ended="onEndedAudioListener" @pause="onPauseAudioListener" :src="'/audio/' + column.value.audio_file_name" controls controlslist="nodownload"></audio>
                                    </v-card-text>
                                </v-card>

                            </v-menu>


                            

                            <image-media 
                                :alt="column.value.img_alt" 
                                :border="'top'"
                                :img_id="column.value.img_chooser" 
                                :id="'fooAnchor' + index.toString()"
                            >
                            </image-media>
                            
                            <v-card-text>
                                <!-- <audio :src="'/audio/' + column.value.audio_file_name" controls ></audio> -->
                                <div class="headline mb-0">{{ column.value.img_person_name}}</div>
                                <div class="font-italic">{{ column.value.img_description }}</div>

                            </v-card-text>

                        </v-card>
                    </v-col>
                    
                    <v-col :sm="$vuetify.breakpoint.smAndDown ? '12':'9'" cols="12">
                        <v-card 
                            :color="getCorrectColor(column.value.text_background_color)" 
                            raised 
                            :height="column.value.height_adjustment"
                        >
                            <v-card-text>
                                <v-row justify="start">
                                    <v-icon large class="ml-5">fas fa-quote-left</v-icon>
                                </v-row>
                                <v-row >
                                    <blockquote v-html="column.value.text" class="blockquote mr-6"></blockquote>
                                </v-row>
                                <v-row justify="end">
                                    <v-icon large class="mr-5">fas fa-quote-right</v-icon>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                        
                </v-row>
                
                <v-row 
                    v-if="column.value.columnType == 'right-image'" 
                >
                    <v-col :sm="$vuetify.breakpoint.smAndDown ? '12':'9'" cols="12">
                        <v-card 
                            :color="getCorrectColor(column.value.text_background_color)" 
                            raised 
                            :height="column.value.height_adjustment"
                            class="mb-4"
                        >
                            <v-card-text>
                                <v-row justify="start" class="ml-5">
                                    <v-icon large>fas fa-quote-left</v-icon>
                                </v-row>
                                <v-row >
                                    <div v-html="column.value.text" class="blockquote"></div>
                            </v-row>
                                <v-row  justify="end" class="mr-5">
                                    <v-icon large>fas fa-quote-right</v-icon>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col :sm="$vuetify.breakpoint.smAndDown ? '12':'3'" cols="12">
                        <v-card 
                            :color="getCorrectColor(column.value.img_background_color)" 
                            raised 
                            :height="column.value.height_adjustment"
                            class="mr-2 mb-4"
                        >
                            <image-media 
                                :alt="column.value.img_alt" 
                                :border="'top'"
                                :img_id="column.value.img_chooser" 
                            />
                            <v-card-text>
                                <div class="headline mb-0">{{ column.value.img_person_name}}</div>
                                <div class="font-italic">{{ column.value.img_description }}</div>
                            </v-card-text>

                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </div>

        <!-- check -->
        <v-row>
            <v-col sm="12" cols="12">
                <v-card color="transparent" raised height="100%">
                    <v-card-text>
                        <v-row  align="center" class="ma-2">
                            <div v-if="!thisBtnDisabled">
                                <v-icon medium>fas fa-pencil-alt</v-icon>
                            </div>
                            <div v-else>
                                <v-icon medium>fas fa-check</v-icon>
                            </div>
                            <v-divider class="mx-4"/>
                            <v-btn 
                                rounded
                                outlined 
                                large 
                                color="primary" 
                                @click="save"
                                :disabled="thisBtnDisabled"
                            >Fertig</v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- DEV DIALOG -->
        <v-dialog v-model="dialog" persistent max-width="320">

            <v-card>
                <v-card-title class="headline">Bitte beachten Sie:</v-card-title>
                <v-card-text>
                    Da dieser Klient als Development-Klient definiert ist, haben sie die Wahl, 
                    ob sie die Daten speichern möchten oder nicht. Solange Sie an dieser Lektion 
                    arbeiten, wird empfohlen, die Daten <b>nicht</b> zu speichern.
                </v-card-text>
                <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="primary" text @click="doNotSave()">Nicht speichern</v-btn>
                <v-btn color="primary" text @click="saveDev()">Speichern</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
        
    </div>
</template>

<script>
import HelperClientIntervention from '@/_helpers/helper_client_intervention'

import imageMedia from '@/components/_client/_intervention/_basicMediaBlocks/imageMedia.vue'

export default {
    props: ['data', 'id', 'reward'],

    data () {
        return {

            dialog: false,

            dev_save: false,

            dev_checked: false,

            audioPlaying: false,

            current_audio: null

        }
    },

    mounted() {

        // ensure that all external links are save and open in a new div

        var linkList = document.querySelectorAll('#id a');

        var external = RegExp('^((f|ht)tps?:)?//(?!' + location.host + ')');

        for(var i in linkList){

            if (external.test(linkList[i].href)) {
                linkList[i].setAttribute('target', '_blank');
                linkList[i].setAttribute('rel', 'noopener noreferrer');
            }
            
        }

    },

    computed: {

        getImgUrl(imageName) {

            return require('@/assets/' + imageName)

        },

        thisBtnDisabled () {

            var tmp = this.$store.getters['store_lection/get_saved_lection_data']
            return tmp.data[this.id].btn_disabled
            
        },

    },

    methods: {

        onPlayAudioListener: function(element) {
            
            if (this.audioPlaying) {

                this.current_audio.pause()

                this.audioPlaying = true
                this.current_audio = element.target

            } else {

                this.audioPlaying = true
                this.current_audio = element.target

            }

        },

        onEndedAudioListener: function(element) {
            
            if (this.audioPlaying && element.target == this.current_audio) {

                this.audioPlaying = false
                this.current_audio = null

            }

        },

        onPauseAudioListener: function(element) {
            
            if (this.audioPlaying && element.target == this.current_audio) {

                this.audioPlaying = false
                this.current_audio = null

            }

        },

        getCorrectColor(input) {

            switch (input) {

              case 'transparent': return 'transparent'
              case 'primary': return 'primary'      // old value: "primary": "#0277bd"
              case 'secondary lighten-5': return '#c1c1c1'
              case 'accent': return '#82B1FF'

            }

        },

        // DEV ---
        doNotSave() {

            this.dev_save = false
            this.dev_checked = true
            this.dialog = false

            this.save()

        },

        saveDev() {

            this.dev_save = true
            this.dev_checked = true
            this.dialog = false

            this.save()

        },

        // DEV END ---

        // save data
       save() {

           if (this.$store.getters['store_client/get_client'].is_dev && this.dev_checked == false) {

               this.dialog = true

           } else if (this.$store.getters['store_client/get_client'].is_dev && this.dev_checked == true) {

               if (this.dev_save == true) {

                   const data = {
                        'save_lection_data': true,
                        'module': this.$route.params.module,
                        'session': this.$route.params.session,
                        'lection': this.$route.params.lection,
                        'id': this.id,
                        'type': 'example',
                        'reward': parseInt(this.reward) 
                    }

                    HelperClientIntervention.InterventionNext(data).then(() => {

                    })


               } else {

                   const data = {
                        'save_lection_data': false,
                        'module': this.$route.params.module,
                        'session': this.$route.params.session,
                        'lection': this.$route.params.lection,
                        'id': this.id,
                        'type': 'example',
                        'reward': parseInt(this.reward) 
                    }


                    HelperClientIntervention.InterventionNext(data).then(() => {

                        console.log('NEXT')

                    })

               }

           } else {

               const data = {
                   'save_lection_data': true,
                   'module': this.$route.params.module,
                   'session': this.$route.params.session,
                   'lection': this.$route.params.lection,
                   'id': this.id,
                   'type': 'example',
                   'reward': parseInt(this.reward) 
               }

               HelperClientIntervention.InterventionNext(data).then(() => {


               })

           }

       }

    },

    components: {
        imageMedia,
    },
}
</script>

<style scoped>
.col-container {
    display: table !important; /* Make the container element behave like a table */
    width: 100% !important; /* Set full-width to expand the whole page */
}

.col {
    display: table-cell !important; /* Make elements inside the container behave like table cells */
}
</style>


