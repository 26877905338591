<template>

<div class="mx-3" v-if="ready">

    <v-row>
      <div class="headline primary--text">{{ question }}</div>
    </v-row>

    <v-row>
      <div class="overline mb-3"><b>Tipp:</b> Fügen Sie in der Tabelle Aussagen/Stichwörter hinzu. Die Farbe zeigt an, ob die Aussage/das Stichwort richtig zugeordnet ist.</div>
    </v-row>

    <v-row>

        <!-- FIELD Pro -->
        <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12':'6'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'6'">

            <v-menu 
                min-width="300px"
                absolute
                z-index="1"
                v-if="$vuetify.breakpoint.mdAndDown"
                :disabled="thisBtnDisabled"
            >

                <template v-slot:activator="{ on, attrs }">

                    <v-card
                        class="mx-auto"
                        outlined
                        min-height="120px"
                        height="100%"
                        v-bind="attrs"
                        v-on="on"
                    >

                    <v-card-text class="text-center headline primary--text font-weight-bold">{{ headerPro }}</v-card-text>

                    <v-divider />

                    <v-card-text>

                        <v-row
                            align="center" 
                            justify="center"
                            v-if="proList.length == 0"
                        >

                            <v-col cols="12">
                                <div class="body-1 text-center">
                                    <i>Klicken</i> Sie hier um eine Aussage/ein Stichwort zuzuordnen.
                                </div>
                            </v-col>

                        </v-row>

                        <v-row
                            v-for="(item, i) in proList"
                            :key="i"
                            align="center" 
                            justify="center"
                        >

                            <v-card :color="getCorrectColor('pro', item)" class="mb-2">
                                <v-card-text class="pa-1 text-center body-1 white--text">
                                    {{ item.assignmentText }}
                                </v-card-text>
                            </v-card>

                        </v-row>

                    </v-card-text>

                </v-card>

                </template>

                <v-list subheader two-line>

                    <v-list-item
                        v-for="(item, index) in mainList.concat(proList)"
                        :key="index"
                        @click="on_item_click(item, index, 'proList')"
                    >

                        <v-list-item-avatar>
                            <v-icon :color="getCorrectColor('pro', item)" v-if="proList.includes(item)">far fa-times-circle</v-icon>
                            <v-icon color="primary" v-if="mainList.includes(item)">far fa-plus-square</v-icon>
                        </v-list-item-avatar>
                        
                        <v-list-item-content>
                            <div class="font-weight-medium primary--text">{{ item.assignmentText }}</div>
                            <div v-if="proList.includes(item)" class="body-2 font-weight-light"><i>Klicken</i> um aus diesem Feld zu entfernen.</div>
                            <div v-if="mainList.includes(item)" class="body-2 font-weight-light"><i>Klicken</i> um diesem Feld zuzuordnen.</div>
                        </v-list-item-content>

                    </v-list-item>

                </v-list>

            </v-menu>

            <v-menu 
                min-width="800px"
                absolute
                z-index="1"
                :disabled="thisBtnDisabled"
                v-else
            >

                <template v-slot:activator="{ on, attrs }">

                    <v-card
                        class="mx-auto"
                        outlined
                        min-height="120px"
                        height="100%"
                        v-bind="attrs"
                        v-on="on"
                    >

                    <v-card-text class="text-center headline primary--text font-weight-bold">{{ headerPro }}</v-card-text>

                    <v-divider />

                    <v-card-text>

                        <v-row
                            align="center" 
                            justify="center"
                            v-if="proList.length == 0"
                        >

                            <v-col cols="12">
                                <div class="body-1 text-center">
                                    <i>Klicken</i> Sie hier um eine Aussage/ein Stichwort zuzuordnen.
                                </div>
                            </v-col>

                        </v-row>

                        <v-row
                            v-for="(item, i) in proList"
                            :key="i"
                            align="center" 
                            justify="center"
                        >

                            <v-card :color="getCorrectColor('pro', item)" class="mb-2">
                                <v-card-text class="pa-1 text-center body-1 white--text">
                                    {{ item.assignmentText }}
                                </v-card-text>
                            </v-card>

                        </v-row>

                    </v-card-text>

                </v-card>

                </template>

                <v-list subheader two-line>

                    <v-list-item
                        v-for="(item, index) in mainList.concat(proList)"
                        :key="index"
                        @click="on_item_click(item, index, 'proList')"
                    >

                        <v-list-item-avatar>
                            <v-icon :color="getCorrectColor('pro', item)" v-if="proList.includes(item)">far fa-times-circle</v-icon>
                            <v-icon color="primary" v-if="mainList.includes(item)">far fa-plus-square</v-icon>
                        </v-list-item-avatar>
                        
                        <v-list-item-content>
                            <div class="font-weight-medium primary--text">{{ item.assignmentText }}</div>
                            <div v-if="proList.includes(item)" class="body-2 font-weight-light"><i>Klicken</i> um aus diesem Feld zu entfernen.</div>
                            <div v-if="mainList.includes(item)" class="body-2 font-weight-light"><i>Klicken</i> um diesem Feld zuzuordnen.</div>
                        </v-list-item-content>

                    </v-list-item>

                </v-list>

            </v-menu>

        </v-col>

        <!-- FIELD Contra -->
        <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12':'6'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'6'">

            <v-menu 
                min-width="300px"
                absolute
                z-index="1"
                v-if="$vuetify.breakpoint.mdAndDown"
                :disabled="thisBtnDisabled"
            >

                <template v-slot:activator="{ on, attrs }">

                    <v-card
                        class="mx-auto"
                        outlined
                        min-height="120px"
                        height="100%"
                        v-bind="attrs"
                        v-on="on"
                    >

                    <v-card-text class="text-center headline primary--text font-weight-bold">{{ headerContra }}</v-card-text>

                    <v-divider />

                    <v-card-text>

                        <v-row
                            align="center" 
                            justify="center"
                            v-if="contraList.length == 0"
                        >

                            <v-col cols="12">
                                <div class="body-1 text-center">
                                    <i>Klicken</i> Sie hier um eine Aussage/ein Stichwort zuzuordnen.
                                </div>
                            </v-col>

                        </v-row>

                        <v-row
                            v-for="(item, i) in contraList"
                            :key="i"
                            align="center" 
                            justify="center"
                        >

                            <v-card :color="getCorrectColor('contra', item)" class="mb-2">
                                <v-card-text class="pa-1 text-center body-1 white--text">
                                    {{ item.assignmentText }}
                                </v-card-text>
                            </v-card>

                        </v-row>

                    </v-card-text>

                </v-card>

                </template>

                <v-list subheader two-line>

                    <v-list-item
                        v-for="(item, index) in mainList.concat(contraList)"
                        :key="index"
                        @click="on_item_click(item, index, 'contraList')"
                    >

                        <v-list-item-avatar>
                            <v-icon :color="getCorrectColor('contra', item)" v-if="contraList.includes(item)">far fa-times-circle</v-icon>
                            <v-icon color="primary" v-if="mainList.includes(item)">far fa-plus-square</v-icon>
                        </v-list-item-avatar>
                        
                        <v-list-item-content>
                            <div class="font-weight-medium primary--text">{{ item.assignmentText }}</div>
                            <div v-if="contraList.includes(item)" class="body-2 font-weight-light"><i>Klicken</i> um aus diesem Feld zu entfernen.</div>
                            <div v-if="mainList.includes(item)" class="body-2 font-weight-light"><i>Klicken</i> um diesem Feld zuzuordnen.</div>
                        </v-list-item-content>

                    </v-list-item>

                </v-list>

            </v-menu>

            <v-menu 
                min-width="800px"
                absolute
                z-index="1"
                :disabled="thisBtnDisabled"
                v-else
            >

                <template v-slot:activator="{ on, attrs }">

                    <v-card
                        class="mx-auto"
                        outlined
                        min-height="120px"
                        height="100%"
                        v-bind="attrs"
                        v-on="on"
                    >

                    <v-card-text class="text-center headline primary--text font-weight-bold">{{ headerContra }}</v-card-text>

                    <v-divider />

                    <v-card-text>

                        <v-row
                            align="center" 
                            justify="center"
                            v-if="contraList.length == 0"
                        >

                            <v-col cols="12">
                                <div class="body-1 text-center">
                                    <i>Klicken</i> Sie hier um eine Aussage/ein Stichwort zuzuordnen.
                                </div>
                            </v-col>

                        </v-row>

                        <v-row
                            v-for="(item, i) in contraList"
                            :key="i"
                            align="center" 
                            justify="center"
                        >

                            <v-card :color="getCorrectColor('contra', item)" class="mb-2">
                                <v-card-text class="pa-1 text-center body-1 white--text">
                                    {{ item.assignmentText }}
                                </v-card-text>
                            </v-card>

                        </v-row>

                    </v-card-text>

                </v-card>

                </template>

                <v-list subheader two-line>

                    <v-list-item
                        v-for="(item, index) in mainList.concat(contraList)"
                        :key="index"
                        @click="on_item_click(item, index, 'contraList')"
                    >

                        <v-list-item-avatar>
                            <v-icon :color="getCorrectColor('contra', item)" v-if="contraList.includes(item)">far fa-times-circle</v-icon>
                            <v-icon color="primary" v-if="mainList.includes(item)">far fa-plus-square</v-icon>
                        </v-list-item-avatar>
                        
                        <v-list-item-content>
                            <div class="font-weight-medium primary--text">{{ item.assignmentText }}</div>
                            <div v-if="contraList.includes(item)" class="body-2 font-weight-light"><i>Klicken</i> um aus diesem Feld zu entfernen.</div>
                            <div v-if="mainList.includes(item)" class="body-2 font-weight-light"><i>Klicken</i> um diesem Feld zuzuordnen.</div>
                        </v-list-item-content>

                    </v-list-item>

                </v-list>

            </v-menu>

        </v-col>

    </v-row>

    <v-row 
        align="center" 
        justify="center"
    >

        <v-alert
            :value="alert"
            :color="alert_color"
            dark
            :icon="alert_icon"
            border="top"
            width="100%"
            class="mt-3"
        >
            <div class="" v-html="alert_text"></div>
        </v-alert>

    </v-row>

  </div>

    
</template>

<script>
import _ from 'lodash'

export default {
    name: 'pro-contra-assignment-task',
    props: ['taskID', 'question', 'id', 'headerPro', 'headerContra', 'assignmentList'],

    data: () => ({
      
        mainList: [],
        
        proList: [],
        contraList: [],

        ready: false,

        alert: false,
        alert_icon: 'far fa-check-circle',
        alert_color: 'success',
        alert_text: '<b>Sehr gut!</b> Sie haben alle Wörter richtig zugeordnet.',

    }),

    created () {

        this.ready = false

        if (_.isEmpty(this.$store.getters['store_lection/get_saved_lection_task_data_by_identifier'](this.taskID))) {

            // fill the main list based on prop assignmentList
            var i;

            for (i = 0; i < this.assignmentList.length; i++) {
                this.mainList.push(this.assignmentList[i])
            }

            //sort main list
            this.mainList.sort(this.dynamicSort('assignmentText'))

            // define task data store
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'mainList', 'data': this.mainList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'proList', 'data': this.proList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'contraList', 'data': this.contraList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'type', 'data': 'proContraAssignmentTask'})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert', 'data': this.alert})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert_icon', 'data': this.alert_icon})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert_color', 'data': this.alert_color})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert_text', 'data': this.alert_text})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'headerPro', 'data': this.headerPro})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'headerContra', 'data': this.headerContra})

            this.ready = true

        } else {

            // get data
            var data = this.$store.getters['store_lection/get_saved_lection_task_data_by_identifier'](this.taskID)
            this.mainList = data.mainList
            this.proList = data.proList
            this.contraList = data.contraList
            this.alert = data.alert
            this.alert_icon= data.alert_icon
            this.alert_color = data.alert_color
            this.alert_text = data.alert_text

            this.ready = true
        }
    },

    watch: {

      // watch the checkbox-state and update the store immediatly on change
      mainList: function(val) {

        // update store variable
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'mainList', 'data': val})
      },

      proList: function(val) {

        // update store variable
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'proList', 'data': val})
      },

      contraList: function(val) {

        // update store variable
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'contraList', 'data': val})
      },

    },

    computed: {
        
        thisBtnDisabled () {

            var tmp = this.$store.getters['store_lection/get_saved_lection_data']
                
            if (tmp.data[this.id].finished == false) {
                return false
            } else {
                return true
            }
 
        },

    },

    methods: {

        on_item_click(item, index, field) {

            if (field == 'contraList') {

                if (this.contraList.includes(item)) {
                    this.contraList.splice(this.contraList.indexOf(item), 1)
                    this.mainList.push(item)
                } else {
                    this.contraList.push(item)
                    this.mainList.splice(index, 1) 
                }

            } else if (field == 'proList') {

                if (this.proList.includes(item)) {
                    this.proList.splice(this.proList.indexOf(item), 1)
                    this.mainList.push(item)
                } else {
                    this.proList.push(item)
                    this.mainList.splice(index, 1) 
                }

            }

            if (this.mainList.length == 0) {

                var i
                var falsePro = []
                var falseContra = []

                for (i = 0; i < this.proList.length; i++) {

                    if (this.proList[i].assignmentType == 'contra') {

                        falsePro.push(this.proList[i].assignmentText)
                    }
                }

                for (i = 0; i < this.contraList.length; i++) {

                    if (this.contraList[i].assignmentType == 'pro') {

                        falseContra.push(this.contraList[i].assignmentText)
                    }
                }

                if (falsePro.length == 0 & falseContra.length == 0) {

                    this.alert_icon = 'far fa-check-circle'
                    this.alert_color = 'success'
                    this.alert_text = '<b>Sehr gut!</b> Sie haben alle Items richtig zugeordnet.'
                    this.alert = true

                    this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': true})  

                } else {

                    if (falsePro.length == 0 & falseContra.length != 0) {

                        this.alert_icon = 'fas fa-exclamation-triangle'
                        this.alert_color = "error"
                        this.alert_text = '<b>Das ist leider falsch.</b> Falsch zugeordnete Items können Sie an der roten Farbe erkennen.'
                        this.alert = true 

                        this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false})  

                    } else if (falsePro.length != 0 & falseContra.length == 0) {

                        this.alert_icon = 'fas fa-exclamation-triangle'
                        this.alert_color = "error"
                        this.alert_text = '<b>Das ist leider falsch.</b> Falsch zugeordnete Items können Sie an der roten Farbe erkennen.'
                        this.alert = true

                        this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false})  

                    } else {

                        this.alert_icon = 'fas fa-exclamation-triangle'
                        this.alert_color = "error"
                        this.alert_text = '<b>Das ist leider falsch.</b> Falsch zugeordnete Items können Sie an der roten Farbe erkennen.'
                        this.alert = true

                        this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false})  

                    }
                }
                
            } else {

                this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false})  

                this.alert = false

            }

        },

        getCorrectColor(where, item) {

            if (where == 'pro' && item.assignmentType == 'pro') {

                return '#4CAF50'

            } else if (where == 'contra' && item.assignmentType == 'contra') {

                return '#4CAF50'

            } else if (item.assignmentType == 'both') {

                return '#4CAF50'
            
            } else {
                return '#FF5252'
            }

        },

        dynamicSort(property) {

            var sortOrder = 1;

            if(property[0] === "-") {

                sortOrder = -1;
                property = property.substr(1);

            }

            return function (a,b) {

                var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                return result * sortOrder;
                
            }
        },
  }

}
</script>

<style scoped>

</style>


