<template>

    <div class="mx-3">

        <v-row align="center" justify="center">

          <v-icon class="mr-3 mt-5" v-if="data.iconSet == 'thermometer' && !$vuetify.breakpoint.smAndDown" large color="primary">fas fa-thermometer-empty</v-icon>
          <v-icon class="mr-3 mt-5" v-if="data.iconSet == 'smiley' && !$vuetify.breakpoint.smAndDown" large color="primary">far fa-sad-cry</v-icon>
          <v-icon class="mr-3 mt-5" v-if="data.iconSet == 'battery' && !$vuetify.breakpoint.smAndDown" large color="primary">fas fa-battery-empty</v-icon>

            <v-radio-group 
                v-model="data.radios" 
                :row="$vuetify.breakpoint.smAndDown ? false : true"
                hide-details
            >

                <div class="mb-3" v-if="$vuetify.breakpoint.smAndDown">
                    <v-icon v-if="iconSet == 'thermometer'" large color="primary">fas fa-thermometer-empty</v-icon>
                    <v-icon v-if="iconSet == 'smiley'" large color="primary">far fa-sad-cry</v-icon>
                    <v-icon v-if="iconSet == 'battery'" large color="primary">fas fa-battery-empty</v-icon>
                </div>


                <v-radio
                    v-for="index in values"
                    :key="index.name"
                    disabled
                    color="accent" 
                    :value="index"
                    :label="index"
                ></v-radio>

                <div class="mb-3" v-if="$vuetify.breakpoint.smAndDown">
                    <v-icon v-if="data.iconSet == 'thermometer'" large color="primary">fas fa-thermometer-full</v-icon>
                    <v-icon v-if="data.iconSet == 'smiley'" large color="primary">far fa-laugh</v-icon>
                    <v-icon v-if="data.iconSet == 'battery'" large color="primary">fas fa-battery-full</v-icon>
                </div>

                <v-icon class="ml-3" v-if="data.iconSet == 'thermometer' && !$vuetify.breakpoint.smAndDown" large color="primary">fas fa-thermometer-full</v-icon>
                <v-icon class="ml-3" v-if="data.iconSet == 'smiley' && !$vuetify.breakpoint.smAndDown" large color="primary">far fa-laugh</v-icon>
                <v-icon class="ml-3" v-if="data.iconSet == 'battery' && !$vuetify.breakpoint.smAndDown" large color="primary">fas fa-battery-full</v-icon>

            </v-radio-group>
        </v-row>

    </div>

</template>

<script>
export default {
    name: 'traditional-likert-scale-data',
    
    props: ['data'],

    data: () => ({

      values: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],


    }),

}   
</script>

<style scoped>

</style>