<template>

    <div v-if="loaded">

        <v-card
            color="grey lighten-5"
            flat
            height="auto"
        >

            <v-expansion-panels flat v-model="first_panel">

                <v-expansion-panel>

                    <v-expansion-panel-header color="grey lighten-5">

                        <v-card flat class="py-0 my-0" color="grey lighten-5">

                            <v-card-text class="pl-0 title primary--text py-0 my-0">
                                Ausstehende Auszahlungen
                            </v-card-text>

                        </v-card>

                        <template v-slot:actions>
                            <v-icon color="primary">$expand</v-icon>
                        </template>
                    </v-expansion-panel-header>

                    <v-divider />

                    <v-expansion-panel-content color="grey lighten-5">

                        <v-card-text class="mx-0 px-0 py-0 my-0">

                            <v-card flat outlined color="grey lighten-5" class="mx-0 px-0">

                                <v-card-text class="mx-0 px-0">

                                    <v-text-field
                                        v-model="search_open_payments"
                                        append-icon="fas fa-search"
                                        label="Suche"
                                        single-line
                                        hide-details
                                    ></v-text-field>

                                </v-card-text>

                            </v-card>

                            <v-divider class="my-3"/>

                            <v-data-table
                                :headers="headers_payment"
                                :items="open_payments"
                                :search="search_open_payments"
                            >

                                <template v-slot:item.client__user__username="{ item }">
                                    <span class="subtitle-2">{{ item.client__user__username }}</span>
                                </template>

                                <template v-slot:item.order_received="{ item }">
                                    <span class="subtitle-2">{{ getCorrectDateTime(item.order_received) }}</span>
                                </template>

                                <template v-slot:item.spended_euros="{ item }">
                                    <span class="subtitle-2">- {{ item.spended_euros }}</span>
                                </template>

                                <template v-slot:item.coupon_sended="{ item }">
                                    <span class="subtitle-2" v-if="item.coupon_sended">
                                        <v-icon color="success">fas fa-check</v-icon>
                                        <span class="ml-3">(am {{ getCorrectDateTime(item.coupon_sended_time) }})</span>
                                    </span>
                                    <span class="subtitle-2" v-else>
                                        <v-icon color="error">fas fa-times</v-icon>
                                    </span>
                                </template>

                                <template v-slot:item.supervisor_coupon_received="{ item }">
                                    <span class="subtitle-2" v-if="item.supervisor_coupon_received">
                                        <v-icon color="success">fas fa-check</v-icon>
                                        <span class="ml-3">(am {{ getCorrectDateTime(item.supervisor_coupon_received_time) }})</span>
                                    </span>
                                    <span class="subtitle-2" v-else>
                                        <v-icon color="error">fas fa-times</v-icon>
                                        <span class="ml-3">
                                            <v-btn small color="error" outlined @click="coupon_received(item)">Eingang bestätigen</v-btn>
                                        </span>
                                    </span>
                                </template>

                                <template v-slot:item.client_coupon_received="{ item }">
                                    <span class="subtitle-2" v-if="item.client_coupon_received">
                                        <v-icon color="success">fas fa-check</v-icon>
                                        <span class="ml-3">(am {{ getCorrectDateTime(item.client_coupon_received_time) }})</span>
                                    </span>
                                    <span class="subtitle-2" v-else>
                                        <v-icon color="error">fas fa-times</v-icon>
                                        <span class="ml-3">
                                            <v-btn small color="error" outlined @click="client_coupon_received(item)" :disabled="!item.supervisor_coupon_received">Übergabe bestätigen</v-btn>
                                        </span>
                                    </span>
                                </template>

                                <template v-slot:no-data>

                                    <v-alert
                                        outlined
                                        type="success"
                                        prominent
                                        border="left"
                                        class="mt-3"
                                    >
                                        Es liegen keine ausstehenden Auszahlungen vor.
                                    </v-alert>

                                </template>

                            </v-data-table>

                        </v-card-text>

                    </v-expansion-panel-content>

                </v-expansion-panel>

            </v-expansion-panels>

        </v-card>

        <v-divider class="my-5"/>

        <v-card
            color="grey lighten-5"
            flat
            height="auto"
        >

            <v-expansion-panels flat>

                <v-expansion-panel>

                    <v-expansion-panel-header color="grey lighten-5">

                        <v-card flat class="py-0 my-0" color="grey lighten-5">

                            <v-card-text class="pl-0 title primary--text py-0 my-0">
                                Vorgenommene Auszahlungen
                            </v-card-text>

                        </v-card>

                        <template v-slot:actions>
                            <v-icon color="primary">$expand</v-icon>
                        </template>
                    </v-expansion-panel-header>

                    <v-divider />

                    <v-expansion-panel-content color="grey lighten-5">

                        <v-card-text class="mx-0 px-0 py-0 my-0">

                            <v-card flat outlined color="grey lighten-5" class="mx-0 px-0">

                                <v-card-text class="mx-0 px-0">

                                    <v-text-field
                                        v-model="search_finished_payments"
                                        append-icon="fas fa-search"
                                        label="Suche"
                                        single-line
                                        hide-details
                                    ></v-text-field>

                                </v-card-text>

                            </v-card>

                            <v-divider class="my-3"/>

                            <v-data-table
                                :headers="headers_payment"
                                :items="finished_payments"
                                :search="search_finished_payments"
                            >

                                <template v-slot:item.client__user__username="{ item }">
                                    <span class="subtitle-2">{{ item.client__user__username }}</span>
                                </template>

                                <template v-slot:item.order_received="{ item }">
                                    <span class="subtitle-2">{{ getCorrectDateTime(item.order_received) }}</span>
                                </template>

                                <template v-slot:item.spended_euros="{ item }">
                                    <span class="subtitle-2">- {{ item.spended_euros }}</span>
                                </template>

                                <template v-slot:item.coupon_sended="{ item }">
                                    <span class="subtitle-2" v-if="item.coupon_sended">
                                        <v-icon color="success">fas fa-check</v-icon>
                                        <span class="ml-3">(am {{ getCorrectDateTime(item.coupon_sended_time) }})</span>
                                    </span>
                                    <span class="subtitle-2" v-else>
                                        <v-icon color="error">fas fa-times</v-icon>
                                    </span>
                                </template>

                                <template v-slot:item.supervisor_coupon_received="{ item }">
                                    <span class="subtitle-2" v-if="item.supervisor_coupon_received">
                                        <v-icon color="success">fas fa-check</v-icon>
                                        <span class="ml-3">(am {{ getCorrectDateTime(item.supervisor_coupon_received_time) }})</span>
                                    </span>
                                    <span class="subtitle-2" v-else>
                                        <v-icon color="error">fas fa-times</v-icon>
                                        <span class="ml-3">
                                            <v-btn small color="error" outlined @click="coupon_received(item)">Eingang bestätigen</v-btn>
                                        </span>
                                    </span>
                                </template>

                                <template v-slot:item.client_coupon_received="{ item }">
                                    <span class="subtitle-2" v-if="item.client_coupon_received">
                                        <v-icon color="success">fas fa-check</v-icon>
                                        <span class="ml-3">(am {{ getCorrectDateTime(item.client_coupon_received_time) }})</span>
                                    </span>
                                    <span class="subtitle-2" v-else>
                                        <v-icon color="error">fas fa-times</v-icon>
                                        <span class="ml-3">
                                            <v-btn small color="error" outlined @click="client_coupon_received(item)">Übergabe bestätigen</v-btn>
                                        </span>
                                    </span>
                                </template>

                                <template v-slot:no-data>

                                    <v-alert
                                        outlined
                                        type="success"
                                        prominent
                                        border="left"
                                        class="mt-3"
                                    >
                                        Es wurden noch keine Aufwandsentschädigungen ausgezahlt.
                                    </v-alert>

                                </template>

                            </v-data-table>

                        </v-card-text>

                    </v-expansion-panel-content>

                </v-expansion-panel>

            </v-expansion-panels>

        </v-card>

        <!-- client_coupon_received_dialog -->
        <v-dialog
            v-model="client_coupon_received_dialog"
            persistent
            max-width="500"
        >
            <v-card outlined>

                <v-toolbar flat color="transparent" class="my-3">
                    <v-icon x-large color="accent">fas fa-hand-holding-usd</v-icon>
                    <v-card-text class="headline ml-4 primary--text">Bestätigung</v-card-text>
                </v-toolbar>

                <v-divider />

                    <v-card-text class="text-center body-1 mt-5">
                        Hiermit bestätige ich die Übergabe des Gutscheines in Höhe von insgesamt <b>{{client_coupon_received_item.spended_euros}} Euro</b> an meine*n Klientin*Klienten
                        <b>{{ client_coupon_received_item.client__user__username }}</b>.
                    </v-card-text>

                <v-divider />

                <v-card-actions class="my-3">

                    <v-btn
                        outlined
                        rounded
                        @click="client_coupon_received_dialog = false"
                    >
                        Schließen
                    </v-btn>

                    <v-spacer />

                    <v-btn
                        outlined
                        rounded
                        color="primary"
                        @click="client_coupon_received_send(client_coupon_received_item)"
                        :loading="loading_client_coupon_received_dialog"
                    >
                        Übergabe bestätigen
                    </v-btn>

                </v-card-actions>
                
            </v-card>

        </v-dialog>

        <!-- client_coupon_received_dialog -->
        <v-dialog
            v-model="coupon_received_dialog"
            persistent
            max-width="500"
        >
            <v-card outlined>

                <v-toolbar flat color="transparent" class="my-3">
                    <v-icon x-large color="accent">fas fa-hand-holding-usd</v-icon>
                    <v-card-text class="headline ml-4 primary--text">Bestätigung</v-card-text>
                </v-toolbar>

                <v-divider />

                    <v-card-text class="text-center body-1 mt-5">
                        Hiermit bestätige ich den Eingang eines Gutscheines in Höhe von insgesamt <b>{{coupon_received_item.spended_euros}} Euro</b> für meine*n Klientin*Klienten
                        <b>{{ coupon_received_item.client__user__username }}</b>.
                    </v-card-text>

                <v-divider />

                <v-card-actions class="my-3">

                    <v-btn
                        outlined
                        rounded
                        @click="coupon_received_dialog = false"
                    >
                        Schließen
                    </v-btn>

                    <v-spacer />

                    <v-btn
                        outlined
                        rounded
                        color="primary"
                        @click="coupon_received_send(coupon_received_item)"
                        :loading="loading_coupon_received_dialog"
                    >
                        Eingang bestätigen
                    </v-btn>

                </v-card-actions>
                
            </v-card>

        </v-dialog>




    </div>

</template>

<script>
import SupervisorApi from '@/_api/supervisor_api'
import DateTimeHelpers from '@/_helpers/date_time_helpers'

export default {
    
    name: 'supervisor_payments',

    data: () => ({

        loaded: false,

        search_open_payments: '',
        search_finished_payments: '',

        client_coupon_received_item: [],
        client_coupon_received_dialog: false,
        loading_client_coupon_received_dialog: false,
        coupon_received_item: [],
        coupon_received_dialog: false,
        loading_coupon_received_dialog: false,

        first_panel: 0,

        open_payments: [],
        finished_payments: [],

        headers_payment: [
            {
                text: 'Klient*in',
                align: 'center',
                sortable: false,
                value: 'client__user__username',
                class: "body-1 primary--text"
            },
            {
                text: 'Beantragt am',
                align: 'center',
                sortable: false,
                value: 'order_received',
                class: "body-1 primary--text"
            },
            {
                text: 'Betrag (Euro)',
                align: 'center',
                sortable: false,
                value: 'spended_euros',
                class: "body-1 primary--text"
            },
            {
                text: 'Gutschein verschickt',
                align: 'center',
                sortable: false,
                value: 'coupon_sended',
                class: "body-1 primary--text"
            },
            {
                text: 'Gutschein eingegangen',
                align: 'center',
                sortable: false,
                value: 'supervisor_coupon_received',
                class: "body-1 primary--text"
            },
            {
                text: 'Gutschein übergeben',
                align: 'center',
                sortable: false,
                value: 'client_coupon_received',
                class: "body-1 primary--text"
            },
        ],

    }),

    created() {

        this.loade = false

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        SupervisorApi.GetSupervisorInfo().then(() => {

            SupervisorApi.GetOpenPayments().then((resp) => {

                this.open_payments = resp.data.data_open_payments
                this.finished_payments = resp.data.data_finished_payments

                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                this.loaded = true

            })

        })

    },

    computed: {


    },

    methods: {

        changeFirstPanel() {
            this.first_panel = !this.first_panel
        },

        changeSecondPanel() {
            this.second_panel = !this.second_panel
        },

        getCorrectDateTime(item) {
            return DateTimeHelpers.convertDatabaseDateTimeString('only_date', item)
        },

        coupon_received(item) {

            this.coupon_received_item = item
            this.coupon_received_dialog = true

        },

        client_coupon_received(item) {

            this.client_coupon_received_item = item
            this.client_coupon_received_dialog = true

        },

        client_coupon_received_send(item) {

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)
            this.loading_client_coupon_received_dialog = true

            SupervisorApi.UpdateOpenPaymentsClientReceived({id: item.id}).then(() => {

                SupervisorApi.GetSupervisorInfo().then(() => {

                    SupervisorApi.GetOpenPayments().then((resp) => {

                        this.open_payments = resp.data.data_open_payments
                        this.finished_payments = resp.data.data_finished_payments

                        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                        this.loaded = true

                        this.loading_client_coupon_received_dialog = false
                        
                        this.client_coupon_received_dialog = false

                    })

                })

            })
        },

        coupon_received_send(item) {

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)
            this.loading_coupon_received_dialog = true

            SupervisorApi.UpdateOpenPaymentsReceived({id: item.id}).then(() => {

                SupervisorApi.GetSupervisorInfo().then(() => {

                    SupervisorApi.GetOpenPayments().then((resp) => {

                        this.open_payments = resp.data.data_open_payments
                        this.finished_payments = resp.data.data_finished_payments

                        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                        this.loaded = true

                        this.loading_coupon_received_dialog = false
                        
                        this.coupon_received_dialog = false

                    })

                })

            })

        }


    },
    
}
</script>

<style scoped>

</style>