<template>

    <div class="mx-3" v-if="ready">

        <v-row class="mt-5 mb-3" v-if="showError">
            <v-alert
                :value="showError"
                color="error"
                icon="fas fa-exclamation-triangle"
                outlined
            >
                {{ infoMessage }}
            </v-alert>
        </v-row>

        <v-row v-if="!showError">
            <div class="headline primary--text">{{ question }}</div>
        </v-row>

        <v-row v-if="!showError">
            <div class="overline mb-3"><b>Tipp:</b> Sie müssen alle Aussagen/Stichwörter hierarchisch ordnen.</div>
        </v-row>

        <v-row v-if="!showError">

            <v-row>

                <v-col cols="12" sm="12">

                    <v-card
                        class="mx-auto"
                        outlined
                    >

                        <v-card-text class="text-center headline primary--text font-weight-bold">Meine Reihenfolge:</v-card-text>

                        <v-divider />

                        <v-row justify="center" align="center">

                            <v-col 
                                cols="12" 
                                sm="12"
                                v-for="(item, i) in hierarchyList"
                                :key="i"
                            >

                                <v-card class="mx-3" outlined flat>

                                    <v-card-text class="text-center">
                                        <v-avatar color="accent" size="36" class="mb-3">
                                            <b>{{i + 1}}</b>
                                        </v-avatar>
                                            
                                        <v-menu offset-y z-index="1" min-width="300">

                                            <template v-slot:activator="{ on, attrs }">
                                                
                                                <v-card
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    v-if="hierarchyList[i] == undefined"
                                                    :disabled="thisBtnDisabled"
                                                >

                                                    <v-alert outlined color="primary" class="pa-0">
                                                        <v-card-text class="text-center body-1 pa-2">Aussage/Stichwort einfügen</v-card-text>
                                                    </v-alert>
                                                </v-card>

                                                <v-card
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    v-else
                                                    :disabled="thisBtnDisabled"
                                                >
                                                    <v-alert outlined color="primary" class="pa-0">
                                                        <v-card-text class="text-center body-1 pa-2">{{ hierarchyList[i].assignmentText }}</v-card-text>
                                                    </v-alert>
                                                </v-card>
                                            </template>

                                            <v-list subheader two-line v-if="hierarchyList[i] == undefined">

                                                <v-list-item
                                                    v-for="(item_main, index) in mainList"
                                                    :key="index"
                                                    @click="add_item(item_main, i)"
                                                >

                                                    <v-list-item-avatar>
                                                        <v-icon color="primary">far fa-plus-square</v-icon>
                                                    </v-list-item-avatar>
                                                    
                                                    <v-list-item-content>
                                                        <div class="font-weight-medium primary--text">{{ item_main.assignmentText }}</div>
                                                        <div class="body-2 font-weight-light"><i>Klicken</i> um diesem Feld zuzuordnen.</div>
                                                    </v-list-item-content>

                                                </v-list-item>

                                            </v-list>

                                            <v-list subheader two-line v-else>

                                                <v-list-item
                                                    @click="delete_item(hierarchyList[i])"
                                                >

                                                    <v-list-item-avatar>
                                                        <v-icon color="primary">far fa-times-circle</v-icon>
                                                    </v-list-item-avatar>
                                                    
                                                    <v-list-item-content>
                                                        <div class="font-weight-medium primary--text">{{ hierarchyList[i].assignmentText }}</div>
                                                        <div class="body-2 font-weight-light"><i>Klicken</i> um aus diesem Feld zu entfernen.</div>
                                                    </v-list-item-content>

                                                </v-list-item>

                                            </v-list>

                                        </v-menu>

                                    </v-card-text>

                                </v-card>

                            </v-col>

                        </v-row>

                    </v-card>

                </v-col>

            </v-row>

        </v-row>

    </div>
    
</template>

<script>
import axios from 'axios'
import _ from 'lodash'

export default {
    name: 'default-hierarchy-assignment-form',
    props: ['taskID', 'question', 'id', 'module_nr', 'session_nr', 'lesson_nr', 'dataIdentifier'],

    data: () => ({
      
      mainList: [],
      hierarchyList: [],

      showError: false,
      infoMessage: 'Es existieren keine Daten zur Übung mit der ID xxx in der Datenbank.',

      ready: false,


    }),

    created () {

        this.ready = false

        if (_.isEmpty(this.$store.getters['store_lection/get_saved_lection_task_data_by_identifier'](this.taskID))) {

            // get client lession data
            var e1 = '/client/client-lection-data/?module_nr=' + this.module_nr  
            var e2 = '&session_nr=' + this.session_nr
            var e3 = '&lession_nr=' + this.lesson_nr
            var e4 = '&dataIdentifier=' + this.dataIdentifier
            var endpoint = e1 + e2 + e3 + e4

            axios.get(endpoint)
            
            .then((response) => {

                if (_.isEmpty(response.data[0].task_data[this.dataIdentifier])) {

                    this.showError = true
                    this.infoMessage = "Es existieren keine Daten zur Übung mit der ID " + this.dataIdentifier + '!'

                    this.ready = true
                    
                } else if (response.data[0].task_data[this.dataIdentifier]) {

                    var i;

                    // collectKeywordsForm
                    if (response.data[0].task_data[this.dataIdentifier].type == 'collectKeywordsForm') {

                        for (i = 0; i < response.data[0].task_data[this.dataIdentifier].keywordList.length; i++) {
                            this.mainList.push(response.data[0].task_data[this.dataIdentifier].keywordList[i])
                            this.hierarchyList.push(null)
                        }

                    } else if (response.data[0].task_data[this.dataIdentifier].type == 'multipleChoicePickNForm') {

                        // multipleChoicePickNForm
                        for (i = 0; i < response.data[0].task_data[this.dataIdentifier].checkbox.length; i++) {

                            if (response.data[0].task_data[this.dataIdentifier].checkbox[i] == true) {

                                this.mainList.push(response.data[0].task_data[this.dataIdentifier].labels[i])
                                this.hierarchyList.push(null)

                            }
                        }

                    } else if (response.data[0].task_data[this.dataIdentifier].type == 'multipleChoicePickNFormPlus') {

                        // multipleChoicePickNFormPlus
                        for (i = 0; i < response.data[0].task_data[this.dataIdentifier].checkbox.length; i++) {

                            if (response.data[0].task_data[this.dataIdentifier].checkbox[i] == true) {

                                this.mainList.push(response.data[0].task_data[this.dataIdentifier].labels[i])
                                this.hierarchyList.push(null)

                            }
                        }

                    } else if (response.data[0].task_data[this.dataIdentifier].type == 'multipleChoicePickNTask') {

                        // multipleChoicePickNTask
                        for (i = 0; i < response.data[0].task_data[this.dataIdentifier].checkbox.length; i++) {

                            if (response.data[0].task_data[this.dataIdentifier].checkbox[i] == true) {

                                this.mainList.push(response.data[0].task_data[this.dataIdentifier].labels[i])
                                this.hierarchyList.push(null)

                            }
                        }

                    } else {

                        this.showError = true
                        this.infoMessage = "Es wurde eine Übung mit der ID " + this.dataIdentifier + ' eingegeben. Diese Übung wird nicht unterstüztzt!'

                    }

                    // define task data store
                    this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'mainList', 'data': this.mainList})
                    this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'assignmentList', 'data': this.mainList})
                    this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'hierarchyList', 'data': this.hierarchyList})
                    this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'type', 'data': 'dataHierarchyAssignmentForm'})      
                
                    this.ready = true

                }

            })
            .catch(error => {
                this.showError = true
                this.infoMessage = error
            })

        } else {

            // get data
            var data = this.$store.getters['store_lection/get_saved_lection_task_data_by_identifier'](this.taskID)
            this.mainList = data.mainList
            this.hierarchyList = data.hierarchyList

            this.ready = true
        }
    },

    watch: {

      mainList: function(val) {

        if (val.length != 0 && this.ready) {

            this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false})
        }

        // update store variable
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'mainList', 'data': val})
      
      },

      hierarchyList: function(val) {

        // update store variable
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'hierarchyList', 'data': val})
      
      },

    },

    computed: {
       
       thisBtnDisabled () {

            var tmp = this.$store.getters['store_lection/get_saved_lection_data']
                
            if (tmp.data[this.id].finished == false) {

                return false

            } else {

                return true

            }

        },

    },

    methods: {

        add_item(item, index) {

            this.hierarchyList[index] = item
            this.mainList.splice(this.mainList.indexOf(item), 1)

            if (this.mainList.length == 0) {

                this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': true})

            } else {

                this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false})

            }

        },

        delete_item(item) {

            var index = this.hierarchyList.indexOf(item)

            this.hierarchyList[index] = null
            this.mainList.push(item)

            if (this.mainList.length == 0) {

                this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': true})

            } else {

                this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false})

            }

        },

  }

}
</script>

<style scoped>

</style>


