<template>

        <v-container fluid v-if="loaded">

        <!-- KLIENTEN -->

        <v-card flat>
        
            <v-card-title class="display-1">
                CTE
            </v-card-title>

            <v-card-text>

                <v-card flat outlined>

                    <v-expansion-panels flat>

                        <v-expansion-panel>

                            <v-expansion-panel-header>

                                <v-card flat class="py-0 my-0">

                                    <v-card-text class="pl-0 title py-0 my-0">
                                        Informationen zur Checklist of Treatment Effectiveness (CTE)
                                    </v-card-text>

                                </v-card>

                                <template v-slot:actions>
                                    <v-icon color="primary">$expand</v-icon>
                                </template>
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>

                                <v-card flat class="mt-3">

                                    <v-card-text class="black--text px-0 mx-0">
                                        <p>
                                            Die Checklist of Treatment Effectiveness (CTE) ist ein Selbstbeschreibungsverfahren zur Erfassung behandlungsbezogener Veränderungen. 
                                            Die CTE orientiert sich dabei an den Inhalten der im Rahmen des Verbundprojektes @myTabu entwickelten Online-Intervention und 
                                            somit an empirisch fundierten Risikofaktoren. 
                                        </p>
                                        <p>
                                            Mit 58 Items erfasst die CTE unterschiedliche Erlebensweisen und Einstellungen. Dabei erfolgt eine Unterteilung in sechs Themenbereiche: 
                                            (1) Motivation, (2) Soziale Unterstützung, (3) Deliktfördernde Kognitionen, (4) Umgang mit Emotionen, (5) Problemlösungsstrategien 
                                            und (6) Sexualität. Diese werden im Folgenden näher beschrieben: 
                                        </p>
                                        <p>
                                            <i>Fehlende Motivation:</i> Diese Subskala umfasst die Therapiemotivation sowie die Motivation bzw. Bereitschaft für 
                                            Veränderungen und das damit zusammenhängende Selbstwirksamkeitserleben der Person 
                                        </p>
                                        <p>
                                            <i>Fehlende Soziale Unterstützung:</i> Dies umfasst das Ausmaß der sozialen Unterstützung. Es werden positive 
                                            und negative soziale Einflüsse berücksichtigt. Außerdem werden die soziale Kompetenz bzw. die Beziehungsfähigkeit im Hinblick auf 
                                            erwachsene Personen, sowohl in freundschaftlicher als auch in partnerschaftlicher Hinsicht sowie die Wahrnehmung der Bewährungshilfe 
                                            als Unterstützung berücksichtigt.
                                        </p>
                                        <p>
                                            <i>Deliktfördernde Kognitionen:</i> Dies umfasst kognitive Verzerrungen von Kindesmissbrauchstätern und 
                                            Kinderpornographie-Konsumenten, die sich deliktfördernd auswirken können.
                                        </p>
                                        <p>
                                            <i>Fehlendes Emotionales Coping/Emotionsregulation:</i> Diese Subskala beschreibt den Umgang mit Emotionen der Person. 
                                            Dabei liegt der Fokus insbesondere auf der Wahrnehmung von und Auseinandersetzung mit Gefühlen. Unangenehmen Emotionen wird in dieser Subskala 
                                            eine besondere Relevanz beigemessen. Übergeordnet spielt das Thema Achtsamkeit eine wichtige Rolle. 
                                        </p>
                                        <p>
                                            <i>Fehlende Problemlösungsstrategien:</i> Diese Subskala umfasst die Problemorientierung und die Problemlösefähigkeiten mit 
                                            unterschiedlichen Problemlösestilen (rational, impulsiv, vermeidend). Darüber hinaus wird das Selbstwirksamkeitserleben in Bezug auf 
                                            die eigenen Problemlösefähigkeiten erfasst.
                                        </p>
                                        <p>
                                            <i>Pädosexualität:</i> Diese Subskala umfasst verschiedene sexuelle Thematiken, die bei Kindesmissbrauchstätern und 
                                            Kinderpornographie-Konsumenten eine wichtige Rolle spielen. Dazu gehören neben der sexuellen Überbeschäftigung das sexuelle Interesse 
                                            an Kindern und die emotionale Identifikation mit Kindern sowie die Überzeugung und das Selbstwirksamkeitserleben, in Zukunft das 
                                            sexuelle Interesse an Kindern verändern zu wollen bzw. zu können und Kontakte zu Kindern zu vermeiden.
                                        </p>
                                        <p>
                                            Es ist zu beachten, dass einige Items invertiert sind. Bei invertierten Items werden für die Antwort stimme zu zwei Punkte vergeben, 
                                            für die Antwort stimme ein bisschen zu ein Punkt und für die Antwort stimme nicht zu kein Punkt. Dies wurde in der unten stehenden 
                                            Tabelle bereits berücksichtigt.
                                        </p>
                                    </v-card-text>

                                </v-card>

                            </v-expansion-panel-content>

                        </v-expansion-panel>

                    </v-expansion-panels>

                </v-card>

                <v-card flat outlined class="mt-5">

                    <v-card-text class="primary--text body-1">
                        <b>Wichtig:</b> Nutzen Sie diese Daten keinesfalls als alleinige Basis für eine Entscheidung. Die Daten stammen aus einer laufenden Studie, 
                        es ist wichtig, dass Sie die erhaltenen Informationen erst im Gespräch mit Ihrem*Ihrer Klient*in oder mithilfe eines Ihrer bereits 
                        etablierten Instrumente überprüfen, bevor Sie sich für ein weiteres Vorgehen entscheiden.
                    </v-card-text>

                </v-card>

            </v-card-text>

            <v-card-text>
                <v-card flat outlined>

                    <v-card-text>
                        <v-row>

                            <v-col cols="12" sm="12" md="6">
                                <v-autocomplete
                                    v-model="dropdown_client_value"
                                    :items="dropdown_client"
                                    dense
                                    label="Welche*r Klient*in"
                                    @change="update_client"
                                    :loading="loading_dropdown_client"
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="12" sm="12" md="6">
                                <v-autocomplete
                                    :items="dropdown_timepoints"
                                    label="Messzeitpunkt"
                                    dense
                                    v-model="dropdown_timepoints_value"
                                    @change="update_timepoint"
                                ></v-autocomplete>
                            </v-col>

                        </v-row>

                    </v-card-text>

                </v-card>
            </v-card-text>

            <v-card-text>
                <v-card flat outlined>
            
                    <v-data-table
                        :headers="headers"
                        :items="current_data"
                        :items-per-page="58"
                        hide-default-footer
                        no-data-text="Für diese*diesen Klienten*Klientin liegen noch keine Daten vor."
                    >

                        <template v-slot:item.item="{ item }">
                            <span class="subtitle-1 primary--text">{{ item.item }}</span>
                        </template>

                         <template v-slot:item.raw_value_2="{ item }">
                            <span v-if="item.risk_value == -999">-</span>
                            <span v-else class="subtitle-1 text-center">{{item.risk_value}}</span>
                        </template>

                        <template v-slot:item.risk_value="{ item }">
                           <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    
                                    <span v-bind="attrs" v-on="on">
                                        <v-row align="center" justify="center">
                                            <v-icon :color="item.risk_value | getIconColorOne">{{ item.risk_value | getIconOne }}</v-icon>
                                            <v-icon :color="item.risk_value | getIconColorTwo">{{ item.risk_value | getIconTwo }}</v-icon>
                                            <v-icon :color="item.risk_value | getIconColorThree">{{ item.risk_value | getIconThree }}</v-icon>
                                        </v-row>
                                    </span>

                                </template>

                                <!-- CHOICES_1_reverse -->
                                <span v-if="is_reverse_item(item)">
                                    <v-icon color="green lighten-1" small>fas fa-circle</v-icon><span class="ml-1 mr-3 body-2">Stimme nicht zu</span>
                                    <v-icon color="orange lighten-1" small>fas fa-circle</v-icon><span class="ml-1 mr-3 body-2">Stimme ein bisschen zu</span>
                                    <v-icon color="red lighten-1" small>fas fa-circle</v-icon><span class="ml-1 body-2">Stimme zu</span>
                                </span>

                                <!-- CHOICES_1 -->
                                <span v-else>
                                    <v-icon color="green lighten-1" small>fas fa-circle</v-icon><span class="ml-1 mr-3 body-2">Stimme zu</span>
                                    <v-icon color="orange lighten-1" small>fas fa-circle</v-icon><span class="ml-1 mr-3 body-2">Stimme ein bisschen zu</span>
                                    <v-icon color="red lighten-1" small>fas fa-circle</v-icon><span class="ml-1 body-2">Stimme nicht zu</span>
                                </span>
                            </v-tooltip>
                        </template>

                        <template v-slot:item.raw_value="{ item }">
                            <span><v-btn color="primary" outlined @click="open_curve(item.item)">Verlauf</v-btn></span>
                        </template>

                    </v-data-table>

                </v-card>
            </v-card-text>

        </v-card>

        <!-- MONEY HAS PAYED DIALOG -->
    <v-dialog
        v-model="show_curve"
        persistent
        max-width="800"
    >
        <v-card outlined>

            <v-card-text class="title primary--text text-center mt-5">
                {{ show_curve_title }}
            </v-card-text>

            <v-divider />

            <v-card-text class="mt-5 caption text-center">

                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left title">
                                    Messzeitpunkt
                                </th>
                                <th class="text-center title">
                                    Wert
                                </th>
                                <th class="text-center title">
                                    Einschätzung
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, i) in current_curve_data.labels"
                                :key="item.name"
                            >
                                <td class="text-left">{{ item }}</td>
                                <td class="text-center">{{ current_curve_data.values[i] }}</td>
                                <td class="text-center">
                                    <v-row align="center" justify="center">
                                        <v-icon :color="current_curve_data.values[i] | getIconColorOne()">{{ current_curve_data.values[i] | getIconOne() }}</v-icon>
                                        <v-icon :color="current_curve_data.values[i] | getIconColorTwo()">{{ current_curve_data.values[i] | getIconTwo() }}</v-icon>
                                        <v-icon :color="current_curve_data.values[i] | getIconColorThree()">{{ current_curve_data.values[i] | getIconThree() }}</v-icon>
                                    </v-row>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                
            </v-card-text>

            <v-divider />

            <v-card-actions class="my-3">
                
                <v-spacer />

                <v-btn
                    outlined
                    rounded
                    @click="show_curve = false"
                >
                    Schließen
                </v-btn>
            </v-card-actions>
            
        </v-card>

    </v-dialog>

    </v-container>

</template>

<script>
import SupervisorApi from '@/_api/supervisor_api'

export default {
    name: 'supervisor-risk-cte',

    components: {
        
    },

    data: () => ({

        loaded: false,

        search: '',

        all_data: {},

        loading_dropdown_client: false,

        dropdown_client: [],
        dropdown_client_value: null,
        dropdown_timepoints: [],
        dropdown_timepoints_value: null,
        
        headers: [
          {
            text: 'Item',
            align: 'left',
            sortable: false,
            value: 'item',
            class: "black--text subtitle-1 font-weight-bold"
          },
          { 
              text: 'Rohwert', 
              value: 'raw_value_2',
              align: 'center',
              class: "black--text subtitle-1 font-weight-bold"
          },
          { 
              text: 'Einschätzung', 
              value: 'risk_value',
              align: 'center',
              class: "black--text subtitle-1 font-weight-bold"
          },
          { 
              text: 'Verlauf', 
              value: 'raw_value',
              sortable: false,
              align: 'center',
              class: "black--text subtitle-1 font-weight-bold"
          },
        ],

        current_data: [],

        current_curve_data: {},
        show_curve: false,
        show_curve_title: ''

    }),

    created() {

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        if (this.$route.params.search_prop == 'null') {
            this.dropdown_client_value = this.clients[0].username
        } else {
            this.dropdown_client_value = this.$route.params.search_prop
        }

        var client = this.clients.find(e => e.username === this.dropdown_client_value);

        SupervisorApi.GetRiskAssessmentCte(client.client_id).then((resp) => {

            this.all_data = resp.data

            this.dropdown_client = resp.data.clients

            this.dropdown_timepoints = resp.data.cte['timepoints']

            this.dropdown_timepoints_value = this.dropdown_timepoints[0]

            this.current_data = resp.data.cte[this.dropdown_timepoints_value]

            this.loaded = true
            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

        })

        


    },

    computed: {

        clients() {
            return this.$store.getters['store_supervisor/get_supervisor_clients']
        },

    },

    filters: {

        getIconOne(value) {
            if (value == 0) {
                return 'fas fa-circle'
            }
            else if (value == 1) {
                return 'far fa-circle'
            }
            else if (value == 2) {
                return 'far fa-circle'
            }
            else {
                return 'far fa-circle'
            }
        },

        getIconColorOne(value) {
            if (value == 0) {
                return 'green lighten-1'
            }
            else if (value == 1) {
                return ''
            }
            else if (value == 2) {
                return ''
            }
            else {
                return ''
            }
        },

        getIconTwo(value) {
            if (value == 0) {
                return 'far fa-circle'
            }
            else if (value == 1) {
                return 'fas fa-circle'
            }
            else if (value == 2) {
                return 'far fa-circle'
            }
            else {
                return 'far fa-circle'
            }
        },

        getIconColorTwo(value) {
            if (value == 0) {
                return ''
            }
            else if (value == 1) {
                return 'orange lighten-1'
            }
            else if (value == 2) {
                return ''
            }
            else {
                return ''
            }
        },

        getIconThree(value) {
            if (value == 0) {
                return 'far fa-circle'
            }
            else if (value == 1) {
                return 'far fa-circle'
            }
            else if (value == 2) {
                return 'fas fa-circle'
            }
            else {
                return 'far fa-circle'
            }
        },

        getIconColorThree(value) {
            if (value == 0) {
                return ''
            }
            else if (value == 1) {
                return ''
            }
            else if (value == 2) {
                return 'red lighten-1'
            }
            else {
                return ''
            }
        },
    },

    methods: {

        is_reverse_item(item) {

            var reverse_items = [
                "Ich tue einfach, was mir in den Sinn kommt.",
                "Wenn ich ein Problem habe, lenke ich mich ab.",
                "Ich bin immer gut gelaunt.",

                "Ein Behandlungsprogramm hat wenig Sinn, weil ich kein Problem habe.",
                "Ich befürchte, dass sich durch die Bewährungshilfe nur wenige Dinge in meinem Leben verändern werden.",
                "Manche Kinder wollen Sex mit Erwachsenen.",

                "Wenn ein Erwachsener mit einem Kind Sex hat, sammelt das Kind wichtige Erfahrungen für später.",
                "Ein Orgasmus ist auch für ein Kind etwas Schönes.",
                "Kinder können mich besser verstehen als Erwachsene.",

                "Eine starke Person wie ich kommt mit seinen Problemen alleine klar und braucht keine Therapie.",
                "Wenn ich ein Problem habe, werde ich nervös.",
                "Ich fühle mich bei Kindern wohler als bei Erwachsenen.",
                "Sexuelle Handlungen mit Kindern können schon mal passieren, wenn man die Kontrolle verliert.",

                "Aus sexuellen Kontakten mit Kindern wird generell eine viel zu große Sache gemacht.",
                "Ich verstehe gar nicht, für was ich einen Bewährungshelfer (m/w/d) brauche.",
                "Kinder akzeptieren mich eher als Erwachsene.",

                "Ich verstehe gar nicht, was an Kinderpornos so schlimm ist. Ich habe mit dem Kind aus dem Porno gar nichts zu tun.",
                "Man schadet einem Kind beim Sex nur dann, wenn man ihm auch körperlich weh tut.",
                "Ich liebe Kinder tatsächlich. Der Sex mit ihnen ist nur eine Nebensache.",

                "Mit sexuellen Zärtlichkeiten kann man einem Kind eine Freude machen.",
                "Manchen Kindern kann man in sexueller Hinsicht schwer widerstehen.",
                "Über Gefühle nachzudenken, bringt doch nichts.",
                "Ich gebe auf, wenn ich ein Problem nicht lösen kann.",

                "Therapeuten verstehen wenig von den echten Problemen des Lebens.",
                "Kinder wehren sich nicht gegen sexuelle Berührungen, weil sie neugierig sind oder es schön finden.",
                "Ich habe Freunde, die meine Sexualstraftat(en) nicht so schlimm finden.",
                "Ich verbringe lieber Zeit mit Kindern als mit Erwachsenen.",

                "Manche Kinder können in sexueller Hinsicht sehr verführerisch sein.",
                "Wenn ich nicht mehr an das Problem denke, löst es sich irgendwann von alleine.",
                "Es ist reine Zeitverschwendung, über meine Sexualstraftat(en) nachzudenken.",
                "Ich komme leichter mit Kindern als mit Erwachsenen in Kontakt."                
            ]

            if (reverse_items.includes(item.item)) {
                return true
            } else {
                return false
            }

        },

        update_timepoint() {
            this.current_data = this.all_data.cte[this.dropdown_timepoints_value]
        },

        update_client() {

            this.loading_dropdown_client = true

            var client = this.clients.find(e => e.username === this.dropdown_client_value);

            SupervisorApi.GetRiskAssessmentCte(client.client_id).then((resp) => {

                this.all_data = resp.data

                this.dropdown_client = resp.data.clients

                this.dropdown_timepoints = resp.data.cte['timepoints']

                this.dropdown_timepoints_value = this.dropdown_timepoints[0]

                this.current_data = resp.data.cte[this.dropdown_timepoints_value]

                this.loading_dropdown_client = false
                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

            })

        },

        open_curve(item){

            this.show_curve_title = item
            this.current_curve_data = this.all_data.cte[item]
            this.show_curve = true

        }
    }
}
</script>

<style scoped>

</style>