<template>

  <div>

    <v-row class="mb-3 mx-1">
      <div class="overline"><b>Tipp:</b> Sie müssen mindestens vier Zeichen eingeben.</div>
    </v-row>

    <v-textarea
        v-model="textInput"
        label="Meine Antwort"
        :placeholder="question"
        :disabled="thisBtnDisabled"
        color="primary"
        auto-grow
        outlined
        hide-details
    ></v-textarea>

  </div>

</template>

<script>
import _ from 'lodash'

export default {
    name: 'freeTextForm',
    props: ['taskID', 'question', 'id'],

    data: () => ({

      textInput: null,
      finished: false,

    }),

    created() {

      //this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false})

      if (_.isEmpty(this.$store.getters['store_lection/get_saved_lection_task_data_by_identifier'](this.taskID))) {

        // define task data store
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'textInput', 'data': this.textInput})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'question', 'data': this.question})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'type', 'data': 'freeTextForm'})

      } else {

        // get data
        var data = this.$store.getters['store_lection/get_saved_lection_task_data_by_identifier'](this.taskID)
        this.textInput = data.textInput

      }
      
    },

    computed: {
        
        thisBtnDisabled () {

            var tmp = this.$store.getters['store_lection/get_saved_lection_data']
                
            if (tmp.data[this.id].finished == false) {

                return false

            } else {

                return true

            }
 
        },

    },

    watch: {

      // watch the checkbox-state and update the store immediatly on change
      textInput: function(val) {

        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'textInput', 'data': val})

        if (val.length > 3) {

          this.finished = true
          this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': true})

        } else {

          this.finished = false
          this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false})

        }
        

      }

    },

    methods: {
     
    }
}   
</script>

<style scoped>
.checkbox-test >>> label {
  color: black !important
}
</style>