import axios from 'axios'
import store from '@/store/store'
import router from '@/router'
import store_helpers from '@/_helpers/store_helpers'

import DateTimeHelper from '@/_helpers/date_time_helpers'


function error_handling(err) {

    if (err == 'Error: Network Error') {

        store.dispatch('store_auth/CHANGE_LOGIN_INFO', { login_info_type: "error", login_info: 'Leider ist der Server aktuell nicht erreichbar. Bitte versuchen Sie es in einigen Minuten erneut.'})

        // clear store
        store_helpers.ClearEntireStore()

        // redirect to login
        router.push('/login')

    } else if (err == 'Error: Request failed with status code 403') {

        var err_msg = 'Sie sind nicht berechtigt, diese Seite zu betrachten. Bitte geben Sie ihr Passwort und ihren Benutzernamen ein.'
        store.dispatch('store_auth/CHANGE_LOGIN_INFO', { login_info_type: "info", login_info: err_msg})

        // clear store
        store_helpers.ClearEntireStore()

        // redirect to login
        router.push('/login')

    } else if (err == 'Error: Request failed with status code 500') {

        err_msg = 'Error 500: Internal Server Error.'
        
        store.dispatch('store_auth/CHANGE_LOGIN_INFO', { login_info_type: "error", login_info: err_msg})

        // clear store
        store_helpers.ClearEntireStore()

        // redirect to login
        router.push('/login')
        
    }

}

const GetCoachInfo = () => new Promise ((resolve) => {
    /**
   * Summary. Get all necessary data of a client.
   */

   const endpoint = 'coach/coach-info/'

   axios.get(endpoint)

   .then(resp => {

       store.dispatch('store_coach/UPDATE_COACH', resp.data.coach)
       store.dispatch('store_coach/UPDATE_COACH_MESSAGES', resp.data.coach_messages)
       store.dispatch('store_coach/UPDATE_COACH_TASKS', resp.data.coach_tasks)

       /**
        * Create Messages if necessary
        */
       if (resp.data.coach_messages.unread_message_count == 1) {

           store.dispatch('store_main_messages/UPDATE_MSG_TXT', 'Sie haben ' + resp.data.coach_messages.unread_message_count + ' neue <a href="/coach/messages/null">Nachricht</a>.')
           store.dispatch('store_main_messages/UPDATE_MSG_COLOR', 'error')
           store.dispatch('store_main_messages/UPDATE_MSG_ICON', 'fas fa-exclamation-circle')
           store.dispatch('store_main_messages/UPDATE_MSG_SHOW', true)

       } else if (resp.data.coach_messages.unread_message_count > 1) {

           store.dispatch('store_main_messages/UPDATE_MSG_TXT', 'Sie haben ' + resp.data.coach_messages.unread_message_count + ' neue <a href="/coach/messages/null">Nachrichten</a>.')
           store.dispatch('store_main_messages/UPDATE_MSG_COLOR', 'error')
           store.dispatch('store_main_messages/UPDATE_MSG_ICON', 'fas fa-exclamation-circle')
           store.dispatch('store_main_messages/UPDATE_MSG_SHOW', true)

       } else {
            store.dispatch('store_main_messages/UPDATE_MSG_SHOW', false)
        }

        //ToDo: unterscheidung zwischen main message MSG und man_message TASK

        if (resp.data.coach_tasks.unfinished_tasks_count == 1) {

            store.dispatch('store_main_messages/UPDATE_MSG_TASK_TXT', 'Sie haben ' + resp.data.coach_tasks.unfinished_tasks_count + ' nicht bearbeitete <a href="/coach/tasks/null">Übung</a>.')
            store.dispatch('store_main_messages/UPDATE_MSG_TASK_COLOR', 'error')
            store.dispatch('store_main_messages/UPDATE_MSG_TASK_ICON', 'fas fa-exclamation-circle')
            store.dispatch('store_main_messages/UPDATE_MSG_TASK_SHOW', true)
    
        } else if (resp.data.coach_tasks.unfinished_tasks_count > 1) {

            store.dispatch('store_main_messages/UPDATE_MSG_TASK_TXT', 'Sie haben ' + resp.data.coach_tasks.unfinished_tasks_count + ' nicht bearbeitete <a href="/coach/tasks/null">Übungen</a>.')
            store.dispatch('store_main_messages/UPDATE_MSG_TASK_COLOR', 'error')
            store.dispatch('store_main_messages/UPDATE_MSG_TASK_ICON', 'fas fa-exclamation-circle')
            store.dispatch('store_main_messages/UPDATE_MSG_TASK_SHOW', true)
    
        } else {
            store.dispatch('store_main_messages/UPDATE_MSG_TASK_SHOW', false)
        }

        resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

const GetCoachAllClientsData = () => new Promise ((resolve) => {
    /**
   * Summary. Get all necessary data of a client.
   */

   const endpoint = 'coach/coach-all-clients-data/'

   axios.get(endpoint)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

/**
 * MESSAGES
 */
const GetThreadById = (thread_id) => new Promise ((resolve) => {
    /**
   * Summary. Get all finished guided tasks of the coach.
   */

  var endpoint = 'messages-api/get-thread-by-id/' + thread_id + '/'

   axios.get(endpoint)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

const GetUserThreadsDeletedAPI = () => new Promise ((resolve) => {
    /**
   * Summary. Get all necessary data of a client.
   */

  var endpoint = 'messages-api/thread-view-deleted/'

   axios.get(endpoint)

   .then(resp => {

        if (resp.data.length != 0) {
            store.dispatch('store_messages/UPDATE_USER_THREADS_DELETED', DateTimeHelper.sortThreadByMessageDate(resp.data))
        } else {
            store.dispatch('store_messages/UPDATE_USER_THREADS_DELETED', resp.data)
        }

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

const GetUserThreadsInboxAPI = () => new Promise ((resolve) => {
    /**
   * Summary. Get all necessary data of a client.
   */

  var endpoint = 'messages-api/thread-view-inbox/'

   axios.get(endpoint)

   .then(resp => {

        if (resp.data.length != 0) {
            store.dispatch('store_messages/UPDATE_USER_THREADS', DateTimeHelper.sortThreadByMessageDate(resp.data))
        } else {
            store.dispatch('store_messages/UPDATE_USER_THREADS', resp.data)
        }

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

const SendMessage = (data) => new Promise((resolve) => {

    const formData = new FormData();

    formData.append("sent_at", DateTimeHelper.getDateTime());
    formData.append("content", data.newMessageValue);
    formData.append("sender", store.getters['store_coach/get_coach'].coach_user_id);
    formData.append("thread", data.current_thread_id);
    

    formData.append("attachment1", data.attachmentFile1);
    formData.append("attachment2", data.attachmentFile2);
    formData.append("attachment3", data.attachmentFile3);

    var endpoint = 'messages-api/message-view/'

    axios.post(endpoint, formData)
    
    .then(()=>{ 

        resolve()
        
    })

    .catch((error) => {

        error_handling(error)

    })

})

const CreateNewThread = (data) => new Promise((resolve) => {


    var endpoint = 'messages-api/create-new-thread/'

    const formData = new FormData();

    formData.append("sent_at", DateTimeHelper.getDateTime());
    formData.append("content", data.content);
    formData.append("sender", store.getters['store_coach/get_coach'].coach_user_id);
    formData.append("to_users", data.receiver);
    formData.append("subject", data.subject);
    formData.append('is_technical_help', data.is_technical_help)

    formData.append("attachment1", data.attachmentFile1);
    formData.append("attachment2", data.attachmentFile2);
    formData.append("attachment3", data.attachmentFile3);

    axios.post(endpoint, formData)
    
    .then(()=>{ 

        resolve()
        
    })

    .catch((error) => {

        error_handling(error)

    })

})

const ThreadToArchive = (data) => new Promise((resolve) => {

    var endpoint_1 = 'messages-api/user-thread-view/'

    var formData = {

        "deleted": true
    }

    axios.get(endpoint_1)
    
    .then((resp)=>{ 

        var correct_id_user_thread = null

        for (var i = 0; i < resp.data.length; i++) {

            if (resp.data[i].thread == data.current_thread_id) {

                correct_id_user_thread = resp.data[i].id

            }
        }


        var endpoint_2 = 'messages-api/user-thread-view/'  + correct_id_user_thread + '/'

        axios.patch(endpoint_2, formData)

        .then(()=> {

            resolve()

        })

        .catch((error) => {

            error_handling(error)
    
        })
        
    })

    .catch((error) => {

        error_handling(error)

    })

})

const UndoThreadToArchive = (data) => new Promise((resolve) => {

    var endpoint_1 = 'messages-api/user-thread-view/'

    var formData = {

        "deleted": false
    }

    axios.get(endpoint_1)
    
    .then((resp)=>{ 

        var correct_id_user_thread = null

        for (var i = 0; i < resp.data.length; i++) {

            if (resp.data[i].thread == data.current_thread_id) {

                correct_id_user_thread = resp.data[i].id

            }
        }


        var endpoint_2 = 'messages-api/user-thread-view/'  + correct_id_user_thread + '/'

        axios.patch(endpoint_2, formData)

        .then(()=> {

            resolve()

        })

        .catch((error) => {

            error_handling(error)
    
        })
        
    })

    .catch((error) => {

        error_handling(error)

    })

})



const SetThreadAsRead = (data) => new Promise((resolve) => {
    /**
     * Summary. Updates the current Thread as read.
     * */


    var endpoint_1 = 'messages-api/user-thread-view/'

    var formData = {

        "unread": false
    }

    axios.get(endpoint_1)
    
    .then((resp)=>{ 

        var correct_id_user_thread = null

        for (var i = 0; i < resp.data.length; i++) {

            if (resp.data[i].thread == data.current_thread_id) {

                correct_id_user_thread = resp.data[i].id

            }
        }


        var endpoint_2 = 'messages-api/user-thread-view/'  + correct_id_user_thread + '/'

        axios.patch(endpoint_2, formData)

        .then(()=> {

            resolve()

        })

        .catch((error) => {

            error_handling(error)
    
        })
        
    })

    .catch((error) => {

        error_handling(error)

    })


})


/**
 * 
 * GUIDED TASKS
 * 
 */
const GetGuidedTaskById = (task_id) => new Promise ((resolve) => {
    /**
   * Summary. Get all finished guided tasks of the coach.
   */

  var endpoint = 'guided-tasks-api/get-task-by-task-id/' + task_id + '/'

   axios.get(endpoint)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

const GetUnfinishedGuidedTasks = () => new Promise ((resolve) => {
    /**
   * Summary. Get all un-finished guided tasks of coach.
   */

  var endpoint = 'guided-tasks-api/guided-task-view-unfinished/'

   axios.get(endpoint)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

const GetFinishedGuidedTasks = () => new Promise ((resolve) => {
    /**
   * Summary. Get all finished guided tasks of the coach.
   */

  var endpoint = 'guided-tasks-api/guided-task-view-finished/'

   axios.get(endpoint)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

const UpdateGuidedTaskWaitingForClient = (guided_task_id) => new Promise ((resolve) => {
    /*
    * Summary. Updates the waiting_for_coach field 'false' and waiting_for_client field to 'true'.
    */

   var endpoint = 'guided-tasks-api/update-waiting-for-client/' + guided_task_id

   var formData = {

        pk: guided_task_id

    }

    axios.patch(endpoint, formData)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

const UpdateGuidedTaskFinalData = (guided_task_id, final_task_data) => new Promise ((resolve) => {
    /*
    * Summary. Updates the revision_finished field of an existing guided-task.
    */

   var endpoint = 'guided-tasks-api/update-final-task-data/' + guided_task_id

   var formData = {

        pk: guided_task_id,
        final_task_data: final_task_data

    }

    axios.patch(endpoint, formData)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

const MarkGuidedTaskRevFinished = (guided_task_id) => new Promise ((resolve) => {
    /*
    * Summary. Updates the revision_finished field of an existing guided-task.
    */

   var endpoint = 'guided-tasks-api/update-revision-finished/' + guided_task_id

   var formData = {

        pk: guided_task_id

    }

    axios.patch(endpoint, formData)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

const AddNewMessageToGuidedTask = (data) => new Promise ((resolve) => {
    /*
    * Summary. Adds a new message to an existing guided-task.
    */

   var endpoint = 'guided-tasks-api/message-guided-task-view/'

    const formData = new FormData();

    formData.append("sent_at", DateTimeHelper.getDateTime());
    formData.append("content", data.content);
    formData.append("sender", store.getters['store_coach/get_coach'].coach_user_id);
    formData.append("guided_task", data.guided_task);
    formData.append("revision_number", data.revision_number);
    formData.append("current_task_data", data.current_task_data);
    

    formData.append("attachment1", data.attachmentFile1);
    formData.append("attachment2", data.attachmentFile2);
    formData.append("attachment3", data.attachmentFile3);

    axios.post(endpoint, formData)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})


/**
 * 
 * CRITICAL EVENTS
 * 
 */


const ReportCriticalEventMessage = (data) => new Promise((resolve) => {

    var endpoint = 'coach/coach-report-critical-event-message/'

    const formData = new FormData();

    formData.append("client_user_id", data.client_user_id)
    formData.append("event_type", data.event_type)
    formData.append("email_sent", false)
    formData.append("sms_sent", false)
    formData.append("event_report_time", DateTimeHelper.getDateTime())
    formData.append("event_thread_id", data.event_thread_id)
    formData.append("event_message_id", data.event_message_id)
    formData.append("event_message_content", data.event_message_content)

    axios.post(endpoint, formData)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)
   
    })
})


const ReportCriticalEventGuidedTask = (data) => new Promise((resolve) => {

    var endpoint = 'coach/coach-report-critical-event-guided-task/'

    const formData = new FormData();

    formData.append("client_user_id", data.client_user_id)
    formData.append("event_type", data.event_type)
    formData.append("email_sent", false)
    formData.append("sms_sent", false)
    formData.append("event_report_time", DateTimeHelper.getDateTime())
    formData.append("event_coach_content_critical_items", data.event_coach_content_critical_items)
    formData.append("event_guided_task_id", data.event_guided_task_id)
    formData.append("event_message_guided_task_id", data.event_message_guided_task_id)
    formData.append("event_guided_task_revision_nr", data.event_guided_task_revision_nr)

    axios.post(endpoint, formData)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)
   
    })
})

const ReportCriticalEventDelay = (data) => new Promise((resolve) => {

    var endpoint = 'coach/coach-report-critical-event-delay/'

    const formData = new FormData();

    formData.append("client_user_id", data.client_user_id)
    formData.append("email_sent", false)
    formData.append("event_report_time", DateTimeHelper.getDateTime())

    axios.post(endpoint, formData)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)
   
    })
})


const GetCriticalEvents = () => new Promise ((resolve) => {
    /**
   * Summary. Get all finished polls for supervisor.
   */

  var endpoint = 'coach/coach-get-critical-events/'

   axios.get(endpoint)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

export default {

    GetCoachInfo,
    GetCoachAllClientsData,

    CreateNewThread,
    SetThreadAsRead,
    UndoThreadToArchive,
    ThreadToArchive,
    SendMessage,
    GetThreadById,

    GetUserThreadsDeletedAPI,
    GetUserThreadsInboxAPI,

    GetUnfinishedGuidedTasks,
    GetFinishedGuidedTasks,
    UpdateGuidedTaskWaitingForClient,
    UpdateGuidedTaskFinalData,
    MarkGuidedTaskRevFinished,
    AddNewMessageToGuidedTask,
    GetGuidedTaskById,

    ReportCriticalEventMessage,
    ReportCriticalEventGuidedTask,
    ReportCriticalEventDelay,
    GetCriticalEvents,

}