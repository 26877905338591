<template>

    <v-card 
        class="mx-auto"
        color="grey lighten-5"
        flat
    >

        <v-card-text v-if="!done">

            <v-form
                ref="form" 
                v-model="forms_valid" 
            >

                <v-card-text 
                    class="title primary--text"
                >
                    Bitte geben Sie die Gründe für den Ausschluss an:
                </v-card-text>

                <v-card-text>

                    <v-row>

                        <v-col cols="12" sm="12">

                            <v-card>
                                <v-card-text>
                                    <div class="body-1">1. Widerruf der Einverständniserklärung</div>

                                    <v-radio-group 
                                        v-model="reason_1"
                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                        :row="$vuetify.breakpoint.mdAndUp"
                                        class="subtitle-1"
                                    >
                                        <v-radio
                                            label="Ja"
                                            value="1"
                                        ></v-radio>

                                        <v-radio
                                            label="Nein"
                                            value="2"
                                        ></v-radio>

                                    </v-radio-group>
                                </v-card-text>
                            </v-card>

                        </v-col>

                        <v-col cols="12" sm="12">

                            <v-card>
                                <v-card-text>
                                    <div class="body-1">2. Die Zeit der Bewährungsaufsicht/Führungsaufsicht ist abgelaufen</div>

                                    <v-radio-group 
                                        v-model="reason_2"
                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                        :row="$vuetify.breakpoint.mdAndUp"
                                        class="subtitle-1"
                                    >
                                        <v-radio
                                            label="Ja"
                                            value="1"
                                        ></v-radio>

                                        <v-radio
                                            label="Nein"
                                            value="2"
                                        ></v-radio>

                                    </v-radio-group>
                                </v-card-text>
                            </v-card>

                        </v-col>

                        <v-col cols="12" sm="12">
                            
                            <v-card>
                                <v-card-text>
                                    <div class="body-1">3. Hat der*die Klient*in gegen Weisungen verstoßen?</div>

                                    <v-radio-group 
                                        v-model="reason_3"
                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                        :row="$vuetify.breakpoint.mdAndUp"
                                        class="subtitle-1"
                                    >
                                        <v-radio
                                            label="Ja"
                                            value="1"
                                        ></v-radio>

                                        <v-radio
                                            label="Nein"
                                            value="2"
                                        ></v-radio>

                                    </v-radio-group>
                                </v-card-text>
                            </v-card>

                        </v-col>

                        <v-col cols="12" sm="12" v-if="reason_3 == '1'">

                            <v-card class="ml-5">
                                <v-card-text>

                                    <div class="body-1">3a. Gegen welche Weisungen wurde verstoßen (Bitte Auflistung mittels KOMMA trennen)?</div>

                                    <v-text-field
                                        class="mb-3 pb-3"
                                        v-model="reason_3a"
                                        :rules="[v => !!v || 'Sie müssen einen Wert angeben.']"
                                    ></v-text-field>

                                </v-card-text>
                            </v-card>

                        </v-col>

                        <v-col cols="12" sm="12" v-if="reason_3 == '1'">

                            <v-card class="ml-5">
                                <v-card-text>
                                    <div class="body-1">3b. Kam es zu einem Strafantrag gemäß § 145a StGB?</div>

                                    <v-radio-group 
                                        v-model="reason_3b"
                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                        :row="$vuetify.breakpoint.mdAndUp"
                                        class="subtitle-1"
                                    >
                                        <v-radio
                                            label="Ja"
                                            value="1"
                                        ></v-radio>

                                        <v-radio
                                            label="Nein"
                                            value="2"
                                        ></v-radio>

                                    </v-radio-group>
                                </v-card-text>
                            </v-card>

                        </v-col>

                        <v-col cols="12" sm="12" v-if="reason_3 == '1'">

                            <v-card class="ml-5">
                                <v-card-text>
                                    <div class="body-1">3c. Kam es zu einer Verurteilung?</div>

                                    <v-radio-group 
                                        v-model="reason_3c"
                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                        :row="$vuetify.breakpoint.mdAndUp"
                                        class="subtitle-1"
                                    >
                                        <v-radio
                                            label="Ja"
                                            value="1"
                                        ></v-radio>

                                        <v-radio
                                            label="Nein"
                                            value="2"
                                        ></v-radio>

                                    </v-radio-group>
                                </v-card-text>
                            </v-card>

                        </v-col>

                        <v-col cols="12" sm="12">

                            <v-card>
                                <v-card-text>
                                    <div class="body-1">4. Klient*in hat gegen Auflagen verstoßen</div>

                                    <v-radio-group 
                                        v-model="reason_4"
                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                        :row="$vuetify.breakpoint.mdAndUp"
                                        class="subtitle-1"
                                    >
                                        <v-radio
                                            label="Ja"
                                            value="1"
                                        ></v-radio>

                                        <v-radio
                                            label="Nein"
                                            value="2"
                                        ></v-radio>

                                    </v-radio-group>
                                </v-card-text>
                            </v-card>

                        </v-col>

                        <v-col cols="12" sm="12" v-if="reason_4 == '1'">

                            <v-card class="ml-5">
                                <v-card-text>

                                    <div class="body-1">4a. Gegen welche Auflagen wurde verstoßen (Bitte Auflistung mittels KOMMA trennen)?</div>

                                    <v-text-field
                                        class="mb-3 pb-3"
                                        v-model="reason_4a"
                                        :rules="[v => !!v || 'Sie müssen einen Wert angeben.']"
                                    ></v-text-field>

                                </v-card-text>
                            </v-card>

                        </v-col>

                        <v-col cols="12" sm="12">

                            <v-card>
                                <v-card-text>
                                    <div class="body-1">5. Klient*in hat eine Straftat gemäß §§ 176, 176a oder 176b StGB begangen</div>

                                    <v-radio-group 
                                        v-model="reason_5"
                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                        :row="$vuetify.breakpoint.mdAndUp"
                                        class="subtitle-1"
                                    >
                                        <v-radio
                                            label="Ja"
                                            value="1"
                                        ></v-radio>

                                        <v-radio
                                            label="Nein"
                                            value="2"
                                        ></v-radio>

                                    </v-radio-group>
                                </v-card-text>
                            </v-card>

                        </v-col>

                        <v-col cols="12" sm="12">

                            <v-card>
                                <v-card-text>
                                    <div class="body-1">6. Klient*in hat eine Straftat gemäß § 184b StGB begangen</div>

                                    <v-radio-group 
                                        v-model="reason_6"
                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                        :row="$vuetify.breakpoint.mdAndUp"
                                        class="subtitle-1"
                                    >
                                        <v-radio
                                            label="Ja"
                                            value="1"
                                        ></v-radio>

                                        <v-radio
                                            label="Nein"
                                            value="2"
                                        ></v-radio>

                                    </v-radio-group>
                                </v-card-text>
                            </v-card>

                        </v-col>

                        <v-col cols="12" sm="12">

                            <v-card>
                                <v-card-text>

                                    <div class="body-1">7. Klient*in hat eine andere Straftat begangen (nicht gemäß §§ 176, 176a, 176b oder 184b StGB)</div>

                                    <v-radio-group 
                                        v-model="reason_7"
                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                        :row="$vuetify.breakpoint.mdAndUp"
                                        class="subtitle-1"
                                    >
                                        <v-radio
                                            label="Ja"
                                            value="1"
                                        ></v-radio>

                                        <v-radio
                                            label="Nein"
                                            value="2"
                                        ></v-radio>

                                    </v-radio-group>
                                </v-card-text>
                            </v-card>

                        </v-col>

                        <v-col cols="12" sm="12">

                            <v-card>
                                <v-card-text>

                                    <div class="body-1">8. akute Selbstgefährdung des*der Klienten*Klientin (z.B. konkrete Suizidplanung)</div>

                                    <v-radio-group 
                                        v-model="reason_8"
                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                        :row="$vuetify.breakpoint.mdAndUp"
                                        class="subtitle-1"
                                    >
                                        <v-radio
                                            label="Ja"
                                            value="1"
                                        ></v-radio>

                                        <v-radio
                                            label="Nein"
                                            value="2"
                                        ></v-radio>

                                    </v-radio-group>
                                </v-card-text>
                            </v-card>

                        </v-col>

                        <v-col cols="12" sm="12">

                            <v-card>
                                <v-card-text>

                                    <div class="body-1">9. Andere Gründe als die bisher genannten Gründe</div>

                                    <v-radio-group 
                                        v-model="reason_9"
                                        :rules="[ reason_9_rules ]"
                                        :row="$vuetify.breakpoint.mdAndUp"
                                        class="subtitle-1"
                                    >
                                        <v-radio
                                            label="Ja"
                                            value="1"
                                        ></v-radio>

                                        <v-radio
                                            label="Nein"
                                            value="2"
                                        ></v-radio>

                                    </v-radio-group>
                                </v-card-text>
                            </v-card>

                        </v-col>

                        <v-col cols="12" sm="12" v-if="reason_9 == '1'">

                            <v-card class="ml-5">
                                <v-card-text>

                                    <div class="body-1">9a. Welche Gründe (Bitte Auflistung mittels KOMMA trennen)?</div>

                                    <v-text-field
                                        class="mb-3 pb-3"
                                        v-model="reason_9a"
                                        :rules="[v => !!v || 'Sie müssen einen Wert angeben.']"
                                    ></v-text-field>

                                </v-card-text>
                            </v-card>

                        </v-col>

                        <v-col cols="12" sm="12">

                            <v-card>
                                <v-card-text>

                                    <div class="body-1">10. Steht der Grund für den Ausschluss in einem direkten Zusammenhang mit @myTabu?</div>

                                    <v-radio-group 
                                        v-model="relation_to_mytabu_1"
                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                        :row="$vuetify.breakpoint.mdAndUp"
                                        class="subtitle-1"
                                    >
                                        <v-radio
                                            label="Ja"
                                            value="1"
                                        ></v-radio>

                                        <v-radio
                                            label="Nein"
                                            value="2"
                                        ></v-radio>

                                    </v-radio-group>
                                </v-card-text>
                            </v-card>

                        </v-col>

                        <v-col cols="12" sm="12">

                            <v-card>
                                <v-card-text>

                                    <div class="body-1">11. Erfolgt der Ausschluss aufgrund der Meldung eines kritischen Ereignisses durch den*die Coach*Coachin?</div>

                                    <v-radio-group 
                                        v-model="relation_to_mytabu_2"
                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                        :row="$vuetify.breakpoint.mdAndUp"
                                        class="subtitle-1"
                                    >
                                        <v-radio
                                            label="Ja"
                                            value="1"
                                        ></v-radio>

                                        <v-radio
                                            label="Nein"
                                            value="2"
                                        ></v-radio>

                                    </v-radio-group>
                                </v-card-text>
                            </v-card>

                        </v-col>

                        <v-col cols="12" sm="12">

                            <v-card>
                                <v-card-text>

                                    <div class="body-1">12. Erfolgt der Ausschluss aufgrund der Angaben des*der Klienten*Klientin im @myTabu-Risk-Assessment?</div>

                                    <v-radio-group 
                                        v-model="relation_to_mytabu_3"
                                        :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                        :row="$vuetify.breakpoint.mdAndUp"
                                        class="subtitle-1"
                                    >
                                        <v-radio
                                            label="Ja"
                                            value="1"
                                        ></v-radio>

                                        <v-radio
                                            label="Nein"
                                            value="2"
                                        ></v-radio>

                                    </v-radio-group>
                                </v-card-text>
                            </v-card>

                        </v-col>

                    </v-row>

                </v-card-text>

            </v-form>

        </v-card-text>

        <v-divider v-if="!done" />

        <v-card-text class="my-3" v-if="!done">

            <v-row>
                <v-col cols>
                    <v-btn outlined @click="go_back()">
                        Abbrechen
                    </v-btn>
                </v-col>

                <v-spacer />

                <v-col cols>
                    <v-btn outlined @click="exclude_client()" color="error" :loading="loading" :disabled="loading">
                        <span v-if="get_client_gender() == 1">Ausschließen</span>
                    </v-btn>
                </v-col>

            </v-row>

        </v-card-text>

        <v-card-text v-if="done">

            <v-row align="center" justify="center">

                <p class="subtitle-1">Der Ausschluss <span v-if="get_client_gender() == 1">des Klienten</span><span v-else>der Klientin</span> wurde erfolgreich beantragt. Sie erhalten eine Email, sobald die Studienleitung <span v-if="get_client_gender() == 1">den Klienten</span><span v-else>die Klientin</span> ausgeschlossen hat.</p>

            </v-row>
            
        </v-card-text>

    </v-card>

</template>

<script>
import SupervisorApi from '@/_api/supervisor_api'

export default {
    name: 'supervisor-exclude-client',

    data: () => ({
        
        loaded: false,
        done: false,
        loading: false,
        forms_valid: true,
        reason_1: null,
        reason_2: null,
        reason_3: null,
        reason_3a: null,
        reason_3b: null,
        reason_3c: null,
        reason_4: null,
        reason_4a: null,
        reason_5: null,
        reason_6: null,
        reason_7: null,
        reason_8: null,
        reason_9: null,
        reason_9a: null,
        relation_to_mytabu_1: null,
        relation_to_mytabu_2: null,
        relation_to_mytabu_3: null,



    }),

    created() {

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        SupervisorApi.GetSupervisorInfo().then(() => {

            this.loaded = true

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

        })

    },

    computed: {

    },

    methods: {

        get_client_gender() {

            var client = this.$store.getters['store_supervisor/get_supervisor_clients'].find(e => e.client_id === this.$route.params.client_id);

            return client.client_gender

        },

        reason_9_rules(value) {

            if (value == null) {

                return 'Sie müssen eine Auswahl treffen'

            } else {

                if (value == '2') {

                    if (this.reason_1 == '2' && this.reason_2 == '2' && this.reason_3 == '2' && this.reason_4 == '2' && this.reason_5 == '2' && this.reason_6 == '2' && this.reason_7 == '2' && this.reason_8 == '2') {
                        return 'Sie müssen "Ja" auswählen, da auch alle anderen Gründe mit Nein beantwortet wurden.'
                    } else {
                        return true
                    }

                } else {

                    if (this.reason_1 == '1' || this.reason_2 == '1' || this.reason_3 == '1' || this.reason_4 == '1' || this.reason_5 == '1' || this.reason_6 == '1' || this.reason_7 == '1' || this.reason_8 == '1') {
                        return 'Sie müssen "Nein" auswählen, da bereits ein anderer Grund ausgewählt wurde.'
                    } else {
                        return true
                    }

                }

            }
          

        },

        go_back() {
            this.$router.push({name: 'supervisor'})
        },

        exclude_client() {

            if (this.$refs.form.validate()) {

                this.loading = true

                if (this.reason_3 == '2') {

                    this.reason_3a = 'NaN'
                    this.reason_3b = '2'
                    this.reason_3c = '2'
                }

                if (this.reason_4 == '2') {

                    this.reason_4a = 'NaN'

                }

                if (this.reason_9 == '2') {

                    this.reason_9a = 'NaN'

                }

                var data = {

                    client_id: this.$route.params.client_id,
                    client_user_id: this.$route.params.client_user_id,
                    reason_1: this.reason_1,
                    reason_2: this.reason_2,
                    reason_3: this.reason_3,
                    reason_3a: this.reason_3a,
                    reason_3b: this.reason_3b,
                    reason_3c: this.reason_3c,
                    reason_4: this.reason_4,
                    reason_4a: this.reason_4a,
                    reason_5: this.reason_5,
                    reason_6: this.reason_6,
                    reason_7: this.reason_7,
                    reason_8: this.reason_8,
                    reason_9: this.reason_9,
                    reason_9a: this.reason_9a,
                    relation_to_mytabu_1: this.relation_to_mytabu_1,
                    relation_to_mytabu_2: this.relation_to_mytabu_2,
                    relation_to_mytabu_3: this.relation_to_mytabu_3

                }

                SupervisorApi.ExcludeClient(data).then(() => {

                    this.loading = false

                    this.done = true

                })

            }

        }
    }
}
</script>

<style scoped>

</style>