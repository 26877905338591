<template>
    <v-container fluid>

        <v-row v-if="$store.getters['store_client/get_client'].client_group == 1">

            <v-col cols="12" sm="12" v-if="!show_m1_s4_l4_t2">

                <v-card
                    outlined
                    
                >

                    <v-card-text class="primary--text title">
                        Warum ich keine Straftaten mehr begehen möchte
                    </v-card-text>

                    <v-card-text class="body-1">
                        Vielleicht ist Ihnen gerade gar nicht mehr so klar, warum Sie kein Kind missbrauchen und keine Missbrauchsabbildungen 
                        ansehen möchten. Im ersten Modul haben Sie aufgeschrieben, warum es für Sie sinnhaft ist keine Straftat zu begehen. 
                        Vielleicht helfen Ihnen Ihre Gedanken aus dem ersten Modul dabei weiterhin straffrei zu leben.
                    </v-card-text>

                    <v-card-text>
                        <v-list>
                            <v-list-item
                                v-for="item in emergency_data.m1_s4_l4_t2.keywordList"
                                :key="item.name"
                            >
                                <v-list-item-icon>
                                    <v-icon color="accent">far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content class="font-weight-bold body-1">{{ item }}</v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>

                </v-card>

            </v-col>

            <v-col cols="12" sm="12" v-if="show_helpful_thought">

                <v-card outlined>

                    <v-card-text class="primary--text title">
                        Hilfreiche Gedanken
                    </v-card-text>

                    <v-card-text class="body-1">
                        Sie haben hilfreiche Gedanken in Modul 5 „Gedankenzerrung“ kennengelernt. Ein hilfreicher Gedanke ist ein Gedanke, 
                        der viele Vorteile mit sich bringt und Ihnen hilft, keine sexuelle Straftat zu begehen. Dabei kann der hilfreiche 
                        Gedanke Gründe enthalten, die gegen eine sexuelle Straftat sprechen, die Nachteile die eine sexuelle Straftat bringt 
                        oder die Vorteile, die ein straffreies Leben mit sich bringt. Wenn Sie also einmal nicht mehr wissen, warum Sie keine 
                        Straftaten begehen sollen, kann Ihnen Ihr hilfreicher Gedanke auf die Sprünge helfen! Lesen Sie hier noch einmal Ihre 
                        hilfreichen Gedanken nach:
                    </v-card-text>

                    <v-card-text>

                        <blockquote class="blockquote black--text note" v-if="!show_helpful_thought_1">
                            {{ emergency_data.helpful_thought_1.textInput }}
                        </blockquote>

                        <blockquote class="blockquote black--text note" v-if="!show_helpful_thought_2">
                            {{ emergency_data.helpful_thought_2.textInput }}
                        </blockquote>

                        <blockquote class="blockquote black--text note" v-if="!show_helpful_thought_3">
                            {{ emergency_data.helpful_thought_3.textInput }}
                        </blockquote>

                    </v-card-text>

                </v-card>

            </v-col>

        </v-row>

        <v-row v-else>

            <v-col cols="12" sm="12">

                <v-card
                    outlined
                    
                >

                    <v-card-text class="primary--text title">
                        Tipps um straffrei zu bleiben
                    </v-card-text>

                    <v-card-text class="body-1">
                        Es kann passieren, dass Sie in Situationen kommen, in denen es Ihnen schwer fällt straffrei zu bleiben und Sie nicht mehr wissen, warum Sie 
                        kein Kind missbrauchen oder Missbrauchsabbildungen ansehen sollen. Auch frühere Teilnehmer von @myTabu haben sich manchmal gefragt, warum 
                        sie keine Straftaten mehr begehen möchten. Ich habe Ihnen hier einige der Gründe, die diese Menschen für sich gefunden haben, 
                        aufgeschrieben. Vielleicht können Sie manches davon ja auch gut verstehen? Gibt es darunter auch gute Gründe für Sie?
                    </v-card-text>

                    <v-card-text>
                        <v-list>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="accent">far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content class="font-weight-bold body-1">Ich möchte die Kontrolle über mich und mein Leben behalten.</v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="accent">far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content class="font-weight-bold body-1">Ich möchte kein schlechtes Gewissen haben oder Scham fühlen, weil ich ein Kind verletzt habe.</v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="accent">far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content class="font-weight-bold body-1">Ich möchte meine Familie und Freunde nicht verlieren.</v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="accent">far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content class="font-weight-bold body-1">Ich möchte meinen Job nicht verlieren.</v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="accent">far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content class="font-weight-bold body-1">Ich möchte nicht in das Gefängnis oder den Maßregelvollzug.</v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="accent">far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content class="font-weight-bold body-1">Ich möchte niemanden verletzen.</v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>

                </v-card>

            </v-col>

        </v-row>

    </v-container>
</template>

<script>
//import ClientApi from '@/_api/client_api'
import _ from 'lodash'

export default {
    name: 'client-emergency-accent-crime',

    data: () => ({
        //
    }),

    created() {


    },

    computed: {

        emergency_data() {

            return this.$store.getters['store_client/get_client_emergency_data']

        },

        show_m1_s4_l4_t2() {
            return _.isEmpty(this.emergency_data.m1_s4_l4_t2)
        },

        show_helpful_thought_1() {
            return _.isEmpty(this.emergency_data.helpful_thought_1)
        },

        show_helpful_thought_2() {
            return _.isEmpty(this.emergency_data.helpful_thought_2)
        },

        show_helpful_thought_3() {
            return _.isEmpty(this.emergency_data.helpful_thought_3)
        },

        show_helpful_thought() {

            if (!_.isEmpty(this.emergency_data.helpful_thought_1) || !_.isEmpty(this.emergency_data.helpful_thought_2) || !_.isEmpty(this.emergency_data.helpful_thought_3)) {
                return true
            } else {
                return false
            }
               
        },

    }

}
</script>

<style scoped>
.note {
  white-space: pre-line; 
  word-wrap: break-word;
  font-family: inherit;
  text-align: left !important;
}
</style>