<template>

<div>

    <v-row class="mb-3">
        <v-col cols="12" sm="12">
            <p class="title mt-2">Vielen Dank!</p>
        </v-col>

    </v-row>

    <v-row class="mb-3">
        <v-col cols="12" sm="12">
            <p class="title font-weight-regular">
                Sie haben alle Fragen beantwortet!
            </p>
        </v-col>
    </v-row>

</div>

</template>

<script>
export default {
    name: 'assessment-end',

    data: () => ({
        //
    }),

    props: ['timepoint'],

    created() {
        //
    },

    computed: {
        //
    },

    methods: {
        //
    }
}
</script>

<style scoped>

</style>