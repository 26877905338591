<template>

    <div v-if="loaded">

        <v-card
            color="grey lighten-5"
            flat
            height="auto"
        >

            <v-toolbar 
                flat
                short
                color="grey lighten-5"
                
            >

                <v-icon color="primary">fas fa-tasks</v-icon>

                <v-toolbar-title class="primary--text ml-3">Offene Übungen</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn 
                    icon
                    @click="openPanelOpen()"
                    color="primary"
                >
                    <v-icon v-if="!panel_open">fas fa-angle-down</v-icon>
                    <v-icon v-else>fas fa-angle-up</v-icon>
                </v-btn>

            </v-toolbar>

            <v-divider />

            <v-card-text v-if="panel_open">
                
                <v-alert
                    v-if="show_unfinished_panel_null_alert()"
                    border="left"
                    elevation="1"
                    colored-border
                    prominent
                    type="success"
                >
                    Es liegen keine offenen Übungen vor.
                </v-alert>

                <div
                    v-for="task in unfinished_tasks"
                    :key="task.id"
                >

                    <v-alert
                        v-if="task.waiting_for_client"
                        border="left"
                        elevation="1"
                        colored-border
                        prominent
                        type="error"
                    >
                        <v-row align="center">

                            <v-col cols="auto" class="mr-auto">

                                <div>
                                    <small>Übung</small>
                                </div>
                                
                                <div class="black--text mt-3">
                                    <b>{{ get_short_question(task) }}</b><small > (Modul {{ task.current_module_nr}} - Sitzung {{ task.current_session_nr }} - Lektion {{ task.current_lession_nr}})</small>
                                </div>

                                <div class="overline mt-3">
                                    <b>Feedback:</b> Leider sind noch Änderungen notwendig.
                                </div>

                            </v-col>

                            <v-col cols="auto">
                                <v-btn
                                    outlined
                                    color="error"
                                    @click="goToTaskDetails(task)"
                                >
                                    Zur Übung
                                </v-btn>
                            </v-col>

                        </v-row>

                    </v-alert>

                    <v-alert
                        v-if="task.waiting_for_coach"
                        border="left"
                        elevation="1"
                        colored-border
                        icon="far fa-bell"
                        prominent
                        color="primary"
                    >
                        <v-row align="center">

                            <v-col cols="auto" class="mr-auto">

                                <div>
                                    <small>Übung</small>
                                </div>
                                
                                <div class="black--text mt-3">
                                    <b>{{ get_short_question(task) }}</b><small > (Modul {{ task.current_module_nr}} - Sitzung {{ task.current_session_nr }} - Lektion {{ task.current_lession_nr}})</small>
                                </div>

                                <div class="overline mt-3">
                                    <b>Es steht noch ein Feedback {{ get_gendered_coach() }} aus.</b>
                                </div>

                            </v-col>

                            <v-col cols="auto">
                                <v-btn
                                    outlined
                                    color="primary"
                                    @click="goToTaskDetails(task)"
                                >
                                    Zur Übung
                                </v-btn>
                            </v-col>

                        </v-row>

                    </v-alert>

                </div>

            </v-card-text>

        </v-card>

        <v-divider class="my-5"/>

        <!-- ABGESCHLOSSENE ÜBUNGEN -->
        <v-card
            color="grey lighten-5"
            flat
            height="auto"
        >

            <v-toolbar 
                flat
                short
                color="grey lighten-5"
                
            >

                <v-icon color="primary">fas fa-check</v-icon>

                <v-toolbar-title class="primary--text ml-3">Bearbeitete Übungen</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn 
                    icon
                    @click="openPanelFinished()"
                    color="primary"
                >
                    <v-icon v-if="!panel_finished">fas fa-angle-down</v-icon>
                    <v-icon v-else>fas fa-angle-up</v-icon>
                </v-btn>

            </v-toolbar>

            <v-divider />

            <v-card-text v-if="panel_finished">
                
                <v-alert
                    v-if="show_finished_panel_null_alert()"
                    border="left"
                    elevation="1"
                    colored-border
                    prominent
                    type="success"
                >
                    Es liegen keine abgeschlossenen Übungen vor.
                </v-alert>

                <div
                    v-for="task in finished_tasks"
                    :key="task.id"
                >

                    <v-alert
                        border="left"
                        elevation="1"
                        colored-border
                        prominent
                        type="success"
                    >
                        <v-row align="center">

                            <v-col cols="auto" class="mr-auto">

                                <div>
                                    <small>Übung</small>
                                </div>
                                
                                <div class="black--text mt-3">
                                    <b>{{ get_short_question(task) }}</b><small > (Modul {{ task.current_module_nr}} - Sitzung {{ task.current_session_nr }} - Lektion {{ task.current_lession_nr}})</small>
                                </div>

                                <div class="overline mt-3">
                                    <b>Sie haben diese Übung erfolgreich bearbeitet!</b>
                                </div>

                            </v-col>

                            <v-col cols="auto">
                                <v-btn
                                    outlined
                                    color="success"
                                    @click="goToTaskDetails(task)"
                                >
                                    Zur Übung
                                </v-btn>
                            </v-col>

                        </v-row>

                    </v-alert>

                </div>

            </v-card-text>

        </v-card>

    </div>

</template>

<script>
import ClientApi from '@/_api/client_api'

export default {
    name: 'client-tasks',

    data: () => ({
        
        panel_open: true,
        panel_finished: false,

        unfinished_tasks: [],
        finished_tasks: [],

        loaded: false,

    }),

    created() {

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        ClientApi.GetUnfinishedGuidedTasks().then((resp) => {

            this.unfinished_tasks = resp.data

            ClientApi.GetFinishedGuidedTasks().then((resp) => {

                this.finished_tasks = resp.data

                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                this.loaded = true

            })

        })


    },

    methods: {

        get_gendered_coach() {
            if (this.$store.getters['store_client/get_client_coach'].coach_gender == 1) {
                return 'Ihres Coaches'
            } else {
                return 'Ihrer Coachin'
            }
        },

        openPanelOpen() {

            if (this.panel_open) {
                this.panel_open = false
            } else {
                this.panel_open = true
            }
        },

        openPanelFinished() {

            if (this.panel_finished) {
                this.panel_finished = false
            } else {
                this.panel_finished = true
            }
        },

        get_short_question(item) {

            var tmp = JSON.parse(item.original_task_data)

            return tmp.wagtail_task_data.value.shortQuestion

        },

        show_unfinished_panel_null_alert() {

            var tmp = true;

            if (this.unfinished_tasks.length == 0) {
                tmp = true
            } else {
                tmp = false
            }

            return tmp

        },

        show_finished_panel_null_alert() {

           if (this.finished_tasks.length == 0) {

                return true

            } else {

                return false

            }
        
        },

        goToTaskDetails(task) {

            this.$router.push({ name: 'client-tasks-details', params: { task_id: task.id} })

        },

    }
}
</script>

<style scoped>
.note {
  white-space: pre-line; 
  word-wrap: break-word;
  font-family: inherit;
  text-align: left !important;
}
</style>