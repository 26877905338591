<template>

    <div class="mx-3">

        <v-row>

            <!-- FIELD Pro -->
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12':'6'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'6'">

                <v-card
                    class="mx-auto"
                    outlined
                    min-height="120px"
                    height="100%"
                >

                    <v-card-text class="text-center headline primary--text font-weight-bold">{{ data.headerPro }}</v-card-text>

                    <v-divider />
                    
                    <v-card-text>

                        <v-row
                            v-for="(item, i) in data.proList"
                            :key="i"
                            align="center" 
                            justify="center"
                        >

                            <v-card color="accent" class="my-3 mx-3">

                                <v-card-text class="body-1">
                                {{ item }}
                                <v-btn icon disabled>
                                    <v-icon color="black">far fa-times-circle</v-icon>
                                </v-btn>
                                </v-card-text>
                                                    
                            </v-card>

                        </v-row>

                    </v-card-text>

                </v-card>

            </v-col>

            <!-- FIELD Contra -->
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12':'6'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'6'">

                <v-card
                    class="mx-auto"
                    outlined
                    min-height="120px"
                    height="100%"
                >

                    <v-card-text class="text-center headline primary--text font-weight-bold">{{ data.headerContra }}</v-card-text>

                    <v-divider />

                    <v-card-text>

                        <v-row
                            v-for="(item, i) in data.contraList"
                            :key="i"
                            align="center" 
                            justify="center"
                        >

                            <v-card color="accent" class="my-3 mx-3">

                                <v-card-text class="body-1">
                                {{ item }}
                                <v-btn icon disabled>
                                    <v-icon color="black">far fa-times-circle</v-icon>
                                </v-btn>
                                </v-card-text>
                                                    
                            </v-card>

                        </v-row>

                    </v-card-text>

                </v-card>

            </v-col>

        </v-row>

    </div>

</template>

<script>
export default {
    name: 'pro-contra-input-task-data',

    props: ['data'],

}   
</script>

<style scoped>

</style>

