<template>
  <div class="mx-3" v-if="ready">

    <v-row>
      <div class="headline primary--text">{{ question }}</div>
    </v-row>

    <v-row>
      <div class="overline mb-3"><b>Tipp:</b> Fügen Sie in der Tabelle Aussagen/Stichwörter hinzu.</div>
    </v-row>

    <v-row v-if="!$vuetify.breakpoint.mdAndDown">

        <v-col cols="2" sm="2"></v-col>

        <v-col cols='5' sm='5'>

            <v-card
                class="mx-auto"
                outlined
            >

                <v-card-text class="text-center headline primary--text font-weight-bold">{{ headerColumn1 }}</v-card-text>

            </v-card>

        </v-col>

        <v-col cols='5' sm='5'>

            <v-card
                class="mx-auto"
                outlined
            >

                <v-card-text class="text-center headline primary--text font-weight-bold">{{ headerColumn2 }}</v-card-text>

            </v-card>

        </v-col>

    </v-row>

    <v-row>

        <v-col v-if="!$vuetify.breakpoint.mdAndDown" :cols="$vuetify.breakpoint.mdAndDown ? '12':'2'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'2'">

            <v-card
                class="mx-auto"
                outlined
                min-height="120px"
                height="100%"
            >

                <v-row align="center" justify="center">

                        <v-col cols="12">

                            <v-card-text class="text-center headline primary--text font-weight-bold">{{ headerRow1 }}</v-card-text>

                        </v-col>

                </v-row>

            </v-card>

        </v-col>


        <!-- FIELD 1 -->
        <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12':'5'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'5'">

            <v-card
                class="mx-auto"
                outlined
                min-height="120px"
                height="100%"
            >

                <v-card-text v-if="$vuetify.breakpoint.mdAndDown" class="text-center headline primary--text font-weight-bold">{{ headerColumn1 }} | {{ headerRow1}}</v-card-text>

                <v-divider v-if="$vuetify.breakpoint.mdAndDown" />

                <v-card-text>

                    <v-row v-if="!field1List.length" class="mx-1" align="center" justify="center">

                        <v-alert 
                            :value="true"
                            color="black"
                            icon="fas fa-exclamation-triangle"
                            outlined
                        >
                            Die Liste ist noch leer. 
                            <span v-if="minItemsField1 != 0">Fügen Sie mindestens {{ minItemsField1 }} Aussagen/Stichwörter ein!</span>
                            <span v-if="minItemsField1 == 0">Sie müssen jedoch keine Aussagen/Stichwörter hinzufügen.</span>
                        </v-alert>

                    </v-row>

                    <v-row v-if="field1List.length > 0 && field1List.length < minItemsField1" class="mx-1" align="center" justify="center">

                        <v-alert 
                            :value="true"
                            color="black"
                            icon="fas fa-exclamation-triangle"
                            outlined
                        >
                            Bitte fügen Sie insgesamt {{ minItemsField1 }} Aussagen/Stichwörter ein!
                        </v-alert>

                    </v-row>

                    <v-row
                        v-for="(item, i) in field1List"
                        :key="i"
                        align="center" 
                        justify="center"
                    >

                        <v-card color="accent" class="my-3 mx-3">

                            <v-card-text class="body-1">
                            {{ item }}
                            <v-btn icon :disabled = "thisBtnDisabled" @click="closeField1Chip(i)">
                                <v-icon color="black">far fa-times-circle</v-icon>
                            </v-btn>
                            </v-card-text>
                                                
                        </v-card>

                    </v-row>

                    <v-row class="mx-1 mt-5" v-if="!thisBtnDisabled">

                        <v-text-field
                            v-model="field1Item"
                            outlined
                            clear-icon="far fa-plus-square"
                            clearable
                            label="Fügen Sie ein Item hinzu."
                            type="text"
                            @click:clear="addField1Item"
                            :disabled="thisBtnDisabled"
                            color="accent"
                            hint='Tippen sie zuerst ihren Text ein. Klicken Sie danach auf das + - Zeichen.'
                        ></v-text-field>

                    </v-row>

                </v-card-text>
                
            </v-card>

        </v-col>

        <!-- FIELD 2 -->
        <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12':'5'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'5'">

            <v-card
                class="mx-auto"
                outlined
                min-height="120px"
                height="100%"
            >

                <v-card-text v-if="$vuetify.breakpoint.mdAndDown" class="text-center headline primary--text font-weight-bold">{{ headerColumn2 }} | {{ headerRow1}}</v-card-text>

                <v-divider v-if="$vuetify.breakpoint.mdAndDown" />
                
                <v-card-text>

                    <v-row v-if="!field2List.length" class="mx-1" align="center" justify="center">

                        <v-alert 
                            :value="true"
                            color="black"
                            icon="fas fa-exclamation-triangle"
                            outlined
                        >
                            Die Liste ist noch leer.
                            <span v-if="minItemsField2 != 0">Fügen Sie mindestens {{ minItemsField2 }} Aussagen/Stichwörter ein!</span>
                            <span v-if="minItemsField2 == 0">Sie müssen jedoch keine Aussagen/Stichwörter hinzufügen.</span>
                        </v-alert>

                    </v-row>

                    <v-row v-if="field2List.length > 0 && field2List.length < minItemsField2" class="mx-1" align="center" justify="center">

                        <v-alert 
                            :value="true"
                            color="black"
                            icon="fas fa-exclamation-triangle"
                            outlined
                        >
                            Bitte fügen Sie insgesamt {{ minItemsField2 }} Aussagen/Stichwörter ein!
                        </v-alert>

                    </v-row>

                    <v-row
                        v-for="(item, i) in field2List"
                        :key="i"
                        align="center" 
                        justify="center"
                    >

                        <v-card color="accent" class="my-3 mx-3">

                            <v-card-text class="body-1">
                            {{ item }}
                            <v-btn icon :disabled = "thisBtnDisabled" @click="closeField2Chip(i)">
                                <v-icon color="black">far fa-times-circle</v-icon>
                            </v-btn>
                            </v-card-text>
                                                
                        </v-card>

                    </v-row>

                    <v-row class="mx-1 mt-5" v-if="!thisBtnDisabled">

                        <v-text-field
                            v-model="field2Item"
                            outlined
                            clear-icon="far fa-plus-square"
                            clearable
                            label="Fügen Sie ein Item hinzu."
                            type="text"
                            @click:clear="addField2Item"
                            :disabled="thisBtnDisabled"
                            color="accent"
                            hint='Tippen sie zuerst ihren Text ein. Klicken Sie danach auf das + - Zeichen.'
                        ></v-text-field>

                    </v-row>

                </v-card-text>

            </v-card>

        </v-col>

        <v-col v-if="!$vuetify.breakpoint.mdAndDown" :cols="$vuetify.breakpoint.mdAndDown ? '12':'2'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'2'">

            <v-card
                class="mx-auto"
                outlined
                min-height="120px"
                height="100%"
            >

                <v-row align="center" justify="center">

                        <v-col cols="12">

                            <v-card-text class="text-center headline primary--text font-weight-bold">{{ headerRow2 }}</v-card-text>

                        </v-col>

                </v-row>

            </v-card>

        </v-col>

        <!-- FIELD 3 -->
        <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12':'5'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'5'">

            <v-card
                class="mx-auto"
                outlined
                min-height="120px"
                height="100%"
            >

                <v-card-text v-if="$vuetify.breakpoint.mdAndDown" class="text-center headline primary--text font-weight-bold">{{ headerColumn1 }} | {{ headerRow2}}</v-card-text>

                <v-divider v-if="$vuetify.breakpoint.mdAndDown" />
                
                <v-card-text>

                    <v-row v-if="!field3List.length" class="mx-1" align="center" justify="center">

                        <v-alert 
                            :value="true"
                            color="black"
                            icon="fas fa-exclamation-triangle"
                            outlined
                        >
                            Die Liste ist noch leer.
                            <span v-if="minItemsField3 != 0">Fügen Sie mindestens {{ minItemsField3 }} Aussagen/Stichwörter ein!</span>
                            <span v-if="minItemsField3 == 0">Sie müssen jedoch keine Aussagen/Stichwörter hinzufügen.</span>
                        </v-alert>

                    </v-row>

                    <v-row v-if="field3List.length > 0 && field3List.length < minItemsField3" class="mx-1" align="center" justify="center">

                        <v-alert 
                            :value="true"
                            color="black"
                            icon="fas fa-exclamation-triangle"
                            outlined
                        >
                            Bitte fügen Sie insgesamt {{ minItemsField3 }} Aussagen/Stichwörter ein!
                        </v-alert>

                    </v-row>

                    <v-row
                        v-for="(item, i) in field3List"
                        :key="i"
                        align="center" 
                        justify="center"
                    >

                        <v-card color="accent" class="my-3 mx-3">

                            <v-card-text class="body-1">
                            {{ item }}
                            <v-btn icon :disabled = "thisBtnDisabled" @click="closeField3Chip(i)">
                                <v-icon color="black">far fa-times-circle</v-icon>
                            </v-btn>
                            </v-card-text>
                                                
                        </v-card>

                    </v-row>

                    <v-row class="mx-1 mt-5" v-if="!thisBtnDisabled">

                        <v-text-field
                            v-model="field3Item"
                            outlined
                            clear-icon="far fa-plus-square"
                            clearable
                            label="Fügen Sie ein Item hinzu."
                            type="text"
                            @click:clear="addField3Item"
                            :disabled="thisBtnDisabled"
                            color="accent"
                            hint='Tippen sie zuerst ihren Text ein. Klicken Sie danach auf das + - Zeichen.'
                        ></v-text-field>

                    </v-row>

                </v-card-text>

            </v-card>

        </v-col>

        <!-- FIELD 4 -->
        <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12':'5'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'5'">

            <v-card
                class="mx-auto"
                outlined
                min-height="120px"
                height="100%"
            >

                <v-card-text v-if="$vuetify.breakpoint.mdAndDown" class="text-center headline primary--text font-weight-bold">{{ headerColumn2 }} | {{ headerRow2}}</v-card-text>

                <v-divider v-if="$vuetify.breakpoint.mdAndDown" />
                
                <v-card-text>

                    <v-row v-if="!field4List.length" class="mx-1" align="center" justify="center">

                        <v-alert 
                            :value="true"
                            color="black"
                            icon="fas fa-exclamation-triangle"
                            outlined
                        >
                            Die Liste ist noch leer.
                            <span v-if="minItemsField4 != 0">Fügen Sie mindestens {{ minItemsField4 }} Aussagen/Stichwörter ein!</span>
                            <span v-if="minItemsField4 == 0">Sie müssen jedoch keine Aussagen/Stichwörter hinzufügen.</span>
                        </v-alert>

                    </v-row>

                    <v-row v-if="field4List.length > 0 && field4List.length < minItemsField4" class="mx-1" align="center" justify="center">

                        <v-alert 
                            :value="true"
                            color="black"
                            icon="fas fa-exclamation-triangle"
                            outlined
                        >
                            Bitte fügen Sie insgesamt {{ minItemsField4 }} Aussagen/Stichwörter ein!
                        </v-alert>

                    </v-row>

                    <v-row
                        v-for="(item, i) in field4List"
                        :key="i"
                        align="center" 
                        justify="center"
                    >

                        <v-card color="accent" class="my-3 mx-3">

                            <v-card-text class="body-1">
                            {{ item }}
                            <v-btn icon :disabled = "thisBtnDisabled" @click="closeField4Chip(i)">
                                <v-icon color="black">far fa-times-circle</v-icon>
                            </v-btn>
                            </v-card-text>
                                                
                        </v-card>

                    </v-row>

                    <v-row class="mx-1 mt-5" v-if="!thisBtnDisabled">

                        <v-text-field
                            v-model="field4Item"
                            outlined
                            clear-icon="far fa-plus-square"
                            clearable
                            label="Fügen Sie ein Item hinzu."
                            type="text"
                            @click:clear="addField4Item"
                            :disabled="thisBtnDisabled"
                            color="accent"
                            hint='Tippen sie zuerst ihren Text ein. Klicken Sie danach auf das + - Zeichen.'
                        ></v-text-field>

                    </v-row>

                </v-card-text>

            </v-card>

        </v-col>

    </v-row>
     
  </div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'four-field-input-form',
    props: ['taskID', 'question', 'id', 'minItemsField1', 'minItemsField2', 'minItemsField3', 'minItemsField4', 'headerColumn1', 'headerColumn2', 'headerRow1', 'headerRow2'],

    data: () => ({
      field1List: [],
      field2List: [],
      field3List: [],
      field4List: [],

      field1Item: null,
      field2Item: null,
      field3Item: null,
      field4Item: null,

      ready: false,

    }),


    created () {

      this.ready = false

      if (_.isEmpty(this.$store.getters['store_lection/get_saved_lection_task_data_by_identifier'](this.taskID))) {

        // define task data store
        
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'field1List', 'data': this.field1List})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'field2List', 'data': this.field2List})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'field3List', 'data': this.field3List})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'field4List', 'data': this.field4List})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'type', 'data': 'fourFieldInputForm'})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'headerColumn1', 'data': this.headerColumn1})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'headerColumn2', 'data': this.headerColumn2})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'headerRow1', 'data': this.headerRow1})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'headerRow2', 'data': this.headerRow2})

        this.ready = true

      } else {

        // get data
        var data = this.$store.getters['store_lection/get_saved_lection_task_data_by_identifier'](this.taskID)
        this.field1List = data.field1List
        this.field2List = data.field2List
        this.field3List = data.field3List
        this.field4List = data.field4List

        this.ready = true

      }
      
    },

    computed: {
        
        thisBtnDisabled () {

            var tmp = this.$store.getters['store_lection/get_saved_lection_data']
                
            if (tmp.data[this.id].finished == false) {

                return false

            } else {

                return true

            }

        },

    },

    watch: {

      field1List: function(val) {

        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'field1List', 'data': val})

        if (val.length >= this.minItemsField1  && this.field2List.length >= this.minItemsField2 && this.field3List.length >= this.minItemsField3 && this.field4List.length >= this.minItemsField4) {
             this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': true}) 
        } else {
             this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false}) 
        }
      
      },

      field2List: function(val) {

        if (val.length >= this.minItemsField2  && this.field1List.length >= this.minItemsField1 && this.field3List.length >= this.minItemsField3 && this.field4List.length >= this.minItemsField4) {
             this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': true}) 
        } else {
             this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false}) 
        }

        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'field2List', 'data': val})
      
      },

      field3List: function(val) {

        if (val.length >= this.minItemsField3  && this.field2List.length >= this.minItemsField2 && this.field1List.length >= this.minItemsField1 && this.field4List.length >= this.minItemsField4) {
             this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': true}) 
        } else {
             this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false}) 
        }

        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'field3List', 'data': val})
      
      },

      field4List: function(val) {

          if (val.length >= this.minItemsField4  && this.field2List.length >= this.minItemsField2 && this.field3List.length >= this.minItemsField3 && this.field1List.length >= this.minItemsField1) {
             this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': true}) 
        } else {
             this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false}) 
        }

        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'field4List', 'data': val})
     
     },

    },

    methods: {

      // add a item to the list and tick it
      addField1Item () {

        this.field1List.push(this.field1Item)
        this.field1Item = null

      },

      addField2Item () {

        this.field2List.push(this.field2Item)
        this.field2Item = null

      },

      addField3Item () {

        this.field3List.push(this.field3Item)
        this.field3Item = null

      },

      addField4Item () {

        this.field4List.push(this.field4Item)
        this.field4Item = null

      },

      closeField1Chip(i) {

          this.field1List.splice(i, 1)

      },

      closeField2Chip(j) {

          this.field2List.splice(j, 1)

      },

      closeField3Chip(i) {

          this.field3List.splice(i, 1)

      },

      closeField4Chip(j) {

          this.field4List.splice(j, 1)

      }
     
    }

}   
</script>

<style scoped>
.custom-chip-field1 {

    display: inline-flex;
    flex-direction: row;
    background-color: #fcb900;

    width: auto;

    height: auto;

    outline: none;

    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-left: 12px;

    margin: 12px;

    line-height: 20px;

    white-space: pre-wrap;

    align-items: center;

    border-radius: 20px;
    font-size: 14px;

    vertical-align: middle;
    text-decoration: none;
    justify-content: center;

}

.custom-chip-field2 {

    display: inline-flex;
    flex-direction: row;
    background-color: #fcb900;

    width: auto;

    height: auto;

    outline: none;

    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-left: 12px;

    margin: 12px;

    line-height: 20px;

    white-space: pre-wrap;

    align-items: center;

    border-radius: 20px;
    font-size: 14px;

    vertical-align: middle;
    text-decoration: none;
    justify-content: center;

}

.drop-main {
    display: flex;
    justify-content: center;
    flex-direction: column;

    align-items: center;


    background: #EEEEEE;

    border-style: solid;
    border-width: 1px;
    border-radius: 5px !important;
    border-color: rgb(148, 148, 148) !important;
}

.row-div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  overflow: auto;
  text-align: center;
}
</style>
