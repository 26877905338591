<template>

    <v-card flat color="grey lighten-5">

        <v-card-text>

            <v-row>
                <v-col cols="12" sm="12">  
                    <v-card-text class="display-1 primary--text text-center">
                        Gut gemacht!
                    </v-card-text>
                </v-col>
                <v-col cols="12" sm="12">
                    <v-card-text class="display-1 primary--text text-center">
                        Sie haben das Online-Programm @myTabu erfolgreich abgeschlossen.
                    </v-card-text>
                </v-col>
            </v-row>

        </v-card-text>

        <v-card-text>

            <v-row v-if="$store.getters['store_client/get_client_progress'].has_to_wait_tasks || $store.getters['store_client/get_client_progress'].has_to_wait_questionnaires || $store.getters['store_client/get_client_reward'].current_coins > 0 || $store.getters['store_client/get_client_reward'].current_euros > 0">
                <v-col cols="12" sm="12">
                    <v-row align="center" justify="center" class="subtitle-1">
                        Es müssen noch einige wenige Dinge erledigt werden:
                    </v-row>
                </v-col>
            </v-row>

            <v-row v-else>
                <v-col cols="12" sm="12">
                    <v-row align="center" justify="center" class="body-1">
                        Wir möchten uns ganz herzlich bei Ihnen für Ihre Mitarbeit, Ihre Zeit und Ihr Engagement bedanken! 
                    </v-row>

                    <v-col cols="12" sm="12">
                        <v-row align="center" justify="center" class="subtitle-1">
                            Ihr @myTabu-Team
                        </v-row>
                    </v-col>
                </v-col>
            </v-row>

        </v-card-text>

        <v-card-text>

            <v-row v-if="$store.getters['store_client/get_client_progress'].has_to_wait_questionnaires">

                <v-col cols="12" sm="12">
                        
                        <v-card flat outlined>

                            <v-card-text class="title error--text text-center">
                                {{ get_correct_q_title }}
                            </v-card-text>

                            <v-divider />

                            <v-card-text class="text-center">
                                Da es sich bei @myTabu um eine wissenschaftliche Studie handelt, müssen wir Sie leider in regelmäßigen Abständen darum 
                                bitten, Fragebögen zu beantworten. Klicken Sie bitte auf den untenstehenden Button um die noch ausstehenden Fragebögen 
                                auszufüllen.
                            </v-card-text>

                            <v-card-actions>

                                <v-spacer />

                                <v-btn
                                    @click="go_to_q()"
                                    color="error"
                                >
                                    Zu den Fragebögen
                                </v-btn>

                                <v-spacer />

                            </v-card-actions>

                        </v-card>

                </v-col>

            </v-row>

            <v-row v-if="$store.getters['store_client/get_client_progress'].has_to_wait_tasks">

                <v-col cols="12" sm="12">
                        
                        <v-card flat outlined>

                            <v-card-text class="title error--text text-center">
                                {{ get_correct_task_title }}
                            </v-card-text>

                            <v-divider />

                            <v-card-text class="text-center">
                                Das Online-Programm beinhaltet einige Übungen, die Sie zusammen mit <span v-if="$store.getters['store_client/get_client_coach'].coach_gender == 1">Ihrem Coach</span><span v-else>Ihrer Coachin</span>bearbeiten. Leider sind noch nicht alle dieser Übungen
                                abgeschlossen, entweder weil Sie nochmals eine oder mehrere Übungen überarbeiten müssen oder weil Sie zu einer oder mehreren Übungen noch keine 
                                Rückmeldung von <span v-if="$store.getters['store_client/get_client_coach'].coach_gender == 1">Ihrem Coach</span><span v-else>Ihrer Coachin</span> erhalten haben. Bitte klicken Sie auf den Button "Zu den Übungen", um nachzusehen, um welche Übungen es sich 
                                handelt.
                            </v-card-text>

                            <v-card-actions>

                                <v-spacer />

                                <v-btn
                                    @click="go_to_task()"
                                    color="error"
                                >
                                    Zu den Übungen
                                </v-btn>

                                <v-spacer />

                            </v-card-actions>

                        </v-card>

                </v-col>

            </v-row>

            <v-row v-if="$store.getters['store_client/get_client_reward'].current_coins > 0 || $store.getters['store_client/get_client_reward'].current_euros > 0">

                <v-col cols="12" sm="12">
                        
                        <v-card flat outlined>

                            <v-card-text class="title error--text text-center">
                                Sie haben sich Ihre Aufwandsentschädigung noch nicht (vollständig) auszahlen lassen.
                            </v-card-text>

                            <v-divider />

                            <v-card-text class="text-center">
                                Sie haben viel Zeit für die Teilnahme an dieser wissenschaftlichen Studie investiert. Lassen Sie sich jetzt Ihre Aufwandsentschädigung auszahlen.
                            </v-card-text>

                            <v-card-actions>

                                <v-spacer />

                                <v-btn
                                    @click="go_to_bank()"
                                    color="error"
                                >
                                    Zu meinem Konto
                                </v-btn>

                                <v-spacer />

                            </v-card-actions>

                        </v-card>

                </v-col>

            </v-row>

        </v-card-text>

    </v-card>

</template>

<script>
//import ClientApi from '@/_api/client_api'

export default {
    name: 'client-final-view',

    data: () => ({
        
        loaded: false,

        current_countdown: '00 : 00 : 00',

        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',

    }),

    created() {

        this.update_countdown()


    },

    computed: {

        get_correct_q_title() {

            var output = 'Einige Fragebögen sind noch unbeantwortet.'

            if (this.$store.getters['store_client/get_client_questionnaires'].unfinished_questionnaires_count == 1) {

                output = 'Ein Fragebogen ist noch unbeantwortet.'

            } else {
               output = 'Einige Fragebögen sind noch unbeantwortet.' 
            }

            return output

        },

        get_correct_task_title() {

            var output = 'Einige Übungen sind noch nicht abgeschlossen.'

            if (this.$store.getters['store_client/get_client_questionnaires'].unfinished_questionnaires_count == 1) {

                output = 'Eine Übung muss noch bearbeitet werden.'

            } else {
               output = 'Einige Übungen müssen noch bearbeitet werden.' 
            }

            return output

        },

    },

    methods: {

        go_to_bank(){

            this.$router.push({ name: 'client-bank'})

        },

        go_to_q() {

            this.$router.push({name: 'client-polls'})

        },

        go_to_task() {

            this.$router.push({name: 'client-tasks', props: { task_id: null }})

        },

        update_countdown() {

            var countDownDate = new Date(this.$store.getters['store_client/get_client'].account_open_until).getTime()

            // Update the count down every 1 second
            var x = setInterval(() => {

                // Get today's date and time
                var now = new Date().getTime();
                    
                // Find the distance between now and the count down date
                var distance = countDownDate - now;
                    
                // Time calculations for days, hours, minutes and seconds
                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                if (days < 10) {
                    this.days = '0' + days
                } else {
                    this.days = days
                }

                if (hours < 10) {
                    this.hours = '0' + hours
                } else {
                    this.hours = hours
                }

                if (minutes < 10) {
                    this.minutes = '0' + minutes
                } else {
                    this.minutes = minutes
                }

                if (seconds < 10) {
                    this.seconds = '0' + seconds
                } else {
                    this.seconds = seconds
                }

                this.value_progress_circular = (100/60)*seconds

                this.current_countdown = this.days + ':' + this.hours + ':' + this.minutes + ':' + this.seconds
                    
                // If the count down is over, write some text 
                if (distance < 0) {
                    clearInterval(x);
                    this.current_countdown = '00 : 00 : 00'
                    this.days = '00'
                    this.hours = '00'
                    this.minutes = '00'
                    this.seconds = '00'
                }

            }, 1000);


        }

    }
}
</script>

<style scoped>

</style>