import revision_txt from '@/_helpers/helper_guided_task_revision_array.js'
import revision_help from '@/_helpers/helper_guided_task_help_array.js'

/**
 * Funktion, die ein Template für die Antwort des Coach in Abhängigkeit der Parameter ausgibt.
 * @param {*} task_id 
 * @param {*} rev_number 
 * @param {*} answer_correct 
 * @param {*} worked_on_task 
 * @param {*} additional_hint 
 * @param {*} client_username 
 * @param {*} coach_username 
 */
function get_correct_template(task_id, rev_number, answer_correct, worked_on_task, additional_hint, client_username, coach_gender) {

    /**
     * Anrede. Diese kann gerne angepasst werden, wird aber in JEDEM template gleich angezeigt.
     */
    var template = 'Hallo ' + client_username + ',\n\n'


    /**
     * get the correct content
     */

    var txt_id = ''

    if (answer_correct) {
        txt_id = 'correct'
    } else if (worked_on_task) {

        if (rev_number == 0) {
            txt_id = '0'
        } else if (rev_number == 1) {
            txt_id = '1'
        } else if (rev_number == 2) {
            txt_id = '2'
        } else if (rev_number == 3) {
            txt_id = '3'
        } 
    } else {
        txt_id = 'not_worked'
    }

    try {
        template = template + revision_txt.revision_object[task_id][txt_id]
    }

    catch (e) {
        template = template + 'Für die task_id ' + task_id + ' wurde für die Revision ' + rev_number + ' noch kein template hinterlegt.'
    }

    /**
     * Abschluss. Kann gerne angepasst werden, iste jedoch für JEDES template gleich
     */
    var last = ''

    if (coach_gender == 1) {
        last = 'Ihr Coach'
    } else {
        last = 'Ihre Coachin'
    }

    template = template + '\n\nViele Grüße,\n' + last


    /**
     * gibt das template zurück
     * NICHTS ÄNDERN!
     */
    return JSON.stringify(template)

}


/**
 * Funktion, die die Hinweise für den Coach für eine Übung in Abhängigkeit der Parameter ausgibt.
 * @param {*} task_id 
 */
function get_correct_help(task_id) {

    var help = revision_help.help_object[task_id]

    return help

}

// export
export default { get_correct_template, get_correct_help }