<template>
  <div class="mx-3" v-if="ready">

    <v-row>
      <div class="headline primary--text">{{ question }}</div>
    </v-row>

    <v-row>
      <div class="overline mb-3"><b>Tipp:</b> Wählen Sie nur eine Antwortmöglichkeit aus.</div>
    </v-row>

    <v-row no-gutters>

        <v-col 
          :cols="responsiveColumn" 
          v-for="(label, i) in labels" 
          :key="label.name"
        >

          <v-checkbox
              v-model="checkbox[i]"   
              :label="label"
              data-vv-name="checkbox"
              type="checkbox"
              :disabled="thisBtnDisabled[i]"
              class="mt-0 pl-0 mb-2"
              color="accent"
              hide-details
          ></v-checkbox>

        </v-col>

    </v-row>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'multipleChoicePickOneForm',
    props: ['taskID', 'taskChoices', 'question', 'id'],

    data: () => ({

      checkbox: [],
      labels: [],

      ready: false

    }),

    created() {

      this.ready = false

      if (_.isEmpty(this.$store.getters['store_lection/get_saved_lection_task_data_by_identifier'](this.taskID))) {

        this.labels = Object.assign({}, this.taskChoices)

        var labels = []

        // define all checkboxes per default not picked
        for (var i = 0; i < this.taskChoices.length; i++) {

          this.checkbox.push(false)
          labels.push(this.labels[i])

        }

        // define task data store
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'checkbox', 'data': this.checkbox})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'labels', 'data': this.labels})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'type', 'data': 'multipleChoicePickOneForm'})

        this.ready = true

      } else {

        // get data
        var data = this.$store.getters['store_lection/get_saved_lection_task_data_by_identifier'](this.taskID)
        this.checkbox = data.checkbox
        this.labels = data.labels

        this.ready = true

      }
      
    },

    computed: {
        
        thisBtnDisabled() {

            var tmp = this.$store.getters['store_lection/get_saved_lection_data']

            var disabled_array = []

            var nrTrueCheckboxes = 0;

            for (var k = 0; k < this.checkbox.length; k++) {

              disabled_array.push(false)

            }
                
            if (tmp.data[this.id].finished == false) {

                for (var l = 0; l < this.checkbox.length; l++) {

                    if (this.checkbox[l] == true) {

                        nrTrueCheckboxes = nrTrueCheckboxes + 1

                    }
                }

                if (nrTrueCheckboxes >= 1) {

                  for (var m = 0; m < disabled_array.length; m++) {

                    if (this.checkbox[m] == true) {

                      disabled_array[m] = false

                    } else {

                      disabled_array[m] = true

                    }

                  }

                } else {

                  for (var j = 0; j < disabled_array.length; j++) {

                    disabled_array[j] = false

                  }

                }

                return disabled_array


            } else {


              for (j = 0; j < disabled_array.length; j++) {

                disabled_array[j] = true

              }

              return disabled_array
  
            }

        },

        responsiveColumn() {

          if (this.taskChoices.length <= 5) {

            return '12'

          } else {

              if (this.$vuetify.breakpoint.name == 'xs' || this.$vuetify.breakpoint.name == 'sm') {

                return '12'

              } else {

                return '6'

              }

            }
        
        },

    },

    watch: {

      // watch the checkbox-state and update the store immediatly on change
      checkbox: function(val) {

        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'checkbox', 'data': val})

        var nrTrueCheckboxes = 0

        for (var l = 0; l < this.checkbox.length; l++) {

            if (this.checkbox[l] == true) {

                nrTrueCheckboxes = nrTrueCheckboxes + 1

            }
        }

        if (nrTrueCheckboxes == 1) {

          this.finished = true
          this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': true})

        } else {

          this.finished = false
          this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false})

        }

      }

    },
}   
</script>

<style scoped>
  
</style>