<template>
  <div class="mx-3" v-if="ready">

    <v-row>
      <div class="headline primary--text">{{ question }}</div>
    </v-row>

    <v-row>
      <div class="overline mb-3" v-if="numberChoices == 1"><b>Tipp:</b> Sie müssen eine Antwortmöglichkeit auswählen.</div>
      <div class="overline mb-3" v-else><b>Tipp:</b> Sie müssen {{ numberChoices }} Antwortmöglichkeiten auswählen.</div>
    </v-row>

    <v-row no-gutters>

        <v-col 
          :cols="responsiveColumn" 
          v-for="(label, i) in labels" 
          :key="label.name"
        >

          <v-checkbox
              v-model="checkbox[i]"   
              :label="label"
              data-vv-name="checkbox"
              type="checkbox"
              :disabled="thisBtnDisabled"
              class="mt-0 pl-0 mb-2"
              color="accent"
              hide-details
          ></v-checkbox>

        </v-col>

    </v-row>

    <v-row 
      align="center" 
      justify="center"
      v-if="alert"
    >

      <v-alert
        :value="alert"
        :color="alert_color"
        dark
        border="top"
        :icon="alert_icon"
        width="100%"
        class="mb-1 mt-3"
      >
        <div class="" v-html="alert_text"></div>
      </v-alert>

    </v-row>

  </div>
</template>

<script>
import _ from 'lodash'

export default {

    name: 'multipleChoicePickNForm',

    props: ['taskID', 'taskChoices', 'question', 'id', 'numberChoices'],

    data: () => ({

      checkbox: [],
      labels: [],

      ready: true,

      alert: false,
      alert_icon: 'fas fa-exclamation-triangle',
      alert_color: 'error',
      alert_text: '',

    }),

    created() {

      if (_.isEmpty(this.$store.getters['store_lection/get_saved_lection_task_data_by_identifier'](this.taskID))) {

        this.labels = Object.assign({}, this.taskChoices)

        var labels = []

        // define all checkboxes per default not picked
        for (var i = 0; i < this.taskChoices.length; i++) {

          this.checkbox.push(false)
          labels.push(this.labels[i])

        }

        // define task data store
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'checkbox', 'data': this.checkbox})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'labels', 'data': this.labels})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'type', 'data': 'multipleChoicePickNForm'})

      } else {

        // get data
        var data = this.$store.getters['store_lection/get_saved_lection_task_data_by_identifier'](this.taskID)
        this.checkbox = data.checkbox
        this.labels = data.labels

      }
      
    },

    computed: {
        
        thisBtnDisabled () {

            var tmp = this.$store.getters['store_lection/get_saved_lection_data']
                
            if (tmp.data[this.id].finished == false) {

                return false

            } else {

                return true

            }
 
        },

        responsiveColumn() {

          console.debug(this.taskChoices.length)

          if (this.taskChoices.length <= 5) {

            return '12'

          } else {

            console.debug("ELSE")

              if (this.$vuetify.breakpoint.name == 'xs' || this.$vuetify.breakpoint.name == 'sm') {

                return '12'

              } else {

                return '6'

              }

            }
        
        },

    },

    watch: {

      // watch the checkbox-state and update the store immediatly on change
      checkbox: function(val) {

        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'checkbox', 'data': val})

        var nrTrueCheckboxes = 0

        for (var l = 0; l < this.checkbox.length; l++) {

            if (this.checkbox[l] == true) {

                nrTrueCheckboxes = nrTrueCheckboxes + 1

            }
        }

        if (nrTrueCheckboxes == this.numberChoices) {

          this.alert = false

          this.finished = true
          this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': true})

        } else {

          if (nrTrueCheckboxes < this.numberChoices) {
            this.alert = true
            if (this.numberChoices == 1) {
              this.alert_text = 'Bitte wählen Sie mindestens eine Antwort aus.'
            } else {
              this.alert_text = 'Bitte wählen Sie mindestens ' + this.numberChoices + ' Antworten aus.'
            }
          } else if (nrTrueCheckboxes > this.numberChoices) {
            this.alert = true
            if (this.numberChoices == 1) {
              this.alert_text = 'Bitte wählen Sie nicht mehr als eine Antwort aus.'
            } else {
              this.alert_text = 'Bitte wählen Sie nicht mehr als ' + this.numberChoices + ' Antworten aus.'
            }
            
          }

          this.finished = false
          this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false})

        }

      }

    },

    methods: {
     
    }

}   
</script>

<style scoped>
  
</style>


