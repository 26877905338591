var help_object = {

    'Ich habe etwas gefälscht (z.B. Unterschrift oder Zeugnis).': {

        description:
            '<p>' +
                'Die Checklist of Behavioral Misconduct (CMC) ist ein Selbstbeurteilungsinstrument zur Erfassung allgemein und sexuell devianter Verhaltensweisen und bezieht ' +
                'sich auf einen Zeitraum von 4 Wochen. Jede erhobene Verhaltensweise lässt sich zudem unterscheiden in deliktnahes Verhalten und konkretes Rückfallverhalten. ' +
                'Beim deliktnahen Verhalten handelt es sich um Verhaltensweisen, die als Vorstufe für ein erneutes Sexualdelikt betrachtet werden <i>können</i>. Das Rückfallverhalten ' +
                'wiederum beinhaltet Verhaltensweisen, die einen eindeutigen Rückfall darstellen (z.B. das Beschaffen kinderpornographischer Schriften). Die relevante Subskala ' +
                'erfasst dabei allgemein deviantes Verhalten und orientiert sich an verschiedenen Straftatbeständen des StGB. Das vorliegende Item gibt <i>Hinweise</i> auf die ' +
                'Verwirklichung des objektiven Tatbestandes der Urkundenfälschung (§ 267 StGB).' +
            '</p>',
        nds:
            '<p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe? </b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, dass der Zweck der ' +
                'Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, wie bspw. eine andere ' +
                'Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p><p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern (KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei verläuft, eine ' +
                'unverzügliche Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). ' +
            '</p>',
        bw:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten, Verstöße gegen Weisungen und neue Straftaten unverzüglich ' + 
                'der Führungsaufsichtsstelle zu melden sind, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu überprüfen und entsprechend anzupassen.' +
            '</p>',
        bayern: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. ' +
                'Es <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, wenn das gezeigte Verhalten ' +
                '(Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) ' +
                'Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b> ' +
            '</p><p>' +
                'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, ' +
                'dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, ' +
                'wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist.' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, ' +
                'dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB) um einen Widerruf zu vermeiden.' +
            '</p>'

    },

    'Ich habe online Sachen eingekauft und nicht bezahlt.': {

        description:
            '<p>' +
                'Die Checklist of Behavioral Misconduct (CMC) ist ein Selbstbeurteilungsinstrument zur Erfassung allgemein und sexuell devianter Verhaltensweisen und bezieht ' +
                'sich auf einen Zeitraum von 4 Wochen. Jede erhobene Verhaltensweise lässt sich zudem unterscheiden in deliktnahes Verhalten und konkretes ' +
                'Rückfallverhalten. Beim deliktnahen Verhalten handelt es sich um Verhaltensweisen, die als Vorstufe für ein erneutes Sexualdelikt betrachtet werden ' +
                '<i>können</i>. Das Rückfallverhalten wiederum beinhaltet Verhaltensweisen, die einen eindeutigen Rückfall darstellen (z.B. das Beschaffen kinderpornographischer ' +
                'Schriften). Die relevante Subskala erfasst dabei allgemein deviantes Verhalten und orientiert sich an verschiedenen Straftatbeständen des StGB. ' +
                'Das vorliegende Item gibt <i>Hinweise</i> auf die Verwirklichung des objektiven Tatbestandes des Betrugs (§ 263 StGB).' +
            '</p>',
        nds:
            '<p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, dass der ' +
                'Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, wie ' +
                'bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p><p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern (KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei verläuft, eine ' +
                'unverzügliche Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). Im Falle von Anzeichen einer ' +
                'ernsthaften Gefährdung Dritter ergeht ebenfalls eine Meldung nachrichtlich an die zuständige Fachberaterin oder den zuständigen Fachberater Risikomanagement. ' +
            '</p><p>' +
                'Des Weiteren sieht die Konzeption bei der möglichen Gefährdung Dritter nach einer Abwägung im Einzelfall eine zusätzliche Information der ' +
                'KURS-Sachbearbeiterinnen oder KURS-Sachbearbeiter vor. Dies <i>kann</i> bspw. unter den Voraussetzungen des § 34 StGB bei einer „gegenwärtigen, nicht anders ' +
                'abwendbaren Gefahr für Leben, Freiheit, Ehre, Eigentum oder ein anderes Rechtsgut“ der Fall sein, wenn das geschützte Interesse die datenschutzrechtliche ' +
                'Beeinträchtigung überwiegt. <i>Hier sind alle gesammelten Erkenntnisse in die Entscheidung miteinzubeziehen.</i>' +
            '</p>',
        bw:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten, Verstöße gegen Weisungen und neue Straftaten unverzüglich ' +
                'der Führungsaufsichtsstelle zu melden sind, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu überprüfen und entsprechend ' +
                'anzupassen. Außerdem wird angeordnet, dass der Bewährungshelfende im Falle einer gegenwärtigen Gefahr für Leib oder Leben, Gesundheit, persönliche Freiheit oder die sexuelle ' +
                'Selbstbestimmung einer Person sowie – soweit gesetzlich zulässig – in anderen Fällen einer gefährlichen und andere Personen gefährdenden Entwicklung des ' +
                'Risikoprobanden, ungeachtet anderer Unterrichtungspflichten, unverzüglich auch die für den Wohnort des Risikoprobanden zuständige Polizeidienststelle ' +
                'unterrichtet wird. Den am Wohnort zuständigen Polizeidienststellen obliegt die Festlegung und Koordinierung der gefahrenabwehrrechtlichen Maßnahmen bei ' +
                'den Risikoprobanden (Ziffer 5.8.1).' +
            '</p><p>' +
                'Als gefahrenabwehrrechtliche Maßnahmen kommen nach Ziffer 5.8.2 <i>bspw.</i> die Gefährderansprache, Gefährdetenansprache oder schließlich auch die Observation in ' +
                'Betracht.' +
            '</p>',
        bayern:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. ' +
                'Es <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, wenn das gezeigte Verhalten ' +
                '(Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) ' +
                'Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b> ' +
            '</p><p>' +
                'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, ' +
                'dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, ' +
                'wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist.' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, ' +
                'dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB) um einen Widerruf zu vermeiden.' +
            '</p>'

    },

    'Ich habe etwas gestohlen.': {

        description:
            '<p>' +
                'Die Checklist of Behavioral Misconduct (CMC) ist ein Selbstbeurteilungsinstrument zur Erfassung allgemein und sexuell devianter Verhaltensweisen und bezieht sich ' +
                'auf einen Zeitraum von 4 Wochen. Jede erhobene Verhaltensweise lässt sich zudem unterscheiden in deliktnahes Verhalten und konkretes Rückfallverhalten. Beim ' +
                'deliktnahen Verhalten handelt es sich um Verhaltensweisen, die als Vorstufe für ein erneutes Sexualdelikt betrachtet werden <i>können</i>. Das Rückfallverhalten ' +
                'wiederum beinhaltet Verhaltensweisen, die einen eindeutigen Rückfall darstellen (z.B. das Beschaffen kinderpornographischer Schriften). Die relevante ' +
                'Subskala erfasst dabei allgemein deviantes Verhalten und orientiert sich an verschiedenen Straftatbeständen des StGB. Das vorliegende Item gibt <i>Hinweise</i> auf ' +
                'die Verwirklichung des objektiven Tatbestandes des Diebstahls (§ 242 StGB).' +
            '</p>',
        nds:
            '<p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, dass der Zweck der ' +
                'Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, wie bspw. eine andere ' +
                'Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p><p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern (KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei verläuft, eine ' +
                'unverzügliche Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). Im Falle von Anzeichen einer ernsthaften ' +
                'Gefährdung Dritter ergeht ebenfalls eine Meldung nachrichtlich an die zuständige Fachberaterin oder den zuständigen Fachberater Risikomanagement. ' +
            '</p><p>' +
                'Des Weiteren sieht die Konzeption bei der möglichen Gefährdung Dritter nach einer Abwägung im Einzelfall eine zusätzliche Information der ' +
                'KURS-Sachbearbeiterinnen oder KURS-Sachbearbeiter vor. Dies <i>kann</i> bspw. unter den Voraussetzungen des § 34 StGB bei einer „gegenwärtigen, nicht ' +
                'anders abwendbaren Gefahr für Leben, Freiheit, Ehre, Eigentum oder ein anderes Rechtsgut“ der Fall sein, wenn das geschützte Interesse die ' +
                'datenschutzrechtliche Beeinträchtigung überwiegt. <i>Hier sind alle gesammelten Erkenntnisse in die Entscheidung miteinzubeziehen</i>.' +
            '</p>',
        bw:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten, Verstöße gegen Weisungen und neue Straftaten ' +
                'unverzüglich der Führungsaufsichtsstelle zu melden sind, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu überprüfen ' +
                'und entsprechend anzupassen. Außerdem wird angeordnet, dass im Falle einer gegenwärtigen Gefahr für Leib oder Leben, Gesundheit, persönliche Freiheit ' +
                'oder die sexuelle Selbstbestimmung einer Person sowie – soweit gesetzlich zulässig – in anderen Fällen einer gefährlichen und andere Personen gefährdenden ' +
                'Entwicklung des Risikoprobanden, ungeachtet anderer Unterrichtungspflichten, unverzüglich auch die für den Wohnort des Risikoprobanden zuständige ' +
                'Polizeidienststelle unterrichtet wird. Den am Wohnort zuständigen Polizeidienststellen obliegt die Festlegung und Koordinierung der ' +
                'gefahrenabwehrrechtlichen Maßnahmen bei den Risikoprobanden (Ziffer 5.8.1).' +
            '</p><p>' +
                'Als gefahrenabwehrrechtliche Maßnahmen kommen nach Ziffer 5.8.2 <i>bspw.</i> die Gefährderansprache, Gefährdetenansprache oder schließlich auch die Observation ' +
                'in Betracht.' +
            '</p>',
        bayern:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. ' +
                'Es <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, wenn das gezeigte Verhalten ' +
                '(Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) ' +
                'Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b> ' +
            '</p><p>' +
                'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, ' +
                'dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, ' +
                'wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist.' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, ' +
                'dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB) um einen Widerruf zu vermeiden.' +
            '</p>'

    },

    'Ich habe etwas absichtlich beschädigt oder zerstört, das mir nicht gehört.': {

        description:
            '<p>' +
                'Die Checklist of Behavioral Misconduct (CMC) ist ein Selbstbeurteilungsinstrument zur Erfassung allgemein und sexuell devianter Verhaltensweisen und bezieht ' +
                'sich auf einen Zeitraum von 4 Wochen. Jede erhobene Verhaltensweise lässt sich zudem unterscheiden in deliktnahes Verhalten und konkretes Rückfallverhalten. ' +
                'Beim deliktnahen Verhalten handelt es sich um Verhaltensweisen, die als Vorstufe für ein erneutes Sexualdelikt betrachtet werden <i>können</i>. Das Rückfallverhalten ' +
                'wiederum beinhaltet Verhaltensweisen, die einen eindeutigen Rückfall darstellen (z.B. das Beschaffen kinderpornographischer Schriften). Die relevante Subskala ' +
                'erfasst dabei allgemein deviantes Verhalten und orientiert sich an verschiedenen Straftatbeständen des StGB. Das vorliegende Item gibt <i>Hinweise</i> auf die ' +
                'Verwirklichung des objektiven Tatbestandes der Sachbeschädigung (§ 303 StGB).' +
            '</p>',
        nds:
            '<p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, dass der Zweck der ' +
                'Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, wie bspw. eine ' +
                'andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p><p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern (KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei verläuft, eine ' +
                'unverzügliche Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). Im Falle von Anzeichen einer ' +
                'ernsthaften Gefährdung Dritter ergeht ebenfalls eine Meldung nachrichtlich an die zuständige Fachberaterin oder den zuständigen Fachberater Risikomanagement. ' +
            '</p><p>' +
                'Des Weiteren sieht die Konzeption bei der möglichen Gefährdung Dritter nach einer Abwägung im Einzelfall eine zusätzliche Information der ' +
                'KURS-Sachbearbeiterinnen oder KURS-Sachbearbeiter vor. Dies <i>kann</i> bspw. unter den Voraussetzungen des § 34 StGB bei einer „gegenwärtigen, ' +
                'nicht anders abwendbaren Gefahr für Leben, Freiheit, Ehre, Eigentum oder ein anderes Rechtsgut“ der Fall sein, wenn das geschützte Interesse ' +
                'die datenschutzrechtliche Beeinträchtigung überwiegt. <i>Hier sind alle gesammelten Erkenntnisse in die Entscheidung miteinzubeziehen.</i>' +
            '</p>',
        bw:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten, Verstöße gegen Weisungen und neue Straftaten ' +
                'unverzüglich der Führungsaufsichtsstelle zu melden sind, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu ' +
                'überprüfen und entsprechend anzupassen. Außerdem wird angeordnet, dass im Falle einer gegenwärtigen Gefahr für Leib oder Leben, Gesundheit, ' +
                'persönliche Freiheit oder die sexuelle Selbstbestimmung einer Person sowie – soweit gesetzlich zulässig – in anderen Fällen einer gefährlichen ' +
                'und andere Personen gefährdenden Entwicklung des Risikoprobanden, ungeachtet anderer Unterrichtungspflichten, unverzüglich auch die für den ' +
                'Wohnort des Risikoprobanden zuständige Polizeidienststelle unterrichtet wird. Den am Wohnort zuständigen Polizeidienststellen obliegt die ' +
                'Festlegung und Koordinierung der gefahrenabwehrrechtlichen Maßnahmen bei den Risikoprobanden (Ziffer 5.8.1).' +
            '</p><p>' +
                'Als gefahrenabwehrrechtliche Maßnahmen kommen nach Ziffer 5.8.2 <i>bspw.</i> die Gefährderansprache, Gefährdetenansprache oder schließlich auch die ' +
                'Observation in Betracht.' +
            '</p>',
        bayern:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. ' +
                'Es <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, wenn das gezeigte Verhalten ' +
                '(Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) ' +
                'Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b> ' +
            '</p><p>' +
                'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, ' +
                'dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, ' +
                'wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist.' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, ' +
                'dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB) um einen Widerruf zu vermeiden.' +
            '</p>'

    },

    'Ich habe mir unerlaubt Zugang zu einer Wohnung oder einem Haus verschafft.': {

        description:
            '<p>' +
                'Die Checklist of Behavioral Misconduct (CMC) ist ein Selbstbeurteilungsinstrument zur Erfassung allgemein und sexuell devianter Verhaltensweisen ' +
                'und bezieht sich auf einen Zeitraum von 4 Wochen. Jede erhobene Verhaltensweise lässt sich zudem unterscheiden in deliktnahes Verhalten und ' +
                'konkretes Rückfallverhalten. Beim deliktnahen Verhalten handelt es sich um Verhaltensweisen, die als Vorstufe für ein erneutes Sexualdelikt ' +
                'betrachtet werden <i>können</i>. Das Rückfallverhalten wiederum beinhaltet Verhaltensweisen, die einen eindeutigen Rückfall darstellen (z.B. das ' +
                'Beschaffen kinderpornographischer Schriften). Die relevante Subskala erfasst dabei allgemein deviantes Verhalten und orientiert sich an ' +
                'verschiedenen Straftatbeständen des StGB. Das vorliegende Item gibt <i>Hinweise</i> auf die Verwirklichung des objektiven Tatbestandes des ' +
                'Hausfriedensbruchs (§ 123 StGB).' +
            '</p>',
        nds:
            '<p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, dass der ' +
                'Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p><p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern (KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei verläuft, eine ' +
                'unverzügliche Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). Im Falle von Anzeichen einer ' +
                'ernsthaften Gefährdung Dritter ergeht ebenfalls eine Meldung nachrichtlich an die zuständige Fachberaterin oder den zuständigen Fachberater Risikomanagement. ' +
            '</p><p>' +
                'Des Weiteren sieht die Konzeption bei der möglichen Gefährdung Dritter nach einer Abwägung im Einzelfall eine zusätzliche Information der ' +
                'KURS-Sachbearbeiterinnen oder KURS-Sachbearbeiter vor. Dies <i>kann</i> bspw. unter den Voraussetzungen des § 34 StGB bei einer „gegenwärtigen, ' +
                'nicht anders abwendbaren Gefahr für Leben, Freiheit, Ehre, Eigentum oder ein anderes Rechtsgut“ der Fall sein, wenn das geschützte Interesse ' +
                'die datenschutzrechtliche Beeinträchtigung überwiegt. <i>Hier sind alle gesammelten Erkenntnisse in die Entscheidung miteinzubeziehen</i>.' +
            '</p>',
        bw:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten, Verstöße gegen Weisungen und neue Straftaten ' +
                'unverzüglich der Führungsaufsichtsstelle zu melden sind, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu ' +
                'überprüfen und entsprechend anzupassen. Außerdem wird angeordnet, dass im Falle einer gegenwärtigen Gefahr für Leib oder Leben, Gesundheit, ' +
                'persönliche Freiheit oder die sexuelle Selbstbestimmung einer Person sowie – soweit gesetzlich zulässig – in anderen Fällen einer gefährlichen ' +
                'und andere Personen gefährdenden Entwicklung des Risikoprobanden, ungeachtet anderer Unterrichtungspflichten, unverzüglich auch die für den Wohnort ' +
                'des Risikoprobanden zuständige Polizeidienststelle unterrichtet wird. Den am Wohnort zuständigen Polizeidienststellen obliegt die Festlegung ' +
                'und Koordinierung der gefahrenabwehrrechtlichen Maßnahmen bei den Risikoprobanden (Ziffer 5.8.1).' +
            '</p><p>' +
                'Als gefahrenabwehrrechtliche Maßnahmen kommen nach Ziffer 5.8.2 <i>bspw.</i> die Gefährderansprache, Gefährdetenansprache oder schließlich auch ' +
                'die Observation in Betracht.' +
            '</p>',
        bayern:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. ' +
                'Es <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, wenn das gezeigte Verhalten ' +
                '(Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) ' +
                'Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b> ' +
            '</p><p>' +
                'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, ' +
                'dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, ' +
                'wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist.' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, ' +
                'dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB) um einen Widerruf zu vermeiden.' +
            '</p>'

    },

    'Ich habe jemanden bedroht.': {

        description:
            '<p>' +
                'Die Checklist of Behavioral Misconduct (CMC) ist ein Selbstbeurteilungsinstrument zur Erfassung allgemein und sexuell devianter Verhaltensweisen ' +
                'und bezieht sich auf einen Zeitraum von 4 Wochen. Jede erhobene Verhaltensweise lässt sich zudem unterscheiden in deliktnahes Verhalten und konkretes ' +
                'Rückfallverhalten. Beim deliktnahen Verhalten handelt es sich um Verhaltensweisen, die als Vorstufe für ein erneutes Sexualdelikt betrachtet werden ' +
                '<i>können</i>. Das Rückfallverhalten wiederum beinhaltet Verhaltensweisen, die einen eindeutigen Rückfall darstellen (z.B. das Beschaffen kinderpornographischer ' +
                'Schriften). Die relevante Subskala erfasst dabei allgemein deviantes Verhalten und orientiert sich an verschiedenen Straftatbeständen des StGB. ' +
                'Das vorliegende Item gibt <i>Hinweise</i> auf die Verwirklichung des objektiven Tatbestandes der Bedrohung (§ 241 StGB).' +
            '</p>',
        nds:
            '<p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, dass der ' +
                'Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p><p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern (KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei verläuft, eine ' +
                'unverzügliche Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). Im Falle von Anzeichen einer ' +
                'ernsthaften Gefährdung Dritter ergeht ebenfalls eine Meldung nachrichtlich an die zuständige Fachberaterin oder den zuständigen Fachberater Risikomanagement. ' +
            '</p><p>' +
                'Des Weiteren sieht die Konzeption bei der möglichen Gefährdung Dritter nach einer Abwägung im Einzelfall eine zusätzliche Information der ' +
                'KURS-Sachbearbeiterinnen oder KURS-Sachbearbeiter vor. Dies <i>kann</i> bspw. unter den Voraussetzungen des § 34 StGB bei einer „gegenwärtigen, ' +
                'nicht anders abwendbaren Gefahr für Leben, Freiheit, Ehre, Eigentum oder ein anderes Rechtsgut“ der Fall sein, wenn das geschützte Interesse ' +
                'die datenschutzrechtliche Beeinträchtigung überwiegt. </i>Hier sind alle gesammelten Erkenntnisse in die Entscheidung miteinzubeziehen</i>.' +
            '</p>',
        bw:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten, Verstöße gegen Weisungen und neue Straftaten ' +
                'unverzüglich der Führungsaufsichtsstelle zu melden sind, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu ' +
                'überprüfen und entsprechend anzupassen. Außerdem wird angeordnet, dass im Falle einer gegenwärtigen Gefahr für Leib oder Leben, Gesundheit, ' +
                'persönliche Freiheit oder die sexuelle Selbstbestimmung einer Person sowie – soweit gesetzlich zulässig – in anderen Fällen einer gefährlichen ' +
                'und andere Personen gefährdenden Entwicklung des Risikoprobanden, ungeachtet anderer Unterrichtungspflichten, unverzüglich auch die für den ' +
                'Wohnort des Risikoprobanden zuständige Polizeidienststelle unterrichtet wird. Den am Wohnort zuständigen Polizeidienststellen obliegt die ' +
                'Festlegung und Koordinierung der gefahrenabwehrrechtlichen Maßnahmen bei den Risikoprobanden (Ziffer 5.8.1).' +
            '</p><p>' +
                'Als gefahrenabwehrrechtliche Maßnahmen kommen nach Ziffer 5.8.2 <i>bspw.</i> die Gefährderansprache, Gefährdetenansprache oder schließlich auch ' +
                'die Observation in Betracht.' +
            '</p>',
        bayern: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. ' +
                'Es <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, wenn das gezeigte Verhalten ' +
                '(Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) ' +
                'Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b> ' +
            '</p><p>' +
                'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, ' +
                'dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, ' +
                'wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist.' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, ' +
                'dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB) um einen Widerruf zu vermeiden.' +
            '</p>'

    },

    'Ich habe jemanden mit einer Waffe bedroht.': {

        description:
            '<p>' +
                'Die Checklist of Behavioral Misconduct (CMC) ist ein Selbstbeurteilungsinstrument zur Erfassung allgemein und sexuell devianter ' +
                'Verhaltensweisen und bezieht sich auf einen Zeitraum von 4 Wochen. Jede erhobene Verhaltensweise lässt sich zudem unterscheiden in ' +
                'deliktnahes Verhalten und konkretes Rückfallverhalten. Beim deliktnahen Verhalten handelt es sich um Verhaltensweisen, die als ' +
                'Vorstufe für ein erneutes Sexualdelikt betrachtet werden <i>können</i>. Das Rückfallverhalten wiederum beinhaltet Verhaltensweisen, die ' +
                'einen eindeutigen Rückfall darstellen (z.B. das Beschaffen kinderpornographischer Schriften). Die relevante Subskala erfasst dabei ' +
                'allgemein deviantes Verhalten und orientiert sich an verschiedenen Straftatbeständen des StGB. Das vorliegende Item gibt <i>Hinweise</i> ' +
                'auf die Verwirklichung des objektiven Tatbestandes der Bedrohung (§ 241 StGB).' +
            '</p>',
        nds:
            '<p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, ' +
                'dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende ' +
                'Maßnahme in Betracht, wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB) ' +
                'um einen Widerruf zu vermeiden.' +
            '</p><p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern (KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei verläuft, ' +
                'eine unverzügliche Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). Im Falle von ' +
                'Anzeichen einer ernsthaften Gefährdung Dritter ergeht ebenfalls eine Meldung nachrichtlich an die zuständige Fachberaterin oder den zuständigen ' +
                'Fachberater Risikomanagement.' +
            '</p><p>' +
                'Des Weiteren sieht die Konzeption bei der möglichen Gefährdung Dritter nach einer Abwägung im Einzelfall eine zusätzliche Information der ' +
                'KURS-Sachbearbeiterinnen oder KURS-Sachbearbeiter vor. Dies <i>kann</i> bspw. unter den Voraussetzungen des § 34 StGB bei einer „gegenwärtigen, ' +
                'nicht anders abwendbaren Gefahr für Leben, Freiheit, Ehre, Eigentum oder ein anderes Rechtsgut“ der Fall sein, wenn das geschützte Interesse ' +
                'die datenschutzrechtliche Beeinträchtigung überwiegt. <i>Hier sind alle gesammelten Erkenntnisse in die Entscheidung miteinzubeziehen.</i>' +
            '</p>',
        bw:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten, Verstöße gegen Weisungen und neue Straftaten ' +
                'unverzüglich der Führungsaufsichtsstelle zu melden sind, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu ' +
                'überprüfen und entsprechend anzupassen. Außerdem wird angeordnet, dass im Falle einer gegenwärtigen Gefahr für Leib oder Leben, Gesundheit, ' +
                'persönliche Freiheit oder die sexuelle Selbstbestimmung einer Person sowie – soweit gesetzlich zulässig – in anderen Fällen einer gefährlichen ' +
                'und andere Personen gefährdenden Entwicklung des Risikoprobanden, ungeachtet anderer Unterrichtungspflichten, unverzüglich auch die für den ' +
                'Wohnort des Risikoprobanden zuständige Polizeidienststelle unterrichtet wird. Den am Wohnort zuständigen Polizeidienststellen obliegt die ' +
                'Festlegung und Koordinierung der gefahrenabwehrrechtlichen Maßnahmen bei den Risikoprobanden (Ziffer 5.8.1).' +
            '</p><p>' +
                'Als gefahrenabwehrrechtliche Maßnahmen kommen nach Ziffer 5.8.2 <i>bspw.</i> die Gefährderansprache, Gefährdetenansprache oder schließlich auch ' +
                'die Observation in Betracht.' +
            '</p>',
        bayern: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. ' +
                'Es <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, wenn das gezeigte Verhalten ' +
                '(Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) ' +
                'Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b> ' +
            '</p><p>' +
                'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, ' +
                'dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, ' +
                'wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist.' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, ' +
                'dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB) um einen Widerruf zu vermeiden.' +
            '</p>'

    },
    
    'Ich habe jemanden eingesperrt.': {

        description:
            '<p>' +
                'Die Checklist of Behavioral Misconduct (CMC) ist ein Selbstbeurteilungsinstrument zur Erfassung allgemein und sexuell devianter ' +
                'Verhaltensweisen und bezieht sich auf einen Zeitraum von 4 Wochen. Jede erhobene Verhaltensweise lässt sich zudem unterscheiden ' +
                'in deliktnahes Verhalten und konkretes Rückfallverhalten. Beim deliktnahen Verhalten handelt es sich um Verhaltensweisen, die ' +
                'als Vorstufe für ein erneutes Sexualdelikt betrachtet werden <i>können</i>. Das Rückfallverhalten wiederum beinhaltet Verhaltensweisen, ' +
                'die einen eindeutigen Rückfall darstellen (z.B. das Beschaffen kinderpornographischer Schriften). Die relevante Subskala erfasst ' +
                'dabei allgemein deviantes Verhalten und orientiert sich an verschiedenen Straftatbeständen des StGB. Das vorliegende Item gibt ' +
                '<i>Hinweise</i> auf die Verwirklichung des objektiven Tatbestandes der Freiheitsberaubung (§ 239 StGB).' +
            '</p>',
        nds:
            '<p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ' +
                'ergibt, dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende ' +
                'Maßnahme in Betracht, wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention ' +
                '(§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p><p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern ' +
                '(KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei ' +
                'verläuft, eine unverzügliche Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). ' +
                'Im Falle von Anzeichen einer ernsthaften Gefährdung Dritter ergeht ebenfalls eine Meldung nachrichtlich an die zuständige Fachberaterin ' +
                'oder den zuständigen Fachberater Risikomanagement. ' +
            '</p><p>' +
                'Des Weiteren sieht die Konzeption bei der möglichen Gefährdung Dritter nach einer Abwägung im Einzelfall eine zusätzliche Information ' +
                'der KURS-Sachbearbeiterinnen oder KURS-Sachbearbeiter vor. Dies <i>kann</i> bspw. unter den Voraussetzungen des § 34 StGB bei einer ' +
                '„gegenwärtigen, nicht anders abwendbaren Gefahr für Leben, Freiheit, Ehre, Eigentum oder ein anderes Rechtsgut“ der Fall sein, ' +
                'wenn das geschützte Interesse die datenschutzrechtliche Beeinträchtigung überwiegt. <i>Hier sind alle gesammelten Erkenntnisse in die ' +
                'Entscheidung miteinzubeziehen</i>.' +
            '</p>',
        bw:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten, Verstöße gegen Weisungen und ' +
                'neue Straftaten unverzüglich der Führungsaufsichtsstelle zu melden sind, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen ' +
                'auf ihre Wirksamkeit zu überprüfen und entsprechend anzupassen. Außerdem wird angeordnet, dass im Falle einer gegenwärtigen Gefahr ' +
                'für Leib oder Leben, Gesundheit, persönliche Freiheit oder die sexuelle Selbstbestimmung einer Person sowie – soweit gesetzlich ' +
                'zulässig – in anderen Fällen einer gefährlichen und andere Personen gefährdenden Entwicklung des Risikoprobanden, ungeachtet anderer ' +
                'Unterrichtungspflichten, unverzüglich auch die für den Wohnort des Risikoprobanden zuständige Polizeidienststelle unterrichtet wird. ' +
                'Den am Wohnort zuständigen Polizeidienststellen obliegt die Festlegung und Koordinierung der gefahrenabwehrrechtlichen Maßnahmen ' +
                'bei den Risikoprobanden (Ziffer 5.8.1).' +
            '</p><p>' +
                'Als gefahrenabwehrrechtliche Maßnahmen kommen nach Ziffer 5.8.2 <i>bspw.</i> die Gefährderansprache, Gefährdetenansprache oder ' +
                'schließlich auch die Observation in Betracht.' +
            '</p>',
        bayern: 
        '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. ' +
                'Es <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, wenn das gezeigte Verhalten ' +
                '(Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) ' +
                'Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b> ' +
            '</p><p>' +
                'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, ' +
                'dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, ' +
                'wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist.' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, ' +
                'dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB) um einen Widerruf zu vermeiden.' +
            '</p>'

    },

    'Ich habe jemanden geschlagen.': {

        description:
            '<p>' +
                'Die Checklist of Behavioral Misconduct (CMC) ist ein Selbstbeurteilungsinstrument zur Erfassung allgemein und sexuell devianter ' +
                'Verhaltensweisen und bezieht sich auf einen Zeitraum von 4 Wochen. Jede erhobene Verhaltensweise lässt sich zudem unterscheiden in ' +
                'deliktnahes Verhalten und konkretes Rückfallverhalten. Beim deliktnahen Verhalten handelt es sich um Verhaltensweisen, die als ' +
                'Vorstufe für ein erneutes Sexualdelikt betrachtet werden <i>können</i>. Das Rückfallverhalten wiederum beinhaltet Verhaltensweisen, die ' +
                'einen eindeutigen Rückfall darstellen (z.B. das Beschaffen kinderpornographischer Schriften). Die relevante Subskala erfasst dabei ' +
                'allgemein deviantes Verhalten und orientiert sich an verschiedenen Straftatbeständen des StGB. Das vorliegende Item gibt <i>Hinweise</i> ' +
                'auf die Verwirklichung des objektiven Tatbestandes der Körperverletzung (§ 223 StGB).' +
            '</p>',
        nds:
            '<p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, ' +
                'dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende ' +
                'Maßnahme in Betracht, wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ ' +
                '67h StGB), um einen Widerruf zu vermeiden.' +
            '</p><p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern ' +
                '(KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei ' +
                'verläuft, eine unverzügliche Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). ' +
                'Im Falle von Anzeichen einer ernsthaften Gefährdung Dritter ergeht ebenfalls eine Meldung nachrichtlich an die zuständige Fachberaterin ' +
                'oder den zuständigen Fachberater Risikomanagement. ' +
            '</p><p>' +
                'Des Weiteren sieht die Konzeption bei der möglichen Gefährdung Dritter nach einer Abwägung im Einzelfall eine zusätzliche Information ' +
                'der KURS-Sachbearbeiterinnen oder KURS-Sachbearbeiter vor. Dies <i>kann</i> bspw. unter den Voraussetzungen des § 34 StGB bei einer „gegenwärtigen, ' +
                'nicht anders abwendbaren Gefahr für Leben, Freiheit, Ehre, Eigentum oder ein anderes Rechtsgut“ der Fall sein, wenn das geschützte ' +
                'Interesse die datenschutzrechtliche Beeinträchtigung überwiegt. <i>Hier sind alle gesammelten Erkenntnisse in die Entscheidung miteinzubeziehen</i>.' +
            '</p>',
        bw:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten, Verstöße gegen Weisungen und neue Straftaten ' +
                'unverzüglich der Führungsaufsichtsstelle zu melden sind, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu überprüfen ' +
                'und entsprechend anzupassen. Außerdem wird angeordnet, dass im Falle einer gegenwärtigen Gefahr für Leib oder Leben, Gesundheit, persönliche ' +
                'Freiheit oder die sexuelle Selbstbestimmung einer Person sowie – soweit gesetzlich zulässig – in anderen Fällen einer gefährlichen und andere ' +
                'Personen gefährdenden Entwicklung des Risikoprobanden, ungeachtet anderer Unterrichtungspflichten, unverzüglich auch die für den Wohnort des ' +
                'Risikoprobanden zuständige Polizeidienststelle unterrichtet wird. Den am Wohnort zuständigen Polizeidienststellen obliegt die Festlegung und ' +
                'Koordinierung der gefahrenabwehrrechtlichen Maßnahmen bei den Risikoprobanden (Ziffer 5.8.1).' +
            '</p><p>' +
                'Als gefahrenabwehrrechtliche Maßnahmen kommen nach Ziffer 5.8.2 <i>bspw.</i> die Gefährderansprache, Gefährdetenansprache oder schließlich auch ' +
                'die Observation in Betracht.' +
            '</p>',
        bayern: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. ' +
                'Es <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, wenn das gezeigte Verhalten ' +
                '(Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) ' +
                'Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b> ' +
            '</p><p>' +
                'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, ' +
                'dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, ' +
                'wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist.' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, ' +
                'dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB) um einen Widerruf zu vermeiden.' +
            '</p>'

    },

    'Ich habe jemanden absichtlich so schwer verletzt, dass er/sie zum Arzt musste.': {

        description:
            '<p>' +
                'Die Checklist of Behavioral Misconduct (CMC) ist ein Selbstbeurteilungsinstrument zur Erfassung allgemein und sexuell devianter Verhaltensweisen und ' +
                'bezieht sich auf einen Zeitraum von 4 Wochen. Jede erhobene Verhaltensweise lässt sich zudem unterscheiden in deliktnahes Verhalten und konkretes ' +
                'Rückfallverhalten. Beim deliktnahen Verhalten handelt es sich um Verhaltensweisen, die als Vorstufe für ein erneutes Sexualdelikt betrachtet werden ' +
                '<i>können</i>. Das Rückfallverhalten wiederum beinhaltet Verhaltensweisen, die einen eindeutigen Rückfall darstellen (z.B. das Beschaffen kinderpornographischer ' +
                'Schriften). Die relevante Subskala erfasst dabei allgemein deviantes Verhalten und orientiert sich an verschiedenen Straftatbeständen des StGB. Das ' +
                'vorliegende Item gibt <i>Hinweise</i> auf die Verwirklichung des objektiven Tatbestandes der Körperverletzung (§ 223 StGB).' +
            '</p>',
        nds:
            '<p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, ' +
                'dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende ' +
                'Maßnahme in Betracht, wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ ' +
                '67h StGB), um einen Widerruf zu vermeiden.' +
            '</p><p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern ' +
                '(KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei ' +
                'verläuft, eine unverzügliche Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). ' +
                'Im Falle von Anzeichen einer ernsthaften Gefährdung Dritter ergeht ebenfalls eine Meldung nachrichtlich an die zuständige Fachberaterin ' +
                'oder den zuständigen Fachberater Risikomanagement. ' +
            '</p><p>' +
                'Des Weiteren sieht die Konzeption bei der möglichen Gefährdung Dritter nach einer Abwägung im Einzelfall eine zusätzliche Information ' +
                'der KURS-Sachbearbeiterinnen oder KURS-Sachbearbeiter vor. Dies <i>kann</i> bspw. unter den Voraussetzungen des § 34 StGB bei einer „gegenwärtigen, ' +
                'nicht anders abwendbaren Gefahr für Leben, Freiheit, Ehre, Eigentum oder ein anderes Rechtsgut“ der Fall sein, wenn das geschützte ' +
                'Interesse die datenschutzrechtliche Beeinträchtigung überwiegt. <i>Hier sind alle gesammelten Erkenntnisse in die Entscheidung miteinzubeziehen</i>.' +
            '</p>',
        bw:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten, Verstöße gegen Weisungen und neue Straftaten ' +
                'unverzüglich der Führungsaufsichtsstelle zu melden sind, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu überprüfen ' +
                'und entsprechend anzupassen. Außerdem wird angeordnet, dass im Falle einer gegenwärtigen Gefahr für Leib oder Leben, Gesundheit, persönliche ' +
                'Freiheit oder die sexuelle Selbstbestimmung einer Person sowie – soweit gesetzlich zulässig – in anderen Fällen einer gefährlichen und andere ' +
                'Personen gefährdenden Entwicklung des Risikoprobanden, ungeachtet anderer Unterrichtungspflichten, unverzüglich auch die für den Wohnort des ' +
                'Risikoprobanden zuständige Polizeidienststelle unterrichtet wird. Den am Wohnort zuständigen Polizeidienststellen obliegt die Festlegung und ' +
                'Koordinierung der gefahrenabwehrrechtlichen Maßnahmen bei den Risikoprobanden (Ziffer 5.8.1).' +
            '</p><p>' +
                'Als gefahrenabwehrrechtliche Maßnahmen kommen nach Ziffer 5.8.2 <i>bspw.</i> die Gefährderansprache, Gefährdetenansprache oder schließlich auch ' +
                'die Observation in Betracht.' +
            '</p>',
        bayern: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. ' +
                'Es <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, wenn das gezeigte Verhalten ' +
                '(Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) ' +
                'Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +
                '<b>Wichtig:</b> Gemäß Ziffer 4.3 der Konzeption HEADS ist bei <i>Gefahr im Verzug</i>  unverzüglich die für den Wohnort des*r Proband*in örtlich zuständigen Polizeidienststelle  zu verständigen, ' +
                'die zunächst über die aktuell notwendigen Maßnahmen (Gefahrenabwehr/Strafverfolgung) entscheidet und die Kriminalpolizeidienststelle, das zuständige Polizeipräsidium ' +
                'sowie die Zentralstelle HEADS zu benachrichtigen hat.' +
            '</p><p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b> ' +
            '</p><p>' +
                'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, ' +
                'dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, ' +
                'wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist.' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, ' +
                'dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB) um einen Widerruf zu vermeiden.' +
            '</p>'

    },

    'Ich habe jemanden getötet.': {

        description:
            '<p>' +
                'Die Checklist of Behavioral Misconduct (CMC) ist ein Selbstbeurteilungsinstrument zur Erfassung allgemein und sexuell devianter Verhaltensweisen ' +
                'und bezieht sich auf einen Zeitraum von 4 Wochen. Jede erhobene Verhaltensweise lässt sich zudem unterscheiden in deliktnahes Verhalten und ' +
                'konkretes Rückfallverhalten. Beim deliktnahen Verhalten handelt es sich um Verhaltensweisen, die als Vorstufe für ein erneutes Sexualdelikt ' +
                'betrachtet werden <i>können</i>. Das Rückfallverhalten wiederum beinhaltet Verhaltensweisen, die einen eindeutigen Rückfall darstellen (z.B. das ' +
                'Beschaffen kinderpornographischer Schriften). Die relevante Subskala erfasst dabei allgemein deviantes Verhalten und orientiert sich an ' +
                'verschiedenen Straftatbeständen des StGB. Das vorliegende Item gibt <i>Hinweise</i> auf die Verwirklichung des objektiven Tatbestandes des Mordes ' +
                'oder Totschlags (§§ 211, 212 StGB).' +
            '</p>',
        nds:
            '<p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, ' +
                'dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende ' +
                'Maßnahme in Betracht, wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ ' +
                '67h StGB), um einen Widerruf zu vermeiden.' +
            '</p><p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern ' +
                '(KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei ' +
                'verläuft, eine unverzügliche Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). ' +
                'Im Falle von Anzeichen einer ernsthaften Gefährdung Dritter ergeht ebenfalls eine Meldung nachrichtlich an die zuständige Fachberaterin ' +
                'oder den zuständigen Fachberater Risikomanagement. ' +
            '</p><p>' +
                'Des Weiteren sieht die Konzeption bei der möglichen Gefährdung Dritter nach einer Abwägung im Einzelfall eine zusätzliche Information ' +
                'der KURS-Sachbearbeiterinnen oder KURS-Sachbearbeiter vor. Dies <i>kann</i> bspw. unter den Voraussetzungen des § 34 StGB bei einer „gegenwärtigen, ' +
                'nicht anders abwendbaren Gefahr für Leben, Freiheit, Ehre, Eigentum oder ein anderes Rechtsgut“ der Fall sein, wenn das geschützte ' +
                'Interesse die datenschutzrechtliche Beeinträchtigung überwiegt. <i>Hier sind alle gesammelten Erkenntnisse in die Entscheidung miteinzubeziehen</i>.' +
            '</p>',
        bw:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten, Verstöße gegen Weisungen und neue Straftaten ' +
                'unverzüglich der Führungsaufsichtsstelle zu melden sind, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu überprüfen ' +
                'und entsprechend anzupassen. Außerdem wird angeordnet, dass im Falle einer gegenwärtigen Gefahr für Leib oder Leben, Gesundheit, persönliche ' +
                'Freiheit oder die sexuelle Selbstbestimmung einer Person sowie – soweit gesetzlich zulässig – in anderen Fällen einer gefährlichen und andere ' +
                'Personen gefährdenden Entwicklung des Risikoprobanden, ungeachtet anderer Unterrichtungspflichten, unverzüglich auch die für den Wohnort des ' +
                'Risikoprobanden zuständige Polizeidienststelle unterrichtet wird. Den am Wohnort zuständigen Polizeidienststellen obliegt die Festlegung und ' +
                'Koordinierung der gefahrenabwehrrechtlichen Maßnahmen bei den Risikoprobanden (Ziffer 5.8.1).' +
            '</p><p>' +
                'Als gefahrenabwehrrechtliche Maßnahmen kommen nach Ziffer 5.8.2 <i>bspw.</i> die Gefährderansprache, Gefährdetenansprache oder schließlich auch ' +
                'die Observation in Betracht.' +
            '</p>',
        bayern: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. ' +
                'Es <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, wenn das gezeigte Verhalten ' +
                '(Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) ' +
                'Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +
                '<b>Wichtig:</b> Gemäß Ziffer 4.3 der Konzeption HEADS ist bei <i>Gefahr im Verzug</i>  unverzüglich die für den Wohnort des*r Proband*in örtlich zuständigen Polizeidienststelle  zu verständigen, ' +
                'die zunächst über die aktuell notwendigen Maßnahmen (Gefahrenabwehr/Strafverfolgung) entscheidet und die Kriminalpolizeidienststelle, das zuständige Polizeipräsidium ' +
                'sowie die Zentralstelle HEADS zu benachrichtigen hat.' +
            '</p><p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b> ' +
            '</p><p>' +
                'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, ' +
                'dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, ' +
                'wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist.' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, ' +
                'dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB) um einen Widerruf zu vermeiden.' +
            '</p>'

    },

    'Ich habe ein Kind angesprochen.': {

        description:
            '<p>' +
                '<i>Ggf. hat Ihr*e Klient*in angegeben, dass er* ein Kind angesprochen hat</i>. Dies stellt eine deliktsnahe Verhaltensweise dar, welche als Vorstufe ' +
                'für ein erneutes Sexualdelikt betrachtet werden <i>kann</i>. ' +
            '</p><p>' +
                '<b>Hat Ihr*e Klient*in eine Weisung in Form eines Kontaktverbots (§ 68b Abs. 1 S. 1 Nr. 3 StGB) erhalten?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Verstoß gegen die Weisung es erforderlich machen <i>kann</i>, dass das Gericht die Höchstdauer der Führungsaufsicht überschreitet und ' +
                'eine unbefristete Führungsaufsicht anordnet, wenn sich aus einem Verstoß gegen Weisungen nach § 68b Abs. 1 oder 2 StGB oder auf Grund anderer bestimmter ' +
                'Tatsachen <i>konkrete</i> Anhaltspunkte dafür ergeben, dass eine Gefährdung der Allgemeinheit durch die Begehung weiterer erheblicher Straftaten zu befürchten ' +
                'ist und gegen die verurteilte Person wegen Straftaten der in § 181b StGB genannten Art eine Freiheitsstrafe oder Gesamtfreiheitsstrafe von mehr als zwei ' +
                'Jahren verhängt oder die Unterbringung in einem psychiatrischen Krankenhaus oder einer Entziehungsanstalt angeordnet wurde (§ 68c Abs. 3 S. 1 Nr. 2 StGB). ' +
                'Ein Verstoß gegen die Weisung ist zudem über § 145a StGB strafbewehrt. <i>Möglich</i> ist ebenfalls eine Nachtragsentscheidung (§ 68d Abs. 1 StGB) durch das Gericht. ' +
                'Die Sachlage <i>kann</i> es jederzeit erfordern, neue Anweisungen zu erteilen, frühere Anweisungen aufzuheben oder veränderten Umständen anzupassen.' +
            '</p><p>' +
                'Des Weiteren <i>kann</i> die beschrieben Verhaltensweise insbesondere auch im Zusammenspiel mit anderen Items der Selbstbeschreibungsverfahren bedenklich werden, ' +
                'bspw. wenn Ihr*e Klient*in zusätzlich im Rahmen der Fragebögen davon berichtet, in den letzten Tagen „oft“ das Gefühl gehabt zu haben, eine sexuelle Handlung ' +
                'mit einem Kind begehen zu müssen oder nicht der These zustimmt, dass er*sie bereit sei, Kontakte zu Kindern zu vermeiden oder sein sexuelles Interesse an ' +
                'Kindern nicht verändern möchte. Dies <i>könnte</i> in der Zusammenschau einen Hinweis darauf geben, dass ein Tatplan oder eine Vorbereitungshandlung bereits so ' +
                'weit in das Versuchsstadium gelangt ist, dass eine gegenwärtige Gefahr für Leib, Leben, Gesundheit oder die sexuelle Selbstbestimmung eines Kindes besteht.' +
            '</p>',
        nds:
            '<p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus dem gröblich und beharrlichem Verstoß gegen die Weisung ' +
                'ergibt, dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 2 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p><p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern (KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei verläuft, eine unverzügliche ' +
                'Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). Im Falle von Verstößen gegen Weisungen ergeht ebenfalls ' +
                'eine Meldung nachrichtlich an die zuständige Fachberaterin oder den zuständigen Fachberater Risikomanagement. ' +
            '</p><p>' +
                'Bei möglicher Gefährdung Dritter sieht die Konzeption nach einer Abwägung im Einzelfall eine zusätzliche Information der KURS-Sachbearbeiterinnen oder ' +
                'KURS-Sachbearbeiter vor. Dies <i>kann</i> bspw. unter den Voraussetzungen des § 34 StGB bei einer „gegenwärtigen, nicht anders abwendbaren Gefahr für Leben, Freiheit, ' +
                'Ehre, Eigentum oder ein anderes Rechtsgut“ der Fall sein, wenn das geschützte Interesse die datenschutzrechtliche Beeinträchtigung überwiegt. <i>Hier sind alle ' +
                'gesammelten Erkenntnisse in die Entscheidung miteinzubeziehen</i>.' +
            '</p>',
        bw:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten und Verstöße gegen Weisungen unverzüglich der Führungsaufsichtsstelle ' +
                'gemeldet werden müssen, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu überprüfen und entsprechend anzupassen. Außerdem wird ' +
                'angeordnet, dass der Bewährungshelfende im Falle einer gegenwärtigen Gefahr für Leib oder Leben, Gesundheit, persönliche Freiheit oder die sexuelle Selbstbestimmung ' +
                'einer Person sowie – soweit gesetzlich zulässig – in anderen Fällen einer gefährlichen und andere Personen gefährdenden Entwicklung des Risikoprobanden, ungeachtet ' +
                'anderer Unterrichtungspflichten, unverzüglich auch die für den Wohnort des Risikoprobanden zuständige Polizeidienststelle unterrichtet. Den am Wohnort zuständigen ' +
                'Polizeidienststellen obliegt die Festlegung und Koordinierung der gefahrenabwehrrechtlichen Maßnahmen bei den Risikoprobanden (Ziffer 5.8.1).' +
            '</p><p>' +
                'Als gefahrenabwehrrechtliche Maßnahmen kommen nach Ziffer 5.8.2 <i>bspw.</i> die Gefährderansprache, Gefährdetenansprache, die Feststellung von Verstößen gegen ' +
                'Weisungen (bspw. wenn Ihr*e Klient*in sich entgegen seine*ihrer Weisung an Spielplätzen aufhält) oder schließlich auch die Observation in Betracht.' +
            '</p>',
        bayern: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. ' +
                'Bei einem Verstoß gegen Auflagen und Weisungen ist der HEADS Ansprechpartner*in der Kriminalpolizeidienststelle unverzüglich der Bewährungshilfestelle, dem zuständigen Polizeipräsidium und der Zentralstelle HEADS zur Mitteilung verpflichtet. ' +
                'Sofern es sich um einen Führungsaufsichtsproband*in handelt, setzt die Bewährungshilfe dann in eigener Verantwortlichkeit die Führungsaufsichtsstelle in Kenntnis. (Ziffer 4.5 der Konzeption).' +
            '</p><p>' +
                'Des Weiteren <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, wenn das gezeigte Verhalten (Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) ' +
                'Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +
                '<b>Wichtig:</b> Gemäß Ziffer 4.3 der Konzeption HEADS ist bei <i>Gefahr im Verzug</i>  unverzüglich die für den Wohnort des*r Proband*in örtlich zuständigen Polizeidienststelle  zu verständigen, ' +
                'die zunächst über die aktuell notwendigen Maßnahmen (Gefahrenabwehr/Strafverfolgung) entscheidet und die Kriminalpolizeidienststelle, das zuständige Polizeipräsidium ' +
                'sowie die Zentralstelle HEADS zu benachrichtigen hat.' +
            '</p><p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b> ' +
            '</p><p>' +
                'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, ' +
                'dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, ' +
                'wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist.' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus dem gröblichen und beharrlichen Verstoß gegen die Weisungen ergibt, ' +
                'dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 2 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB) um einen Widerruf zu vermeiden.' +
            '</p>'

    },

    'Ich war an einem Kindergarten oder einer Schule.': {

        description:
            '<p>' +
                '<i>Ggf. hat Ihr*e Klient*in angegeben, dass er*sie sich an einem Kindergarten oder einer Schule aufgehalten hat</i>. Diese stellen deliktsnahe ' +
                'Verhaltensweisen dar, welche als Vorstufe für ein erneutes Sexualdelikt betrachtet werden <i>können</i>. ' +
            '</p><p>' +
                '<b>Hat Ihr*e Klient*in eine Weisung in Form eines Kontaktverbots (§ 68b Abs. 1 S. 1 Nr. 3 StGB) erhalten oder eine Weisung, sich nicht an ' +
                'bestimmten Orten aufzuhalten, die Gelegenheit oder Anreiz für weitere Straftaten bieten könnten (§ 68b Abs. 1 S. 1 Nr. 2 StGB)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Verstoß gegen die Weisungen es erforderlich machen <i>kann</i>, dass das Gericht die Höchstdauer der Führungsaufsicht überschreitet und ' +
                'eine unbefristete Führungsaufsicht anordnet, wenn sich aus einem Verstoß gegen Weisungen nach § 68b Abs. 1 oder 2 StGB oder auf Grund anderer bestimmter ' +
                'Tatsachen <i>konkrete</i> Anhaltspunkte dafür ergeben, dass eine Gefährdung der Allgemeinheit durch die Begehung weiterer erheblicher Straftaten zu befürchten ' +
                'ist und gegen die verurteilte Person wegen Straftaten der in § 181b StGB genannten Art eine Freiheitsstrafe oder Gesamtfreiheitsstrafe von mehr als zwei ' +
                'Jahren verhängt oder die Unterbringung in einem psychiatrischen Krankenhaus oder einer Entziehungsanstalt angeordnet wurde (§ 68c Abs. 3 S. 1 Nr. 2 StGB). ' +
                'Ein Verstoß gegen die Weisung ist zudem über § 145a StGB strafbewehrt. <i>Möglich</i> ist ebenfalls eine Nachtragsentscheidung (§ 68d Abs. 1 StGB) durch das Gericht. ' +
                'Die Sachlage <i>kann</i> es jederzeit erfordern, neue Anweisungen zu erteilen, frühere Anweisungen aufzuheben oder veränderten Umständen anzupassen.' +
            '</p><p>' +
                'Des Weiteren <i>können</i> diese Verhaltensweisen insbesondere auch im Zusammenspiel mit anderen Items der Selbstbeschreibungsverfahren bedenklich werden, ' +
                'bspw. wenn Ihr*e Klient*in zusätzlich im Rahmen der Fragebögen davon berichtet, in den letzten Tagen „oft“ das Gefühl gehabt zu haben, eine sexuelle Handlung ' +
                'mit einem Kind begehen zu müssen, nicht der These zustimmt, dass er*sie bereit sei, Kontakte zu Kindern zu vermeiden oder sein sexuelles Interesse an ' +
                'Kindern nicht verändern möchte oder bereits Kontakt zu einem Kind aufgenommen hat. Dies <i>könnte</i> in der Zusammenschau einen Hinweis darauf geben, dass ' +
                'ein Tatplan oder eine Vorbereitungshandlung bereits so weit in das Versuchsstadium gelangt ist, dass eine gegenwärtige Gefahr für Leib, Leben, Gesundheit ' +
                'oder die sexuelle Selbstbestimmung eines Kindes besteht.' +
            '</p>',
        nds:
            '<p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus dem gröblich und beharrlichem Verstoß gegen die Weisung ' +
                'ergibt, dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 2 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p><p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern (KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei verläuft, eine ' +
                'unverzügliche Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). Im Falle von Verstößen gegen Weisungen ' +
                'ergeht ebenfalls eine Meldung nachrichtlich an die zuständige Fachberaterin oder den zuständigen Fachberater Risikomanagement. ' +
            '</p><p>' +
                'Bei möglicher Gefährdung Dritter sieht die Konzeption nach einer Abwägung im Einzelfall eine zusätzliche Information der KURS-Sachbearbeiterinnen oder ' +
                'KURS-Sachbearbeiter vor. Dies <i>kann</i> bspw. unter den Voraussetzungen des § 34 StGB bei einer „gegenwärtigen, nicht anders abwendbaren Gefahr für Leben, ' +
                'Freiheit, Ehre, Eigentum oder ein anderes Rechtsgut“ der Fall sein, wenn das geschützte Interesse die datenschutzrechtliche Beeinträchtigung überwiegt. ' +
                '<i>Hier sind alle gesammelten Erkenntnisse in die Entscheidung miteinzubeziehen</i>.' +
            '</p>',
        bw:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten und Verstöße gegen Weisungen unverzüglich der Führungsaufsichtsstelle ' +
                'gemeldet werden müssen, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu überprüfen und entsprechend anzupassen. Außerdem wird ' +
                'angeordnet, dass der Bewährungshelfende im Falle einer gegenwärtigen Gefahr für Leib oder Leben, Gesundheit, persönliche Freiheit oder die sexuelle Selbstbestimmung ' +
                'einer Person sowie – soweit gesetzlich zulässig – in anderen Fällen einer gefährlichen und andere Personen gefährdenden Entwicklung des Risikoprobanden, ungeachtet ' +
                'anderer Unterrichtungspflichten, unverzüglich auch die für den Wohnort des Risikoprobanden zuständige Polizeidienststelle unterrichtet. Den am Wohnort zuständigen ' +
                'Polizeidienststellen obliegt die Festlegung und Koordinierung der gefahrenabwehrrechtlichen Maßnahmen bei den Risikoprobanden (Ziffer 5.8.1).' +
            '</p><p>' +
                'Als gefahrenabwehrrechtliche Maßnahmen kommen nach Ziffer 5.8.2 <i>bspw.</i> die Gefährderansprache, Gefährdetenansprache, die Feststellung von Verstößen gegen ' +
                'Weisungen (bspw. wenn Ihr*e Klient*in sich entgegen seine*ihrer Weisung an Spielplätzen aufhält) oder schließlich auch die Observation in Betracht.' +
            '</p>',
        bayern: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. ' +
                'Bei einem Verstoß gegen Auflagen und Weisungen ist der HEADS Ansprechpartner*in der Kriminalpolizeidienststelle unverzüglich der Bewährungshilfestelle, dem zuständigen Polizeipräsidium und der Zentralstelle HEADS zur Mitteilung verpflichtet. ' +
                'Sofern es sich um einen Führungsaufsichtsproband*in handelt, setzt die Bewährungshilfe dann in eigener Verantwortlichkeit die Führungsaufsichtsstelle in Kenntnis. (Ziffer 4.5 der Konzeption).' +
            '</p><p>' +
                'Des Weiteren <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, wenn das gezeigte Verhalten (Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) ' +
                'Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +
                '<b>Wichtig:</b> Gemäß Ziffer 4.3 der Konzeption HEADS ist bei <i>Gefahr im Verzug</i>  unverzüglich die für den Wohnort des*r Proband*in örtlich zuständigen Polizeidienststelle  zu verständigen, ' +
                'die zunächst über die aktuell notwendigen Maßnahmen (Gefahrenabwehr/Strafverfolgung) entscheidet und die Kriminalpolizeidienststelle, das zuständige Polizeipräsidium ' +
                'sowie die Zentralstelle HEADS zu benachrichtigen hat.' +
            '</p><p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b> ' +
            '</p><p>' +
                'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, ' +
                'dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, ' +
                'wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist.' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus dem gröblichen und beharrlichen Verstoß gegen die Weisungen ergibt, ' +
                'dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 2 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB) um einen Widerruf zu vermeiden.' +
            '</p>'

    },

    'Ich war an einem Spielplatz oder im Schwimmbad.': {

        description:
            '<p>' +
                '<i>Ggf. hat Ihr*e Klient*in angegeben, dass er*sie sich an einem Spielplatz oder im Schwimmbad aufgehalten hat</i>. Diese stellen deliktsnahe Verhaltensweisen ' +
                'dar, welche als Vorstufe für ein erneutes Sexualdelikt betrachtet werden <i>können</i>. ' +
            '</p><p>' +
                '<b>Hat Ihr*e Klient*in eine Weisung in Form eines Kontaktverbots (§ 68b Abs. 1 S. 1 Nr. 3 StGB) erhalten oder eine Weisung, sich nicht an bestimmten Orten ' +
                'aufzuhalten, die Gelegenheit oder Anreiz für weitere Straftaten bieten könnten (§ 68b Abs. 1 S. 1 Nr. 2 StGB)? </b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Verstoß gegen die Weisungen es erforderlich machen <i>können</i>, dass das Gericht die Höchstdauer der Führungsaufsicht überschreitet und ' +
                'eine unbefristete Führungsaufsicht anordnet, wenn sich aus einem Verstoß gegen Weisungen nach § 68b Abs. 1 oder 2 StGB oder auf Grund anderer bestimmter ' +
                'Tatsachen <i>konkrete</i> Anhaltspunkte dafür ergeben, dass eine Gefährdung der Allgemeinheit durch die Begehung weiterer erheblicher Straftaten zu befürchten ' +
                'ist und gegen die verurteilte Person wegen Straftaten der in § 181b StGB genannten Art eine Freiheitsstrafe oder Gesamtfreiheitsstrafe von mehr als zwei ' +
                'Jahren verhängt oder die Unterbringung in einem psychiatrischen Krankenhaus oder einer Entziehungsanstalt angeordnet wurde (§ 68c Abs. 3 S. 1 Nr. 2 StGB). ' +
                'Ein Verstoß gegen die Weisung ist zudem über § 145a StGB strafbewehrt. <i>Möglich</i> ist ebenfalls eine Nachtragsentscheidung (§ 68d Abs. 1 StGB) durch das Gericht. ' +
                'Die Sachlage <i>kann</i> es jederzeit erfordern, neue Anweisungen zu erteilen, frühere Anweisungen aufzuheben oder veränderten Umständen anzupassen.' +
            '</p><p>' +
                'Des Weiteren <i>können</i> diese Verhaltensweisen insbesondere auch im Zusammenspiel mit anderen Items der Selbstbeschreibungsverfahren bedenklich werden, ' +
                'bspw. wenn Ihr*e Klient*in zusätzlich im Rahmen der Fragebögen davon berichtet, in den letzten Tagen „oft“ das Gefühl gehabt zu haben, eine sexuelle Handlung ' +
                'mit einem Kind begehen zu müssen, nicht der These zustimmt, dass er*sie bereit sei, Kontakte zu Kindern zu vermeiden oder sein sexuelles Interesse an Kindern nicht verändern ' +
                'möchte oder bereits Kontakt zu einem Kind aufgenommen hat. Dies <i>könnte</i> in der Zusammenschau einen Hinweis darauf geben, dass ein Tatplan oder eine Vorbereitungshandlung bereits so ' +
                'weit in das Versuchsstadium gelangt ist, dass eine gegenwärtige Gefahr für Leib, Leben, Gesundheit oder die sexuelle Selbstbestimmung eines Kindes besteht.' +
            '</p>',
        nds:
            '<p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus dem gröblich und beharrlichem Verstoß gegen die Weisung ' +
                'ergibt, dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 2 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p><p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern (KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei verläuft, eine unverzügliche ' +
                'Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). Im Falle von Verstößen gegen Weisungen ergeht ebenfalls ' +
                'eine Meldung nachrichtlich an die zuständige Fachberaterin oder den zuständigen Fachberater Risikomanagement. ' +
            '</p><p>' +
                'Bei möglicher Gefährdung Dritter sieht die Konzeption nach einer Abwägung im Einzelfall eine zusätzliche Information der KURS-Sachbearbeiterinnen oder ' +
                'KURS-Sachbearbeiter vor. Dies <i>kann</i> bspw. unter den Voraussetzungen des § 34 StGB bei einer „gegenwärtigen, nicht anders abwendbaren Gefahr für Leben, Freiheit, ' +
                'Ehre, Eigentum oder ein anderes Rechtsgut“ der Fall sein, wenn das geschützte Interesse die datenschutzrechtliche Beeinträchtigung überwiegt. <i>Hier sind alle ' +
                'gesammelten Erkenntnisse in die Entscheidung miteinzubeziehen</i>.' +
            '</p>',
        bw:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten und Verstöße gegen Weisungen unverzüglich der Führungsaufsichtsstelle ' +
                'gemeldet werden müssen, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu überprüfen und entsprechend anzupassen. Außerdem wird ' +
                'angeordnet, dass der Bewährungshelfende im Falle einer gegenwärtigen Gefahr für Leib oder Leben, Gesundheit, persönliche Freiheit oder die sexuelle Selbstbestimmung ' +
                'einer Person sowie – soweit gesetzlich zulässig – in anderen Fällen einer gefährlichen und andere Personen gefährdenden Entwicklung des Risikoprobanden, ungeachtet ' +
                'anderer Unterrichtungspflichten, unverzüglich auch die für den Wohnort des Risikoprobanden zuständige Polizeidienststelle unterrichtet. Den am Wohnort zuständigen ' +
                'Polizeidienststellen obliegt die Festlegung und Koordinierung der gefahrenabwehrrechtlichen Maßnahmen bei den Risikoprobanden (Ziffer 5.8.1).' +
            '</p><p>' +
                'Als gefahrenabwehrrechtliche Maßnahmen kommen nach Ziffer 5.8.2 <i>bspw.</i> die Gefährderansprache, Gefährdetenansprache, die Feststellung von Verstößen gegen ' +
                'Weisungen (bspw. wenn Ihr*e Klient*in sich entgegen seine*ihrer Weisung an Spielplätzen aufhält) oder schließlich auch die Observation in Betracht.' +
            '</p>',
        bayern: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. ' +
                'Bei einem Verstoß gegen Auflagen und Weisungen ist der HEADS Ansprechpartner*in der Kriminalpolizeidienststelle unverzüglich der Bewährungshilfestelle, dem zuständigen Polizeipräsidium und der Zentralstelle HEADS zur Mitteilung verpflichtet. ' +
                'Sofern es sich um einen Führungsaufsichtsproband*in handelt, setzt die Bewährungshilfe dann in eigener Verantwortlichkeit die Führungsaufsichtsstelle in Kenntnis. (Ziffer 4.5 der Konzeption).' +
            '</p><p>' +
                'Des Weiteren <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, wenn das gezeigte Verhalten (Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) ' +
                'Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +
                '<b>Wichtig:</b> Gemäß Ziffer 4.3 der Konzeption HEADS ist bei <i>Gefahr im Verzug</i>  unverzüglich die für den Wohnort des*r Proband*in örtlich zuständigen Polizeidienststelle  zu verständigen, ' +
                'die zunächst über die aktuell notwendigen Maßnahmen (Gefahrenabwehr/Strafverfolgung) entscheidet und die Kriminalpolizeidienststelle, das zuständige Polizeipräsidium ' +
                'sowie die Zentralstelle HEADS zu benachrichtigen hat.' +
            '</p><p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b> ' +
            '</p><p>' +
                'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, ' +
                'dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, ' +
                'wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist.' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus dem gröblichen und beharrlichen Verstoß gegen die Weisungen ergibt, ' +
                'dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 2 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB) um einen Widerruf zu vermeiden.' +
            '</p>'


    },

    'Ich habe ein Kind zu mir nach Hause eingeladen.': {

        description:
            '<p>' +
                '<i>Ggf. hat Ihr*e Klient*in angegeben, dass er* ein Kind zu sich nach Hause eingeladen hat</i>. Dies stellt eine deliktsnahe Verhaltensweise dar, welche als Vorstufe ' +
                'für ein erneutes Sexualdelikt betrachtet werden <i>kann</i>. ' +
            '</p><p>' +
                '<b>Hat Ihr*e Klient*in eine Weisung in Form eines Kontaktverbots (§ 68b Abs. 1 S. 1 Nr. 3 StGB) erhalten?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Verstoß gegen die Weisung es erforderlich machen <i>kann</i>, dass das Gericht die Höchstdauer der Führungsaufsicht überschreitet und ' +
                'eine unbefristete Führungsaufsicht anordnet, wenn sich aus einem Verstoß gegen Weisungen nach § 68b Abs. 1 oder 2 StGB oder auf Grund anderer bestimmter ' +
                'Tatsachen <i>konkrete</i> Anhaltspunkte dafür ergeben, dass eine Gefährdung der Allgemeinheit durch die Begehung weiterer erheblicher Straftaten zu befürchten ' +
                'ist und gegen die verurteilte Person wegen Straftaten der in § 181b StGB genannten Art eine Freiheitsstrafe oder Gesamtfreiheitsstrafe von mehr als zwei ' +
                'Jahren verhängt oder die Unterbringung in einem psychiatrischen Krankenhaus oder einer Entziehungsanstalt angeordnet wurde (§ 68c Abs. 3 S. 1 Nr. 2 StGB). ' +
                'Ein Verstoß gegen die Weisung ist zudem über § 145a StGB strafbewehrt. <i>Möglich</i> ist ebenfalls eine Nachtragsentscheidung (§ 68d Abs. 1 StGB) durch das Gericht. ' +
                'Die Sachlage <i>kann</i> es jederzeit erfordern, neue Anweisungen zu erteilen, frühere Anweisungen aufzuheben oder veränderten Umständen anzupassen.' +
            '</p><p>' +
                'Des Weiteren <i>kann</i> die beschrieben Verhaltensweise insbesondere auch im Zusammenspiel mit anderen Items der Selbstbeschreibungsverfahren bedenklich werden, ' +
                'bspw. wenn Ihr*e Klient*in zusätzlich im Rahmen der Fragebögen davon berichtet, in den letzten Tagen „oft“ das Gefühl gehabt zu haben, eine sexuelle Handlung ' +
                'mit einem Kind begehen zu müssen oder nicht der These zustimmt, dass er*sie bereit sei, Kontakte zu Kindern zu vermeiden oder sein sexuelles Interesse an ' +
                'Kindern nicht verändern möchte. Dies <i>könnte</i> in der Zusammenschau einen Hinweis darauf geben, dass ein Tatplan oder eine Vorbereitungshandlung bereits so ' +
                'weit in das Versuchsstadium gelangt ist, dass eine gegenwärtige Gefahr für Leib, Leben, Gesundheit oder die sexuelle Selbstbestimmung eines Kindes besteht.' +
            '</p>',
        nds:
            '<p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus dem gröblich und beharrlichem Verstoß gegen die Weisung ' +
                'ergibt, dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 2 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p><p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern (KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei verläuft, eine unverzügliche ' +
                'Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). Im Falle von Verstößen gegen Weisungen ergeht ebenfalls ' +
                'eine Meldung nachrichtlich an die zuständige Fachberaterin oder den zuständigen Fachberater Risikomanagement. ' +
            '</p><p>' +
                'Bei möglicher Gefährdung Dritter sieht die Konzeption nach einer Abwägung im Einzelfall eine zusätzliche Information der KURS-Sachbearbeiterinnen oder ' +
                'KURS-Sachbearbeiter vor. Dies <i>kann</i> bspw. unter den Voraussetzungen des § 34 StGB bei einer „gegenwärtigen, nicht anders abwendbaren Gefahr für Leben, Freiheit, ' +
                'Ehre, Eigentum oder ein anderes Rechtsgut“ der Fall sein, wenn das geschützte Interesse die datenschutzrechtliche Beeinträchtigung überwiegt. <i>Hier sind alle ' +
                'gesammelten Erkenntnisse in die Entscheidung miteinzubeziehen</i>.' +
            '</p>',
        bw:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten und Verstöße gegen Weisungen unverzüglich der Führungsaufsichtsstelle ' +
                'gemeldet werden müssen, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu überprüfen und entsprechend anzupassen. Außerdem wird ' +
                'angeordnet, dass der Bewährungshelfende im Falle einer gegenwärtigen Gefahr für Leib oder Leben, Gesundheit, persönliche Freiheit oder die sexuelle Selbstbestimmung ' +
                'einer Person sowie – soweit gesetzlich zulässig – in anderen Fällen einer gefährlichen und andere Personen gefährdenden Entwicklung des Risikoprobanden, ungeachtet ' +
                'anderer Unterrichtungspflichten, unverzüglich auch die für den Wohnort des Risikoprobanden zuständige Polizeidienststelle unterrichtet. Den am Wohnort zuständigen ' +
                'Polizeidienststellen obliegt die Festlegung und Koordinierung der gefahrenabwehrrechtlichen Maßnahmen bei den Risikoprobanden (Ziffer 5.8.1).' +
            '</p><p>' +
                'Als gefahrenabwehrrechtliche Maßnahmen kommen nach Ziffer 5.8.2 <i>bspw.</i> die Gefährderansprache, Gefährdetenansprache, die Feststellung von Verstößen gegen ' +
                'Weisungen (bspw. wenn Ihr*e Klient*in sich entgegen seine*ihrer Weisung an Spielplätzen aufhält) oder schließlich auch die Observation in Betracht.' +
            '</p>',
        bayern: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. ' +
                'Bei einem Verstoß gegen Auflagen und Weisungen ist der HEADS Ansprechpartner*in der Kriminalpolizeidienststelle unverzüglich der Bewährungshilfestelle, dem zuständigen Polizeipräsidium und der Zentralstelle HEADS zur Mitteilung verpflichtet. ' +
                'Sofern es sich um einen Führungsaufsichtsproband*in handelt, setzt die Bewährungshilfe dann in eigener Verantwortlichkeit die Führungsaufsichtsstelle in Kenntnis. (Ziffer 4.5 der Konzeption).' +
            '</p><p>' +
                'Des Weiteren <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, wenn das gezeigte Verhalten (Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) ' +
                'Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +
                '<b>Wichtig:</b> Gemäß Ziffer 4.3 der Konzeption HEADS ist bei <i>Gefahr im Verzug</i>  unverzüglich die für den Wohnort des*r Proband*in örtlich zuständigen Polizeidienststelle  zu verständigen, ' +
                'die zunächst über die aktuell notwendigen Maßnahmen (Gefahrenabwehr/Strafverfolgung) entscheidet und die Kriminalpolizeidienststelle, das zuständige Polizeipräsidium ' +
                'sowie die Zentralstelle HEADS zu benachrichtigen hat.' +
            '</p><p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b> ' +
            '</p><p>' +
                'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, ' +
                'dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, ' +
                'wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist.' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus dem gröblichen und beharrlichen Verstoß gegen die Weisungen ergibt, ' +
                'dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 2 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB) um einen Widerruf zu vermeiden.' +
            '</p>'

    },

    'Ich habe ein Kind im Internet kontaktiert.': {

        description:
            '<p>' +
                'Der sexuelle Missbrauch von Kindern ist gem. § 176 ff. StGB strafbar. Schutzgut der §§ 176 ff. StGB ist die kindliche Gesamtentwicklung und die freie Entwicklung ' +
                'sexueller Selbstbestimmungsfähigkeit. Es wird eine absolute Grenze für den sexualbezogenen Umgang mit Kindern bestimmt, indem solche Kontakte ausnahmslos verboten ' +
                'sind. ' +
            '</p><p>' +
                'Zur Bestimmung des aktuellen Rückfallrisikos wurde im Rahmen von @myTabu u.a. das Selbstbeurteilungsinstrument der Checklist of Behavioral Misconduct (CMC) ' +
                'eingesetzt. Es soll überprüfen, ob die Online-Intervention eine Reduktion der devianten Verhaltensweisen herbeiführen konnte. Dabei werden in den einzelnen ' +
                'Items zum sexuell devianten Verhalten Szenarien abgefragt, die eine Straftat i.S.d. §§ 176 ff., 184b StGB darstellen oder darstellen <i>könnten</i>, wenn der*die Klient*in ' +
                'mit entsprechender Intention gehandelt hat. ' +
            '</p><p>' +
                'Das vorliegende Item erfordert eine Spezifizierung, um (noch) strafloses Verhalten von strafbarem Verhalten abzugrenzen. Handelte der*die Klient*in beim sog. ' +
                'Cybergrooming (§ 176 Abs. 4 Nr. 3 StGB) in der Absicht, das Kind zu sexuellen Handlungen an oder vor dem Klienten oder einem Dritten oder zur Duldung sexueller ' +
                'Handlungen zu bringen? Das Ziel von Handlungen des Kindes an sich selbst reicht hierzu nicht aus. Der Versuch ist seit dem 13. März 2020 ebenfalls strafbar ' +
                '(§ 176 Abs. 6 StGB). Hierzu reicht es auch aus, dass der Täter irrig annimmt, sein Einwirken beziehe sich auf ein Kind. ' +
            '</p><p>' +
                '<i>Da die Strafbarkeit im Einzelfall von vielen Feinheiten abhängt, bedarf die vermeintliche Tathandlung einer weiteren Abklärung.</i>' +
            '</p><p>' +
                'Bitte beachten Sie, dass bei dem von Ihrem*Ihrer Klient*in berichteten sexuell devianten Verhalten auch ein Kind als Opfer betroffen sein <i>könnte</i>, dass mit ' +
                'Ihrem*Ihrer Klient*in in enger Verbindung steht und damit möglicherweise bei einer Wiederholungstat gefährdet sein <i>könnte</i>. In solchen Fällen <i>können</i> Sie angehalten ' +
                'sein, Ihre Schweigepflicht (§ 203 StGB) zu brechen, wenn eine gegenwärtige, nicht anders abwendbar Gefahr für Leben, Leib, Freiheit, Ehre, Eigentum oder ein anderes ' +
                'Rechtsgut für das Kind besteht. Wichtig ist hier eine Abwägung der widerstreitenden Interessen der betroffenen Rechtsgüter und des Grades der ihr drohenden Gefahren. ' +
                'Das geschützte Interesse muss das beeinträchtigte wesentlich überwiegen. Es ist eine objektive Prognose aus der Sicht eines nachträglichen Beobachters vorzunehmen, ' +
                'dem im kritischen Augenblick die wesentlichen Umstände bekannt sind. Der Eintritt der konkreten Gefahr für das Rechtsgut des Kindes ist wahrscheinlich, wenn die ' +
                'Möglichkeit des Eintritts eines Schadens nahe liegt oder eine begründete Besorgnis besteht.' +
            '</p>',
        nds:
            '<p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, dass ' +
                'der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p><p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern (KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei verläuft, eine unverzügliche ' +
                'Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). Im Falle von Anzeichen einer ernsthaften Gefährdung Dritter ergeht ebenfalls ' +
                'eine Meldung nachrichtlich an die zuständige Fachberaterin oder den zuständigen Fachberater Risikomanagement. ' +
            '</p><p>' +
                'In Fällen der möglichen Gefährdung Dritter sieht die Konzeption nach einer Abwägung im Einzelfall eine zusätzliche Information der KURS-Sachbearbeiterinnen oder ' +
                'KURS-Sachbearbeiter vor. Dies <i>kann</i> bspw. unter den Voraussetzungen des § 34 StGB bei einer „gegenwärtigen, nicht anders abwendbaren Gefahr für Leben, Freiheit, ' +
                'Ehre, Eigentum oder ein anderes Rechtsgut“ der Fall sein, wenn das geschützte Interesse die datenschutzrechtliche Beeinträchtigung überwiegt. <i>Hier sind alle ' +
                'gesammelten Erkenntnisse in die Entscheidung miteinzubeziehen</i>.' +
            '</p>',
        bw:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten, Verstöße gegen Weisungen und neue Straftaten ' +
                'unverzüglich der Führungsaufsichtsstelle zu melden sind, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu ' +
                'überprüfen und entsprechend anzupassen. Außerdem wird angeordnet, dass im Falle einer gegenwärtigen Gefahr für Leib oder Leben, Gesundheit, ' +
                'persönliche Freiheit oder die sexuelle Selbstbestimmung einer Person sowie – soweit gesetzlich zulässig – in anderen Fällen einer gefährlichen ' +
                'und andere Personen gefährdenden Entwicklung des Risikoprobanden, ungeachtet anderer Unterrichtungspflichten, unverzüglich auch die für den ' +
                'Wohnort des Risikoprobanden zuständige Polizeidienststelle unterrichtet wird. Den am Wohnort zuständigen Polizeidienststellen obliegt die ' +
                'Festlegung und Koordinierung der gefahrenabwehrrechtlichen Maßnahmen bei den Risikoprobanden (Ziffer 5.8.1).' +
            '</p><p>' +
                'Als gefahrenabwehrrechtliche Maßnahmen kommen nach Ziffer 5.8.2 <i>bspw.</i> die Gefährderansprache, Gefährdetenansprache oder schließlich auch die ' +
                'Observation in Betracht.' +
            '</p>',
        bayern:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. ' +
                'Bei einem Verstoß gegen Auflagen und Weisungen ist der HEADS Ansprechpartner*in der Kriminalpolizeidienststelle unverzüglich der Bewährungshilfestelle, dem zuständigen Polizeipräsidium und der Zentralstelle HEADS zur Mitteilung verpflichtet. ' +
                'Sofern es sich um einen Führungsaufsichtsproband*in handelt, setzt die Bewährungshilfe dann in eigener Verantwortlichkeit die Führungsaufsichtsstelle in Kenntnis. (Ziffer 4.5 der Konzeption).' +
            '</p><p>' +
                'Des Weiteren <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, wenn das gezeigte Verhalten (Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) ' +
                'Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +
                '<b>Wichtig:</b> Gemäß Ziffer 4.3 der Konzeption HEADS ist bei <i>Gefahr im Verzug</i>  unverzüglich die für den Wohnort des*r Proband*in örtlich zuständigen Polizeidienststelle  zu verständigen, ' +
                'die zunächst über die aktuell notwendigen Maßnahmen (Gefahrenabwehr/Strafverfolgung) entscheidet und die Kriminalpolizeidienststelle, das zuständige Polizeipräsidium ' +
                'sowie die Zentralstelle HEADS zu benachrichtigen hat.' +
            '</p><p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b> ' +
            '</p><p>' +
                'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, ' +
                'dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, ' +
                'wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist.' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus dem gröblichen und beharrlichen Verstoß gegen die Weisungen ergibt, ' +
                'dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 2 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB) um einen Widerruf zu vermeiden.' +
            '</p>'

    },

    'Ich hatte Geschlechtsverkehr mit einem Kind oder habe es versucht.': {

        description:
            '</p><p>' +
                'Der sexuelle Missbrauch von Kindern ist gem. § 176 ff. StGB strafbar. Schutzgut der §§ 176 ff. StGB ist die kindliche Gesamtentwicklung und die freie Entwicklung ' +
                'sexueller Selbstbestimmungsfähigkeit. Es wird eine absolute Grenze für den sexualbezogenen Umgang mit Kindern bestimmt, indem solche Kontakte ausnahmslos verboten ' +
                'sind. Bei dem ausgewählten Item handelt es sich um einen schweren sexuellen Missbrauch von Kindern (§ 176a Abs. 2 Nr. 1 StGB). ' +
            '</p><p>' +
                'Bitte beachten Sie, dass bei dem von Ihrem*Ihrer Klient*in berichteten sexuell devianten Verhalten auch ein Kind als Opfer betroffen sein <i>könnte</i>, dass mit ' +
                'Ihrem*Ihrer Klient*in in einem Haushalt lebt oder in enger Verbindung steht und damit möglicherweise bei einer Wiederholungstat gefährdet sein <i>könnte</i>. In solchen Fällen <i>können</i> Sie angehalten ' +
                'sein, Ihre Schweigepflicht (§ 203 StGB) zu brechen, wenn eine gegenwärtige, nicht anders abwendbar Gefahr für Leben, Leib, Freiheit, Ehre, Eigentum oder ein anderes ' +
                'Rechtsgut für das Kind besteht. Wichtig ist hier eine Abwägung der widerstreitenden Interessen der betroffenen Rechtsgüter und des Grades der ihr drohenden Gefahren. ' +
                'Das geschützte Interesse muss das beeinträchtigte wesentlich überwiegen. Es ist eine objektive Prognose aus der Sicht eines nachträglichen Beobachters vorzunehmen, ' +
                'dem im kritischen Augenblick die wesentlichen Umstände bekannt sind. Der Eintritt der konkreten Gefahr für das Rechtsgut des Kindes ist wahrscheinlich, wenn die ' +
                'Möglichkeit des Eintritts eines Schadens nahe liegt oder eine begründete Besorgnis besteht.' +
            '</p>',
        nds: 
            '<p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, dass ' +
                'der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p><p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern (KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei verläuft, eine unverzügliche ' +
                'Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). Im Falle von Anzeichen einer ernsthaften Gefährdung Dritter ergeht ebenfalls ' +
                'eine Meldung nachrichtlich an die zuständige Fachberaterin oder den zuständigen Fachberater Risikomanagement. ' +
            '</p><p>' +
                'In Fällen der möglichen Gefährdung Dritter sieht die Konzeption nach einer Abwägung im Einzelfall eine zusätzliche Information der KURS-Sachbearbeiterinnen oder ' +
                'KURS-Sachbearbeiter vor. Dies <i>kann</i> bspw. unter den Voraussetzungen des § 34 StGB bei einer „gegenwärtigen, nicht anders abwendbaren Gefahr für Leben, Freiheit, ' +
                'Ehre, Eigentum oder ein anderes Rechtsgut“ der Fall sein, wenn das geschützte Interesse die datenschutzrechtliche Beeinträchtigung überwiegt. <i>Hier sind alle ' +
                'gesammelten Erkenntnisse in die Entscheidung miteinzubeziehen</i>.' +
            '</p>',
        bw: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten, Verstöße gegen Weisungen und neue Straftaten unverzüglich der ' +
                'Führungsaufsichtsstelle zu melden sind, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu überprüfen und entsprechend anzupassen. ' +
                'Außerdem wird angeordnet, dass im Falle einer gegenwärtigen Gefahr für Leib oder Leben, Gesundheit, persönliche Freiheit oder die sexuelle Selbstbestimmung einer ' +
                'Person sowie – soweit gesetzlich zulässig – in anderen Fällen einer gefährlichen und andere Personen gefährdenden Entwicklung des Risikoprobanden, ungeachtet anderer ' +
                'Unterrichtungspflichten, unverzüglich auch die für den Wohnort des Risikoprobanden zuständige Polizeidienststelle unterrichtet wird. Den am Wohnort zuständigen ' +
                'Polizeidienststellen obliegt die Festlegung und Koordinierung der gefahrenabwehrrechtlichen Maßnahmen bei den Risikoprobanden (Ziffer 5.8.1).' +
            '</p><p>' +
                'Als gefahrenabwehrrechtliche Maßnahmen kommen nach Ziffer 5.8.2 <i>bspw</i>. die Gefährderansprache, Gefährdetenansprache oder schließlich auch die Observation in Betracht.' +
            '</p>',
        bayern: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. Es <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, ' +
                'wenn das gezeigte Verhalten (Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +
                '<b>Wichtig:</b> Gemäß Ziffer 4.3 der Konzeption HEADS ist bei <i>Gefahr im Verzug</i>  unverzüglich die für den Wohnort des*r Proband*in örtlich zuständigen Polizeidienststelle  zu verständigen, ' +
                'die zunächst über die aktuell notwendigen Maßnahmen (Gefahrenabwehr/Strafverfolgung) entscheidet und die Kriminalpolizeidienststelle, das zuständige Polizeipräsidium ' +
                'sowie die Zentralstelle HEADS zu benachrichtigen hat.' +
            '</p><p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b> ' +
            '</p><p>' +
                'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, ' +
                'dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, ' +
                'wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist.' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). ' +
                '<i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p>'

    },

    'Ich hatte Oralverkehr mit einem Kind.': {

        description:
            '</p><p>' +
                'Der sexuelle Missbrauch von Kindern ist gem. § 176 ff. StGB strafbar. Schutzgut der §§ 176 ff. StGB ist die kindliche Gesamtentwicklung und die freie Entwicklung ' +
                'sexueller Selbstbestimmungsfähigkeit. Es wird eine absolute Grenze für den sexualbezogenen Umgang mit Kindern bestimmt, indem solche Kontakte ausnahmslos verboten ' +
                'sind. Bei dem ausgewählten Item handelt es sich um einen schweren sexuellen Missbrauch von Kindern (§ 176a Abs. 2 Nr. 1 StGB). ' +
            '</p><p>' +
                'Bitte beachten Sie, dass bei dem von Ihrem*Ihrer Klient*in berichteten sexuell devianten Verhalten auch ein Kind als Opfer betroffen sein <i>könnte</i>, dass mit ' +
                'Ihrem*Ihrer Klient*in in einem Haushalt lebt oder in enger Verbindung steht und damit möglicherweise bei einer Wiederholungstat gefährdet sein <i>könnte</i>. In solchen Fällen <i>können</i> Sie angehalten ' +
                'sein, Ihre Schweigepflicht (§ 203 StGB) zu brechen, wenn eine gegenwärtige, nicht anders abwendbar Gefahr für Leben, Leib, Freiheit, Ehre, Eigentum oder ein anderes ' +
                'Rechtsgut für das Kind besteht. Wichtig ist hier eine Abwägung der widerstreitenden Interessen der betroffenen Rechtsgüter und des Grades der ihr drohenden Gefahren. ' +
                'Das geschützte Interesse muss das beeinträchtigte wesentlich überwiegen. Es ist eine objektive Prognose aus der Sicht eines nachträglichen Beobachters vorzunehmen, ' +
                'dem im kritischen Augenblick die wesentlichen Umstände bekannt sind. Der Eintritt der konkreten Gefahr für das Rechtsgut des Kindes ist wahrscheinlich, wenn die ' +
                'Möglichkeit des Eintritts eines Schadens nahe liegt oder eine begründete Besorgnis besteht.' +
            '</p>',
        nds: 
            '<p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, dass ' +
                'der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p><p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern (KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei verläuft, eine unverzügliche ' +
                'Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). Im Falle von Anzeichen einer ernsthaften Gefährdung Dritter ergeht ebenfalls ' +
                'eine Meldung nachrichtlich an die zuständige Fachberaterin oder den zuständigen Fachberater Risikomanagement. ' +
            '</p><p>' +
                'In Fällen der möglichen Gefährdung Dritter sieht die Konzeption nach einer Abwägung im Einzelfall eine zusätzliche Information der KURS-Sachbearbeiterinnen oder ' +
                'KURS-Sachbearbeiter vor. Dies <i>kann</i> bspw. unter den Voraussetzungen des § 34 StGB bei einer „gegenwärtigen, nicht anders abwendbaren Gefahr für Leben, Freiheit, ' +
                'Ehre, Eigentum oder ein anderes Rechtsgut“ der Fall sein, wenn das geschützte Interesse die datenschutzrechtliche Beeinträchtigung überwiegt. <i>Hier sind alle ' +
                'gesammelten Erkenntnisse in die Entscheidung miteinzubeziehen</i>.' +
            '</p>',
        bw: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten, Verstöße gegen Weisungen und neue Straftaten unverzüglich der ' +
                'Führungsaufsichtsstelle zu melden sind, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu überprüfen und entsprechend anzupassen. ' +
                'Außerdem wird angeordnet, dass im Falle einer gegenwärtigen Gefahr für Leib oder Leben, Gesundheit, persönliche Freiheit oder die sexuelle Selbstbestimmung einer ' +
                'Person sowie – soweit gesetzlich zulässig – in anderen Fällen einer gefährlichen und andere Personen gefährdenden Entwicklung des Risikoprobanden, ungeachtet anderer ' +
                'Unterrichtungspflichten, unverzüglich auch die für den Wohnort des Risikoprobanden zuständige Polizeidienststelle unterrichtet wird. Den am Wohnort zuständigen ' +
                'Polizeidienststellen obliegt die Festlegung und Koordinierung der gefahrenabwehrrechtlichen Maßnahmen bei den Risikoprobanden (Ziffer 5.8.1).' +
            '</p><p>' +
                'Als gefahrenabwehrrechtliche Maßnahmen kommen nach Ziffer 5.8.2 <i>bspw</i>. die Gefährderansprache, Gefährdetenansprache oder schließlich auch die Observation in Betracht.' +
            '</p>',
        bayern: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. Es <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, ' +
                'wenn das gezeigte Verhalten (Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +
                '<b>Wichtig:</b> Gemäß Ziffer 4.3 der Konzeption HEADS ist bei <i>Gefahr im Verzug</i>  unverzüglich die für den Wohnort des*r Proband*in örtlich zuständigen Polizeidienststelle  zu verständigen, ' +
                'die zunächst über die aktuell notwendigen Maßnahmen (Gefahrenabwehr/Strafverfolgung) entscheidet und die Kriminalpolizeidienststelle, das zuständige Polizeipräsidium ' +
                'sowie die Zentralstelle HEADS zu benachrichtigen hat.' +
            '</p><p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b> ' +
            '</p><p>' +
                'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, ' +
                'dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, ' +
                'wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist.' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). ' +
                '<i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p>'

    },

    'Ich habe das Geschlechtsteil eines Kindes angefasst.': {

        description:
            '</p><p>' +
                'Der sexuelle Missbrauch von Kindern ist gem. § 176 ff. StGB strafbar. Schutzgut der §§ 176 ff. StGB ist die kindliche Gesamtentwicklung und die freie Entwicklung ' +
                'sexueller Selbstbestimmungsfähigkeit. Es wird eine absolute Grenze für den sexualbezogenen Umgang mit Kindern bestimmt, indem solche Kontakte ausnahmslos verboten ' +
                'sind. Wird das Kind besonders gefährdet oder handelt es sich um eine Wiederholungstat (§ 176a Abs. 1 StGB), stellt die Tat ggf. ein Verbrechen (§ 12 Abs. 1 StGB) dar.' +
            '</p><p>' +
                'Zur Bestimmung des aktuellen Rückfallrisikos wurde im Rahmen von @myTabu u.a. das Selbstbeurteilungsinstrument der Checklist of Behavioral Misconduct (CMC) eingesetzt. ' +
                'Es soll überprüfen, ob die Online-Intervention eine Reduktion der devianten Verhaltensweisen herbeiführen konnte. Dabei werden in den einzelnen Items zum sexuell ' +
                'devianten Verhalten Szenarien abgefragt, die eine Straftat i.S.d. §§ 176 ff., 184b StGB darstellen oder darstellen <i>könnten</i>, wenn der*die Klient*in mit entsprechender ' +
                'Intention gehandelt hat. Es kommt im Umgang mit Kindern zu zahlreichen körperlichen Kontakten, deren sexuelle Natur und Erheblichkeit nur im Einzelfall beurteilt werden ' +
                'kann. Handelte es sich dabei objektiv um eine sexualbezogene Handlung? Neutrale Handlungen, denen nur der*die Klient*in sexuelle Bedeutung beimisst, reichen nicht aus. ' +
                '<i>Da die Strafbarkeit im Einzelfall von vielen Feinheiten abhängt, bedarf die vermeintliche Tathandlung einer weiteren Abklärung</i>. ' +
            '</p><p>' +
                'Bitte beachten Sie, dass bei dem von Ihrem*Ihrer Klient*in berichteten sexuell devianten Verhalten auch ein Kind als Opfer betroffen sein <i>könnte</i>, dass mit ' +
                'Ihrem*Ihrer Klient*in in einem Haushalt lebt oder in enger Verbindung steht und damit möglicherweise bei einer Wiederholungstat gefährdet sein <i>könnte</i>. In solchen Fällen <i>können</i> Sie angehalten ' +
                'sein, Ihre Schweigepflicht (§ 203 StGB) zu brechen, wenn eine gegenwärtige, nicht anders abwendbar Gefahr für Leben, Leib, Freiheit, Ehre, Eigentum oder ein anderes ' +
                'Rechtsgut für das Kind besteht. Wichtig ist hier eine Abwägung der widerstreitenden Interessen der betroffenen Rechtsgüter und des Grades der ihr drohenden Gefahren. ' +
                'Das geschützte Interesse muss das beeinträchtigte wesentlich überwiegen. Es ist eine objektive Prognose aus der Sicht eines nachträglichen Beobachters vorzunehmen, ' +
                'dem im kritischen Augenblick die wesentlichen Umstände bekannt sind. Der Eintritt der konkreten Gefahr für das Rechtsgut des Kindes ist wahrscheinlich, wenn die ' +
                'Möglichkeit des Eintritts eines Schadens nahe liegt oder eine begründete Besorgnis besteht.' +
            '</p>',
        nds: 
            '<p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, dass ' +
                'der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p><p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern (KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei verläuft, eine unverzügliche ' +
                'Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). Im Falle von Anzeichen einer ernsthaften Gefährdung Dritter ergeht ebenfalls ' +
                'eine Meldung nachrichtlich an die zuständige Fachberaterin oder den zuständigen Fachberater Risikomanagement. ' +
            '</p><p>' +
                'In Fällen der möglichen Gefährdung Dritter sieht die Konzeption nach einer Abwägung im Einzelfall eine zusätzliche Information der KURS-Sachbearbeiterinnen oder ' +
                'KURS-Sachbearbeiter vor. Dies <i>kann</i> bspw. unter den Voraussetzungen des § 34 StGB bei einer „gegenwärtigen, nicht anders abwendbaren Gefahr für Leben, Freiheit, ' +
                'Ehre, Eigentum oder ein anderes Rechtsgut“ der Fall sein, wenn das geschützte Interesse die datenschutzrechtliche Beeinträchtigung überwiegt. <i>Hier sind alle ' +
                'gesammelten Erkenntnisse in die Entscheidung miteinzubeziehen</i>.' +
            '</p>',
        bw: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten, Verstöße gegen Weisungen und neue Straftaten unverzüglich der ' +
                'Führungsaufsichtsstelle zu melden sind, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu überprüfen und entsprechend anzupassen. ' +
                'Außerdem wird angeordnet, dass im Falle einer gegenwärtigen Gefahr für Leib oder Leben, Gesundheit, persönliche Freiheit oder die sexuelle Selbstbestimmung einer ' +
                'Person sowie – soweit gesetzlich zulässig – in anderen Fällen einer gefährlichen und andere Personen gefährdenden Entwicklung des Risikoprobanden, ungeachtet anderer ' +
                'Unterrichtungspflichten, unverzüglich auch die für den Wohnort des Risikoprobanden zuständige Polizeidienststelle unterrichtet wird. Den am Wohnort zuständigen ' +
                'Polizeidienststellen obliegt die Festlegung und Koordinierung der gefahrenabwehrrechtlichen Maßnahmen bei den Risikoprobanden (Ziffer 5.8.1).' +
            '</p><p>' +
                'Als gefahrenabwehrrechtliche Maßnahmen kommen nach Ziffer 5.8.2 <i>bspw.</i> die Gefährderansprache, Gefährdetenansprache oder schließlich auch die Observation in Betracht.' +
            '</p>',
        bayern: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. Es <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, ' +
                'wenn das gezeigte Verhalten (Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +
                '<b>Wichtig:</b> Gemäß Ziffer 4.3 der Konzeption HEADS ist bei <i>Gefahr im Verzug</i>  unverzüglich die für den Wohnort des*r Proband*in örtlich zuständigen Polizeidienststelle  zu verständigen, ' +
                'die zunächst über die aktuell notwendigen Maßnahmen (Gefahrenabwehr/Strafverfolgung) entscheidet und die Kriminalpolizeidienststelle, das zuständige Polizeipräsidium ' +
                'sowie die Zentralstelle HEADS zu benachrichtigen hat.' +
            '</p><p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b> ' +
            '</p><p>' +
                'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, ' +
                'dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, ' +
                'wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist.' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). ' +
                '<i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p>'


    },

    'Ich habe mich selbst befriedigt, als ein Kind in meiner Nähe war.': {

        description:
            '</p><p>' +
                'Der sexuelle Missbrauch von Kindern ist gem. § 176 ff. StGB strafbar. Schutzgut der §§ 176 ff. StGB ist die kindliche Gesamtentwicklung und die freie Entwicklung ' +
                'sexueller Selbstbestimmungsfähigkeit. Es wird eine absolute Grenze für den sexualbezogenen Umgang mit Kindern bestimmt, indem solche Kontakte ausnahmslos verboten ' +
                'sind. Darunter fallen auch sog. Hands-Off Handlungen.' +
            '</p><p>' +
                'Zur Bestimmung des aktuellen Rückfallrisikos wurde im Rahmen von @myTabu u.a. das Selbstbeurteilungsinstrument der Checklist of Behavioral Misconduct (CMC) ' +
                'eingesetzt. Es soll überprüfen, ob die Online-Intervention eine Reduktion der devianten Verhaltensweisen herbeiführen konnte. Dabei werden in den einzelnen Items zum ' +
                'sexuell devianten Verhalten Szenarien abgefragt, die eine Straftat i.S.d. §§ 176 ff., 184b StGB darstellen oder darstellen <i>könnten</i>, wenn der*die Klient*in mit ' +
                'entsprechender Intention gehandelt hat. Hat das Kind den Vorgang wahrgenommen? Auf eine körperliche Nähe kommt es dabei nicht an. War für den Klient die Wahrnehmung ' +
                'des Kindes handlungsbestimmend? Straflos ist z.B. die Vornahme sexueller Handlungen unter beengten Wohnverhältnissen unter Inkaufnahme einer Beobachtung durch Kinder. ' +
                'War die Handlung exhibitionistischer Natur (§ 183 StGB)? <i>Da die Strafbarkeit im Einzelfall von vielen Feinheiten abhängt, bedarf die vermeintliche Tathandlung einer ' +
                'weiteren Abklärung</i>. ' +
            '</p><p>' +
                'Bitte beachten Sie, dass bei dem von Ihrem*Ihrer Klient*in berichteten sexuell devianten Verhalten auch ein Kind als Opfer betroffen sein <i>könnte</i>, dass mit ' +
                'Ihrem*Ihrer Klient*in in einem Haushalt lebt oder in enger Verbindung steht und damit möglicherweise bei einer Wiederholungstat gefährdet sein <i>könnte</i>. In solchen Fällen <i>können</i> Sie angehalten ' +
                'sein, Ihre Schweigepflicht (§ 203 StGB) zu brechen, wenn eine gegenwärtige, nicht anders abwendbar Gefahr für Leben, Leib, Freiheit, Ehre, Eigentum oder ein anderes ' +
                'Rechtsgut für das Kind besteht. Wichtig ist hier eine Abwägung der widerstreitenden Interessen der betroffenen Rechtsgüter und des Grades der ihr drohenden Gefahren. ' +
                'Das geschützte Interesse muss das beeinträchtigte wesentlich überwiegen. Es ist eine objektive Prognose aus der Sicht eines nachträglichen Beobachters vorzunehmen, ' +
                'dem im kritischen Augenblick die wesentlichen Umstände bekannt sind. Der Eintritt der konkreten Gefahr für das Rechtsgut des Kindes ist wahrscheinlich, wenn die ' +
                'Möglichkeit des Eintritts eines Schadens nahe liegt oder eine begründete Besorgnis besteht.' +
            '</p>',
        nds: 
            '<p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, dass ' +
                'der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p><p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern (KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei verläuft, eine unverzügliche ' +
                'Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). Im Falle von Anzeichen einer ernsthaften Gefährdung Dritter ergeht ebenfalls ' +
                'eine Meldung nachrichtlich an die zuständige Fachberaterin oder den zuständigen Fachberater Risikomanagement. ' +
            '</p><p>' +
                'In Fällen der möglichen Gefährdung Dritter sieht die Konzeption nach einer Abwägung im Einzelfall eine zusätzliche Information der KURS-Sachbearbeiterinnen oder ' +
                'KURS-Sachbearbeiter vor. Dies <i>kann</i> bspw. unter den Voraussetzungen des § 34 StGB bei einer „gegenwärtigen, nicht anders abwendbaren Gefahr für Leben, Freiheit, ' +
                'Ehre, Eigentum oder ein anderes Rechtsgut“ der Fall sein, wenn das geschützte Interesse die datenschutzrechtliche Beeinträchtigung überwiegt. <i>Hier sind alle ' +
                'gesammelten Erkenntnisse in die Entscheidung miteinzubeziehen</i>.' +
            '</p>',
        bw: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten, Verstöße gegen Weisungen und neue Straftaten unverzüglich der ' +
                'Führungsaufsichtsstelle zu melden sind, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu überprüfen und entsprechend anzupassen. ' +
                'Außerdem wird angeordnet, dass im Falle einer gegenwärtigen Gefahr für Leib oder Leben, Gesundheit, persönliche Freiheit oder die sexuelle Selbstbestimmung einer ' +
                'Person sowie – soweit gesetzlich zulässig – in anderen Fällen einer gefährlichen und andere Personen gefährdenden Entwicklung des Risikoprobanden, ungeachtet ' +
                'anderer Unterrichtungspflichten, unverzüglich auch die für den Wohnort des Risikoprobanden zuständige Polizeidienststelle unterrichtet wird. Den am Wohnort ' +
                'zuständigen Polizeidienststellen obliegt die Festlegung und Koordinierung der gefahrenabwehrrechtlichen Maßnahmen bei den Risikoprobanden (Ziffer 5.8.1).' +
            '</p><p>' +
                'Als gefahrenabwehrrechtliche Maßnahmen kommen nach Ziffer 5.8.2 <i>bspw</i>. die Gefährderansprache, Gefährdetenansprache oder schließlich auch die Observation ' +
                'in Betracht.' +
            '</p>',
        bayern: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. Es <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, ' +
                'wenn das gezeigte Verhalten (Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +
                '<b>Wichtig:</b> Gemäß Ziffer 4.3 der Konzeption HEADS ist bei <i>Gefahr im Verzug</i>  unverzüglich die für den Wohnort des*r Proband*in örtlich zuständigen Polizeidienststelle  zu verständigen, ' +
                'die zunächst über die aktuell notwendigen Maßnahmen (Gefahrenabwehr/Strafverfolgung) entscheidet und die Kriminalpolizeidienststelle, das zuständige Polizeipräsidium ' +
                'sowie die Zentralstelle HEADS zu benachrichtigen hat.' +
            '</p><p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b> ' +
            '</p><p>' +
                'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, ' +
                'dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, ' +
                'wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist.' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). ' +
                '<i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p>'

    },

    'Ich habe mir Pornos angeschaut, als Kinder im Raum waren.': {

        description:
            '</p><p>' +
                'Der sexuelle Missbrauch von Kindern ist gem. § 176 ff. StGB strafbar. Schutzgut der §§ 176 ff. StGB ist die kindliche Gesamtentwicklung und die freie Entwicklung ' +
                'sexueller Selbstbestimmungsfähigkeit. Es wird eine absolute Grenze für den sexualbezogenen Umgang mit Kindern bestimmt, indem solche Kontakte ausnahmslos verboten ' +
                'sind. Darunter fallen auch sog. Hands-Off Handlungen, wie bspw. das Vorzeigen pornografischer Abbildungen (§ 176 Abs. 4 Nr. 4 StGB). ' +
            '</p><p>' +
                'Zur Bestimmung des aktuellen Rückfallrisikos wurde im Rahmen von @myTabu u.a. das Selbstbeurteilungsinstrument der Checklist of Behavioral Misconduct (CMC) ' +
                'eingesetzt. Es soll überprüfen, ob die Online-Intervention eine Reduktion der devianten Verhaltensweisen herbeiführen konnte. Dabei werden in den einzelnen ' +
                'Items zum sexuell devianten Verhalten Szenarien abgefragt, die eine Straftat i.S.d. §§ 176 ff., 184b StGB darstellen oder darstellen <i>könnten</i>, wenn der*die Klient*in ' +
                'mit entsprechender Intention gehandelt hat. Erforderlich ist eine Handlung, die zur sinnlichen Wahrnehmung des Kindes von den Abbildungen führt. <i>Die bloße ' +
                'Möglichkeit der Wahrnehmung genügt hierzu nicht</i>. Darüber hinaus muss das Einwirken auf eine psychische Einflussnahme in der Weise gerichtet sein, dass in dem ' +
                'Kind sexuelle Interessen geweckt oder sonst sexuelle Impulse ausgelöst werden sollen. <i>Da die Strafbarkeit im Einzelfall von vielen Feinheiten abhängt, bedarf die ' +
                'vermeintliche Tathandlung einer weiteren Abklärung</i>. ' +
            '</p><p>' +
                'Bitte beachten Sie, dass bei dem von Ihrem*Ihrer Klient*in berichteten sexuell devianten Verhalten auch ein Kind als Opfer betroffen sein <i>könnte</i>, dass mit ' +
                'Ihrem*Ihrer Klient*in in einem Haushalt lebt oder in enger Verbindung steht und damit möglicherweise bei einer Wiederholungstat gefährdet sein <i>könnte</i>. In solchen Fällen <i>können</i> Sie angehalten ' +
                'sein, Ihre Schweigepflicht (§ 203 StGB) zu brechen, wenn eine gegenwärtige, nicht anders abwendbar Gefahr für Leben, Leib, Freiheit, Ehre, Eigentum oder ein anderes ' +
                'Rechtsgut für das Kind besteht. Wichtig ist hier eine Abwägung der widerstreitenden Interessen der betroffenen Rechtsgüter und des Grades der ihr drohenden Gefahren. ' +
                'Das geschützte Interesse muss das beeinträchtigte wesentlich überwiegen. Es ist eine objektive Prognose aus der Sicht eines nachträglichen Beobachters vorzunehmen, ' +
                'dem im kritischen Augenblick die wesentlichen Umstände bekannt sind. Der Eintritt der konkreten Gefahr für das Rechtsgut des Kindes ist wahrscheinlich, wenn die ' +
                'Möglichkeit des Eintritts eines Schadens nahe liegt oder eine begründete Besorgnis besteht.' +
            '</p>',
        nds: 
            '<p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, dass ' +
                'der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p><p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern (KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei verläuft, eine unverzügliche ' +
                'Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). Im Falle von Anzeichen einer ernsthaften Gefährdung Dritter ergeht ebenfalls ' +
                'eine Meldung nachrichtlich an die zuständige Fachberaterin oder den zuständigen Fachberater Risikomanagement. ' +
            '</p><p>' +
                'In Fällen der möglichen Gefährdung Dritter sieht die Konzeption nach einer Abwägung im Einzelfall eine zusätzliche Information der KURS-Sachbearbeiterinnen oder ' +
                'KURS-Sachbearbeiter vor. Dies <i>kann</i> bspw. unter den Voraussetzungen des § 34 StGB bei einer „gegenwärtigen, nicht anders abwendbaren Gefahr für Leben, Freiheit, ' +
                'Ehre, Eigentum oder ein anderes Rechtsgut“ der Fall sein, wenn das geschützte Interesse die datenschutzrechtliche Beeinträchtigung überwiegt. <i>Hier sind alle ' +
                'gesammelten Erkenntnisse in die Entscheidung miteinzubeziehen</i>.' +
            '</p>',
        bw: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten, Verstöße gegen Weisungen und neue Straftaten unverzüglich der ' +
                'Führungsaufsichtsstelle zu melden sind, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu überprüfen und entsprechend anzupassen. ' +
                'Außerdem wird angeordnet, dass im Falle einer gegenwärtigen Gefahr für Leib oder Leben, Gesundheit, persönliche Freiheit oder die sexuelle Selbstbestimmung ' +
                'einer Person sowie – soweit gesetzlich zulässig – in anderen Fällen einer gefährlichen und andere Personen gefährdenden Entwicklung des Risikoprobanden, ' +
                'ungeachtet anderer Unterrichtungspflichten, unverzüglich auch die für den Wohnort des Risikoprobanden zuständige Polizeidienststelle unterrichtet wird. Den ' +
                'am Wohnort zuständigen Polizeidienststellen obliegt die Festlegung und Koordinierung der gefahrenabwehrrechtlichen Maßnahmen bei den Risikoprobanden (Ziffer 5.8.1).' +
            '</p><p>' +
                'Als gefahrenabwehrrechtliche Maßnahmen kommen nach Ziffer 5.8.2 <i>bspw</i>. die Gefährderansprache, Gefährdetenansprache oder schließlich auch die Observation in Betracht.' +
            '</p>',
        bayern: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. Es <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, ' +
                'wenn das gezeigte Verhalten (Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +
                '<b>Wichtig:</b> Gemäß Ziffer 4.3 der Konzeption HEADS ist bei <i>Gefahr im Verzug</i>  unverzüglich die für den Wohnort des*r Proband*in örtlich zuständigen Polizeidienststelle  zu verständigen, ' +
                'die zunächst über die aktuell notwendigen Maßnahmen (Gefahrenabwehr/Strafverfolgung) entscheidet und die Kriminalpolizeidienststelle, das zuständige Polizeipräsidium ' +
                'sowie die Zentralstelle HEADS zu benachrichtigen hat.' +
            '</p><p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b> ' +
            '</p><p>' +
                'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, ' +
                'dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, ' +
                'wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist.' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). ' +
                '<i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p>'

    },

    'Ich habe mir Kinderpornos angesehen.': {

        description:
            '</p><p>' +
                'Der sexuelle Missbrauch von Kindern ist gem. §§ 176 ff. StGB strafbar. Schutzgut der §§ 176 ff. StGB ist die kindliche Gesamtentwicklung und die freie ' +
                'Entwicklung sexueller Selbstbestimmungsfähigkeit. Es wird eine absolute Grenze für den sexualbezogenen Umgang mit Kindern bestimmt, indem solche Kontakte ' +
                'ausnahmslos verboten sind. Die Strafvorschrift des § 184b StGB (Verbreitung, Erwerb und Besitz kinderpornographischer Schriften) stützt sich auf die Belange ' +
                'des Schutzes von Kindern und zielt so letztlich auch auf eine Bestrafung der mittelbaren Förderung des sexuellen Missbrauchs von Kindern ab. Tatgegenstand ' +
                'muss eine kinderpornografische Schrift sein, die in § 184b Abs. 1 Nr. 1 StGB legaldefiniert wird. Verboten ist das Verbreiten, Zugänglichmachen für die ' +
                'Öffentlichkeit, die Besitzverschaffung einer anderen Person an Schriften mit Realitätsgehalt, das Herstellen einer kinderpornografischen Schrift, die ein ' +
                'tatsächliches Geschehen wiedergibt, etwaige Vorbereitungshandlungen wie das Anbieten, Bewerben oder die Ein- und Ausfuhr von kinderpornografischen Schriften ' +
                '(§ 184b Abs. 1 StGB).' +
            '</p><p>' +
                'Zur Bestimmung des aktuellen Rückfallrisikos wurde im Rahmen von @myTabu u.a. das Selbstbeurteilungsinstrument der Checklist of Behavioral Misconduct (CMC) ' +
                'eingesetzt. Es soll überprüfen, ob die Online-Intervention eine Reduktion der devianten Verhaltensweisen herbeiführen konnte. Dabei werden in den einzelnen ' +
                'Items zum sexuell devianten Verhalten Szenarien abgefragt, die eine Straftat i.S.d. §§ 176 ff., 184b StGB darstellen oder darstellen <i>könnten</i>, wenn der*die ' +
                'Klient*in mit entsprechender Intention gehandelt hat. ' +
            '</p><p>' +
                'Befand sich das Material im Besitz des*der Klient*in, hat er*sie es sich verschafft oder ggf. lediglich visuell zur Kenntnis genommen? Der eigene Besitz oder ' +
                'das sich Besitz-Verschaffen von kinderpornografischen Schriften, die ein tatsächliches oder wirklichkeitsnahes Geschehen wiedergeben, ist strafbar (§ 184b Abs. ' +
                '3 StGB). Für ein Sich-Verschaffen des Materials reicht es nach neuerer Rechtsprechung schon aus, wenn der Download in den Arbeitsspeicher, bzw. im Cache-Speicher ' +
                'vollendet ist. Der Besitz ist als Dauerdelikt konzipiert, der sich in der Aufrechterhaltung eines tatsächlichen Herrschaftsverhältnisses erstreckt. <i>Da die ' +
                'Strafbarkeit im Einzelfall von vielen Feinheiten abhängt, bedarf die vermeintliche Tathandlung einer weiteren Abklärung</i>. ' +
            '</p><p>' +
                'Bitte beachten Sie, dass bei dem von Ihrem*Ihrer Klient*in berichteten sexuell devianten Verhalten auch ein Kind als Opfer betroffen sein <i>könnte</i>, dass mit ' +
                'Ihrem*Ihrer Klient*in in einem Haushalt lebt oder in enger Verbindung steht und damit möglicherweise bei einer Wiederholungstat gefährdet sein <i>könnte</i>. In solchen Fällen <i>können</i> Sie angehalten ' +
                'sein, Ihre Schweigepflicht (§ 203 StGB) zu brechen, wenn eine gegenwärtige, nicht anders abwendbar Gefahr für Leben, Leib, Freiheit, Ehre, Eigentum oder ein anderes ' +
                'Rechtsgut für das Kind besteht. Wichtig ist hier eine Abwägung der widerstreitenden Interessen der betroffenen Rechtsgüter und des Grades der ihr drohenden Gefahren. ' +
                'Das geschützte Interesse muss das beeinträchtigte wesentlich überwiegen. Es ist eine objektive Prognose aus der Sicht eines nachträglichen Beobachters vorzunehmen, ' +
                'dem im kritischen Augenblick die wesentlichen Umstände bekannt sind. Der Eintritt der konkreten Gefahr für das Rechtsgut des Kindes ist wahrscheinlich, wenn die ' +
                'Möglichkeit des Eintritts eines Schadens nahe liegt oder eine begründete Besorgnis besteht.' +
            '</p>',
        nds: 
            '<p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, dass ' +
                'der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p><p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern (KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei verläuft, eine unverzügliche ' +
                'Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). Im Falle von Anzeichen einer ernsthaften Gefährdung Dritter ergeht ebenfalls ' +
                'eine Meldung nachrichtlich an die zuständige Fachberaterin oder den zuständigen Fachberater Risikomanagement. ' +
            '</p><p>' +
                'In Fällen der möglichen Gefährdung Dritter sieht die Konzeption nach einer Abwägung im Einzelfall eine zusätzliche Information der KURS-Sachbearbeiterinnen oder ' +
                'KURS-Sachbearbeiter vor. Dies <i>kann</i> bspw. unter den Voraussetzungen des § 34 StGB bei einer „gegenwärtigen, nicht anders abwendbaren Gefahr für Leben, Freiheit, ' +
                'Ehre, Eigentum oder ein anderes Rechtsgut“ der Fall sein, wenn das geschützte Interesse die datenschutzrechtliche Beeinträchtigung überwiegt. <i>Hier sind alle ' +
                'gesammelten Erkenntnisse in die Entscheidung miteinzubeziehen</i>.' +
            '</p>',
        bw: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten, Verstöße gegen Weisungen und neue Straftaten unverzüglich ' +
                'der Führungsaufsichtsstelle zu melden sind, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu überprüfen und entsprechend ' +
                'anzupassen. Außerdem wird angeordnet, dass im Falle einer gegenwärtigen Gefahr für Leib oder Leben, Gesundheit, persönliche Freiheit oder die sexuelle ' +
                'Selbstbestimmung einer Person sowie – soweit gesetzlich zulässig – in anderen Fällen einer gefährlichen und andere Personen gefährdenden Entwicklung des ' +
                'Risikoprobanden, ungeachtet anderer Unterrichtungspflichten, unverzüglich auch die für den Wohnort des Risikoprobanden zuständige Polizeidienststelle ' +
                'unterrichtet wird. Den am Wohnort zuständigen Polizeidienststellen obliegt die Festlegung und Koordinierung der gefahrenabwehrrechtlichen Maßnahmen bei ' +
                'den Risikoprobanden (Ziffer 5.8.1).' +
            '</p><p>' +
                'Als gefahrenabwehrrechtliche Maßnahmen kommen nach Ziffer 5.8.2 <i>bspw</i>. die Gefährderansprache, Gefährdetenansprache oder schließlich auch die ' +
                'Observation in Betracht.' +
            '</p>',
        bayern: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. Es <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, ' +
                'wenn das gezeigte Verhalten (Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +
                '<b>Wichtig:</b> Gemäß Ziffer 4.3 der Konzeption HEADS ist bei <i>Gefahr im Verzug</i>  unverzüglich die für den Wohnort des*r Proband*in örtlich zuständigen Polizeidienststelle  zu verständigen, ' +
                'die zunächst über die aktuell notwendigen Maßnahmen (Gefahrenabwehr/Strafverfolgung) entscheidet und die Kriminalpolizeidienststelle, das zuständige Polizeipräsidium ' +
                'sowie die Zentralstelle HEADS zu benachrichtigen hat.' +
            '</p><p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b> ' +
            '</p><p>' +
                'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, ' +
                'dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, ' +
                'wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist.' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). ' +
                '<i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p>'

    },

    'Ich habe Fotos von Kindern gemacht.': {

        description:
            '</p><p>' +
                'Der sexuelle Missbrauch von Kindern ist gem. § 176 ff. StGB strafbar. Schutzgut der §§ 176 ff. StGB ist die kindliche Gesamtentwicklung und die freie ' +
                'Entwicklung sexueller Selbstbestimmungsfähigkeit. Es wird eine absolute Grenze für den sexualbezogenen Umgang mit Kindern bestimmt, indem solche Kontakte ' +
                'ausnahmslos verboten sind. Darunter fallen auch sog. Hands-Off Handlungen, wie bspw. das Vorzeigen pornografischer Abbildungen (§ 176 Abs. 4 Nr. 4 StGB) ' +
                'oder das Fotografieren zwecks Herstellung kinderpornografischen Materials (§ 176 Abs. 4 Nr. 3 lit. b StGB). Wird das Kind besonders gefährdet oder handelt ' +
                'es sich um eine Wiederholungstat (§ 176a Abs. 1 StGB), stellt die Tat ggf. ein Verbrechen (§ 12 Abs. 1 StGB) dar. Die Strafvorschrift des § 184b StGB ' +
                '(Verbreitung, Erwerb und Besitz kinderpornographischer Schriften) stützt sich auf die Belange des Schutzes von Kindern und zielt so letztlich auch auf ' +
                'eine Bestrafung der mittelbaren Förderung des sexuellen Missbrauchs von Kindern ab. Tatgegenstand muss eine kinderpornografische Schrift sein, die in ' +
                '§ 184b Abs. 1 Nr. 1 StGB legaldefiniert wird. Verboten ist das Verbreiten, Zugänglichmachen für die Öffentlichkeit, die Besitzverschaffung einer anderen ' +
                'Person an Schriften mit Realitätsgehalt, das Herstellen einer kinderpornografischen Schrift, die ein tatsächliches Geschehen wiedergibt, etwaige ' +
                'Vorbereitungshandlungen wie das Anbieten, Bewerben oder die Ein- und Ausfuhr von kinderpornografischen Schriften (§ 184b Abs. 1 StGB).' +
            '</p><p>' +
                'Zur Bestimmung des aktuellen Rückfallrisikos wurde im Rahmen von @myTabu u.a. das Selbstbeurteilungsinstrument der Checklist of Behavioral Misconduct (CMC) ' +
                'eingesetzt. Es soll überprüfen, ob die Online-Intervention eine Reduktion der devianten Verhaltensweisen herbeiführen konnte. Dabei werden in den einzelnen ' +
                'Items zum sexuell devianten Verhalten Szenarien abgefragt, die eine Straftat i.S.d. §§ 176 ff., 184b StGB darstellen oder darstellen <i>könnten</i>, wenn der*die Klient*in ' +
                'mit entsprechender Intention gehandelt hat. ' +
            '</p><p>' +
                'Handelt es sich um Fotos, die per Definition einer „kinderpornografischen Schrift“ i.S.d. § 184b Abs. 1 Nr. 1 StGB entsprechen (Darstellung einer sexuellen Handlung ' +
                'an oder vor Kindern, sog. Posing – unnatürlich geschlechtsbetonte Körperhaltung, sexuell aufreizende Abbildungen von Genitalien oder Gesäß)? Ausgeschlossen sind ' +
                'Darstellungen, aus denen sich keine Handlung ergibt, die unwillkürliche Positionen zeigen oder einen „harmlosen“ Charakter darstellen. Dabei kommt es auf den vom ' +
                'Klienten intendierten Zweck der Abbildung an. <i>Da die Strafbarkeit im Einzelfall von vielen Feinheiten abhängt, bedarf die vermeintliche Tathandlung einer weiteren ' +
                'Abklärung</i>. ' +
            '</p><p>' +
                'Bitte beachten Sie, dass bei dem von Ihrem*Ihrer Klient*in berichteten sexuell devianten Verhalten auch ein Kind als Opfer betroffen sein <i>könnte</i>, dass mit ' +
                'Ihrem*Ihrer Klient*in in einem Haushalt lebt oder in enger Verbindung steht und damit möglicherweise bei einer Wiederholungstat gefährdet sein <i>könnte</i>. In solchen Fällen <i>können</i> Sie angehalten ' +
                'sein, Ihre Schweigepflicht (§ 203 StGB) zu brechen, wenn eine gegenwärtige, nicht anders abwendbar Gefahr für Leben, Leib, Freiheit, Ehre, Eigentum oder ein anderes ' +
                'Rechtsgut für das Kind besteht. Wichtig ist hier eine Abwägung der widerstreitenden Interessen der betroffenen Rechtsgüter und des Grades der ihr drohenden Gefahren. ' +
                'Das geschützte Interesse muss das beeinträchtigte wesentlich überwiegen. Es ist eine objektive Prognose aus der Sicht eines nachträglichen Beobachters vorzunehmen, ' +
                'dem im kritischen Augenblick die wesentlichen Umstände bekannt sind. Der Eintritt der konkreten Gefahr für das Rechtsgut des Kindes ist wahrscheinlich, wenn die ' +
                'Möglichkeit des Eintritts eines Schadens nahe liegt oder eine begründete Besorgnis besteht.' +
            '</p>',
        nds: 
            '<p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, dass ' +
                'der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, ' +
                'wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p><p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern (KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei verläuft, eine unverzügliche ' +
                'Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). Im Falle von Anzeichen einer ernsthaften Gefährdung Dritter ergeht ebenfalls ' +
                'eine Meldung nachrichtlich an die zuständige Fachberaterin oder den zuständigen Fachberater Risikomanagement. ' +
            '</p><p>' +
                'In Fällen der möglichen Gefährdung Dritter sieht die Konzeption nach einer Abwägung im Einzelfall eine zusätzliche Information der KURS-Sachbearbeiterinnen oder ' +
                'KURS-Sachbearbeiter vor. Dies <i>kann</i> bspw. unter den Voraussetzungen des § 34 StGB bei einer „gegenwärtigen, nicht anders abwendbaren Gefahr für Leben, Freiheit, ' +
                'Ehre, Eigentum oder ein anderes Rechtsgut“ der Fall sein, wenn das geschützte Interesse die datenschutzrechtliche Beeinträchtigung überwiegt. <i>Hier sind alle ' +
                'gesammelten Erkenntnisse in die Entscheidung miteinzubeziehen</i>.' +
            '</p>',
        bw: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten, Verstöße gegen Weisungen und neue Straftaten unverzüglich der Führungsaufsichtsstelle ' +
                'zu melden sind, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu überprüfen und entsprechend anzupassen. Außerdem wird ' +
                'angeordnet, dass im Falle einer gegenwärtigen Gefahr für Leib oder Leben, Gesundheit, persönliche Freiheit oder die sexuelle Selbstbestimmung ' +
                'einer Person sowie – soweit gesetzlich zulässig – in anderen Fällen einer gefährlichen und andere Personen gefährdenden Entwicklung des Risikoprobanden, ungeachtet ' +
                'anderer Unterrichtungspflichten, unverzüglich auch die für den Wohnort des Risikoprobanden zuständige Polizeidienststelle unterrichtet wird. Den am Wohnort zuständigen ' +
                'Polizeidienststellen obliegt die Festlegung und Koordinierung der gefahrenabwehrrechtlichen Maßnahmen bei den Risikoprobanden (Ziffer 5.8.1).' +
            '</p><p>' +
                'Als gefahrenabwehrrechtliche Maßnahmen kommen nach Ziffer 5.8.2 <i>bspw.</i> die Gefährderansprache, Gefährdetenansprache oder schließlich auch die Observation in Betracht.' +
            '</p>',
        bayern:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. Es <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, ' +
                'wenn das gezeigte Verhalten (Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +
                '<b>Wichtig:</b> Gemäß Ziffer 4.3 der Konzeption HEADS ist bei <i>Gefahr im Verzug</i>  unverzüglich die für den Wohnort des*r Proband*in örtlich zuständigen Polizeidienststelle  zu verständigen, ' +
                'die zunächst über die aktuell notwendigen Maßnahmen (Gefahrenabwehr/Strafverfolgung) entscheidet und die Kriminalpolizeidienststelle, das zuständige Polizeipräsidium ' +
                'sowie die Zentralstelle HEADS zu benachrichtigen hat.' +
            '</p><p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b> ' +
            '</p><p>' +
                'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, ' +
                'dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, ' +
                'wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist.' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus der Begehung einer Straftat ergibt, dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 1 StGB). ' +
                '<i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p>'

    }, 

    'Ich habe Alkohol getrunken.': {

        description:
            '<p>' +
                'Der Substanzkonsum wird in der Intervention durch verschiedene Messinstrumente erfasst: Im Rahmen des Acute-2007-Self-Report, einem Selbstbeschreibungsverfahren zur ' +
                'Erfassung von akut-dynamischen Rückfallfaktoren und im Rahmen des Selbstbeurteilungsinstruments der Checklist of Behavioral Misconduct (CMC), das kriminelle ' +
                'Verhaltensweisen erfasst und sich auf einen Zeitraum von 4 Wochen bezieht. Akut-dynamische Rückfallfaktoren können sich innerhalb von Stunden oder Tagen verändern. ' +
                'Neben dem Konsumverhalten werden auch das Kontrollerleben sowie das Vorliegen von möglichen, aus dem Substanzkonsum resultierende Probleme erfasst. Der ' +
                'Alkoholkonsum spielt bei Kindesmissbrauchstätern eine deutlich größere Rolle als der Konsum anderer Drogen. ' +
            '</p><p>' +
                '<i>Ggf. hat Ihr*e Klient*in angegeben, dass er*sie innerhalb der letzten 4 Wochen oder „oft“ oder „manchmal“ Alkohol zu sich genommen hat</i>.' +
            '</p><p>' +
                '<b>Ist Ihrem*Ihrer Klient*in eine Weisung hinsichtlich des Substanzkonsums erteilt worden (§ 68b Abs. 1 S. 1 Nr. 10 StGB) oder gibt es eine Weisung zur Alkohol- ' +
                'oder Suchtmittelkontrolle?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Verstoß gegen die Weisung es erforderlich machen <i>kann</i>, dass das Gericht die Höchstdauer der Führungsaufsicht überschreitet und eine ' +
                'unbefristete Führungsaufsicht anordnet, wenn sich aus einem Verstoß gegen Weisungen nach § 68b Abs. 1 StGB oder auf Grund anderer bestimmter Tatsachen <i>konkrete ' +
                'Anhaltspunkte</i> dafür ergeben, dass eine Gefährdung der Allgemeinheit durch die Begehung weiterer erheblicher Straftaten zu befürchten ist und gegen die verurteilte ' +
                'Person wegen Straftaten der in § 181b StGB genannten Art eine Freiheitsstrafe oder Gesamtfreiheitsstrafe von mehr als zwei Jahren verhängt oder die Unterbringung ' +
                'in einem psychiatrischen Krankenhaus oder einer Entziehungsanstalt angeordnet wurde (§ 68c Abs. 3 S. 1 Nr. 2 StGB). Ein Verstoß gegen die Weisung ist zudem über ' +
                '§ 145a StGB strafbewehrt. <i>Möglich</i> ist ebenfalls eine Nachtragsentscheidung (§ 68d Abs. 1 StGB) durch das Gericht. Die Sachlage <i>kann</i> es jederzeit erfordern, neue ' +
                'Anweisungen zu erteilen, frühere Anweisungen aufzuheben oder veränderten Umständen anzupassen.' +
            '</p>',
        nds:
            '<p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe? </b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus dem Weisungsverstoß oder der Begehung einer Straftat ergibt, ' +
                'dass der Zweck der Maßregel die Unterbringung erfordert. <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, wie bspw. eine andere ' +
                'Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p><p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern (KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei verläuft, eine ' +
                'unverzügliche Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). Im Falle eines ' +
                'Weisungsverstoßes ergeht ebenfalls eine Meldung nachrichtlich an die zuständige Fachberaterin oder den zuständigen Fachberater Risikomanagement. Die ' +
                'Führungsaufsichtsstelle wird sich dann unverzüglich mit der Zentralstelle KURS im LKA Niedersachsen in Verbindung setzen. Im Falle eines Alkohol- oder ' +
                'Drogenabusus ist unverzüglich das weitere Vorgehen mit der Führungsaufsichtsstelle und der Fachberatung Risikomanagement abzustimmen. ' +
            '</p>',
        bw:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten, Verstöße gegen Weisungen und neue Straftaten unverzüglich ' + 
                'der Führungsaufsichtsstelle zu melden sind, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu überprüfen und entsprechend anzupassen.' +
            '</p>',
        bayern: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter</b>' +
                '</p><p>' +
                    'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. ' +
                    'Bei einem Verstoß gegen Auflagen und Weisungen ist der HEADS Ansprechpartner*in der Kriminalpolizeidienststelle unverzüglich der Bewährungshilfestelle, ' +
                    'dem zuständigen Polizeipräsidium und der Zentralstelle HEADS zur Mitteilung verpflichtet. Sofern es sich um einen Führungsaufsichtsproband*in handelt, ' +
                    'setzt die Bewährungshilfe dann in eigener Verantwortlichkeit die Führungsaufsichtsstelle in Kenntnis. (Ziffer 4.5 der Konzeption).' +
                '</p><p>' +
                    'Des Weiteren <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, wenn das gezeigte Verhalten ' +
                    '(Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) ' +
                    'Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
                '</p><p>' +  
                    '<b>Wichtig:</b> Gemäß Ziffer 4.3 der Konzeption HEADS ist bei <i>Gefahr im Verzug</i>  unverzüglich die für den Wohnort des*r Proband*in örtlich zuständigen Polizeidienststelle  zu verständigen, ' +
                    'die zunächst über die aktuell notwendigen Maßnahmen (Gefahrenabwehr/Strafverfolgung) entscheidet und die Kriminalpolizeidienststelle, das zuständige Polizeipräsidium ' +
                    'sowie die Zentralstelle HEADS zu benachrichtigen hat. ' +
                '</p><p>' +   
                    '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b> ' +
                '</p><p>' +    
                      'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 ' +
                      'die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist. ' +
                '</p><p>' +    
                    'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus dem gröblichen und beharrlichen Verstoß gegen die Weisungen ergibt, dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 2 StGB). ' +
                    '<i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB) um einen Widerruf zu vermeiden.' +
                '</p>'

    },

    'Ich habe Drogen genommen.': {

        description:
            '<p>' +
                'Der Substanzkonsum wird in der Intervention durch verschiedene Messinstrumente erfasst: Im Rahmen des Acute-2007-Self-Report, einem Selbstbeschreibungsverfahren zur ' +
                'Erfassung von akut-dynamischen Rückfallfaktoren und im Rahmen des Selbstbeurteilungsinstruments der Checklist of Behavioral Misconduct (CMC), das kriminelle ' +
                'Verhaltensweisen erfasst und sich auf einen Zeitraum von 4 Wochen bezieht. Akut-dynamische Rückfallfaktoren können sich innerhalb von Stunden oder Tagen verändern. ' +
                'Neben dem Konsumverhalten werden auch das Kontrollerleben sowie das Vorliegen von möglichen, aus dem Substanzkonsum resultierende Probleme erfasst. Der ' +
                'Alkoholkonsum spielt bei Kindesmissbrauchstätern eine deutlich größere Rolle als der Konsum anderer Drogen. ' +
            '</p><p>' +
                '<i>Ggf. hat Ihr*e Klient*in angegeben, dass er*sie innerhalb der letzten 4 Wochen oder „oft“ oder „manchmal“ Drogen zu sich genommen hat</i>.' +
            '</p><p>' +
                '<b>Ist Ihrem*Ihrer Klient*in eine Weisung hinsichtlich des Substanzkonsums erteilt worden (§ 68b Abs. 1 S. 1 Nr. 10 StGB) oder gibt es eine Weisung zur Alkohol- ' +
                'oder Suchtmittelkontrolle?</b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Verstoß gegen die Weisung es erforderlich machen <i>kann</i>, dass das Gericht die Höchstdauer der Führungsaufsicht überschreitet und eine ' +
                'unbefristete Führungsaufsicht anordnet, wenn sich aus einem Verstoß gegen Weisungen nach § 68b Abs. 1 StGB oder auf Grund anderer bestimmter Tatsachen <i>konkrete ' +
                'Anhaltspunkte</i> dafür ergeben, dass eine Gefährdung der Allgemeinheit durch die Begehung weiterer erheblicher Straftaten zu befürchten ist und gegen die verurteilte ' +
                'Person wegen Straftaten der in § 181b StGB genannten Art eine Freiheitsstrafe oder Gesamtfreiheitsstrafe von mehr als zwei Jahren verhängt oder die Unterbringung ' +
                'in einem psychiatrischen Krankenhaus oder einer Entziehungsanstalt angeordnet wurde (§ 68c Abs. 3 S. 1 Nr. 2 StGB). Ein Verstoß gegen die Weisung ist zudem über ' +
                '§ 145a StGB strafbewehrt. <i>Möglich</i> ist ebenfalls eine Nachtragsentscheidung (§ 68d Abs. 1 StGB) durch das Gericht. Die Sachlage <i>kann</i> es jederzeit erfordern, neue ' +
                'Anweisungen zu erteilen, frühere Anweisungen aufzuheben oder veränderten Umständen anzupassen.' +
            '</p><p>' +
                'Des Weiteren ist zu berücksichtigen, dass der reine Konsum zwar straflos, der Erwerb, das Verschaffen und der Besitz von Betäubungsmitteln allerdings gem. § 29 Abs. ' +
                '1 BtMG strafbar ist. ' +
            '</p>',
        nds:
            '<p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe? </b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus dem Weisungsverstoß oder der Begehung einer Straftat ergibt, dass ' +
                'der Zweck der Maßregel die Unterbringung erfordert. <i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, wie bspw. eine andere ' +
                'Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p><p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern (KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei verläuft, eine ' +
                'unverzügliche Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). Im Falle eines Weisungsverstoßes ' +
                'ergeht ebenfalls eine Meldung nachrichtlich an die zuständige Fachberaterin oder den zuständigen Fachberater Risikomanagement. Die Führungsaufsichtsstelle ' +
                'wird sich dann unverzüglich mit der Zentralstelle KURS im LKA Niedersachsen in Verbindung setzen. Im Falle eines Alkohol- oder Drogenabusus ist unverzüglich ' +
                'das weitere Vorgehen mit der Führungsaufsichtsstelle und der Fachberatung Risikomanagement abzustimmen. ' +
            '</p>',
        bw:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten, Verstöße gegen Weisungen und neue Straftaten unverzüglich ' + 
                'der Führungsaufsichtsstelle zu melden sind, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu überprüfen und entsprechend anzupassen.' +
            '</p>',
        bayern: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter</b>' +
                '</p><p>' +
                    'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. ' +
                    'Bei einem Verstoß gegen Auflagen und Weisungen ist der HEADS Ansprechpartner*in der Kriminalpolizeidienststelle unverzüglich der Bewährungshilfestelle, ' +
                    'dem zuständigen Polizeipräsidium und der Zentralstelle HEADS zur Mitteilung verpflichtet. Sofern es sich um einen Führungsaufsichtsproband*in handelt, ' +
                    'setzt die Bewährungshilfe dann in eigener Verantwortlichkeit die Führungsaufsichtsstelle in Kenntnis. (Ziffer 4.5 der Konzeption).' +
                '</p><p>' +
                    'Des Weiteren <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, wenn das gezeigte Verhalten ' +
                    '(Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) ' +
                    'Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
                '</p><p>' +  
                    '<b>Wichtig:</b> Gemäß Ziffer 4.3 der Konzeption HEADS ist bei <i>Gefahr im Verzug</i>  unverzüglich die für den Wohnort des*r Proband*in örtlich zuständigen Polizeidienststelle  zu verständigen, ' +
                    'die zunächst über die aktuell notwendigen Maßnahmen (Gefahrenabwehr/Strafverfolgung) entscheidet und die Kriminalpolizeidienststelle, das zuständige Polizeipräsidium ' +
                    'sowie die Zentralstelle HEADS zu benachrichtigen hat. ' +
                '</p><p>' +   
                    '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b> ' +
                '</p><p>' +    
                      'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 ' +
                      'die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist. ' +
                '</p><p>' +    
                    'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus dem gröblichen und beharrlichen Verstoß gegen die Weisungen ergibt, dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 2 StGB). ' +
                    '<i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB) um einen Widerruf zu vermeiden.' +
                '</p>'

    },

    'Ich habe gegen meine Weisungen verstoßen.': {

        description:
            '<p>' +
                '<i>Ggf. hat Ihr*e Klient*in angegeben, dass er*sie gegen seine*ihre Weisungen verstoßen hat</i>. Dies stellt ein deliktsnahes Verhalten dar, ' +
                'welches als Vorstufe für ein erneutes Sexualdelikt betrachtet werden kann. ' +
            '</p><p>' +
                'Beachten Sie, dass ein Verstoß gegen die Weisungen es erforderlich machen <i>kann</i>, dass das Gericht die Höchstdauer der Führungsaufsicht ' +
                'überschreitet und eine unbefristete Führungsaufsicht anordnet, wenn sich aus einem Verstoß gegen Weisungen nach § 68b Abs. 1 oder 2 StGB ' +
                'oder auf Grund anderer bestimmter Tatsachen <i>konkrete Anhaltspunkte</i> dafür ergeben, dass eine Gefährdung der Allgemeinheit durch die Begehung ' +
                'weiterer erheblicher Straftaten zu befürchten ist und gegen die verurteilte Person wegen Straftaten der in § 181b StGB genannten Art eine ' +
                'Freiheitsstrafe oder Gesamtfreiheitsstrafe von mehr als zwei Jahren verhängt oder die Unterbringung in einem psychiatrischen Krankenhaus ' +
                'oder einer Entziehungsanstalt angeordnet wurde (§ 68c Abs. 3 S. 1 Nr. 2 StGB). Ein Verstoß gegen die Weisung ist zudem über § 145a StGB ' +
                'strafbewehrt. <i>Möglich</i> ist ebenfalls eine Nachtragsentscheidung (§ 68d Abs. 1 StGB) durch das Gericht. Die Sachlage <i>kann</i> es jederzeit ' +
                'erfordern, neue Anweisungen zu erteilen, frühere Anweisungen aufzuheben oder veränderten Umständen anzupassen.' +
            '</p>',
        nds:
            '<p>' +
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe? </b>' +
            '</p><p>' +
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus dem gröblichen und beharrlichen Verstoß ' +
                'gegen die Weisungen ergibt, dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 2 StGB). <i>Ggf.</i> kommt auch eine ' +
                'weniger schwerwiegende Maßnahme in Betracht, wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention ' +
                '(§ 67h StGB), um einen Widerruf zu vermeiden.' +
            '</p><p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern (KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei verläuft, eine ' +
                'unverzügliche Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). Im Falle von Verstößen gegen ' +
                'Weisungen ergeht ebenfalls eine Meldung nachrichtlich an die zuständige Fachberaterin oder den zuständigen Fachberater Risikomanagement. ' +
            '</p><p>' +   
                'In Fällen der möglichen Gefährdung Dritter sieht die Konzeption nach einer Abwägung im Einzelfall eine zusätzliche Information der ' +
                'KURS-Sachbearbeiterinnen oder KURS-Sachbearbeiter vor. Dies <i>kann</i> bspw. unter den Voraussetzungen des § 34 StGB bei einer „gegenwärtigen, ' +
                'nicht anders abwendbaren Gefahr für Leben, Freiheit, Ehre, Eigentum oder ein anderes Rechtsgut“ der Fall sein, wenn das geschützte Interesse ' +
                'die datenschutzrechtliche Beeinträchtigung überwiegt. <i>Hier sind alle gesammelten Erkenntnisse in die Entscheidung miteinzubeziehen</i>.' +
            '</p>',
        bw:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten und Verstöße gegen Weisungen unverzüglich ' +
                'der Führungsaufsichtsstelle gemeldet werden müssen, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu ' +
                'überprüfen und entsprechend anzupassen.' +
            '</p>',
        bayern: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. ' +
                'Bei einem Verstoß gegen Auflagen und Weisungen ist der HEADS Ansprechpartner*in der Kriminalpolizeidienststelle unverzüglich der Bewährungshilfestelle, ' +
                'dem zuständigen Polizeipräsidium und der Zentralstelle HEADS zur Mitteilung verpflichtet. Sofern es sich um einen Führungsaufsichtsproband*in handelt, ' +
                'setzt die Bewährungshilfe dann in eigener Verantwortlichkeit die Führungsaufsichtsstelle in Kenntnis. (Ziffer 4.5 der Konzeption).' +
            '</p><p>' +
                'Des Weiteren <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, wenn das gezeigte Verhalten ' +
                '(Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) ' +
                'Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +  
                '<b>Wichtig:</b> Gemäß Ziffer 4.3 der Konzeption HEADS ist bei <i>Gefahr im Verzug</i>  unverzüglich die für den Wohnort des*r Proband*in örtlich zuständigen Polizeidienststelle  zu verständigen, ' +
                'die zunächst über die aktuell notwendigen Maßnahmen (Gefahrenabwehr/Strafverfolgung) entscheidet und die Kriminalpolizeidienststelle, das zuständige Polizeipräsidium ' +
                'sowie die Zentralstelle HEADS zu benachrichtigen hat. ' +
            '</p><p>' +   
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe?</b> ' +
            '</p><p>' +    
                    'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 ' +
                    'die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist. ' +
            '</p><p>' +    
                'Beachten Sie, dass ein Widerruf der Aussetzung der Maßregel (§ 67g StGB) <i>möglich</i> ist, wenn sich aus dem gröblichen und beharrlichen Verstoß gegen die Weisungen ergibt, dass der Zweck der Maßregel die Unterbringung erfordert (§ 67g Abs. 1 S. 1 Nr. 2 StGB). ' +
                '<i>Ggf.</i> kommt auch eine weniger schwerwiegende Maßnahme in Betracht, wie bspw. eine andere Ausgestaltung der Führungsaufsicht (§ 68d Abs. 1 StGB) oder eine Krisenintervention (§ 67h StGB) um einen Widerruf zu vermeiden.' +
            '</p>'

    } 


}

// export
export default { help_object }