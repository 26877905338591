<template>
    <div>

        <v-row v-if="loaded">

            <v-card
                color="grey lighten-5"
                flat
                height="auto"
                min-height="250px"
                tile
                width="100%"
                id="fooAnchor"
            >

                <v-toolbar flat short color="grey lighten-5">

                    <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp">
                        <v-icon color="primary" class="mr-3">far fa-check-circle</v-icon>
                        Abgeschlossene Übungen
                    </v-toolbar-title>

                    <v-spacer v-if="$vuetify.breakpoint.smAndUp" />

                    <v-spacer v-if="$vuetify.breakpoint.smAndUp" />

                    <v-autocomplete
                        v-model="search_archive"
                        :items="all_clients"
                        label="Übungen nach Klient*in durchsuchen"
                        prepend-icon="fas fa-search"
                        chips
                        deletable-chips
                        hide-details
                        small-chips
                    ></v-autocomplete>

                </v-toolbar>

                <v-divider />

                <v-list 
                    color="grey lighten-5"
                    v-if="filteredFinishedTasks.length > 0"
                >
                    <v-list-item-group>
                                                            

                        <v-list-item 
                            v-for="item in filteredFinishedTasks"
                            :key="item.id"
                            three-line
                            @click="openTask(item)"
                            
                        >

                            <v-list-item-avatar>
                                <v-icon v-if="checkIfActionNesseary(item)" color="primary">fas fa-redo</v-icon>
                                <v-icon v-else>fas fa-check</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title v-if="$vuetify.breakpoint.mdAndUp">
                                    <b>{{ get_short_question(item) }}</b><small > (Modul {{ item.current_module_nr}} - Sitzung {{ item.current_session_nr }} - Lektion {{ item.current_lession_nr}})</small>
                                </v-list-item-title>
                                <v-list-item-title v-else>
                                    <b>M {{ item.current_module_nr}} - S {{ item.current_session_nr }} - L {{ item.current_lession_nr}}</b>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <span class='primary--text font-weight-bold'>{{ convertedSender(item.latest_message.sender.username) }}</span> &mdash; Revision {{ item.latest_message.revision_number }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle class="caption">
                                    {{ get_converted_time(item.latest_message.sent_at) }}
                                </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                                <v-chip color="accent" class="black--text">{{ getChatPartnerName(item) }}</v-chip>
                            </v-list-item-icon>

                        </v-list-item>


                    </v-list-item-group>

                </v-list>

                <v-alert
                    v-else
                    icon="fas fa-info-circle"
                    color="primary"
                    
                    class="mt-3 mx-3"
                    outlined
                > 
                    <span v-if="search_archive !=null">Es existieren keine abgeschlossenen Übungen {{ get_gendered_client(search_archive) }} <i>{{search_archive}}</i>.</span>
                    <span v-else>Es existieren keine abgeschlossenen Übungen.</span>
                </v-alert>

            </v-card>

        </v-row>

    </div>

</template>

<script>
import CoachApi from '@/_api/coach_api'
import DateTimeHelpers from '@/_helpers/date_time_helpers'

export default {
    name: 'coach-tasks-closed',

    data: () => ({
        
        finished_tasks: [],
        loaded: false,
        search_archive: null,

        all_clients: []

    }),

    created() {

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

         CoachApi.GetCoachInfo().then(() => {

            CoachApi.GetCoachAllClientsData().then((resp) => {

                for (var i = 0; i < resp.data.length; i++) {

                    this.all_clients.push(resp.data[i].username)
                }

                CoachApi.GetFinishedGuidedTasks().then((resp) => {

                    this.finished_tasks = resp.data

                    if (this.$route.params.search_prop == 'null') {
                        this.search_archive = null
                    } else {
                        this.search_archive = this.$route.params.search_prop
                    }

                    this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                    this.loaded = true

                })

            })

        })

    },

    watch: {

        '$route' () {

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

            CoachApi.GetCoachInfo().then(() => {

                CoachApi.GetCoachAllClientsData().then((resp) => {

                    for (var i = 0; i < resp.data.length; i++) {

                        this.all_clients.push(resp.data[i].username)
                    }

                    CoachApi.GetFinishedGuidedTasks().then((resp) => {

                        this.finished_tasks = resp.data

                        if (this.$route.params.search_prop == 'null') {
                            this.search_archive = null
                        } else {
                            this.search_archive = this.$route.params.search_prop
                        }

                        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                        this.loaded = true

                    })

                })

            })

        },

        'search' () {

            if (this.search_archive == '' || this.search_archive == ' ' || this.search_archive == '  ') {
                this.search_archive = null
            }
        }

    },

    computed: {

        usergroup() {
            return this.$store.getters['store_auth/get_user_group']
        },

        filteredFinishedTasks() {

            var filtered = []

            if (this.search_archive != null) {

                for (var i=0; i < this.finished_tasks.length; i++) {
                    

                    this.finished_tasks[i].users.forEach(element => {

                        if (element.username === this.search_archive) {

                            filtered.push(this.finished_tasks[i]);

                        }

                    })

                }

            } else {

                filtered = this.finished_tasks

            }

            return filtered
            
        },

    },

    methods: {

        get_gendered_client(username) {

            var result = this.$store.getters['store_coach/get_coach_clients'].find(obj => {
                return obj.username === username
            })

            if (result.client_gender == 1) {
                return 'des Klienten'
            } else {
                return 'der Klientin'
            }
        },

        openTask(item) {

            this.$router.push({ name: 'coach-tasks-detailed', params: { task_id: item.id }})

        },

        get_short_question(item) {

            var tmp = JSON.parse(item.original_task_data)

            return tmp.wagtail_task_data.value.shortQuestion

        },

        getAttachmentName(att) {

            var tmp = att.split('/')

            return tmp[tmp.length - 1]

        },

        getChatPartnerName(item) {

            var partner = ''
            
            item.users.forEach(element => {

                if (element.username != this.$store.getters['store_auth/get_user_username']) {

                    partner = element.username

                } 
                
            });

            return partner

        },

        getClientUserId(item) {

            var id = null
            
            item.users.forEach(element => {

                if (element.username != this.$store.getters['store_auth/get_user_username']) {

                    id = element.id

                } 
                
            });

            return id

        },

        convertedSender(username) {

            if (username == this.$store.getters['store_auth/get_user_username']) {

                return 'Ich'

            } else {

                return username

            }


        },

        checkIfActionNesseary(item) {

            if (item.waiting_for_coach) {

                return true

            } else {

                return false
                
            }

        },

        get_converted_time(item) {

            return DateTimeHelpers.convertDatabaseDateTimeString('full', item)
        },

        getCorrectDateTime(item) {
            return DateTimeHelpers.convertDatabaseDateTimeString('full', item)
        },

        is_finished(current_chat) {

            if (current_chat.revision_finished) {
                return true
            } else {
                return false
            }
        },
        
    },

}
</script>

<style scoped>

</style>