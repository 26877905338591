import axios from 'axios'
import store from '@/store/store'
import router from '@/router'
import store_helpers from '@/_helpers/store_helpers'

import DateTimeHelper from '@/_helpers/date_time_helpers'


function error_handling(err) {

    if (err == 'Error: Network Error') {

        store.dispatch('store_auth/CHANGE_LOGIN_INFO', { login_info_type: "error", login_info: 'Leider ist der Server aktuell nicht erreichbar. Bitte versuchen Sie es in einigen Minuten erneut.'})

        // clear store
        store_helpers.ClearEntireStore()

        // redirect to login
        router.push('/login')

    } else if (err == 'Error: Request failed with status code 403') {

        var err_msg = 'Sie sind nicht berechtigt, diese Seite zu betrachten. Bitte geben Sie ihr Passwort und ihren Benutzernamen ein.'
        store.dispatch('store_auth/CHANGE_LOGIN_INFO', { login_info_type: "info", login_info: err_msg})

        // clear store
        store_helpers.ClearEntireStore()

        // redirect to login
        router.push('/login')

    } else if (err == 'Error: Request failed with status code 500') {

        err_msg = 'Error 500: Internal Server Error.'
        
        store.dispatch('store_auth/CHANGE_LOGIN_INFO', { login_info_type: "error", login_info: err_msg})

        // clear store
        store_helpers.ClearEntireStore()

        // redirect to login
        router.push('/login')
        
    }

}


const GetClientInfo = () => new Promise ((resolve) => {
     /**
    * Summary. Get all necessary data of a client.
    */

    const endpoint = 'client/client-info/'

    axios.get(endpoint)

    .then(resp => {

        store.dispatch('store_client/UPDATE_CLIENT', resp.data.client)
        store.dispatch('store_client/UPDATE_CLIENT_PROGRESS', resp.data.client_progress)
        store.dispatch('store_client/UPDATE_CLIENT_MESSAGES', resp.data.client_messages)
        store.dispatch('store_client/UPDATE_CLIENT_TASKS', resp.data.client_tasks)
        store.dispatch('store_client/UPDATE_CLIENT_QUESTIONNAIRES', resp.data.client_questionnaires)
        store.dispatch('store_client/UPDATE_CLIENT_COACH', resp.data.client_coach)
        store.dispatch('store_client/UPDATE_CLIENT_SUPERVISOR', resp.data.client_supervisor)
        store.dispatch('store_client/UPDATE_CLIENT_REWARD', resp.data.client_reward)
        store.dispatch('store_client/UPDATE_CLIENT_AWARD', resp.data.client_awards)
        store.dispatch('store_client/UPDATE_CLIENT_EMERGENCY_DATA', resp.data.client_emergency_data)

        /**
         * Create message that money was paid
         */

        if (resp.data.client_reward.money_paid) {
            store.dispatch('store_client/UPDATE_MONEY_HAS_PAYED', true) 
            store.dispatch('store_client/UPDATE_AMOUNT_MONEY', resp.data.client_reward.paid_money_amount)
        }

        /**
         * Create Messages if necessary
         */
        if (resp.data.client_messages.unread_message_count == 1) {

            store.dispatch('store_main_messages/UPDATE_MSG_TXT', 'Sie haben ' + resp.data.client_messages.unread_message_count + ' neue <a href="/client/messages/null">Nachricht</a>.')
            store.dispatch('store_main_messages/UPDATE_MSG_COLOR', 'error')
            store.dispatch('store_main_messages/UPDATE_MSG_ICON', 'fas fa-exclamation-circle')
            store.dispatch('store_main_messages/UPDATE_MSG_SHOW', true)

        } else if (resp.data.client_messages.unread_message_count > 1) {

            store.dispatch('store_main_messages/UPDATE_MSG_TXT', 'Sie haben ' + resp.data.client_messages.unread_message_count + ' neue <a href="/client/messages/null">Nachrichten</a>.')
            store.dispatch('store_main_messages/UPDATE_MSG_COLOR', 'error')
            store.dispatch('store_main_messages/UPDATE_MSG_ICON', 'fas fa-exclamation-circle')
            store.dispatch('store_main_messages/UPDATE_MSG_SHOW', true)

        } else {
            store.dispatch('store_main_messages/UPDATE_MSG_SHOW', false)
        }

        if (resp.data.client_tasks.unfinished_tasks_count == 1) {

            store.dispatch('store_main_messages/UPDATE_MSG_TASK_TXT', 'Sie haben ' + resp.data.client_tasks.unfinished_tasks_count + ' nicht bearbeitete <a href="/client/tasks">Übung</a>.')
            store.dispatch('store_main_messages/UPDATE_MSG_TASK_COLOR', 'error')
            store.dispatch('store_main_messages/UPDATE_MSG_TASK_ICON', 'fas fa-exclamation-circle')
            store.dispatch('store_main_messages/UPDATE_MSG_TASK_SHOW', true)
    
        } else if (resp.data.client_tasks.unfinished_tasks_count > 1) {

            store.dispatch('store_main_messages/UPDATE_MSG_TASK_TXT', 'Sie haben ' + resp.data.client_tasks.unfinished_tasks_count + ' nicht bearbeitete <a href="/client/tasks">Übungen</a>.')
            store.dispatch('store_main_messages/UPDATE_MSG_TASK_COLOR', 'error')
            store.dispatch('store_main_messages/UPDATE_MSG_TASK_ICON', 'fas fa-exclamation-circle')
            store.dispatch('store_main_messages/UPDATE_MSG_TASK_SHOW', true)
    
        } else {
            store.dispatch('store_main_messages/UPDATE_MSG_TASK_SHOW', false)
        }

        if (resp.data.client_questionnaires.unfinished_questionnaires_count == 1) {

            store.dispatch('store_main_messages/UPDATE_MSG_Q_TXT', 'Sie haben ' + resp.data.client_questionnaires.unfinished_questionnaires_count + ' nicht bearbeiteten <a href="/client/polls">Fragebogen</a>.')
            store.dispatch('store_main_messages/UPDATE_MSG_Q_COLOR', 'error')
            store.dispatch('store_main_messages/UPDATE_MSG_Q_ICON', 'fas fa-exclamation-circle')
            store.dispatch('store_main_messages/UPDATE_MSG_Q_SHOW', true)
    
        } else if (resp.data.client_questionnaires.unfinished_questionnaires_count > 1) {

            store.dispatch('store_main_messages/UPDATE_MSG_Q_TXT', 'Sie haben ' + resp.data.client_questionnaires.unfinished_questionnaires_count + ' nicht bearbeitete <a href="/client/polls">Fragebögen</a>.')
            store.dispatch('store_main_messages/UPDATE_MSG_Q_COLOR', 'error')
            store.dispatch('store_main_messages/UPDATE_MSG_Q_ICON', 'fas fa-exclamation-circle')
            store.dispatch('store_main_messages/UPDATE_MSG_Q_SHOW', true)
    
        } else {
            store.dispatch('store_main_messages/UPDATE_MSG_Q_SHOW', false)
        }

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)

    })

})

const GetLectionContent = data => new Promise ((resolve) => {

    var endpoint = ''

    if (store.getters['store_client/get_client'].client_group == 1) {
        endpoint = 'api-wagtail/lection-intervention/?module_nr=' + data.module_nr + '&session_nr=' + data.session_nr + '&lesson_rank=' + data.lesson_rank
    } else {
        endpoint = 'api-wagtail/lection-placebo/?module_nr=' + data.module_nr + '&session_nr=' + data.session_nr + '&lesson_rank=' + data.lesson_rank
    }

    axios.get(endpoint)

    .then(resp => {

        // check if more than one page
        if (resp.data.items.length > 1) {

            // check if dependent lection
            if (resp.data.items[0].dependent_lection == true) {

                // get data of dependent lection task
                var endpoint_2 = 'client/client-lection-data/?module_nr=' + resp.data.items[0].dependent_module_nr + '&session_nr=' + resp.data.items[0].dependent_session_nr + '&lession_nr=' + resp.data.items[0].dependent_lesson_nr

                axios.get(endpoint_2)

                .then(resp2 => {

                    if (resp2.data.length == 0 ) {

                        store.dispatch('store_lection/UPDATE_LECTION_WAGTAIL_CONTENT', resp2.data.items[0])

                        resolve(resp2)

                    } else {

                        // get all indices with true (choosen by the client)
                        var indexes = [], i;

                        for(i = 0; i < resp2.data[0].task_data[resp.data.items[0].dependent_dataIdentifier].checkbox.length; i++)

                            if (resp2.data[0].task_data[resp.data.items[0].dependent_dataIdentifier].checkbox[i] === true)

                                indexes.push(i);

                        // get index for this session based on session_nr
                        var correct_index = null

                        if (data.session_nr == 2) {

                            correct_index = indexes[0]

                        } else if (data.session_nr == 3) {

                            correct_index = indexes[1]
                            
                        } else {

                            correct_index = indexes[2]

                        }

                        // get correct label to serach for correct dependent_dataIdentifier_label
                        var correct_label = resp2.data[0].task_data[resp.data.items[0].dependent_dataIdentifier].labels[correct_index]

                        // find correct index for resp.data.items
                        var index_2 = [], j;

                        for(j = 0; j < resp.data.items.length; j++)

                            if (resp.data.items[j].dependent_dataIdentifier_label === correct_label)

                                index_2.push(j);

                        store.dispatch('store_lection/UPDATE_LECTION_WAGTAIL_CONTENT', resp.data.items[index_2])

                        resolve(resp2)

                    }

                })

                .catch(err => {

                    error_handling(err)
            
                })

            } else {

                store.dispatch('store_lection/UPDATE_LECTION_WAGTAIL_CONTENT', resp.data.items[0])

                resolve(resp)

            }

        } else {


            if (resp.data.meta.total_count == 0) {
                store.dispatch('store_lection/UPDATE_LECTION_WAGTAIL_CONTENT', []) 
            } else {
               store.dispatch('store_lection/UPDATE_LECTION_WAGTAIL_CONTENT', resp.data.items[0]) 
            }

            resolve(resp)

        } 

    })

    .catch(err => {

        error_handling(err)

    })


})

const GetInterventionInfo = data => new Promise ((resolve) => {
    /**
     * Summary. Get content of current lection.
     */

    var endpoint = 'api-wagtail/intervention-info?module_nr=' + data.module_nr + '&session_nr=' + data.session_nr

    axios.get(endpoint)

    .then(resp => {

        store.dispatch('store_lection/UPDATE_LECTION_INFO', resp.data)

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)

    })

})

const GetInterventionOverview = () => new Promise ((resolve) => {
    /**
     * Summary. Get content of current lection.
     */

    var endpoint = 'api-wagtail/intervention-overview'

    axios.get(endpoint)

    .then(resp => {

        store.dispatch('store_client/UPDATE_CLIENT_INTERVENTION_OVERVIEW', resp.data)

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)

    })

})

const CreateNewDetailedProgress = data => new Promise ((resolve) => {

    var endpoint = 'client/client-intervention-progress-detailed/'

    var formData = {
        "current_module_nr": data.module_nr,
        "current_session_nr": data.session_nr,
        "current_lession_nr": data.lession_nr,
        "lession_started": new Date()
      }

    axios.post(endpoint, formData)

    .then(resp => {

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })

})

const CheckIfDetailedProgressExists = data => new Promise ((resolve) => {

    var endpoint = 'client/client-intervention-progress-detailed/?module_nr=' + data.module_nr + '&session_nr=' + data.session_nr + '&lection_nr=' + data.lesson_rank

    axios.get(endpoint)

    .then(resp => {

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })

})

const PatchDetailedProgress = data => new Promise ((resolve) => {

    var endpoint = 'client/client-progress-detailed/update-finished/' + data.module_nr + '/' + data.session_nr + '/' + data.lession_nr
    

    var formData = {
        "current_module_nr": data.module_nr,
        "current_session_nr": data.session_nr,
        "current_lession_nr": data.lession_nr,
      }

    axios.patch(endpoint, formData)

    .then(resp => {

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })

})



const GetSavedLectionData = data => new Promise ((resolve) => {
    /**
     * Summary. Get content of current lection.
     */

    var endpoint = 'client/client-lection-data/?module_nr=' + data.module_nr + '&session_nr=' + data.session_nr + '&lession_nr=' + data.lesson_rank

    axios.get(endpoint)

    .then(resp => {

        /**
         * CREATE NEW LECTION DATA IF NOT EXISTING YET
         */

        // if no data is available yet in the database (client enters lession for the first time!)
        // in this case a default client-data array has to be build
        if (resp.data.length == 0 || resp.data[0].lession_data.data.length == 0 || resp.data[0].lession_data.data.length != store.getters['store_lection/get_lection_wagtail_content'].body.length) {

            var j;

            // basic default lession data
            var tmp = {
                lession_rank: data.lesson_rank, 
                lession_finished: false,
                data: []
            }

            for (j = 0; j < store.getters['store_lection/get_lection_wagtail_content'].body.length; j++) {
            
                var defaultData = {
                    type: store.getters['store_lection/get_lection_wagtail_content'].body[j].type,
                    finished: false,    // if client has finished the block
                    show: false,    // if block content is shown
                    btn_disabled: false,    // if save button is enabled
                }

                var defaultDataStartBlock = {
                    type: store.getters['store_lection/get_lection_wagtail_content'].body[j].type,
                    finished: false,    // if client has finished the block
                    show: true,    // if block content is shown
                    btn_disabled: false,    // if save button is enabled
                }

                if (j === 0) {

                    tmp.data.push(defaultDataStartBlock)

                } else {

                    tmp.data.push(defaultData)
                }
            }

            // save tmp
            store.dispatch('store_lection/UPDATE_SAVED_LECTION_DATA', tmp)

            /**
             * CREATE NEW TASK DATA IF NOT EXISTING YET
             */

            var i;

            for (i = 0; i < store.getters['store_lection/get_lection_wagtail_content'].body.length; i++) {

                // if there is a quiz
                if (store.getters['store_lection/get_lection_wagtail_content'].body[i].type == 'quiz') {

                    store.dispatch('store_lection/DEFINE_SAVED_LECTION_QUIZ_DATA_NEW_DATA_IDENTIFIER_TASKID', {'taskID': i})

                }

                // if there is a task
                if (store.getters['store_lection/get_lection_wagtail_content'].body[i].type == 'basicTask' || store.getters['store_lection/get_lection_wagtail_content'].body[i].type == 'optionalTask') {

                    store.dispatch('store_lection/DEFINE_SAVED_LECTION_TASK_DATA_NEW_DATA_IDENTIFIER', {'taskID': store.getters['store_lection/get_lection_wagtail_content'].body[i].value.taskID})

                    // define finised                   
                    store.dispatch('store_task/DEFINE_FINISHED_IDENTIFIER', {'taskID': store.getters['store_lection/get_lection_wagtail_content'].body[i].value.taskID})
                    store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': store.getters['store_lection/get_lection_wagtail_content'].body[i].value.taskID, 'property_name': 'finished', 'data': false})

                }

            }

            resolve(resp)

        } else {

            store.dispatch('store_lection/UPDATE_SAVED_LECTION_DATA', resp.data[0].lession_data)

            // task data
            store.dispatch('store_lection/UPDATE_SAVED_LECTION_TASK_DATA', resp.data[0].task_data)

            // if there is a task
            for (i = 0; i < store.getters['store_lection/get_lection_wagtail_content'].body.length; i++) {

                if (store.getters['store_lection/get_lection_wagtail_content'].body[i].type == 'basicTask' || store.getters['store_lection/get_lection_wagtail_content'].body[i].type == 'optionalTask') {

                    // define finised                   
                    store.dispatch('store_task/DEFINE_FINISHED_IDENTIFIER', {'taskID': store.getters['store_lection/get_lection_wagtail_content'].body[i].value.taskID})
                    store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': store.getters['store_lection/get_lection_wagtail_content'].body[i].value.taskID, 'property_name': 'finished', 'data': false})

                }

            }

            resolve(resp)

        }

    })

    .catch(err => {

        error_handling(err)
    
    })

})

/**
 * MONEY / Extrinsic Motivation
 */

const UpdateClientReward = () => new Promise((resolve) => {

    var endpoint = 'client/client-rewards/' + store.getters['store_client/get_client_reward'].reward_pk + '/'

    var formData = {
        current_coins: store.getters['store_client/get_client_reward'].current_coins,
        spended_coins: store.getters['store_client/get_client_reward'].spended_coins,
        current_euros: store.getters['store_client/get_client_reward'].current_euros,
        spended_euros: store.getters['store_client/get_client_reward'].spended_euros,
    }
    
    return axios.put(endpoint, formData)
    
    .then((resp)=>{ 

        resolve(resp)
        
    })
    .catch((error) => {

        error_handling(error)

    })
    
})

const UpdateMoneyPaidFlag = () => new Promise((resolve) => {

    var endpoint = 'client/client-update-money-paid-flag/'

    return axios.post(endpoint)
    
    .then((resp)=>{ 

        resolve(resp)
        
    })
    .catch((error) => {

        error_handling(error)

    })
    
})

const GetClientPaymentDetailed = () => new Promise ((resolve) => {
    /**
   * Summary. Get all necessary data of a client.
   */

   const endpoint = 'client/client-detailed-payment/'

   axios.get(endpoint)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

const ExchangeCoins = (coins) => new Promise ((resolve) => {
    /**
   * Summary. Get all necessary data of a client.
   */

   const endpoint = 'client/client-exchange-coins/' + coins

   axios.post(endpoint)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

const AddEuros = (euros) => new Promise ((resolve) => {
    /**
   * Summary. Get all necessary data of a client.
   */

   const endpoint = 'client/client-add-euros/' + euros

   axios.post(endpoint)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

/**
 * MESSAGES
 */

const GetThreadById = (thread_id) => new Promise ((resolve) => {
    /**
   * Summary. Get all finished guided tasks of the coach.
   */

  var endpoint = 'messages-api/get-thread-by-id/' + thread_id + '/' //-> Issue #385???

   axios.get(endpoint)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

const GetUserThreadsDeletedAPI = () => new Promise ((resolve) => {
    /**
   * Summary. Get all necessary data of a client.
   */

  var endpoint = 'messages-api/thread-view-deleted/'

   axios.get(endpoint)

   .then(resp => {

        if (resp.data.length != 0) {
            store.dispatch('store_messages/UPDATE_USER_THREADS_DELETED', DateTimeHelper.sortThreadByMessageDate(resp.data))
        } else {
            store.dispatch('store_messages/UPDATE_USER_THREADS_DELETED', resp.data)
        }

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

const GetUserThreadsInboxAPI = () => new Promise ((resolve) => {
    /**
   * Summary. Get all necessary data of a client.
   */

  var endpoint = 'messages-api/thread-view-inbox/'

   axios.get(endpoint)

   .then(resp => {

        if (resp.data.length != 0) {
            store.dispatch('store_messages/UPDATE_USER_THREADS', DateTimeHelper.sortThreadByMessageDate(resp.data))
        } else {
            store.dispatch('store_messages/UPDATE_USER_THREADS', resp.data)
        }

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

const SendMessage = (data) => new Promise((resolve) => {

    const formData = new FormData();

    formData.append("sent_at", DateTimeHelper.getDateTime());
    formData.append("content", data.newMessageValue);
    formData.append("sender", store.getters['store_client/get_client'].client_user_id);
    formData.append("thread", data.current_thread_id);
    

    formData.append("attachment1", data.attachmentFile1);
    formData.append("attachment2", data.attachmentFile2);
    formData.append("attachment3", data.attachmentFile3);

    var endpoint = 'messages-api/message-view/'

    axios.post(endpoint, formData)
    
    .then(()=>{ 

        resolve()
        
    })

    .catch((error) => {

        error_handling(error)

    })

})

const CreateNewThread = (data) => new Promise((resolve) => {


    var endpoint = 'messages-api/create-new-thread/'

    const formData = new FormData();

    formData.append("sent_at", DateTimeHelper.getDateTime());
    formData.append("content", data.content);
    formData.append("sender", store.getters['store_client/get_client'].client_user_id);
    formData.append("to_users", data.receiver);
    formData.append("subject", data.subject);
    formData.append('is_technical_help', data.is_technical_help)

    formData.append("attachment1", data.attachmentFile1);
    formData.append("attachment2", data.attachmentFile2);
    formData.append("attachment3", data.attachmentFile3);

    axios.post(endpoint, formData)
    
    .then(()=>{ 

        resolve()
        
    })

    .catch((error) => {

        error_handling(error)

    })

})

const ThreadToArchive = (data) => new Promise((resolve) => {

    var endpoint_1 = 'messages-api/user-thread-view/'

    var formData = {

        "deleted": true
    }

    axios.get(endpoint_1)
    
    .then((resp)=>{ 

        var correct_id_user_thread = null

        for (var i = 0; i < resp.data.length; i++) {

            if (resp.data[i].thread == data.current_thread_id) {

                correct_id_user_thread = resp.data[i].id

            }
        }


        var endpoint_2 = 'messages-api/user-thread-view/'  + correct_id_user_thread + '/'

        axios.patch(endpoint_2, formData)

        .then(()=> {

            resolve()

        })

        .catch((error) => {

            error_handling(error)
    
        })
        
    })

    .catch((error) => {

        error_handling(error)

    })

})

const UndoThreadToArchive = (data) => new Promise((resolve) => {

    var endpoint_1 = 'messages-api/user-thread-view/'

    var formData = {

        "deleted": false
    }

    axios.get(endpoint_1)
    
    .then((resp)=>{ 

        var correct_id_user_thread = null

        for (var i = 0; i < resp.data.length; i++) {

            if (resp.data[i].thread == data.current_thread_id) {

                correct_id_user_thread = resp.data[i].id

            }
        }


        var endpoint_2 = 'messages-api/user-thread-view/'  + correct_id_user_thread + '/'

        axios.patch(endpoint_2, formData)

        .then(()=> {

            resolve()

        })

        .catch((error) => {

            error_handling(error)
    
        })
        
    })

    .catch((error) => {

        error_handling(error)

    })

})



const SetThreadAsRead = (data) => new Promise((resolve) => {
    /**
     * Summary. Updates the current Thread as read.
     * */


    var endpoint_1 = 'messages-api/user-thread-view/'

    var formData = {

        "unread": false
    }

    axios.get(endpoint_1)
    
    .then((resp)=>{ 

        var correct_id_user_thread = null

        for (var i = 0; i < resp.data.length; i++) {

            if (resp.data[i].thread == data.current_thread_id) {

                correct_id_user_thread = resp.data[i].id

            }
        }


        var endpoint_2 = 'messages-api/user-thread-view/'  + correct_id_user_thread + '/'

        axios.patch(endpoint_2, formData)

        .then(()=> {

            resolve()

        })

        .catch((error) => {

            error_handling(error)
    
        })
        
    })

    .catch((error) => {

        error_handling(error)

    })


})


/**
 * 
 * 
 * DATA LECTION
 * 
 */

const SaveLectionData = data => new Promise ((resolve) => { 
    /**
     * Summary. Save lection data.
     */

    var endpoint = 'client/update-client-lesson-data/'

    var formData = {
        "module_nr": data.module_nr,
        "session_nr": data.session_nr,
        "lession_nr": data.lesson_rank,
        "lession_data": store.getters['store_lection/get_saved_lection_data'],
        "task_data": store.getters['store_lection/get_saved_lection_task_data']
      }

    axios.patch(endpoint, formData)

    .then(resp => {

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })

})

const SaveInterventionProgress = data => new Promise ((resolve) => {
    /**
     * Summary: Save the current progress of the client.
     */
    var endpoint = 'client/client-intervention-progress/' + store.getters['store_auth/get_user_username'] + '/'

    var formData = {
          "current_module_nr": data.module_nr,
          "current_session_nr": data.session_nr,
          "current_lession_nr": data.lesson_rank,
          "intervention_started": data.intervention_started,
          "intervention_finished": data.intervention_finished,
          "introduction_finished": data.introduction_finished,
        }

    axios.put(endpoint, formData)

    .then(resp => {

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })

})

/*
*
* ASSESSMENT
*
*/

const GetUnfinishedPolls = () => new Promise ((resolve) => {
    /**
   * Summary. Get all necessary data of a client.
   */

  var endpoint = 'client/client-polls-unfinished/'

   axios.get(endpoint)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

const GetFinishedPolls = () => new Promise ((resolve) => {
    /**
   * Summary. Get all necessary data of a client.
   */

  var endpoint = 'client/client-polls-finished/'

   axios.get(endpoint)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

const GetAssessmentInfo = data => new Promise ((resolve) => {


    var endpoint = 'api-wagtail/get-assessment/?module_nr=' + data.module + '&session_nr=' + data.session + '&lection_nr=' + data.lection + '&timepoint=' + data.timepoint

    axios.get(endpoint)

    .then(resp => {

        store.dispatch('store_assessment/UPDATE_ALL_ASSESSMENTS_IN_MODULE', resp.data.questionnaires)

        if (data.step != 0 && data.step <= resp.data.questionnaires.length) {

            
            store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_CONTENT', resp.data.questionnaires[data.step - 1])
            store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA', resp.data.questionnaires[data.step - 1].fields.data)

        }

        store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })
})

const GetSingleQuestionnaire = data => new Promise ((resolve) => {


    var endpoint = 'api-wagtail/get-single-questionnaire/?module_nr=' + data.module + '&session_nr=' + data.session + '&lection_nr=' + data.lection + '&name=' + data.name

    axios.get(endpoint)

    .then(resp => {

        store.dispatch('store_assessment/UPDATE_ALL_ASSESSMENTS_IN_MODULE', resp.data.questionnaires)

        store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_CONTENT', resp.data.questionnaires[0])
        store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA', resp.data.questionnaires[0].fields.data)


        store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })
})

const UpdateOpenAssessments = data => new Promise ((resolve) => {

    var endpoint = 'client/client-open-assessments-update/' + data.module + '/' + data.session + '/' + data.lection + '/' + data.timepoint + '/' + data.name

    axios.patch(endpoint)

    .then(resp => {      

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })
})


const SaveAssessmentData = data => new Promise ((resolve) => {

    var endpoint = ''

    if (data.name == 'aoo') {

        endpoint = 'q-api/aoo/'

    } else if (data.name == 'acute') {

        endpoint = 'q-api/acute/'

    } else if (data.name == 'bis') {

        endpoint = 'q-api/bis/'

    } else if (data.name == 'cmc') {

        endpoint = 'q-api/cmc/'

    } else if (data.name == 'cte') {

        endpoint = 'q-api/cte/'

    } else if (data.name == 'ctq') {

        endpoint = 'q-api/ctq/'

    } else if (data.name == 'cusi') {

        endpoint = 'q-api/cusi/'

    } else if (data.name == 'cvtrq') {

        endpoint = 'q-api/cvtrq/'

    } else if (data.name == 'ders') {

        endpoint = 'q-api/ders/'

    } else if (data.name == 'ekk') {

        endpoint = 'q-api/ekk/'

    } else if (data.name == 'esiq') {

        endpoint = 'q-api/esiq/'

    } else if (data.name == 'exsik') {

        endpoint = 'q-api/exsik/'

    } else if (data.name == 'fsozu') {

        endpoint = 'q-api/fsozu/'

    } else if (data.name == 'flashlight_pre') {

        endpoint = 'q-api/flashlight-pre/'

    } else if (data.name == 'flashlight_post') {

        endpoint = 'q-api/flashlight-post/'

    } else if (data.name == 'flexsik') {

        endpoint = 'q-api/flexsik/'

    } else if (data.name == 'hbi') {

        endpoint = 'q-api/hbi/'

    } else if (data.name == 'icd') {

        if (store.getters['store_client/get_client'].client_gender == 1) {
            endpoint = 'q-api/icd-male/'
        } else {
            endpoint = 'q-api/icd-female/'
        }

    } else if (data.name == 'kvm') {

        endpoint = 'q-api/kvm/'

    } else if (data.name == 'motsik') {

        endpoint = 'q-api/motsik/'

    } else if (data.name == 'narq') {

        endpoint = 'q-api/narq/'

    } else if (data.name == 'ors') {

        endpoint = 'q-api/ors/'

    } else if (data.name == 'phq') {

        endpoint = 'q-api/phq/'

    } else if (data.name == 'rcq') {

        endpoint = 'q-api/rcq/'

    } else if (data.name == 'sbv') {

        endpoint = 'q-api/sbv/'

    } else if (data.name == 'soi') {

        endpoint = 'q-api/soi/'

    } else if (data.name == 'spsi') {

        endpoint = 'q-api/spsi/'

    } else if (data.name == 'ssik') {

        endpoint = 'q-api/ssik/'

    } else if (data.name == 'sample_client') {

        endpoint = 'q-api/sample-client/'

    } else if (data.name == 'ucla') {

        endpoint = 'q-api/ucla/'

    } else if (data.name == 'utaut') {

        endpoint = 'q-api/utaut/'

    } else if (data.name == 'wai') {

        endpoint = 'q-api/wai/'

    } else if (data.name == 'wai_sv') {

        endpoint = 'q-api/wai-sv/'

    } else if (data.name == 'who') {

        endpoint = 'q-api/who/'

    }


    // formData
    var formData = Object.assign({}, store.getters['store_assessment/get_current_assessment_data'])

    formData['client'] = store.getters['store_client/get_client'].client_id
    formData['created_by'] = store.getters['store_client/get_client'].client_user_id
    formData['current_module_nr'] = data.module
    formData['current_session_nr'] = data.session
    formData['current_lesson_nr'] = data.lection

    axios.post(endpoint, formData,)

    .then(resp => {

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })
})


/**
 * HELP PAGES
 */

const GetHelpPages = () => new Promise ((resolve) => {

    var endpoint = 'api-wagtail/pages/?type=myTabu_CMS.MyTabuClientHelpPage&fields=body,tab_title&limit=50'

    axios.get(endpoint)

    .then(resp => {      

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })

})

/**
 * INTRODUCTION PAGES
 */

const GetIntroductionPages = (data) => new Promise ((resolve) => {

    var endpoint = ''

    endpoint = 'api-wagtail/pages/?type=myTabu_CMS.MyTabuIntroductionPartPage&fields=body,introduction_rank,subTitle'

    axios.get(endpoint)

    .then(resp => {

        var no_content = true

        for (var i = 0; i < resp.data.items.length; i++) {

            if (resp.data.items[i].introduction_rank == data.lesson_rank) {

                no_content = false

                store.dispatch('store_lection/UPDATE_LECTION_WAGTAIL_CONTENT', resp.data.items[i])

            }

        }

        if (no_content) {
            store.dispatch('store_lection/UPDATE_LECTION_WAGTAIL_CONTENT', []) 
        }

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })

})

const SaveIntroductionData = data => new Promise ((resolve) => { 
    /**
     * Summary. Save lection data.
     */

    var endpoint = 'client/update-client-lesson-data/'

    var formData = {
        "module_nr": 0,
        "session_nr": 0,
        "lession_nr": data,
        "lession_data": store.getters['store_introduction/get_current_page_finished_blocks'],
        "task_data": {}
      }

    axios.patch(endpoint, formData)

    .then(resp => {

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })

})

const GetSavedIntroductionData = data => new Promise ((resolve) => {
    /**
     * Summary. Get content of current lection.
     */

    var endpoint = 'client/client-lection-data/?module_nr=0&session_nr=0&lession_nr=' + data.lession_nr

    axios.get(endpoint)

    .then(resp => {

        /**
         * CREATE NEW LECTION DATA IF NOT EXISTING YET
         */

        // if no data is available yet in the database (client enters lession for the first time!)
        // in this case a default client-data array has to be build
        if (resp.data.length != 0) {

            store.dispatch('store_introduction/UPDATE_CURRENT_PAGE_FINISHED_BLOCKS', resp.data[0].lession_data)

        }

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })

})

/**
 * 
 * GUIDED TASKS
 * 
 */

const GetGuidedTaskData = data => new Promise ((resolve) => {
    /**
     * Summary. Get guided task data by taskID.
     */

    var endpoint = 'guided-tasks-api/get-guided-task-data/' + data + '/'

    return axios.get(endpoint)

    .then(resp => {

        store.dispatch('store_lection/DEFINE_SAVED_LECTION_GUIDED_TASK_DATA_NEW_DATA_IDENTIFIER', {'taskID': data})
        store.dispatch('store_lection/ADD_SAVED_LECTION_GUIDED_TASK_DATA_PROPERTY_AND_DATA', {'identifier': data, 'property_name': 'data', 'data': resp.data})
        

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })

})


const CreateNewGuidedTask = data => new Promise((resolve) => {
    
    /**
     * Summary. Creates a new guided task with a first message. Automatically creates necessary Guided Tasks for
     * all receiver of the message.
     * */

    var endpoint = 'guided-tasks-api/create-new-guided-task/'

    const formData = new FormData();

    formData.append("sent_at", DateTimeHelper.getDateTime());
    formData.append("content", data.content);
    formData.append("sender", store.getters['store_client/get_client'].client_user_id);
    formData.append("to_users", store.getters['store_client/get_client_coach'].id);
    formData.append("title", data.title);
    formData.append("current_module_nr", data.module_nr);
    formData.append("current_session_nr", data.session_nr);
    formData.append("current_lession_nr", data.lesson_nr);
    formData.append("original_task_id", data.task_id);
    formData.append("original_task_data", JSON.stringify(data.task_data));
    formData.append("revision_finished", 'False');
    formData.append("waiting_for_coach", 'True');
    formData.append("waiting_for_client", 'False');
    formData.append("revision_number", data.revision_nr);
    formData.append("current_task_data", null);

    formData.append("attachment1", data.attachmentFile1);
    formData.append("attachment2", data.attachmentFile2);
    formData.append("attachment3", data.attachmentFile3);
    
    return axios.post(endpoint, formData)

    .then((resp)=>{

        resolve(resp)
            
        
    })
    .catch((err)=>{

        error_handling(err)

    })
    
})

const GetUnfinishedGuidedTasks = () => new Promise ((resolve) => {
    /**
   * Summary. Get all un-finished guided tasks of coach.
   */

  var endpoint = 'guided-tasks-api/guided-task-view-unfinished/'

   axios.get(endpoint)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

const GetFinishedGuidedTasks = () => new Promise ((resolve) => {
    /**
   * Summary. Get all finished guided tasks of the coach.
   */

  var endpoint = 'guided-tasks-api/guided-task-view-finished/'

   axios.get(endpoint)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

const GetGuidedTaskById = (guided_task_id) => new Promise ((resolve) => {
    /**
   * Summary. Get all finished guided tasks of the coach.
   */

  var endpoint = 'guided-tasks-api/guided-task-view/' + guided_task_id + '/'

   axios.get(endpoint)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

     })

})

const UpdateGuidedTaskWaitingForCoach = (guided_task_id) => new Promise ((resolve) => {
   /*
    * Summary. Updates the waiting_for_coach field 'true' and waiting_for_client field to false.
    */

    var endpoint = 'guided-tasks-api/update-waiting-for-coach/' + guided_task_id

    var formData = {

        pk: guided_task_id

    }

    axios.patch(endpoint, formData)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})


const AddNewMessageToGuidedTask = (data) => new Promise ((resolve) => {
    /*
    * Summary. Adds a new message to an existing guided-task.
    */

   var endpoint = 'guided-tasks-api/message-guided-task-view/'

    const formData = new FormData();

    formData.append("sent_at", DateTimeHelper.getDateTime());
    formData.append("content", data.content);
    formData.append("sender", store.getters['store_client/get_client'].client_user_id);
    formData.append("guided_task", data.guided_task);
    formData.append("revision_number", data.revision_number);
    formData.append("current_task_data", data.current_task_data);
    

    formData.append("attachment1", data.attachmentFile1);
    formData.append("attachment2", data.attachmentFile2);
    formData.append("attachment3", data.attachmentFile3);

    axios.post(endpoint, formData)

   .then(resp => {

       resolve(resp)

   })

   .catch(err => {

       error_handling(err)

    })

})

/* 
* AWARDS
*/

const GetAwards = () => new Promise ((resolve) => {
    /*
     * Summary. Updates the waiting_for_coach field 'true' and waiting_for_client field to false.
     */
 
     var endpoint = 'client/client-avatar-awards/'
 
 
     axios.get(endpoint)
 
    .then(resp => {
 
        resolve(resp)
 
    })
 
    .catch(err => {
 
        error_handling(err)
 
    })
 
 })

 const UpdateAwardsEquipped = data => new Promise ((resolve) => {

    var endpoint = 'client/client-avatar-award-update-equipped/' + data.award_nr + '/' + data.bool_str

    axios.patch(endpoint)

    .then(resp => {      

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })
})

const UpdateAwardsGetted = data => new Promise ((resolve) => {

    var endpoint = 'client/client-avatar-award-update-getted/' + data.award_nr

    axios.patch(endpoint)

    .then(resp => {   

        resolve(resp)

    })

    .catch(err => {

        error_handling(err)
    
    })
})

const UpdateNewAwardFlag = () => new Promise((resolve) => {

    var endpoint = 'client/client-update-new-award-flag/'

    return axios.post(endpoint)
    
    .then((resp)=>{ 

        resolve(resp)
        
    })
    .catch((error) => {

        error_handling(error)

    })
    
})



export default {

    GetClientInfo,
    GetLectionContent,
    GetInterventionInfo,
    GetInterventionOverview,
    GetSavedLectionData,

    UpdateClientReward,
    GetClientPaymentDetailed,
    ExchangeCoins,
    AddEuros,
    UpdateMoneyPaidFlag,

    GetGuidedTaskData,
    CreateNewGuidedTask,
    GetUnfinishedGuidedTasks,
    GetFinishedGuidedTasks,
    GetGuidedTaskById,
    AddNewMessageToGuidedTask,
    UpdateGuidedTaskWaitingForCoach ,

    SaveLectionData,
    SaveInterventionProgress,
    CreateNewDetailedProgress,
    PatchDetailedProgress,
    CheckIfDetailedProgressExists,
    GetAssessmentInfo,
    SaveAssessmentData,
    UpdateOpenAssessments,

    SendMessage,
    SetThreadAsRead,
    CreateNewThread,
    ThreadToArchive,
    UndoThreadToArchive,
    GetUserThreadsDeletedAPI,
    GetUserThreadsInboxAPI,
    GetThreadById,

    GetHelpPages,
    GetIntroductionPages,
    SaveIntroductionData,
    GetSavedIntroductionData,

    GetUnfinishedPolls,
    GetFinishedPolls,
    GetSingleQuestionnaire,

    GetAwards,
    UpdateAwardsEquipped,
    UpdateAwardsGetted,
    UpdateNewAwardFlag


}