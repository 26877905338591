<template>
    <v-container fluid>

        <v-row v-if="$store.getters['store_client/get_client'].client_group == 1">

            <v-col cols="12" sm="12" v-if="!show_m3_s4_l4_t1">

                <v-card
                    outlined
                    
                >

                    <v-card-text class="primary--text title">
                        Skills
                    </v-card-text>

                    <v-card-text class="body-1">
                        Wenn Sie starke unangenehmen Gefühle wie Ärger, Trauer oder Angst haben, dann können Ihnen Skills helfen. Skills sind Verhaltensweisen 
                        gegen starke Anspannung. In einer Situation, in der wir starke unangenehme Gefühle haben, können sie uns ablenken. Sie haben sich im 
                        dritten Modul aufgeschrieben, welche Skills Ihnen helfen können und was Sie für diese Skills immer dabeihaben müssen. Sie können diese 
                        Skills anwenden, wenn Sie den Eindruck haben, dass Sie nicht mehr klar denken und entscheiden können.
                    </v-card-text>

                    <v-card-text>
                        <blockquote class="blockquote black--text note">
                            {{ emergency_data.m3_s4_l4_t1.textInput }}
                        </blockquote>
                    </v-card-text>

                </v-card>

            </v-col>

            <v-col cols="12" sm="12" v-if="!show_m3_s1_l7_t1">

                <v-card outlined>

                    <v-card-text class="primary--text title">
                        Bedürfnisse
                    </v-card-text>

                    <v-card-text class="body-1">
                        Im Modul 3 „Immer diese Gefühle“ haben Sie sich mit Ihren Bedürfnissen befasst. Ein Bedürfnis ist ein starker Wunsch oder ein Verlangen nach 
                        etwas. Alle Menschen haben Bedürfnisse. Wenn diese nicht erfüllt werden, kann es uns ziemlich schlecht gehen. Wir fahren schnell aus der Haut 
                        oder sind häufig schlecht gelaunt. Damit wir glücklich durch unser Leben gehen, ist es deswegen sinnvoll, dass wir unseren Bedürfnissen 
                        Beachtung schenken. Im dritten Modul haben Sie das Bedürfnis aufgeschrieben, welches Ihnen am wichtigsten ist und sich überlegt, wie Sie es 
                        erfüllen können. Wenn Sie bemerken, dass es Ihnen häufig nicht gut geht, könnte es Ihnen helfen, Ihr Bedürfnis stärker zu erfüllen.
                    </v-card-text>

                    <v-card-text>
                         <blockquote class="blockquote black--text note">
                            {{ emergency_data.m3_s1_l7_t1.textInput }}
                        </blockquote>
                    </v-card-text>

                </v-card>

            </v-col>

            <v-col cols="12" sm="12" v-if="!show_m2_s3_l5_t3">

                <v-card outlined>

                    <v-card-text class="primary--text title">
                        Meine (zukünftigen) persönlichen Bewährungshelfer*innen
                    </v-card-text>

                    <v-card-text class="body-1">
                        Diese Personen haben Sie in Modul 2 als Ihre (zukünftigen) persönlichen Bewährungshelfer*innen ausgewählt. Wenn Sie sich nicht gut fühlen, 
                        melden Sie sich doch bei diesen Personen. Führen Sie ein Gespräch am Telefon oder verabreden Sie sich mit ihnen. Gehen Sie mit ihnen 
                        spazieren, einen Kaffee trinken oder kochen Sie zusammen etwas Leckeres. Durch den Kontakt zu anderen Menschen werden Sie sich bestimmt 
                        besser fühlen.<br>
                        Wenn Sie noch keine persönlichen Bewährungshelfer*innen nennen konnten, da Sie keine Kontakte zu anderen Menschen haben, dann sprechen 
                        Sie mit Ihrem*Ihrer Bewährungshelfer*in über dieses Thema. Er*sie wird Ihnen ganz bestimmt ein paar gute Tipps geben können, wie Sie 
                        mehr Kontakt zu anderen aufbauen können.
                    </v-card-text>

                    <v-card-text>
                        <v-list>

                            <v-list-item
                                v-for="item in emergency_data.m2_s3_l5_t3.keywordList"
                                :key="item.name"
                            >
                                <v-list-item-icon>
                                    <v-icon color="accent">fas fa-user</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content class="font-weight-bold body-1">{{ item }}</v-list-item-content>
                            </v-list-item>

                        </v-list>
                    </v-card-text>

                </v-card>

            </v-col>

        </v-row>

        <v-row v-else>

            <v-col cols="12" sm="12">

                <v-card
                    outlined
                    
                >

                    <v-card-text class="primary--text title">
                        Tipps zum Umgang mit starken unangenehmen Gefühlen
                    </v-card-text>

                    <v-card-text class="body-1">
                        Es kann immer mal passieren, dass es uns nicht gut geht. Denn unangenehme Gefühle gehören genauso zum Leben wie angenehme Gefühle. 
                        Dennoch sind wir unseren Gefühlen nicht ausgeliefert, sondern können Sie durch unser Handeln steuern. Manchmal hilft es auch schon, 
                        sich für einen Moment abzulenken. Hier habe ich Ihnen ein paar Möglichkeiten aufgeschrieben, die vielen Menschen helfen mit starken 
                        unangenehmen Gefühlen umzugehen. Wenn Sie den Eindruck haben, nicht mehr klar denken zu können, probieren Sie doch eine oder mehrere 
                        davon.
                    </v-card-text>

                    <v-card-text>
                        <v-list>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="accent">far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content class="font-weight-bold body-1">In eine Zitrone oder Chilischote beißen.</v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="accent">far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content class="font-weight-bold body-1">Sport machen (z. B. Laufen oder Krafttraining).</v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="accent">far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content class="font-weight-bold body-1">Von 1000 in 7er Schritte rüsckwärts zählen.</v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>

                </v-card>

            </v-col>

        </v-row>

    </v-container>
</template>

<script>
//import ClientApi from '@/_api/client_api'
import _ from 'lodash'

export default {
    name: 'client-emergency-accent-emotion',

    data: () => ({
        //
    }),

    created() {

    },

    computed: {

        emergency_data() {

            return this.$store.getters['store_client/get_client_emergency_data']

        },

        show_m3_s4_l4_t1() {
            return _.isEmpty(this.emergency_data.m3_s4_l4_t1)
        },

        show_m3_s1_l7_t1() {
            return _.isEmpty(this.emergency_data.m3_s1_l7_t1)
        },

        show_m2_s3_l5_t3() {
            return _.isEmpty(this.emergency_data.m2_s3_l5_t3)
        }
    }

}
</script>

<style scoped>
.note {
  white-space: pre-line; 
  word-wrap: break-word;
  font-family: inherit;
  text-align: left !important;
}
</style>