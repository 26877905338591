<template>

    <v-row v-if="ready">

        <div class="timeline-item">

            <!-- icon and headline -->
            <v-row class="pa-0 ma-0">

                <v-col cols="12" sm="12" class="pa-0">

                    <v-row>

                        <v-avatar :v-show="$vuetify.breakpoint.mdAndDown" color="primary" size="50" class="elevation-5" style="top: 75px;">
                            <v-icon medium color="white">fas fa-graduation-cap</v-icon>
                        </v-avatar>

                        <div :class="!$vuetify.breakpoint.mdAndDown ? 'timeline-title' : 'timeline-title-mobile'">

                            <v-avatar v-if="thisFinished && data.guided == 'yes' && this.guided_task_data.revision_finished" color="success" size="40" class="elevation-5" id="cardbadge">
                                <v-icon small color="white">fas fa-check</v-icon>
                            </v-avatar>
                        
                            <v-avatar v-if="thisFinished && data.guided== 'yes' && !this.guided_task_data.revision_finished" color="accent" size="40" class="elevation-5" id="cardbadge">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon color="white" v-on="on">fas fa-redo-alt</v-icon>
                                    </template>
                                    <span>Diese Übung muss noch überprüft oder nochmals bearbeitet werden.</span>
                                </v-tooltip>
                            </v-avatar>

                            <v-avatar v-if="thisFinished && data.guided == 'no'" color="success" size="40" class="elevation-5" id="cardbadge">
                                <v-icon small color="white">fas fa-check</v-icon>
                            </v-avatar>

                            <v-avatar v-if="!thisFinished" color="error" size="40" class="elevation-5" id="cardbadge">
                                <v-icon small color="white">fas fa-pencil-alt</v-icon>
                            </v-avatar>

                            <v-card flat>

                                <v-expansion-panels flat>

                                    <v-expansion-panel :readonly="!thisShow">

                                        <v-expansion-panel-header class="pa-3 timeline-expansion-panel elevation-5">

                                            <v-card flat class="ml-3 mr-3">

                                                <div class="title primary--text">Zusätzliche Übung</div>
                                                <div class="headline font-weight-bold">{{ data.title }}</div>
                                                <div class="subtitle-1 primary--text">{{ data.subtitle }}</div>
                                                
                                            </v-card>

                                            <template v-slot:actions>
                                                <v-icon :color="thisShow ? 'primary' : 'grey'">$expand</v-icon>
                                            </template>

                                        </v-expansion-panel-header>

                                        <v-expansion-panel-content>

                                            <!-- content -->
                                            <v-row class="pa-0">
                                                
                                                <v-card flat color="transparent">

                                                    <!-- basic example component -->
                                                    <basic-task :data="data" :id='id' v-if="!thisFinished" />

                                                    <basicData :data="data" :guided_task_data="guided_task_data" :guided_finished="guided_task_data.revision_finished" :id='id' v-if="thisFinished && guided_task_data.revision_finished" />

                                                    <basic-task :data="data" :id='id' v-if="thisFinished && guided_task_data.revision_finished == undefined "/>


                                                    <!-- IF GUIDED TASK IN REVISION -->
                                                    
                                                    <div v-if="thisFinished && data.guided == 'yes' && !guided_task_data.revision_finished">

                                                        <!-- explanation -->
                                                        <v-row>
                                                            <v-col sm="12">
                                                                <v-card color="transparent" raised>
                                                                    <v-card-text>

                                                                        <div class="title  mb-2"><span><v-icon large class="mr-3">far fa-compass</v-icon></span>Ziel</div>

                                                                        <div 
                                                                            class="subtitle-1  mb-0" 
                                                                            v-html="data.goal"
                                                                        ></div>

                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>

                                                        <!-- instruction -->
                                                        <v-row>
                                                            <v-col sm="12">
                                                                <v-card color="transparent" raised>
                                                                    <v-card-text>
                                                                        
                                                                            
                                                                            <div class="title  mb-2"><span><v-icon large class="mr-3">far fa-clipboard</v-icon></span>Anleitung</div>

                                                                            <div 
                                                                                class="subtitle-1  mb-0" 
                                                                                v-html="data.instruction"
                                                                            ></div>

                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>

                                                        <!-- task -->
                                                        <v-row>

                                                            <v-col sm="12">

                                                                <v-card color="transparent" raised>
                                                                    <v-card-text>
                                                                            
                                                                        <div class="title  mb-2"><span><v-icon large class="mr-3">far fa-keyboard</v-icon></span>Übung</div>

                                                                        <v-row class="fill-height mx-1" align="center" justify="center"  style="min-height: 250px; background-color: #EEEEEE;">
                                                                            
                                                                                <v-col class="text-center">
                                                                                    
                                                                                    <v-progress-circular
                                                                                        :size="50"
                                                                                        color="primary"
                                                                                        indeterminate
                                                                                    ></v-progress-circular>

                                                                                    <div v-if="this.guided_task_data.waiting_for_client" class="mt-12 title">
                                                                                        <span v-if="$store.getters['store_client/get_client_coach'].coach_gender == 1">Ihr Coach wartet auf Ihre Rückmeldung...</span>
                                                                                        <span v-else>Ihre Coachin wartet auf Ihre Rückmeldung...</span>
                                                                                        <div class="mt-4">
                                                                                            <v-btn outlined rounded color="primary" @click="goToTasks()">Rückmeldung geben</v-btn>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div v-if="this.guided_task_data.waiting_for_coach" class="mt-12 title">
                                                                                        <span v-if="$store.getters['store_client/get_client_coach'].coach_gender == 1">Die Rückmeldung durch Ihren Coach steht noch aus...</span>
                                                                                        <span v-else>Die Rückmeldung durch Ihre Coachin steht noch aus...</span>
                                                                                        <div class="mt-4">
                                                                                            <v-btn outlined rounded color="primary" @click="messageToCoach()">
                                                                                                <span v-if="$store.getters['store_client/get_client_coach'].coach_gender == 1">Coach erinnern</span>
                                                                                                <span v-else>Coachin erinnern</span>
                                                                                            </v-btn>
                                                                                        </div>
                                                                                    </div>

                                                                                </v-col>
                                                                            </v-row>

                                                                        </v-card-text>

                                                                </v-card>

                                                            </v-col>

                                                        </v-row>

                                                        <!-- check -->
                                                        <v-row v-if="!$vuetify.breakpoint.mdAndDown">
                                                            <v-col sm="12" cols="12">
                                                                <v-card color="transparent" raised height="100%">
                                                                    <v-card-text>
                                                                        <v-row  align="center" class="ma-2">
                                                                            
                                                                            <v-icon medium>fas fa-pencil-alt</v-icon>

                                                                            <v-divider class="mx-4"/>

                                                                            <div class="overline" v-html="'Warten auf Rückmeldung durch Ihren Coach'" v-if="this.guided_task_data.waiting_for_coach && $store.getters['store_client/get_client_coach'].coach_gender == 1"></div>
                                                                            <div class="overline" v-html="'Warten auf Rückmeldung durch Ihre Coachin'" v-if="this.guided_task_data.waiting_for_coach && $store.getters['store_client/get_client_coach'].coach_gender == 2"></div>
                                                                            <div class="overline" v-html="'Ihr Coach wartet auf Ihre Rückmeldung'" v-if="this.guided_task_data.waiting_for_client && $store.getters['store_client/get_client_coach'].coach_gender == 1"></div>
                                                                            <div class="overline" v-html="'Ihre Coachin wartet auf Ihre Rückmeldung'" v-if="this.guided_task_data.waiting_for_client && $store.getters['store_client/get_client_coach'].coach_gender == 2"></div>


                                                                            <v-divider class="mx-4"/>

                                                                            <v-btn 
                                                                                rounded
                                                                                outlined 
                                                                                large 
                                                                                color="primary" 
                                                                                disabled
                                                                            >Fertig</v-btn>
                                                                        </v-row>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>

                                                        <v-row v-else>
                                                            <v-col sm="12" cols="12">
                                                                <v-card color="transparent" raised height="100%">
                                                                    <v-card-text>

                                                                        <div class="overline" v-html="'Warten auf Rückmeldung durch Ihren Coach'" v-if="this.guided_task_data.waiting_for_coach && $store.getters['store_client/get_client_coach'].coach_gender == 1"></div>
                                                                        <div class="overline" v-html="'Warten auf Rückmeldung durch Ihre Coachin'" v-if="this.guided_task_data.waiting_for_coach && $store.getters['store_client/get_client_coach'].coach_gender == 2"></div>
                                                                        <div class="overline" v-html="'Ihr Coach wartet auf Ihre Rückmeldung'" v-if="this.guided_task_data.waiting_for_client && $store.getters['store_client/get_client_coach'].coach_gender == 1"></div>
                                                                        <div class="overline" v-html="'Ihre Coachin wartet auf Ihre Rückmeldung'" v-if="this.guided_task_data.waiting_for_client && $store.getters['store_client/get_client_coach'].coach_gender == 2"></div>

                                                                        <v-row  align="center" class="ma-2">

                                                                            <v-icon medium>fas fa-pencil-alt</v-icon>

                                                                            <v-divider class="mx-4"/>

                                                                            <v-btn 
                                                                                rounded
                                                                                outlined 
                                                                                large 
                                                                                color="primary" 
                                                                                disabled
                                                                            >Fertig</v-btn>
                                                                        </v-row>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>

                                                    </div>

                                                </v-card>

                                            </v-row>

                                        </v-expansion-panel-content>

                                    </v-expansion-panel>

                                </v-expansion-panels>

                            </v-card>

                        </div>

                    </v-row>

                </v-col>

            </v-row>
     
        </div>
    </v-row>
</template>


<script>
import router from '@/router'

import ClientApi from '@/_api/client_api'

import basicTask from '@/components/_client/_intervention/_basicBlocks/basicTask.vue'
import basicData from '@/components/_client/_intervention/_basicBlocks/basicData.vue'

export default {
  name: 'optionalTask',

  props: ['data', 'id'],

    data () {

      return {

        show: false,

        ready: false,

      }

    },

    created() {

        this.ready = false

        if (this.data.guided == 'yes' && this.thisFinished) {

            ClientApi.GetGuidedTaskData(this.data.taskID).then(() => {

                this.ready = true

            })

        } else {

            this.ready = true

        }

        if (this.id == 0) {
            this.$store.dispatch('client_lession/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': this.id + 1, 'data': true})
        }

    },


    methods: {

        goToTasks() {
            router.push({name: 'tasks'})
        },

        messageToCoach() {
            router.push({name: 'client-new-messages'})
        },

    },

    computed: {

        guided_task_data() {

            if (this.data.guided == 'yes' && this.thisFinished && this.ready) {

                var tmp = this.$store.getters['store_lection/get_saved_lection_guided_task_data_by_identifier'](this.data.taskID)

                return tmp.data

            } else {

                return {}

            }

        },

        thisFinished () {
            var tmp = this.$store.getters['store_lection/get_saved_lection_data']
            return tmp.data[this.id].finished
        },

        thisShow () {
            var tmp = this.$store.getters['store_lection/get_saved_lection_data']
            return tmp.data[this.id].show
        },

        // add colors to css
        cssProps () {
            return {
                '--primary-color': this.$vuetify.theme.primary
            }
        },

    },

    components: {

        basicTask,
        basicData

    }
}
</script>

<style scoped>
.timeline-title {
  width: calc(100% - 68px);
  margin-left: 68px;
  position: relative;
}

.timeline-title-mobile {
  width: 100%;
  position: relative;
}

#cardbadge { 
  left: -20px;
  top: -20px; 
  position: absolute;
  z-index: 2;
}

#timeline .timeline-item {
  margin-bottom: 10px;
  position: relative;
  width: 100%;
}

#timeline .timeline-item .timeline-content {
  width: calc(100% - 68px);
  margin-left: 68px;
}

#timeline .timeline-item .timeline-content-optional {
  width: 100%;
  margin-left: 0px;
}

#timeline-mobile .timeline-item {
  margin-bottom: 10px;
  position: relative;
  width: 100%;
}

#timeline-mobile .timeline-item .timeline-content {
  width: 100%;
}

.timeline-expansion-panel {
  border-style: solid;
  border-width: 1px;
  border-radius: 5px !important;
  border-color: #004b8c !important;
  padding-left: 5px;
  width: 100%;
}

.v-expansion-panel-content >>> .v-expansion-panel-content__wrap { 
    padding: 0 12px 16px;
}
</style>

