<template>

    <v-card flat>

      <v-card-text>
        <div class="mb-3">Meine Antwort:</div>

        <v-card outlined>

          <v-card-text class="body-1">
            {{ data.textInput }}
          </v-card-text>

        </v-card>
        
      </v-card-text>

    </v-card>

</template>

<script>
export default {
    name: 'freeTextFormData',
    props: ['data'],

}   
</script>

<style scoped>

</style>