<template>
    <v-container fluid>

        <v-row>

            <v-col
                cols="12"
                sm="4"
            >
                <v-card
                    class="pa-2"
                    height="100%"
                    outlined
                    to="/client/emergency/accent/emotion"
                    v-if="show_card_emotion"
                >

                    <v-card-text>
                        <v-row align="center" justify="center">
                            <v-icon size="80" color="primary">far fa-frown</v-icon>
                        </v-row>
                    </v-card-text>

                    <v-card-text>
                        <v-row align="center" justify="center" class="title">
                            Ich fühle mich nicht gut.
                        </v-row>
                    </v-card-text>

                    <v-card-text>
                        <v-row align="center" justify="center" v-if="$store.getters['store_client/get_client'].client_group == 1">
                            Es kann immer mal passieren, dass es uns nicht gut geht. Unangenehme Gefühle gehören genauso zum Leben wie angenehme Gefühle. 
                            Aber wir sind unseren Gefühlen nicht ausgeliefert, sondern können Sie durch unser Handeln steuern. Schauen Sie sich gerne an, 
                            was Sie dazu bislang gemacht haben.
                        </v-row>

                        <v-row align="center" justify="center" v-if="$store.getters['store_client/get_client'].client_group == 2">
                            Es kann immer mal passieren, dass es uns nicht gut geht. Denn unangenehme Gefühle gehören genauso zum Leben wie angenehme Gefühle. 
                            Dennoch sind wir unseren Gefühlen nicht ausgeliefert, sondern können Sie durch unser Handeln steuern. Manchmal hilft es auch schon, 
                            sich für einen Moment abzulenken. Hier habe ich Ihnen ein paar Möglichkeiten aufgeschrieben, die vielen Menschen helfen mit starken 
                            unangenehmen Gefühlen umzugehen.
                        </v-row>
                    </v-card-text>

                </v-card>

                <v-card
                    class="pa-2"
                    height="100%"
                    outlined
                    v-else
                >

                    <v-card-text>
                        <v-row align="center" justify="center">
                            <v-icon size="80" color="grey lighten-1">far fa-frown</v-icon>
                        </v-row>
                    </v-card-text>

                    <v-card-text>
                        <v-row align="center" justify="center" class="grey--text text--lighten-1 title">
                            Ich fühle mich nicht gut.
                        </v-row>
                    </v-card-text>

                    <v-card-text>
                        <v-row align="center" justify="center" class="grey--text text--lighten-1">
                            Es kann immer mal passieren, dass es uns nicht gut geht. Unangenehme Gefühle gehören genauso zum Leben wie angenehme Gefühle. 
                            Aber wir sind unseren Gefühlen nicht ausgeliefert, sondern können Sie durch unser Handeln steuern. Schauen Sie sich gerne an, 
                            was Sie dazu bislang gemacht haben.
                        </v-row>
                    </v-card-text>

                </v-card>
            </v-col>

            <v-col
                cols="12"
                sm="4"
            >

                <v-card
                    class="pa-2"
                    height="100%"
                    outlined
                    to="/client/emergency/accent/control"
                    v-if="show_card_control"
                >

                    <v-card-text>
                        <v-row align="center" justify="center">
                            <v-icon size="80" color="primary">fas fa-poo-storm</v-icon>
                        </v-row>
                    </v-card-text>

                    <v-card-text>
                        <v-row align="center" justify="center" class="title">
                            Ich verliere die Kontrolle über sexuelle Fantasien oder sexuelles Verhalten.
                        </v-row>
                    </v-card-text>

                    <v-card-text>
                        <v-row align="center" justify="center" v-if="$store.getters['store_client/get_client'].client_group == 1">
                            Sie haben in Modul 6 erarbeitet, dass Ihr Risiko für erneute Straftaten steigen kann, wenn Sie die Kontrolle über 
                            Ihre sexuellen Gedanken/Fantasien und Handlungen verlieren. Es kann z.B. sein, dass Sie die Kontrolle über Ihre sexuellen 
                            Fantasien (über Kinder) verlieren. Es kann auch passieren, dass Sie kurz davor stehen, eine erneute sexuelle Straftat zu begehen. 
                            Im Folgenden finden Sie Wege, um in solchen Situationen sexuelle Gedanken/Fantasien und Handlungen besser im Griff zu haben.
                        </v-row>

                        <v-row align="center" justify="center" v-if="$store.getters['store_client/get_client'].client_group == 2">
                            Ihr Risiko für erneute Straftaten kann steigen, wenn Sie die Kontrolle über Ihre sexuellen Gedanken und Handlungen verlieren. Ganz 
                            besonders gefährlich wird es natürlich, wenn Sie die Kontrolle über Ihre sexuellen Fantasien über Kinder verlieren. Ich habe Ihnen 
                            hier Wege, die anderen Menschen helfen, sexuelle Gedanken und Fantasien besser zu kontrollieren, aufgeschrieben. 
                        </v-row>
                    </v-card-text>

                </v-card>

                <v-card
                    class="pa-2"
                    height="100%"
                    outlined
                    v-else
                >

                    <v-card-text>
                        <v-row align="center" justify="center">
                            <v-icon size="80" color="grey-lighten-1">fas fa-poo-storm</v-icon>
                        </v-row>
                    </v-card-text>

                    <v-card-text>
                        <v-row align="center" justify="center" class="grey--text text--lighten-1 title">
                            Ich verliere die Kontrolle über sexuelle Fantasien oder sexuelles Verhalten.
                        </v-row>
                    </v-card-text>

                    <v-card-text>
                        <v-row align="center" justify="center" class="grey--text text--lighten-1">

                            Sie haben in Modul 6 erarbeitet, dass Ihr Risiko für erneute Straftaten steigen kann, wenn Sie die Kontrolle über Ihre sexuellen Gedanken 
                            und Handlungen verlieren. Ganz besonders gefährlich wird es natürlich, wenn Sie die Kontrolle über Ihre sexuellen Fantasien über Kinder 
                            verlieren. Im Folgenden finden Sie Wege, wie Sie sexuelle Gedanken/Fantasien und Handlungen besser kontrollieren können.

                        </v-row>
                    </v-card-text>

                </v-card>

            </v-col>

            <v-col
                cols="12"
                sm="4"
            >

                <v-card
                    class="pa-2"
                    height="100%"
                    outlined
                    to="/client/emergency/accent/crime"
                    v-if="show_card_crime"
                >

                    <v-card-text>
                        <v-row align="center" justify="center">
                            <v-icon size="80" color="primary">fas fa-cloud-rain</v-icon>
                        </v-row>
                    </v-card-text>

                    <v-card-text>
                        <v-row align="center" justify="center" class="title">
                            Ich weiß nicht, warum ich keine Straftaten begehen soll.
                        </v-row>
                    </v-card-text>

                    <v-card-text>
                        <v-row align="center" justify="center" v-if="$store.getters['store_client/get_client'].client_group == 1">
                            Es kann passieren, dass Sie in Situationen kommen, in denen es Ihnen schwer fällt straffrei zu bleiben. Vielleicht wissen Sie nicht, 
                            warum Sie kein Kind missbrauchen oder Missbrauchsabbildungen ansehen sollten. Sie haben sich innerhalb von @myTabu Dinge überlegt, 
                            die Ihnen in diesen Situationen helfen können. Schauen Sie sich gerne Ihre Gedanken dazu an.
                        </v-row>

                        <v-row align="center" justify="center" v-if="$store.getters['store_client/get_client'].client_group == 2">
                            Es kann passieren, dass Sie in Situationen kommen, in denen es Ihnen schwer fällt straffrei zu bleiben und Sie nicht mehr wissen, 
                            warum Sie kein Kind missbrauchen oder Missbrauchsabbildungen ansehen sollen. Auch frühere Teilnehmer von @myTabu haben sich manchmal 
                            gefragt, warum sie keine Straftaten mehr begehen möchten. Ich habe Ihnen hier einige der Gründe, die diese Menschen für sich gefunden 
                            haben, aufgeschrieben. 
                        </v-row>
                    </v-card-text>

                </v-card>

                <v-card
                    class="pa-2"
                    height="100%"
                    outlined
                    v-else
                >

                    <v-card-text>
                        <v-row align="center" justify="center">
                            <v-icon size="80" color="grey-lighten-1">fas fa-cloud-rain</v-icon>
                        </v-row>
                    </v-card-text>

                    <v-card-text>
                        <v-row align="center" justify="center" class="grey--text text--lighten-1 title">
                            Ich weiß nicht, warum ich keine Straftaten begehen soll.
                        </v-row>
                    </v-card-text>

                    <v-card-text>
                        <v-row align="center" justify="center" class="grey--text text--lighten-1">
                            Es kann passieren, dass Sie in Situationen kommen, in denen es Ihnen schwer fällt straffrei zu bleiben. Vielleicht wissen Sie nicht, 
                            warum Sie kein Kind missbrauchen oder Missbrauchsabbildungen ansehen sollten. Sie haben sich innerhalb von @myTabu Dinge überlegt, 
                            die Ihnen in diesen Situationen helfen können. Schauen Sie sich gerne Ihre Gedanken dazu an.
                        </v-row>
                    </v-card-text>

                </v-card>

            </v-col>

        </v-row>

    </v-container>
</template>

<script>
//import ClientApi from '@/_api/client_api'
import _ from 'lodash'

export default {
    name: 'client-emergency-accent',

    data: () => ({
        //
    }),

    created() {


    },

    computed: {

        emergency_data() {

            return this.$store.getters['store_client/get_client_emergency_data']

        },

        show_card_emotion() {

            if (this.$store.getters['store_client/get_client'].client_group == 2) {
                return true
            } else {

                if (!_.isEmpty(this.emergency_data.m3_s4_l4_t1) || !_.isEmpty(this.emergency_data.m3_s1_l7_t1) || !_.isEmpty(this.emergency_data.m2_s3_l5_t3)) {
                    return true
                } else {
                    return false
                }

            }

        },

        show_card_control() {

            if (this.$store.getters['store_client/get_client'].client_group == 2) {
                return true
            } else {
                if (!_.isEmpty(this.emergency_data.m6_s1_l5_t1) || !_.isEmpty(this.emergency_data.m6_s1_l5_t2) || !_.isEmpty(this.emergency_data.m6_s2_l4_t1) || !_.isEmpty(this.emergency_data.m6_s3_l4_t2) || !_.isEmpty(this.emergency_data.m6_s4_l5_t1)) {
                    return true
                } else {
                    return false
                }
            }
        },

        show_card_crime() {

            if (this.$store.getters['store_client/get_client'].client_group == 2) {
                return true
            } else {
                if (!_.isEmpty(this.emergency_data.helpful_thought_1) || !_.isEmpty(this.emergency_data.helpful_thought_2) || !_.isEmpty(this.emergency_data.helpful_thought_3) || !_.isEmpty(this.emergency_data.m1_s4_l4_t2)) {
                    return true
                } else {
                    return false
                }
            }
        }

    }

}
</script>

<style scoped>

</style>