<template>
    <v-container fluid class="py-0 fill-height" v-if="post">

        <!-- Title -->
        <v-row class="mb-5 pa-0">
            <h1 class="display-2 font-weight-bold primary--text">Das Online-Programm im Überblick</h1>
        </v-row>

        <!-- Sub-Title -->
        <v-row class="mb-3 pt-0">
            <h1 class="display-1">Module, Sitzungen und Lektionen</h1>
        </v-row>

        <!-- Modules -->
        <v-row 
            v-for="(module, i) in content_overview"
            :key="i"
            :id="get_correct_id(module)"
            class="pa-0 ma-0"
        >

            <v-col cols="12" sm="12" class="pa-0">

                <v-row>

                    <v-avatar :v-show="$vuetify.breakpoint.mdAndDown" color="primary" size="50" class="elevation-5" style="top: 70px;">
                        <span class="white--text headline">{{ i + 1 }}</span>
                    </v-avatar>

                    <div :class="!$vuetify.breakpoint.mdAndDown ? 'timeline-title' : 'timeline-title-mobile'">

                        <v-avatar v-if="module.finished" color="success" size="40" class="elevation-5" id="cardbadge">
                            <v-icon small color="white">fas fa-check</v-icon>
                        </v-avatar>

                        <v-avatar v-else color="error" size="40" class="elevation-5" id="cardbadge">
                            <v-icon small color="white">fas fa-pencil-alt</v-icon>
                        </v-avatar>

                        <v-card flat>

                            <v-expansion-panels flat v-model="module_panel[i]">

                                <v-expansion-panel>

                                    <v-expansion-panel-header class="pa-3 timeline-expansion-panel elevation-5">

                                        <v-card flat class="ml-3 mr-3">

                                            <div class="title primary--text">Modul {{ i + 1 }}</div>
                                            <div class="headline font-weight-bold">{{ module.title }}</div>
                                            
                                        </v-card>

                                        <template v-slot:actions>
                                            <v-icon color="primary">$expand</v-icon>
                                        </template>

                                    </v-expansion-panel-header>

                                    <v-expansion-panel-content>

                                        <!-- SESSIONS -->
                                        <v-row 
                                            v-for="(session, j) in module.sessions"
                                            :key="j"
                                        >

                                            <v-col cols="12" sm="12" class="ma-0 pa-0">

                                                <v-row class="ma-0 pa-0">

                                                    <div class="timeline-session-title">

                                                        <v-avatar v-if="session.finished" color="success" size="40" class="elevation-5" id="cardbadge">
                                                            <v-icon small color="white">fas fa-check</v-icon>
                                                        </v-avatar>

                                                        <v-avatar v-else :color="session.current ? 'error':'grey'" size="40" class="elevation-5" id="cardbadge">
                                                            <v-icon small :color="session.current ? 'white':'black'">fas fa-pencil-alt</v-icon>
                                                        </v-avatar>
                                
                                                        <v-card flat>

                                                            <v-expansion-panels flat v-model="session_panels[i][j]">

                                                                <v-expansion-panel :readonly="disable_session_btn(session)">

                                                                    <v-expansion-panel-header class="pa-3 timeline-session-expansion-panel elevation-5">

                                                                        <v-card flat class="ml-3 mr-3">

                                                                            <div class="title primary--text">Sitzung {{ j + 1 }}</div>
                                                                            <div class="headline font-weight-bold inline">{{ session.title }}</div>
                                                                            
                                                                        </v-card>

                                                                        <template v-slot:actions>
                                                                            <v-icon :color="!disable_session_btn(session) ? 'primary' : 'grey'">$expand</v-icon>
                                                                        </template>

                                                                    </v-expansion-panel-header>

                                                                    <v-expansion-panel-content>

                                                                        <!-- LECTIONS -->
                                                                        <div class="timeline-content mt-3">

                                                                            <v-row
                                                                                v-for="(lection, k) in session.lections"
                                                                                :key="k"
                                                                            >

                                                                            <v-col cols="12" sm="12" class="px-0">

                                                                                <v-card flat raised class="timeline-session-expansion-panel">

                                                                                    <v-card-text class="mx-0 px-0">

                                                                                        <v-row align="center" class="ma-2" v-if="!$vuetify.breakpoint.mdAndDown">

                                                                                            <v-btn 
                                                                                                v-if="!$vuetify.breakpoint.mdAndDown && !lection.finished"
                                                                                                outlined 
                                                                                                fab 
                                                                                                color="error"
                                                                                                v-on:click="goToLection(module.rank, session.rank, lection.rank)"
                                                                                                :disabled="!lection.current || !$store.getters['store_client/get_client_progress'].introduction_finished"
                                                                                                small
                                                                                            >
                                                                                                <v-icon small>fas fa-pencil-alt</v-icon>
                                                                                            </v-btn>

                                                                                            <v-btn 
                                                                                                v-if="!$vuetify.breakpoint.mdAndDown && lection.finished"
                                                                                                outlined
                                                                                                fab 
                                                                                                color="success"
                                                                                                v-on:click="goToLection(module.rank, session.rank, lection.rank)"
                                                                                                small>
                                                                                                <v-icon small>fas fa-check</v-icon>
                                                                                            </v-btn>  

                                                                                            <div :class="!$vuetify.breakpoint.mdAndDown ? 'title primary--text ml-3' : 'title primary--text ml-1'">
                                                                                                {{lection.title}}
                                                                                            </div>

                                                                                            <v-divider class="mx-3" v-if="!$vuetify.breakpoint.mdAndDown" />

                                                                                            <v-btn 
                                                                                                :class="!$vuetify.breakpoint.mdAndDown ? '' : 'mt-3'"
                                                                                                v-if="!lection.finished"
                                                                                                outlined 
                                                                                                rounded
                                                                                                color="primary"
                                                                                                v-on:click="goToLection(module.rank, session.rank, lection.rank)"
                                                                                                :disabled="!lection.current || !$store.getters['store_client/get_client_progress'].introduction_finished"
                                                                                            >
                                                                                                Bearbeiten
                                                                                            </v-btn>

                                                                                            <v-btn 
                                                                                                :class="!$vuetify.breakpoint.mdAndDown ? '' : 'mt-3'"
                                                                                                v-else
                                                                                                outlined 
                                                                                                rounded
                                                                                                color="primary"
                                                                                                v-on:click="goToLection(module.rank, session.rank, lection.rank)"
                                                                                            >
                                                                                                Ansehen
                                                                                            </v-btn>  

                                                                                        </v-row>


                                                                                        <div v-else class="ma-2">

                                                                                            <v-btn 
                                                                                                v-if="!$vuetify.breakpoint.mdAndDown && !lection.finished"
                                                                                                outlined 
                                                                                                fab 
                                                                                                color="error"
                                                                                                v-on:click="goToLection(module.rank, session.rank, lection.rank)"
                                                                                                :disabled="!lection.current || !$store.getters['store_client/get_client_progress'].introduction_finished"
                                                                                                small
                                                                                            >
                                                                                                <v-icon small>fas fa-pencil-alt</v-icon>
                                                                                            </v-btn>

                                                                                            <v-btn 
                                                                                                v-if="!$vuetify.breakpoint.mdAndDown && lection.finished"
                                                                                                outlined
                                                                                                fab 
                                                                                                color="success"
                                                                                                v-on:click="goToLection(module.rank, session.rank, lection.rank)"
                                                                                                small>
                                                                                                <v-icon small>fas fa-check</v-icon>
                                                                                            </v-btn>  

                                                                                            <div :class="!$vuetify.breakpoint.mdAndDown ? 'title primary--text ml-3' : 'title primary--text ml-1'">
                                                                                                {{lection.title}}
                                                                                            </div>

                                                                                            <v-divider class="mx-3" v-if="!$vuetify.breakpoint.mdAndDown" />

                                                                                            <v-btn 
                                                                                                :class="!$vuetify.breakpoint.mdAndDown ? '' : 'mt-3'"
                                                                                                v-if="!lection.finished"
                                                                                                outlined 
                                                                                                rounded
                                                                                                color="primary"
                                                                                                v-on:click="goToLection(module.rank, session.rank, lection.rank)"
                                                                                                :disabled="!lection.current || !$store.getters['store_client/get_client_progress'].introduction_finished"
                                                                                            >
                                                                                                Bearbeiten
                                                                                            </v-btn>

                                                                                            <v-btn 
                                                                                                :class="!$vuetify.breakpoint.mdAndDown ? '' : 'mt-3'"
                                                                                                v-else
                                                                                                outlined 
                                                                                                rounded
                                                                                                color="primary"
                                                                                                v-on:click="goToLection(module.rank, session.rank, lection.rank)"
                                                                                            >
                                                                                                Ansehen
                                                                                            </v-btn>  

                                                                                        </div>

                                                                                    </v-card-text>

                                                                                </v-card>

                                                                            </v-col>

                                                                        </v-row>

                                                                    </div>   

                                                                    </v-expansion-panel-content>

                                                                </v-expansion-panel>

                                                            </v-expansion-panels>

                                                        </v-card>

                                                    </div>

                                                </v-row>

                                            </v-col>

                                        </v-row>
                        

                                    </v-expansion-panel-content>

                                </v-expansion-panel>

                            </v-expansion-panels>

                        </v-card>

                    </div>

                </v-row>

            </v-col>

        </v-row>

    </v-container>
</template>

<script>
import ClientApi from '@/_api/client_api'

export default {
    name: 'client-overview',

    data: () => ({

        post: false,

        content_overview: [],

        module_panel: [1, 1, 1, 1, 1, 1],

        session_panels: [[1, 1, 1, 1], [1, 1, 1, 1], [1, 1, 1, 1], [1, 1, 1, 1], [1, 1, 1, 1], [1, 1, 1, 1]]
        
    }),

    created() {

        this.post = false

        this.content_overview = [],

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        ClientApi.GetInterventionOverview().then(() => {

            this.content_overview = this.$store.getters['store_client/get_client_intervention_overview']

            if (this.$store.getters['store_client/get_client_progress'].current_module_nr > 0 && this.$store.getters['store_client/get_client_progress'].current_module_nr != 7) {
               this.module_panel[this.$store.getters['store_client/get_client_progress'].current_module_nr - 1] = 0
               this.session_panels[this.$store.getters['store_client/get_client_progress'].current_module_nr - 1][this.$store.getters['store_client/get_client_progress'].current_session_nr - 1] = 0
            }

            if (this.$store.getters['store_client/get_client_progress'].current_module_nr == 7) {
                this.module_panel = [0, 0, 0, 0, 0, 0]
            }

            this.post = true

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

        })

    },

    computed: {

        is_overview() {
            
            if (this.$route.path == '/client/overview') {

                return true

            } else {

                return false

            }
        },
        
    },

    watch: {

        '$route' () {

            this.post = false

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

            ClientApi.GetClientInfo().then(() => {

                ClientApi.GetInterventionOverview().then(() => {

                    
                    this.content_overview = this.$store.getters['store_client/get_client_intervention_overview']

                    this.show_module = this.$store.getters['store_client/get_client_progress'].current_module_nr
                    this.show_session.session = this.$store.getters['store_client/get_client_progress'].current_session_nr
                    this.show_session.module = this.$store.getters['store_client/get_client_progress'].current_module_nr

                    this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                    this.post = true

                })

            })

        }

    },

    methods: {

        get_correct_id(module) {

            if (module.rank != this.content_overview.length) {

                if (!this.$vuetify.breakpoint.mdAndDown) {
                    return 'timeline'
                } else {
                    return 'timeline-mobile'
                }

            } else {

                if (!this.$vuetify.breakpoint.mdAndDown) {
                    return 'timeline-last'
                } else {
                    return 'timeline-last-mobile'
                }

            }

        },

        disable_session_btn(session) {

            if (!session.current & !session.finished) {
                return true
            } else {
                return false
            }

        },

        goToLection(m_rank, s_rank, l_rank) {

            // set page allowed to true
            this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

            this.$router.push({ name: 'client-intervention', params: { module: m_rank, session: s_rank, lection: l_rank } })

        },

        toggle_module(m) {

            if (this.show_module == m.rank) {
                this.show_module = null
            } else {
                this.show_module = m.rank
            }
        },

        
        toggle_session(s, m) {

            if (this.show_session.session == s.rank & this.show_session.module == m.rank) {
                this.show_session.session = null
                this.show_session.module = null
            } else {
                this.show_session.session = s.rank
                this.show_session.module = m.rank
            }
        },
    }
    
}
</script>

<style scoped>
.timeline-title {
  width: calc(100% - 68px);
  margin-left: 68px;
  position: relative;
}

.timeline-title-mobile {
  width: 100%;
  position: relative;
}

.timeline-expansion-panel {
  border-style: solid;
  border-width: 1px;
  border-radius: 5px !important;
  border-color: #004b8c !important;
  padding-left: 5px;
  width: 100%;
}

.timeline-session-title {
  width: calc(100% - 16px);
  margin-left: 16px;
  margin-top: 32px;
  position: relative;

}

.timeline-session-expansion-panel {
  border-style: solid;
  border-width: 1px;
  border-radius: 5px !important;
  border-color: #004b8c !important;
  padding-left: 5px;
  width: 100%;
}

#cardbadge { 
  left: -20px;
  top: -20px; 
  position: absolute;
  z-index: 2;
}

#timeline {
  position: relative;

}

#timeline-last {
  position: relative;
}

/* line between icons */
#timeline:before {
  content: "";
  width: 2px;
  height: 100%;
  background: #616161;
  /* background: #1976D2; */
  left: 12px;
  top: 90px;
  position: absolute;
  
}

#timeline:after {
  content: "";
  clear: both;
  display: table;
  width: 0%;
}

#timeline .timeline-item {
  margin-bottom: 10px;
  position: relative;
  width: 100%;
}

#timeline-last .timeline-last-item {
  margin-bottom: 10px;
  position: relative;
}

</style>