<template>
  <div class="mx-3" v-if="ready">

    <v-row>
      <div class="headline primary--text">{{ question }}</div>
    </v-row>

    <v-row>
      <div class="overline mb-3"><b>Tipp:</b> Fügen Sie in der Tabelle Aussagen/Stichwörter hinzu.</div>
    </v-row>

    <v-row>

        <!-- FIELD Pro -->
        <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12':'6'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'6'">

            <v-card
                class="mx-auto"
                outlined
                min-height="120px"
                height="100%"
            >

                <v-card-text class="text-center headline primary--text font-weight-bold">{{ headerPro }}</v-card-text>

                <v-divider />
                
                <v-card-text>

                    <v-row v-if="!proList.length" class="mx-1" align="center" justify="center">

                        <v-alert 
                            :value="true"
                            color="black"
                            icon="fas fa-exclamation-triangle"
                            outlined
                        >
                            Die Liste ist noch leer.
                            <span v-if="minItemsPro != 0">Fügen Sie mindestens {{ minItemsPro }} Aussagen/Stichwörter ein!</span>
                            <span v-if="minItemsPro == 0">Sie müssen jedoch keine Aussagen/Stichwörter hinzufügen.</span>
                        </v-alert>

                    </v-row>

                    <v-row v-if="proList.length > 0 && proList.length < minItemsPro" class="mx-1" align="center" justify="center">

                        <v-alert 
                            :value="true"
                            color="black"
                            icon="fas fa-exclamation-triangle"
                            outlined
                        >
                            Bitte fügen Sie insgesamt {{ minItemsPro }} Aussagen/Stichwörter ein!
                        </v-alert>

                    </v-row>

                    <v-row
                        v-for="(item, i) in proList"
                        :key="i"
                        align="center" 
                        justify="center"
                    >

                        <v-card color="accent" class="my-3 mx-3">

                            <v-card-text class="body-1">
                            {{ item }}
                            <v-btn icon :disabled = "thisBtnDisabled" @click="closeProChip(i)">
                                <v-icon color="black">far fa-times-circle</v-icon>
                            </v-btn>
                            </v-card-text>
                                                
                        </v-card>

                    </v-row>

                    <v-row class="mx-1 mt-5" v-if="!thisBtnDisabled">

                        <v-text-field
                            v-model="proItem"
                            outlined
                            clear-icon="far fa-plus-square"
                            clearable
                            label="Fügen Sie ein Item hinzu."
                            type="text"
                            @click:clear="addProItem"
                            :disabled="thisBtnDisabled"
                            color="accent"
                            hint='Tippen sie zuerst ihren Text ein. Klicken Sie danach auf das + - Zeichen.'
                        ></v-text-field>

                    </v-row>

                </v-card-text>

            </v-card>

        </v-col>

        <!-- FIELD Contra -->
        <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12':'6'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'6'">

            <v-card
                class="mx-auto"
                outlined
                min-height="120px"
                height="100%"
            >

                <v-card-text class="text-center headline primary--text font-weight-bold">{{ headerContra }}</v-card-text>

                <v-divider />

                <v-card-text>

                    <v-row v-if="!contraList.length" class="mx-1" align="center" justify="center">

                        <v-alert 
                            :value="true"
                            color="black"
                            icon="fas fa-exclamation-triangle"
                            outlined
                        >
                            Die Liste ist noch leer.
                            <span v-if="minItemsContra != 0">Fügen Sie mindestens {{ minItemsContra }} Aussagen/Stichwörter ein!</span>
                            <span v-if="minItemsContra == 0">Sie müssen jedoch keine Aussagen/Stichwörter hinzufügen.</span>
                        </v-alert>

                    </v-row>

                    <v-row v-if="contraList.length > 0 && contraList.length < minItemsContra" class="mx-1" align="center" justify="center">

                        <v-alert 
                            :value="true"
                            color="black"
                            icon="fas fa-exclamation-triangle"
                            outlined
                        >
                            Bitte fügen Sie insgesamt {{ minItemsContra }} Aussagen/Stichwörter ein!
                        </v-alert>

                    </v-row>

                    <v-row
                        v-for="(item, i) in contraList"
                        :key="i"
                        align="center" 
                        justify="center"
                    >

                        <v-card color="accent" class="my-3 mx-3">

                            <v-card-text class="body-1">
                            {{ item }}
                            <v-btn icon :disabled = "thisBtnDisabled" @click="closeContraChip(i)">
                                <v-icon color="black">far fa-times-circle</v-icon>
                            </v-btn>
                            </v-card-text>
                                                
                        </v-card>

                    </v-row>

                    <v-row class="mx-1 mt-5" v-if="!thisBtnDisabled">

                        <v-text-field
                            v-model="contraItem"
                            outlined
                            clear-icon="far fa-plus-square"
                            clearable
                            label="Fügen Sie ein Item hinzu."
                            type="text"
                            @click:clear="addContraItem"
                            :disabled="thisBtnDisabled"
                            color="accent"
                            hint='Tippen sie zuerst ihren Text ein. Klicken Sie danach auf das + - Zeichen.'
                        ></v-text-field>

                    </v-row>

                </v-card-text>

            </v-card>

        </v-col>

    </v-row>

  </div>
    
</template>

<script>
import _ from 'lodash'

export default {
    name: 'traditional-likert-scale',

    props: ['taskID', 'question', 'id', 'minItemsPro', 'minItemsContra', 'headerPro', 'headerContra'],

    data: () => ({
      proList: [],
      contraList: [],

      proItem: null,
      contraItem: null,

      ready: false,
    }),


    created () {

        this.ready = false

        if (_.isEmpty(this.$store.getters['store_lection/get_saved_lection_task_data_by_identifier'](this.taskID))) {

            // define task data store
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'proList', 'data': this.proList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'contraList', 'data': this.contraList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'type', 'data': 'proContraInputTask'})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'headerPro', 'data': this.headerPro})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'headerContra', 'data': this.headerContra})

            this.ready = true
        
        } else {

            // get data
            var data = this.$store.getters['store_lection/get_saved_lection_task_data_by_identifier'](this.taskID)
            this.proList = data.proList
            this.contraList = data.contraList

            this.ready = true

        }
      
    },

    computed: {
        
        thisBtnDisabled () {

            var tmp = this.$store.getters['store_lection/get_saved_lection_data']
                
            if (tmp.data[this.id].finished == false) {

                return false

            } else {

                return true

            }

        },

    },

    watch: {

      // watch the checkbox-state and update the store immediatly on change
      proList: function(val) {

        if (val.length >= this.minItemsPro  && this.contraList.length >= this.minItemsContra) {
            this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': true})
        } else {
            this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false})
        }
          
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'proList', 'data': val})
      
      },

      contraList: function(val) {

        if (val.length >= this.minItemsContra  && this.proList.length >= this.minItemsPro) {
            this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': true})
        } else {
            this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false})
        }

        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'contraList', 'data': val})
      
      },

    },

    methods: {

      // add a item to the list and tick it
      addProItem () {

        this.proList.push(this.proItem)
        this.proItem = null

      },

      addContraItem () {

        this.contraList.push(this.contraItem)
        this.contraItem = null

      },

      closeProChip(i) {

          this.proList.splice(i, 1)

      },

      closeContraChip(j) {

          this.contraList.splice(j, 1)

      }
     
    }

}   
</script>

<style scoped>

</style>
