<template>

    <v-card
        color="grey lighten-5"
        outlined
        v-if="loaded"
    >

        <v-card-text>

            <v-row class="mx-1 mt-3">

                <div class="subtitle-2">
                    Übung
                </div>

            </v-row>

            <v-row class="mx-1 mt-3">

                <div class="title black--text">
                    <b>{{ get_short_question(task) }}</b><small > (Modul {{ task.current_module_nr}} - Sitzung {{ task.current_session_nr }} - Lektion {{ task.current_lession_nr}})</small>
                </div>

                <v-spacer />

                <v-btn 
                    outlined 
                    color="primary"
                    @click="goToLection()"
                >
                    Lektion ansehen
                </v-btn>

            </v-row>

            <v-divider class="mt-3"></v-divider>

            <v-row>

                <v-container fluid style="width: 100% !important;">

                    <v-row
                        v-for="(msg, index) in task.all_messages"
                        :key="index"
                    >

                        <v-col
                            :cols="$vuetify.breakpoint.mdAndUp ? '10' : '12'"
                            :sm="$vuetify.breakpoint.mdAndUp ? '10' : '12'"
                            :offset-sm="msg.sender.username !== $store.getters['store_auth/get_user_username'] ? '0': '2'" 
                        >

                            <v-card outlined :id="'fooAnchor' + index + 5">

                                <v-card-title>

                                    <v-chip 
                                        :color="msg.sender.username !== $store.getters['store_auth/get_user_username'] ? 'accent' : 'primary'"
                                        :class="msg.sender.username !== $store.getters['store_auth/get_user_username'] ? 'black--text' : 'white--text'"
                                    >
                                        {{ convertedSender(msg.sender) }}
                                    </v-chip>

                                    <v-spacer />

                                    <v-tooltip bottom v-if="msg.critical_event">
                                        <template v-slot:activator="{ on }">
                                            <v-icon color="error" dark v-on="on">far fa-bell</v-icon>
                                        </template>
                                        <span>Dies wurde ihrem Bewährungshelfer als potentielles kritisches Ereignis gemeldet  (gemeldet am {{ getCorrectDateTime(msg.critical_event_report_time) }}). Bitte nehmen Sie mit Ihrem Bewährungshelfer Kontakt auf, um nähere Informationen zu erhalten.</span>
                                    </v-tooltip>

                                </v-card-title>

                                <v-divider />

                                <v-card-text class='black--text' v-if="msg.revision_number != 0 && msg.sender.username === $store.getters['store_auth/get_user_username']">

                                    <v-card outlined v-if="msg.content != 'null'">

                                        <v-card-title>
                                            Nachricht
                                        </v-card-title>

                                        <v-divider />

                                        <v-card-text>

                                            <v-container fluid class="pa-0">

                                                <v-row
                                                    align="center"
                                                    justify="start"
                                                    class="pa-0"
                                                >

                                                    <v-col cols="12" sm="12" class="note py-0">
                                                        <div class="body-1">{{ msg.content }}</div>
                                                    </v-col>

                                                </v-row>

                                            </v-container>

                                        </v-card-text>

                                    </v-card>

                                </v-card-text>

                                <v-card-text class='black--text' v-if="msg.sender.username !== $store.getters['store_auth/get_user_username']">

                                    <v-card outlined>

                                        <v-card-title>
                                            Nachricht
                                        </v-card-title>

                                        <v-divider />

                                        <v-card-text>

                                            <v-container fluid class="pa-0">

                                                <v-row
                                                    align="center"
                                                    justify="start"
                                                    class="pa-0"
                                                >

                                                    <v-col cols="12" sm="12" class="note py-0">
                                                        <div class="body-1">{{ msg.content }}</div>
                                                    </v-col>

                                                </v-row>

                                            </v-container>

                                        </v-card-text>

                                    </v-card>

                                </v-card-text>

                                <v-card-text v-if="msg.revision_number == 0 & msg.sender.username == $store.getters['store_auth/get_user_username']">

                                    <GuidedTaskData 
                                        :data="JSON.parse(task.original_task_data)" 
                                        :first="true"
                                        :title="'Ursprüngliche Bearbeitung'"
                                    />

                                </v-card-text>

                                <v-card-text v-if="msg.revision_number != 0 & msg.sender.username == $store.getters['store_auth/get_user_username']">

                                    <GuidedTaskData 
                                        :data="msg.current_task_data" 
                                        :first="false"
                                        :title="'Revision ' + msg.revision_number"
                                    />

                                </v-card-text>

                                <v-card-subtitle v-if="msg.revision_number != 0">
                                    <small class='overline'>{{ getCorrectDateTime(msg.sent_at) }} | <b>Revision {{ msg.revision_number }}</b></small>
                                </v-card-subtitle>

                                <v-card-subtitle v-else>
                                    <small class='overline'>{{ getCorrectDateTime(msg.sent_at) }} | <b>Ursprüngliche Bearbeitung</b></small>
                                </v-card-subtitle>

                            </v-card>

                        </v-col>

                    </v-row>

                </v-container>

            </v-row>

            <v-divider class="my-3" v-if="!is_finished(task) && task.waiting_for_client"></v-divider>

            <v-card flat v-if="!is_finished(task) && task.waiting_for_client">

                <v-card-title>
                    Meine Überarbeitung

                    <v-spacer />

                    <v-btn 
                        outlined 
                        color="primary"
                        @click="goToLection()"
                    >
                        Lektion ansehen
                    </v-btn>
                    
                </v-card-title>

                <v-card-text>
                    
                    <GuidedTaskInput :data="JSON.parse(task.original_task_data)" />
                    
                </v-card-text>

                <v-card-text>

                    <v-form ref="new_message_form" v-model="new_message_form_valid" lazy-validation>

                        <v-textarea
                            name="input-7-1"
                            label="Zusätzliche Nachricht:"
                            v-model="newMessageValue"
                            rows="3"
                            auto-grow
                            required
                            outlined
                            color="primary"
                            v-if="!is_finished(task)"
                        ></v-textarea>

                    </v-form>

                    <v-col cols="12" sm="12" class="pa-0">

                        <div class="caption"><b>Bitte beachten Sie:</b> Zum Schutz der Daten anderer Personen, vermeiden Sie es unbedingt, persönliche Daten von jemand anderem in Ihren Nachrichten zu nennen, z.B. vollständige Namen oder Adressen von Menschen, die Sie kennen.</div>

                    </v-col>

                    <v-row align="center" v-if="!$store.getters['store_guided_task/getCurrentGuidedTask'].success">
                        <v-col cols="12" sm="12">
                            <div class="error--text float-right">Sie müssen die Übung erst vollständig bearbeiten.</div>
                        </v-col>
                    </v-row>

                    <v-toolbar
                        flat
                        short
                        id="fooAnchor_attachment"
                        v-if="!is_finished(task)"
                    >

                        <v-spacer />

                        <v-btn 
                            @click="sendMessage"
                            :loading="sending"
                            color='primary'
                            outlined
                            class="mt-3"
                            :disabled="!$store.getters['store_guided_task/getCurrentGuidedTask'].success"
                        >
                            <v-icon left color="primary">far fa-paper-plane</v-icon><span class="ml-3">Versenden</span>
                        </v-btn>

                    </v-toolbar>

                </v-card-text>

            </v-card>

    </v-card-text>

    </v-card>

</template>

<script>
import ClientApi from '@/_api/client_api'
import DateTimeHelpers from '@/_helpers/date_time_helpers'

import GuidedTaskData from '@/components/_guided_task/guided_task_DATA'
import GuidedTaskInput from '@/components/_guided_task/guided_task_INPUT'

export default {
    name: 'client-tasks-details',

    components: {

        GuidedTaskData,
        GuidedTaskInput

    },

    data: () => ({

        loaded: false,

        task: {},

        new_thread_form_valid: true,
        new_thread_form_subject_rules: [
                v => !!v || 'Sie müssen einen Betreff angeben!',
                v => (v && v.length <= 120) || 'Der Betreff darf nicht länger als 120 Zeichen sein!'
            ],
        new_thread_form_content_rules: [
                v => !!v || 'Der Inhalt der Nachricht darf nicht leer sein!'],


        new_message_form_valid: true,
        new_message_form_content_rules: [
                v => !!v || 'Der Inhalt der Nachricht darf nicht leer sein!'],

        newMessageValue: null,

        // send message
        to_user: [],
        receiver: [],
        receiver_names: [],
        subject: null,
        content: null,

        file: null,
        attachmentName1: '',
        attachmentUrl1: '',
        attachmentFile1: '',
        
        attachmentName2: '',
        attachmentUrl2: '',
        attachmentFile2: '',
        
        attachmentName3: '',
        attachmentUrl3: '',
        attachmentFile3: '',

        sending: false,

        loading_attachment: false,

        checkbox_revision_finished: false,

    }),

    created() {

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        this.$store.dispatch('store_guided_task/RESET_STATE')

        ClientApi.GetGuidedTaskById(this.$route.params.task_id).then((resp) => {

            this.task = resp.data

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

            this.loaded = true

        })


    },

    computed: {

        usergroup() {
            return this.$store.getters['store_auth/get_user_group']
        },

    },

    methods: {

        goToLection() {

            // set page allowed to true
            this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

            this.$router.push({ name: 'client-intervention', params: { module: this.task.current_module_nr, session: this.task.current_session_nr, lection: this.task.current_lession_nr } })

        },

        get_short_question() {

            var tmp = JSON.parse(this.task.original_task_data)

            return tmp.wagtail_task_data.value.shortQuestion

        },

        getAttachmentName(att) {

            var tmp = att.split('/')

            return tmp[tmp.length - 1]

        },

        getChatPartnerName(item) {

            var partner = ''
            
            item.users.forEach(element => {

                if (element.username != this.$store.getters['store_auth/get_user_username']) {

                    partner = element.username

                } 
                
            });

            return partner

        },

       convertedSender(sender) {

            if (sender.username == this.$store.getters['store_auth/get_user_username']) {

                return 'Ich'

            } else {

                if (this.$store.getters['store_client/get_client_coach'].id == sender.id) {
                    if (this.$store.getters['store_client/get_client_coach'].coach_gender == 1) {
                        return 'Coach'
                    } else {
                        return 'Coachin'
                    }
                } else if (this.$store.getters['store_client/get_client_supervisor'].id == sender.id) {
                    return this.$store.getters['store_client/get_client_supervisor'].firstname + ' ' + this.$store.getters['store_client/get_client_supervisor'].last_name
                } else {
                    return sender.username
                }

            }

        },

        checkIfActionNesseary(item) {

            if (item.waiting_for_coach) {

                return true

            } else {

                return false
                
            }

        },

        get_converted_time(item) {

            return DateTimeHelpers.convertDatabaseDateTimeString('full', item)
        },

        getCorrectDateTime(item) {
            return DateTimeHelpers.convertDatabaseDateTimeString('full', item)
        },

        is_finished(current_chat) {

            if (current_chat.revision_finished) {
                return true
            } else {
                return false
            }
        },

        getNumberOfAttachments(msg) {

            var nr_msg = 0

            if (msg.attachment1) {

                nr_msg = nr_msg + 1

            } 

            if (msg.attachment2) {

                nr_msg = nr_msg + 1

            } 

            if (msg.attachment3) {

                nr_msg = nr_msg + 1

            } 

            return nr_msg

        },

        onAttachmentPicked (i) {  

            this.loading_attachment = false

            if (i == 1) {
                this.attachmentFile1 = this.file;
                this.attachmentName1 = this.file.name
            } else if (i == 2) {
                this.attachmentFile2 = this.$refs.attachment2.files[0];
                this.attachmentName2 = this.$refs.attachment2.files[0].name
            } else if (i == 3) {
                this.attachmentFile3 = this.$refs.attachment3.files[0];
                this.attachmentName3 = this.$refs.attachment3.files[0].name
            }
        },

        sendMessage() {

            if (this.$store.getters['store_guided_task/getCurrentGuidedTask'].success) {

                this.sending = true

                var data = {
                    'content': this.newMessageValue, 
                    'guided_task': this.task.id,
                    'revision_number': this.task.latest_message.revision_number + 1, 
                    'current_task_data': JSON.stringify(this.$store.getters['store_guided_task/getCurrentGuidedTask']),
                    'current_thread_id': this.task.id,
                    'attachmentFile1': this.attachmentFile1,
                    'attachmentFile2': this.attachmentFile2,
                    'attachmentFile3': this.attachmentFile3,
                }

                ClientApi.AddNewMessageToGuidedTask(data).then(() => {

                    ClientApi.UpdateGuidedTaskWaitingForCoach(this.task.id).then(() => {

                        this.sending = false

                        this.$store.dispatch('store_guided_task/RESET_STATE')

                        this.$router.push('/client/tasks')

                    })

                })

            } 

        }, 
        
    },

}
</script>

<style scoped>
.note {
  white-space: pre-line; 
  word-wrap: break-word;
  font-family: inherit;
  text-align: left !important;
}
</style>