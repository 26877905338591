<template>

    <v-container fluid v-if="show">

        <v-row>

            <v-col cols="12" sm="12">

                <v-card color="grey lighten-5" id="fooAnchor" flat>

                    <v-card-text flat color="grey lighten-5">

                        <v-row>

                            <v-col cols="auto">

                                <v-menu
                                    v-model="menu"
                                    bottom
                                    :close-on-content-click="false"
                                    :nudge-width="800"
                                    offset-y
                                    :nudge-bottom="9"
                                    z-index="1"
                                    min-width="90%"
                                    attach="#fooAnchor"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                        >
                                            Auszeichnungen
                                        </v-btn>
                                    </template>

                                    <v-card color="grey lighten-5" flat>

                                        <v-card-text>

                                            <v-row>
                                                <v-col
                                                    v-for="(award, index) in awards"
                                                    :key="index"
                                                    class="d-flex child-flex"
                                                    cols="12" sm="12" md="4"
                                                >

                                                    <v-card outlined :id="award.name" color="grey lighten-2">

                                                        <v-app-bar
                                                            flat
                                                            color="grey lighten-2"
                                                        >

                                                            <v-btn 
                                                                outlined
                                                                :disabled="!award.reached"
                                                                @click="equip(index)"
                                                                :loading="award.loading"
                                                                v-if="!award.equipped"
                                                            >
                                                                Ausrüsten
                                                            </v-btn>

                                                            <v-btn 
                                                                outlined
                                                                :disabled="!award.reached"
                                                                @click="unequip(index)"
                                                                :loading="award.loading"
                                                                v-if="award.equipped"
                                                            >
                                                                Ablegen
                                                            </v-btn>

                                                            <v-spacer />

                                                            <v-btn 
                                                                icon 
                                                                color="black"
                                                                @click="change_award_menu(index)"
                                                            >
                                                                <v-icon>far fa-question-circle</v-icon>
                                                            </v-btn>
            
                                                        </v-app-bar>

                                                        <v-img
                                                            :src="'/images/awards/' + award.image"
                                                            aspect-ratio="1"
                                                            class="grey lighten-2"
                                                            v-if="!award.menu_help"
                                                            :style="!award.reached ? 'filter: blur(10px) grayscale(100%);' : ''"
                                                        >
                                                            <template v-slot:placeholder>
                                                                <v-row
                                                                    class="fill-height ma-0"
                                                                    align="center"
                                                                    justify="center"
                                                                >
                                                                    <v-progress-circular
                                                                    indeterminate
                                                                    color="grey lighten-5"
                                                                    ></v-progress-circular>
                                                                </v-row>
                                                            </template>
                                                        </v-img>

                                                        <v-card-text v-if="award.menu_help" class="text-center">

                                                            {{ award.description }}

                                                        </v-card-text>

                                                        <v-card-text class="text-center title">

                                                            {{ award.name }}

                                                        </v-card-text>

                                                    </v-card>
                                                </v-col>
                                            </v-row>

                                        </v-card-text>

                                    </v-card>

                                </v-menu>

                            </v-col>

                        <v-spacer />

                        <v-col cols="auto" class="primary--text title text-center">
                            <span class="overline mr-1 mt-2">Mein Neues</span>
                            Ich.
                        </v-col>

                        <v-spacer />

                        </v-row>

                    </v-card-text>

                    <v-divider />

                    <v-card-text>

                        <v-row align="center" justify="center">

                            <v-img src="/images/awards/award_0.png" max-height="600" contain v-if="!awards[0].equipped"></v-img>

                            <v-img src="/images/awards/award_0_1.png" max-height="600" contain v-if="awards[0].equipped && !awards[1].equipped"></v-img>
                                
                            <v-img src="/images/awards/award_0_1_2.png" max-height="600" contain v-if="awards[1].equipped && !awards[2].equipped"></v-img>
                                    
                            <v-img src="/images/awards/award_0_1_2_3.png" max-height="600" contain v-if="awards[2].equipped && !awards[3].equipped"></v-img>

                            <v-img src="/images/awards/award_0_1_2_3_4.png" max-height="600" contain v-if="awards[3].equipped && !awards[4].equipped"></v-img>

                            <v-img src="/images/awards/award_0_1_2_3_4_5.png" max-height="600" contain v-if="awards[4].equipped && !awards[5].equipped"></v-img>

                            <v-img src="/images/awards/award_0_1_2_3_4_5_6.png" max-height="600" contain v-if="awards[5].equipped && !awards[6].equipped"></v-img>

                            <v-img src="/images/awards/award_0_1_2_3_4_5_6_7.png" max-height="600" contain v-if="awards[6].equipped && !awards[7].equipped"></v-img>

                            <v-img src="/images/awards/award_0_1_2_3_4_5_6_7_8.png" max-height="600" contain v-if="awards[7].equipped && !awards[8].equipped"></v-img>

                            <v-img src="/images/awards/award_0_1_2_3_4_5_6_7_8_9.png" max-height="600" contain v-if="awards[8].equipped"></v-img>

                        </v-row>

                    </v-card-text>

                </v-card>

            </v-col>

        </v-row>

    </v-container>
</template>

<script>
import ClientApi from '@/_api/client_api'

export default {
    name: 'client-awards',

    /**
     * REIHENFOLGE DER AWARDS:
     * Basis-Charakter
     * (1) Heldenanzug (nach Einführung)
     * (2) Cape (nach 1. Sitzung) 
     * (3) Stiefel (nach 1. fertiger guided task)
     * (4) Brille (nach Modul 1)
     * (5) Begleiter (nach Modul 2) 
     * (6) Computer (nach Modul 3)
     * (7) Helm (nach Modul 4)
     * (8) Schild (nach Modul 5)
     * (9) Schlüssel (nach Modul 6)
     */

    data: () => ({
        
        show: false,
        menu: false,

        awards: [
            {
                name: 'Heldenanzug',
                description: 'Wenn Sie die Einführung abgeschlossen haben, haben Sie den ersten Schritt getan – Sie haben angefangen! Damit sind Sie auf dem Weg, ein Superheld zu werden. Mit jeder Aufgabe, jeder Lektion und jedem Modul werden Sie ihrem Ziel, ein Held zu werden, näherkommen. Und am Ende wird Ihr neues „Ich“ Sie erwarten.\n\nIhr neues „Ich“ wird mit allem ausgerüstet sein, was Sie brauchen, um ein straffreies Leben zu führen.',
                image: 'HerosuitTN.png',
                award_equipped: false,
                menu_help: false,
                reached: false,
                equipped: false,
                loading: false,
            },
            {
                name: 'Cape',
                description: 'Mit Abschluss der ersten Sitzung verdienen Sie sich das wichtigste Markenzeichen eines Superhelden: ein Cape!\nDenn dann haben Sie bewiesen, dass Sie bereit sind, ein Superheld zu werden. Mit Abschluss der ersten Sitzung zeigen Sie, dass Sie bereit sind, die nächsten Schritte zu gehen und mit jedem Schritt auch ein wenig mehr Wissen zu sammeln. Und am Ende werden Sie alles haben, was ein richtiger Superheld braucht und wissen muss!',
                image: 'CapeTN.png',
                award_equipped: false,
                menu_help: false,
                reached: false,
                equipped: false,
                loading: false,
            },
            {
                name: 'Stiefel',
                description: 'Nachdem Sie die erste Coach-begleitete Übung absolviert haben, sind Sie einen Schritt weiter auf dem Weg, ein Superheld zu werden.\nMit jedem Schritt, den Sie bei @myTabu machen werden, stehen Sie selbstständiger und selbstbewusster auf Ihren eigenen Füßen. Deshalb bekommen Sie dieses Paar Stiefel. Vielleicht fällt es Ihnen damit sogar leichter, jeden weiteren Schritt zu machen.',
                image: 'BootsTN.png',
                award_equipped: false,
                reached: false,
                equipped: false,
                loading: false,
            },
            {
                name: 'Brille',
                description: 'Die Brille ist das Zeichen dafür, dass Sie das erste Modul erfolgreich abgeschlossen haben und das zweite Modul auf Sie wartet. Die Brille soll Ihnen helfen, eine klare Sicht auf die Aufgaben zu haben, die vor Ihnen liegen.',
                image: 'BrilleTN.png',
                award_equipped: false,
                reached: false,
                equipped: false,
                loading: false,
            },
            {
                name: 'Begleiter',
                description: 'An dieser Stelle haben Sie schon zwei Module erfolgreich gemeistert und eine Menge Wissen gesammelt. Von diesem Punkt an wird Ihnen ein treuer Begleiter zur Seite stehen und Ihnen bei den kommenden Aufgaben auf Ihrem Weg helfen. Zusammen mit Ihrem Begleiter können Sie die nächsten Module meistern, mehr Wissen sammeln und erfolgreich zu Ihrem neuen „Ich“ gelangen.',
                image: 'HuskyTN.png',
                award_equipped: false,
                reached: false,
                equipped: false,
                loading: false,
            },
            {
                name: 'Computer',
                description: 'Die Hälfte der Module haben Sie zu diesem Zeitpunkt erfolgreich gemeistert – klasse! Dabei haben Sie eine ganze Menge neues Wissen und Fähigkeiten gesammelt und um all diese wichtigen Punkte nicht zu vergessen, kriegen Sie den nächsten Teil Ihrer Ausrüstung: den Computer!\nZusammen mit Ihrem Computer können Sie noch weitergehen und die Reise bei @myTabu fortsetzen.',
                image: 'ComputerTN.png',
                award_equipped: false,
                reached: false,
                equipped: false,
                loading: false,
            },
            {
                name: 'Helm',
                description: 'Vier Module liegen schon hinter Ihnen, wenn Sie diese Auszeichnung bekommen. Das heißt, Sie haben einiges an Wissen aus diesem Modul mitnehmen können und sind nun in der Lage, dieses auch anzuwenden. Um das darzustellen, bekommen Sie einen Helm.\nHiermit wird es Ihnen bestimmt leichter fallen, auch die letzten beiden Module zu meistern, sich an das schon gewonnene Wissen zu erinnern und weiter so erfolgreich in die Zukunft zu gehen!',
                image: 'HelmTN.png',
                award_equipped: false,
                reached: false,
                equipped: false,
                loading: false,
            },
            {
                name: 'Schild',
                description: 'Inzwischen fehlt Ihnen nur noch ein weiteres Modul. Das heißt auch, dass Sie fünf Module erfolgreich gemeistert haben und das ist mehr als Grund genug diesen Erfolg mit einem weiteren Gegenstand zu feiern – Ihrem Schild.\nDamit ist Ihre Superhelden-Ausrüstung fast vollständig und Ihnen fehlt nicht mehr viel Wissen, um Ihr zukünftiges Leben straffrei fortzuführen. Behalten Sie alles, was Sie schon gelernt haben im Gedächtnis und seien Sie bereit für das letzte Modul von @myTabu.',
                image: 'SchildTN.png',
                award_equipped: false,
                reached: false,
                equipped: false,
                loading: false,
            },
            {
                name: 'Schlüssel',
                description: 'Der Schlüssel ist der letzte Bestandteil Ihrer Ausrüstung! Das bedeutet, dass Sie es geschafft haben @myTabu mit allen Modulen, Übungen und Texten, erfolgreich zu beenden.\nMit diesem Schlüssel können Sie nun in die Welt Ihres neuen „Ichs“ eintreten. Nun sind Sie als frischgebackener Superheld bereit, sich Ihrem Alltag zu stellen und Ihr straffreies Leben zu leben.',
                image: 'SchlüsselTN.png',
                award_equipped: false,
                reached: false,
                equipped: false,
                loading: false,
            }
        ]

    }),

    created() {

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        this.show = false

        ClientApi.GetAwards().then((resp) => {

            this.awards[0].reached = resp.data[0].award1
            this.awards[0].equipped = resp.data[0].award1_equipped
            this.awards[1].reached = resp.data[0].award2
            this.awards[1].equipped = resp.data[0].award2_equipped
            this.awards[2].reached = resp.data[0].award3
            this.awards[2].equipped = resp.data[0].award3_equipped
            this.awards[3].reached = resp.data[0].award4
            this.awards[3].equipped = resp.data[0].award4_equipped
            this.awards[4].reached = resp.data[0].award5
            this.awards[4].equipped = resp.data[0].award5_equipped
            this.awards[5].reached= resp.data[0].award6
            this.awards[5].equipped = resp.data[0].award6_equipped
            this.awards[6].reached = resp.data[0].award7
            this.awards[6].equipped = resp.data[0].award7_equipped
            this.awards[7].reached = resp.data[0].award8
            this.awards[7].equipped = resp.data[0].award8_equipped
            this.awards[8].reached = resp.data[0].award9
            this.awards[8].equipped = resp.data[0].award9_equipped

            if (resp.data[0].award1_equipped) {

                this.$set(this.awards[0], 'equipped', true)

            }

            if (resp.data[0].award2_equipped) {

                this.$set(this.awards[1], 'equipped', true)
                this.$set(this.awards[0], 'equipped', true)

            }

            if (resp.data[0].award3_equipped) {

                this.$set(this.awards[2], 'equipped', true)
                this.$set(this.awards[1], 'equipped', true)
                this.$set(this.awards[0], 'equipped', true)

            } 
            
            if (resp.data[0].award4_equipped) {

                this.$set(this.awards[3], 'equipped', true)
                this.$set(this.awards[2], 'equipped', true)
                this.$set(this.awards[1], 'equipped', true)
                this.$set(this.awards[0], 'equipped', true)

            } 
            
            if (resp.data[0].award5_equipped) {

                this.$set(this.awards[4], 'equipped', true)
                this.$set(this.awards[3], 'equipped', true)
                this.$set(this.awards[2], 'equipped', true)
                this.$set(this.awards[1], 'equipped', true)
                this.$set(this.awards[0], 'equipped', true)

            } 
            
            if (resp.data[0].award6_equipped) {

                this.$set(this.awards[5], 'equipped', true)
                this.$set(this.awards[4], 'equipped', true)
                this.$set(this.awards[3], 'equipped', true)
                this.$set(this.awards[2], 'equipped', true)
                this.$set(this.awards[1], 'equipped', true)
                this.$set(this.awards[0], 'equipped', true)

            } 
            
            if (resp.data[0].award7_equipped) {

                this.$set(this.awards[6], 'equipped', true)
                this.$set(this.awards[5], 'equipped', true)
                this.$set(this.awards[4], 'equipped', true)
                this.$set(this.awards[3], 'equipped', true)
                this.$set(this.awards[2], 'equipped', true)
                this.$set(this.awards[1], 'equipped', true)
                this.$set(this.awards[0], 'equipped', true)

            } 
            
            if (resp.data[0].award8_equipped) {

                this.$set(this.awards[7], 'equipped', true)
                this.$set(this.awards[6], 'equipped', true)
                this.$set(this.awards[5], 'equipped', true)
                this.$set(this.awards[4], 'equipped', true)
                this.$set(this.awards[3], 'equipped', true)
                this.$set(this.awards[2], 'equipped', true)
                this.$set(this.awards[1], 'equipped', true)
                this.$set(this.awards[0], 'equipped', true)

            }
            
            if (resp.data[0].award9_equipped) {

                this.$set(this.awards[8], 'equipped', true)
                this.$set(this.awards[7], 'equipped', true)
                this.$set(this.awards[6], 'equipped', true)
                this.$set(this.awards[5], 'equipped', true)
                this.$set(this.awards[4], 'equipped', true)
                this.$set(this.awards[3], 'equipped', true)
                this.$set(this.awards[2], 'equipped', true)
                this.$set(this.awards[1], 'equipped', true)
                this.$set(this.awards[0], 'equipped', true)

            }

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

            this.show = true

        })

    },

    computed: {
    
    },

    methods: {

        change_award_menu(index) {

            this.$set(this.awards[index], 'menu_help', !this.awards[index].menu_help)

        },

        equip(index) {

            var bool_str = 'T'
            var award_nr = null
            this.awards[index].loading = true
            
            if (this.awards[index].image == 'HerosuitTN.png') {
                award_nr = 1
            } else if (this.awards[index].image == 'CapeTN.png') {
                award_nr = 2
            } else if (this.awards[index].image == 'BootsTN.png') {
                award_nr = 3
            } else if (this.awards[index].image == 'BrilleTN.png') {
                award_nr = 4
            } else if (this.awards[index].image == 'HuskyTN.png') {
                award_nr = 5
            } else if (this.awards[index].image == 'ComputerTN.png') {
                award_nr = 6
            } else if (this.awards[index].image == 'HelmTN.png') {
                award_nr = 7
            } else if (this.awards[index].image == 'SchildTN.png') {
                award_nr = 8
            } else if (this.awards[index].image == 'SchlüsselTN.png') {
                award_nr = 9
            }

            ClientApi.UpdateAwardsEquipped({award_nr: award_nr, bool_str: bool_str}).then(() => {

                if (index == 0) {

                    this.$set(this.awards[index], 'equipped', true)

                } else if (index == 1) {

                    this.$set(this.awards[index - 1], 'equipped', true)
                    this.$set(this.awards[index], 'equipped', true)

                } else if (index == 2) {

                    this.$set(this.awards[index - 2], 'equipped', true)
                    this.$set(this.awards[index - 1], 'equipped', true)
                    this.$set(this.awards[index], 'equipped', true)

                } else if (index == 3) {

                    this.$set(this.awards[index - 3], 'equipped', true)
                    this.$set(this.awards[index - 2], 'equipped', true)
                    this.$set(this.awards[index - 1], 'equipped', true)
                    this.$set(this.awards[index], 'equipped', true)

                } else if (index == 4) {

                    this.$set(this.awards[index - 4], 'equipped', true)
                    this.$set(this.awards[index - 3], 'equipped', true)
                    this.$set(this.awards[index - 2], 'equipped', true)
                    this.$set(this.awards[index - 1], 'equipped', true)
                    this.$set(this.awards[index], 'equipped', true)

                } else if (index == 5) {

                    this.$set(this.awards[index - 5], 'equipped', true)
                    this.$set(this.awards[index - 4], 'equipped', true)
                    this.$set(this.awards[index - 3], 'equipped', true)
                    this.$set(this.awards[index - 2], 'equipped', true)
                    this.$set(this.awards[index - 1], 'equipped', true)
                    this.$set(this.awards[index], 'equipped', true)

                } else if (index == 6) {

                    this.$set(this.awards[index - 6], 'equipped', true)
                    this.$set(this.awards[index - 5], 'equipped', true)
                    this.$set(this.awards[index - 4], 'equipped', true)
                    this.$set(this.awards[index - 3], 'equipped', true)
                    this.$set(this.awards[index - 2], 'equipped', true)
                    this.$set(this.awards[index - 1], 'equipped', true)
                    this.$set(this.awards[index], 'equipped', true)

                } else if (index == 7) {

                    this.$set(this.awards[index - 7], 'equipped', true)
                    this.$set(this.awards[index - 6], 'equipped', true)
                    this.$set(this.awards[index - 5], 'equipped', true)
                    this.$set(this.awards[index - 4], 'equipped', true)
                    this.$set(this.awards[index - 3], 'equipped', true)
                    this.$set(this.awards[index - 2], 'equipped', true)
                    this.$set(this.awards[index - 1], 'equipped', true)
                    this.$set(this.awards[index], 'equipped', true)

                } else if (index == 8) {

                    this.$set(this.awards[index - 8], 'equipped', true)
                    this.$set(this.awards[index - 7], 'equipped', true)
                    this.$set(this.awards[index - 6], 'equipped', true)
                    this.$set(this.awards[index - 5], 'equipped', true)
                    this.$set(this.awards[index - 4], 'equipped', true)
                    this.$set(this.awards[index - 3], 'equipped', true)
                    this.$set(this.awards[index - 2], 'equipped', true)
                    this.$set(this.awards[index - 1], 'equipped', true)
                    this.$set(this.awards[index], 'equipped', true)

                }

                this.awards[index].loading = false
                this.menu = false
            })

        },

        unequip(index) {

            var bool_str = 'F'
            var award_nr = null
            this.awards[index].loading = true
            
            this.$set(this.awards[index], 'equipped', false)

            if (this.awards[index].image == 'HerosuitTN.png') {
                award_nr = 1
            } else if (this.awards[index].image == 'CapeTN.png') {
                award_nr = 2
            } else if (this.awards[index].image == 'BootsTN.png') {
                award_nr = 3
            } else if (this.awards[index].image == 'BrilleTN.png') {
                award_nr = 4
            } else if (this.awards[index].image == 'HuskyTN.png') {
                award_nr = 5
            } else if (this.awards[index].image == 'ComputerTN.png') {
                award_nr = 6
            } else if (this.awards[index].image == 'HelmTN.png') {
                award_nr = 7
            } else if (this.awards[index].image == 'SchildTN.png') {
                award_nr = 8
            } else if (this.awards[index].image == 'SchlüsselTN.png') {
                award_nr = 9
            }

            ClientApi.UpdateAwardsEquipped({award_nr: award_nr, bool_str: bool_str}).then(() => {

                if (index == 8) {

                    this.$set(this.awards[index], 'equipped', false)

                } else if (index == 7) {

                    this.$set(this.awards[index + 1], 'equipped', false)
                    this.$set(this.awards[index], 'equipped', false)

                } else if (index == 6) {

                    this.$set(this.awards[index + 2], 'equipped', false)
                    this.$set(this.awards[index + 1], 'equipped', false)
                    this.$set(this.awards[index], 'equipped', false)

                } else if (index == 5) {

                    this.$set(this.awards[index + 3], 'equipped', false)
                    this.$set(this.awards[index + 2], 'equipped', false)
                    this.$set(this.awards[index + 1], 'equipped', false)
                    this.$set(this.awards[index], 'equipped', false)

                } else if (index == 4) {

                    this.$set(this.awards[index + 4], 'equipped', false)
                    this.$set(this.awards[index + 3], 'equipped', false)
                    this.$set(this.awards[index + 2], 'equipped', false)
                    this.$set(this.awards[index + 1], 'equipped', false)
                    this.$set(this.awards[index], 'equipped', false)

                } else if (index == 3) {

                    this.$set(this.awards[index + 5], 'equipped', false)
                    this.$set(this.awards[index + 4], 'equipped', false)
                    this.$set(this.awards[index + 3], 'equipped', false)
                    this.$set(this.awards[index + 2], 'equipped', false)
                    this.$set(this.awards[index + 1], 'equipped', false)
                    this.$set(this.awards[index], 'equipped', false)

                } else if (index == 2) {

                    this.$set(this.awards[index + 6], 'equipped', false)
                    this.$set(this.awards[index + 5], 'equipped', false)
                    this.$set(this.awards[index + 4], 'equipped', false)
                    this.$set(this.awards[index + 3], 'equipped', false)
                    this.$set(this.awards[index + 2], 'equipped', false)
                    this.$set(this.awards[index + 1], 'equipped', false)
                    this.$set(this.awards[index], 'equipped', false)

                } else if (index == 1) {

                    this.$set(this.awards[index + 7], 'equipped', false)
                    this.$set(this.awards[index + 6], 'equipped', false)
                    this.$set(this.awards[index + 5], 'equipped', false)
                    this.$set(this.awards[index + 4], 'equipped', false)
                    this.$set(this.awards[index + 3], 'equipped', false)
                    this.$set(this.awards[index + 2], 'equipped', false)
                    this.$set(this.awards[index + 1], 'equipped', false)
                    this.$set(this.awards[index], 'equipped', false)

                } else if (index == 0) {

                    this.$set(this.awards[index + 8], 'equipped', false)
                    this.$set(this.awards[index + 7], 'equipped', false)
                    this.$set(this.awards[index + 6], 'equipped', false)
                    this.$set(this.awards[index + 5], 'equipped', false)
                    this.$set(this.awards[index + 4], 'equipped', false)
                    this.$set(this.awards[index + 3], 'equipped', false)
                    this.$set(this.awards[index + 2], 'equipped', false)
                    this.$set(this.awards[index + 1], 'equipped', false)
                    this.$set(this.awards[index], 'equipped', false)

                }
                this.awards[index].loading = false
                this.menu = false
            })
            
        },

    }
}
</script>

<style scoped>

</style>