<template>

    <div class="mx-3">

        <v-row align="center" justify="center">

            <v-card
                class="d-flex flex-wrap align-center"
                flat
                outlined
            >
                <v-card
                    v-for="(item, i) in data.wordList" 
                    :key="item.name"
                    class="py-2 px-1"
                    flat
                    tile
                >
                    <div class="body-1" v-if="!data.dropIndex.includes(i)">{{ item }}</div>

                    <v-card
                        disabled
                        v-if="data.dropIndex.includes(i)"
                    >
                        <v-alert outlined :color="getCorrectColor(i, dropLists[i])" class="pa-0 ma-0">
                            <v-card-text class="text-center body-1 pa-2">{{ dropLists[i][0] }}</v-card-text>
                        </v-alert>
                    </v-card>
                       
                </v-card>
            </v-card>

        </v-row>

        <v-row>

            <v-card
                class="mx-auto"
                outlined
                min-height="40px"
                min-width="100%"
            >

                <v-card-text>

                    <v-row>

                        <div 
                            v-for="(item, i) in data.wordList" 
                            :key="item.name"
                        >

                            <v-card v-if="!data.dropIndex.includes(i)" flat class="mb-1">

                                <v-card-text class="py-1 px-0">

                                    <v-card flat class="px-0">

                                        <v-card-text class="pa-1">

                                            <span class="body-1">{{ item }}</span>

                                        </v-card-text>

                                    </v-card>

                                </v-card-text>

                            </v-card>

                            <v-card
                                outlined
                                min-height="40px"
                                min-width="60px"
                                v-if="data.dropIndex.includes(i)"
                                class="mb-1"
                            >

                                <v-card-text>

                                    <v-row
                                        v-for="(item, index) in data.dropLists[i]"
                                        :key="index"
                                        align="center" 
                                        justify="center"
                                    >

                                        <v-card :color="getCorrectColor(i, item)">
                                            <v-card-text class="py-1">
                                                <div class="text-center body-1 white--text">{{ item }}</div>
                                            </v-card-text>
                                        </v-card>

                                    </v-row>

                                </v-card-text>

                            </v-card>


                        </div>

                    </v-row>


                </v-card-text>

            </v-card>

        </v-row>

    </div>

</template>

<script>
export default {
    name: 'fill-in-the-gap-task-data',
    props: ['data'],

    mounted () {
        //
    },

    methods: {

        getCorrectColor(i, item) {

            if (this.data.correctList[item].correct_index == i) {

                return 'success'

            } else {

                return 'error'

            }

        },
    
    
    }

}
</script>

<style scoped>

</style>