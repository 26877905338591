<template>

    <v-container>

        <v-row style="padding-top: 35%;">

            <v-col cols="12" sm="12">

                    <v-card-text style="text-align: center;">
                        <h1 style="text-align: center;">
                            <v-icon size="240" style="color: red;">fas fa-medkit</v-icon>
                        </h1>
                    </v-card-text>

                    <v-card-text>
                        <h1 style="text-align: center; font-size: 90px; font-weight: bold; margin: 16px;">Mein</h1>
                    </v-card-text>

                    <v-card-text style="padding-top: 32px;">
                        <h1 style="text-align: center; font-size: 90px; font-weight: bold;">Notfallkoffer</h1>
                    </v-card-text>
          
            </v-col>

        </v-row>

        <!-- Emergency Accent EMOTION -->
        <v-row id="page2el">
            <v-col cols="12" sm="12">

                <v-card
                    class="pa-2"
                    height="100%"
                    outlined
                    v-if="show_card_emotion"
                >

                    <v-card-text style="color: #004b8c; font-weight: bold;">
                        <h1>Ich fühle mich nicht gut</h1>
                    </v-card-text>

                    <v-card-text>
                        <div v-if="$store.getters['store_client/get_client'].client_group == 1">
                            Es kann immer mal passieren, dass es uns nicht gut geht. Unangenehme Gefühle gehören genauso zum Leben wie angenehme Gefühle. 
                            Aber wir sind unseren Gefühlen nicht ausgeliefert, sondern können Sie durch unser Handeln steuern. Schauen Sie sich gerne an, 
                            was Sie dazu bislang gemacht haben.
                        </div>

                        <div v-if="$store.getters['store_client/get_client'].client_group == 2">
                            Es kann immer mal passieren, dass es uns nicht gut geht. Denn unangenehme Gefühle gehören genauso zum Leben wie angenehme Gefühle. 
                            Dennoch sind wir unseren Gefühlen nicht ausgeliefert, sondern können Sie durch unser Handeln steuern. Manchmal hilft es auch schon, 
                            sich für einen Moment abzulenken. Hier habe ich Ihnen ein paar Möglichkeiten aufgeschrieben, die vielen Menschen helfen mit starken 
                            unangenehmen Gefühlen umzugehen.
                        </div>
                    </v-card-text>

                </v-card>

            </v-col>

        </v-row>

        <v-row v-if="$store.getters['store_client/get_client'].client_group == 1">

            <v-col cols="12" sm="12" v-if="!show_m3_s4_l4_t1">

                

                    <v-card-text style="color: #004b8c; font-weight: bold;">
                        <h2>Skills</h2>
                    </v-card-text>

                    <v-card-text class="body-1">
                        Wenn Sie starke unangenehmen Gefühle wie Ärger, Trauer oder Angst haben, dann können Ihnen Skills helfen. Skills sind Verhaltensweisen 
                        gegen starke Anspannung. In einer Situation, in der wir starke unangenehme Gefühle haben, können sie uns ablenken. Sie haben sich im 
                        dritten Modul aufgeschrieben, welche Skills Ihnen helfen können und was Sie für diese Skills immer dabeihaben müssen. Sie können diese 
                        Skills anwenden, wenn Sie den Eindruck haben, dass Sie nicht mehr klar denken und entscheiden können.
                    </v-card-text>

                    <v-card-text>
                        <blockquote class="blockquote black--text note">
                            {{ emergency_data.m3_s4_l4_t1.textInput }}
                        </blockquote>
                    </v-card-text>

                

            </v-col>

            <v-col cols="12" sm="12" v-if="!show_m3_s1_l7_t1">

                

                    <v-card-text style="color: #004b8c; font-weight: bold;">
                        <h2>Bedürfnisse</h2>
                    </v-card-text>

                    <v-card-text class="body-1">
                        Im Modul 3 „Immer diese Gefühle“ haben Sie sich mit Ihren Bedürfnissen befasst. Ein Bedürfnis ist ein starker Wunsch oder ein Verlangen nach 
                        etwas. Alle Menschen haben Bedürfnisse. Wenn diese nicht erfüllt werden, kann es uns ziemlich schlecht gehen. Wir fahren schnell aus der Haut 
                        oder sind häufig schlecht gelaunt. Damit wir glücklich durch unser Leben gehen, ist es deswegen sinnvoll, dass wir unseren Bedürfnissen 
                        Beachtung schenken. Im dritten Modul haben Sie das Bedürfnis aufgeschrieben, welches Ihnen am wichtigsten ist und sich überlegt, wie Sie es 
                        erfüllen können. Wenn Sie bemerken, dass es Ihnen häufig nicht gut geht, könnte es Ihnen helfen, Ihr Bedürfnis stärker zu erfüllen.
                    </v-card-text>

                    <v-card-text>
                         <blockquote class="blockquote black--text note">
                            {{ emergency_data.m3_s1_l7_t1.textInput }}
                        </blockquote>
                    </v-card-text>

                

            </v-col>

            <v-col cols="12" sm="12" v-if="!show_m2_s3_l5_t3">

                

                     <v-card-text style="color: #004b8c; font-weight: bold;">
                        <h2>Meine (zukünftigen) persönlichen Bewährungshelfer*innen</h2>
                    </v-card-text>

                    <v-card-text class="body-1">
                        Diese Personen haben Sie in Modul 2 als Ihre (zukünftigen) persönlichen Bewährungshelfer*innen ausgewählt. Wenn Sie sich nicht gut fühlen, 
                        melden Sie sich doch bei diesen Personen. Führen Sie ein Gespräch am Telefon oder verabreden Sie sich mit ihnen. Gehen Sie mit ihnen 
                        spazieren, einen Kaffee trinken oder kochen Sie zusammen etwas Leckeres. Durch den Kontakt zu anderen Menschen werden Sie sich bestimmt 
                        besser fühlen.<br>
                        Wenn Sie noch keine persönlichen Bewährungshelfer*innen nennen konnten, da Sie keine Kontakte zu anderen Menschen haben, dann sprechen 
                        Sie mit Ihrem*Ihrer Bewährungshelfer*in über dieses Thema. Er*sie wird Ihnen ganz bestimmt ein paar gute Tipps geben können, wie Sie 
                        mehr Kontakt zu anderen aufbauen können.
                    </v-card-text>

                    <v-card-text>
                        <v-list>

                            <v-list-item
                                v-for="item in emergency_data.m2_s3_l5_t3.keywordList"
                                :key="item.name"
                            >
                                <v-list-item-icon>
                                    <v-icon style="color: #fcb900;" small>fas fa-user</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content style="font-weight: bold;">{{ item }}</v-list-item-content>
                            </v-list-item>

                        </v-list>
                    </v-card-text>

                

            </v-col>

        </v-row>

        <v-row v-else>

            <v-col cols="12" sm="12">

                    <v-card-text style="color: #004b8c; font-weight: bold;">
                        <h2>Tipps zum Umgang mit starken unangenehmen Gefühlen</h2>
                    </v-card-text>

                    <v-card-text class="body-1">
                        Es kann immer mal passieren, dass es uns nicht gut geht. Denn unangenehme Gefühle gehören genauso zum Leben wie angenehme Gefühle. 
                        Dennoch sind wir unseren Gefühlen nicht ausgeliefert, sondern können Sie durch unser Handeln steuern. Manchmal hilft es auch schon, 
                        sich für einen Moment abzulenken. Hier habe ich Ihnen ein paar Möglichkeiten aufgeschrieben, die vielen Menschen helfen mit starken 
                        unangenehmen Gefühlen umzugehen. Wenn Sie den Eindruck haben, nicht mehr klar denken zu können, probieren Sie doch eine oder mehrere 
                        davon.
                    </v-card-text>

                    <v-card-text>
                        <v-list>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon style="color: #fcb900;" small>far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content style="font-weight: bold;">In eine Zitrone oder Chilischote beißen.</v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon style="color: #fcb900;" small>far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content style="font-weight: bold;">Sport machen (z. B. Laufen oder Krafttraining).</v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon style="color: #fcb900;" small>far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content style="font-weight: bold;">Von 1000 in 7er Schritte rüsckwärts zählen.</v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>

            </v-col>

        </v-row>

        <!-- Emergency Accent CONTROL -->
        <v-row>
            <v-col cols="12" sm="12">

                <v-card
                    class="pa-2"
                    height="100%"
                    outlined
                    v-if="show_card_control"
                >

                    <v-card-text style="color: #004b8c; font-weight: bold;">
                        <h1>Ich verliere die Kontrolle über sexuelle Fantasien oder sexuelles Verhalten.</h1>
                    </v-card-text>

                    <v-card-text>
                        <div v-if="$store.getters['store_client/get_client'].client_group == 1">
                            Sie haben in Modul 6 erarbeitet, dass Ihr Risiko für erneute Straftaten steigen kann, wenn Sie die Kontrolle über 
                            Ihre sexuellen Gedanken/Fantasien und Handlungen verlieren. Es kann z.B. sein, dass Sie die Kontrolle über Ihre sexuellen 
                            Fantasien (über Kinder) verlieren. Es kann auch passieren, dass Sie kurz davor stehen, eine erneute sexuelle Straftat zu begehen. 
                            Im Folgenden finden Sie Wege, um in solchen Situationen sexuelle Gedanken/Fantasien und Handlungen besser im Griff zu haben.
                        </div>

                        <div v-if="$store.getters['store_client/get_client'].client_group == 2">
                            Ihr Risiko für erneute Straftaten kann steigen, wenn Sie die Kontrolle über Ihre sexuellen Gedanken und Handlungen verlieren. Ganz 
                            besonders gefährlich wird es natürlich, wenn Sie die Kontrolle über Ihre sexuellen Fantasien über Kinder verlieren. Ich habe Ihnen 
                            hier Wege, die anderen Menschen helfen, sexuelle Gedanken und Fantasien besser zu kontrollieren, aufgeschrieben.
                        </div>
                    </v-card-text>

                </v-card>

            </v-col>

        </v-row>

        <v-row v-if="$store.getters['store_client/get_client'].client_group == 1">

            <v-col cols="12" sm="12" v-if="!show_m6_s1_l5_t1">

                    <v-card-text style="color: #004b8c; font-weight: bold;">
                        <h2>Risiko-Situationen meiden</h2>
                    </v-card-text>

                    <v-card-text class="body-1">
                        Sie können Ihre sexuellen Gedanken und Handlungen womöglich besser kontrollieren, wenn Sie diese Risiko-Situationen vermeiden. 
                        Denn diese fördern bei Ihnen sexuelle Gedanken und Handlungen.
                    </v-card-text>

                    <v-card-text>
                        <v-list>
                            <v-list-item
                                v-for="item in emergency_data.m6_s1_l5_t1.keywordList"
                                :key="item.name"
                            >
                                <v-list-item-icon>
                                    <v-icon style="color: #fcb900;" small>far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content style="font-weight: bold;">{{ item }}</v-list-item-content>
                            </v-list-item>

                        </v-list>
                    </v-card-text>

            </v-col>

            <v-col cols="12" sm="12" v-if="!show_m6_s1_l5_t2">

                    <v-card-text style="color: #004b8c; font-weight: bold;">
                        <h2>Sexuelle Gedanken und Handlungen besser kontrollieren</h2>
                    </v-card-text>

                    <v-card-text class="body-1">
                        Sie haben sich in Sitzung 1 im Modul 6 für diesen Weg entschieden, um Ihre sexuellen Gedanken und Handlungen besser zu kontrollieren. 
                        Sie können diesen Weg nun anwenden, um wieder Herr über Ihre sexuellen Gedanken und Handlungen zu werden.
                    </v-card-text>

                    <v-card-text>
                        <v-list>
                            <v-list-item
                                v-for="item in m6_s1_l5_t2_items"
                                :key="item.name"
                            >
                                <v-list-item-icon>
                                    <v-icon style="color: #fcb900;" small>far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content style="font-weight: bold;">{{ item }}</v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>

            </v-col>

            <v-col cols="12" sm="12" v-if="!show_m6_s2_l4_t1">

                    <v-card-text style="color: #004b8c; font-weight: bold;">
                        <h2>Emotionaler und räumlicher Abstand zu Kindern</h2>
                    </v-card-text>

                    <v-card-text class="body-1">
                        Wenn Sie sexuelle Fantasien über Kinder haben, die Sie nur schwer kontrollieren können und/oder kurz davor stehen eine erneute sexuelle 
                        Straftat zu begehen, dann kann es helfen, mehr Abstand zu Kindern zu gewinnen. Sie haben sich in Sitzung 2 im Modul 6 dazu entschieden, 
                        Kinder über die unten stehenden Wege emotional und räumlich auf Abstand zu halten. Verfolgen Sie diese Wege weiter!
                    </v-card-text>

                    <v-card-text>
                        <v-list>
                            <v-list-item
                                v-for="item in m6_s2_l4_t1_items"
                                :key="item.name"
                            >
                                <v-list-item-icon>
                                    <v-icon style="color: #fcb900;" small>far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content style="font-weight: bold;">{{ item }}</v-list-item-content>
                            </v-list-item>

                        </v-list>
                    </v-card-text>

            </v-col>

            <v-col cols="12" sm="12" v-if="!show_m6_s3_l4_t2">

                    <v-card-text style="color: #004b8c; font-weight: bold;">
                        <h2>Sexuelle Fantasien über Kinder und/oder das Risiko einer erneuten sexuellen Straftat besser kontrollieren</h2>
                    </v-card-text>

                    <v-card-text class="body-1">
                        Sie haben sich in Sitzung 4 im Modul 6 für diese Wege entschieden, um Ihre sexuellen Fantasien über Kinder und/oder das Risiko 
                        einer erneuten sexuellen Straftat besser zu kontrollieren. Wenden Sie einen dieser Wege doch sofort an, wenn Sie das Gefühl haben, 
                        dass Sie die Kontrolle verlieren! 
                    </v-card-text>

                    <v-card-text>
                        <v-list>
                            <v-list-item
                                v-for="item in m6_s3_l4_t2_items"
                                :key="item.name"
                            >
                                <v-list-item-icon>
                                    <v-icon style="color: #fcb900;" small>far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content style="font-weight: bold;">{{ item }}</v-list-item-content>
                            </v-list-item>

                        </v-list>
                    </v-card-text>

            </v-col>

            <v-col cols="12" sm="12" v-if="!show_m6_s4_l5_t1">

                    <v-card-text style="color: #004b8c; font-weight: bold;">
                        <h2>Meine unproblematische sexuelle Fantasie</h2>
                    </v-card-text>

                    <v-card-text class="body-1">
                        In Sitzung 4 des Moduls 6 haben Sie sich diese unproblematische sexuelle Fantasie ausgedacht. Wenn Sie sich selber befriedigen möchten, 
                        können Sie diese Fantasie nutzen. Wenn sich sexuelle Fantasien über Kinder während der Selbstbefriedigung aufdrängen und/oder Sie kurz 
                        davor stehen, eine erneute sexuelle Straftat zu begehen, lenken Sie Ihre Aufmerksamkeit auf diese unproblematische sexuelle Fantasie.  
                    </v-card-text>

                    <v-card-text>
                        <blockquote class="blockquote black--text note">
                            {{ emergency_data.m6_s4_l5_t1.textInput }}
                        </blockquote>
                    </v-card-text>

            </v-col>

        </v-row>

        <v-row v-else>

            <v-col cols="12" sm="12">

                    <v-card-text style="color: #004b8c; font-weight: bold;">
                        <h2>Tipps um meine sexuellen Fantasien (über Kinder) besser zu kontrollieren</h2>
                    </v-card-text>

                    <v-card-text class="body-1">
                        Ihr Risiko für erneute Straftaten kann steigen, wenn Sie die Kontrolle über Ihre sexuellen Gedanken und Handlungen verlieren. Ganz 
                        besonders gefährlich wird es natürlich, wenn Sie die Kontrolle über Ihre sexuellen Fantasien über Kinder verlieren. Ich habe Ihnen 
                        hier Wege, die anderen Menschen helfen, sexuelle Gedanken und Fantasien besser zu kontrollieren, aufgeschrieben. Manche Menschen 
                        gewinnen die Kontrolle über ihre sexuellen Fantasien wieder, indem sie…
                    </v-card-text>

                    <v-card-text>
                        <v-list>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon style="color: #fcb900;" small>far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content style="font-weight: bold;">sich ablenken (z. B. eiskalt duschen).</v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon style="color: #fcb900;" small>far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content style="font-weight: bold;">Risikosituationen möglichst vermeiden (z. B. zu viel im Internet surfen).</v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon style="color: #fcb900;" small>far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content style="font-weight: bold;">sich mit etwas belohnen, dass ihnen ebenfalls Spaß macht (z. B. eine*n Freund*in treffen).</v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon style="color: #fcb900;" small>far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content style="font-weight: bold;">die Herausforderung annehmen und das Verlangen wie eine Welle kommen und gehen lassen.</v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>

            </v-col>

        </v-row>

        <!-- Client Emergency CRIME -->
        <v-row>
            <v-col cols="12" sm="12">

                <v-card
                    class="pa-2"
                    height="100%"
                    outlined
                    v-if="show_card_crime"
                >

                    <v-card-text style="color: #004b8c; font-weight: bold;">
                        <h1>Ich weiß nicht, warum ich keine Straftaten begehen soll.</h1>
                    </v-card-text>

                    <v-card-text>
                        <div v-if="$store.getters['store_client/get_client'].client_group == 1">
                            Es kann passieren, dass Sie in Situationen kommen, in denen es Ihnen schwer fällt straffrei zu bleiben. Vielleicht wissen Sie nicht, 
                            warum Sie kein Kind missbrauchen oder Missbrauchsabbildungen ansehen sollten. Sie haben sich innerhalb von @myTabu Dinge überlegt, 
                            die Ihnen in diesen Situationen helfen können. Schauen Sie sich gerne Ihre Gedanken dazu an.
                        </div>

                        <div v-if="$store.getters['store_client/get_client'].client_group == 2">
                            Es kann passieren, dass Sie in Situationen kommen, in denen es Ihnen schwer fällt straffrei zu bleiben und Sie nicht mehr wissen, 
                            warum Sie kein Kind missbrauchen oder Missbrauchsabbildungen ansehen sollen. Auch frühere Teilnehmer von @myTabu haben sich manchmal 
                            gefragt, warum sie keine Straftaten mehr begehen möchten. Ich habe Ihnen hier einige der Gründe, die diese Menschen für sich gefunden 
                            haben, aufgeschrieben.
                        </div>
                    </v-card-text>

                </v-card>

            </v-col>

        </v-row>

        <v-row v-if="$store.getters['store_client/get_client'].client_group == 1">

            <v-col cols="12" sm="12" v-if="!show_m1_s4_l4_t2">

                    <v-card-text style="color: #004b8c; font-weight: bold;">
                        <h2>Warum ich keine Straftaten mehr begehen möchte</h2>
                    </v-card-text>

                    <v-card-text class="body-1">
                        Vielleicht ist Ihnen gerade gar nicht mehr so klar, warum Sie kein Kind missbrauchen und keine Missbrauchsabbildungen 
                        ansehen möchten. Im ersten Modul haben Sie aufgeschrieben, warum es für Sie sinnhaft ist keine Straftat zu begehen. 
                        Vielleicht helfen Ihnen Ihre Gedanken aus dem ersten Modul dabei weiterhin straffrei zu leben.
                    </v-card-text>

                    <v-card-text>
                        <v-list>
                            <v-list-item
                                v-for="item in emergency_data.m1_s4_l4_t2.keywordList"
                                :key="item.name"
                            >
                                <v-list-item-icon>
                                    <v-icon style="color: #fcb900;" small>far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content style="font-weight: bold;">{{ item }}</v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>

            </v-col>

            <v-col cols="12" sm="12" v-if="show_helpful_thought">

                    <v-card-text style="color: #004b8c; font-weight: bold;">
                        <h2>Hilfreiche Gedanken</h2>
                    </v-card-text>

                    <v-card-text class="body-1">
                        Sie haben hilfreiche Gedanken in Modul 5 „Gedankenzerrung“ kennengelernt. Ein hilfreicher Gedanke ist ein Gedanke, 
                        der viele Vorteile mit sich bringt und Ihnen hilft, keine sexuelle Straftat zu begehen. Dabei kann der hilfreiche 
                        Gedanke Gründe enthalten, die gegen eine sexuelle Straftat sprechen, die Nachteile die eine sexuelle Straftat bringt 
                        oder die Vorteile, die ein straffreies Leben mit sich bringt. Wenn Sie also einmal nicht mehr wissen, warum Sie keine 
                        Straftaten begehen sollen, kann Ihnen Ihr hilfreicher Gedanke auf die Sprünge helfen! Lesen Sie hier noch einmal Ihre 
                        hilfreichen Gedanken nach:
                    </v-card-text>

                    <v-card-text>

                        <blockquote class="blockquote black--text note" v-if="!show_helpful_thought_1">
                            {{ emergency_data.helpful_thought_1.textInput }}
                        </blockquote>

                        <blockquote class="blockquote black--text note" v-if="!show_helpful_thought_2">
                            {{ emergency_data.helpful_thought_2.textInput }}
                        </blockquote>

                        <blockquote class="blockquote black--text note" v-if="!show_helpful_thought_3">
                            {{ emergency_data.helpful_thought_3.textInput }}
                        </blockquote>

                    </v-card-text>

            </v-col>

        </v-row>

        <v-row v-else>

            <v-col cols="12" sm="12">

                    <v-card-text style="color: #004b8c; font-weight: bold;">
                        <h2>Tipps um straffrei zu bleiben</h2>
                    </v-card-text>

                    <v-card-text class="body-1">
                        Es kann passieren, dass Sie in Situationen kommen, in denen es Ihnen schwer fällt straffrei zu bleiben und Sie nicht mehr wissen, warum Sie 
                        kein Kind missbrauchen oder Missbrauchsabbildungen ansehen sollen. Auch frühere Teilnehmer von @myTabu haben sich manchmal gefragt, warum 
                        sie keine Straftaten mehr begehen möchten. Ich habe Ihnen hier einige der Gründe, die diese Menschen für sich gefunden haben, 
                        aufgeschrieben. Vielleicht können Sie manches davon ja auch gut verstehen? Gibt es darunter auch gute Gründe für Sie?
                    </v-card-text>

                    <v-card-text>
                        <v-list>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon style="color: #fcb900;" small>far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content style="font-weight: bold;">Ich möchte die Kontrolle über mich und mein Leben behalten.</v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon style="color: #fcb900;" small>far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content style="font-weight: bold;">Ich möchte kein schlechtes Gewissen haben oder Scham fühlen, weil ich ein Kind verletzt habe.</v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon style="color: #fcb900;" small>far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content style="font-weight: bold;">Ich möchte meine Familie und Freunde nicht verlieren.</v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon style="color: #fcb900;" small>far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content style="font-weight: bold;">Ich möchte meinen Job nicht verlieren.</v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon style="color: #fcb900;" small>far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content style="font-weight: bold;">Ich möchte nicht in das Gefängnis oder den Maßregelvollzug.</v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon style="color: #fcb900;" small>far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content style="font-weight: bold;">Ich möchte niemanden verletzen.</v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>

            </v-col>

        </v-row>

    </v-container>

</template>

<script>
import _ from 'lodash'

export default {
    name: 'emergency_pdf',

    data () {
        return {

          ready: false,

        }
    },

    created() {

    },

    computed: {

        emergency_data() {

            return this.$store.getters['store_client/get_client_emergency_data']

        },

        show_card_emotion() {

            if (!_.isEmpty(this.emergency_data.m3_s4_l4_t1) || !_.isEmpty(this.emergency_data.m3_s1_l7_t1) || !_.isEmpty(this.emergency_data.m2_s3_l5_t3)) {
                return true
            } else {
                return false
            }

        },

        show_card_control() {
            if (!_.isEmpty(this.emergency_data.m6_s1_l5_t1) || !_.isEmpty(this.emergency_data.m6_s1_l5_t2) || !_.isEmpty(this.emergency_data.m6_s2_l4_t1) || !_.isEmpty(this.emergency_data.m6_s3_l4_t2) || !_.isEmpty(this.emergency_data.m6_s4_l5_t1)) {
                return true
            } else {
                return false
            }
        },

        show_card_crime() {
            if (!_.isEmpty(this.emergency_data.helpful_thought_1) || !_.isEmpty(this.emergency_data.helpful_thought_2) || !_.isEmpty(this.emergency_data.helpful_thought_3) || !_.isEmpty(this.emergency_data.m1_s4_l4_t2)) {
                return true
            } else {
                return false
            }
        },

        show_m3_s4_l4_t1() {
            return _.isEmpty(this.emergency_data.m3_s4_l4_t1)
        },

        show_m3_s1_l7_t1() {
            return _.isEmpty(this.emergency_data.m3_s1_l7_t1)
        },

        show_m2_s3_l5_t3() {
            return _.isEmpty(this.emergency_data.m2_s3_l5_t3)
        },

        m6_s1_l5_t2_items() {

            var out = []

            for (var i = 0; i < this.emergency_data.m6_s1_l5_t2.checkbox.length; i++) {

                if (this.emergency_data.m6_s1_l5_t2.checkbox[i] == true) {

                    out.push(this.emergency_data.m6_s1_l5_t2.labels[i])

                }
            }

            return out

        },

        m6_s3_l4_t2_items() {

            var out = []

            for (var i = 0; i < this.emergency_data.m6_s3_l4_t2.checkbox.length; i++) {

                if (this.emergency_data.m6_s3_l4_t2.checkbox[i] == true) {

                    out.push(this.emergency_data.m6_s3_l4_t2.labels[i])

                }
            }

            return out

        },

        m6_s2_l4_t1_items() {

            var out = []

            for (var i = 0; i < this.emergency_data.m6_s2_l4_t1.checkbox.length; i++) {

                if (this.emergency_data.m6_s2_l4_t1.checkbox[i] == true) {

                    out.push(this.emergency_data.m6_s2_l4_t1.labels[i])

                }
            }

            return out

        },

        show_m6_s1_l5_t1() {
            return _.isEmpty(this.emergency_data.m6_s1_l5_t1)
        },

        show_m6_s1_l5_t2() {
            return _.isEmpty(this.emergency_data.m6_s1_l5_t2)
        },

        show_m6_s2_l4_t1() {
            return _.isEmpty(this.emergency_data.m6_s2_l4_t1)
        },

        show_m6_s3_l4_t2() {
            return _.isEmpty(this.emergency_data.m6_s3_l4_t2)
        },

        show_m6_s4_l5_t1() {
            return _.isEmpty(this.emergency_data.m6_s4_l5_t1)
        },

        show_m1_s4_l4_t2() {
            return _.isEmpty(this.emergency_data.m1_s4_l4_t2)
        },

        show_helpful_thought_1() {
            return _.isEmpty(this.emergency_data.helpful_thought_1)
        },

        show_helpful_thought_2() {
            return _.isEmpty(this.emergency_data.helpful_thought_2)
        },

        show_helpful_thought_3() {
            return _.isEmpty(this.emergency_data.helpful_thought_3)
        },

        show_helpful_thought() {

            if (!_.isEmpty(this.emergency_data.helpful_thought_1) || !_.isEmpty(this.emergency_data.helpful_thought_2) || !_.isEmpty(this.emergency_data.helpful_thought_3)) {
                return true
            } else {
                return false
            }
               
        },

    },

    components: {
    
    }
}
</script>

<style scoped>
.note {
  white-space: pre-line; 
  font-weight: bold;
  color: black;
  word-wrap: break-word;
  font-family: inherit;
  text-align: left !important;
  margin: 12px;
}
</style>