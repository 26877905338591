<template>
  <div class="mx-3" v-if="ready">

        <v-row>
            <div class="headline primary--text">{{ question }}</div>
        </v-row>

        <v-row>
            <div class="overline mb-3"><b>Tipp:</b> Wählen Sie bitte eine Zahl aus.</div>
        </v-row>
        
        <v-row align="center" justify="center">

          <v-icon class="mr-3 mt-5" v-if="iconSet == 'thermometer' && !$vuetify.breakpoint.smAndDown" large color="primary">fas fa-thermometer-empty</v-icon>
          <v-icon class="mr-3 mt-5" v-if="iconSet == 'smiley' && !$vuetify.breakpoint.smAndDown" large color="primary">far fa-sad-cry</v-icon>
          <v-icon class="mr-3 mt-5" v-if="iconSet == 'battery' && !$vuetify.breakpoint.smAndDown" large color="primary">fas fa-battery-empty</v-icon>

            <v-radio-group 
                v-model="radios" 
                :row="$vuetify.breakpoint.smAndDown ? false : true"
                hide-details
            >

                <div class="mb-3" v-if="$vuetify.breakpoint.smAndDown">
                    <v-icon v-if="iconSet == 'thermometer'" large color="primary">fas fa-thermometer-empty</v-icon>
                    <v-icon v-if="iconSet == 'smiley'" large color="primary">far fa-sad-cry</v-icon>
                    <v-icon v-if="iconSet == 'battery'" large color="primary">fas fa-battery-empty</v-icon>
                </div>


                <v-radio
                    v-for="index in values"
                    :key="index.name"
                    :disabled="thisBtnDisabled" 
                    color="accent" 
                    :value="index"
                    :label="index"
                ></v-radio>

                <div class="mb-3" v-if="$vuetify.breakpoint.smAndDown">
                    <v-icon v-if="iconSet == 'thermometer'" large color="primary">fas fa-thermometer-full</v-icon>
                    <v-icon v-if="iconSet == 'smiley'" large color="primary">far fa-laugh</v-icon>
                    <v-icon v-if="iconSet == 'battery'" large color="primary">fas fa-battery-full</v-icon>
                </div>

                <v-icon class="ml-3" v-if="iconSet == 'thermometer' && !$vuetify.breakpoint.smAndDown" large color="primary">fas fa-thermometer-full</v-icon>
                <v-icon class="ml-3" v-if="iconSet == 'smiley' && !$vuetify.breakpoint.smAndDown" large color="primary">far fa-laugh</v-icon>
                <v-icon class="ml-3" v-if="iconSet == 'battery' && !$vuetify.breakpoint.smAndDown" large color="primary">fas fa-battery-full</v-icon>

            </v-radio-group>
        </v-row>

  </div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'traditional-likert-scale',
    props: ['taskID', 'question', 'id', 'maxLabel', 'minLabel', 'iconSet'],

    data: () => ({

      radios: null,

      values: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],

      ready: false,

    }),

    created() {

      this.ready = false

      if (_.isEmpty(this.$store.getters['store_lection/get_saved_lection_task_data_by_identifier'](this.taskID))) {


        // define task data store
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'radios', 'data': this.radios})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'type', 'data': 'traditionalLikertScaleForm'})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'maxLabel', 'data': this.maxLabel})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'minLabel', 'data': this.minLabel})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'iconSet', 'data': this.iconSet})

        this.ready = true

      } else {

        // get data
        var data = this.$store.getters['store_lection/get_saved_lection_task_data_by_identifier'](this.taskID)
        this.radios = data.radios

        this.ready = true

      }
      
    },

    computed: {

        thisBtnDisabled () {

            var tmp = this.$store.getters['store_lection/get_saved_lection_data']
                
            if (tmp.data[this.id].finished == false) {

                return false

            } else {

                return true

            }
 
        },

    },

    watch: {

      // watch the checkbox-state and update the store immediatly on change
      radios: function(val) {

        if (val == undefined) {
            this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false})
        } else {
          this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': true})
        }

        
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'radios', 'data': val})
      
      }

    },

}   
</script>

<style scoped>

</style>