<template>
  <div class="mx-3" v-if="ready">

        <v-row>
            <div class="headline primary--text">{{ question }}</div>
        </v-row>

        <v-row>
            <div class="overline mb-3"><b>Tipp:</b> Wählen Sie bitte eine Zahl aus.</div>
        </v-row>
        
        <v-row align="center" justify="center">

          <v-icon class="mr-3 mt-5" v-if="iconSet == 'thermometer' && !$vuetify.breakpoint.smAndDown" large color="primary">fas fa-thermometer-empty</v-icon>
          <v-icon class="mr-3 mt-5" v-if="iconSet == 'smiley' && !$vuetify.breakpoint.smAndDown" large color="primary">far fa-sad-cry</v-icon>
          <v-icon class="mr-3 mt-5" v-if="iconSet == 'battery' && !$vuetify.breakpoint.smAndDown" large color="primary">fas fa-battery-empty</v-icon>

            <v-radio-group 
                v-model="radios" 
                :row="$vuetify.breakpoint.smAndDown ? false : true"
                hide-details
            >

                <div class="mb-3" v-if="$vuetify.breakpoint.smAndDown">
                    <v-icon v-if="iconSet == 'thermometer'" large color="primary">fas fa-thermometer-empty</v-icon>
                    <v-icon v-if="iconSet == 'smiley'" large color="primary">far fa-sad-cry</v-icon>
                    <v-icon v-if="iconSet == 'battery'" large color="primary">fas fa-battery-empty</v-icon>
                </div>


                <v-radio
                    v-for="index in values"
                    :key="index.name"
                    :disabled="thisBtnDisabled" 
                    color="accent" 
                    :value="index"
                    :label="index"
                ></v-radio>

                <div class="mb-3" v-if="$vuetify.breakpoint.smAndDown">
                    <v-icon v-if="iconSet == 'thermometer'" large color="primary">fas fa-thermometer-full</v-icon>
                    <v-icon v-if="iconSet == 'smiley'" large color="primary">far fa-laugh</v-icon>
                    <v-icon v-if="iconSet == 'battery'" large color="primary">fas fa-battery-full</v-icon>
                </div>

                <v-icon class="ml-3" v-if="iconSet == 'thermometer' && !$vuetify.breakpoint.smAndDown" large color="primary">fas fa-thermometer-full</v-icon>
                <v-icon class="ml-3" v-if="iconSet == 'smiley' && !$vuetify.breakpoint.smAndDown" large color="primary">far fa-laugh</v-icon>
                <v-icon class="ml-3" v-if="iconSet == 'battery' && !$vuetify.breakpoint.smAndDown" large color="primary">fas fa-battery-full</v-icon>

            </v-radio-group>
        </v-row>

  </div>
</template>

<script>
export default {
    name: 'traditional-likert-scale-rev',

    props: ['taskID', 'question', 'id', 'maxLabel', 'minLabel', 'iconSet'],

    data: () => ({

      radios: null,

      values: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],

      ready: true,

      thisBtnDisabled: false,

    }),

    created() {

        this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'type', 'val': 'traditionalLikertScaleForm'})
        this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'radios', 'val': this.radios})
        this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'iconSet', 'val': this.iconSet})
        this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'minLabel', 'val': this.minLabel})
        this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'maxLabel', 'val': this.maxLabel})
        this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'success', 'val': false})
      
    },

    computed: {

    },

    watch: {

      // watch the checkbox-state and update the store immediatly on change
      radios: function(val) {

        this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'radios', 'val': val})

        if (val == undefined) {
            this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'success', 'val': false})
        } else {
            this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'success', 'val': true})
        }

      }

    },

}   
</script>

<style scoped>
.mt-5 .v-slider__ticks .v-slider__ticks--always-show .span {
  color: black !important
}
  
</style>