import { render, staticRenderFns } from "./assessment_end.vue?vue&type=template&id=c0974df6&scoped=true&"
import script from "./assessment_end.vue?vue&type=script&lang=js&"
export * from "./assessment_end.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0974df6",
  null
  
)

export default component.exports