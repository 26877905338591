<template>
    <div>
        <div 
            v-for="column in data"
            :key="column.name"
            id="id"
        >

                <!-- ONE ROW -->

                <!-- type: one-row only text-->
                <v-row 
                    v-if="column.type == 'oneColumnOnlyText'"
                >
                    <v-col sm="12" cols="12">
                        <v-card 
                            raised 
                            :color="getCorrectColor(column.value.column_background)"
                        >                                
                            <v-card-text> 
                                <div class="headline mb-2" v-if="column.value.title !=''">{{ column.value.title }}</div>  
                                <div 
                                    v-html="column.value.text"
                                    class="subtitle-1 mb-0" 
                                ></div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <!-- type: one-row only image-->
                <v-row 
                    v-if="column.type == 'oneColumnOnlyImage'"
                >
                    <v-col sm="12">
                        <v-card 
                            raised 
                            :color="getCorrectColor(column.value.column_background)"
                        >   
                            <image-media 
                                :alt="column.value.img_alt"
                                :img_id="column.value.img_chooser" 
                                :border="'none'"
                            />
                        </v-card>                
                    </v-col>
                </v-row>

                <!-- type: one-row only video-->
                <v-row 
                    v-if="column.type == 'oneColumnOnlyVideo'"
                >
                    <v-col sm="12">
                        <v-card 
                            raised 
                            :color="getCorrectColor(column.value.column_background)"
                        >   
                            <video-media 
                                :source="column.value.vid_src" 
                                :border="'none'"
                                :video_id="column.value.vid_chooser"
                            />
                            
                        </v-card> 
                    </v-col>
                </v-row>

                <!-- type: one-row text and image-->
                <v-row 
                    v-if="column.type == 'oneColumnTextAndImage'"
                    >
                        <v-col sm="12">
                            <v-card 
                                raised 
                                :color="getCorrectColor(column.value.column_background)"
                            >  
                                <image-media 
                                    v-if="column.value.img_pos == 'top'"
                                    :alt="column.value.img_alt"
                                    :border="'top'"
                                    :img_id="column.value.img_chooser" 
                                />

                                <v-card-text> 
                                    <div class="headline mb-2" v-if="column.value.title !=''">{{ column.value.title }}</div>  
                                    <div 
                                        v-html="column.value.text"
                                        class="subtitle-1 mb-0" 
                                    ></div>
                                </v-card-text>

                                <image-media 
                                    v-if="column.value.img_pos == 'bottom'"
                                    :alt="column.value.img_alt"
                                    :border="'bottom'"
                                    :img_id="column.value.img_chooser" 
                                />

                            </v-card>

                        </v-col>
                </v-row>

                <!-- TWO ROWS -->


                <!-- type: twoColumnsOnlyImageOnlyText -->
               
                    <v-row 
                        v-if="column.value.content_order == 'text-image' && column.type == 'twoColumnsOnlyImageOnlyText'"
                        
                    >
                        <v-col :sm="getLeftColumnWidth(column.value.column_width)" cols="12">

                            <v-card 
                                raised 
                                :color="getCorrectColor(column.value.text_column_background)"
                                :height="column.value.height_adjustment"

                            >  
                                <v-card-text> 
                                    <div class="headline mb-2">{{ column.value.text_column_title }}</div>  
                                    <div 
                                        v-html="column.value.text_column_text"
                                        class="subtitle-1  mb-0" 
                                    ></div>
                                </v-card-text>
                            </v-card>

                        </v-col>

                        <v-col :sm="getRightColumnWidth(column.value.column_width)" cols="12">

                            <v-card 
                                raised 
                                :color="getCorrectColor(column.value.img_column_background)"
                                :height="column.value.height_adjustment"
                                class="d-flex flex-wrap"
                            >  
                                <image-media 
                                    :alt="column.value.img_column_img_alt" 
                                    :border="'none'"
                                    :img_id="column.value.img_column_img_chooser" 
                                />
                            </v-card>

                        </v-col>
                    </v-row>

                    <v-row 
                        v-if="column.value.content_order == 'image-text' && column.type == 'twoColumnsOnlyImageOnlyText'"
                    >
                        <v-col :sm="getLeftColumnWidth(column.value.column_width)" cols="12">
                            <v-card 
                                raised 
                                :color="getCorrectColor(column.value.img_column_background)"
                                :height="column.value.height_adjustment"
                                :class="get_d_flex"
                            >  
                                <image-media 
                                    :alt="column.value.img_column_img_alt" 
                                    :border="'none'"
                                    :img_id="column.value.img_column_img_chooser" 
                                />
                            </v-card>
                        </v-col>
                        <v-col :sm="getRightColumnWidth(column.value.column_width)" cols="12">
                            <v-card 
                                raised 
                                :color="getCorrectColor(column.value.text_column_background)"
                                :height="column.value.height_adjustment"
                            >  
                                <v-card-text> 
                                    <div class="headline mb-2">{{ column.value.text_column_title }}</div>  
                                    <div 
                                        v-html="column.value.text_column_text"
                                        class="subtitle-1  mb-0" 
                                    ></div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
               

                <!-- type: twoColumnsOnlyVideoOnlyText -->
                <div v-if="column.type == 'twoColumnsOnlyVideoOnlyText'">
                    <v-row 
                        v-if="column.value.content_order == 'text-image'"
                    >
                        <v-col :sm="getLeftColumnWidth(column.value.column_width)">
                            <v-card 
                                raised 
                                :color="getCorrectColor(column.value.text_column_background)"
                                :height="column.value.height_adjustment"
                                
                            >  
                                <v-card-text> 
                                    <div class="headline mb-2">{{ column.value.text_column_title }}</div>  
                                    <div 
                                        v-html="column.value.text_column_text"
                                        class="subtitle-1  mb-0" 
                                    ></div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col :sm="getRightColumnWidth(column.value.column_width)">
                            <v-card 
                                raised 
                                :color="getCorrectColor(column.value.vid_column_background)"
                                :height="column.value.height_adjustment"
                            >  
                                <video-media :source="column.value.vid_column_vid_src" :border="'none'" :video_id="column.value.vid_column_chooser"/>

                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row 
                        v-if="column.value.content_order == 'image-text'"
                    >
                        <v-col :sm="getLeftColumnWidth(column.value.column_width)">
                            <v-card 
                                raised 
                                :color="getCorrectColor(column.value.vid_column__background)"
                                :height="column.value.height_adjustment"
                                
                            >  
                                <video-media :source="column.value.vid_column_vid_src" :border="'none'" :video_id="column.value.vid_column_chooser"/>
                            </v-card>
                        </v-col>
                        <v-col :sm="getRightColumnWidth(column.value.column_width)">
                            <v-card 
                                raised 
                                :color="getCorrectColor(column.value.text_column_background)"
                                :height="column.value.height_adjustment"
                            >  
                                <v-card-text> 
                                    <div class="headline mb-2">{{ column.value.text_column_title }}</div>  
                                    <div 
                                        v-html="column.value.text_column_text"
                                        class="subtitle-1  mb-0" 
                                    ></div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>

                <!-- type: twoColumnsBothOnlyText -->
                <v-row v-if="column.type == 'twoColumnsBothOnlyText'">
                    <v-col :sm="getLeftColumnWidth(column.value.column_width)" cols="12">
                        <v-card 
                            raised 
                            :color="getCorrectColor(column.value.left_column_background)"
                            :height="column.value.height_adjustment"
                            
                        >  
                            <v-card-text> 
                                <div class="headline mb-2">{{ column.value.left_column_title }}</div>  
                                <div 
                                    v-html="column.value.left_column_text"
                                    class="subtitle-1  mb-0"
                                ></div>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col :sm="getRightColumnWidth(column.value.column_width)" cols="12">
                        <v-card 
                            raised 
                            :color="getCorrectColor(column.value.right_column_background)"
                            :height="column.value.height_adjustment"
                            
                        >  
                            <v-card-text> 
                                <div class="headline mb-2">{{ column.value.right_column_title }}</div>  
                                <div 
                                    v-html="column.value.right_column_text"
                                    class="subtitle-1  mb-0" 
                                ></div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <!-- type: twoColumnsOnlyTextTextAndImage -->
                <div v-if="column.type == 'twoColumnsOnlyTextTextAndImage'">
                    <v-row 
                        v-if="column.value.content_order == 'text-textandimage'"
                    >
                        <v-col :sm="getLeftColumnWidth(column.value.column_width)" cols="12">
                            <v-card 
                                raised 
                                :color="getCorrectColor(column.value.text_column_background)"
                                :height="column.value.height_adjustment"
                                
                            >  
                                <v-card-text> 
                                    <div class="headline mb-2">{{ column.value.text_column_title }}</div>  
                                    <div 
                                        v-html="column.value.text_column_text"
                                        class="subtitle-1  mb-0" 
                                    ></div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col :sm="getRightColumnWidth(column.value.column_width)" cols="12">
                            <v-card 
                                raised 
                                :color="getCorrectColor(column.value.textandimage_column_background)"
                                :height="column.value.height_adjustment"
                                
                            >  
                                <image-media 
                                    v-if="column.value.textandimage_column_img_pos == 'top'"
                                    :alt="column.value.textandimage_column_img_alt"
                                    :border="'top'"
                                    :img_id="column.value.textandimage_column_img_chooser" 
                                />
                                <v-card-text> 
                                    <div class="headline mb-2">{{ column.value.textandimage_column_title }}</div>  
                                    <div 
                                        v-html="column.value.textandimage_column_text"
                                        class="subtitle-1  mb-0" 
                                    ></div>
                                </v-card-text>
                                <image-media 
                                    v-if="column.value.textandimage_column_img_pos == 'bottom'"
                                    :alt="column.value.textandimage_column_img_alt"
                                    :border="'bottom'"
                                    :img_id="column.value.textandimage_column_img_chooser" 
                                />
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row 
                        v-if="column.value.content_order == 'textandimage-text'"
                    >
                        <v-col :sm="getLeftColumnWidth(column.value.column_width)" cols="12">
                            <v-card 
                                raised 
                                :color="getCorrectColor(column.value.textandimage_column_background)"
                                :height="column.value.height_adjustment"
                                
                            >  
                                <image-media 
                                    v-if="column.value.textandimage_column_img_pos == 'top'"
                                    :alt="column.value.textandimage_column_img_alt"
                                    :border="'top'"
                                    :img_id="column.value.textandimage_column_img_chooser" 
                                />
                                <v-card-text> 
                                    <div class="headline mb-2">{{ column.value.textandimage_column_title }}</div>  
                                    <div 
                                        v-html="column.value.textandimage_column_text"
                                        class="subtitle-1  mb-0" 
                                    ></div>
                                </v-card-text>
                                <image-media 
                                    v-if="column.value.textandimage_column_img_pos == 'bottom'"
                                    :alt="column.value.textandimage_column_img_alt"
                                    :border="'bottom'"
                                    :img_id="column.value.textandimage_column_img_chooser" 
                                />
                            </v-card>
                        </v-col>
                        <v-col :sm="getRightColumnWidth(column.value.column_width)" cols="12">
                            <v-card 
                                raised 
                                :color="getCorrectColor(column.value.text_column_background)"
                                :height="column.value.height_adjustment"
                                
                            >  
                                <v-card-text> 
                                    <div class="headline mb-2">{{ column.value.text_column_title }}</div>  
                                    <div 
                                        v-html="column.value.text_column_text"
                                        class="subtitle-1  mb-0" 
                                    ></div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>

                <!-- type: twoColumnsOnlyImageTextAndImage -->
                <div v-if="column.type == 'twoColumnsOnlyImageTextAndImage'">
                    <v-row 
                        v-if="column.value.content_order == 'text-image'"
                    >
                        <v-col :sm="getLeftColumnWidth(column.value.column_width)" cols="12">
                            <v-card 
                                raised 
                                :color="getCorrectColor(column.value.textandimage_column_background)"
                                :height="column.value.height_adjustment"
                               
                            >  
                                <image-media 
                                    v-if="column.value.textandimage_column_img_pos == 'top'"
                                    :alt="column.value.textandimage_column_img_alt"
                                    :border="'top'"
                                    :img_id="column.value.textandimage_column_img_chooser" 
                                />
                                <v-card-text> 
                                    <div class="headline mb-2">{{ column.value.textandimage_column_title }}</div>  
                                    <div 
                                        v-html="column.value.textandimage_column_text"
                                        class="subtitle-1  mb-0" 
                                    ></div>
                                </v-card-text>
                                <image-media 
                                    v-if="column.value.textandimage_column_img_pos == 'bottom'"
                                    :alt="column.value.textandimage_column_img_alt"
                                    :border="'bottom'"
                                    :img_id="column.value.textandimage_column_img_chooser" 
                                />
                            </v-card>
                        </v-col>
                        <v-col :sm="getRightColumnWidth(column.value.column_width)" cols="12">
                            <v-card 
                                raised 
                                :color="getCorrectColor(column.value.img_column_background)"
                                :height="column.value.height_adjustment"
                                
                            >  
                                <image-media 
                                    :alt="column.value.img_column_img_alt" 
                                    :border="'none'"
                                    :img_id="column.value.img_column_img_chooser" 
                                />
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row 
                        v-if="column.value.content_order == 'image-text'"
                    >
                        <v-col :sm="getLeftColumnWidth(column.value.column_width)" cols="12">
                            <v-card 
                                raised 
                                :color="getCorrectColor(column.value.img_column_background)"
                                :height="column.value.height_adjustment"
                                
                            >  
                                <image-media 
                                    :alt="column.value.img_column_img_alt" 
                                    :border="'none'"
                                    :img_id="column.value.img_column_img_chooser" 
                                />
                            </v-card>
                        </v-col>
                        <v-col :sm="getRightColumnWidth(column.value.column_width)" cols="12">
                            <v-card 
                                raised 
                                :color="getCorrectColor(column.value.textandimage_column_background)"
                                :height="column.value.height_adjustment"
                                
                            >  
                                <image-media 
                                    v-if="column.value.textandimage_column_img_pos == 'top'"
                                    :alt="column.value.textandimage_column_img_alt"
                                    :border="'top'"
                                    :img_id="column.value.textandimage_column_img_chooser" 
                                />
                                <v-card-text> 
                                    <div class="headline mb-2">{{ column.value.textandimage_column_title }}</div>  
                                    <div 
                                        v-html="column.value.textandimage_column_text"
                                        class="subtitle-1  mb-0" 
                                    ></div>
                                </v-card-text>
                                <image-media 
                                    v-if="column.value.textandimage_column_img_pos == 'bottom'"
                                    :alt="column.value.textandimage_column_img_alt"
                                    :border="'bottom'"
                                    :img_id="column.value.textandimage_column_img_chooser" 
                                />
                            </v-card>
                        </v-col>
                    </v-row>
                </div>

                <!-- type: twoColumnsOnlyVideoTextAndImage -->
                <div v-if="column.type == 'twoColumnsOnlyVideoTextAndImage'">
                    <v-row 
                        
                        v-if="column.value.content_order == 'text-image'"
                    >
                        <v-col :sm="getLeftColumnWidth(column.value.column_width)" cols="12">
                            <v-card 
                                raised 
                                :color="getCorrectColor(column.value.textandimage_column_background)"
                                :height="column.value.height_adjustment"
                                
                            >  
                                <image-media 
                                    v-if="column.value.textandimage_column_img_pos == 'top'"
                                    :alt="column.value.textandimage_column_img_alt"
                                    :border="'big'"
                                    :img_id="column.value.textandimage_column_img_chooser" 
                                />
                                <v-card-text> 
                                    <div class="headline mb-2">{{ column.value.textandimage_column_title }}</div>  
                                    <div 
                                        v-html="column.value.textandimage_column_text"
                                        class="subtitle-1  mb-0" 
                                    ></div>
                                </v-card-text>
                                <image-media 
                                    v-if="column.value.textandimage_column_img_pos == 'bottom'"
                                    :alt="column.value.textandimage_column_img_alt"
                                    :border="'big-bottom'"
                                />
                            </v-card>
                        </v-col>
                        <v-col :sm="getRightColumnWidth(column.value.column_width)" cols="12">
                            <v-card 
                                raised 
                                :color="getCorrectColor(column.value.vid_column_background)"
                                :height="column.value.height_adjustment"
                                
                            >  
                                <video-media :source="column.value.vid_column_vid_src" :border="'none'" :video_id="column.value.vid_column_chooser"/>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row 
                        v-if="column.value.content_order == 'image-text'"
                    >
                        <v-col :sm="getLeftColumnWidth(column.value.column_width)" cols="12">
                            <v-card 
                                raised 
                                :color="getCorrectColor(column.value.vid_column_background)"
                                :height="column.value.height_adjustment"
                                
                            >  
                                <video-media :source="column.value.vid_column_vid_src" :border="'none'" :video_id="column.value.vid_column_chooser"/>
                            </v-card>
                        </v-col>
                        <v-col :sm="getRightColumnWidth(column.value.column_width)" cols="12">
                            <v-card 
                                raised 
                                :color="getCorrectColor(column.value.textandimage_column_background)"
                                :height="column.value.height_adjustment"
                                
                            >  
                                <image-media 
                                    v-if="column.value.textandimage_column_img_pos == 'top'"
                                    :alt="column.value.textandimage_column_img_alt"
                                    :border="'big'"
                                    :img_id="column.value.textandimage_column_img_chooser" 
                                />
                                <v-card-text> 
                                    <div class="headline mb-2">{{ column.value.textandimage_column_title }}</div>  
                                    <div 
                                        v-html="column.value.textandimage_column_text"
                                        class="subtitle-1  mb-0" 
                                    ></div>
                                </v-card-text>
                                <image-media 
                                    v-if="column.value.textandimage_column_img_pos == 'bottom'"
                                    :alt="column.value.textandimage_column_img_alt"
                                    :border="'big-bottom'"
                                    :img_id="column.value.textandimage_column_img_chooser" 
                                />
                            </v-card>
                        </v-col>
                    </v-row>
                </div>

                <!-- type: twoColumnsBothTextAndImage -->
                <div v-if="column.type == 'twoColumnsBothTextAndImage'">
                    <v-row>
                        <v-col :sm="getLeftColumnWidth(column.value.column_width)" cols="12">
                            <v-card 
                                raised 
                                :color="getCorrectColor(column.value.left_column_background)"
                                :height="column.value.height_adjustment"
                                
                            >  
                                <image-media 
                                    v-if="column.value.left_column_img_pos == 'top'"
                                    :alt="column.value.left_column_img_alt"
                                    :border="'top'"
                                    :img_id="column.value.left_column_img_chooser" 
                                />
                                <v-card-text> 
                                    <div class="headline mb-2">{{ column.value.left_column_title }}</div>  
                                    <div 
                                        v-html="column.value.left_column_text"
                                        class="subtitle-1  mb-0" 
                                    ></div>
                                </v-card-text>
                                <image-media 
                                    v-if="column.value.left_column_img_pos == 'bottom'"
                                    :alt="column.value.left_column_img_alt"
                                    :border="'bottom'"
                                    :img_id="column.value.left_column_img_chooser" 
                                />
                            </v-card>
                        </v-col>
                        <v-col :sm="getRightColumnWidth(column.value.column_width)" cols="12">
                            <v-card 
                                raised 
                                :color="getCorrectColor(column.value.right_column_background)"
                                :height="column.value.height_adjustment"
                                
                            >  
                                <image-media 
                                    v-if="column.value.right_column_img_pos == 'top'"
                                    :alt="column.value.right_column_img_alt"
                                    :border="'top'"
                                    :img_id="column.value.right_column_img_chooser" 
                                />
                                <v-card-text> 
                                    <div class="headline mb-2">{{ column.value.right_column_title }}</div>  
                                    <div 
                                        v-html="column.value.right_column_text"
                                        class="subtitle-1  mb-0" 
                                    ></div>
                                </v-card-text>
                                <image-media 
                                    v-if="column.value.right_column_img_pos == 'bottom'"
                                    :alt="column.value.right_column_img_alt"
                                    :border="'bottom'"
                                    :img_id="column.value.right_column_img_chooser" 
                                />
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
        </div>

        <!-- check -->
        <v-row>
            <v-col sm="12" >
                <v-card color="transparent" raised height="100%">
                    <v-card-text>
                        <v-row  align="center" class="ma-2">
                            <div v-if="!thisBtnDisabled">
                                <v-icon medium>fas fa-pencil-alt</v-icon>
                            </div>
                            <div v-else>
                                <v-icon medium>fas fa-check</v-icon>
                            </div>
                            <v-divider class="mx-4"/>
                            <v-btn 
                                rounded
                                outlined 
                                large 
                                color="primary" 
                                @click="save"
                                :disabled="thisBtnDisabled"
                            >Fertig</v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- DEV DIALOG -->
        <v-dialog v-model="dialog" persistent max-width="320">

            <v-card>
                <v-card-title class="headline">Bitte beachten Sie:</v-card-title>
                <v-card-text>
                    Da dieser Klient als Development-Klient definiert ist, haben sie die Wahl, 
                    ob sie die Daten speichern möchten oder nicht. Solange Sie an dieser Lektion 
                    arbeiten, wird empfohlen, die Daten <b>nicht</b> zu speichern.
                </v-card-text>
                <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="primary" text @click="doNotSave()">Nicht speichern</v-btn>
                <v-btn color="primary" text @click="saveDev()">Speichern</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

    </div>
</template>

<script>
import HelperClientIntervention from '@/_helpers/helper_client_intervention'

import imageMedia from '@/components/_client/_intervention/_basicMediaBlocks/imageMedia.vue'
import videoMedia from '@/components/_client/_intervention/_basicMediaBlocks/videoMedia.vue'

export default {
    name: 'basicInfo',

    props: ['data', 'id', 'reward'],

    components: {
        imageMedia,
        videoMedia
    },

    data () {
        return {

            dialog: false,

            dev_save: false,

            dev_checked: false,

        }
    },

    mounted() {

        // ensure that all external links are save and open in a new div

        var linkList = document.querySelectorAll('#id a');

        var external = RegExp('^((f|ht)tps?:)?//(?!' + location.host + ')');

        for(var i in linkList){

            if (external.test(linkList[i].href)) {
                linkList[i].setAttribute('target', '_blank');
                linkList[i].setAttribute('rel', 'noopener noreferrer');
            }
            
        }

    },

    computed: {

        get_d_flex() {

            if (this.$vuetify.breakpoint.smAndDown == true) {
                return ''
            } else {
                return 'd-flex flex-wrap'
            }

        },

        thisBtnDisabled () {

            var tmp = this.$store.getters['store_lection/get_saved_lection_data']
            
            if (tmp.lession_finished) {
                return true
            } else {
                return tmp.data[this.id].finished 
            }

        },      

    }, 

    methods: {

        getCorrectColor(input) {

            switch (input) {

              case 'transparent': return 'transparent'
              case 'primary': return 'primary'      // old value: "primary": "#0277bd"
              case 'secondary lighten-5': return '#c1c1c1'
              case 'accent': return '#82B1FF'

            }

        },

        getLeftColumnWidth(keyword) {

            if (this.$vuetify.breakpoint.smAndDown == true) {
                return '12'
            } else {
                if (keyword == 'equal') {
                    return '6'
                } else if (keyword == 'smaller-left') {
                    return '4'
                } else if (keyword == 'smaller-right') {
                    return '8'
                }
            }

        },

        getRightColumnWidth(keyword) {

            if (this.$vuetify.breakpoint.smAndDown == true) {
                return '12'
            } else {
                if (keyword == 'equal') {
                    return '6'
                } else if (keyword == 'smaller-left') {
                    return '8'
                } else if (keyword == 'smaller-right') {
                    return '4'
                }
            }
        },

        // DEV ---
        doNotSave() {

            this.dev_save = false
            this.dev_checked = true
            this.dialog = false

            this.save()

        },

        saveDev() {

            this.dev_save = true
            this.dev_checked = true
            this.dialog = false

            this.save()

        },

        // DEV END ---

         // save data
       save() {

           if (this.$store.getters['store_client/get_client'].is_dev && this.dev_checked == false) {

               this.dialog = true

           } else if (this.$store.getters['store_client/get_client'].is_dev && this.dev_checked == true) {

               if (this.dev_save == true) {

                   const data = {
                        'save_lection_data': true,
                        'module': 0,
                        'session': 0,
                        'lection': this.$route.params.rank,
                        'id': this.id,
                        'type': 'info',
                        'reward': parseInt(this.reward) 
                    }

                    HelperClientIntervention.InterventionNext(data).then(() => {

                    })


               } else {

                   const data = {
                        'save_lection_data': false,
                        'module': 0,
                        'session': 0,
                        'lection': this.$route.params.rank,
                        'id': this.id,
                        'type': 'info',
                        'reward': parseInt(this.reward) 
                    }


                    HelperClientIntervention.InterventionNext(data).then(() => {

                        console.log('NEXT')

                    })

               }

           } else {

               const data = {
                   'save_lection_data': true,
                   'module': 0,
                   'session': 0,
                   'lection': this.$route.params.rank,
                   'id': this.id,
                   'type': 'info',
                   'reward': parseInt(this.reward) 
               }

               HelperClientIntervention.InterventionNext(data).then(() => {

               })

           }

       }

    }
}
</script>

<style scoped>

</style>

