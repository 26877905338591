<template>

    <div class="mx-3">

        <v-row>

            <v-col cols="12" sm="12">

                <v-card
                    class="mx-auto"
                    outlined
                >

                    <v-card-text v-if="$vuetify.breakpoint.mdAndUp">

                        <v-row 
                            v-for="(item, i) in data.itemList" 
                            :key="item.name"
                            class="my-3"
                            align="center"
                        >
                            <v-card color="primary" class="mx-auto">
                                <v-card-text class="pa-1 text-center body-1 white--text">
                                    {{ item }}
                                </v-card-text>
                            </v-card>

                            <v-divider class="mx-4"/>

                            <v-card
                                class="mx-auto"
                                flat
                            >

                                <v-card>

                                    <v-alert outlined :color="getCorrectColor(i, data.dropLists[i][0])" class="pa-0">
                                        <v-card-text class="text-center body-1 pa-2">{{ data.dropLists[i][0] }}</v-card-text>
                                    </v-alert>

                                </v-card>

                            </v-card>

                        </v-row>

                    </v-card-text>

                    <v-card-text class="pt-0" v-else>

                        <v-row 
                            v-for="(item, i) in data.itemList" 
                            :key="item.name"
                            :class="i > 0 ? 'my-3' : 'mb-3'"
                            justify="center" align="center"
                        >
                            <v-col cols="12" sm="12">
                                <v-divider v-if="i > 0" />
                            </v-col>
                                
                            <v-col cols="12" sm="12">

                                <v-row align="center" justify="center">
                                    <v-card color="primary" class="mx-auto">
                                        <v-card-text class="pa-1 text-center body-1 white--text">
                                            {{ item }}
                                        </v-card-text>
                                    </v-card>
                                </v-row>

                            </v-col>

                            <v-col cols="12" sm="12">

                                <div class="vl" />

                            </v-col>

                            <v-card
                                class="mx-auto"
                                flat
                            >

                                <v-card>

                                    <v-alert outlined :color="getCorrectColor(i, data.dropLists[i][0])" class="pa-0">
                                        <v-card-text class="text-center body-1 pa-2">{{ data.dropLists[i][0] }}</v-card-text>
                                    </v-alert>
                                    
                                </v-card>

                            </v-card>


                        </v-row>

                    </v-card-text>

                </v-card>

            </v-col>

        </v-row>

    </div>

</template>

<script>
export default {
    name: 'simple-assignment-task-data',

    props: ['data'],

    methods: {

        getCorrectColor(i, item) {

            if (this.data.correctList[item].correct_index == i) {

                return 'success'

            } else {

                return 'error'

            }

        },

    }
}
</script>

<style scoped>
.custom-chip {

    display: inline-flex;
    flex-direction: row;
    background-color: #004b8c;

    max-width: 50%;

    height: auto;

    outline: none;

    padding-top: 5px;
    padding-right: 12px;
    padding-bottom: 5px;
    padding-left: 12px;

    line-height: 20px;

    white-space: pre-wrap;

    align-items: center;

    border-radius: 16px;
    font-size: 14px;

    vertical-align: middle;
    text-decoration: none;
    justify-content: center;

}

.drop-item {
    top: 50%;
    transform: translateY(-50%);
}

.fill-divider {

    display: flex !important;
    justify-content: center !important;
    flex-direction: row !important;

    min-width: 80px;
    min-height: 45px;

}

.fill-layout {
    display: flex;
    justify-content: center;
    flex-direction: row;

    width: auto; 
    min-width: 80px;
    min-height: 45px;

    background: #EEEEEE;

    border-style: solid;
    border-width: 2px;
    border-radius: 5px !important;
    border-color: rgb(148, 148, 148) !important;
}

.fill-p {
    display: flex;
    justify-content: center;
    flex-direction: column;

    width: auto;
    height: 100%;
    min-height: 40px;
}

.fill-box {
    display: flex;
    justify-content: center;
    flex-direction: column;

    min-height: 45px;
    min-width: 100px;

    background: #EEEEEE;

    border-style: solid;
    border-width: 2px;
    border-radius: 5px !important;
    border-color: rgb(148, 148, 148) !important;
}

.drop-main {
    display: flex;
    justify-content:flex-start;
    flex-direction: row;

    min-width: 100%;
    min-height: 45px;

    background: #EEEEEE;

    border-style: solid;
    border-width: 1px;
    border-radius: 5px !important;
    border-color: rgb(148, 148, 148) !important;
}

.sentence {
    
    width: 100%;
    
}

.sentence-main-row {

    border-style: solid;
    border-width: 1px;
    border-radius: 5px !important;
    border-color: rgb(148, 148, 148) !important;
    padding: 10px;

}
</style>
