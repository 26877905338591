<template>
  <div class="mx-3" v-if="ready">

    <v-row>
      <div class="headline mt-5 mb-3 primary--text">{{ id + 1}}. {{ question }}</div>
    </v-row>

    <v-row>
      <div class="overline mb-3" v-if="nrCorrectAnswers > 1"><b>Tipp:</b> {{ nrCorrectAnswers }} Antworten sind richtig.</div>
      <div class="overline mb-3" v-else><b>Tipp:</b> Nur eine Antwort ist richtig.</div>
    </v-row>

    <v-row no-gutters class="mb-6">

        <v-col 
          :cols="responsiveColumn" 
          v-for="(label, i) in labels" 
          :key="label.name"
        >

          <v-checkbox
              v-model="checkbox[i]"   
              :label="label.choice"
              data-vv-name="checkbox"
              type="checkbox"
              :disabled="thisBtnDisabled[i]"
              class="mx-3 mt-0 mb-2 pl-0"
              color="accent"
              hide-details
              @change="update_current_field_value(label.correctAnswer, i)"
          ></v-checkbox>

        </v-col>

    </v-row>

    <v-row 
      align="center" 
      justify="center"
      v-for="(label, i) in labels" 
      :key="label.name"
      >

      <v-alert
        :value="alert[i]"
        :color="alert_color[i]"
        dark
        border="top"
        :icon="alert_icon[i]"
        width="100%"
        class="mb-1"
      >
        <div class="" v-html="alert_text[i]"></div>
      </v-alert>

    </v-row>

    <v-row 
      align="center" 
      justify="center"
      >

      <v-alert
        :value="alert_more_answers"
        color="primary"
        dark
        border="top"
        icon="fas fa-lightbulb"
        width="100%"
        class="mb-1"
      >
        <div v-if="number_more_alerts == 1">Es ist noch eine weitere Antwortoptionen richtig! Bitte wählen sie noch eine weitere Antwortoptionen aus.</div>
        <div v-else>Es sind noch {{number_more_alerts}} weitere Antwortoptionen richtig! Bitte wählen sie noch {{number_more_alerts}} weitere Antwortoptionen aus.</div>
      </v-alert>

    </v-row>

  </div>


</template>

<script>
import _ from 'lodash'
export default {
    name: 'quizMultipleChoicePickNForm',

    props: ['taskID', 'taskChoices', 'question', 'id'],

    data: () => ({

      ready: false,

      alert: [],
      alert_icon: [],
      alert_color: [],
      alert_text: [],
      finished: false,
      save_clicks: 0,


      checkbox: [],
      labels: [],
      nrCorrectAnswers: 0,

      number_more_alerts: 0,
      alert_more_answers: false

    }),

    created () {

      this.ready = false
      this.save_clicks = 0

      if (_.isEmpty(this.$store.getters['store_lection/get_saved_lection_quiz_data_by_identifier'](this.taskID, this.id))) {


        // copy the prop in order to allow changes
        this.labels = Object.assign({}, this.taskChoices)

        var labels = []

        // define all checkboxes per default not picked
        for (var i = 0; i < this.taskChoices.length; i++) {

          this.checkbox.push(false)

          this.alert.push(false)
          this.alert_icon.push('far fa-check-circle')
          this.alert_color.push('success')
          this.alert_text.push(this.labels[i].feedback)

          labels.push(this.labels[i])

          if (this.labels[i].correctAnswer == true) {

              this.nrCorrectAnswers = this.nrCorrectAnswers + 1

          }
        }

        this.$store.dispatch('store_lection/DEFINE_SAVED_LECTION_QUIZ_DATA_NEW_DATA_IDENTIFIER_ID', {'taskID': this.taskID, 'id': this.id})

        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_QUIZ_DATA_PROPERTY_AND_DATA', {'taskID': this.taskID, 'identifier': this.id, 'property_name': 'checkbox', 'data': this.checkbox})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_QUIZ_DATA_PROPERTY_AND_DATA', {'taskID': this.taskID, 'identifier': this.id, 'property_name': 'labels', 'data': this.labels})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_QUIZ_DATA_PROPERTY_AND_DATA', {'taskID': this.taskID, 'identifier': this.id, 'property_name': 'alert', 'data': this.alert})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_QUIZ_DATA_PROPERTY_AND_DATA', {'taskID': this.taskID, 'identifier': this.id, 'property_name': 'alert_icon', 'data': this.alert_icon})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_QUIZ_DATA_PROPERTY_AND_DATA', {'taskID': this.taskID, 'identifier': this.id, 'property_name': 'alert_color', 'data': this.alert_color})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_QUIZ_DATA_PROPERTY_AND_DATA', {'taskID': this.taskID, 'identifier': this.id, 'property_name': 'alert_text', 'data': this.alert_text})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_QUIZ_DATA_PROPERTY_AND_DATA', {'taskID': this.taskID, 'identifier': this.id, 'property_name': 'finished', 'data': this.finished})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_QUIZ_DATA_PROPERTY_AND_DATA', {'taskID': this.taskID, 'identifier': this.id, 'property_name': 'type', 'data': 'multipleChoicePickNTask'})

        this.ready = true

      } else {

        var data = this.$store.getters['store_lection/get_saved_lection_task_data'][this.taskID]

        this.checkbox = data[this.id].checkbox
        this.labels = data[this.id].labels
        this.alert = data[this.id].alert
        this.alert_icon= data[this.id].alert_icon
        this.alert_color = data[this.id].alert_color
        this.alert_text = data[this.id].alert_text
        this.finished = data[this.id].finished

        // define all checkboxes per default not picked
        for (i = 0; i < this.taskChoices.length; i++) {

          if (this.labels[i].correctAnswer == true) {

              this.nrCorrectAnswers = this.nrCorrectAnswers + 1

          }
        }

        this.ready = true

      }
      
  },

    computed: {
        
        thisBtnDisabled() {

            var tmp = this.$store.getters['store_lection/get_saved_lection_data']

            var disabled_array = []

            var nrTrueCheckboxes = 0;

            for (var k = 0; k < this.taskChoices.length; k++) {

              disabled_array.push(false)

            }
                
            if (tmp.data[this.taskID].finished == false) {

                for (var l = 0; l < this.checkbox.length; l++) {

                    if (this.checkbox[l] == true) {

                        nrTrueCheckboxes = nrTrueCheckboxes + 1

                    }
                }

                if (nrTrueCheckboxes >= this.nrCorrectAnswers) {

                  for (var m = 0; m < disabled_array.length; m++) {

                    if (this.checkbox[m] == true) {

                      disabled_array[m] = false

                    } else {

                      disabled_array[m] = true

                    }

                  }

                } else {

                  for (var j = 0; j < disabled_array.length; j++) {

                    disabled_array[j] = false

                  }

                }

                return disabled_array


            } else {


              for (j = 0; j < disabled_array.length; j++) {

                disabled_array[j] = true

              }

              return disabled_array
  
            }

        },

        responsiveColumn() {

          if (this.taskChoices.length <= 5) {

            return '12'

          } else {

              if (this.$vuetify.breakpoint.name == 'xs' || this.$vuetify.breakpoint.name == 'sm') {

                return '12'

              } else {

                return '6'

              }

            }
        
        },

    },

    watch: {

      checkbox: function() {

        // zeige zusätzliche Info nur dann, wenn:
        // (1) NUR wenn korrekte Antworten ausgewählt sind UND
        // (2) Anzahl aller Antworten < nrCorrectAnswers

        var nrTrueCheckboxes = 0

        for (var l = 0; l < this.checkbox.length; l++) {

            if (this.checkbox[l] == true) {

                nrTrueCheckboxes = nrTrueCheckboxes + 1

            }
        }

        // check if only correct answers
        var onlyCorrectAnswers = true

        for (var m = 0; m < this.checkbox.length; m++) {

            if (this.checkbox[m] == true) {
                
                if(this.labels[m].correctAnswer == false) {

                    onlyCorrectAnswers = false

                }
            }
        }

        if (onlyCorrectAnswers && nrTrueCheckboxes < this.nrCorrectAnswers && nrTrueCheckboxes != 0) {
          this.alert_more_answers = true
          this.number_more_alerts = this.nrCorrectAnswers - nrTrueCheckboxes
        } else {
          this.alert_more_answers = false
        }

      }

    },

    methods: {

      update_current_field_value(correct, i) {

        this.alert[i] = !this.alert[i]

        if (correct == true) {

          this.alert_icon[i] = 'far fa-check-circle'
          this.alert_color[i] = 'success'

        } else {

          this.alert_icon[i] = 'fas fa-exclamation-triangle'
          this.alert_color[i] = 'error'

        }

        // update store
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_QUIZ_DATA_PROPERTY_AND_DATA', {'taskID': this.taskID, 'identifier': this.id, 'property_name': 'checkbox', 'data': this.checkbox})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_QUIZ_DATA_PROPERTY_AND_DATA', {'taskID': this.taskID, 'identifier': this.id, 'property_name': 'labels', 'data': this.labels})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_QUIZ_DATA_PROPERTY_AND_DATA', {'taskID': this.taskID, 'identifier': this.id, 'property_name': 'alert', 'data': this.alert})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_QUIZ_DATA_PROPERTY_AND_DATA', {'taskID': this.taskID, 'identifier': this.id, 'property_name': 'alert_icon', 'data': this.alert_icon})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_QUIZ_DATA_PROPERTY_AND_DATA', {'taskID': this.taskID, 'identifier': this.id, 'property_name': 'alert_color', 'data': this.alert_color})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_QUIZ_DATA_PROPERTY_AND_DATA', {'taskID': this.taskID, 'identifier': this.id, 'property_name': 'alert_text', 'data': this.alert_text})
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_QUIZ_DATA_PROPERTY_AND_DATA', {'taskID': this.taskID, 'identifier': this.id, 'property_name': 'finished', 'data': this.finished})

        // check if correct nr of correct answers
        var nrTrueCheckboxes = 0

            for (var l = 0; l < this.checkbox.length; l++) {

                if (this.checkbox[l] == true) {

                    nrTrueCheckboxes = nrTrueCheckboxes + 1

                }
            }

            if (nrTrueCheckboxes < this.nrCorrectAnswers) {
             
                 if (this.save_clicks == 1) {

                  this.$store.dispatch('store_quiz/UPDATE_CORRECT_ANSWERS_IN_CURRENT_QUIZ', this.$store.getters['store_quiz/get_correct_answers_in_current_quiz'] - 1)
                  this.save_clicks = this.save_clicks - 1

                }

            } else if (nrTrueCheckboxes > this.nrCorrectAnswers) {

                if (this.save_clicks == 1) {

                  this.$store.dispatch('store_quiz/UPDATE_CORRECT_ANSWERS_IN_CURRENT_QUIZ', this.$store.getters['store_quiz/get_correct_answers_in_current_quiz'] - 1)
                  this.save_clicks = this.save_clicks - 1

                }

            } else if (nrTrueCheckboxes == this.nrCorrectAnswers) {

                var correctAnswers = 0

                // check if choosen answers are the correct ones
                for (var m = 0; m < this.checkbox.length; m++) {

                    if (this.checkbox[m] == true) {
                        
                        if(this.labels[m].correctAnswer == true) {

                            correctAnswers = correctAnswers + 1

                        }
                    }
                }

                if (correctAnswers == this.nrCorrectAnswers) {

                    if (this.save_clicks == 0) {
                      this.save_clicks = this.save_clicks + 1
                      this.$store.dispatch('store_quiz/UPDATE_CORRECT_ANSWERS_IN_CURRENT_QUIZ', this.$store.getters['store_quiz/get_correct_answers_in_current_quiz'] + 1)
                    }

                } else {

                    if (this.save_clicks == 1) {

                      this.$store.dispatch('store_quiz/UPDATE_CORRECT_ANSWERS_IN_CURRENT_QUIZ', this.$store.getters['store_quiz/get_correct_answers_in_current_quiz'] - 1)
                      this.save_clicks = this.save_clicks - 1

                    }

                }
            }

      }, 

    }
}   
</script>

<style scoped>
  
</style>