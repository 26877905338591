import { render, staticRenderFns } from "./client_assessment_single_questionnaire.vue?vue&type=template&id=6f0a925c&scoped=true&"
import script from "./client_assessment_single_questionnaire.vue?vue&type=script&lang=js&"
export * from "./client_assessment_single_questionnaire.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f0a925c",
  null
  
)

export default component.exports