var help_object = {

    /**
     * INTERVENTION
     * 
     * insgesamt 29 guided tasks
     */

    /**
     * Modul 1
     */

    'm1_s1_l4_t1': '<ul><li>Es soll der wichtigste Wunsch für die Zukunft aufgeschrieben werden</li><li>Es ist wichtig, dass der genannte Wunsch zu einem Ziel hinführt</li><li>Bei Unklarheiten, die aber nicht deutlich destruktiv ausgelegt sind oder die Aufgabe offensichtlich bewusst missverstehen, noch einmal kurz nachfragen</li><li>Wichtigster Wunsch für die Zukunft wird aufgeschrieben (in Sitzung 3 soll aus dem genannten Wunsch ein Ziel formuliert werden)</li><li>Die Aufgabe ist korrekt beantwortet, wenn ein Wunsch genannt wird der weder dem TN noch jemand anderem schadet.</li></ul>',

    'm1_s1_l6_t1': '<ul><li>Es sollen die schlimmsten Folgen aufgeschrieben werden, wenn der Teilnehmende wieder eine Straftat begeht</li><li>Die Antwort ist korrekt beantwortet, wenn negative Folgen von erneuten Straftaten genannt werden</li></ul>',

    'm1_s1_l7_t1': '<ul><li>Es sollen vier Wege/Dinge aufgeschrieben werden, mit denen sich der TN nach der Sitzung belohnen kann</li><li>Die Antwort ist korrekt beantwortet, wenn mindestens ein Weg gefunden wurde. Dieser Weg darf weder den TN noch jemand anderem schaden und muss realistisch sein.</li><li>Belohnung darf weder für TN noch jemand anderen potentiell schädlich sein',

    'm1_s2_l7_t1': '<ul><li>Es soll eine Tabelle erstellt werden, in der alle Vorteile und alle Nachteile gesucht werden, die die Straftat für den TN hatte. Diese sollen in kurzfristige und langfristige Folgen unterteilt werden</li><li>Die Übung ist korrekt bearbeitet, wenn die Tabelle ausgefüllt ist und mehr Nachteile als Vorteile gefunden wurden</li></ul>',

    'm1_s3_l6_t1': '<ul><li>TN sollen ein konkretes Ziel aufschreiben und 4 Zwischenschritte, um das Ziel erreichen zu können</li><li>Die Übung ist korrekt beantwortet, wenn es sich um ein konkretes Ziel handelt (messbar, realistisch, sinnhaft) und wenn der TN mindestens 2 Zwischenschritte gefunden hat um dieses Ziel zu erreichen (in den Zwischenschritten soll auch konkret drinstehen, was er bis wann gemacht haben möchte)</li></ul>',

    'm1_s3_l6_t2': '<ul><li>TN sollen überlegen, wie sie sich an Ziele erinnern können</li><li>Übung ist korrekt beantwortet, wenn mindestens eine Erinnerungsmöglichkeit genannt wird</li></ul>',

    'm1_s4_l4_t2': '<ul><li>TN sollen überlegen, warum es ihnen wichtig ist, keine Straftat mehr zu begehen</li><li>Übung ist korrekt beantwortet, wenn mindestens ein Grund genannt wird</li></ul>',

    'm1_s4_l5_t1': '<ul><li>TN soll sagen, wann er vorhat an den Sitzungen von @myTabu teilzunehmen</li><li>Übung ist korrekt beantwortet, wenn er genau nennt, wann er die Sitzungen bearbeiten möchte (an einem bestimmten Tag, mit einem Hobby verknüpft o.ä.)</li></ul>',

    /**
     * MODUL 2
     */

    'm2_s1_l4_t1': 'Die Übung wurde richtig bearbeitet, wenn die Teilnehmenden eine Situation mit ihren Bewährungshelfer*innen (BH) skizzieren, in der das Aufkommen innerer Widerstände nachvollziehbar erscheint, weil die Teilnehmenden Kooperationsbereitschaft zeigen müssen. Typischerweise handelt es sich dabei um eine Situation, in der die BH Anforderungen an die Teilnehmenden stellen, vermeintliche Erwartungen an sie haben oder Ratschläge erteilen, die die Teilnehmenden nicht annehmen möchten. Die Antworten der Teilnehmenden werden in der zweiten Sitzung (Kooperationsbereitschaft) des zweiten Moduls aufgegriffen, wenn es um die Vor- und Nachteile einer Kooperation mit BH geht. Die Situation soll den Teilnehmenden also die Möglichkeit bieten, sich Vor- und Nachteile zu überlegen, wenn sie sich kooperativ verhalten anstatt sich zu verweigern.',

    'm2_s2_l4_t2': 'Die Übung wurde richtig bearbeitet, wenn der Klient mindestens einen nachvollziehbaren Vorteil genannt hat, der eintreten würde, wenn er sich kooperativ gegenüber seinem*seiner BH verhält anstatt sich zu verweigern.',

    'm2_s3_l5_t3': 'Die Übung wurde richtig bearbeitet, wenn die Teilnehmenden mindestens eine*n persönliche*n Bewährungshelfer*in genannt haben (entweder per Namen oder Beschreibung der Beziehung, wie z.B. Mutter, Vater, Tante o.Ä.). Dennoch kann die Möglichkeit nicht ausgeschlossen, werden, dass es Teilnehmende gibt, die keine Personen in ihrem Umfeld haben, die sie bei dieser Übung nennen könnten (siehe letzte Antwortoption).',

    'm2_s4_l3_t2': 'Die Übung wurde richtig bearbeitet, wenn der Klient mindestens eine Person genannt hat (entweder per Namen oder Beschreibung der Beziehung, wie z.B. Mutter, Vater, Tante o.Ä.). Dennoch kann die Möglichkeit nicht ausgeschlossen, werden, dass es Teilnehmende gibt, die keine Personen in ihrem Umfeld haben, die sie bei dieser Übung nennen könnten (siehe letzte Antwortoption).',

    /**
     * MODUL 3
     */

    'm3_s1_l7_t1': '<ul><li>TN soll sich ein Bedürfnis auswählen und ein Plan, mit dem er das Bedürfnis erfüllen kann<ul><li>Anerkennung und Respekt</li><li>Zugehörigkeit und Nähe</li><li>Kontrolle und Orientierung</li><li>Lustgewinn und Vermeidung von Langeweile</li></ul></li><li>Übung ist korrekt bearbeitet, wenn TN ein Bedürfnis nennt und einen möglichst konkreten Plan nennt (nicht „kreativer sein“ sondern „Mal-Kurs besuchen“)</li></ul>',

    'm3_s3_l2_t1': '<ul><li>TN sollen drei Situationen aufschreiben aus denen hervorgeht was passiert ist, welches Gefühl sie hatten, wie stark das Gefühl war (0-100), welchen Handlungsimpuls sie hatten und was sie gemacht haben</li><li>Aufgabe ist korrekt beantwortet, wenn aus zumindest einer Situation diese Punkte hervorgehen</li><li>Musterlösung für „es gab keine passenden Situationen“ unter „nicht bearbeitet“</li></ul>',

    'm3_s3_l7_t1': '<ul><li>TN soll sich 3 Skills auswählen</li><li>Die Aufgabe ist gelöst, wenn mindestens 2 Dinge genannt werden, die gemacht werden können, wenn der TN starke unangenehme Gefühle hat und die weder ihm selbst noch jemand anderem schaden</li></ul>',

    'm3_s4_l2_t1': '<ul><li>TN sollen drei Situationen aufschreiben aus denen hervorgeht was passiert ist, welches Gefühl sie hatten, wie stark das Gefühl war (0-100), welchen Handlungsimpuls sie hatten und welchen Skill sie angewandt haben</li><li>Aufgabe ist korrekt beantwortet, wenn aus zumindest einer Situation diese Punkte hervorgehen</li><li>Unter „falsch beantwortet“ finden sich Musterlösungen für (antizipiert) häufige Szenarien (Skills vergessen; Utensilien nicht dabei; nicht geschafft, da Emotion zu stark)</li></ul>',

    'm3_s4_l4_t1': '<ul><li>TN soll sich 3 Skills auswählen und dazuschreiben, was sie dafür brauchen in ihrer Skills-Tasche<ul><li>Skill: ... <i>(Gegenstand, der dafür in meiner Skills-Tasche sein muss)</i></li></ul></li><li>Die Aufgabe ist gelöst, wenn mindestens 2 Handlungen genannt werden, die gemacht werden können, wenn der TN starke unangenehme Gefühle hat und die weder ihm selbst noch jemand</li></ul>',

    'm3_s4_l7_t3': '<ul><li>Es sollen vier Wege/Dinge aufgeschrieben werden, mit denen sich der TN nach der Sitzung belohnen kann</li><li>Die Antwort ist korrekt beantwortet, wenn mindestens ein Weg gefunden wurde. Dieser Weg darf weder den TN noch jemand anderem schaden und muss realistisch sein.</li></ul>',

        /**
     * MODUL 4
     */

    'm4_s2_l5_t1': '<ul><li>Der Teilnehmende soll seine Probleme, die er in einer vorherigen Übung gesammelt hat, in veränderbar und nicht veränderbar einteilen.</li><li>Die korrekte Einteilung ist wichtig, weil der Teilnehmende später an einem seiner veränderbaren Probleme arbeiten soll.</li><li>Die Aufgabe ist korrekt bearbeitet, wenn in der Spalte „veränderbare Probleme“ nur Probleme stehen, die der Teilnehmende auch alleine bzw. mit Unterstützung anderer bearbeiten kann.</li></ul>',

    'm4_s3_l5_t1': '<ul><li>Der Teilnehmende soll sich ein Problem aus seiner Liste veränderbarer Probleme aussuchen und das beschreiben.</li><li>Die Übung ist korrekt bearbeitet, wenn der Klient beschrieben hat, wie das Problem entstanden ist, in welcher Situation es auftritt und wer evtl. noch daran beteiligt ist.</li></ul>',

    'm4_s4_l5_t1': '<ul><li>Das Ziel der Übung ist, dass der Teilnehmende einen Plan zur Umsetzung seiner Lösungsidee aufstellt.</li><li>Der Plan soll kleinschrittig genug sein, dass beim Durchlesen klar wird, was der Klient vorhat</li><li>Die Aufgabe wurde korrekt bearbeitet, wenn der Klient mindestens 3 Schritte ausgearbeitet hat.</li></ul>',

        /**
     * MODUL 5
     */

    'm5_s1_l4_t2': '<ul><li>Die Übung wurde dann richtig bearbeitet, wenn der Klient zwei logische Beweise formuliert, die gegen die Aussage "Andere fahren ja auch schnell und denen passiert auch nichts" sprechen.</li><li>Dabei können die Beweise auf Beobachtungen des Klienten basieren (z.B. „Ich habe schon erlebt, wie mir oder anderen beim schnell fahren ein Unfall passiert ist.“), auf Schlussfolgerungen (z.B. „Nur weil anderen nichts passiert, bedeutet das nicht, dass Sven nichts passiert.“) oder Fakten (z.B. „Es ist bewiesen, dass zu schnelles Fahren zu mehr Unfällen führt.“).</li><li>Es ist ausreichend, wenn der Eindruck vermittelt wird, dass der Klient durch seine Überlegungen selbst zu dem Schluss gekommen ist, dass Svens Aussage nicht richtig ist und das erläutern kann. Die Beweise müssen nicht empirisch belegt sein oder eine ausgefeilte Argumentationsstruktur dahinter haben.</li></ul>',

    '1m5_s2_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen (= Missbrauch/ Missbrauchsabbildungen) genannt sind, die nichtzutreffende Option soll bitte gestrichen werden.</li></ul>',

    '2m5_s2_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen (= Missbrauch/ Missbrauchsabbildungen) genannt sind, die nichtzutreffende Option soll bitte gestrichen werden.</li></ul>',

    '3m5_s2_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen (= Missbrauch/ Missbrauchsabbildungen) genannt sind, die nichtzutreffende Option soll bitte gestrichen werden.</li></ul>',

    '4m5_s2_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen (= Missbrauch/ Missbrauchsabbildungen) genannt sind, die nichtzutreffende Option soll bitte gestrichen werden.</li></ul>',

    '5m5_s2_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen (= Missbrauch/ Missbrauchsabbildungen) genannt sind, die nichtzutreffende Option soll bitte gestrichen werden.</li></ul>',

    '6m5_s2_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen (= Missbrauch/ Missbrauchsabbildungen) genannt sind, die nichtzutreffende Option soll bitte gestrichen werden.</li></ul>',

    '7m5_s2_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen (= Missbrauch/ Missbrauchsabbildungen) genannt sind, die nichtzutreffende Option soll bitte gestrichen werden.</li></ul>',

    '8m5_s2_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen (= Missbrauch/ Missbrauchsabbildungen) genannt sind, die nichtzutreffende Option soll bitte gestrichen werden.</li></ul>',

    '9m5_s2_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen (= Missbrauch/ Missbrauchsabbildungen) genannt sind, die nichtzutreffende Option soll bitte gestrichen werden.</li></ul>',

    '10m5_s2_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen (= Missbrauch/ Missbrauchsabbildungen) genannt sind, die nichtzutreffende Option soll bitte gestrichen werden.</li></ul>',

    '11m5_s2_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen (= Missbrauch/ Missbrauchsabbildungen) genannt sind, die nichtzutreffende Option soll bitte gestrichen werden.</li></ul>',

    '12m5_s2_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen (= Missbrauch/ Missbrauchsabbildungen) genannt sind, die nichtzutreffende Option soll bitte gestrichen werden.</li></ul>',

    '13m5_s2_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen (= Missbrauch/ Missbrauchsabbildungen) genannt sind, die nichtzutreffende Option soll bitte gestrichen werden.</li></ul>',

    '14m5_s2_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen (= Missbrauch/ Missbrauchsabbildungen) genannt sind, die nichtzutreffende Option soll bitte gestrichen werden.</li></ul>',

    '15m5_s2_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen (= Missbrauch/ Missbrauchsabbildungen) genannt sind, die nichtzutreffende Option soll bitte gestrichen werden.</li></ul>',

    '16m5_s2_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen (= Missbrauch/ Missbrauchsabbildungen) genannt sind, die nichtzutreffende Option soll bitte gestrichen werden.</li></ul>',
    
    
    
    '1m5_s3_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '2m5_s3_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '3m5_s3_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '4m5_s3_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '5m5_s3_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '6m5_s3_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '7m5_s3_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '8m5_s3_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '9m5_s3_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '10m5_s3_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '11m5_s3_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '12m5_s3_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '13m5_s3_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '14m5_s3_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '15m5_s3_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '16m5_s3_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',



    '1m5_s4_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '2m5_s4_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '3m5_s4_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '4m5_s4_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '5m5_s4_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '6m5_s4_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '7m5_s4_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '8m5_s4_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '9m5_s4_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '10m5_s4_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '11m5_s4_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '12m5_s4_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '13m5_s4_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '14m5_s4_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '15m5_s4_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

    '16m5_s4_l5_t2': '<ul><li>Der Teilnehmende soll sich einen Gedanken überlegen, der ihm dabei hilft nicht rückfällig zu werden, z.B. indem er auf die negativen Folgen einer Straftat hinweist bzw. auf die positiven Aspekte eines straffreien Lebens. Der Gedanke kann auch die Informationen beinhalten, die in dem ausgewählten verzerrten Gedanken nicht drinstecken (z.B. „Kinder lassen sich nicht gerne für Missbrauchsabbildungen fotografieren und leiden darunter.“)</li><li>Die Übung ist korrekt bearbeitet, wenn der Teilnehmende einen Gedanken formuliert hat, der irgendeinen Grund oder Argument für ihn enthält keinen Missbrauch zu begehen bzw. Missbrauchsabbildungen zu gucken.</li><li>Der Teilnehmende bekommt auch immer einen hilfreichen Gedanken vorgeschlagen, das heißt es kann sein, dass er auch einfach nur den Vorschlag ins Textfeld kopiert. Der ist dann natürlich korrekt. Ich habe die Antwort so formuliert, dass sie auch passt, wenn er den vorgeschlagenen Gedanken auswählt.</li><li>In der Sitzung ging es entweder um einen verzerrten Gedanken zu Missbrauchsabbildungen oder sexuellen Missbrauch (der konkrete verzerrte Gedanke steht in der Instruktion). Dem entsprechend bezieht sich der hilfreiche Gedanke wahrscheinlich auf eins von beidem. Ich formuliere die Antworten so, dass beide Optionen genannt sind, aber es darf gerne die nichtzutreffende Option gestrichen werden.</li></ul>',

        /**
     * MODUL 6
     */

    'm6_s1_l5_t1': 'Die Übung wurde richtig bearbeitet, wenn die Teilnehmenden mindestens eine Situation (Ereignisse, Gefühle, Erinnerungen, Personen, etc.) genannt haben, die nachvollziehbar das Aufkommen sexueller Gedanken und Handlungen fördert. Dabei reichen einzelne Wörter/Stichpunkte wie z.B. Ärger, PC, Nachbarin.',

    'm6_s2_l4_t1': 'Bei dieser Übung handelt es sich um eine Multiple-Choice-Frage ohne die Möglichkeit, eigene Strategien zu ergänzen.  Diese Übung kann deshalb nicht fehlerhaft bearbeitet werden. Sollten mehr als einer der letzten drei Punkte gewählt werden, sollte der Zusatztext nur einmal versandt werden. Die Strategien zur Auswahl sind:<ul><li>Die obigen vier Regeln auf einen Zettel notieren, um mich immer wieder daran zu erinnern</li><li>Orte meiden, an denen ich Kinder treffen könnte (z.B. Schwimmbäder und Spielplätze)</li><li>Möglichst nicht draußen sein, wenn viele Kinder draußen sind (z.B. vor oder nach der Schulzeit)</li><li>Mehr Beziehungen zu Erwachsenen aufbauen</li><li>Mich mehr mit meinen bestehenden Beziehungen zu Erwachsenen beschäftigen</li><li>Gemeinsam mit meinem*meiner Bewährungshelfer*in überlegen, wie ich mehr Beziehungen zu Erwachsenen aufbauen kann</li><li>Gemeinsam mit meinem*meiner Bewährungshelfer*in weitere Wege überlegen, wie ich emotionalen und räumlichen Abstand zu Kindern aufbauen kann</li><li>Gemeinsam mit meinem*meiner therapeutischen Behandler*in überlegen, wie ich mehr Beziehungen zu Erwachsenen aufbauen kann</li><li>Gemeinsam mit meinem*meiner therapeutischen Behandler*in weitere Wege überlegen, wie ich emotionalen und räumlichen Abstand zu Kindern aufbauen kann</li></ul>',

    'm6_s3_l4_t2': 'Bei dieser Übung handelt es sich um eine Multiple-Choice-Frage ohne die Möglichkeit, eigene Strategien zu ergänzen. Diese Übung kann deshalb nicht fehlerhaft bearbeitet werden. Die Strategien zur Auswahl sind:<ul><li>Ablenkung durch Skills (z.B. auf eine Zitrone beißen)</li><li>Belohnung mit etwas, das mir (ebenfalls) Spaß macht (z.B. etwas von meiner Belohnungsliste)</li><li>Risiko-Situationen möglichst vermeiden (z.B. ins Schwimmbad gehen, wenn viele Kinder dort sind)</li><li>Die Herausforderung annehmen und das Verlangen wie eine Welle im Meer kommen und gehen lassen</li></ul>',

    'm6_s4_l5_t1': 'Die Übung wurde korrekt bearbeitet, wenn die Teilnehmenden eine sexuelle Fantasie in ganzen Sätzen oder Stichpunkten beschreiben/skizzieren, die nicht problematisch ist.',



    /**
     * PLACEBO
     * 
     * insgesamt 29 guided tasks
     * 
     */

        /**
         * MODUL 1
         */

    'Pm1_s1_l4_t1': '<ul><li>Es sollen Hilfsmittel aufgeschrieben werden, durch die man sich weniger bewegen/arbeiten muss</li><li>Beispiel: Staubsauger, Toaster, Handy, Auto etc.</li><li>Es ist wichtig, dass der genannte Wunsch zu einem Ziel hinführt</li></ul>',

    'Pm1_s1_l6_t1': '<ul><li>Teilnehmende*r soll überlegen, welche Beschwerden er/sie kennt, weshalb ein größeres Risiko für Verletzungen bestehen kann oder die Beweglichkeit erschwert ist.</li><li>Beispiele: Amputationen, kognitive Störungen, Erkrankungen der Atemwege, Lähmungen (Beispiele in der Lektion: Multiple Sklerose und Rheuma)</li></ul>',

    'Pm1_s1_l7_t1': '<ul><li>Es sollen vier Wege/Dinge aufgeschrieben werden, mit denen sich der TN nach der Sitzung belohnen kann</li><li>Die Antwort ist korrekt beantwortet, wenn mindestens ein Weg gefunden wurde. Dieser Weg darf weder den TN noch jemand anderem schaden und muss realistisch sein.</li></ul>',

    'Pm1_s2_l5_t1': '<ul><li>Es soll eine Übung beschrieben werden, die dabei hilft, die Stabilität des Körpers zu erhöhen.</li><li>Die Übung ist korrekt bearbeitet, wenn eine Übung verständlich erklärt wird</li></ul>',

    'Pm1_s3_l4_t1': '<ul><li>TN soll sich einen Einfluss auf die Beweglichkeit aussuchen und zwei Gründe, warum er diesen interessant findet, (körperlicher & biologischer Einfluss; psychischer Einfluss; äußerer Einfluss) nennen.</li><li>körperlicher & biologischer Einfluss: Beweglichkeit kann vererbt werden, es ist zwischen Männern und Frauen, je nach Körpertemperatur, je nach Alter, unterschiedlich</li><li>psychischer Einfluss: Beweglichkeit kann durch Gefühle und Stress beeinflusst werden</li><li>äußerer Einfluss: Beweglichkeit ist abhängig von der Umgebungstemperatur und Tageszeit</li><li>Die Übung ist korrekt beantwortet, wenn er einen Einfluss benennt und mindestens 2 Gründe nennt</li></ul>',

    'Pm1_s3_l6_t1': '<ul><li>Es soll eine Übung beschrieben werden, die dabei hilft, die Beweglichkeit und Dehnfähigkeit des Körpers zu erhöhen.</li><li>Die Übung ist korrekt bearbeitet, wenn eine Übung verständlich erklärt wird</li></ul>',

    'Pm1_s4_l4_t2': '<ul><li>TN sollen für Corinna und Werner Aktivitäten herausfinden, bei denen sie zu viel sitzen</li><li>Sie sollen pro Avatar eine Aktivität nennen</li><li>Corinna: Bürojob, Sitzen in der Mittagspause, mit dem Handy spielen, Kino (Aktivitäten, bei denen Corinna sitzt) -> Sie kann während des Arbeitens häufiger aufstehen, sie kann in ihrer Freizeit mehr Dinge machen, bei denen Sie nicht sitzt, sie kann im Stehen Mittagspause machen</li><li>Werner: Frühstücken/Abendessen, Fernsehgucken, Fifa spielen (Aktivitäten, bei denen Werner sitzt) -> er kann sich Hobbys suchen, bei denen er nicht sitzt (z.B. Fußballspielen)</li></ul>',

    'Pm1_s4_l5_t1': '<ul><li>TN soll sich Tipps für Peter überlegen, wie er sich mehr am Tag bewegen kann</li><li>Übung ist korrekt beantwortet, wenn er sich 2 Tipps für Peter überlegt</li><li>Dinge, bei denen Peter viel Sitzt: morgens auf dem Balkon, im Auto, auf der Arbeit als Warenauffüller, abends auf dem Sofa</li><li>Es sollen spezifische Regeln sein, also nicht dazu führen, dass Peter immer steht/geht</li></ul>',

    /**
     * MODUL 2
     */
    
    'Pm2_s1_l4_t1': 'Die Übung wurde richtig bearbeitet, wenn die Teilnehmenden eine Mahlzeit beschreiben, in denen Lebensmittel vorkommen, die in der Lektion besprochen wurden. Diese sind:<ul><li>Nahrung mit hohem Proteingehalt<ul><li>Hühnereier</li><li>Fleisch</li><li>Fisch </li><li>Nüsse </li><li>Erbsen</li><li>Bohnen</li><li>Soja</li><li>Käse</li><li>Quark</li><li>Buttermilch</li></ul></li><li>Nahrung mit wichtigen Vitaminen für den Muskelaufbau<ul><li>Spinat</li><li>Feldsalat</li><li>Pilze</li><li>Kiwis</li><li>Johannesbeeren</li><li>Stachelbeeren</li><li>Sonnenblumenöl</li><li>Olivenöl</li><li>Kartoffeln</li><li>Bananen</li></ul></li></ul><p>Mindestens ein und höchstens zwei Lebensmittel der beschriebenen Mahlzeit sollen – wie in der Lektion beschrieben – einen hohen Proteingehalt haben. Die Lebensmittelgruppen Fleisch, Fisch und Nüsse können, müssen aber nicht spezifiziert werden (z.B. Steak, Lachs, Walnüsse).</p><p>Die Mahlzeit muss weder ausgefallen noch gewöhnlich sein.</p>',

    'Pm2_s2_l4_t2': 'Die Übung wurde richtig bearbeitet, wenn die Teilnehmenden das äußere Hindernis „Schmerzen“ (Schmerzen in den Beinen) genannt haben. ',

    'Pm2_s3_l5_t3': 'Die Übung wurde richtig bearbeitet, wenn die Teilnehmenden mindestens eine Einzel- oder Tanzsportart nennen, die noch nicht in der Lektion angesprochen wurde. Diese sind:<ul><li>Einzelsportarten: Leichtathletik, Turnen, Yoga</li><li>Tanzsportarten: Zumba, Partnertanz (z.B. Walzer), Hip Hop</li></ul>Einzelsportarten, die in anderen Lektionen im Rahmen anderer Sportarten genannt wurden, sind zulässig (z.B. Laufen, Schwimmen).',

    'Pm2_s4_l3_t1': 'Die Übung wurde richtig bearbeitet, wenn der Klient eine der in dem Video beschriebenen Sportarten nennt (Laufen/Joggen, Fahrradfahren oder Kraftsport im Fitnessstudio).',

    /**
     * MODUL 3
     */

    'Pm3_s1_l7_t1': 'Die Übung wurde richtig bearbeitet, wenn der Teilnehmende beschrieben hat, was ein Jetlag ist. Folgende Punkte sind wichtig:<p>Entstehung: Ein Jetlag entsteht, wenn wir innerhalb weniger Stunden mehrere Zeitzonen durchreisen.</p><p>Faktoren für Ausprägung:<ol><li>Anzahl der durchquerten Zeitzonen</li><li>Flugrichtung für die Schwere des Jetlags entscheidend, denn bei Reisen nach Westen verlängert sich der Tag, Richtung Osten verkürzt er sich.</li></ol><p>Tipps und Tricks<ul><li>Sind wir nur wenige Tage in einer anderen Zeitzone vor Ort ist es empfehlenswert, den Körper erst gar nicht an die neue Ortszeit zu gewöhnen und den alten Tagesrhythmus beizubehalten.</li><li>Ist ein längerer Aufenthalt geplant, sind regelmäßige, an die neue Uhrzeit angepasste Essens- und Schlafzeiten und Bewegung bei Tageslicht förderlich.</li></ul></p>',

    'Pm3_s3_l4_t1': 'Die Übung wurde richtig bearbeitet, wenn der Teilnehmende folgende Punkte in seine Antwort einbezieht:<ul><li>In der Apotheke gibt es verschiedene Schlafmittel, die man ohne Rezept kaufen kann. Beispiele sind: Johanniskraut, Baldrian, Baldrian-Hopfen oder Melisse</li><li>Der Arzt kann Antidepressiva und Melatonin verschreiben.</li><li>Man sollte Schlafmittel nicht für einen längeren Zeitraum einnehmen, da ihre Wirkung auf Dauer nachlässt und die Gefahr einer Abhängigkeit besteht. Zudem können bei der Einnahme Nebenwirkungen auftreten (zum Beispiel Gedächtnis- und Konzentrationsstörungen, Benommenheit und Muskelschwäche)</li></ul>',

    'Pm3_s3_l7_t1': 'Die Übung wurde richtig bearbeitet, wenn der Teilnehmende folgende Punkte in seine Antwort einbezieht:<ul><li>jede Altersgruppe nennt ganz eigene Faktoren, die hauptsächlich für ihren Stress verantwortlich sind: Jüngere Menschen sind eher gestresst durch die Arbeit. Mit zunehmenden Alter sind wir eher gestresst durch Konflikte in der Familie, Erkrankungen oder durch die Betreuung von pflegebedürftigen Angehörigen.</li><li>Mit Entspannungsübungen baut man Energiereserven wieder auf. Mit Entspannungsübungen gilt es, einen lebendigen Wechsel zwischen Spannung und Entspannung zu unterstützen und zu fördern.</li></ul>',

    'Pm3_s4_l2_t1': 'Die Übung wurde richtig bearbeitet, wenn der Teilnehmende folgende Punkte in seine Antwort einbezieht:<ul><li>Von den Betroffenen wird das Schnarchen in den meisten Fällen gar nicht bemerkt.</li><li>Maßnahmen, die helfen können: Nasenpflaster, Unterkiefer-Schienen bzw. kieferchirurgische Maßnahmen</li></ul>',

    'Pm3_s4_l3_t1': 'Die Übung wurde richtig bearbeitet, wenn der Teilnehmende folgende Punkte in seine Antwort einbezieht:<ul><li>Bei der obstruktiven Schlafapnoe treten wiederholte Atemaussetzer während des Schlafs auf.</li><li>Die auf die Atemaussetzer folgenden spontanen Weckreaktionen reißen betroffene Schnarcher meist aus seiner Tiefschlaf-/REM-Phase und sorgen so dafür, dass Betroffene zu wenig Schlafzeit in der Tiefschlafphase und der REM-Phase verbringen. Deshalb kann sich der Betroffene in aller Regel im Schlaf nicht ausreichend erholen.</li><li>Starke Schnarcher sind anfälliger für Herzkreislauferkrankungen.</li></ul>',

    'Pm3_s4_l7_t3': '<ul><li>Es sollen vier Wege/Dinge aufgeschrieben werden, mit denen sich der TN nach der Sitzung belohnen kann</li><li>Die Antwort ist korrekt beantwortet, wenn mindestens ein Weg gefunden wurde. Dieser Weg darf weder den TN noch jemand anderem schaden und muss realistisch sein.</li></ul>',

        /**
     * MODUL 4
     */

    'Pm4_s2_l5_t2': 'Die Übung wurde richtig bearbeitet, wenn der Teilnehmende folgende Punkte in seine Antwort einbezieht:<ul><li>Der Hauptinhalt unseres Traums, also das eigentliche Motiv, lässt sich als Traumsymbol erfassen, das sich aus Bildern, Handlungen und Gefühlen des Traums zusammensetzt.</li><li>Mit diesem Traumsymbol kann in der Traumdeutung die Bedeutung des Traums entschlüsselt werden.</li></ul>',

    'Pm4_s3_l5_t1': 'Die Übung wurde richtig bearbeitet, wenn der Teilnehmende folgende Punkte in seine Antwort einbezieht:<ul><li>Regelmäßigkeit: je regelmäßiger die Zeiten sind, zu denen wir zu Bett gehen und aufstehen, umso leichter fällt unserem Körper das Umschalten zwischen Schlaf und Wachsein. Nickerchen am Tag mögen angenehm sein, stören aber bei den meisten Menschen den Nachtschlaf.</li><li>Das Schlafzimmer ist das Zimmer, in dem wir schlafen. Ein Heimarbeitsplatz sollte nicht im Schlafzimmer eingerichtet werden, da wir sonst nur schwer abschalten können.</li><li>Lärmstörungen verhindern: werden wir beim Einschlafen durch Lärm von den Nachbarn oder von der Straße gestört, können wir entweder versuchen, die Räume besser vor den Geräuschen zu schützen. Oder wir nutzen Ohrenstöpsel.</li><li>Kein Sport direkt vorm Schlafengehen: Sport hält uns wach, wir sollten daher möglichst drei Stunden vor dem Zubettgehen keiner körperlich anstrengenden Aktivität nachgehen.</li><li>Alkohol vermeiden: Alkohol mag das Einschlafen fördern, das Durchschlafen erschwert er jedoch. Und auch unvermutetes Koffein in Tees und sogar manchen Wassersorten verursacht häufig Schlafstörungen.</li></ul>',

    'Pm4_s4_l5_t1': 'Die Übung wurde richtig bearbeitet, wenn der Teilnehmende folgende Punkte in seine Antwort einbezieht:<ul><li>Eine erste Erleichterung kann das Erzählen des Traumes bringen.</li><li>Eine weitere Möglichkeit ist das luzide Träumen oder Klarträumen: Wer luzide träumt, weiß schon während des Traums, dass er träumt. Ein Albtraum verliert auf diese Weise seinen Schrecken, da der Träumer ihn als Albtraum erkennt.</li></ul>',

        /**
     * MODUL 5
     */

    'Pm5_s1_l4_t2': 'Die Übung wurde richtig bearbeitet, wenn der Teilnehmende beschrieben hat, was mit der Nahrung im Dünndarm, Dickdarm und Enddarm passiert. Folgende Punkte sind wichtig:<ul><li>Im Dünndarm werden die Nährstoffe der Nahrung in dem Körper aufgenommen.</li><li>Im Dickdarm wird den schwer verdaulichen Nahrungsresten das Wasser und Mineralstoffe entzogen, so entsteht Stuhl.</li><li>Im Enddarm werden die unverdaulichen Reste ausgeschieden.</li></ul>',

    // 2. Sitzung

    'P1m5_s2_l5_t5': '<ul><li>Die Übung wurde richtig bearbeitet, wenn der Teilnehmende:<ul><li>Schreibt, dass beim Vegetarismus auf Fleisch und Fisch verzichtet wird, also Lebensmittel für die Tiere getötet werden.</li><li>Einen Vorteil von Vegetarismus nennt.</li><li>Einen Nachteil von Vegetarismus nennt.</li></ul></li><li>Ein „nice-to-have“ wäre, wenn er nennt, dass andere tierische Produkte wie Milch, Eier oder Käse gegessen werden.</li><li>Beispiele für Vorteile, die der Teilnehmende nennen könnte, sind:<ul><li>Ethische Gründe: Jedes Lebewesen wird gleichermaßen respektiert und somit möchte man nicht für das Töten eines Tieres verantwortlich sein.</li><li>Ökologische Motive: Die Umwelt soll durch die Verringerung der Massentierhaltung geschont werden.</li><li>Soziale Aspekte: die Bekämpfung des Welthungers soll durch vermehrten Anbau pflanzlicher Produkte im Gegensatz zur Massentierhaltung befördert werden.</li><li>Gesundheitliche Gründe: durch eine verringerte Aufnahme von in Fleisch enthaltenen schädlichen Stoffen kann sich die Gesundheit verbessern und zum Beispiel Herz-Kreislauf-Erkrankungen vorgebeugt werden.</li></ul></li><li>Beispiele für Nachteile, die der Teilnehmende nennen könnte, sind:<ul><li>Eine falsche Zusammenstellung der Lebensmittel kann Mangelerscheinungen hervorrufen.</li><li>Um Nährstoffmangel vorzubeugen, müssen Menschen, die sich vegetarisch ernähren für ihre Ernährung einen höheren Aufwand betreiben und sich viel Wissen über Lebensmittel aneignen.</li><li>Die ausreichende Versorgung mit Eisen, Zink, Eiweiß, Jod und Vitamin B12 sollte regelmäßig kontrolliert werden.</li><li>Eine abwechslungsreiche vegetarische Ernährung ist häufig mit Mehrkosten verbunden.</li><li>Vegetarier und Vegetarierinnen haben es im Alltag oft schwerer als Personen, die Fleisch essen (z. B. nicht so viel Auswahl in Restaurants oder Kantinen</li></ul></li></ul>',

    'P2m5_s2_l5_t5': '<ul><li>Die Übung wurde richtig bearbeitet, wenn der Teilnehmende:<ul><li>Schreibt, dass beim Veganismus nur pflanzliche Lebensmittel gegessen werden / keine Lebensmittel, die von Tieren stammen.</li><li>Einen Vorteil von Veganismus nennt.</li><li>Einen Nachteil von Veganismus nennt.</li></ul></li><li>Beispiele für Vorteile, die der Teilnehmende nennen könnte, sind:<ul><li>Tierschutz</li><li>Umwelt wird geschont</li><li>Gut für die Gesundheit</li><li>Welthunger kann so effektiv bekämpft werden</li></ul></li><li>Beispiele für Nachteile, die der Teilnehmende nennen könnte, sind:<ul><li>Gefahr von Mangelerscheinungen</li><li>Regelmäßige Kontrolle der Nährstoffversorgung nötig</li><li>Aneignung von Wissen ist zeitaufwendig</li><li>Die Verfügbarkeit von veganen Lebensmitteln und Gerichten in Restaurants und Supermärkten ist nicht immer gut</li></ul></li></ul>',

    'P3m5_s2_l5_t3': '<ul><li>Die Übung wurde richtig bearbeitet, wenn der Teilnehmende:<ul><li>Schreibt, dass bei der Paleo-Ernährung nur solche Lebensmittel gegessen werden, die es in der Steinzeit auch schon gab, z.B. Fleisch, Fisch, Meeresfrüchte, Eier, Obst, Gemüse, Pilze, Nüsse und Honig</li><li>Einen Vorteil von Paleo nennt.</li><li>Einen Nachteil von Paleo nennt.</li></ul></li><li>Ein „nice-to-have“ wäre, wenn er nennt, dass Lebensmittel, die industriell verarbeitet sind und die es in der Steinzeit noch nicht gab, z.B. Milchprodukte, Süßigkeiten, Nudeln nicht gegessen werden.</li><li>Beispiele für Vorteile, die der Teilnehmende nennen könnte, sind:<ul><li>Besserer Schlaf</li><li>Stärkt das Immunsystem</li><li>Nährstoffreichste Ernährungsform</li><li>Verzicht auf ungesunde Lebensmittel</li></ul></li><li>Beispiele für Nachteile, die der Teilnehmende nennen könnte, sind:<ul><li>aufwendige Ernährungsumstellung</li><li>Paleo-Ernährung ist teuer</li><li>sehr viele tierische Produkte, kein Tierschutz</li><li>Verzicht auf wichtige pflanzliche Proteinquellen</li></ul></li></ul>',

    'P4m5_s2_l5_t4': '<ul><li>Die Übung wurde richtig bearbeitet, wenn der Teilnehmende:<ul><li>Schreibt, dass bei der Low Carb-Ernährung Lebensmittel gegessen werden, die wenig oder keine Kohlenhydrate enthalten, also vor allem Fisch, Fleisch, Milchprodukte und Gemüse.</li><li>Einen Vorteil von Low Carb nennt.</li><li>Einen Nachteil von Low Carb nennt.</li></ul></li><li>Ein "nice-to-have" wäre, wenn er nennt, dass Lebensmittel, die viele Kohlenhydrate haben, wie Süßigkeiten, Zucker, Nudeln aus Weizenmehl nicht gegessen werden</li><li>Beispiele für Vorteile, die der Teilnehmende nennen könnte, sind:<ul><li>Low Carb macht länger satt</li><li>Ideale Versorung mit Nährstoffen</li><li>Niedrigerer Blutzuckerspiegel</li><li>Mehr Energie und weniger Müdigkeit</li></ul></li><li>Beispiele für Nachteile, die der Teilnehmende nennen könnte, sind:<ul><li>Aufwendige Ernährungsumstellung</li><li>Zu wenig Kohlenhydrate sind auf Dauer ungesund</li><li>Fleischkonsum muss beobachtet werden</li><li>Jojo-Effekt</li></ul></li></ul>',

    'P5m5_s2_l5_t4': '<ul><li>Die Übung wurde richtig bearbeitet, wenn der Teilnehmende:<ul><li>Schreibt, dass bei der Mischkost-Ernährung alle Lebensmittel gegessen werden, es kommt aber auf die richtige Menge an und dass es ausgewogen ist.</li><li>Einen Vorteil von Mischkost nennt.</li><li>Einen Nachteil von Mischkost nennt.</li></ul></li><li>Ein „nice-to-have“ wäre, wenn er nennt, dass der Schwerpunkt dieser Ernährungsform auf pflanzlichen Lebensmitteln wie Obst, Gemüse und Getreide liegt. Tierische Produkte wie Fleisch, Fisch und Eier sollten daher in nicht ganz so großen Mengen verzehrt werden.</li><li>Beispiele für Vorteile, die der Teilnehmende nennen könnte, sind:<ul><li>Alle Lebensmittel sind erlaubt</li><li>Mangelerscheinungen sind selten</li><li>Kann gut für eine Diät und als langfristige Ernährungsform genutzt werden</li><li>Verhindert den Jo-Jo-Effekt</li><li>Mehr abwechslungsreiche Ernährung</li></ul></li><li>Beispiele für Nachteile, die der Teilnehmende nennen könnte, sind:<ul><li>Vorher genau informieren ist zeitaufwendig</li><li>Leichter mal Zurückgreifen auf Fast Food</li><li>Nicht gut für die Umwelt</li><li>Massentierhaltung wird unterstützt</li><li>Motivation bei Diät kann schnell vergehen</li></ul></li></ul>',

    // 3. Sitzung
    'P1m5_s3_l5_t5': '<ul><li>Die Übung wurde richtig bearbeitet, wenn der Teilnehmende:<ul><li>Schreibt, dass beim Vegetarismus auf Fleisch und Fisch verzichtet wird, also Lebensmittel für die Tiere getötet werden.</li><li>Einen Vorteil von Vegetarismus nennt.</li><li>Einen Nachteil von Vegetarismus nennt.</li></ul></li><li>Ein „nice-to-have“ wäre, wenn er nennt, dass andere tierische Produkte wie Milch, Eier oder Käse gegessen werden.</li><li>Beispiele für Vorteile, die der Teilnehmende nennen könnte, sind:<ul><li>Ethische Gründe: Jedes Lebewesen wird gleichermaßen respektiert und somit möchte man nicht für das Töten eines Tieres verantwortlich sein.</li><li>Ökologische Motive: Die Umwelt soll durch die Verringerung der Massentierhaltung geschont werden.</li><li>Soziale Aspekte: die Bekämpfung des Welthungers soll durch vermehrten Anbau pflanzlicher Produkte im Gegensatz zur Massentierhaltung befördert werden.</li><li>Gesundheitliche Gründe: durch eine verringerte Aufnahme von in Fleisch enthaltenen schädlichen Stoffen kann sich die Gesundheit verbessern und zum Beispiel Herz-Kreislauf-Erkrankungen vorgebeugt werden.</li></ul></li><li>Beispiele für Nachteile, die der Teilnehmende nennen könnte, sind:<ul><li>Eine falsche Zusammenstellung der Lebensmittel kann Mangelerscheinungen hervorrufen.</li><li>Um Nährstoffmangel vorzubeugen, müssen Menschen, die sich vegetarisch ernähren für ihre Ernährung einen höheren Aufwand betreiben und sich viel Wissen über Lebensmittel aneignen.</li><li>Die ausreichende Versorgung mit Eisen, Zink, Eiweiß, Jod und Vitamin B12 sollte regelmäßig kontrolliert werden.</li><li>Eine abwechslungsreiche vegetarische Ernährung ist häufig mit Mehrkosten verbunden.</li><li>Vegetarier und Vegetarierinnen haben es im Alltag oft schwerer als Personen, die Fleisch essen (z. B. nicht so viel Auswahl in Restaurants oder Kantinen</li></ul></li></ul>',

    'P2m5_s3_l5_t5': '<ul><li>Die Übung wurde richtig bearbeitet, wenn der Teilnehmende:<ul><li>Schreibt, dass beim Veganismus nur pflanzliche Lebensmittel gegessen werden / keine Lebensmittel, die von Tieren stammen.</li><li>Einen Vorteil von Veganismus nennt.</li><li>Einen Nachteil von Veganismus nennt.</li></ul></li><li>Beispiele für Vorteile, die der Teilnehmende nennen könnte, sind:<ul><li>Tierschutz</li><li>Umwelt wird geschont</li><li>Gut für die Gesundheit</li><li>Welthunger kann so effektiv bekämpft werden</li></ul></li><li>Beispiele für Nachteile, die der Teilnehmende nennen könnte, sind:<ul><li>Gefahr von Mangelerscheinungen</li><li>Regelmäßige Kontrolle der Nährstoffversorgung nötig</li><li>Aneignung von Wissen ist zeitaufwendig</li><li>Die Verfügbarkeit von veganen Lebensmitteln und Gerichten in Restaurants und Supermärkten ist nicht immer gut</li></ul></li></ul>',

    'P3m5_s3_l5_t3': '<ul><li>Die Übung wurde richtig bearbeitet, wenn der Teilnehmende:<ul><li>Schreibt, dass bei der Paleo-Ernährung nur solche Lebensmittel gegessen werden, die es in der Steinzeit auch schon gab, z.B. Fleisch, Fisch, Meeresfrüchte, Eier, Obst, Gemüse, Pilze, Nüsse und Honig</li><li>Einen Vorteil von Paleo nennt.</li><li>Einen Nachteil von Paleo nennt.</li></ul></li><li>Ein „nice-to-have“ wäre, wenn er nennt, dass Lebensmittel, die industriell verarbeitet sind und die es in der Steinzeit noch nicht gab, z.B. Milchprodukte, Süßigkeiten, Nudeln nicht gegessen werden.</li><li>Beispiele für Vorteile, die der Teilnehmende nennen könnte, sind:<ul><li>Besserer Schlaf</li><li>Stärkt das Immunsystem</li><li>Nährstoffreichste Ernährungsform</li><li>Verzicht auf ungesunde Lebensmittel</li></ul></li><li>Beispiele für Nachteile, die der Teilnehmende nennen könnte, sind:<ul><li>aufwendige Ernährungsumstellung</li><li>Paleo-Ernährung ist teuer</li><li>sehr viele tierische Produkte, kein Tierschutz</li><li>Verzicht auf wichtige pflanzliche Proteinquellen</li></ul></li></ul>',

    'P4m5_s3_l5_t4': '<ul><li>Die Übung wurde richtig bearbeitet, wenn der Teilnehmende:<ul><li>Schreibt, dass bei der Low Carb-Ernährung Lebensmittel gegessen werden, die wenig oder keine Kohlenhydrate enthalten, also vor allem Fisch, Fleisch, Milchprodukte und Gemüse.</li><li>Einen Vorteil von Low Carb nennt.</li><li>Einen Nachteil von Low Carb nennt.</li></ul></li><li>Ein "nice-to-have" wäre, wenn er nennt, dass Lebensmittel, die viele Kohlenhydrate haben, wie Süßigkeiten, Zucker, Nudeln aus Weizenmehl nicht gegessen werden</li><li>Beispiele für Vorteile, die der Teilnehmende nennen könnte, sind:<ul><li>Low Carb macht länger satt</li><li>Ideale Versorung mit Nährstoffen</li><li>Niedrigerer Blutzuckerspiegel</li><li>Mehr Energie und weniger Müdigkeit</li></ul></li><li>Beispiele für Nachteile, die der Teilnehmende nennen könnte, sind:<ul><li>Aufwendige Ernährungsumstellung</li><li>Zu wenig Kohlenhydrate sind auf Dauer ungesund</li><li>Fleischkonsum muss beobachtet werden</li><li>Jojo-Effekt</li></ul></li></ul>',

    'P5m5_s3_l5_t4': '<ul><li>Die Übung wurde richtig bearbeitet, wenn der Teilnehmende:<ul><li>Schreibt, dass bei der Mischkost-Ernährung alle Lebensmittel gegessen werden, es kommt aber auf die richtige Menge an und dass es ausgewogen ist.</li><li>Einen Vorteil von Mischkost nennt.</li><li>Einen Nachteil von Mischkost nennt.</li></ul></li><li>Ein „nice-to-have“ wäre, wenn er nennt, dass der Schwerpunkt dieser Ernährungsform auf pflanzlichen Lebensmitteln wie Obst, Gemüse und Getreide liegt. Tierische Produkte wie Fleisch, Fisch und Eier sollten daher in nicht ganz so großen Mengen verzehrt werden.</li><li>Beispiele für Vorteile, die der Teilnehmende nennen könnte, sind:<ul><li>Alle Lebensmittel sind erlaubt</li><li>Mangelerscheinungen sind selten</li><li>Kann gut für eine Diät und als langfristige Ernährungsform genutzt werden</li><li>Verhindert den Jo-Jo-Effekt</li><li>Mehr abwechslungsreiche Ernährung</li></ul></li><li>Beispiele für Nachteile, die der Teilnehmende nennen könnte, sind:<ul><li>Vorher genau informieren ist zeitaufwendig</li><li>Leichter mal Zurückgreifen auf Fast Food</li><li>Nicht gut für die Umwelt</li><li>Massentierhaltung wird unterstützt</li><li>Motivation bei Diät kann schnell vergehen</li></ul></li></ul>',

    // 4. Sitzung
    'P1m5_s4_l5_t5': '<ul><li>Die Übung wurde richtig bearbeitet, wenn der Teilnehmende:<ul><li>Schreibt, dass beim Vegetarismus auf Fleisch und Fisch verzichtet wird, also Lebensmittel für die Tiere getötet werden.</li><li>Einen Vorteil von Vegetarismus nennt.</li><li>Einen Nachteil von Vegetarismus nennt.</li></ul></li><li>Ein „nice-to-have“ wäre, wenn er nennt, dass andere tierische Produkte wie Milch, Eier oder Käse gegessen werden.</li><li>Beispiele für Vorteile, die der Teilnehmende nennen könnte, sind:<ul><li>Ethische Gründe: Jedes Lebewesen wird gleichermaßen respektiert und somit möchte man nicht für das Töten eines Tieres verantwortlich sein.</li><li>Ökologische Motive: Die Umwelt soll durch die Verringerung der Massentierhaltung geschont werden.</li><li>Soziale Aspekte: die Bekämpfung des Welthungers soll durch vermehrten Anbau pflanzlicher Produkte im Gegensatz zur Massentierhaltung befördert werden.</li><li>Gesundheitliche Gründe: durch eine verringerte Aufnahme von in Fleisch enthaltenen schädlichen Stoffen kann sich die Gesundheit verbessern und zum Beispiel Herz-Kreislauf-Erkrankungen vorgebeugt werden.</li></ul></li><li>Beispiele für Nachteile, die der Teilnehmende nennen könnte, sind:<ul><li>Eine falsche Zusammenstellung der Lebensmittel kann Mangelerscheinungen hervorrufen.</li><li>Um Nährstoffmangel vorzubeugen, müssen Menschen, die sich vegetarisch ernähren für ihre Ernährung einen höheren Aufwand betreiben und sich viel Wissen über Lebensmittel aneignen.</li><li>Die ausreichende Versorgung mit Eisen, Zink, Eiweiß, Jod und Vitamin B12 sollte regelmäßig kontrolliert werden.</li><li>Eine abwechslungsreiche vegetarische Ernährung ist häufig mit Mehrkosten verbunden.</li><li>Vegetarier und Vegetarierinnen haben es im Alltag oft schwerer als Personen, die Fleisch essen (z. B. nicht so viel Auswahl in Restaurants oder Kantinen</li></ul></li></ul>',

    'P2m5_s4_l5_t5': '<ul><li>Die Übung wurde richtig bearbeitet, wenn der Teilnehmende:<ul><li>Schreibt, dass beim Veganismus nur pflanzliche Lebensmittel gegessen werden / keine Lebensmittel, die von Tieren stammen.</li><li>Einen Vorteil von Veganismus nennt.</li><li>Einen Nachteil von Veganismus nennt.</li></ul></li><li>Beispiele für Vorteile, die der Teilnehmende nennen könnte, sind:<ul><li>Tierschutz</li><li>Umwelt wird geschont</li><li>Gut für die Gesundheit</li><li>Welthunger kann so effektiv bekämpft werden</li></ul></li><li>Beispiele für Nachteile, die der Teilnehmende nennen könnte, sind:<ul><li>Gefahr von Mangelerscheinungen</li><li>Regelmäßige Kontrolle der Nährstoffversorgung nötig</li><li>Aneignung von Wissen ist zeitaufwendig</li><li>Die Verfügbarkeit von veganen Lebensmitteln und Gerichten in Restaurants und Supermärkten ist nicht immer gut</li></ul></li></ul>',

    'P3m5_s4_l5_t3': '<ul><li>Die Übung wurde richtig bearbeitet, wenn der Teilnehmende:<ul><li>Schreibt, dass bei der Paleo-Ernährung nur solche Lebensmittel gegessen werden, die es in der Steinzeit auch schon gab, z.B. Fleisch, Fisch, Meeresfrüchte, Eier, Obst, Gemüse, Pilze, Nüsse und Honig</li><li>Einen Vorteil von Paleo nennt.</li><li>Einen Nachteil von Paleo nennt.</li></ul></li><li>Ein „nice-to-have“ wäre, wenn er nennt, dass Lebensmittel, die industriell verarbeitet sind und die es in der Steinzeit noch nicht gab, z.B. Milchprodukte, Süßigkeiten, Nudeln nicht gegessen werden.</li><li>Beispiele für Vorteile, die der Teilnehmende nennen könnte, sind:<ul><li>Besserer Schlaf</li><li>Stärkt das Immunsystem</li><li>Nährstoffreichste Ernährungsform</li><li>Verzicht auf ungesunde Lebensmittel</li></ul></li><li>Beispiele für Nachteile, die der Teilnehmende nennen könnte, sind:<ul><li>aufwendige Ernährungsumstellung</li><li>Paleo-Ernährung ist teuer</li><li>sehr viele tierische Produkte, kein Tierschutz</li><li>Verzicht auf wichtige pflanzliche Proteinquellen</li></ul></li></ul>',

    'P4m5_s4_l5_t4': '<ul><li>Die Übung wurde richtig bearbeitet, wenn der Teilnehmende:<ul><li>Schreibt, dass bei der Low Carb-Ernährung Lebensmittel gegessen werden, die wenig oder keine Kohlenhydrate enthalten, also vor allem Fisch, Fleisch, Milchprodukte und Gemüse.</li><li>Einen Vorteil von Low Carb nennt.</li><li>Einen Nachteil von Low Carb nennt.</li></ul></li><li>Ein "nice-to-have" wäre, wenn er nennt, dass Lebensmittel, die viele Kohlenhydrate haben, wie Süßigkeiten, Zucker, Nudeln aus Weizenmehl nicht gegessen werden</li><li>Beispiele für Vorteile, die der Teilnehmende nennen könnte, sind:<ul><li>Low Carb macht länger satt</li><li>Ideale Versorung mit Nährstoffen</li><li>Niedrigerer Blutzuckerspiegel</li><li>Mehr Energie und weniger Müdigkeit</li></ul></li><li>Beispiele für Nachteile, die der Teilnehmende nennen könnte, sind:<ul><li>Aufwendige Ernährungsumstellung</li><li>Zu wenig Kohlenhydrate sind auf Dauer ungesund</li><li>Fleischkonsum muss beobachtet werden</li><li>Jojo-Effekt</li></ul></li></ul>',

    'P5m5_s4_l5_t4': '<ul><li>Die Übung wurde richtig bearbeitet, wenn der Teilnehmende:<ul><li>Schreibt, dass bei der Mischkost-Ernährung alle Lebensmittel gegessen werden, es kommt aber auf die richtige Menge an und dass es ausgewogen ist.</li><li>Einen Vorteil von Mischkost nennt.</li><li>Einen Nachteil von Mischkost nennt.</li></ul></li><li>Ein „nice-to-have“ wäre, wenn er nennt, dass der Schwerpunkt dieser Ernährungsform auf pflanzlichen Lebensmitteln wie Obst, Gemüse und Getreide liegt. Tierische Produkte wie Fleisch, Fisch und Eier sollten daher in nicht ganz so großen Mengen verzehrt werden.</li><li>Beispiele für Vorteile, die der Teilnehmende nennen könnte, sind:<ul><li>Alle Lebensmittel sind erlaubt</li><li>Mangelerscheinungen sind selten</li><li>Kann gut für eine Diät und als langfristige Ernährungsform genutzt werden</li><li>Verhindert den Jo-Jo-Effekt</li><li>Mehr abwechslungsreiche Ernährung</li></ul></li><li>Beispiele für Nachteile, die der Teilnehmende nennen könnte, sind:<ul><li>Vorher genau informieren ist zeitaufwendig</li><li>Leichter mal Zurückgreifen auf Fast Food</li><li>Nicht gut für die Umwelt</li><li>Massentierhaltung wird unterstützt</li><li>Motivation bei Diät kann schnell vergehen</li></ul></li></ul>',

        /**
     * MODUL 6
     */

    'Pm6_s1_l5_t1': 'Die Übung wurde richtig bearbeitet, wenn die Teilnehmenden mindestens ein Nahrungsmittel (Essen oder Getränk) genannt haben, das sich positiv auf den Darm/Verdauung auswirkt. Z.B. Vollkornprodukte (Vollkornbrot), ballaststoffreiche Nahrungsmittel, Haferflocken, Weizenkleie, Leinsamen, Obstsorten, Hülsenfrüchte (Erbsen, Bohnen, Linsen), Gemüsesorten, Wasser, o.Ä.',

    'Pm6_s2_l4_t1': 'Bei dieser Übung handelt es sich um eine Multiple-Choice-Frage ohne die Möglichkeit, eigene Strategien zu ergänzen.  Diese Übung kann deshalb nicht fehlerhaft bearbeitet werden. Die Inhalte zur Auswahl sind:<ul><li>Duschen</li><li>Baden</li><li>Rasieren</li><li>Zweimal täglich Zähne putzen</li><li>Regelmäßig zum Zahnarzt oder zur Zahnärztin gehen</li><li>Zahnseide oder kleine Bürstchen für die Zahnzwischenräume benutzen</li><li>Professionelle Zahnreinigung beim Zahnarzt</li><li>Hände regelmäßig eincremen</li><li>Nagelfeile benutzen',

    'Pm6_s3_l4_t2': 'Bei dieser Übung handelt es sich um eine Multiple-Choice-Frage ohne die Möglichkeit, eigene Strategien zu ergänzen. Diese Übung kann deshalb nicht fehlerhaft bearbeitet werden. Die Beispiele zur Auswahl sind:<ol><li>In der Natur sein</li><li>Singen</li><li>Meditieren</li><li>Yoga</li><li>Handy-Pause</li><li>Musik hören</li><li>Nichts davon</li></ol>',

    'Pm6_s4_l5_t1': 'Die Übung wurde korrekt bearbeitet, wenn die Teilnehmenden eine Definition von gemeinschaftlichem Wohlbefinden in ganzen Sätzen oder Stichpunkten geben. Dabei soll die vorgegebene Definition nicht einfach nur kopiert, sondern in eigenen Worten beschrieben werden. Die vorgegeben Definition lautet:  „Beim gemeinschaftlichen Wohlbefinden wird Wohlbefinden in einem breiteren gesellschaftlichen und gemeinschaftlichen Sinne betrachtet. Es geht also nicht mehr um die einzelne Person, sondern um Personen in einer Gemeinschaft. Gemeinschaftliches Wohlbefinden bedeutet, dass sich die Menschen in einer Gemeinschaft miteinander wohl fühlen.“'

}

// export
export default { help_object }