<template>

    <v-card flat>

        <v-card-text>

            <v-card flat outlined>

                <v-card-text class="body-1 primary--text">
                    <b>Wichtig:</b> Nutzen Sie diese Daten keinesfalls als alleinige Basis für eine Entscheidung. Die Daten stammen aus einer laufenden Studie, 
                    es ist wichtig, dass Sie die erhaltenen Informationen erst im Gespräch mit Ihrem*Ihrer Klient*in oder mithilfe eines Ihrer bereits 
                    etablierten Instrumente überprüfen, bevor Sie sich für ein weiteres Vorgehen entscheiden.
                </v-card-text>

            </v-card>

        </v-card-text>

        <v-card-text>

            <!-- Allgemeine Beschreibung -->
            <v-card flat outlined>

                <v-card-text class="title pb-1"><p>Allgemeine Hinweise</p></v-card-text>

                <v-card-text class="body-1 pt-1" v-html="data.description"></v-card-text>

            </v-card>

            <!-- Spezifische Hinweise: NDS -->
            <v-card flat outlined class="mt-5" v-if="federal_state == 1">

                <v-card-text class="title pb-1"><p>Spezifische Hinweise für Niedersachsen</p></v-card-text>

                <v-card-text class="body-1 pt-1" v-html="data.nds"> </v-card-text>

            </v-card>

            <!-- Spezifische Hinweise: BW -->
            <v-card flat outlined class="mt-5" v-if="federal_state == 2">

                <v-card-text class="title pb-1"><p>Spezifische Hinweise für Baden-Württemberg</p></v-card-text>

                <v-card-text class="body-1 pt-1" v-html="data.bw"> </v-card-text>

            </v-card>

            <!-- Spezifische Hinweise: Bayern -->
            <v-card flat outlined class="mt-5" v-if="federal_state == 3">

                <v-card-text class="title pb-1"><p>Spezifische Hinweise für Bayern</p></v-card-text>

                <v-card-text class="body-1 pt-1" v-html="data.bayern"> </v-card-text>

            </v-card>

        </v-card-text>


    </v-card>

</template>

<script>

export default {

    name: 'dhpol-guided',
    props: ['data', 'federal_state'],

    data () {
        return {
            
        }
    },
    
}
</script>

