<template>

<div>

    <v-row class="mb-3">
        <p class="title mt-2">Vielen Dank!</p>

    </v-row>

    <v-row class="mb-3">

        <p class="title font-weight-regular" style="text-align:justify;" v-if="$route.params.timepoint == 1 && $route.params.module != 0">
            Sie haben alle Fragebögen beantwortet! Wenn Sie nun auf "Weiter" klicken, dann geht es los mit der ersten Lektion der ersten Sitzung.
        </p>

        <p class="title font-weight-regular" style="text-align:justify;" v-if="$route.params.timepoint == 2 && $route.params.module != 0">
            Sie haben das Modul erfolgreich abgeschlossen! Wenn Sie nun auf "Weiter" klicken, kommen sie wieder zur Übersicht.
        </p>

        <p class="title font-weight-regular" style="text-align:justify;" v-if="$route.params.timepoint == 3 && $route.params.module != 0">
            Sie haben alle Fragebögen beantwortet! Wenn Sie nun auf "Weiter" klicken, dann geht es los mit der ersten Lektion der {{ $route.params.session }}. Sitzung.
        </p>

        <p class="title font-weight-regular" style="text-align:justify;" v-if="$route.params.timepoint == 4 && $route.params.module != 0">
            Sie haben die Sitzung erfolgreich abgeschlossen! Wenn Sie nun auf "Weiter" klicken, kommen sie wieder zur Übersicht.
        </p>

        <p class="title font-weight-regular" style="text-align:justify;" v-if="$route.params.timepoint == 1 && $route.params.module == 0">
            Sie haben alle Fragebögen beantwortet! Wenn Sie nun auf "Weiter" klicken, dann geht es los mit der Einführung.
        </p>

        <p class="title font-weight-regular" style="text-align:justify;" v-if="$route.params.timepoint == 2 && $route.params.module == 0">
            Sie haben alle Fragebögen beantwortet! Wenn Sie nun auf "Weiter" klicken, dann kommen Sie zurück auf die Startseite.
        </p>
    </v-row>

</div>

</template>

<script>
export default {
    name: 'assessment-end',

    data: () => ({
        //
    }),

    props: ['timepoint'],

    created() {
        //
    },

    computed: {
        //
    },

    methods: {
        //
    }
}
</script>

<style scoped>

</style>