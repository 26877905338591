<template>

    <v-container fluid class="py-0" v-if="post">

        <div>

            <!-- Title -->
            <v-row class="mb-3" v-if="$route.params.step == 0">
                <v-col cols="12" sm="12">
                    <p class="title mt-2">Sehr geehrte*r Bewährungshelfer*in,</p>
                </v-col>
                <v-col cols="12" sm="12">
                    <p class="title font-weight-regular" style="text-align:justify;">
                        Da es sich bei @myTabu um eine wissenschaftliche Studie handelt, ist es wichtig, dass detaillierte und zuverlässige Daten bezüglich Ihres*Ihrer Klienten*Klientin erhoben werden.</p>
                </v-col>
                <v-col cols="12" sm="12">
                    <p class="title font-weight-regular" style="text-align:justify;">
                        Bitte lesen Sie sich die folgenden Fragen genau durch. Bitte beantworten Sie die Fragen, wenn notwendig, unter Nutzung der Akten.
                    </p>
                </v-col>
            </v-row>


            <div v-if="$route.params.step > 0 && $route.params.step <= all_assessments.length && post" >
                <v-row class="mb-5">
                    
                    <v-expansion-panels class="mb-5 mt-1 mx-0" :value="panel">

                        <v-expansion-panel>

                            <v-expansion-panel-header class="title">Anleitung</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                
                                <p class="subtitle-1 font-weight-regular px-0 mx-0" style="text-align:justify;" v-html="assessment_content.fields.instruction">
                              
                                </p>

                                <p><b>Hinweis:</b> Sollte es trotz intensiver Recherche nicht möglich sein, in den Unterlagen eine bestimmte Angabe zu finden, so tragen Sie bitte <b><i>999</i></b> in das Feld ein.</p>

                            </v-expansion-panel-content>

                        </v-expansion-panel>

                    </v-expansion-panels>
                    
                    <p class="title" v-if="!assessment_content.already_saved"><span><v-icon color="error" class="mr-2">fas fa-exclamation</v-icon></span>
                        Bitte beantworten Sie alle Fragen (Teil {{ current_field_chunk + 1 }} von {{ get_chunks_of_field_verbose_names.length }}).
                    </p>
                    <p class="title" v-else><span><v-icon color="success" class="mr-2">fas fa-check</v-icon></span>
                        Sie haben bereits alle Fragen beantwortet (Teil {{ current_field_chunk + 1 }} von {{ get_chunks_of_field_verbose_names.length }}).
                    </p>
                    <v-progress-linear
                        color="primary"
                        id="progress"
                        height="10"
                        :value="progress_value"
                        class="mt-3 mb-3 mx-0"
                        rounded
                    ></v-progress-linear>
                </v-row>

                <v-row v-if="assessment_content.name == 'supervisor_sample'">

                    <v-col cols="12" sm="12">

                        <div v-if="current_field_chunk == 0">

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 1 && index != 3 && index != 4 && index != 6 && index != 7"
                                    >
                                        <v-card-text
                                                class="title primary--text"
                                            >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >

                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-5"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 1 && sample_supervisor_1"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="andere Staatsangehörigkeit"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                counter="255"
                                                :rules="[v => !!v || 'Sie müssen einen Wert angeben.', v => v.length <= 255 || 'Sie dürfen nicht mehr als 255 Zeichen eingeben.']"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-5"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 3 && sample_supervisor_2"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Anzahl"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :rules="[rules_text_field_numbers.required, rules_text_field_numbers.onlyNumbers, rules_text_field_numbers.notNull]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-5"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 4 && sample_supervisor_2"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-5"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 6 && sample_supervisor_3"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Anzahl"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :rules="[rules_text_field_numbers.required, rules_text_field_numbers.onlyNumbers, rules_text_field_numbers.notNull]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-5"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 7 && sample_supervisor_3"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                </div>

                            </v-form>

                        </div>

                        <div v-if="current_field_chunk == 1">

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 11 && index != 13"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-5"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 11 && sample_supervisor_6"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Diagnosen"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                counter="255"
                                                :rules="[v => !!v || 'Sie müssen einen Wert angeben.', v => v.length <= 255 || 'Sie dürfen nicht mehr als 255 Zeichen eingeben.']"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-5"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 13 && sample_supervisor_7"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Anzahl"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :rules="[rules_text_field_numbers.required, rules_text_field_numbers.onlyNumbers, rules_text_field_numbers.notNull]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                </div>

                            </v-form>

                        </div>

                        <div v-if="current_field_chunk == 2">

                            <v-card 
                                class="mb-4"
                                width="100%"
                                color="grey lighten-5"
                            >
                                
                             <v-card-text class="title">
                                 Im Folgenden finden Sie Fragen zu dem Anlassdelikt des Klienten. Mit Anlassdelikt sind die Delikte nach §§ 176, 176a, 176b oder 184b StGB gemeint, weshalb der Klient aktuell unterstellt ist.
                             </v-card-text>
                                
                            </v-card>

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 15 && index != 16 && index != 19 && index != 20"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-5"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="(index == 15 || index == 16) && sample_supervisor_8"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-5"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 19 && sample_supervisor_10"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Monate"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :rules="[rules_text_field_numbers.required, rules_text_field_numbers.onlyNumbers, rules_text_field_numbers.notNull]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-5"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 20 && sample_supervisor_10"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                </div>

                            </v-form>

                        </div>

                        <div v-if="current_field_chunk == 3">

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 25 && index != 27 && index != 28 && index != 23"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 23"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-5"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 25 && sample_supervisor_14"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Monate"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :rules="[rules_text_field_numbers.required, rules_text_field_numbers.onlyNumbers, rules_text_field_numbers.notNull]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-5"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 27 && sample_supervisor_15"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-5"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 28 && sample_supervisor_15 && get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][27]] == 6"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Bezeichnung der Kategorie"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                counter="255"
                                                :rules="[v => !!v || 'Sie müssen einen Wert angeben.', v => v.length <= 255 || 'Sie dürfen nicht mehr als 255 Zeichen eingeben.']"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                </div>

                            </v-form>

                        </div>

                        <div v-if="current_field_chunk == 4">

                            <v-card 
                                class="mb-4"
                                width="100%"
                                color="grey lighten-5"
                            >
                                <v-card-text
                                    class="title"
                                >
                                        Im Folgenden geht es um alle Missbrauchsdelikte an Kindern, die der Klient begangen hat. Gefragt wird nach begangenen sexuellen Übergriffen an Kindern. Mit sexuellen Übergriffen ist jede sexuelle Handlung und jede versuchte sexuelle Handlung (berühren, streicheln, küssen, eindringen in den Körper) vor oder an einem Kind gemeint. Das bedeutet, dass es bei dem sexuellen Übergriff körperlichen Kontakt zu dem Kind gegeben haben kann oder auch nicht (beispielsweise Exhibitionismus).
                                </v-card-text>
                            </v-card>

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 34"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 34"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                            <p class="ml-3"><small><b>Hinweis:</b> Wenn ihr*e Klient*in sowohl in der Bewährungshilfe als auch der Führungsaufsicht unterstellt ist, dann wählen Sie bitte <i>Führungsaufsicht</i>.</small></p>
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                </div>

                            </v-form>

                        </div>

                        <!-- sample_supervisor_21 = 1 == Bewährungshilfe -->
                        <div v-if="current_field_chunk == 5 && sample_supervisor_21 != 1">

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text
                                            class="title primary--text"
                                        >
                                        Diese Fragen treffen nicht auf Ihre*Ihren Klientin*Klienten zu. Bitte klicken Sie auf Weiter.
                                    </v-card-text>

                                </v-card>

                            </v-form>

                        </div>

                        <div v-if="current_field_chunk == 5 && sample_supervisor_21 == 1">

                            <v-card 
                                class="mb-4"
                                width="100%"
                                color="grey lighten-5"
                            >
                                <v-card-text
                                    class="title"
                                >
                                        Die folgenden Fragen beziehen sich auf die <b>aktuelle</b> Bewährungszeit des Klienten.
                                </v-card-text>
                            </v-card>

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 35 || index == 36 || index == 37"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                :label="index == 37 ? 'pro Monat' : 'Monate'"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :rules="[rules_text_field_numbers.required, rules_text_field_numbers.onlyNumbers, rules_text_field_numbers.notNull]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 38"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-5"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 39 && sample_supervisor_25"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Art der Heilbehandlung"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                counter="255"
                                                :rules="[v => !!v || 'Sie müssen einen Wert angeben.', v => v.length <= 255 || 'Sie dürfen nicht mehr als 255 Zeichen eingeben.']"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                </div>

                            </v-form>

                        </div>

                        <div v-if="current_field_chunk == 6 && sample_supervisor_21 != 1">

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text
                                        class="title primary--text"
                                    >
                                        Diese Fragen treffen nicht auf Ihre*Ihren Klientin*Klienten zu. Bitte klicken Sie auf Weiter.
                                    </v-card-text>

                                </v-card>

                            </v-form>

                        </div>

                        <div v-if="current_field_chunk == 6 && sample_supervisor_21 == 1">

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 40"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-5"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="(index == 41 || index == 42) && sample_supervisor_26"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                :label="index == 41 ? 'Anzahl' : 'Auflagen'"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :rules="index == 41 ? [rules_text_field_numbers.required, rules_text_field_numbers.onlyNumbers, rules_text_field_numbers.notNull] : [rules_text_field_numbers.required]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 43 && sample_supervisor_26"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-5"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 44 && sample_supervisor_26 && sample_supervisor_26c"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Änderungen"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                counter="255"
                                                :rules="[v => !!v || 'Sie müssen einen Wert angeben.', v => v.length <= 255 || 'Sie dürfen nicht mehr als 255 Zeichen eingeben.']"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                </div>

                            </v-form>

                        </div>

                        <div v-if="current_field_chunk == 7 && sample_supervisor_21 != 1">

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text
                                        class="title primary--text"
                                    >
                                        Diese Fragen treffen nicht auf Ihre*Ihren Klientin*Klienten zu. Bitte klicken Sie auf Weiter.
                                    </v-card-text>

                                </v-card>

                            </v-form>

                        </div>

                        <div v-if="current_field_chunk == 7 && sample_supervisor_21 == 1">

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 45"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-5"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="(index == 46 || index == 47) && sample_supervisor_27"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                :label="index == 46 ? 'Anzahl' : 'Weisungen'"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :rules="index == 46 ? [rules_text_field_numbers.required, rules_text_field_numbers.onlyNumbers, rules_text_field_numbers.notNull] : [rules_text_field_numbers.required]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 48 && sample_supervisor_27"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-5"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 49 && sample_supervisor_27 && sample_supervisor_27c"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Änderungen"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                counter="255"
                                                :rules="[v => !!v || 'Sie müssen einen Wert angeben.', v => v.length <= 255 || 'Sie dürfen nicht mehr als 255 Zeichen eingeben.']"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                </div>

                            </v-form>

                        </div>

                        <div v-if="current_field_chunk == 8 && sample_supervisor_21 != 1">

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text
                                        class="title primary--text"
                                    >
                                        Diese Fragen treffen nicht auf Ihre*Ihren Klientin*Klienten zu. Bitte klicken Sie auf Weiter.
                                    </v-card-text>

                                </v-card>

                            </v-form>

                        </div>

                        <div v-if="current_field_chunk == 8 && sample_supervisor_21 == 1">

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 53"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-5"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 53 && sample_supervisor_30"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Welche Drogen?"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                counter="255"
                                                :rules="[v => !!v || 'Sie müssen einen Wert angeben.', v => v.length <= 255 || 'Sie dürfen nicht mehr als 255 Zeichen eingeben.']"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                </div>

                            </v-form>

                        </div>



                        <!-- sample_supervisor_21 = 2 == Führungsaufsicht -->
                        <div v-if="current_field_chunk == 9 && sample_supervisor_21 != 2">

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text
                                            class="title primary--text"
                                        >
                                        Diese Fragen treffen nicht auf Ihre*Ihren Klientin*Klienten zu. Bitte klicken Sie auf Weiter.
                                    </v-card-text>

                                </v-card>

                            </v-form>

                        </div>

                        <div v-if="current_field_chunk == 9 && sample_supervisor_21 == 2">

                            <v-card 
                                class="mb-4"
                                width="100%"
                                color="grey lighten-5"
                            >
                                <v-card-text
                                    class="title"
                                >
                                        Die folgenden Fragen beziehen sich auf die <b>aktuelle</b> Führungszeit des Klienten.
                                </v-card-text>
                            </v-card>

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 54 || index == 58"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 56 || index == 57"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">


                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                :label="index == 55 ? 'Monate' : 'pro Monat'"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :rules="[rules_text_field_numbers.required, rules_text_field_numbers.onlyNumbers, rules_text_field_numbers.notNull]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>


                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-5"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 55 && sample_supervisor_31"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label='Monate'
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :rules="[rules_text_field_numbers.required, rules_text_field_numbers.onlyNumbers, rules_text_field_numbers.notNull]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-5"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 59 && sample_supervisor_34"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label='alle ? Wochen'
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :rules="[rules_text_field_numbers.required, rules_text_field_numbers.onlyNumbers, rules_text_field_numbers.notNull]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-5"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 60 && sample_supervisor_34"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 61"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">


                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>


                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-5"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 62 && sample_supervisor_35"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="pro Monat"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :rules="[rules_text_field_numbers.required, rules_text_field_numbers.onlyNumbers, rules_text_field_numbers.notNull]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                </div>

                            </v-form>

                        </div>

                        <div v-if="current_field_chunk == 10 && sample_supervisor_21 != 2">

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text 
                                        class="title primary--text"
                                    >
                                        Diese Fragen treffen nicht auf Ihre*Ihren Klientin*Klienten zu. Bitte klicken Sie auf Weiter.
                                    </v-card-text>

                                </v-card>

                            </v-form>

                        </div>

                        <div v-if="current_field_chunk == 10 && sample_supervisor_21 == 2">

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 63"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-5"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="(index == 66 || index == 68) && sample_supervisor_36"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-10"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 67 && sample_supervisor_36 && sample_supervisor_36c"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-5"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="(index == 64 || index == 65) && sample_supervisor_36"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                :label="index == 64 ? 'Anzahl' : 'Weisungen'"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :rules="index == 64 ? [rules_text_field_numbers.required, rules_text_field_numbers.onlyNumbers, rules_text_field_numbers.notNull] : [rules_text_field_numbers.required]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-10"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 69 && sample_supervisor_36 && sample_supervisor_36d"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Änderungen"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                counter="255"
                                                :rules="[v => !!v || 'Sie müssen einen Wert angeben.', v => v.length <= 255 || 'Sie dürfen nicht mehr als 255 Zeichen eingeben.']"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                </div>

                            </v-form>

                        </div>

                        <div v-if="current_field_chunk == 11 && sample_supervisor_21 != 2">

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text
                                        class="title primary--text"
                                    >
                                        Diese Fragen treffen nicht auf Ihre*Ihren Klientin*Klienten zu. Bitte klicken Sie auf Weiter.
                                    </v-card-text>

                                </v-card>

                            </v-form>

                        </div>

                        <div v-if="current_field_chunk == 11 && sample_supervisor_21 == 2">

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 73"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4 ml-5"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 73 && sample_supervisor_39"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Welche Drogen?"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                counter="255"
                                                :rules="[v => !!v || 'Sie müssen einen Wert angeben.', v => v.length <= 255 || 'Sie dürfen nicht mehr als 255 Zeichen eingeben.']"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                </div>

                            </v-form>

                        </div>

                    </v-col>

                </v-row>

                <!-- repeated_supervisor_sample -->
                <v-row v-if="assessment_content.name == 'repeated_supervisor_sample'">

                    <v-col cols="12" sm="12">

                        <div v-if="current_field_chunk == 0">

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index != 4"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 4"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                            <p class="ml-3"><small><b>Hinweis:</b> Wenn ihr*e Klient*in sowohl in der Bewährungshilfe als auch der Führungsaufsicht unterstellt ist, dann wählen Sie bitte <i>Führungsaufsicht</i>.</small></p>
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                </div>

                            </v-form>

                        </div>


                        <!-- Bewährungshilfe - repeated_sample_supervisor_5 == 1 -->
                        <div v-if="current_field_chunk == 1 && repeated_sample_supervisor_5 != 1">

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text 
                                        class="title primary--text"
                                    >
                                        Diese Fragen treffen nicht auf Ihre*Ihren Klientin*Klienten zu. Bitte klicken Sie auf Weiter.
                                    </v-card-text>

                                </v-card>

                            </v-form>

                        </div>

                        <div v-if="current_field_chunk == 1 && repeated_sample_supervisor_5 == 1">

                            <v-card 
                                class="mb-4"
                                width="100%"
                                color="grey lighten-5"
                            >
                                <v-card-text
                                    class="title black--text"
                                >
                                        Die folgenden Fragen beziehen sich auf die <b>aktuelle</b> Bewährungszeit des Klienten.
                                </v-card-text>
                            </v-card>

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 5"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="(index == 6 || index == 7) && repeated_sample_supervisor_6"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                :label="index == 6 ? 'Anzahl' : 'Auflagen'"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :rules="index == 6 ? [rules_text_field_numbers.required, rules_text_field_numbers.onlyNumbers, rules_text_field_numbers.notNull] : [rules_text_field_numbers.required]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 8 && repeated_sample_supervisor_6"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 9 && repeated_sample_supervisor_6 && repeated_sample_supervisor_6c"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Änderungen"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                counter="255"
                                                :rules="[v => !!v || 'Sie müssen einen Wert angeben.', v => v.length <= 255 || 'Sie dürfen nicht mehr als 255 Zeichen eingeben.']"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                </div>

                            </v-form>

                        </div>


                        <div v-if="current_field_chunk == 2 && repeated_sample_supervisor_5 != 1">

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text
                                        class="title primary--text"
                                    >
                                        Diese Fragen treffen nicht auf Ihre*Ihren Klientin*Klienten zu. Bitte klicken Sie auf Weiter.
                                    </v-card-text>

                                </v-card>

                            </v-form>

                        </div>

                        <div v-if="current_field_chunk == 2 && repeated_sample_supervisor_5 == 1">

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 10"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="(index == 11 || index == 12) && repeated_sample_supervisor_7"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                :label="index == 11 ? 'Anzahl' : 'Weisungen'"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :rules="index == 11 ? [rules_text_field_numbers.required, rules_text_field_numbers.onlyNumbers, rules_text_field_numbers.notNull] : [rules_text_field_numbers.required]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 13 && repeated_sample_supervisor_7"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 14 && repeated_sample_supervisor_7 && repeated_sample_supervisor_7c"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Änderungen"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                counter="255"
                                                :rules="[v => !!v || 'Sie müssen einen Wert angeben.', v => v.length <= 255 || 'Sie dürfen nicht mehr als 255 Zeichen eingeben.']"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                </div>

                            </v-form>

                        </div>

                        <!-- repeated_sample_supervisor_5 == 2  - Führungsaufsicht -->
                        <div v-if="current_field_chunk == 3 && repeated_sample_supervisor_5 != 2">

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <v-card 
                                    class="mb-4"
                                    width="100%"
                                    color="grey lighten-5"
                                >
                                    <v-card-text
                                        class="title primary--text"
                                    >
                                        Diese Fragen treffen nicht auf Ihre*Ihren Klientin*Klienten zu. Bitte klicken Sie auf Weiter.
                                    </v-card-text>

                                </v-card>

                            </v-form>

                        </div>

                        <div v-if="current_field_chunk == 3 && repeated_sample_supervisor_5 == 2">

                            <v-card 
                                class="mb-4"
                                width="100%"
                                color="grey lighten-5"
                            >
                                <v-card-text
                                    class="title"
                                >
                                        Die folgenden Fragen beziehen sich auf die <b>aktuelle</b> Führungszeit des Klienten.
                                </v-card-text>
                            </v-card>

                            <v-form 
                                ref="form" 
                                v-model="forms_valid[current_field_chunk]"  
                            >

                                <div
                                    v-for="(field, index) in get_chunks_of_field_verbose_names[current_field_chunk]"
                                    :key="index"
                                >

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 15"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="(index == 16 || index == 17) && repeated_sample_supervisor_8"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                :label="index == 16 ? 'Anzahl' : 'Weisungen'"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :rules="index == 16 ? [rules_text_field_numbers.required, rules_text_field_numbers.onlyNumbers, rules_text_field_numbers.notNull] : [rules_text_field_numbers.required]"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="(index == 18 || index == 20) && repeated_sample_supervisor_8"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 19 && repeated_sample_supervisor_8 && repeated_sample_supervisor_8c"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-radio-group 
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                :row="$vuetify.breakpoint.mdAndUp"
                                                :rules="[v => v != null || 'Sie müssen eine Antwortmöglichkeit auswählen.']"
                                                :disabled="assessment_content.already_saved"
                                            >
                                                <div
                                                    v-for="(label, x) in get_chunks_of_field_choices[current_field_chunk][index]"
                                                    :key="x"
                                                >
                                                    <v-radio
                                                        v-if="label[1] != 'NaN'"
                                                        :label="label[1]"
                                                        :value="label[0]"
                                                        @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, label[0])"
                                                    ></v-radio>

                                                </div>
                                            </v-radio-group>

                                        </div>
                                    </v-card>

                                    <v-card 
                                        class="mb-4"
                                        width="100%"
                                        color="grey lighten-5"
                                        v-if="index == 21 && repeated_sample_supervisor_8 && repeated_sample_supervisor_8d"
                                    >
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            {{parseInt(index) + 1}}. {{ get_chunks_of_field_verbose_names[current_field_chunk][index] }}
                                        </v-card-text>

                                        <div class="mx-3">

                                            <v-text-field
                                                class="mb-3 pb-3"
                                                single-line
                                                label="Änderungen"
                                                v-model="get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]]"
                                                counter="255"
                                                :rules="[v => !!v || 'Sie müssen einen Wert angeben.', v => v.length <= 255 || 'Sie dürfen nicht mehr als 255 Zeichen eingeben.']"
                                                @change="update_current_field_value(get_chunks_of_field_names[current_field_chunk][index], index, get_chunks_of_field_data[current_field_chunk][get_chunks_of_field_names[current_field_chunk][index]])"
                                            ></v-text-field>

                                        </div>
                                    </v-card>

                                </div>

                            </v-form>

                        </div>

                    </v-col>

                </v-row>

                <!-- Copyright -->
                <v-row>

                    <v-col cols="12" sm="12">
                        <v-row align="center" justify="center">
                            <div class="">&copy; {{ get_correct_copyright(assessment_content.name) }}</div>
                        </v-row>
                    </v-col>

                </v-row>

            </div>

            <AssessmentEnd v-if="$route.params.step > all_assessments.length" :timepoint="$route.params.timepoint" />

        </div>

                
            <!-- Navigation -->
            <v-row class="mb-2 mt-3">
                <v-btn 
                    @click="goBack()"
                    outlined
                    rounded
                    large
                    color="secondary" 
                    class="headline" 
                    style="text-transform: none !important"
                    :disabled="overall_current_position == 0"
                >
                    Zurück
                </v-btn>

                <v-spacer />

                <v-btn 
                    @click="goForward()"
                    outlined
                    rounded
                    large
                    color="primary" 
                    class="headline ml-2" 
                    style="text-transform: none !important"
                >
                    Weiter
                </v-btn>
            </v-row>
        
    </v-container>


</template>

<script>
import router from '@/router'

import SupervisorApi from '@/_api/supervisor_api'

import AssessmentEnd from '@/components/_client/_assessment/assessment_end_single'


export default {
    name: 'supervisor-assessment-single',

    data: () => ({

        post: false,

        direct_access: true,

        panel: 0,

        forms_valid: [],

        rules_text_field_numbers: {

            required: value => !!value || "Sie müssen einen Wert angeben.",

            onlyNumbers: value => {
                const pattern = /^[0-9]*$/;
                return (
                    pattern.test(value) ||
                    "Sie müssen eine Zahl eingeben."
                );
            },

            notNull: value => {
                const pattern = /^[1-9][0-9]*$/;
                return (
                    pattern.test(value) ||
                    "Sie müssen eine Zahl größer als 0 angeben."
                );
            },

            age: value => value >= 18 || "Sie müssen einen Wert größer oder gleich 18 Jahre angeben.",

        },

        current_field_chunk: 0,

        // sample_supervisor
        sample_supervisor_1: null,
        sample_supervisor_2: null,
        sample_supervisor_3: null,
        sample_supervisor_6: null,
        sample_supervisor_7: null,
        sample_supervisor_8: null,
        sample_supervisor_10: null,
        sample_supervisor_14: null,
        sample_supervisor_15: null,
        sample_supervisor_21: null,
        sample_supervisor_25: null,
        sample_supervisor_26: null,
        sample_supervisor_26c: null,
        sample_supervisor_27: null,
        sample_supervisor_27c: null,
        sample_supervisor_30: null,

        sample_supervisor_31: null,
        sample_supervisor_34: null,
        sample_supervisor_35: null,
        sample_supervisor_36: null,
        sample_supervisor_36c: null,
        sample_supervisor_36d: null,
        sample_supervisor_39: null,

        repeated_sample_supervisor_5: null,
        repeated_sample_supervisor_6: null,
        repeated_sample_supervisor_6c: null,
        repeated_sample_supervisor_7: null,
        repeated_sample_supervisor_7c: null,
        repeated_sample_supervisor_8: null,
        repeated_sample_supervisor_8c: null,
        repeated_sample_supervisor_8d: null,

        

    }),

    components: {

        AssessmentEnd

    },

    created() {

        this.post = false

        this.current_field_chunk = 0

        this.forms_valid = []

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        const data = {

                'module': parseInt(this.$route.params.module),
                'session': parseInt(this.$route.params.session),
                'lection': parseInt(this.$route.params.lection),
                'timepoint': parseInt(this.$route.params.timepoint),
                'name': this.$route.params.name,
                'client_user_id': parseInt(this.$route.params.client_user_id),
                'client_id': parseInt(this.$route.params.client_id)

            }

        SupervisorApi.GetSupervisorInfo().then(() => {

            SupervisorApi.GetSingleQuestionnaire(data).then(() => {

                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                this.add_is_validated()

                this.post = true

            })

        })

    },

    watch: {

        'current_field_chunk' () {

            this.$nextTick(() => {

                if (this.current_field_chunk != 0) {
                    this.$vuetify.goTo('#progress');
                    return;
                }

            })

        },

        '$route' () {

            this.current_field_chunk = 0

            this.forms_valid = []

            this.post = false

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

            const data = {

                'module': parseInt(this.$route.params.module),
                'session': parseInt(this.$route.params.session),
                'lection': parseInt(this.$route.params.lection),
                'timepoint': parseInt(this.$route.params.timepoint),
                'name': this.$route.params.name,
                'client_user_id': parseInt(this.$route.params.client_user_id),
                'client_id': parseInt(this.$route.params.client_id)

            }

            SupervisorApi.GetSupervisorInfo().then(() => {

                SupervisorApi.GetSingleQuestionnaire(data).then(() => {

                    this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                    this.add_is_validated()

                    this.post = true

                })

            })

        }

    },

    computed: {

        all_assessments() {
            return this.$store.getters['store_assessment/get_all_assessments_in_module']
        },

        assessment_content() {

            return this.$store.getters['store_assessment/get_current_assessment_content']
        },

        overall_current_position() {
           return this.$route.params.step
        },

        allowed() {

            if (this.$store.getters['store_client/get_client'].is_dev) {

                return true

            } else if (this.direct_access == false) {

                return true

            } else {

                return false

            }

        },

        progress_value() {

            return ((this.current_field_chunk + 1)/this.get_chunks_of_field_names.length)*100

        },

        get_chunks_of_field_choices () {
            /**
            * Summary. Divide all fields of questionnaire in chuncks of 3 fields
             */

            var final = [];
            var counter = 0;
            var portion = {};

            // do it only if step > 0
            if (this.$route.params.step != 0) {

                if (this.assessment_content.name == 'supervisor_sample') {

                    var ranges = [
                        [0, 8],    // 1 - 4
                        [9, 13],   // 5 - 7
                        [14, 20],   // 8 - 10
                        [21, 28],   // 11-15
                        [29, 34],   // 16-21

                        // Block A
                        [35, 39],   // 22-25
                        [40, 44],   // 26
                        [45, 49],   // 27
                        [50, 53],   // 28-30

                        // Block B
                        [54, 62],  // 31-35
                        [63, 69],  //36
                        [70, 73],  // 37-39

                    ];

                    var values = Object.values(this.assessment_content.fields.field_choices);
                    var keys =  Object.keys(this.assessment_content.fields.field_choices);

                    ranges.forEach(function (range) {

                        for (var i = range[0]; i <= range[1]; i++) {

                            portion[keys[i]] = values[i];

                        }

                        final.push(portion);

                        portion = {}

                    });

                } else if (this.assessment_content.name == 'repeated_supervisor_sample') {

                    ranges = [
                        [0, 4],    // 1 - 4

                        // Block A
                        [5, 9],   // 22-25
                        [10, 14],   // 26

                        // Block B
                        [15, 21],  // 31-35


                    ];

                    values = Object.values(this.assessment_content.fields.field_choices);
                    keys =  Object.keys(this.assessment_content.fields.field_choices);

                    ranges.forEach(function (range) {

                        for (var i = range[0]; i <= range[1]; i++) {

                            portion[keys[i]] = values[i];

                        }

                        final.push(portion);

                        portion = {}

                    });

                } else {

                    values = Object.values(this.assessment_content.fields.field_choices);

                    for (var key in this.assessment_content.fields.field_choices) {
                        if (counter !== 0 && counter % 3 === 0) {
                            final.push(portion);
                            portion = {};
                        }
                        
                        portion[key] = values[counter];
                        counter++
                    }

                    final.push(portion);

                    }


                }

            return final;

        },

        get_chunks_of_field_names () {
            /**
            * Summary. Divide all fields of questionnaire in chuncks of 3 fields
             */
            
            var final = [];
            var counter = 0;
            var portion = {};

            // do it only if step > 0
            if (this.$route.params.step != 0) {

                if (this.assessment_content.name == 'supervisor_sample') {

                    var ranges = [
                        [0, 8],    // 1 - 4
                        [9, 13],   // 5 - 7
                        [14, 20],   // 8 - 10
                        [21, 28],   // 11-15
                        [29, 34],   // 16-21

                        // Block A
                        [35, 39],   // 22-25
                        [40, 44],   // 26
                        [45, 49],   // 27
                        [50, 53],   // 28-30

                        // Block B
                        [54, 62],  // 31-35
                        [63, 69],  //36
                        [70, 73],  // 37-39

                    ];

                    var values = Object.values(this.assessment_content.fields.field_names);
                    var keys =  Object.keys(this.assessment_content.fields.field_names);

                    ranges.forEach(function (range) {

                        for (var i = range[0]; i <= range[1]; i++) {

                            portion[keys[i]] = values[i];

                        }

                        final.push(portion);

                        portion = {}

                    });

                } else if (this.assessment_content.name == 'repeated_supervisor_sample') {

                    ranges = [
                        [0, 4],    // 1 - 4

                        // Block A
                        [5, 9],   // 22-25
                        [10, 14],   // 26

                        // Block B
                        [15, 21],  // 31-35


                    ];

                    values = Object.values(this.assessment_content.fields.field_names);
                    keys =  Object.keys(this.assessment_content.fields.field_names);

                    ranges.forEach(function (range) {

                        for (var i = range[0]; i <= range[1]; i++) {

                            portion[keys[i]] = values[i];

                        }

                        final.push(portion);

                        portion = {}

                    });

                } else {

                    values = Object.values(this.assessment_content.fields.field_names);

                    for (var key in this.assessment_content.fields.field_names) {
                        if (counter !== 0 && counter % 3 === 0) {
                            final.push(portion);
                            portion = {};
                        }
                        
                        portion[key] = values[counter];
                        counter++
                    }

                    final.push(portion);

                    }

                }

            return final;
        },

        get_chunks_of_field_verbose_names () {
            /**
            * Summary. Divide all fields of questionnaire in chuncks of 3 fields
             */
            
            var final = [];
            var counter = 0;
            var portion = {};

            // do it only if step > 0
            if (this.$route.params.step != 0) {

                if (this.assessment_content.name == 'supervisor_sample') {

                    var ranges = [
                        [0, 8],    // 1 - 4
                        [9, 13],   // 5 - 7
                        [14, 20],   // 8 - 10
                        [21, 28],   // 11-15
                        [29, 34],   // 16-21

                        // Block A
                        [35, 39],   // 22-25
                        [40, 44],   // 26
                        [45, 49],   // 27
                        [50, 53],   // 28-30

                        // Block B
                        [54, 62],  // 31-35
                        [63, 69],  //36
                        [70, 73],  // 37-39

                    ];

                    var values = Object.values(this.assessment_content.fields.field_verbose_names);
                    var keys =  Object.keys(this.assessment_content.fields.field_verbose_names);

                    ranges.forEach(function (range) {

                        for (var i = range[0]; i <= range[1]; i++) {

                            portion[keys[i]] = values[i];

                        }

                        final.push(portion);

                        portion = {}

                    });

                } else if (this.assessment_content.name == 'repeated_supervisor_sample') {

                    ranges = [
                        [0, 4],    // 1 - 4

                        // Block A
                        [5, 9],   // 22-25
                        [10, 14],   // 26

                        // Block B
                        [15, 21],  // 31-35


                    ];

                    values = Object.values(this.assessment_content.fields.field_verbose_names);
                    keys =  Object.keys(this.assessment_content.fields.field_verbose_names);

                    ranges.forEach(function (range) {

                        for (var i = range[0]; i <= range[1]; i++) {

                            portion[keys[i]] = values[i];

                        }

                        final.push(portion);

                        portion = {}

                    });

                } else {

                    values = Object.values(this.assessment_content.fields.field_verbose_names);

                    for (var key in this.assessment_content.fields.field_verbose_names) {
                        if (counter !== 0 && counter % 3 === 0) {
                            final.push(portion);
                            portion = {};
                        }
                        
                        portion[key] = values[counter];
                        counter++
                    }

                    final.push(portion);

                    }

                }

            return final;
        },

        get_chunks_of_field_data () {
            /**
            * Summary. Divide all fields of questionnaire in chuncks of 3 fields
             */
            
            var final = [];
            var counter = 0;
            var portion = {};

            // do it only if step > 0
            if (this.$route.params.step != 0) {

                if (this.assessment_content.name == 'supervisor_sample') {

                    var ranges = [
                        [0, 8],    // 1 - 4
                        [9, 13],   // 5 - 7
                        [14, 20],   // 8 - 10
                        [21, 28],   // 11-15
                        [29, 34],   // 16-21

                        // Block A
                        [35, 39],   // 22-25
                        [40, 44],   // 26
                        [45, 49],   // 27
                        [50, 53],   // 28-30

                        // Block B
                        [54, 62],  // 31-35
                        [63, 69],  //36
                        [70, 73],  // 37-39

                    ];

                    var values = Object.values(this.assessment_content.fields.data);
                    var keys =  Object.keys(this.assessment_content.fields.data);

                    ranges.forEach(function (range) {

                        for (var i = range[0]; i <= range[1]; i++) {

                            portion[keys[i]] = values[i];

                        }

                        final.push(portion);

                        portion = {}

                    });

                } else if (this.assessment_content.name == 'repeated_supervisor_sample') {

                    ranges = [
                        [0, 4],    // 1 - 4

                        // Block A
                        [5, 9],   // 22-25
                        [10, 14],   // 26

                        // Block B
                        [15, 21],  // 31-35


                    ];

                    values = Object.values(this.assessment_content.fields.data);
                    keys =  Object.keys(this.assessment_content.fields.data);

                    ranges.forEach(function (range) {

                        for (var i = range[0]; i <= range[1]; i++) {

                            portion[keys[i]] = values[i];

                        }

                        final.push(portion);

                        portion = {}

                    });

                } else {

                    values = Object.values(this.assessment_content.fields.data);

                    for (var key in this.assessment_content.fields.data) {
                        if (counter !== 0 && counter % 3 === 0) {
                            final.push(portion);
                            portion = {};
                        }
                        
                        portion[key] = values[counter];
                        counter++
                    }

                    final.push(portion);

                    }

            }

            return final;
        },

    },

    methods: {

        get_correct_copyright(name) {

            var txt = ''

            if (name == 'aoo') {

                txt = ''

            } else if (name == 'acute') {

                txt = 'Kriminologische Zentralstelle (KrimZ), 2020'
            
            } else if (name == 'bis') {

                txt = 'Meule, Vögele & Kübler, 2011'            
            
            } else if (name == 'cmc') {

                txt = 'Kriminologische Zentralstelle (KrimZ), 2020'
            
            } else if (name == 'cte') {

                txt = 'Kriminologische Zentralstelle (KrimZ), 2020'
            
            } else if (name == 'cusi') {

                txt = 'Cortoni & Marshall, 2001; deutsche Übersetzung: @myTabu'
            
            } else if (name == 'cvtrq') {

                txt = 'Casey, Day, Howells & Ward, 2007; deutsche Übersetzung: @myTabu'
            
            } else if (name == 'ders') {

                txt = 'Gratz & Roemer, 2004; deutsche Übersetzung: Ehring, Fischer, Schnülle, Bösterling & Tuschen-Caffier, 2008'
            
            } else if (name == 'ekk') {

                txt = 'Mack & Yundina, 2012'
            
            } else if (name == 'esiq') {

                txt = 'Banse, Schmidt & Clabour, 2010  '
            
            } else if (name == 'exsik') {

                txt = 'Tozdan, Jakob, Schuhmann, Budde & Briken, 2015'
            
            } else if (name == 'fsozu') {

                txt = 'Mit freundlicher Genehmigung der Hogrefe Verlag GmbH & Co. KG © Hogrefe Verlag GmbH & Co. KG, Göttingen. Nachdruck und jegliche Art der Vervielfältigung verboten.'
            
            } else if (name == 'flashlight_pre') {

                txt = '@myTabu'
            
            } else if (name == 'flashlight_post') {

                txt = '@myTabu'
            
            } else if (name == 'flexsik') {

                txt = 'Tozdan, Jakob, Schuhmann, Budde & Briken, 2015'
            
            } else if (name == 'hbi') {

                txt = 'Reid, Garos & Carpenter, 2011; deutsche Übersetzung: Klein, Rettenberger, Boom & Briken, 2013'
            
            } else if (name == 'icd') {

                txt = 'Briken, 2019'
            
            } else if (name == 'kvm') {

                txt = 'Feelgood, Schaefer & Hoyer, 2008, https://creativecommons.org/licenses/by-nc-nd/3.0/de/, keine Änderungen vorgenommen.'
            
            } else if (name == 'motsik') {

                txt = 'Tozdan, Jakob, Schuhmann, Budde & Briken, 2015'
            
            } else if (name == 'narq') {

                txt = 'Scherer, Eberle, Boecker, Vöggele, Gauggel & Forkmann, 2013'
            
            } else if (name == 'ors') {

                txt = 'Herzberg, 2002'
            
            } else if (name == 'phq') {

                txt = 'deutsche Übersetzung: Löwe, Spitzer, Zipfel & Herzog, 2002'
            
            } else if (name == 'rcq') {

                txt = 'Rollnick, Heather, Gold & Hall, 1992; deutsche Übersetzung: Hannöver, Thyrian, Hapke, Rumpf, Meyer & John, 2002'
            
            } else if (name == 'sample_client') {

                txt = '@myTabu'
            
            } else if (name == 'sbv') {

                txt = 'Endres & Oranu, 2010'
            
            } else if (name == 'soi') {

                txt = 'Briken, 2010'
            
            } else if (name == 'spsi') {

                txt = '1996, 2002 Multi-Health Systems Inc. All rights reserved. Reproduced only with Permission from MHS – permissions@mhs.com  '
            
            } else if (name == 'ssik') {

                txt = 'Tozdan, Jakob, Schuhmann, Budde & Briken, 2015'
            
            } else if (name == 'supervisor_sample') {

                txt = '@myTabu'
            
            } else if (name == 'repeated_supervisor_sample') {

                txt = '@myTabu'

            } else if (name == 'ucla') {

                txt = 'Bilsky & Hosser, 1998'

            } else if (name == 'utaut') {

                txt = '@myTabu'
            
            } else if (name == 'wai') {

                txt = 'Horvath & Greenberg 1986, 1989; deutsche Übersetzung: Wilmers, Munder, Leonhart, Herzog, Plassmann, Barth & Linster, 2008'
            
            } else if (name == 'wai_sv') {

                txt = 'Horvath & Greenberg 1986, 1989; deutsche Übersetzung: Wilmers, Munder, Leonhart, Herzog, Plassmann, Barth & Linster, 2008'
            
            } else if (name == 'who') {

                txt = 'Bech, 2012; deutsche Übersetzung: Brähler, Mühlan, Albani & Schmidt, 2007'

            } else {

                txt = ''

            }

            return txt

        },

        add_is_validated() {

            for (var i = 0; i < this.get_chunks_of_field_names.length; i++) {

                this.forms_valid.push(true)
            }
        },

        goBack() {

            if (this.$route.params.step > 0) {

                if (this.current_field_chunk > 0) {

                    // go next    
                    this.current_field_chunk = this.current_field_chunk - 1

                } else if (this.current_field_chunk == 0) {
            
                    router.push({ name: 'supervisor-assessment' })

                }

            }

        },

        goForward() {

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

            if (this.$route.params.step == 0) {

                // show FIRST questionnaire
                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_CONTENT', this.$store.getters['store_assessment/get_all_assessments_in_module'][this.overall_current_position])
                this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA', this.$store.getters['store_assessment/get_all_assessments_in_module'][this.overall_current_position].fields.data)
            
                router.push({ name: 'supervisor-assessment-single', params: { module: this.$route.params.module, session: this.$route.params.session, lection: this.$route.params.lection, timepoint: this.$route.params.timepoint, name:this.$route.params.name, step: parseInt(this.$route.params.step) + 1 } })


            } else if (this.$route.params.step > 0 && this.$route.params.step < this.all_assessments.length) {

                if (this.current_field_chunk < this.get_chunks_of_field_names.length - 1) {

                    // validate form
                    if (this.$refs.form.validate()) {

                        // go next    
                        this.current_field_chunk = this.current_field_chunk + 1
                        //this.$refs.form.resetValidation()

                    }  else {

                        this.$nextTick(() => {
                            const el = this.$el.querySelector(".v-messages__message:first-of-type");

                            var rect = el.getBoundingClientRect()
                            var scrollTop = window.pageYOffset || document.documentElement.scrollTop
                            var top = rect.top + scrollTop

                            this.$vuetify.goTo(top - 100);
                            return;
                        });
                    }
                    
                } else if (this.current_field_chunk == this.get_chunks_of_field_names.length - 1) {

                    // save current questionnaire
                    if (this.$refs.form.validate()) {

                        const data = {

                            'module': parseInt(this.$route.params.module),
                            'session': parseInt(this.$route.params.session),
                            'lection': parseInt(this.$route.params.lection),
                            'name': this.assessment_content.name,
                            'client_user_id': parseInt(this.$route.params.client_user_id),
                            'client_id': parseInt(this.$route.params.client_id)

                        }

                        if (this.assessment_content.already_saved == false) {

                            SupervisorApi.SaveAssessmentData(data).then(() => {

                                const data = {

                                    'module': parseInt(this.$route.params.module),
                                    'session': parseInt(this.$route.params.session),
                                    'lection': parseInt(this.$route.params.lection),
                                    'timepoint': parseInt(this.$route.params.timepoint),
                                    'name': this.assessment_content.name,
                                    'client_user_id': parseInt(this.$route.params.client_user_id),
                                    'client_id': parseInt(this.$route.params.client_id)

                                }

                                SupervisorApi.UpdateOpenAssessments(data).then(() => {
                                
                                    router.push({ name: 'supervisor-assessment-single', params: { module: this.$route.params.module, session: this.$route.params.session, lection: this.$route.params.lection, timepoint: this.$route.params.timepoint, name:this.$route.params.name, step: parseInt(this.$route.params.step) + 1 } })

                                })

                            })

                        } else {

                            router.push({ name: 'supervisor-assessment-single', params: { module: this.$route.params.module, session: this.$route.params.session, lection: this.$route.params.lection, timepoint: this.$route.params.timepoint, name:this.$route.params.name, step: parseInt(this.$route.params.step) + 1 } })

                        }

                        

                    }  else {

                        this.$nextTick(() => {
                            const el = this.$el.querySelector(".v-messages__message:first-of-type");

                            var rect = el.getBoundingClientRect()
                            var scrollTop = window.pageYOffset || document.documentElement.scrollTop
                            var top = rect.top + scrollTop

                            this.$vuetify.goTo(top - 100);
                            return;
                        });
                    }

                }

            } else if (this.$route.params.step == this.all_assessments.length) {

                if (this.current_field_chunk < this.get_chunks_of_field_names.length - 1) {

                    // validate form
                    if (this.$refs.form.validate()) {

                        // go next    
                        this.current_field_chunk = this.current_field_chunk + 1
                        //this.$refs.form.resetValidation()

                    }  else {

                        this.$nextTick(() => {
                            const el = this.$el.querySelector(".v-messages__message:first-of-type");

                            var rect = el.getBoundingClientRect()
                            var scrollTop = window.pageYOffset || document.documentElement.scrollTop
                            var top = rect.top + scrollTop

                            this.$vuetify.goTo(top - 100);
                            return;
                        });
                    }
                    
                } else if (this.current_field_chunk == this.get_chunks_of_field_names.length - 1) {

                    if (this.$refs.form.validate()) {

                        const data = {

                            'module': parseInt(this.$route.params.module),
                            'session': parseInt(this.$route.params.session),
                            'lection': parseInt(this.$route.params.lection),
                            'name': this.assessment_content.name,
                            'client_user_id': parseInt(this.$route.params.client_user_id),
                            'client_id': parseInt(this.$route.params.client_id)

                        }

                        if (this.assessment_content.already_saved == false) {

                            SupervisorApi.SaveAssessmentData(data).then(() => {

                                const data = {

                                    'module': parseInt(this.$route.params.module),
                                    'session': parseInt(this.$route.params.session),
                                    'lection': parseInt(this.$route.params.lection),
                                    'timepoint': parseInt(this.$route.params.timepoint),
                                    'name': this.assessment_content.name,
                                    'client_user_id': parseInt(this.$route.params.client_user_id),
                                    'client_id': parseInt(this.$route.params.client_id)

                                }

                                SupervisorApi.UpdateOpenAssessments(data).then(() => {

                                    router.push({ name: 'supervisor-assessment-single', params: { module: this.$route.params.module, session: this.$route.params.session, lection: this.$route.params.lection, timepoint: this.$route.params.timepoint, name:this.$route.params.name, step: parseInt(this.$route.params.step) + 1 } })

                                })
                            })

                        } else {

                            router.push({ name: 'supervisor-assessment-single', params: { module: this.$route.params.module, session: this.$route.params.session, lection: this.$route.params.lection, timepoint: this.$route.params.timepoint, name:this.$route.params.name, step: parseInt(this.$route.params.step) + 1 } })

                        }

                    }  else {

                        this.$nextTick(() => {
                            const el = this.$el.querySelector(".v-messages__message:first-of-type");

                            var rect = el.getBoundingClientRect()
                            var scrollTop = window.pageYOffset || document.documentElement.scrollTop
                            var top = rect.top + scrollTop

                            this.$vuetify.goTo(top - 100);
                            return;
                        });
                    }

                }

            } else {

                // go to overview
                this.$router.push({ name: 'supervisor-assessment'})

            
            }

             this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

        },

        update_current_field_value(field, index, value) {

            // supervisor_sample
            if (this.assessment_content.name == 'supervisor_sample') {

                if (this.current_field_chunk == 0) {

                    if (index == 0) {
                        
                        if (value == 2 || value == 3) {

                            this.sample_supervisor_1 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][1]})

                        } else {

                            this.sample_supervisor_1 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][1]}) 

                        }

                    } else if (index == 2) {

                        if (value == 1) {

                            this.sample_supervisor_2 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][3]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][4]})

                        } else {

                            this.sample_supervisor_2 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[this.current_field_chunk][3]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[this.current_field_chunk][4]})  

                        }

                    } else if (index == 5) {

                        if (value == 1) {

                            this.sample_supervisor_3 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][6]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][7]}) 

                        } else {

                            this.sample_supervisor_3 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[this.current_field_chunk][6]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[this.current_field_chunk][7]})  

                        }

                    } else {

                        if (index == 1) {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': value, 'key': field}) 
                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field}) 
                        }
        
                    }

                } else if (this.current_field_chunk == 1) {

                    if (index == 10) {
                        
                        if (value == 1) {

                            this.sample_supervisor_6 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][11]})

                        } else {

                            this.sample_supervisor_6 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][11]}) 

                        }

                    } else if (index == 12) {

                        if (value == 1) {

                            this.sample_supervisor_7 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][13]})

                        } else {

                            this.sample_supervisor_7 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[this.current_field_chunk][13]})  

                        }

                    } else {
                        if (index == 11) {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': value, 'key': field}) 
                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field}) 
                        }
                    }

                } else if (this.current_field_chunk == 2) {

                    if (index == 14) {
                        
                        if (value == 1 || value == 3) {

                            this.sample_supervisor_8 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][15]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][16]})

                        } else {

                            this.sample_supervisor_8 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[this.current_field_chunk][15]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[this.current_field_chunk][16]})

                        }

                    } else if (index == 18) {

                        if (value == 1) {

                            this.sample_supervisor_10 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][19]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][20]})

                        } else {

                            this.sample_supervisor_10 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[this.current_field_chunk][19]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 4, 'key': this.get_chunks_of_field_names[this.current_field_chunk][20]}) 

                        }

                        
                    } else {
                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field}) 
                    }

                } else if (this.current_field_chunk == 3) {

                    if (index == 24) {
                        
                        if (value == 1|| value == 2 || value == 3) {

                            this.sample_supervisor_14 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][25]})

                        } else {

                            this.sample_supervisor_14 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[this.current_field_chunk][25]})
                        }

                    // HIER 15 */
                    } else if (index == 26) {

                        if (value == 1) {

                            this.sample_supervisor_15 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][27]})


                        } else {

                            this.sample_supervisor_15 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 4, 'key': this.get_chunks_of_field_names[this.current_field_chunk][27]})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][28]})

                        }

                    } else if (index == 27) {

                        if (value == 6) {

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': '', 'key': this.get_chunks_of_field_names[this.current_field_chunk][28]})

                        } else {

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][28]})
                        }

                        
                    } else {

                        if (index == 28) {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': value, 'key': field}) 
                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field}) 
                        }
                        
                    }

                } else if (this.current_field_chunk == 4) {

                    if (index == 34) {

                        console.debug('VALUE: ' + value)
                        
                        if (value == 1) {

                            this.sample_supervisor_21 = 1

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 3, 'key': this.get_chunks_of_field_names[9][54]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[9][55]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[9][56]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[9][57]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[9][58]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[9][59]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[9][60]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[9][61]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[9][62]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[10][63]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[10][64]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[10][65]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[10][66]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 4, 'key': this.get_chunks_of_field_names[10][67]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 6, 'key': this.get_chunks_of_field_names[10][68]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[10][69]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[11][70]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[11][71]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[11][72]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[11][73]})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[5][35]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[5][36]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[5][37]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[5][38]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[5][39]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[6][40]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[6][41]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[6][42]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[6][43]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[6][44]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[7][45]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[7][46]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[7][47]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[7][48]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[7][49]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[8][50]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[8][51]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[8][52]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[8][53]})

                        } else {

                            this.sample_supervisor_21 = 2

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[5][35]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[5][36]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[5][37]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[5][38]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[5][39]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[6][40]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[6][41]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[6][42]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[6][43]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[6][44]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[7][45]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[7][46]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[7][47]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[7][48]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[7][49]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[8][50]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[8][51]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[8][52]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[8][53]})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[9][54]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[9][55]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[9][56]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[9][57]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[9][58]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[9][59]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[9][60]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[9][61]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[9][62]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[10][63]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[10][64]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[10][65]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[10][66]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[10][67]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[10][68]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[10][69]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[11][70]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[11][71]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[11][72]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[11][73]})

                        }

                        
                    } else {
                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field}) 
                    }

                } else if (this.current_field_chunk == 5) {

                    if (index == 38) {
                        
                        if (value == 1) {

                            this.sample_supervisor_25 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': '', 'key': this.get_chunks_of_field_names[this.current_field_chunk][39]})

                        } else {

                            this.sample_supervisor_25 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][39]})
                        }

                        
                    } else {
                        if (index == 39) {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': value, 'key': field}) 
                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field}) 
                        }
                    }

                } else if (this.current_field_chunk == 6) {

                    if (index == 40) {
                        
                        if (value == 1) {

                            this.sample_supervisor_26 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][41]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': '', 'key': this.get_chunks_of_field_names[this.current_field_chunk][42]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][43]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': '', 'key': this.get_chunks_of_field_names[this.current_field_chunk][44]})

                        } else {

                            this.sample_supervisor_26 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[this.current_field_chunk][41]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][42]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[this.current_field_chunk][43]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][44]})
                        }

                    } else if (index == 43) {
                        
                        if (value == 1) {

                            this.sample_supervisor_26c = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': '', 'key': this.get_chunks_of_field_names[this.current_field_chunk][44]})

                        } else {

                            this.sample_supervisor_26c = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][44]})
                        }

                        
                    } else {
                        if (index == 42 || index == 44) {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': value, 'key': field}) 
                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field}) 
                        }
                    }

                } else if (this.current_field_chunk == 7) {

                    if (index == 45) {
                        
                        if (value == 1) {

                            this.sample_supervisor_27 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][46]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': '', 'key': this.get_chunks_of_field_names[this.current_field_chunk][47]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][48]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': '', 'key': this.get_chunks_of_field_names[this.current_field_chunk][49]})

                        } else {

                            this.sample_supervisor_27 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[this.current_field_chunk][46]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][47]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[this.current_field_chunk][48]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][49]})
                        }

                    } else if (index == 48) {
                        
                        if (value == 1) {

                            this.sample_supervisor_27c = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': value, 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': '', 'key': this.get_chunks_of_field_names[this.current_field_chunk][49]})

                        } else {

                            this.sample_supervisor_27c = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][49]})
                        }

                        
                    } else {
                        if (index == 47 || index == 49) {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': value, 'key': field}) 
                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field}) 
                        }
                    }

                } else if (this.current_field_chunk == 8) {

                    if (index == 52) {
                        
                        if (value == 1) {

                            this.sample_supervisor_30 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': '', 'key': this.get_chunks_of_field_names[this.current_field_chunk][53]})

                        } else {

                            this.sample_supervisor_30 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][53]})

                        }
                        
                    } else {
                        if (index == 53) {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': value, 'key': field}) 
                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field}) 
                        }
                    }

                } else if (this.current_field_chunk == 9) {

                    if (index == 54) {
                        
                        if (value == 1) {

                            this.sample_supervisor_31 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][55]})

                        } else {

                            this.sample_supervisor_31 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[this.current_field_chunk][55]})

                        }

                    } else if (index == 58) {
                        
                        if (value == 1) {

                            this.sample_supervisor_34 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][59]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][60]})

                        } else {

                            this.sample_supervisor_34 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[this.current_field_chunk][59]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[this.current_field_chunk][60]})

                        }

                    } else if (index == 61) {
                        
                        if (value == 1) {

                            this.sample_supervisor_35 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][62]})

                        } else {

                            this.sample_supervisor_35 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[this.current_field_chunk][62]})

                        }
                        
                    } else {
                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field}) 
                    }

                } else if (this.current_field_chunk == 10) {

                    if (index == 63) {
                        
                        if (value == 1) {

                            this.sample_supervisor_36 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][64]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': '', 'key': this.get_chunks_of_field_names[this.current_field_chunk][65]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][66]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][67]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][68]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': '', 'key': this.get_chunks_of_field_names[this.current_field_chunk][69]})

                        } else {

                            this.sample_supervisor_36 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[this.current_field_chunk][64]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][65]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[this.current_field_chunk][66]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 4, 'key': this.get_chunks_of_field_names[this.current_field_chunk][67]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 6, 'key': this.get_chunks_of_field_names[this.current_field_chunk][68]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][69]})

                        }

                    } else if (index == 66) {
                        
                        if (value == 1) {

                            this.sample_supervisor_36c = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][67]})

                        } else {

                            this.sample_supervisor_36c = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 4, 'key': this.get_chunks_of_field_names[this.current_field_chunk][67]})

                        }

                    } else if (index == 68) {
                        
                        if (value == 4) {

                            this.sample_supervisor_36d = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': '', 'key': this.get_chunks_of_field_names[this.current_field_chunk][69]})

                        } else {

                            this.sample_supervisor_36d = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][69]})

                        }
                        
                    } else {
                        if (index == 65 || index == 67 || index == 69) {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': value, 'key': field}) 
                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field}) 
                        }
                    }

                } else if (this.current_field_chunk == 11) {

                    if (index == 72) {
                        
                        if (value == 1) {

                            this.sample_supervisor_39 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': '', 'key': this.get_chunks_of_field_names[this.current_field_chunk][73]})

                        } else {

                            this.sample_supervisor_39 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][73]})

                        }
                        
                    } else {
                        if (index == 73) {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': value, 'key': field}) 
                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field}) 
                        } 
                    }


                } else {
                    this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field}) 
                }

            } else if (this.assessment_content.name == 'repeated_supervisor_sample') {

                if (this.current_field_chunk == 0) {

                    if (index == 4) {
                        
                        if (value == 1) {

                            this.repeated_sample_supervisor_5 = 1

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[3][15]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[3][16]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[3][17]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[3][18]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 4, 'key': this.get_chunks_of_field_names[3][19]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 6, 'key': this.get_chunks_of_field_names[3][20]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[3][21]})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[1][5]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[1][6]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[1][7]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[1][8]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[1][9]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[2][10]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[2][11]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[2][12]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[2][13]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[2][14]})

                        } else {

                            this.repeated_sample_supervisor_5 = 2

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[1][5]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[1][6]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[1][7]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[1][8]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[1][9]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[2][10]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[2][11]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[2][12]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[2][13]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[2][14]})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[3][15]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[3][16]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[3][17]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[3][18]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[3][19]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[3][20]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[3][21]})

                        }
                        
                    } else {
    
                        this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field}) 

                    }

                } else if (this.current_field_chunk == 1) {

                    if (index == 5) {
                        
                        if (value == 1) {

                            this.repeated_sample_supervisor_6 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][6]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][7]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][8]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][9]})

                        } else {

                            this.repeated_sample_supervisor_6 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[this.current_field_chunk][6]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][7]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[this.current_field_chunk][8]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][9]})

                        }

                    } else if (index == 8) {

                        if (value == 1) {

                            this.repeated_sample_supervisor_6c = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][9]})

                        } else {

                            this.repeated_sample_supervisor_6c = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][9]})
                        }
                        
                    } else {
    
                        if (index == 7 || index == 9) {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': value, 'key': field}) 
                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field}) 
                        } 

                    }

                } else if (this.current_field_chunk == 2) {

                    if (index == 10) {
                        
                        if (value == 1) {

                            this.repeated_sample_supervisor_7 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][11]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][12]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][13]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][14]})

                        } else {

                            this.repeated_sample_supervisor_7 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[this.current_field_chunk][11]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][12]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[this.current_field_chunk][13]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][14]})

                        }

                    } else if (index == 13) {

                        if (value == 1) {

                            this.repeated_sample_supervisor_7c = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][14]})

                        } else {

                            this.repeated_sample_supervisor_7c = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][14]})
                        }
                        
                    } else {
    
                        if (index == 12 || index == 14) {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': value, 'key': field}) 
                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field}) 
                        } 

                    }

                } else if (this.current_field_chunk == 3) {

                    if (index == 15) {
                        
                        if (value == 1) {

                            this.repeated_sample_supervisor_8 = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][16]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][17]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][18]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][19]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][20]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][21]})

                        } else {

                            this.repeated_sample_supervisor_8 = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': -999, 'key': this.get_chunks_of_field_names[this.current_field_chunk][16]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][17]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 2, 'key': this.get_chunks_of_field_names[this.current_field_chunk][18]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 4, 'key': this.get_chunks_of_field_names[this.current_field_chunk][19]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 6, 'key': this.get_chunks_of_field_names[this.current_field_chunk][20]})
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][21]})

                        }

                    } else if (index == 18) {

                        if (value == 1) {

                            this.repeated_sample_supervisor_8c = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][19]})

                        } else {

                            this.repeated_sample_supervisor_8c = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 4, 'key': this.get_chunks_of_field_names[this.current_field_chunk][19]})
                        }

                    } else if (index == 20) {

                        if (value == 4) {

                            this.repeated_sample_supervisor_8d = true

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': null, 'key': this.get_chunks_of_field_names[this.current_field_chunk][21]})

                        } else {

                            this.repeated_sample_supervisor_8d = false

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field})

                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': 'NaN', 'key': this.get_chunks_of_field_names[this.current_field_chunk][21]})
                        }
                        
                    } else {
    
                        if (index == 17 || index == 21) {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': value, 'key': field}) 
                        } else {
                            this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field}) 
                        } 

                    }

                } else {
                    this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field}) 
                }


            } else {
               this.$store.dispatch('store_assessment/UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE', {'value': parseInt(value), 'key': field}) 
            }

        },

    }
}
</script>

<style scoped>

</style>