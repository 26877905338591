<template>

    <div class="mx-3">

        <v-row v-if="!$vuetify.breakpoint.mdAndDown">

            <v-col cols="2" sm="2"></v-col>

            <v-col cols='5' sm='5'>

                <v-card
                    class="mx-auto"
                    outlined
                >

                    <v-card-text class="text-center headline primary--text font-weight-bold">{{ data.headerColumn1 }}</v-card-text>

                </v-card>

            </v-col>

            <v-col cols='5' sm='5'>

                <v-card
                    class="mx-auto"
                    outlined
                >

                    <v-card-text class="text-center headline primary--text font-weight-bold">{{ data.headerColumn2 }}</v-card-text>

                </v-card>

            </v-col>

        </v-row>

        <v-row>

            <v-col v-if="!$vuetify.breakpoint.mdAndDown" :cols="$vuetify.breakpoint.mdAndDown ? '12':'2'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'2'">

                <v-card
                    class="mx-auto"
                    outlined
                    min-height="120px"
                    height="100%"
                >

                    <v-row align="center" justify="center">

                            <v-col cols="12">

                                <v-card-text class="text-center headline primary--text font-weight-bold">{{ data.headerRow1 }}</v-card-text>

                            </v-col>

                    </v-row>

                </v-card>

            </v-col>


            <!-- FIELD 1 -->
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12':'5'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'5'">

                <v-card
                    class="mx-auto"
                    outlined
                    min-height="120px"
                    height="100%"
                >

                    <v-card-text v-if="$vuetify.breakpoint.mdAndDown" class="text-center headline primary--text font-weight-bold">{{ data.headerColumn1 }} | {{ data.headerRow1}}</v-card-text>

                    <v-divider v-if="$vuetify.breakpoint.mdAndDown" />

                    <v-card-text>

                        <v-row
                            v-for="(item, i) in data.field1List"
                            :key="i"
                            align="center" 
                            justify="center"
                        >

                            <v-card color="accent" class="my-3 mx-3">

                                <v-card-text class="body-1">
                                {{ item }}
                                <v-btn icon disabled>
                                    <v-icon color="black">far fa-times-circle</v-icon>
                                </v-btn>
                                </v-card-text>
                                                    
                            </v-card>

                        </v-row>

                    </v-card-text>
                    
                </v-card>

            </v-col>

            <!-- FIELD 2 -->
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12':'5'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'5'">

                <v-card
                    class="mx-auto"
                    outlined
                    min-height="120px"
                    height="100%"
                >

                    <v-card-text v-if="$vuetify.breakpoint.mdAndDown" class="text-center headline primary--text font-weight-bold">{{ data.headerColumn2 }} | {{ data.headerRow1}}</v-card-text>

                    <v-divider v-if="$vuetify.breakpoint.mdAndDown" />
                    
                    <v-card-text>

                        <v-row
                            v-for="(item, i) in data.field2List"
                            :key="i"
                            align="center" 
                            justify="center"
                        >

                            <v-card color="accent" class="my-3 mx-3">

                                <v-card-text class="body-1">
                                {{ item }}
                                <v-btn icon disabled>
                                    <v-icon color="black">far fa-times-circle</v-icon>
                                </v-btn>
                                </v-card-text>
                                                    
                            </v-card>

                        </v-row>

                    </v-card-text>

                </v-card>

            </v-col>

            <v-col v-if="!$vuetify.breakpoint.mdAndDown" :cols="$vuetify.breakpoint.mdAndDown ? '12':'2'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'2'">

                <v-card
                    class="mx-auto"
                    outlined
                    min-height="120px"
                    height="100%"
                >

                    <v-row align="center" justify="center">

                            <v-col cols="12">

                                <v-card-text class="text-center headline primary--text font-weight-bold">{{ data.headerRow2 }}</v-card-text>

                            </v-col>

                    </v-row>

                </v-card>

            </v-col>

            <!-- FIELD 3 -->
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12':'5'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'5'">

                <v-card
                    class="mx-auto"
                    outlined
                    min-height="120px"
                    height="100%"
                >

                    <v-card-text v-if="$vuetify.breakpoint.mdAndDown" class="text-center headline primary--text font-weight-bold">{{ data.headerColumn1 }} | {{ data.headerRow2}}</v-card-text>

                    <v-divider v-if="$vuetify.breakpoint.mdAndDown" />
                    
                    <v-card-text>

                        <v-row
                            v-for="(item, i) in data.field3List"
                            :key="i"
                            align="center" 
                            justify="center"
                        >

                            <v-card color="accent" class="my-3 mx-3">

                                <v-card-text class="body-1">
                                {{ item }}
                                <v-btn icon disabled>
                                    <v-icon color="black">far fa-times-circle</v-icon>
                                </v-btn>
                                </v-card-text>
                                                    
                            </v-card>

                        </v-row>

                    </v-card-text>

                </v-card>

            </v-col>

            <!-- FIELD 4 -->
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12':'5'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'5'">

                <v-card
                    class="mx-auto"
                    outlined
                    min-height="120px"
                    height="100%"
                >

                    <v-card-text v-if="$vuetify.breakpoint.mdAndDown" class="text-center headline primary--text font-weight-bold">{{ data.headerColumn2 }} | {{ data.headerRow2}}</v-card-text>

                    <v-divider v-if="$vuetify.breakpoint.mdAndDown" />
                    
                    <v-card-text>

                        <v-row
                            v-for="(item, i) in data.field4List"
                            :key="i"
                            align="center" 
                            justify="center"
                        >

                            <v-card color="accent" class="my-3 mx-3">

                                <v-card-text class="body-1">
                                {{ item }}
                                <v-btn icon disabled>
                                    <v-icon color="black">far fa-times-circle</v-icon>
                                </v-btn>
                                </v-card-text>
                                                    
                            </v-card>

                        </v-row>

                    </v-card-text>

                </v-card>

            </v-col>

        </v-row>

    </div>

</template>

<script>
export default {
    name: 'four-field-input-form-data',

    props: ['data'],

}   
</script>

<style scoped>

</style>
