<template>
  <div class="mx-3" v-if="ready">

    <v-row>
      <div class="headline primary--text">{{ question }}</div>
    </v-row>

    <v-row>
      <div class="overline mb-3"><b>Tipp:</b> Fügen Sie in der Tabelle Aussagen/Stichwörter hinzu. Die Farbe zeigt an, ob die Aussage/das Stichwort richtig zugeordnet ist.</div>
    </v-row>

    <v-row v-if="!$vuetify.breakpoint.mdAndDown">

        <v-col cols="2" sm="2"></v-col>

        <v-col cols='5' sm='5'>

            <v-card
                class="mx-auto"
                outlined
            >

                <v-card-text class="text-center headline primary--text font-weight-bold">{{ headerColumn1 }}</v-card-text>

            </v-card>

        </v-col>

        <v-col cols='5' sm='5'>

            <v-card
                class="mx-auto"
                outlined
            >

                <v-card-text class="text-center headline primary--text font-weight-bold">{{ headerColumn2 }}</v-card-text>

            </v-card>

        </v-col>

    </v-row>

    <v-row>

        <v-col v-if="!$vuetify.breakpoint.mdAndDown" :cols="$vuetify.breakpoint.mdAndDown ? '12':'2'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'2'">

            <v-card
                class="mx-auto"
                outlined
                min-height="120px"
                height="100%"
            >

                <v-row align="center" justify="center">

                        <v-col cols="12">

                            <v-card-text class="text-center headline primary--text font-weight-bold">{{ headerRow1 }}</v-card-text>

                        </v-col>

                </v-row>

            </v-card>

        </v-col>


        <!-- FIELD 1 -->
        <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12':'5'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'5'">

            <v-menu 
                min-width="300px"
                absolute
                z-index="1"
                v-if="$vuetify.breakpoint.mdAndDown"
                :disabled="thisBtnDisabled"
            >

                <template v-slot:activator="{ on, attrs }">

                    <v-card
                        class="mx-auto"
                        outlined
                        min-height="120px"
                        height="100%"
                        v-bind="attrs"
                        v-on="on"
                    >

                    <v-card-text v-if="$vuetify.breakpoint.mdAndDown" class="text-center headline primary--text font-weight-bold">{{ headerColumn1 }} | {{ headerRow1}}</v-card-text>

                    <v-divider v-if="$vuetify.breakpoint.mdAndDown" />

                    <v-card-text>

                        <v-row
                            align="center" 
                            justify="center"
                            v-if="field1List.length == 0"
                        >

                            <v-col cols="12">
                                <div class="body-1 text-center">
                                    <i>Klicken</i> Sie hier um eine Aussage/ein Stichwort zuzuordnen.
                                </div>
                            </v-col>

                        </v-row>

                        <v-row
                            v-for="(item, i) in field1List"
                            :key="i"
                            align="center" 
                            justify="center"
                        >

                            <v-card :color="getCorrectColor('field1', item)" class="mb-2">
                                <v-card-text class="pa-1 text-center body-1 white--text">
                                    {{ item.assignmentText }}
                                </v-card-text>
                            </v-card>

                        </v-row>

                    </v-card-text>

                </v-card>

                </template>

                <v-list subheader two-line>

                    <v-list-item
                        v-for="(item, index) in mainList.concat(field1List)"
                        :key="index"
                        @click="on_item_click(item, index, 'field1List')"
                    >

                        <v-list-item-avatar>
                            <v-icon :color="getCorrectColor('field1', item)" v-if="field1List.includes(item)">far fa-times-circle</v-icon>
                            <v-icon color="primary" v-if="mainList.includes(item)">far fa-plus-square</v-icon>
                        </v-list-item-avatar>
                        
                        <v-list-item-content>
                            <div class="font-weight-medium primary--text">{{ item.assignmentText }}</div>
                            <div v-if="field1List.includes(item)" class="body-2 font-weight-light"><i>Klicken</i> um aus diesem Feld zu entfernen.</div>
                            <div v-if="mainList.includes(item)" class="body-2 font-weight-light"><i>Klicken</i> um diesem Feld zuzuordnen.</div>
                        </v-list-item-content>

                    </v-list-item>

                </v-list>

            </v-menu>

            <v-menu 
                min-width="800px"
                absolute
                z-index="1"
                :disabled="thisBtnDisabled"
                v-else
            >

                <template v-slot:activator="{ on, attrs }">

                    <v-card
                        class="mx-auto"
                        outlined
                        min-height="120px"
                        height="100%"
                        v-bind="attrs"
                        v-on="on"
                    >

                    <v-card-text v-if="$vuetify.breakpoint.mdAndDown" class="text-center headline primary--text font-weight-bold">{{ headerColumn1 }} | {{ headerRow1}}</v-card-text>

                    <v-divider v-if="$vuetify.breakpoint.mdAndDown" />

                    <v-card-text>

                        <v-row
                            align="center" 
                            justify="center"
                            v-if="field1List.length == 0"
                        >

                            <v-col cols="12">
                                <div class="body-1 text-center">
                                    <i>Klicken</i> Sie hier um eine Aussage/ein Stichwort zuzuordnen.
                                </div>
                            </v-col>

                        </v-row>

                        <v-row
                            v-for="(item, i) in field1List"
                            :key="i"
                            align="center" 
                            justify="center"
                        >

                            <v-card :color="getCorrectColor('field1', item)" class="mb-2">
                                <v-card-text class="pa-1 text-center body-1 white--text">
                                    {{ item.assignmentText }}
                                </v-card-text>
                            </v-card>

                        </v-row>

                    </v-card-text>

                </v-card>

                </template>

                <v-list subheader two-line>

                    <v-list-item
                        v-for="(item, index) in mainList.concat(field1List)"
                        :key="index"
                        @click="on_item_click(item, index, 'field1List')"
                    >

                        <v-list-item-avatar>
                            <v-icon :color="getCorrectColor('field1', item)" v-if="field1List.includes(item)">far fa-times-circle</v-icon>
                            <v-icon color="primary" v-if="mainList.includes(item)">far fa-plus-square</v-icon>
                        </v-list-item-avatar>
                        
                        <v-list-item-content>
                            <div class="font-weight-medium primary--text">{{ item.assignmentText }}</div>
                            <div v-if="field1List.includes(item)" class="body-2 font-weight-light"><i>Klicken</i> um aus diesem Feld zu entfernen.</div>
                            <div v-if="mainList.includes(item)" class="body-2 font-weight-light"><i>Klicken</i> um diesem Feld zuzuordnen.</div>
                        </v-list-item-content>

                    </v-list-item>

                </v-list>

            </v-menu>

        </v-col>

        <!-- FIELD 2 -->
        <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12':'5'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'5'">

            <v-menu 
                min-width="300px"
                absolute
                z-index="1"
                v-if="$vuetify.breakpoint.mdAndDown"
                :disabled="thisBtnDisabled"
            >

                <template v-slot:activator="{ on, attrs }">

                    <v-card
                        class="mx-auto"
                        outlined
                        min-height="120px"
                        height="100%"
                        v-bind="attrs"
                        v-on="on"
                    >

                    <v-card-text v-if="$vuetify.breakpoint.mdAndDown" class="text-center headline primary--text font-weight-bold">{{ headerColumn2 }} | {{ headerRow1}}</v-card-text>

                    <v-divider v-if="$vuetify.breakpoint.mdAndDown" />

                    <v-card-text>

                        <v-row
                            align="center" 
                            justify="center"
                            v-if="field2List.length == 0"
                        >

                            <v-col cols="12">
                                <div class="body-1 text-center">
                                    <i>Klicken</i> Sie hier um eine Aussage/ein Stichwort zuzuordnen.
                                </div>
                            </v-col>

                        </v-row>

                        <v-row
                            v-for="(item, i) in field2List"
                            :key="i"
                            align="center" 
                            justify="center"
                        >

                            <v-card :color="getCorrectColor('field2', item)" class="mb-2">
                                <v-card-text class="pa-1 text-center body-1 white--text">
                                    {{ item.assignmentText }}
                                </v-card-text>
                            </v-card>

                        </v-row>

                    </v-card-text>

                </v-card>

                </template>

                <v-list subheader two-line>

                    <v-list-item
                        v-for="(item, index) in mainList.concat(field2List)"
                        :key="index"
                        @click="on_item_click(item, index, 'field2List')"
                    >

                        <v-list-item-avatar>
                            <v-icon :color="getCorrectColor('field2', item)" v-if="field2List.includes(item)">far fa-times-circle</v-icon>
                            <v-icon color="primary" v-if="mainList.includes(item)">far fa-plus-square</v-icon>
                        </v-list-item-avatar>
                        
                        <v-list-item-content>
                            <div class="font-weight-medium primary--text">{{ item.assignmentText }}</div>
                            <div v-if="field2List.includes(item)" class="body-2 font-weight-light"><i>Klicken</i> um aus diesem Feld zu entfernen.</div>
                            <div v-if="mainList.includes(item)" class="body-2 font-weight-light"><i>Klicken</i> um diesem Feld zuzuordnen.</div>
                        </v-list-item-content>

                    </v-list-item>

                </v-list>

            </v-menu>

            <v-menu 
                min-width="800px"
                absolute
                z-index="1"
                :disabled="thisBtnDisabled"
                v-else
            >

                <template v-slot:activator="{ on, attrs }">

                    <v-card
                        class="mx-auto"
                        outlined
                        min-height="120px"
                        height="100%"
                        v-bind="attrs"
                        v-on="on"
                    >

                    <v-card-text v-if="$vuetify.breakpoint.mdAndDown" class="text-center headline primary--text font-weight-bold">{{ headerColumn2 }} | {{ headerRow1}}</v-card-text>

                    <v-divider v-if="$vuetify.breakpoint.mdAndDown" />

                    <v-card-text>

                        <v-row
                            align="center" 
                            justify="center"
                            v-if="field2List.length == 0"
                        >

                            <v-col cols="12">
                                <div class="body-1 text-center">
                                    <i>Klicken</i> Sie hier um eine Aussage/ein Stichwort zuzuordnen.
                                </div>
                            </v-col>

                        </v-row>

                        <v-row
                            v-for="(item, i) in field2List"
                            :key="i"
                            align="center" 
                            justify="center"
                        >

                            <v-card :color="getCorrectColor('field2', item)" class="mb-2">
                                <v-card-text class="pa-1 text-center body-1 white--text">
                                    {{ item.assignmentText }}
                                </v-card-text>
                            </v-card>

                        </v-row>

                    </v-card-text>

                </v-card>

                </template>

                <v-list subheader two-line>

                    <v-list-item
                        v-for="(item, index) in mainList.concat(field2List)"
                        :key="index"
                        @click="on_item_click(item, index, 'field2List')"
                    >

                        <v-list-item-avatar>
                            <v-icon :color="getCorrectColor('field2', item)" v-if="field2List.includes(item)">far fa-times-circle</v-icon>
                            <v-icon color="primary" v-if="mainList.includes(item)">far fa-plus-square</v-icon>
                        </v-list-item-avatar>
                        
                        <v-list-item-content>
                            <div class="font-weight-medium primary--text">{{ item.assignmentText }}</div>
                            <div v-if="field2List.includes(item)" class="body-2 font-weight-light"><i>Klicken</i> um aus diesem Feld zu entfernen.</div>
                            <div v-if="mainList.includes(item)" class="body-2 font-weight-light"><i>Klicken</i> um diesem Feld zuzuordnen.</div>
                        </v-list-item-content>

                    </v-list-item>

                </v-list>

            </v-menu>

        </v-col>

        <v-col v-if="!$vuetify.breakpoint.mdAndDown" :cols="$vuetify.breakpoint.mdAndDown ? '12':'2'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'2'">

            <v-card
                class="mx-auto"
                outlined
                min-height="120px"
                height="100%"
            >

                <v-row align="center" justify="center">

                        <v-col cols="12">

                            <v-card-text class="text-center headline primary--text font-weight-bold">{{ headerRow2 }}</v-card-text>

                        </v-col>

                </v-row>

            </v-card>

        </v-col>

        <!-- FIELD 3 -->
        <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12':'5'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'5'">

            <v-menu 
                min-width="300px"
                absolute
                z-index="1"
                v-if="$vuetify.breakpoint.mdAndDown"
                :disabled="thisBtnDisabled"
            >

                <template v-slot:activator="{ on, attrs }">

                    <v-card
                        class="mx-auto"
                        outlined
                        min-height="120px"
                        height="100%"
                        v-bind="attrs"
                        v-on="on"
                    >

                    <v-card-text v-if="$vuetify.breakpoint.mdAndDown" class="text-center headline primary--text font-weight-bold">{{ headerColumn1 }} | {{ headerRow2}}</v-card-text>

                    <v-divider v-if="$vuetify.breakpoint.mdAndDown" />

                    <v-card-text>

                        <v-row
                            align="center" 
                            justify="center"
                            v-if="field3List.length == 0"
                        >

                            <v-col cols="12">
                                <div class="body-1 text-center">
                                    <i>Klicken</i> Sie hier um eine Aussage/ein Stichwort zuzuordnen.
                                </div>
                            </v-col>

                        </v-row>

                        <v-row
                            v-for="(item, i) in field3List"
                            :key="i"
                            align="center" 
                            justify="center"
                        >

                            <v-card :color="getCorrectColor('field3', item)" class="mb-2">
                                <v-card-text class="pa-1 text-center body-1 white--text">
                                    {{ item.assignmentText }}
                                </v-card-text>
                            </v-card>

                        </v-row>

                    </v-card-text>

                </v-card>

                </template>

                <v-list subheader two-line>

                    <v-list-item
                        v-for="(item, index) in mainList.concat(field3List)"
                        :key="index"
                        @click="on_item_click(item, index, 'field3List')"
                    >

                        <v-list-item-avatar>
                            <v-icon :color="getCorrectColor('field3', item)" v-if="field3List.includes(item)">far fa-times-circle</v-icon>
                            <v-icon color="primary" v-if="mainList.includes(item)">far fa-plus-square</v-icon>
                        </v-list-item-avatar>
                        
                        <v-list-item-content>
                            <div class="font-weight-medium primary--text">{{ item.assignmentText }}</div>
                            <div v-if="field3List.includes(item)" class="body-2 font-weight-light"><i>Klicken</i> um aus diesem Feld zu entfernen.</div>
                            <div v-if="mainList.includes(item)" class="body-2 font-weight-light"><i>Klicken</i> um diesem Feld zuzuordnen.</div>
                        </v-list-item-content>

                    </v-list-item>

                </v-list>

            </v-menu>

            <v-menu 
                min-width="800px"
                absolute
                z-index="1"
                :disabled="thisBtnDisabled"
                v-else
            >

                <template v-slot:activator="{ on, attrs }">

                    <v-card
                        class="mx-auto"
                        outlined
                        min-height="120px"
                        height="100%"
                        v-bind="attrs"
                        v-on="on"
                    >

                    <v-card-text v-if="$vuetify.breakpoint.mdAndDown" class="text-center headline primary--text font-weight-bold">{{ headerColumn1 }} | {{ headerRow2}}</v-card-text>

                    <v-divider v-if="$vuetify.breakpoint.mdAndDown" />

                    <v-card-text>

                        <v-row
                            align="center" 
                            justify="center"
                            v-if="field1List.length == 0"
                        >

                            <v-col cols="12">
                                <div class="body-1 text-center">
                                    <i>Klicken</i> Sie hier um eine Aussage/ein Stichwort zuzuordnen.
                                </div>
                            </v-col>

                        </v-row>

                        <v-row
                            v-for="(item, i) in field3List"
                            :key="i"
                            align="center" 
                            justify="center"
                        >

                            <v-card :color="getCorrectColor('field3', item)" class="mb-2">
                                <v-card-text class="pa-1 text-center body-1 white--text">
                                    {{ item.assignmentText }}
                                </v-card-text>
                            </v-card>

                        </v-row>

                    </v-card-text>

                </v-card>

                </template>

                <v-list subheader two-line>

                    <v-list-item
                        v-for="(item, index) in mainList.concat(field3List)"
                        :key="index"
                        @click="on_item_click(item, index, 'field3List')"
                    >

                        <v-list-item-avatar>
                            <v-icon :color="getCorrectColor('field3', item)" v-if="field3List.includes(item)">far fa-times-circle</v-icon>
                            <v-icon color="primary" v-if="mainList.includes(item)">far fa-plus-square</v-icon>
                        </v-list-item-avatar>
                        
                        <v-list-item-content>
                            <div class="font-weight-medium primary--text">{{ item.assignmentText }}</div>
                            <div v-if="field3List.includes(item)" class="body-2 font-weight-light"><i>Klicken</i> um aus diesem Feld zu entfernen.</div>
                            <div v-if="mainList.includes(item)" class="body-2 font-weight-light"><i>Klicken</i> um diesem Feld zuzuordnen.</div>
                        </v-list-item-content>

                    </v-list-item>

                </v-list>

            </v-menu>

        </v-col>

        <!-- FIELD 4 -->
        <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12':'5'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'5'">

            <v-menu 
                min-width="300px"
                absolute
                z-index="1"
                v-if="$vuetify.breakpoint.mdAndDown"
                :disabled="thisBtnDisabled"
            >

                <template v-slot:activator="{ on, attrs }">

                    <v-card
                        class="mx-auto"
                        outlined
                        min-height="120px"
                        height="100%"
                        v-bind="attrs"
                        v-on="on"
                    >

                    <v-card-text v-if="$vuetify.breakpoint.mdAndDown" class="text-center headline primary--text font-weight-bold">{{ headerColumn2 }} | {{ headerRow2}}</v-card-text>

                    <v-divider v-if="$vuetify.breakpoint.mdAndDown" />

                    <v-card-text>

                        <v-row
                            align="center" 
                            justify="center"
                            v-if="field4List.length == 0"
                        >

                            <v-col cols="12">
                                <div class="body-1 text-center">
                                    <i>Klicken</i> Sie hier um eine Aussage/ein Stichwort zuzuordnen.
                                </div>
                            </v-col>

                        </v-row>

                        <v-row
                            v-for="(item, i) in field4List"
                            :key="i"
                            align="center" 
                            justify="center"
                        >

                            <v-card :color="getCorrectColor('field4', item)" class="mb-2">
                                <v-card-text class="pa-1 text-center body-1 white--text">
                                    {{ item.assignmentText }}
                                </v-card-text>
                            </v-card>

                        </v-row>

                    </v-card-text>

                </v-card>

                </template>

                <v-list subheader two-line>

                    <v-list-item
                        v-for="(item, index) in mainList.concat(field4List)"
                        :key="index"
                        @click="on_item_click(item, index, 'field4List')"
                    >

                        <v-list-item-avatar>
                            <v-icon :color="getCorrectColor('field4', item)" v-if="field4List.includes(item)">far fa-times-circle</v-icon>
                            <v-icon color="primary" v-if="mainList.includes(item)">far fa-plus-square</v-icon>
                        </v-list-item-avatar>
                        
                        <v-list-item-content>
                            <div class="font-weight-medium primary--text">{{ item.assignmentText }}</div>
                            <div v-if="field4List.includes(item)" class="body-2 font-weight-light"><i>Klicken</i> um aus diesem Feld zu entfernen.</div>
                            <div v-if="mainList.includes(item)" class="body-2 font-weight-light"><i>Klicken</i> um diesem Feld zuzuordnen.</div>
                        </v-list-item-content>

                    </v-list-item>

                </v-list>

            </v-menu>

            <v-menu 
                min-width="800px"
                absolute
                z-index="1"
                :disabled="thisBtnDisabled"
                v-else
            >

                <template v-slot:activator="{ on, attrs }">

                    <v-card
                        class="mx-auto"
                        outlined
                        min-height="120px"
                        height="100%"
                        v-bind="attrs"
                        v-on="on"
                    >

                    <v-card-text v-if="$vuetify.breakpoint.mdAndDown" class="text-center headline primary--text font-weight-bold">{{ headerColumn2 }} | {{ headerRow2}}</v-card-text>

                    <v-divider v-if="$vuetify.breakpoint.mdAndDown" />

                    <v-card-text>

                        <v-row
                            align="center" 
                            justify="center"
                            v-if="field4List.length == 0"
                        >

                            <v-col cols="12">
                                <div class="body-1 text-center">
                                    <i>Klicken</i> Sie hier um eine Aussage/ein Stichwort zuzuordnen.
                                </div>
                            </v-col>

                        </v-row>

                        <v-row
                            v-for="(item, i) in field4List"
                            :key="i"
                            align="center" 
                            justify="center"
                        >

                            <v-card :color="getCorrectColor('field4', item)" class="mb-2">
                                <v-card-text class="pa-1 text-center body-1 white--text">
                                    {{ item.assignmentText }}
                                </v-card-text>
                            </v-card>

                        </v-row>

                    </v-card-text>

                </v-card>

                </template>

                <v-list subheader two-line>

                    <v-list-item
                        v-for="(item, index) in mainList.concat(field4List)"
                        :key="index"
                        @click="on_item_click(item, index, 'field4List')"
                    >

                        <v-list-item-avatar>
                            <v-icon :color="getCorrectColor('field4', item)" v-if="field4List.includes(item)">far fa-times-circle</v-icon>
                            <v-icon color="primary" v-if="mainList.includes(item)">far fa-plus-square</v-icon>
                        </v-list-item-avatar>
                        
                        <v-list-item-content>
                            <div class="font-weight-medium primary--text">{{ item.assignmentText }}</div>
                            <div v-if="field4List.includes(item)" class="body-2 font-weight-light"><i>Klicken</i> um aus diesem Feld zu entfernen.</div>
                            <div v-if="mainList.includes(item)" class="body-2 font-weight-light"><i>Klicken</i> um diesem Feld zuzuordnen.</div>
                        </v-list-item-content>

                    </v-list-item>

                </v-list>

            </v-menu>

        </v-col>

    </v-row>

    <v-row 
        align="center" 
        justify="center"
    >

        <v-alert
            :value="alert"
            :color="alert_color"
            dark
            :icon="alert_icon"
            border="top"
            width="100%"
            class="mt-3"
        >
            <div class="" v-html="alert_text"></div>
        </v-alert>

    </v-row>
     
  </div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'four-field-assignment-task',
    props: ['taskID', 'question', 'id', 'headerColumn1', 'headerColumn2', 'headerRow1', 'headerRow2', 'assignmentList'],

    data: () => ({
      
        mainList: [],
        field1List: [],
        field2List: [],
        field3List: [],
        field4List: [],

        ready: false,

        alert: false,
        alert_icon: 'far fa-check-circle',
        alert_color: 'success',
        alert_text: '<b>Sehr gut!</b> Sie haben alle Wörter richtig zugeordnet.',


    }),

    created () {

        this.ready = false

        if (_.isEmpty(this.$store.getters['store_lection/get_saved_lection_task_data_by_identifier'](this.taskID))) {

            // fill the main list based on prop assignmentList
            var i;

            for (i = 0; i < this.assignmentList.length; i++) {
                this.mainList.push(this.assignmentList[i])
            }

            //sort main list
            this.mainList.sort(this.dynamicSort('assignmentText'))


            // define task data store
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'mainList', 'data': this.mainList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'field1List', 'data': this.field1List})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'field2List', 'data': this.field2List})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'field3List', 'data': this.field3List})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'field4List', 'data': this.field4List})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'type', 'data': 'fourFieldAssignmentTask'})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'headerColumn1', 'data': this.headerColumn1})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'headerColumn2', 'data': this.headerColumn2})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'headerRow1', 'data': this.headerRow1})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'headerRow2', 'data': this.headerRow2})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert', 'data': this.alert})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert_icon', 'data': this.alert_icon})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert_color', 'data': this.alert_color})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert_text', 'data': this.alert_text})

            this.ready = true

        } else {

            // get data
            var data = this.$store.getters['store_lection/get_saved_lection_task_data_by_identifier'](this.taskID)
            this.mainList = data.mainList
            this.field1List = data.field1List
            this.field2List = data.field2List
            this.field3List = data.field3List
            this.field4List = data.field4List
            this.alert = data.alert
            this.alert_icon= data.alert_icon
            this.alert_color = data.alert_color
            this.alert_text = data.alert_text

            this.ready = true

        }
    },

    watch: {

      // watch the checkbox-state and update the store immediatly on change
      mainList: function(val) {
          
        if (val.length != 0 && this.ready) {

            this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false}) 

        }

        // update store variable
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'mainList', 'data': val})

      },

      field1List: function(val) {

        // update store variable
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'field1List', 'data': val})
      },

      field2List: function(val) {

        // update store variable
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'field2List', 'data': val})
      },

      field3List: function(val) {

        // update store variable
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'field3List', 'data': val})
      },

      field4List: function(val) {

        // update store variable
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'field4List', 'data': val})
      },

    },

    computed: {
        
        thisBtnDisabled () {

            var tmp = this.$store.getters['store_lection/get_saved_lection_data']
                
            if (tmp.data[this.id].finished == false) {
                return false
            } else {
                return true
            }
 
        },

    },

    methods: {

        on_item_click(item, index, field) {

            if (field == 'field1List') {

                if (this.field1List.includes(item)) {
                    this.field1List.splice(this.field1List.indexOf(item), 1)
                    this.mainList.push(item)
                } else {
                    this.field1List.push(item)
                    this.mainList.splice(index, 1) 
                }

            } else if (field == 'field2List') {

                if (this.field2List.includes(item)) {
                    this.field2List.splice(this.field2List.indexOf(item), 1)
                    this.mainList.push(item)
                } else {
                    this.field2List.push(item)
                    this.mainList.splice(index, 1) 
                }

            } else if (field == 'field3List') {

                if (this.field3List.includes(item)) {
                    this.field3List.splice(this.field3List.indexOf(item), 1)
                    this.mainList.push(item)
                } else {
                    this.field3List.push(item)
                    this.mainList.splice(index, 1) 
                }

            } else if (field == 'field4List') {

                if (this.field4List.includes(item)) {
                    this.field4List.splice(this.field4List.indexOf(item), 1)
                    this.mainList.push(item)
                } else {
                    this.field4List.push(item)
                    this.mainList.splice(index, 1) 
                }

            }


            if (this.mainList.length == 0) {

                var i
                var falseField1 = []
                var falseField2 = []
                var falseField3 = []
                var falseField4 = []

                for (i = 0; i < this.field1List.length; i++) {

                    if (this.field1List[i].assignmentType != 'field1') {

                        if (this.field1List[i].assignmentType != 'all') {

                            falseField1.push(this.field1List[i].assignmentText)

                        }
                    
                    }
                }

                for (i = 0; i < this.field2List.length; i++) {

                    if (this.field2List[i].assignmentType != 'field2') {

                        if (this.field2List[i].assignmentType != 'all') {

                            falseField2.push(this.field2List[i].assignmentText)

                        }
                    }
                }

                for (i = 0; i < this.field3List.length; i++) {

                    if (this.field3List[i].assignmentType != 'field3') {

                        if (this.field3List[i].assignmentType != 'all') {

                            falseField3.push(this.field3List[i].assignmentText)

                        }
                    }
                }

                for (i = 0; i < this.field4List.length; i++) {

                    if (this.field4List[i].assignmentType != 'field4') {

                        if (this.field4List[i].assignmentType != 'all') {

                            falseField4.push(this.field4List[i].assignmentText)

                        }
                    }
                }

                if (falseField1.length == 0 & falseField2.length == 0 & falseField3.length == 0 & falseField4.length == 0) {

                    this.alert_icon = 'far fa-check-circle'
                    this.alert_color = 'success'
                    this.alert_text = '<b>Sehr gut!</b> Sie haben alle Items richtig zugeordnet.'
                    this.alert = true

                    this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': true}) 

                } else {

                    if (falseField1.length != 0 || falseField2.length != 0 || falseField3.length != 0 || falseField4.length != 0) {

                        this.alert_icon = 'fas fa-exclamation-triangle'
                        this.alert_color = "error"
                        this.alert_text = '<b>Das ist leider falsch.</b> Falsch zugeordnete Items können Sie an der roten Farbe erkennen.'
                        this.alert = true 

                        this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false}) 
    
                    } else {

                        this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false})  

                    }

                }
            
            } else {

                this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false})  

            }

        },

        getCorrectColor(where, item) {

            if (where == 'field1' && item.assignmentType == 'field1') {

                return '#4CAF50'

            } else if (where == 'field2' && item.assignmentType == 'field2') {

                return '#4CAF50'

            } else if (where == 'field3' && item.assignmentType == 'field3') {

                return '#4CAF50'

            } else if (where == 'field4' && item.assignmentType == 'field4') {

                return '#4CAF50'

            } else if (item.assignmentType == 'all') {

                return '#4CAF50'
            
            } else {
                return '#FF5252'
            }

        },

        dynamicSort(property) {

            var sortOrder = 1;

            if(property[0] === "-") {

                sortOrder = -1;
                property = property.substr(1);

            }
            return function (a,b) {

                var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                return result * sortOrder;

            }
        },

    }

}
</script>

<style scoped>

</style>


