var moment = require('moment');

function getDateTime() {

    var date = new Date();

    var hour = date.getHours();
    hour = (hour < 10 ? "0" : "") + hour;

    var min  = date.getMinutes();
    min = (min < 10 ? "0" : "") + min;

    var sec  = Math.round(date.getSeconds());
    sec = (sec < 10 ? "0" : "") + sec;

    var year = date.getFullYear();

    var month = date.getMonth() + 1;
    month = (month < 10 ? "0" : "") + month;

    var day  = date.getDate();
    day = (day < 10 ? "0" : "") + day;

    return year + "-" + month + "-" + day + "T" + hour + ":" + min + ":" + sec + "Z";

}


function convertDatabaseDateTimeString(mode, time_str) {

    if (time_str == undefined) {
        return 'NaN'
    } else {
        var tmp1 = time_str.split("T");
        var date = tmp1[0];

        var date_splitted = date.split('-');
        var day = date_splitted[2]
        var month = date_splitted[1]
        var year = date_splitted[0]

        var tmp2 = tmp1[1].split("Z");
        var time = tmp2[0];
        var result = '';

        if (mode == 'full') {

            if (time.length > 8) {
                time = time.substring(0, 8)
            }

            result = day + '-' + month + '-' + year + " | " + time

            return result

        } else if (mode == 'only_date') {

            result = day + '-' + month + '-' + year

            return result
        }
    }

    


}

function getPassedDays(time_str) {

    if (time_str == undefined) {

        return 999

    } else {
        var days = moment().diff(time_str, "days");

        return days
    }

}


function sortThreadByMessageDate(thread_array) {


    var ret = thread_array.sort(function(a, b) {

        var aDate = new Date(a.latest_message.sent_at)
        var bDate = new Date(b.latest_message.sent_at)

        if (bDate > aDate) {
            return 1;   
        }
        else if (bDate < aDate) {
            return -1; // a comes before b
        } else {
            return 0;
        }

    })

    return ret



}

// export
export default { getDateTime, convertDatabaseDateTimeString, getPassedDays, sortThreadByMessageDate }