<template>

    <div>

        <v-row v-if="loaded">

            <v-card
                color="grey lighten-5"
                flat
                height="auto"
                min-height="250px"
                tile
                width="100%"
                id="fooAnchor"
            >

                <v-card flat color="grey lighten-5">

                    <v-card-text class="py-0">

                        <v-row>

                            <v-col cols="12" sm="12" md="6">

                                <div class="title black--text mt-3">
                                    <v-icon color="primary" class="mr-3">fas fa-archive</v-icon>
                                    Archivierte Chats
                                </div>

                            </v-col>

                            <v-col cols="12" sm="12" md="6">

                                <v-autocomplete
                                    v-model="search_archive"
                                    :items="all_clients"
                                    label="Chats nach Klient*in durchsuchen"
                                    prepend-icon="fas fa-search"
                                    chips
                                    deletable-chips
                                    hide-details
                                    small-chips
                                ></v-autocomplete>

                            </v-col>

                        </v-row>

                    </v-card-text>

                </v-card>

                <v-divider />

                <v-list 
                    color="grey lighten-5"
                    v-if="filteredArchivedChats.length > 0"
                >
                    <v-list-item-group>
                                                            

                        <v-list-item 
                            v-for="item in filteredArchivedChats"
                            :key="item.id"
                            three-line
                            @click="openChat(item)"
                            
                        >

                            <v-list-item-avatar>
                                <v-icon v-if="checkIfUnread(item)" color="primary">fas fa-envelope</v-icon>
                                <v-icon v-else>fas fa-envelope-open</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>
                                    <b>Betreff: </b>{{ item.subject }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <span class='primary--text font-weight-bold'>{{ convertedSender(item.latest_message.sender.username) }}</span> &mdash; {{ item.latest_message.content }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle class="caption">
                                    {{ getCorrectDateTime(item.latest_message.sent_at) }}
                                </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                                <v-chip color="accent" class="black--text">{{ getChatPartnerName(item) }}</v-chip>
                            </v-list-item-icon>

                        </v-list-item>


                    </v-list-item-group>

                </v-list>

                <v-alert
                    v-else
                    icon="fas fa-info-circle"
                    color="primary"
                    
                    class="mt-3 mx-3"
                    outlined
                > 
                    <span v-if="search_archive !=null">Es existieren keine archivierten Chats mit {{ get_gendered_client(search_archive) }} <i>{{search_archive}}</i>.</span>
                    <span v-else>Es existieren keine archivierten Chats.</span>
                </v-alert>

            </v-card>

        </v-row>

    </div>

</template>

<script>
import CoachApi from '@/_api/coach_api'
import DateTimeHelpers from '@/_helpers/date_time_helpers'

export default {
    name: 'coach-messages',

    data: () => ({

        loaded: false,
        items_archived: [],

        search_archive: null,

        all_clients: []

    }),

    created() {

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        CoachApi.GetCoachInfo().then(() => {

            CoachApi.GetCoachAllClientsData().then((resp) => {

                for (var i = 0; i < resp.data.length; i++) {

                    this.all_clients.push(resp.data[i].username)
                }

                CoachApi.GetUserThreadsDeletedAPI().then((resp) => {

                    this.items_archived = resp.data

                    if (this.$route.params.search_prop == 'null') {
                        this.search = null
                    } else {
                        this.search = this.$route.params.search_prop
                    }

                    this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                    this.loaded = true

                })

            })

        })


    },

    watch: {

        '$route' () {

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

            CoachApi.GetCoachInfo().then(() => {

                CoachApi.GetCoachAllClientsData().then((resp) => {

                    for (var i = 0; i < resp.data.length; i++) {

                        this.all_clients.push(resp.data[i].username)
                    }

                    CoachApi.GetUserThreadsDeletedAPI().then((resp) => {

                        this.items_archived = resp.data

                        if (this.$route.params.search_prop == 'null') {
                            this.search = null
                        } else {
                            this.search = this.$route.params.search_prop
                        }

                        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                        this.loaded = true

                    })

                })

            })

        },

        'search_archive' () {

            if (this.search_archive == '' || this.search_archive == ' ' || this.search_archive == '  ') {
                this.search_archive = null
            }
        }

    },

    computed: {

        usergroup() {
            return this.$store.getters['store_auth/get_user_group']
        },

        filteredArchivedChats() {

            var filtered = []

            if (this.search_archive != null) {

                for (var i=0; i < this.items_archived.length; i++) {
                    

                    this.items_archived[i].users.forEach(element => {

                        if (element.username === this.search_archive) {

                            filtered.push(this.items_archived[i]);

                        }

                    })

                }

            } else {

                filtered = this.items_archived

            }

            return filtered
            
        },

    },

    methods: {

        get_gendered_client(username) {

            var result = this.$store.getters['store_coach/get_coach_clients'].find(obj => {
                return obj.username === username
            })

            if (result.client_gender == 1) {
                return 'Ihrem Klienten'
            } else {
                return 'Ihrer Klientin'
            }
        },

        getAttachmentName(att) {

            var tmp = att.split('/')

            return tmp[tmp.length - 1]

        },

        getChatPartnerName(item) {

            var partner = ''
            
            item.users.forEach(element => {

                if (element.username != this.$store.getters['store_auth/get_user_username']) {

                    partner = element.username

                } 
                
            });

            return partner

        },

        convertedSender(username) {

            if (username == this.$store.getters['store_auth/get_user_username']) {

                return 'Ich'

            } else {

                return username

            }


        },

        openChat(item) {

            var data = {
                'current_thread_id': item.id,
            }

            CoachApi.SetThreadAsRead(data).then(() => {

                this.$router.push({ name: 'coach-messages-detailed', params: { thread_id: item.id }})

            })

        },

        checkIfUnread(item) {

            var val = false

            item.user_threads.forEach(element => {

                if (element.user.username == this.$store.getters['store_auth/get_user_username']) {

                    if (element.unread) {

                        val = true

                    }
                }
                
            });

            return val

        },


        is_archived(item) {

            var tmp_archived = false

            for (var i = 0; i < item.user_threads.length; i++) {

                if (item.user_threads[i].deleted == true && item.user_threads[i].user.username == this.$store.getters['store_auth/get_user_username']) {

                    tmp_archived = true

                }
            }

            return tmp_archived

        },

        getCorrectDateTime(item) {
            return DateTimeHelpers.convertDatabaseDateTimeString('full', item)
        },
		
		onAttachmentPicked (i) {  

            this.loading_attachment = false

            if (i == 1) {
                this.attachmentFile1 = this.file;
                this.attachmentName1 = this.file.name
            } else if (i == 2) {
                this.attachmentFile2 = this.$refs.attachment2.files[0];
                this.attachmentName2 = this.$refs.attachment2.files[0].name
            } else if (i == 3) {
                this.attachmentFile3 = this.$refs.attachment3.files[0];
                this.attachmentName3 = this.$refs.attachment3.files[0].name
            }
		},

    },

}
</script>

<style scoped>

</style>