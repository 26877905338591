<template>
    <div>
        <!-- explanation -->
        <v-row>
            <v-col sm="12">
                <v-card color="transparent" raised>
                    <v-card-text>

                        <div class="title  mb-2"><span><v-icon large class="mr-3">far fa-compass</v-icon></span>Ziel</div>

                        <div 
                            class="subtitle-1  mb-0" 
                            v-html="data.goal"
                        ></div>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- instruction -->
        <v-row>
            <v-col sm="12">
                <v-card color="transparent" raised>
                    <v-card-text>
                        
                            
                            <div class="title  mb-2"><span><v-icon large class="mr-3">far fa-clipboard</v-icon></span>Anleitung</div>

                            <div 
                                class="subtitle-1  mb-0" 
                                v-html="data.instruction"
                            ></div>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- task -->
        <v-row>

            <v-col sm="12">

                <v-card color="transparent" raised>
                    <v-card-text>
                            
                        <div class="title  mb-2"><span><v-icon large class="mr-3">far fa-keyboard</v-icon></span>Übung</div>

                        <!-- TASKS -->

                        
                        <div v-if="data.task[0].type == 'MultipleChoicePickNDependentLectionBlock'">
                            <multipleChoicePickNDependentLection :id = this.id :taskID = "data.taskID" :taskChoices = "data.task[0].value.taskChoices" :question = "data.task[0].value.shortQuestion" :numberChoices = "data.task[0].value.numberChoices" />
                        </div>

                        <div v-if="data.task[0].type == 'multipleChoicePickNFormPlus'">
                            <multipleChoicePickNFormPlus :id = this.id :taskID = "data.taskID" :taskChoices = "data.task[0].value.taskChoices" :question = "data.task[0].value.shortQuestion" />
                        </div>

                        <div v-if="data.task[0].type == 'multipleChoicePickNForm'">
                            <multipleChoicePickNForm :id = this.id :taskID = "data.taskID" :taskChoices = "data.task[0].value.taskChoices" :question = "data.task[0].value.shortQuestion" />
                        </div>

                        <div v-if="data.task[0].type == 'multipleChoicePickOneFormPlus'">
                            <multipleChoicePickOneFormPlus :id = this.id :taskID = "data.taskID" :taskChoices = "data.task[0].value.taskChoices" :question = "data.task[0].value.shortQuestion"/>
                        </div>

                        <div v-if="data.task[0].type == 'multipleChoicePickOneForm'">
                            <multipleChoicePickOneForm :id = this.id :taskID = "data.taskID" :taskChoices = "data.task[0].value.taskChoices" :question = "data.task[0].value.shortQuestion"/>
                        </div>

                        <div v-if="data.task[0].type == 'freeTextForm'">
                            <free-text-form :id = this.id :taskID = "data.taskID" :question = "data.task[0].value.shortQuestion"/>
                        </div>

                        <div v-if="data.task[0].type == 'rightWrongForm'">
                            <right-wrong-form :id = this.id :taskID = "data.taskID" :question = "data.task[0].value.question" :correctAnswer = "data.task[0].value.correctAnswer"/>
                        </div>

                        <div v-if="data.task[0].type == 'proContraInputTask'">
                            <proContraInputTask :id = this.id :taskID = "data.taskID" :minItemsPro = "data.task[0].value.minItemsPro" :minItemsContra = "data.task[0].value.minItemsContra" :headerPro = "data.task[0].value.headerPro" :headerContra = "data.task[0].value.headerContra" :question = "data.task[0].value.shortQuestion"/>
                        </div>

                        <div v-if="data.task[0].type == 'traditionalLikertScaleForm'">
                            <traditional-likert-scale-form :id = this.id :taskID = "data.taskID"  :maxLabel = "data.task[0].value.maxLabel"  :minLabel = "data.task[0].value.minLabel" :iconSet = "data.task[0].value.iconSet" :question = "data.task[0].value.shortQuestion" :assignmentList = "data.task[0].value.assignmentList"/>
                        </div>

                        <div v-if="data.task[0].type == 'collectKeywordsForm'">
                            <collect-keywords-form :id = this.id :taskID = "data.taskID" :question = "data.task[0].value.shortQuestion" :minKeyWords = 'data.task[0].value.minKeywords'/>
                        </div>

                        <div v-if="data.task[0].type == 'defaultHierarchyAssignmentForm'">
                            <default-hierarchy-assignment-form :id = this.id :taskID = "data.taskID" :question = "data.task[0].value.shortQuestion" :assignmentList = "data.task[0].value.assignmentList" />
                        </div>

                        <div v-if="data.task[0].type == 'fourFieldInputForm'">
                            <four-field-input-form :id = this.id :taskID = "data.taskID" :minItemsField1 = "data.task[0].value.minItemsField1" :minItemsField2 = "data.task[0].value.minItemsField2" :minItemsField3 = "data.task[0].value.minItemsField3" :minItemsField4 = "data.task[0].value.minItemsField4" :headerColumn1 = "data.task[0].value.headerColumn1" :headerColumn2 = "data.task[0].value.headerColumn2" :headerRow1 = "data.task[0].value.headerRow1" :headerRow2 = "data.task[0].value.headerRow2" :question = "data.task[0].value.shortQuestion"/>
                        </div>

                        <div v-if="data.task[0].type == 'dataHierarchyAssignmentForm'">
                            <data-hierarchy-assignment-form :id = this.id :taskID = "data.taskID" :question = "data.task[0].value.shortQuestion" :module_nr = "data.task[0].value.module_nr" :session_nr = "data.task[0].value.session_nr" :lesson_nr = "data.task[0].value.lesson_nr" :dataIdentifier = "data.task[0].value.dataIdentifier" />
                        </div>

                        <!-- TASKS -->
                        <div v-if="data.task[0].type == 'proContraAssignment'">
                            <pro-contra-assignment-task :id = this.id :taskID = "data.taskID" :question = "data.task[0].value.shortQuestion" :headerPro = "data.task[0].value.headerPro" :headerContra = "data.task[0].value.headerContra" :assignmentList = "data.task[0].value.assignmentList"/>
                        </div>

                        <div v-if="data.task[0].type == 'multipleChoicePickNTask'">
                            <multiple-choice-pick-n-task :id = this.id :taskID = "data.taskID" :question = "data.task[0].value.shortQuestion" :taskChoices = "data.task[0].value.taskChoices" />
                        </div>

                        <div v-if="data.task[0].type == 'fillInTheGapAssignment'">
                            <fill-in-the-gap-assignment-task :id = this.id :taskID = "data.taskID" :question = "data.task[0].value.shortQuestion" :sentence = "data.task[0].value.sentence" />
                        </div>

                        <div v-if="data.task[0].type == 'simpleAssignment'">
                            <simple-assignment-task :id = this.id :taskID = "data.taskID" :question = "data.task[0].value.shortQuestion" :assignmentList = "data.task[0].value.assignmentList" />
                        </div>

                        <div v-if="data.task[0].type == 'fourFieldAssignment'">
                            <four-field-assignment-task :id = this.id :taskID = "data.taskID"  :headerColumn1 = "data.task[0].value.headerColumn1" :headerColumn2 = "data.task[0].value.headerColumn2" :headerRow1 = "data.task[0].value.headerRow1" :headerRow2 = "data.task[0].value.headerRow2" :question = "data.task[0].value.shortQuestion" :assignmentList="data.task[0].value.assignmentList"/>
                        </div>

                    </v-card-text>
                </v-card>

            </v-col>

        </v-row>

         <!-- check -->
        <v-row v-if="!$vuetify.breakpoint.mdAndDown">
            <v-col sm="12" cols="12">
                <v-card color="transparent" raised height="100%">
                    <v-card-text>
                        <v-row  align="center" class="ma-2">
                            <div v-if="hint == '<b>Hinweis:</b> Sie müssen die Übung erst bearbeiten.'">
                                <v-icon medium>fas fa-pencil-alt</v-icon>
                            </div>
                            <div v-else>
                                <v-icon medium>fas fa-check</v-icon>
                            </div>

                            <v-divider class="mx-4"/>

                            <div class="overline error--text" v-html="hint" v-if="hint == '<b>Hinweis:</b> Sie müssen die Übung erst bearbeiten.'"></div>
                            <div class="overline success--text" v-html="hint" v-else></div>

                            <v-divider class="mx-4"/>

                            <v-btn 
                                rounded
                                outlined 
                                large 
                                color="primary" 
                                @click="save"
                                :disabled="thisBtnDisabled"
                            >Fertig</v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row v-else>
            <v-col sm="12" cols="12">
                <v-card color="transparent" raised height="100%">
                    <v-card-text>

                        <div class="overline error--text" v-html="hint" v-if="hint == '<b>Hinweis:</b> Sie müssen die Übung erst bearbeiten.'"></div>
                        <div class="overline success--text" v-html="hint" v-else></div> 

                        <v-row  align="center" class="ma-2">
                            <div v-if="hint == '<b>Hinweis:</b> Sie müssen die Übung erst bearbeiten.'">
                                <v-icon medium>fas fa-pencil-alt</v-icon>
                            </div>
                            <div v-else>
                                <v-icon medium>fas fa-check</v-icon>
                            </div>

                            <v-divider class="mx-4"/>

                            <v-btn 
                                rounded
                                outlined 
                                large 
                                color="primary" 
                                @click="save"
                                :disabled="thisBtnDisabled"
                            >Fertig</v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- DEV DIALOG -->
        <v-dialog v-model="dialog" persistent max-width="320">

            <v-card>
                <v-card-title class="headline">Bitte beachten Sie:</v-card-title>
                <v-card-text>
                    Da dieser Klient als Development-Klient definiert ist, haben sie die Wahl, 
                    ob sie die Daten speichern möchten oder nicht. Solange Sie an dieser Lektion 
                    arbeiten, wird empfohlen, die Daten <b>nicht</b> zu speichern.
                </v-card-text>
                <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="primary" text @click="doNotSave()">Nicht speichern</v-btn>
                <v-btn color="primary" text @click="saveDev()">Speichern</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

    </div>
</template>

<script>
import HelperClientIntervention from '@/_helpers/helper_client_intervention'

// forms
import multipleChoicePickNDependentLection from '@/components/_client/_intervention/_basicFormBlocks/multipleChoicePickN_dependentLection.vue'
import multipleChoicePickNFormPlus from '@/components/_client/_intervention/_basicFormBlocks/multipleChoicePickNFormPlus.vue'
import multipleChoicePickNForm from '@/components/_client/_intervention/_basicFormBlocks/multipleChoicePickNForm.vue'
import multipleChoicePickOneFormPlus from '@/components/_client/_intervention/_basicFormBlocks/multipleChoicePickOneFormPlus.vue'
import multipleChoicePickOneForm from '@/components/_client/_intervention/_basicFormBlocks/multipleChoicePickOneForm.vue'
import freeTextForm from '@/components/_client/_intervention/_basicFormBlocks/freeTextForm.vue'
import rightWrongForm from '@/components/_client/_intervention/_basicFormBlocks/rightWrongForm.vue'
import traditionalLikertScaleForm from '@/components/_client/_intervention/_basicFormBlocks/traditionalLikertScaleForm.vue'
import proContraInputTask from '@/components/_client/_intervention/_basicFormBlocks/proContraInputTask.vue'
import collectKeywordsForm from '@/components/_client/_intervention/_basicFormBlocks/collectKeywordsForm.vue'
import defaultHierarchyAssignmentForm from '@/components/_client/_intervention/_basicFormBlocks/defaultHierarchyAssignmentForm.vue'
import fourFieldInputForm from '@/components/_client/_intervention/_basicFormBlocks/fourFieldInputForm.vue'
import dataHierarchyAssignmentForm from '@/components/_client/_intervention/_basicFormBlocks/dataHierarchyAssignmentForm.vue'

// tasks
//import rightWrongTask from '@/components/_client/_intervention/_basicTaskBlocks/rightWrongTask.vue'
import proContraAssignmentTask from '@/components/_client/_intervention/_basicTaskBlocks/proContraAssignmentTask.vue'
import multipleChoicePickNTask from '@/components/_client/_intervention/_basicTaskBlocks/multipleChoicePickNTask.vue'
//import multipleChoicePickOneTask from '@/components/_client/_intervention/_basicTaskBlocks/multipleChoicePickOneTask.vue'
import fillInTheGapAssignmentTask from '@/components/_client/_intervention/_basicTaskBlocks/fillInTheGapAssignmentTask.vue'
import simpleAssignmentTask from '@/components/_client/_intervention/_basicTaskBlocks/simpleAssignmentTask.vue'
import fourFieldAssignmentTask from '@/components/_client/_intervention/_basicTaskBlocks/fourFieldAssignmentTask.vue'



export default {
    name: 'basicTask',

    props: ['data', 'id'],

    components: {
        multipleChoicePickNDependentLection,
        multipleChoicePickNFormPlus,
        multipleChoicePickNForm,
        multipleChoicePickOneFormPlus,
        multipleChoicePickOneForm,
        freeTextForm,
        rightWrongForm,
        traditionalLikertScaleForm,
        proContraInputTask,
        collectKeywordsForm,
        defaultHierarchyAssignmentForm,
        fourFieldInputForm,
        dataHierarchyAssignmentForm,

        //rightWrongTask,
        proContraAssignmentTask,
        //multipleChoicePickOneTask,
        multipleChoicePickNTask,
        fillInTheGapAssignmentTask,
        simpleAssignmentTask,
        fourFieldAssignmentTask
      
    },

    data () {

        return {

            dialog: false,

            dev_save: false,

            dev_checked: false,

            //thisBtnDisabled: false,

            //hint: '<b>Hinweis:</b> Sie müssen die Übung erst bearbeiten.'
            
        }
    },

    created() {


    },

    watch: {

    },

    computed: {

        //// TEST /////
        finished_in_saved_data () {

            return this.$store.getters['store_lection/get_saved_lection_data'].data[this.id].finished

        },

        finished_in_current_data () {

            return this.$store.getters['store_task/get_finished_by_identifier'](this.data.taskID)

        },

        thisBtnDisabled () {

            if (this.finished_in_saved_data) {

                return true


            } else {

                if ( this.finished_in_current_data ) {

                    return false

                } else {

                    return true
                }

            }
        },

        hint() {

            if (this.finished_in_saved_data || this.finished_in_current_data ) {

                return '<b>Hinweis:</b> Sie haben die Übung bearbeitet.'

            } else {

                return '<b>Hinweis:</b> Sie müssen die Übung erst bearbeiten.'
            }

        }


    },    

    methods: {

        getClass(i) {

            // if last task
            if (i == this.data.task.length) {
                return 'mx-3'
            } else {
                return 'mx-3 mb-3'
            }

        },

        // DEV ---
        doNotSave() {

            this.dev_save = false
            this.dev_checked = true
            this.dialog = false

            this.save()

        },

        saveDev() {

            this.dev_save = true
            this.dev_checked = true
            this.dialog = false

            this.save()

        },

        // DEV END ---

        // save data
       save() {

           var type = 'task'

            if (this.data.guided == 'yes') {

                type = 'guided_task'

            }

           if (this.$store.getters['store_client/get_client'].is_dev && this.dev_checked == false) {

               this.dialog = true

           } else if (this.$store.getters['store_client/get_client'].is_dev && this.dev_checked == true) {

               if (this.dev_save == true) {

                   const data = {
                        'save_lection_data': true,
                        'module': this.$route.params.module,
                        'session': this.$route.params.session,
                        'lection': this.$route.params.lection,
                        'id': this.id,
                        'wagtail_data': this.data,
                        'type': type,
                        'reward': parseInt(this.data.reward) 
                    }

                    HelperClientIntervention.InterventionNext(data).then(() => {

                    })


               } else {

                   const data = {
                        'save_lection_data': false,
                        'module': this.$route.params.module,
                        'session': this.$route.params.session,
                        'lection': this.$route.params.lection,
                        'id': this.id,
                        'type': type,
                        'reward': parseInt(this.data.reward) 
                    }

                    HelperClientIntervention.InterventionNext(data).then(() => {

                        console.log('NEXT')

                    })

               }

           } else {

               const data = {
                    'save_lection_data': true,
                    'module': this.$route.params.module,
                    'session': this.$route.params.session,
                    'lection': this.$route.params.lection,
                    'id': this.id,
                    'wagtail_data': this.data,
                    'type': type,
                    'reward': parseInt(this.data.reward) 
                }

               HelperClientIntervention.InterventionNext(data).then(() => {

               })

           }

       }

    },
}
</script>

