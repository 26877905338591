<template>
    <div class="mx-3" v-if="ready">

        <v-row>
            <div class="headline mt-1 primary--text">{{ question }}</div>
        </v-row>

        <v-row>
            <div class="overline mb-3"><b>Tipp:</b> Sie müssen alle Wörter richtig zuordnen.</div>
        </v-row>

        <v-row align="center" justify="center">

            <v-card
                class="d-flex flex-wrap align-center"
                flat
                outlined
            >
                <v-card
                    v-for="(item, i) in wordList" 
                    :key="item.name"
                    class="py-2 px-1"
                    flat
                    tile
                >
                    <div class="body-1" v-if="!dropIndex.includes(i)">{{ item }}</div>

                    <v-menu offset-y z-index="1" min-width="300" v-if="dropIndex.includes(i)">

                        <template v-slot:activator="{ on, attrs }">
                            <v-card
                                v-bind="attrs"
                                v-on="on"
                                v-if="dropLists[i].length == 0"
                                :disabled="thisBtnDisabled"
                            >

                                <v-alert outlined color="primary" class="pa-0 ma-0">
                                    <v-card-text class="text-center body-1 pa-2">Wort einfügen</v-card-text>
                                </v-alert>
                            </v-card>

                            <v-card
                                v-bind="attrs"
                                v-on="on"
                                v-else
                                :disabled="thisBtnDisabled"
                            >
                                <v-alert outlined :color="getCorrectColor(i, dropLists[i])" class="pa-0 ma-0">
                                    <v-card-text class="text-center body-1 pa-2">{{ dropLists[i][0] }}</v-card-text>
                                </v-alert>
                            </v-card>
                        </template>

                        <v-list subheader two-line v-if="dropLists[i].length == 0">

                            <v-list-item
                                v-for="(item_main, index) in mainList"
                                :key="index"
                                @click="add_item(item_main, index, dropLists[i])"
                            >

                                <v-list-item-avatar>
                                    <v-icon color="primary">far fa-plus-square</v-icon>
                                </v-list-item-avatar>
                                
                                <v-list-item-content>
                                    <div class="font-weight-medium primary--text">{{ item_main }}</div>
                                    <div class="body-2 font-weight-light"><i>Klicken</i> um diesem Feld zuzuordnen.</div>
                                </v-list-item-content>

                            </v-list-item>

                        </v-list>

                        <v-list subheader two-line v-else>

                            <v-list-item
                                v-for="(item_drop, index) in dropLists[i]"
                                :key="index"
                                @click="delete_item(item_drop, index, dropLists[i])"
                            >

                                <v-list-item-avatar>
                                    <v-icon :color="getCorrectColor(i, item_drop)">far fa-times-circle</v-icon>
                                </v-list-item-avatar>
                                
                                <v-list-item-content>
                                    <div class="font-weight-medium primary--text">{{ item_drop }}</div>
                                    <div class="body-2 font-weight-light"><i>Klicken</i> um aus diesem Feld zu entfernen.</div>
                                </v-list-item-content>

                            </v-list-item>

                        </v-list>

                    </v-menu>
                </v-card>
            </v-card>

        </v-row>

        <v-row 
            align="center" 
            justify="center"
        >

            <v-alert
                :value="alert"
                :color="alert_color"
                dark
                :icon="alert_icon"
                border="top"
                width="100%"
                class="mt-3"
            >
                <div class="" v-html="alert_text"></div>
            </v-alert>

        </v-row>

    </div>
    
</template>

<script>
import _ from 'lodash'

export default {

    name: 'fill-in-the-gap-task',

    props: ['taskID', 'id', 'sentence', 'question'],

    data: () => ({
      
        mainList: [],
        wordList: [],
        dropIndex: [],
        dropLists: [],
        answerList: [],
        correctList: {},

        ready: false,

        alert: false,
        alert_icon: 'far fa-check-circle',
        alert_color: 'success',
        alert_text: '<b>Sehr gut!</b> Sie haben alle Wörter richtig zugeordnet.',

    }),

    created () {

        // create split sentence and define boxes

        this.ready = false

        if (_.isEmpty(this.$store.getters['store_lection/get_saved_lection_task_data_by_identifier'](this.taskID))) {

            var wordList = this.sentence.split(' ')

            var m = 0

            for (m; m < wordList.length; m++) {

                if (wordList[m].includes('*')) {

                    this.dropIndex.push(m)

                    var tmp_n = wordList[m].split('*')
                    var tmp = tmp_n[1].split('__')

                    this.wordList.push(tmp[0])
                    this.answerList.push(tmp[0])
                    this.mainList.push(tmp[0])
                    this.dropLists.push([])

                    _.set(this.correctList, tmp[0], {})
                    this.correctList[tmp[0]].correct_index = m

                } else {

                    var tmp_words = wordList[m].split(' ')

                    for (var k = 0; k < tmp_words.length; k++) {

                            this.wordList.push(tmp_words[k])

                    }

                    this.dropLists.push([])
                }
            }

            // sort mainList in order to change the start list
            this.mainList.sort()

            // define task data store

            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'mainList', 'data': this.mainList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'wordList', 'data': this.wordList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'dropLists', 'data': this.dropLists})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'answerList', 'data': this.answerList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'dropIndex', 'data': this.dropIndex})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'correctList', 'data': this.correctList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert', 'data': this.alert})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert_icon', 'data': this.alert_icon})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert_color', 'data': this.alert_color})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert_text', 'data': this.alert_text})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'type', 'data': 'fillInTheGapAssignmentTask'})

            this.ready = true

        } else {

            var data = this.$store.getters['store_lection/get_saved_lection_task_data_by_identifier'](this.taskID)

            this.mainList = data.mainList
            this.dropLists = data.dropLists
            this.wordList = data.wordList
            this.dropIndex = data.dropIndex
            this.answerList = data.answerList
            this.correctList = data.correctList
            this.alert = data.alert
            this.alert_icon= data.alert_icon
            this.alert_color = data.alert_color
            this.alert_text = data.alert_text

            this.ready = true

        }
            
    },

    watch: {


      mainList: function(val) {

        // update store variable
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'mainList', 'data': val})

      },

      dropLists: function(val) {

        // update store variable
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'dropLists', 'data': val})

      },

      dropIndex: function(val) {

        // update store variable
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'dropIndex', 'data': val})

      },

    },

    computed: {

        thisBtnDisabled () {

            var tmp = this.$store.getters['store_lection/get_saved_lection_data']
                
            if (tmp.data[this.id].finished == false) {
                return false
            } else {
                return true
            }
 
        },

    },

    methods: {

        add_item(item, index, which_list) {

            which_list.push(item)
            this.mainList.splice(this.mainList.indexOf(item), 1)

            if (this.mainList.length == 0) {

                var correctList = []

                // get items of relevance in dropLists
                var x

                for (x = 0; x < this.dropIndex.length; x++) {

                    if (this.dropLists[this.dropIndex[x]] == this.answerList[x]) {

                        correctList.push(true)

                    } else {

                        correctList.push(false)
                    }
                }

                if (correctList.includes(false)) {

                    this.alert_icon = 'fas fa-exclamation-triangle'
                    this.alert_color = "error"
                    this.alert_text = '<b>Das ist leider falsch.</b> Falsch zugeordnete Wörter können Sie an der roten Farbe erkennen.'
                    this.alert = true

                    this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false})

                } else {

                    this.alert_icon = 'far fa-check-circle'
                    this.alert_color = 'success'
                    this.alert_text = '<b>Sehr gut!</b> Sie haben alle Wörter richtig zugeordnet.'
                    this.alert = true

                    this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': true})  

                }
                
            } else {

                this.alert = false

            }

            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'mainList', 'data': this.mainList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'wordList', 'data': this.wordList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'dropLists', 'data': this.dropLists})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'answerList', 'data': this.answerList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'dropIndex', 'data': this.dropIndex})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'correctList', 'data': this.correctList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert', 'data': this.alert})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert_icon', 'data': this.alert_icon})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert_color', 'data': this.alert_color})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert_text', 'data': this.alert_text})

        },

        delete_item(item, index, which_list) {

            which_list.splice(which_list.indexOf(item), 1)
            this.mainList.push(item)

            if (this.mainList.length == 0) {

                var correctList = []

                // get items of relevance in dropLists
                var x

                for (x = 0; x < this.dropIndex.length; x++) {

                    if (this.dropLists[this.dropIndex[x]] == this.answerList[x]) {

                        correctList.push(true)

                    } else {

                        correctList.push(false)
                    }
                }

                if (correctList.includes(false)) {

                    this.alert_icon = 'fas fa-exclamation-triangle'
                    this.alert_color = "error"
                    this.alert_text = '<b>Das ist leider falsch.</b> Falsch zugeordnete Wörter können Sie an der roten Farbe erkennen.'
                    this.alert = true

                    this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false})

                } else {

                    this.alert_icon = 'far fa-check-circle'
                    this.alert_color = 'success'
                    this.alert_text = '<b>Sehr gut!</b> Sie haben alle Wörter richtig zugeordnet.'
                    this.alert = true

                    this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': true})  

                }
                
            } else {

                this.alert = false

            }

            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'mainList', 'data': this.mainList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'wordList', 'data': this.wordList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'dropLists', 'data': this.dropLists})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'answerList', 'data': this.answerList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'dropIndex', 'data': this.dropIndex})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'correctList', 'data': this.correctList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert', 'data': this.alert})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert_icon', 'data': this.alert_icon})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert_color', 'data': this.alert_color})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert_text', 'data': this.alert_text})

        },


        // ToDo: Issue #58
        splitString(string) {

            var result = string.split(" ")

            return result

        },

        getCorrectColor(i, item) {

            if (this.correctList[item].correct_index == i) {

                return 'success'

            } else {

                return 'error'

            }

        },

  }

}
</script>

<style scoped>

</style>


