<template>

    <v-container fluid class="py-0" v-if="post">

        <div v-if="content_exist">

            <!-- Title -->
            <v-row class="mb-5 pa-0" style="-moz-hyphens: auto; -webkit-hyphens: auto; -ms-hyphens: auto; hyphens: auto;">
                <div class="display-2 font-weight-bold primary--text">{{ content.title }}</div>
            </v-row>

            <!-- Sub-Title -->
            <v-row class="mb-3 pt-0">
                <div class="display-1">{{ content.subTitle }}</div>
            </v-row>

            <!-- timeline-like optic for lection -->
            <div :id="!$vuetify.breakpoint.mdAndDown ? 'timeline' : 'timeline-mobile'" :style="cssVars" v-if="post">

                <!-- main for-loop for a blocks in the lection -->
                <div 
                    v-for="(block, i) in content.body" 
                    :key="block.name"
                >

                    <!-- ************* -->
                    <!-- BASIC-QUIZ  OK -->
                    <!-- ************* -->
                    <div v-if="block.type == 'quiz'">
                        <quiz :data="block.value" :id="i" />
                    </div>


                    <!-- ************* -->
                    <!-- BASIC-INFO  OK -->
                    <!-- ************* -->
                    <div v-if="block.type == 'basicInfo'">
                        <necessary-info :data="block.value" :id="i" />
                    </div>
                    

                    <!-- ************* -->
                    <!-- OPTIONAL-INFO OK -->
                    <!-- ************* -->
                    <div v-if="block.type == 'optionalInfo'">
                        <optional-info :data="block.value" :id="i"/>
                    </div>

                        
                    <!-- ************* -->
                    <!-- BASIC-EXAMPLE OK -->
                    <!-- ************* -->
                    <div v-if="block.type == 'basicExample'">
                        <necessary-example :data="block.value" :id="i" />
                    </div>


                    <!-- ************* -->
                    <!-- BASIC-DATA -->
                    <!-- ************* -->
                    <div v-if="block.type == 'basicData'">
                        <necessary-data :data="block.value" :id="i" />
                    </div>


                    <!-- ************* -->
                    <!-- BASIC-TASK -->
                    <!-- ************* -->
                    <div v-if="block.type == 'basicTask'">
                        <necessary-task :data="block.value" :id="i" />
                    </div>


                    <!-- ************* -->
                    <!-- OPTIONAL-TASK -->
                    <!-- ************* -->
                    <div v-if="block.type == 'optionalTask'">
                        <optional-task :data="block.value" :id="i"/>
                    </div>

                </div>
            </div>

            <!-- LAST ITEM -->
            <div :id="!$vuetify.breakpoint.mdAndDown ? 'timeline-last' : 'timeline-last-mobile'" v-if="post">

                <v-row>
                    
                    <v-col class="justify-content-center" >

                        <div class="timeline-last-item">

                            <v-row >

                                <v-avatar v-if="!$vuetify.breakpoint.mdAndDown" color="primary" size="50" class="elevation-5" style="margin-left: -12px;">
                                    <div v-if="!thisLessionFinished">
                                    <v-icon medium color="white">fas fa-ban</v-icon>
                                    </div>
                                    <div v-else>
                                    <v-icon medium color="white">fas fa-check</v-icon>
                                    </div>
                                </v-avatar>

                                <!-- Navigation -->

                                <v-btn 
                                    @click="goBack()"
                                    outlined
                                    rounded
                                    large
                                    color="secondary" 
                                    :class="!$vuetify.breakpoint.mdAndDown ? 'headline ml-4 elevation-5' : 'headline elevation-5'" 
                                    style="text-transform: none !important"
                                >
                                    Zurück
                                </v-btn>

                                <v-spacer />

                                <v-btn 
                                    @click="goForward()"
                                    outlined
                                    rounded
                                    large
                                    color="primary" 
                                    class="headline ml-2 elevation-5" 
                                    style="text-transform: none !important"
                                    :disabled="!thisLessionFinished"
                                    :loading="loading_btn"
                                >
                                    Weiter
                                </v-btn>

                            </v-row>

                        </div>

                    </v-col>
                </v-row>

            </div>

        </div>

        <div v-if="!content_exist">
            
            <v-alert
                border="left"
                elevation="1"
                colored-border
                prominent
                type="error"
            >
                <v-row align="center">

                    <v-col cols="auto" class="mr-auto">

                        <div>
                            <small>Fehler</small>
                        </div>
                        
                        <div class="black--text mt-3">
                            Diese Lektion existiert leider nicht.
                        </div>


                    </v-col>

                    <v-col cols="auto">
                        <v-btn
                            outlined
                            color="error"
                            @click="goToCurrentLection"
                        >
                            Mit der letzten Lektion weitermachen
                        </v-btn>
                    </v-col>

                </v-row>

            </v-alert>
        </div>

        <!-- DEV -->
        <v-dialog v-model="dialog" persistent max-width="320">

            <v-card>
                <v-card-title class="headline">Fragebögen müssen ausgefüllt werden.</v-card-title>
                <v-card-text>
                    Da dieser Klient als Development-Klient definiert ist, haben sie die Wahl, 
                    ob sie die Fragebögen ausfüllen möchten, oder nicht.
                </v-card-text>
                <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="primary" text @click="dialog = false">Nicht ausfüllen</v-btn>
                <v-btn color="primary" text @click="goToAssessment">Ausfüllen</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

        <v-dialog v-model="dialog_dev" persistent max-width="320">

            <v-card>
                <v-card-title class="headline">Fragebögen müssen ausgefüllt werden.</v-card-title>
                <v-card-text>
                    Da dieser Klient als Development-Klient definiert ist, haben sie die Wahl, 
                    ob sie die Fragebögen ausfüllen möchten, oder nicht.
                </v-card-text>
                <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="primary" text @click="dev_next">Nicht ausfüllen</v-btn>
                <v-btn color="primary" text @click="goToAssessment">Ausfüllen</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

        <v-dialog v-model="dialog_dev_next" persistent max-width="450">

            <v-card>
                <v-card-title class="headline">Bitte nicht speichern während der Entwicklung.</v-card-title>
                <v-card-text>
                    Dieser Klient ist als Development-Klient definiert. Solange sie an dieser Lektion die Inhalte bearbeiten, sollten sie nicht auf
                    Weiter klicken, da dann die Daten in der Datenbank gespeichert werden. Dies kann bei späteren Änderungen in Wagtail zu einem 
                    unvorhergesehenen Verhalten der Anwendung führen. In diesem Falle müssten sie dann den Klienten vollständig zurücksetzen.
                </v-card-text>
                <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="primary" text @click="doNotSave">Nicht speichern</v-btn>
                <v-btn color="primary" text @click="saveDev">Speichern und weiter</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>


    </v-container>

</template>

<script>
import ClientApi from '@/_api/client_api'
import axios from 'axios'
import router from '@/router'
import store from '@/store/store'

// mainBlocks
import optionalInfo from '@/components/_client/_intervention/_mainBlocks/optionalInfo.vue'
import necessaryInfo from '@/components/_client/_intervention/_mainBlocks/necessaryInfo.vue'
import necessaryExample from '@/components/_client/_intervention/_mainBlocks/necessaryExample.vue'
import necessaryTask from '@/components/_client/_intervention/_mainBlocks/necessaryTask.vue'
import optionalTask from '@/components/_client/_intervention/_mainBlocks/optionalTask.vue'
import necessaryData from '@/components/_client/_intervention/_mainBlocks/necessaryData.vue'
import quiz from '@/components/_client/_intervention/_mainBlocks/quiz.vue'

export default {
    name: 'client-intervention',

    data: () => ({
        post: false,

        dialog: false,

        dialog_dev: false,

        dialog_dev_next: false,
        dev_go_on: false,
        dev_checked: false,

        timepoint_for_assessment: 1,

        loading_btn: false,

        already_finished: null,

        content_exist: false


    }),

    components: {
        necessaryInfo,
        optionalInfo,
        necessaryExample,
        necessaryTask,
        optionalTask,
        necessaryData,
        quiz

    },

    beforeRouteEnter (to, from, next){
        
        ClientApi.GetClientInfo().then((resp) => {

            if (store.getters['store_client/get_page_allowed'] || store.getters['store_client/get_client'].is_dev) {

                console.log('allowed to enter page (beforeRouteEnter)')

                var data = {}

                if (parseInt(to.params.module) >= 7) {

                    data = {

                        'module_nr': 6,
                        'session_nr': 1,
                        'lesson_rank': 1

                    }

                } else {

                    data = {

                        'module_nr': parseInt(to.params.module),
                        'session_nr': parseInt(to.params.session),
                        'lesson_rank': parseInt(to.params.lection)

                    }

                }

                ClientApi.CheckIfDetailedProgressExists(data).then((resp1) => {

                    if (resp1.data.length == 0) {

                        if (resp.data.client_progress.has_to_wait_questionnaires || resp.data.client_progress.has_to_wait_tasks || resp.data.client_progress.has_to_wait_time) {

                            next(vm => { 

                                vm.$router.push({ name: 'client-has-to-wait'})
                            })

                        } else {

                            // if it is the first session and first lection within a module
                            if (parseInt(to.params.session) == 1 && parseInt(to.params.lection) == 1) {

                                //timepoint 1 == pre; timepoint 2 == post
                                var endpoint = 'api-wagtail/get-assessment/?module_nr=' + data.module_nr + '&session_nr=' + data.session_nr + '&lection_nr=' + data.lesson_rank + '&timepoint=' + 1

                                axios.get(endpoint)

                                .then(resp => {

                                    var show = false

                                    resp.data.questionnaires.forEach(element => {

                                        if (element.already_saved == false) {
                                            show = true
                                        }
                                        
                                    });

                                    if (show == true) {

                                        if (store.getters['store_client/get_client'].is_dev) {

                                            next(vm => { 

                                                vm.timepoint_for_assessment = 1
                                                vm.dialog = true

                                            })

                                        } else {

                                            // set page allowed to true
                                            console.log('UPDATE_PAGE_ALLOWED: client_intervention')
                                            store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)
                
                                            router.push({ name: 'client-assessment', params: { module: to.params.module, session: to.params.session, lection: to.params.lection, timepoint: 1, step: 0 } })

                                        }

                                    } else {

                                        next()

                                    }


                                })

                                .catch(err => {

                                    console.log(err)
                                
                                })

                            // if it is the first lection within a session: Session-Pre-Mesaurement
                            } else if (parseInt(to.params.session) != 1 && parseInt(to.params.lection) == 1) {

                                //timepoint 3 -> Session Pre-Measuremnt
                                endpoint = 'api-wagtail/get-assessment/?module_nr=' + to.params.module + '&session_nr=' + to.params.session + '&lection_nr=' + to.params.lection + '&timepoint=' + 3

                                axios.get(endpoint)

                                .then(resp => {

                                    var show = false

                                    resp.data.questionnaires.forEach(element => {

                                        if (element.already_saved == false) {
                                            show = true
                                        }
                                        
                                    });

                                    if (show == true) {

                                        if (store.getters['store_client/get_client'].is_dev) {

                                            next(vm => { 

                                                vm.timepoint_for_assessment = 3

                                                vm.dialog = true

                                            })

                                        } else {

                                            // set page allowed to true
                                            console.log('UPDATE_PAGE_ALLOWED: client_intervention')
                                            store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

                                            router.push({ name: 'client-assessment', params: { module: to.params.module, session: to.params.session, lection: to.params.lection, timepoint: 3 , step: 0} })

                                        }

                                    } else {

                                        next()

                                    }


                                })

                                .catch(err => {

                                    console.log(err)
                                
                                })
                    
                            } else {

                                next()

                            }

                        }


                    } else {

                        next()

                    }

                })

            } else {

                console.log('not_allowed (beforeRouteEnter)')

                next(vm => { 

                    vm.$router.push({ name: 'client-not-allowed'})
                    
                })

            }

        })
        
    },

    beforeRouteUpdate (to, from, next){

        ClientApi.GetClientInfo().then((resp) => {

            if (this.$store.getters['store_client/get_page_allowed'] || this.$store.getters['store_client/get_client'].is_dev) {

                console.log('allowed to enter page (beforeRouteUpdate)')

                var data = {}

                if (parseInt(to.params.module) >= 7) {

                    data = {

                        'module_nr': 6,
                        'session_nr': 1,
                        'lesson_rank': 1

                    }

                } else {

                    data = {

                        'module_nr': parseInt(to.params.module),
                        'session_nr': parseInt(to.params.session),
                        'lesson_rank': parseInt(to.params.lection)

                    }

                }

                ClientApi.CheckIfDetailedProgressExists(data).then((resp1) => {

                    if (resp1.data.length == 0) {

                        if (resp.data.client_progress.has_to_wait_questionnaires || resp.data.client_progress.has_to_wait_tasks || resp.data.client_progress.has_to_wait_time) {

                            next(vm => { 

                                vm.$router.push({ name: 'client-has-to-wait'})
                            })

                        } else {

                            // if it is the first session and first lection within a module
                            if (parseInt(to.params.session) == 1 && parseInt(to.params.lection) == 1) {

                                //timepoint 1 == pre; timepoint 2 == post
                                var endpoint = 'api-wagtail/get-assessment/?module_nr=' + data.module_nr + '&session_nr=' + data.session_nr + '&lection_nr=' + data.lesson_rank + '&timepoint=' + 1

                                axios.get(endpoint)

                                .then(resp => {

                                    var show = false

                                    resp.data.questionnaires.forEach(element => {

                                        if (element.already_saved == false) {
                                            show = true
                                        }
                                        
                                    });

                                    if (show == true) {

                                        if (store.getters['store_client/get_client'].is_dev) {

                                            next(vm => { 

                                                vm.timepoint_for_assessment = 1
                                                vm.dialog = true

                                            })

                                        } else {

                                            // set page allowed to true
                                            console.log('UPDATE_PAGE_ALLOWED: client_intervention')
                                            this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

                                            router.push({ name: 'client-assessment', params: { module: to.params.module, session: to.params.session, lection: to.params.lection, timepoint: 1, step: 0 } })

                                        }

                                    } else {

                                        next()

                                    }


                                })

                                .catch(err => {

                                    console.log(err)
                                
                                })

                            // if it is the first lection within a session: Session-Pre-Mesaurement
                            } else if (parseInt(to.params.session) != 1 && parseInt(to.params.lection) == 1) {

                                //timepoint 3 -> Session Pre-Measuremnt
                                endpoint = 'api-wagtail/get-assessment/?module_nr=' + to.params.module + '&session_nr=' + to.params.session + '&lection_nr=' + to.params.lection + '&timepoint=' + 3

                                axios.get(endpoint)

                                .then(resp => {

                                    var show = false

                                    resp.data.questionnaires.forEach(element => {

                                        if (element.already_saved == false) {
                                            show = true
                                        }
                                        
                                    });

                                    if (show == true) {

                                        if (store.getters['store_client/get_client'].is_dev) {

                                            next(vm => { 

                                                vm.timepoint_for_assessment = 3
                                                vm.dialog = true

                                            })

                                        } else {

                                            // set page allowed to true
                                            console.log('UPDATE_PAGE_ALLOWED: client_intervention')
                                            this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

                                            router.push({ name: 'client-assessment', params: { module: to.params.module, session: to.params.session, lection: to.params.lection, timepoint: 3 , step: 0} })

                                        }

                                    } else {

                                        next()

                                    }


                                })

                                .catch(err => {

                                    console.log(err)
                                
                                })
                    
                            } else {

                                next()


                            }

                        }

                    } else {

                        next()

                    }

                })

            } else {

                console.log('not_allowed (beforeRouteEnter)')

                next(vm => { 

                    vm.$router.push({ name: 'client-not-allowed'})
                    
                })

            }

        })
        
    },

    created() {

        if (this.$store.getters['store_client/get_page_allowed'] || this.$store.getters['store_client/get_client'].is_dev) {

            console.log('allowed to enter page (created)')

            // set page allowed to false
            this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', false)

            this.loading_btn = false

            const data = {

                'module_nr': parseInt(this.$route.params.module),
                'session_nr': parseInt(this.$route.params.session),
                'lesson_rank': parseInt(this.$route.params.lection)

            }

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

            this.post = false

            // clear relevant stores
            this.$store.dispatch('store_lection/RESET_STATE')
            this.$store.dispatch('store_task/RESET_STATE')
            this.$store.dispatch('store_quiz/RESET_STATE')

            if (parseInt(this.$route.params.module) >= 7) {

                if (this.$store.getters['store_client/get_client'].status == 5) {
                    this.$router.push({ name: 'client-intervention', params: { module: 1, session: 1, lection: 1 } })
                } else {
                   this.$router.push({ name: 'client-final'}) 
                }

                

            } else {

                // get content of lection
                ClientApi.GetLectionContent(data).then(() => {

                    this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

                    if (this.$store.getters['store_lection/get_lection_wagtail_content'].length == 0) {

                        this.content_exist = false

                        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                        this.post = true

                    } else {

                        this.content_exist = true

                        // get intervention info
                        ClientApi.GetInterventionInfo(data).then(() => {

                            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

                            ClientApi.GetSavedLectionData(data).then(() => {

                                ClientApi.CheckIfDetailedProgressExists(data).then((resp) => {

                                    if (resp.data.length == 0) {

                                        this.already_finished = null

                                        if (this.$store.getters['store_client/get_client'].is_dev) {

                                            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                                            this.post = true

                                        } else {

                                            var new_detail_data = {

                                                "module_nr": parseInt(this.$route.params.module),
                                                "session_nr": parseInt(this.$route.params.session),
                                                "lession_nr": parseInt(this.$route.params.lection),

                                            }

                                            ClientApi.CreateNewDetailedProgress(new_detail_data).then(() => {

                                                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                                                this.post = true


                                            })

                                        }
                                            

                                    } else {

                                        this.already_finished = resp.data[0].lession_finished
                                        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)
                                        this.post = true

                                    }


                                })

                            })

                        })

                    }

                })

            }

        } else {
           
           console.log('not_allowed (created)')

            this.$router.push({ name: 'client-not-allowed'})
                

        }

    },

    computed: {

        loading() {
            return this.$store.getters['store_loader/get_show_main_loader']
        },

        client_is_dev() {
            return this.$store.getters['store_client/get_client'].is_dev
        },

        content() {
            return this.$store.getters['store_lection/get_lection_wagtail_content']
        },

        savedLectionData() {
            return this.$store.getters['store_lection/get_saved_lection_data']
        },

        thisLessionFinished() {
            return this.$store.getters['store_lection/get_saved_lection_data'].lession_finished
        },

        cssVars() {

            if (this.content.body.length >= 3) {

                return {
                    '--timeline-before-height': '99%'
                }
                   
            } else {

                return {
                    '--timeline-before-height': '96%'
                }

            }
            
        }

    },

    // To react to params changes in the same component, you can simply watch the $route object:
    watch: {

        '$route' () {

            console.log('ROUTE!!!!!!')

            if (this.$store.getters['store_client/get_page_allowed'] || this.$store.getters['store_client/get_client'].is_dev) {

                console.log('allowed to enter page (created)')

                // set page allowed to false
                this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', false)

                this.loading_btn = false

                const data = {

                    'module_nr': parseInt(this.$route.params.module),
                    'session_nr': parseInt(this.$route.params.session),
                    'lesson_rank': parseInt(this.$route.params.lection)

                }

                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

                this.post = false

                // clear relevant stores
                this.$store.dispatch('store_lection/RESET_STATE')
                this.$store.dispatch('store_task/RESET_STATE')
                this.$store.dispatch('store_quiz/RESET_STATE')

                if (parseInt(this.$route.params.module) >= 7) {

                    if (this.$store.getters['store_client/get_client'].status == 5) {
                        this.$router.push({ name: 'client-intervention', params: { module: 1, session: 1, lection: 1 } })
                    } else {
                    this.$router.push({ name: 'client-final'}) 
                    }

                    

                } else {

                    // get content of lection
                    ClientApi.GetLectionContent(data).then(() => {

                        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

                        if (this.$store.getters['store_lection/get_lection_wagtail_content'].length == 0) {

                            this.content_exist = false

                            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                            this.post = true

                        } else {

                            this.content_exist = true

                            // get intervention info
                            ClientApi.GetInterventionInfo(data).then(() => {

                                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

                                ClientApi.GetSavedLectionData(data).then(() => {

                                    ClientApi.CheckIfDetailedProgressExists(data).then((resp) => {

                                        if (resp.data.length == 0) {

                                            this.already_finished = null

                                            if (this.$store.getters['store_client/get_client'].is_dev) {

                                                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                                                this.post = true

                                            } else {

                                                var new_detail_data = {

                                                    "module_nr": parseInt(this.$route.params.module),
                                                    "session_nr": parseInt(this.$route.params.session),
                                                    "lession_nr": parseInt(this.$route.params.lection),

                                                }

                                                ClientApi.CreateNewDetailedProgress(new_detail_data).then(() => {

                                                    this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                                                    this.post = true


                                                })

                                            }
                                                

                                        } else {

                                            this.already_finished = resp.data[0].lession_finished
                                            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)
                                            this.post = true

                                        }


                                    })

                                })

                            })

                        }

                    })

                }

            } else {
            
            console.log('not_allowed (created)')

                this.$router.push({ name: 'client-not-allowed'})
                    

            }

        }

    },

    methods: {

        goToCurrentLection() {

            // set page allowed to true
            console.log('UPDATE_PAGE_ALLOWED: client_intervention')
            this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

            if (this.$store.getters['store_client/get_client_progress'].introduction_finished) {
                if (this.$store.getters['store_client/get_client_progress'].intervention_started) {
                    this.$router.push({ name: 'client-intervention', params: { module: this.$store.getters['store_client/get_client_progress'].current_module_nr, session: this.$store.getters['store_client/get_client_progress'].current_session_nr, lection: this.$store.getters['store_client/get_client_progress'].current_lession_nr } })
                } else {
                    this.$router.push({ name: 'client-intervention', params: { module: 1, session: 1, lection: 1 } })
                }
            } else {
                this.$router.push({ name: 'client-introduction', params: { rank: 1 } })
            }

        },

        goToAssessment() {

            // set page allowed to true
            console.log('UPDATE_PAGE_ALLOWED: client_intervention')
            this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

            router.push({ name: 'client-assessment', params: { module: this.$route.params.module, session: this.$route.params.session, lection: this.$route.params.lection, timepoint: this.timepoint_for_assessment, step: 0 } })

        },

        // DEV ---

        dev_next() {

            this.dialog_dev = false

            // go to overview
            this.$router.push({ name: 'client-overview'})

        },

        doNotSave() {

            this.dialog_dev_next = false
            this.dev_go_on = false
            this.dev_checked = true

        },

        saveDev() {

            this.dev_checked = true
            this.dialog_dev_next = false
            this.dev_go_on = true

            this.goForward()

        },

        // DEV END ---

        goBack() {

            // if it is the first lection in this session, go to overview
            if (parseInt(this.$route.params.lection) == 1) {

                // go to overview
                this.$router.push({ name: 'client-overview'})

            } else {

                // set page allowed to true
                console.log('UPDATE_PAGE_ALLOWED: client_intervention')
                this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

                var new_module_nr = parseInt(this.$route.params.module)
                var new_session_nr = parseInt(this.$route.params.session)
                var new_lection_nr = parseInt(this.$route.params.lection) - 1

                this.$router.push({ name: 'client-intervention', params: { module: new_module_nr, session: new_session_nr, lection: new_lection_nr } })

            }

        },

        goForward() {

            this.loading_btn = true

            // if the lection is not already saved
            if (this.already_finished == null) {

                // client is dev and dialog not shown yet -> SHOW DIALOG
                if (this.$store.getters['store_client/get_client'].is_dev && this.dev_checked == false) {

                    this.dialog_dev_next = true

                // client is dev and dialog already shown
                } else if (this.$store.getters['store_client/get_client'].is_dev && this.dev_checked == true) {

                    if (this.dev_go_on == true) {

                        this.save_and_next(true)

                    //
                    // DO NOT SAVE AS DEV -> go forward without saving
                    //
                    } else {

                        this.next_without_saving()

                    }

                //
                // CLIENT IS NOT DEV
                //
                } else {

                    this.save_and_next(false)

                }

            //
            // Already saved
            //
            } else {

                this.next_without_saving()
            }

        },

        next_without_saving() {

            // if it is the last lection in this session, go to overview
            if (this.$store.getters['store_lection/get_lection_info'].this_session_lection_count == parseInt(this.$route.params.lection)) {

                if (parseInt(this.$route.params.module) == 6 && parseInt(this.$route.params.session) == 4) {

                    // go to final
                    this.$router.push({ name: 'client-final'})

                } else {

                    // go to overview
                    this.$router.push({ name: 'client-overview'})

                }

            } else {

                // set page allowed to true
                console.log('UPDATE_PAGE_ALLOWED: client_intervention')
                this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)


                var new_module_nr = parseInt(this.$route.params.module)
                var new_session_nr = parseInt(this.$route.params.session)
                var new_lection_nr = parseInt(this.$route.params.lection) + 1

                this.$router.push({ name: 'client-intervention', params: { module: new_module_nr, session: new_session_nr, lection: new_lection_nr } })

            }

        },

        save_and_next(is_dev) {

            //
            // if it is the last lection in session
            //
            if (this.$store.getters['store_lection/get_lection_info'].this_session_lection_count == parseInt(this.$route.params.lection)) {

                // if it is also the last module
                if (this.$store.getters['store_lection/get_lection_info'].all_modules_count == parseInt(this.$route.params.module)) {

                    // if it is also the last session in the last module
                    if (this.$store.getters['store_lection/get_lection_info'].this_module_session_count == parseInt(this.$route.params.session)) {

                        const data = {
                            'module_nr': parseInt(this.$route.params.module),
                            'session_nr': parseInt(this.$route.params.session),
                            'lesson_rank': parseInt(this.$route.params.lection)
                        }

                        ClientApi.SaveLectionData(data).then(() => {

                            const progress_data = {
                                'module_nr': parseInt(this.$route.params.module) + 1,
                                'session_nr': 1,
                                'lesson_rank': 1,
                                "intervention_started": true,
                                "intervention_finished": true,
                                "introduction_finished": this.$store.getters['store_client/get_client_progress'].introduction_finished
                            }

                            ClientApi.SaveInterventionProgress(progress_data).then(() => {

                                // save award
                                ClientApi.UpdateAwardsGetted({award_nr: 9}).then(() => {

                                    //this.$store.dispatch('store_dialogs/UPDATE_SHOW_AWARD_DIALOG', { show: true, image: 'SchlüsselTN.png'})

                                    var new_detail_data = {

                                        "module_nr": parseInt(this.$route.params.module),
                                        "session_nr": parseInt(this.$route.params.session),
                                        "lession_nr": parseInt(this.$route.params.lection),

                                    }

                                    if (!is_dev) {

                                        ClientApi.PatchDetailedProgress(new_detail_data).then(() => {
                                            // check if post-questionnaires have to be filled in
 
                                            //timepoint 1 == pre; timepoint 2 == post
                                            var endpoint = 'api-wagtail/get-assessment/?module_nr=' + this.$route.params.module + '&session_nr=' + this.$route.params.session + '&lection_nr=' + this.$route.params.lection + '&timepoint=' + 2
                                            axios.get(endpoint)
                                            .then(resp => {
                                                if (resp.data.questionnaires.length != 0) {
                                                    if (this.$store.getters['store_client/get_client'].is_dev) {
                                                            this.timepoint_for_assessment = 2
                                                            this.dialog_dev = true
                                                    } else {

                                                        // set page allowed to true
                                                        console.log('UPDATE_PAGE_ALLOWED: client_intervention')
                                                        this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)
                
                                                        this.$router.push({ name: 'client-assessment', params: { module: this.$route.params.module, session: this.$route.params.session, lection: this.$route.params.lection, timepoint: 2, step: 0 } })
                                                    }

                                                } else {
                                                    // go to overview
                                                    this.$router.push({ name: 'client-final'})
                                                }
                                            })
                                        })

                                    } else {

                                        // check if post-questionnaires have to be filled in

                                        //timepoint 1 == pre; timepoint 2 == post
                                        var endpoint = 'api-wagtail/get-assessment/?module_nr=' + this.$route.params.module + '&session_nr=' + this.$route.params.session + '&lection_nr=' + this.$route.params.lection + '&timepoint=' + 2

                                        axios.get(endpoint)

                                        .then(resp => {

                                            if (resp.data.questionnaires.length != 0) {

                                                if (this.$store.getters['store_client/get_client'].is_dev) {

                                                        this.timepoint_for_assessment = 2

                                                        this.dialog_dev = true

                                                } else {

                                                    // set page allowed to true
                                                    console.log('UPDATE_PAGE_ALLOWED: client_intervention')
                                                    this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)
                

                                                    this.$router.push({ name: 'client-assessment', params: { module: this.$route.params.module, session: this.$route.params.session, lection: this.$route.params.lection, timepoint: 2, step: 0 } })

                                                }

                                            } else {

                                                // go to overview
                                                this.$router.push({ name: 'client-final'})

                                            }

                                        })

                                    }

                                })

                            })

                        })

                    // if it is NOT the last session in the last module
                    } else {

                        const data = {
                            'module_nr': parseInt(this.$route.params.module),
                            'session_nr': parseInt(this.$route.params.session),
                            'lesson_rank': parseInt(this.$route.params.lection)
                        }

                        ClientApi.SaveLectionData(data).then(() => {

                            const progress_data = {
                                'module_nr': parseInt(this.$route.params.module),
                                'session_nr': parseInt(this.$route.params.session) + 1,
                                'lesson_rank': 1,
                                "intervention_started": true,
                                "intervention_finished": this.$store.getters['store_client/get_client_progress'].intervention_finished,
                                "introduction_finished": this.$store.getters['store_client/get_client_progress'].introduction_finished
                            }

                            ClientApi.SaveInterventionProgress(progress_data).then(() => {

                                var new_detail_data = {

                                    "module_nr": parseInt(this.$route.params.module),
                                    "session_nr": parseInt(this.$route.params.session),
                                    "lession_nr": parseInt(this.$route.params.lection),

                                }

                                if (!is_dev) {

                                    ClientApi.PatchDetailedProgress(new_detail_data).then(() => {

                                        // check if post-questionnaires have to be filled in

                                        //timepoint 1 == pre; timepoint 2 == post
                                        var endpoint = 'api-wagtail/get-assessment/?module_nr=' + this.$route.params.module + '&session_nr=' + this.$route.params.session + '&lection_nr=' + this.$route.params.lection + '&timepoint=' + 4

                                        axios.get(endpoint)

                                        .then(resp => {

                                            if (resp.data.questionnaires.length != 0) {

                                                if (this.$store.getters['store_client/get_client'].is_dev) {

                                                        this.timepoint_for_assessment = 4

                                                        this.dialog_dev = true

                                                } else {

                                                    // set page allowed to true
                                                    console.log('UPDATE_PAGE_ALLOWED: client_intervention')
                                                    this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)


                                                    this.$router.push({ name: 'client-assessment', params: { module: this.$route.params.module, session: this.$route.params.session, lection: this.$route.params.lection, timepoint: 4, step: 0 } })

                                                }

                                            } else {

                                                // go to overview
                                                this.$router.push({ name: 'client-overview'})

                                            }

                                        })

                                    })

                                } else {

                                    // check if post-questionnaires have to be filled in

                                    //timepoint 1 == pre; timepoint 2 == post
                                    var endpoint = 'api-wagtail/get-assessment/?module_nr=' + this.$route.params.module + '&session_nr=' + this.$route.params.session + '&lection_nr=' + this.$route.params.lection + '&timepoint=' + 4

                                    axios.get(endpoint)

                                    .then(resp => {

                                        if (resp.data.questionnaires.length != 0) {

                                            if (this.$store.getters['store_client/get_client'].is_dev) {

                                                    this.timepoint_for_assessment = 4

                                                    this.dialog_dev = true

                                            } else {

                                                // set page allowed to true
                                                console.log('UPDATE_PAGE_ALLOWED: client_intervention')
                                                this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

                                                this.$router.push({ name: 'client-assessment', params: { module: this.$route.params.module, session: this.$route.params.session, lection: this.$route.params.lection, timepoint: 4, step: 0 } })

                                            }

                                        } else {

                                            // go to overview
                                            this.$router.push({ name: 'client-overview'})

                                        }

                                    })

                                }

                            })
                        })

                    }
                
                // not the last module
                } else {

                    // if it is the last session in a module
                    if (this.$store.getters['store_lection/get_lection_info'].this_module_session_count == parseInt(this.$route.params.session)) {

                        const data = {
                            'module_nr': parseInt(this.$route.params.module),
                            'session_nr': parseInt(this.$route.params.session),
                            'lesson_rank': parseInt(this.$route.params.lection)
                        }

                        ClientApi.SaveLectionData(data).then(() => {

                            const progress_data = {
                                'module_nr': parseInt(this.$route.params.module) + 1,
                                'session_nr': 1,
                                'lesson_rank': 1,
                                "intervention_started": true,
                                "intervention_finished": false,
                                "introduction_finished": this.$store.getters['store_client/get_client_progress'].introduction_finished
                            }

                            ClientApi.SaveInterventionProgress(progress_data).then(() => {

                                var a_nr = 0

                                

                                if (parseInt(this.$route.params.module) == 1) {
                                    a_nr = 4
                                    
                                } else if (parseInt(this.$route.params.module) == 2) {
                                    a_nr = 5
                                    
                                } else if (parseInt(this.$route.params.module) == 3) {
                                    a_nr = 6
                                    
                                } else if (parseInt(this.$route.params.module) == 4) {
                                    a_nr = 7
                                    
                                } else if (parseInt(this.$route.params.module) == 5) {
                                    a_nr = 8
                                    
                                } else if (parseInt(this.$route.params.module) == 6) {
                                    a_nr = 9
                                    
                                }

                                // save award
                                ClientApi.UpdateAwardsGetted({award_nr: a_nr}).then(() => {

                                    //this.$store.dispatch('store_dialogs/UPDATE_SHOW_AWARD_DIALOG', { show: true, image: img})

                                    var new_detail_data = {

                                        "module_nr": parseInt(this.$route.params.module),
                                        "session_nr": parseInt(this.$route.params.session),
                                        "lession_nr": parseInt(this.$route.params.lection),

                                    }

                                    if (!is_dev) {

                                        ClientApi.PatchDetailedProgress(new_detail_data).then(() => {

                                            // check if post-questionnaires have to be filled in

                                            //timepoint 1 == pre; timepoint 2 == post
                                            var endpoint = 'api-wagtail/get-assessment/?module_nr=' + this.$route.params.module + '&session_nr=' + this.$route.params.session + '&lection_nr=' + this.$route.params.lection + '&timepoint=' + 2

                                            axios.get(endpoint)

                                            .then(resp => {

                                                if (resp.data.questionnaires.length != 0) {

                                                    if (this.$store.getters['store_client/get_client'].is_dev) {

                                                            this.timepoint_for_assessment = 2

                                                            this.dialog_dev = true

                                                    } else {

                                                        // set page allowed to true
                                                        console.log('UPDATE_PAGE_ALLOWED: client_intervention')
                                                        this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

                                                        this.$router.push({ name: 'client-assessment', params: { module: this.$route.params.module, session: this.$route.params.session, lection: this.$route.params.lection, timepoint: 2, step: 0 } })

                                                    }

                                                } else {

                                                    // go to overview
                                                    this.$router.push({ name: 'client-overview'})

                                                }

                                            })

                                        })

                                    } else {

                                        // check if post-questionnaires have to be filled in

                                        //timepoint 1 == pre; timepoint 2 == post
                                        var endpoint = 'api-wagtail/get-assessment/?module_nr=' + this.$route.params.module + '&session_nr=' + this.$route.params.session + '&lection_nr=' + this.$route.params.lection + '&timepoint=' + 2

                                        axios.get(endpoint)

                                        .then(resp => {

                                            if (resp.data.questionnaires.length != 0) {

                                                if (this.$store.getters['store_client/get_client'].is_dev) {

                                                        this.timepoint_for_assessment = 2

                                                        this.dialog_dev = true

                                                } else {

                                                    // set page allowed to true
                                                    this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)
                                                    this.$router.push({ name: 'client-assessment', params: { module: this.$route.params.module, session: this.$route.params.session, lection: this.$route.params.lection, timepoint: 2, step: 0 } })

                                                }

                                            } else {

                                                // go to overview
                                                this.$router.push({ name: 'client-overview'})

                                            }

                                        })

                                    }

                                })

                            })
                        })

                    
                    } else {

                        const data = {
                            'module_nr': parseInt(this.$route.params.module),
                            'session_nr': parseInt(this.$route.params.session),
                            'lesson_rank': parseInt(this.$route.params.lection)
                        }

                        ClientApi.SaveLectionData(data).then(() => {

                            const progress_data = {
                                'module_nr': parseInt(this.$route.params.module),
                                'session_nr': parseInt(this.$route.params.session) + 1,
                                'lesson_rank': 1,
                                "intervention_started": true,
                                "intervention_finished": this.$store.getters['store_client/get_client_progress'].intervention_finished,
                                "introduction_finished": this.$store.getters['store_client/get_client_progress'].introduction_finished
                            }

                            ClientApi.SaveInterventionProgress(progress_data).then(() => {


                                if (parseInt(this.$route.params.module) == 1 && parseInt(this.$route.params.session) == 1) {

                                    // save award
                                    ClientApi.UpdateAwardsGetted({award_nr: 2}).then(() => {

                                        //this.$store.dispatch('store_dialogs/UPDATE_SHOW_AWARD_DIALOG', { show: true, image: 'CapeTN.png'})

                                        var new_detail_data = {

                                            "module_nr": parseInt(this.$route.params.module),
                                            "session_nr": parseInt(this.$route.params.session),
                                            "lession_nr": parseInt(this.$route.params.lection),

                                        }

                                        if (!is_dev) {
                                    
                                            ClientApi.PatchDetailedProgress(new_detail_data).then(() => {

                                                // check if post-questionnaires have to be filled in

                                                //timepoint 1 == pre; timepoint 2 == post
                                                var endpoint = 'api-wagtail/get-assessment/?module_nr=' + this.$route.params.module + '&session_nr=' + this.$route.params.session + '&lection_nr=' + this.$route.params.lection + '&timepoint=' + 4

                                                axios.get(endpoint)

                                                .then(resp => {

                                                    if (resp.data.questionnaires.length != 0) {

                                                        if (this.$store.getters['store_client/get_client'].is_dev) {

                                                                this.timepoint_for_assessment = 4

                                                                this.dialog_dev = true

                                                        } else {

                                                            // set page allowed to true
                                                            this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

                                                            this.$router.push({ name: 'client-assessment', params: { module: this.$route.params.module, session: this.$route.params.session, lection: this.$route.params.lection, timepoint: 4, step: 0 } })

                                                        }

                                                    } else {

                                                        // go to overview
                                                        this.$router.push({ name: 'client-overview'})

                                                    }

                                                })

                                            })

                                        } else {

                                            // check if post-questionnaires have to be filled in

                                            //timepoint 1 == pre; timepoint 2 == post
                                            var endpoint = 'api-wagtail/get-assessment/?module_nr=' + this.$route.params.module + '&session_nr=' + this.$route.params.session + '&lection_nr=' + this.$route.params.lection + '&timepoint=' + 4

                                            axios.get(endpoint)

                                            .then(resp => {

                                                if (resp.data.questionnaires.length != 0) {

                                                    if (this.$store.getters['store_client/get_client'].is_dev) {

                                                            this.timepoint_for_assessment = 4

                                                            this.dialog_dev = true

                                                    } else {

                                                        // set page allowed to true
                                                        console.log('UPDATE_PAGE_ALLOWED: client_intervention')
                                                        this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

                                                        this.$router.push({ name: 'client-assessment', params: { module: this.$route.params.module, session: this.$route.params.session, lection: this.$route.params.lection, timepoint: 4, step: 0 } })

                                                    }

                                                } else {

                                                    // go to overview
                                                    this.$router.push({ name: 'client-overview'})

                                                }

                                            })

                                        }

                                    })

                                } else {

                                    var new_detail_data = {

                                        "module_nr": parseInt(this.$route.params.module),
                                        "session_nr": parseInt(this.$route.params.session),
                                        "lession_nr": parseInt(this.$route.params.lection),

                                    }

                                    if (!is_dev) {

                                        ClientApi.PatchDetailedProgress(new_detail_data).then(() => {

                                            // check if post-questionnaires have to be filled in

                                            //timepoint 1 == pre; timepoint 2 == post
                                            var endpoint = 'api-wagtail/get-assessment/?module_nr=' + this.$route.params.module + '&session_nr=' + this.$route.params.session + '&lection_nr=' + this.$route.params.lection + '&timepoint=' + 4

                                            axios.get(endpoint)

                                            .then(resp => {

                                                if (resp.data.questionnaires.length != 0) {

                                                    if (this.$store.getters['store_client/get_client'].is_dev) {

                                                            this.timepoint_for_assessment = 4

                                                            this.dialog_dev = true

                                                    } else {

                                                        // set page allowed to true
                                                        console.log('UPDATE_PAGE_ALLOWED: client_intervention')
                                                        this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

                                                        this.$router.push({ name: 'client-assessment', params: { module: this.$route.params.module, session: this.$route.params.session, lection: this.$route.params.lection, timepoint: 4, step: 0 } })

                                                    }

                                                } else {

                                                    // go to overview
                                                    this.$router.push({ name: 'client-overview'})

                                                }

                                            })

                                        })

                                    } else {

                                        // check if post-questionnaires have to be filled in

                                        //timepoint 1 == pre; timepoint 2 == post
                                        var endpoint = 'api-wagtail/get-assessment/?module_nr=' + this.$route.params.module + '&session_nr=' + this.$route.params.session + '&lection_nr=' + this.$route.params.lection + '&timepoint=' + 4

                                        axios.get(endpoint)

                                        .then(resp => {

                                            if (resp.data.questionnaires.length != 0) {

                                                if (this.$store.getters['store_client/get_client'].is_dev) {

                                                        this.timepoint_for_assessment = 4

                                                        this.dialog_dev = true

                                                } else {

                                                    // set page allowed to true
                                                    console.log('UPDATE_PAGE_ALLOWED: client_intervention')
                                                    this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

                                                    this.$router.push({ name: 'client-assessment', params: { module: this.$route.params.module, session: this.$route.params.session, lection: this.$route.params.lection, timepoint: 4, step: 0 } })

                                                }

                                            } else {

                                                // go to overview
                                                this.$router.push({ name: 'client-overview'})

                                            }

                                        })

                                    }

                                }

                            })

                        })

                    }

                }

            //
            // if it is NOT the last lection in session
            //
            } else {

                const data = {
                    'module_nr': parseInt(this.$route.params.module),
                    'session_nr': parseInt(this.$route.params.session),
                    'lesson_rank': parseInt(this.$route.params.lection)
                }

                ClientApi.SaveLectionData(data).then(() => {

                    const progress_data = {
                        'module_nr': parseInt(this.$route.params.module),
                        'session_nr': parseInt(this.$route.params.session),
                        'lesson_rank': parseInt(this.$route.params.lection) + 1,
                        "intervention_started": true,
                        "intervention_finished": this.$store.getters['store_client/get_client_progress'].intervention_finished,
                        "introduction_finished": this.$store.getters['store_client/get_client_progress'].introduction_finished
                    }

                    ClientApi.SaveInterventionProgress(progress_data).then(() => {

                        var new_detail_data = {

                            "module_nr": parseInt(this.$route.params.module),
                            "session_nr": parseInt(this.$route.params.session),
                            "lession_nr": parseInt(this.$route.params.lection),

                        }


                        // ToDo: Save detailed progress
                        if (!is_dev) {
                            ClientApi.PatchDetailedProgress(new_detail_data).then(() => {

                                // set page allowed to true
                                console.log('UPDATE_PAGE_ALLOWED: client_intervention')
                                this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

                                // go to overview
                                this.$router.push({ name: 'client-intervention', params: { module: parseInt(this.$route.params.module), session: parseInt(this.$route.params.session), lection: parseInt(this.$route.params.lection) + 1 } })

                            })

                        } else {

                            // set page allowed to true
                            console.log('UPDATE_PAGE_ALLOWED: client_intervention')
                            this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

                            // go to overview
                            this.$router.push({ name: 'client-intervention', params: { module: parseInt(this.$route.params.module), session: parseInt(this.$route.params.session), lection: parseInt(this.$route.params.lection) + 1 } })

                        }

                    })

                })

            }

        }
    }
}
</script>

<style scoped>
.timeline-title {
  border-style: solid;
  border-width: 1px;
  border-radius:15px !important;
  border-color: #004b8c !important;
  padding: 10px;
  width: 100%;
  margin-left: 68px;

}

#cardbadge { 
  float: left;
  left: -25px;
  top: -25px; 
}

#timeline {
  position: relative;
  padding: 0 10px;
}

#timeline-mobile {
  position: relative;
  padding: 0 10px;
}

#timeline-last {
  width: 100%;
  position: relative;
  padding: 0 10px;
  margin-top: 20px;
}

#timeline-last-mobile {
  width: 100%;
  position: relative;
  padding: 0 10px;
  margin-top: 20px;
}

/* line between icons */
#timeline:before {
  content: "";
  width: 2px;
  height: var(--timeline-before-height);
  background: #616161;
  /* background: #1976D2; */
  left: 10px;
  top: 90px;
  position: absolute;
  
}

#timeline:after {
  content: "";
  clear: both;
  display: table;
  width: 0%;
}

#timeline-last .timeline-last-item {
  margin-bottom: 10px;
  position: relative;
}

#timeline-last .timeline-last-item .timeline-last-content {
  width: 100%;
  margin-left: 68px;
}

#timeline-last-mobile .timeline-last-item {
  margin-bottom: 10px;
  position: relative;
}

#timeline-last-mobile .timeline-last-item .timeline-last-content {
  width: 100%;
}

#timeline-last:before {
  content: "";
  width: 2px;
  height: 100%;
  background: #616161;
  /* background: #1976D2; */
  left: 10px;
  bottom: 50px;
  position: absolute;
}
</style>