<template>
    <div>

        <!-- explanation -->
        <v-row v-if="!guided_finished">
            <v-col sm="12">
                <v-card color="transparent" raised>
                    <v-card-text>

                        <div class="title  mb-2"><span><v-icon large class="mr-3">far fa-bell</v-icon></span>Erklärung</div>

                        <div 
                            class="subtitle-1  mb-0" 
                            v-html="data.explanation"
                        ></div>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- explanation -->
        <v-row v-if="guided_finished">
            <v-col sm="12">
                <v-card color="transparent" raised>
                    <v-card-text>

                        <div class="title  mb-2"><span><v-icon large class="mr-3">far fa-compass</v-icon></span>Ziel</div>

                        <div 
                            class="subtitle-1  mb-0" 
                            v-html="data.goal"
                        ></div>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- instruction -->
        <v-row v-if="guided_finished">
            <v-col sm="12">
                <v-card color="transparent" raised>
                    <v-card-text>
                        
                            
                            <div class="title  mb-2"><span><v-icon large class="mr-3">far fa-clipboard</v-icon></span>Anleitung</div>

                            <div 
                                class="subtitle-1  mb-0" 
                                v-html="data.instruction"
                            ></div>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        
        <!-- type: data -->
        <v-row>

            <v-col sm="12">

                <v-card color="transparent" raised>
                    <v-card-text>

                        <v-row class="mt-5 mb-3" v-if="showError">
                            <v-alert
                                :value="showError"
                                color="error"
                                icon="fas fa-exclamation-triangle"
                                outlined
                            >
                                {{ infoMessage }}
                            </v-alert>
                        </v-row>

                        <!-- {{dataType}} -->

                        <!-- TASKS -->
                        <div v-if="dataType == 'multipleChoicePickNFormPlus'">
                            <multipleChoicePickNFormPlus-data :data = "fetchedData"/>
                        </div>

                        <div v-if="dataType == 'multipleChoicePickNForm'">
                            <multipleChoicePickNForm-data :data = "fetchedData"/>
                        </div>

                        <div v-if="dataType == 'multipleChoicePickOneFormPlus'">
                            <multipleChoicePickOneFormPlus-data :data = "fetchedData"/>
                        </div>

                        <div v-if="dataType == 'multipleChoicePickOneForm'">
                            <multipleChoicePickOneForm-data :data = "fetchedData"/>
                        </div>

                        <div v-if="dataType == 'freeTextForm'">
                            <free-text-form-data :data = "fetchedData"/>
                        </div>

                        <div v-if="dataType == 'rightWrongForm'">
                            <right-wrong-form-data :data = "fetchedData"/>
                        </div>

                        <div v-if="dataType == 'proContraInputTask'">
                            <proContraInputTask-data :data = "fetchedData"/>
                        </div>

                        <div v-if="dataType == 'traditionalLikertScaleForm'">
                            <traditional-likert-scale-form-data :data = "fetchedData"/>
                        </div>

                        <div v-if="dataType == 'collectKeywordsForm'">
                            <collect-keywords-form-data :data = "fetchedData"/>
                        </div>

                        <div v-if="dataType == 'defaultHierarchyAssignmentForm'">
                            <default-hierarchy-assignment-form-data :data = "fetchedData"/>
                        </div>

                        <div v-if="dataType == 'fourFieldInputForm'">
                            <four-field-input-form-data :data = "fetchedData"/>
                        </div>

                        <div v-if="dataType == 'dataHierarchyAssignmentForm'">
                            <data-hierarchy-assignment-form-data :data = "fetchedData"/>
                        </div>

                        <!-- TASKS -->
                        <div v-if="dataType == 'proContraAssignmentTask'">
                            <pro-contra-assignment-task-data :data = "fetchedData"/>
                        </div>

                        <div v-if="dataType == 'multipleChoicePickNTask'">
                            <multiple-choice-pick-n-task-data :data = "fetchedData"/>
                        </div>

                        <div v-if="dataType == 'fillInTheGapAssignmentTask'">
                            <fill-in-the-gap-assignment-task-data :data = "fetchedData"/>
                        </div>

                        <div v-if="dataType == 'simpleAssignmentTask'">
                            <simple-assignment-task-data :data = "fetchedData"/>
                        </div>

                        <div v-if="dataType == 'fourFieldAssignmentTask'">
                            <four-field-assignment-task-data :data = "fetchedData"/>
                        </div>

                        </v-card-text>
                    </v-card>
                </v-col>

        </v-row>

        <!-- check -->
        <v-row>
            <v-col sm="12" cols="12">
                <v-card color="transparent" raised height="100%">
                    <v-card-text>
                        <v-row  align="center" class="ma-2">
                            <div v-if="!thisBtnDisabled">
                                <v-icon medium>fas fa-pencil-alt</v-icon>
                            </div>
                            <div v-else>
                                <v-icon medium>fas fa-check</v-icon>
                            </div>
                            <v-divider class="mx-4"/>
                            <v-btn 
                                rounded
                                outlined 
                                large 
                                color="primary" 
                                @click="save"
                                :disabled="thisBtnDisabled"
                            >Fertig</v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- DEV DIALOG -->
        <v-dialog v-model="dialog" persistent max-width="320">

            <v-card>
                <v-card-title class="headline">Bitte beachten Sie:</v-card-title>
                <v-card-text>
                    Da dieser Klient als Development-Klient definiert ist, haben sie die Wahl, 
                    ob sie die Daten speichern möchten oder nicht. Solange Sie an dieser Lektion 
                    arbeiten, wird empfohlen, die Daten <b>nicht</b> zu speichern.
                </v-card-text>
                <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="primary" text @click="doNotSave()">Nicht speichern</v-btn>
                <v-btn color="primary" text @click="saveDev()">Speichern</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

    </div>
</template>


<script>
import axios from 'axios'
import _ from 'lodash'

import ClientApi from '@/_api/client_api'

import HelperClientIntervention from '@/_helpers/helper_client_intervention'

// forms
import multipleChoicePickNFormPlusData from '@/components/_client/_intervention/_basicDataBlocks/multipleChoicePickNFormPlusData.vue'
import multipleChoicePickNFormData from '@/components/_client/_intervention/_basicDataBlocks/multipleChoicePickNFormData.vue'
import multipleChoicePickOneFormPlusData from '@/components/_client/_intervention/_basicDataBlocks/multipleChoicePickOneFormPlusData.vue'
import multipleChoicePickOneFormData from '@/components/_client/_intervention/_basicDataBlocks/multipleChoicePickOneFormData.vue'
import freeTextFormData from '@/components/_client/_intervention/_basicDataBlocks/freeTextFormData.vue'
import rightWrongFormData from '@/components/_client/_intervention/_basicDataBlocks/rightWrongFormData.vue'
//import sliderLikertScaleFormData from '@/components/_client/_intervention/_basicDataBlocks/sliderLikertScaleFormData.vue'
import traditionalLikertScaleFormData from '@/components/_client/_intervention/_basicDataBlocks/traditionalLikertScaleFormData.vue'
import proContraInputTaskData from '@/components/_client/_intervention/_basicDataBlocks/proContraInputTaskData.vue'
import collectKeywordsFormData from '@/components/_client/_intervention/_basicDataBlocks/collectKeywordsFormData.vue'
import defaultHierarchyAssignmentFormData from '@/components/_client/_intervention/_basicDataBlocks/defaultHierarchyAssignmentFormData.vue'
import fourFieldInputFormData from '@/components/_client/_intervention/_basicDataBlocks/fourFieldInputFormData.vue'
import dataHierarchyAssignmentFormData from '@/components/_client/_intervention/_basicDataBlocks/dataHierarchyAssignmentFormData.vue'

// tasks
//import rightWrongTaskData from '@/components/_client/_intervention/_basicDataBlocks/rightWrongTaskData.vue'
import proContraAssignmentTaskData from '@/components/_client/_intervention/_basicDataBlocks/proContraAssignmentTaskData.vue'
import multipleChoicePickNTaskData from '@/components/_client/_intervention/_basicDataBlocks/multipleChoicePickNTaskData.vue'
//import multipleChoicePickOneTaskData from '@/components/_client/_intervention/_basicDataBlocks/multipleChoicePickOneTaskData.vue'
import fillInTheGapAssignmentTaskData from '@/components/_client/_intervention/_basicDataBlocks/fillInTheGapAssignmentTaskData.vue'
import simpleAssignmentTaskData from '@/components/_client/_intervention/_basicDataBlocks/simpleAssignmentTaskData.vue'
import fourFieldAssignmentTaskData from '@/components/_client/_intervention/_basicDataBlocks/fourFieldAssignmentTaskData.vue'

export default {

    name: 'basicData',

    props: ['data', 'id', 'guided_task_data', 'guided_finished'],

    components: {
      multipleChoicePickNFormPlusData,
      multipleChoicePickNFormData,
      multipleChoicePickOneFormPlusData,
      multipleChoicePickOneFormData,
      freeTextFormData,
      rightWrongFormData,
      //sliderLikertScaleFormData,
      traditionalLikertScaleFormData,
      proContraInputTaskData,
      collectKeywordsFormData,
      defaultHierarchyAssignmentFormData,
      fourFieldInputFormData,
      dataHierarchyAssignmentFormData,

      //rightWrongTaskData,
      proContraAssignmentTaskData,
      //multipleChoicePickOneTaskData,
      multipleChoicePickNTaskData,
      fillInTheGapAssignmentTaskData,
      simpleAssignmentTaskData,
      fourFieldAssignmentTaskData
      
    },

    data () {
        return {

            fetchedData: null,
            dataType: null,

            showError: false,
            infoMessage: 'Es existieren keine Daten zur Übung mit der ID xxx in der Datenbank.',

            dialog: false,

            dev_save: false,

            dev_checked: false,

            ready: false

        }
    },

    created () {

        if (!this.guided_finished) {

            // get client lession data
            var e1 = '/client/client-lection-data/?module_nr=' + this.data.task_module_nr  
            var e2 = '&session_nr=' + this.data.task_session_nr
            var e3 = '&lession_nr=' + this.data.task_lesson_nr
            var e4 = '&dataIdentifier=' + this.data.taskIdentifier
            var endpoint = e1 + e2 + e3 + e4

            axios.get(endpoint)
            
            .then((response) => {

                if (_.isEmpty(response.data[0].task_data[this.data.taskIdentifier])) {

                    this.showError = true
                    this.infoMessage = "Es existieren keine Daten zur Übung mit der ID " + this.data.taskIdentifier + '!'

                    this.ready = true
                    
                } else {

                    // check if wagtail_task_data exists --> guided task

                    if (_.isEmpty(response.data[0].task_data[this.data.taskIdentifier].wagtail_task_data)) {

                        // no guided task

                        this.fetchedData = response.data[0].task_data[this.data.taskIdentifier]
                        this.dataType = response.data[0].task_data[this.data.taskIdentifier].type

                        this.showError = false

                        this.ready = true

                    } else {

                        // guided task

                        ClientApi.GetGuidedTaskData(this.data.taskIdentifier).then((resp) => {

                            // check if guided task already finished
                            if (resp.data.revision_finished) {
                                this.fetchedData = resp.data.final_task_data
                                this.dataType = resp.data.final_task_data.type
                            } else {

                                // bei Revision 0 gibt es noch keine neuen Daten
                                if (resp.data.latest_message.revision_number == 0) {

                                    this.fetchedData = response.data[0].task_data[this.data.taskIdentifier]
                                    this.dataType = response.data[0].task_data[this.data.taskIdentifier].type

                                } else {

                                    // wenn die letzte Nachricht von Klient selbst ist
                                    if (resp.data.latest_message.sender.username == this.$store.getters['store_auth/get_user_username']) {
                                        this.fetchedData = resp.data.latest_message.current_task_data
                                        this.dataType = resp.data.latest_message.current_task_data.type
                                    } else {

                                        var current_revision = resp.data.latest_message.revision_number

                                        var correct_latest_message = {}

                                        for (var k = 0; k < resp.data.all_messages.length; k++) {

                                            if (resp.data.all_messages[k].revision_number == current_revision && resp.data.all_messages[k].sender.username == this.$store.getters['store_auth/get_user_username']) {
                                                correct_latest_message = resp.data.all_messages[k]
                                            }

                                        }

                                        this.fetchedData = correct_latest_message.current_task_data
                                        this.dataType = correct_latest_message.current_task_data.type

                                    }
                                    

                                }
                                
                            }

                            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                            this.ready = true

                        })
                    }

                }

            })
            .catch(error => {
                this.error = true
                this.errorMessage = error
            })

        } else {

            this.fetchedData = this.guided_task_data.final_task_data
            this.dataType = this.data.task[0].type

            this.showError = false

            this.ready = true

        }
        
    },

    computed: {
        
        thisBtnDisabled () {

            var tmp = this.$store.getters['store_lection/get_saved_lection_data']
            return tmp.data[this.id].btn_disabled

        },    

    }, 

    methods: {

        // DEV ---
        doNotSave() {

            this.dev_save = false
            this.dev_checked = true
            this.dialog = false

            this.save()

        },

        saveDev() {

            this.dev_save = true
            this.dev_checked = true
            this.dialog = false

            this.save()

        },

        // DEV END ---

        // save data
        save() {

        

           if (this.$store.getters['store_client/get_client'].is_dev && this.dev_checked == false) {

               this.dialog = true

           } else if (this.$store.getters['store_client/get_client'].is_dev && this.dev_checked == true) {

               if (this.dev_save == true) {

                   const data = {
                        'save_lection_data': true,
                        'module': this.$route.params.module,
                        'session': this.$route.params.session,
                        'lection': this.$route.params.lection,
                        'id': this.id,
                        'type': 'info',
                        'reward': parseInt(this.data.reward) 
                    }

                    HelperClientIntervention.InterventionNext(data).then(() => {


                    })


               } else {

                   const data = {
                        'save_lection_data': false,
                        'module': this.$route.params.module,
                        'session': this.$route.params.session,
                        'lection': this.$route.params.lection,
                        'id': this.id,
                        'type': 'info',
                        'reward': parseInt(this.data.reward) 
                    }


                    HelperClientIntervention.InterventionNext(data).then(() => {

                        console.log('NEXT')

                    })

               }

           } else {

               const data = {
                   'save_lection_data': true,
                   'module': this.$route.params.module,
                   'session': this.$route.params.session,
                   'lection': this.$route.params.lection,
                   'id': this.id,
                   'type': 'info',
                   'reward': parseInt(this.data.reward) 
               }

               HelperClientIntervention.InterventionNext(data).then(() => {

               })

           }

       }

    }
}
</script>

