<template>

    <div>

        <v-row v-if="loaded">

            <v-card
                color="grey lighten-5"
                flat
                height="auto"
                min-height="250px"
                tile
                width="100%"
                id="fooAnchor"
            >

                <v-card flat color="grey lighten-5">

                    <v-card-text class="py-0">

                        <v-row>

                            <v-col cols="12" sm="12" md="6">

                                <div class="title black--text mt-3">
                                    <v-icon color="primary" class="mr-3">fas fa-archive</v-icon>
                                    Archivierte Chats
                                </div>

                            </v-col>

                            <v-col cols="12" sm="12" md="6">

                                <v-text-field
                                v-model="search_archive"
                                label="Chats durchsuchen (Absender, Betreff und Inhalt)"
                                flat
                                hide-details
                                clearable
                                prepend-icon="fas fa-search"
                                clear-icon="mdi-close-circle-outline"
                            ></v-text-field>

                            </v-col>

                        </v-row>

                    </v-card-text>

                </v-card>

                <v-divider />

                <v-list 
                    color="grey lighten-5"
                    v-if="filteredArchivedChats.length > 0"
                >
                    <v-list-item-group>
                                                            

                        <v-list-item 
                            v-for="item in filteredArchivedChats"
                            :key="item.id"
                            three-line
                            @click="openChat(item)"
                            
                        >

                            <v-list-item-avatar>
                                <v-icon v-if="checkIfUnread(item)" color="primary">fas fa-envelope</v-icon>
                                <v-icon v-else>fas fa-envelope-open</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>
                                    <b>Betreff: </b>{{ item.subject }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <span class='primary--text font-weight-bold'>{{ convertedSender(item.latest_message.sender) }}</span> &mdash; {{ item.latest_message.content }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle class="caption">
                                    {{ get_converted_time(item.latest_message.sent_at) }}
                                </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                                <v-chip color="accent" class="black--text">{{ getChatPartnerName(item) }}</v-chip>
                            </v-list-item-icon>

                        </v-list-item>


                    </v-list-item-group>

                </v-list>

                <v-alert
                    v-else
                    icon="fas fa-info-circle"
                    color="primary"
                    
                    class="mt-3 mx-3"
                    outlined
                > 
                    <span v-if="search_archive !=null">Es existieren keine archivierten Chats mit <i>{{search_archive}}</i> als Absender oder im Betreff oder im Inhalt einer Nachricht.</span>
                    <span v-else>Es existieren keine archivierten Chats.</span>
                </v-alert>

            </v-card>

        </v-row>

    </div>

</template>

<script>
import ClientApi from '@/_api/client_api'
import DateTimeHelpers from '@/_helpers/date_time_helpers'

export default {
    name: 'client-messages-archived',

    data: () => ({

        loaded: false,
        items_archived: [],
        search_archive: null,

    }),

    created() {

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        ClientApi.GetUserThreadsDeletedAPI().then((resp) => {

            this.items_archived = resp.data

            if (this.$route.params.search_prop == 'null') {
                this.search_archive = null
            } else {
                this.search_archive = this.$route.params.search_prop
            }

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

            this.loaded = true

        })

    },

    watch: {

        '$route' () {

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

            ClientApi.GetClientInfo().then(() => {

                ClientApi.GetUserThreadsDeletedAPI().then((resp) => {

                    this.items_archived = resp.data

                    if (this.$route.params.search_prop == 'null') {
                        this.search_archive = null
                    } else {
                        this.search_archive = this.$route.params.search_prop
                    }

                    this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                    this.loaded = true

                })

            })

        },

        'search_archive' () {

            if (this.search_archive == '' || this.search_archive == ' ' || this.search_archive == '  ') {
                this.search_archive = null
            }
        }

    },

    computed: {

        usergroup() {
            return this.$store.getters['store_auth/get_user_group']
        },

        filteredArchivedChats() {

            var filtered = []

            if (this.search_archive != null) {

                for (var i=0; i < this.items_archived.length; i++) {
                    

                    this.items_archived[i].users.forEach(element => {

                        var tmp = ''

                        if (this.search_archive.match(/coach/g) || this.search_archive.match(/mein/g) || this.search_archive.match(/coach/g)) {
                            tmp = this.$store.getters['store_client/get_client_coach'].username
                        }

                        var sv_fullname = this.$store.getters['store_client/get_client_supervisor'].firstname + ' ' + this.$store.getters['store_client/get_client_supervisor'].last_name
                        var sv_firstname = this.$store.getters['store_client/get_client_supervisor'].firstname
                        var sv_lastname = this.$store.getters['store_client/get_client_supervisor'].last_name

                        if (this.search_archive.includes(sv_fullname) || this.search_archive.includes(sv_firstname) || this.search_archive.includes(sv_lastname) || this.search_archive.includes(sv_fullname.toLowerCase()) || this.search_archive.includes(sv_firstname.toLowerCase()) || this.search_archive.includes(sv_lastname.toLowerCase())) {
                            tmp = this.$store.getters['store_client/get_client_supervisor'].username
                        }

                        if (element.username === tmp) {

                            if (!filtered.includes(this.items_archived[i])) {
                                filtered.push(this.items_archived[i]);  
                            }

                        }

                    })

                    // search_archive in subject
                    if (this.items_archived[i].subject.toLowerCase().includes(this.search_archive.toLowerCase())) {

                        if (!filtered.includes(this.items_archived[i])) {
                            filtered.push(this.items_archived[i]);  
                        }

                    }

                    // search_archive content
                    this.items_archived[i].all_messages.forEach(element => {

                        if (element.content.toLowerCase().includes(this.search_archive.toLowerCase())) {

                            if (!filtered.includes(this.items_archived[i])) {
                                filtered.push(this.items_archived[i]);  
                            }
                            
                        }

                    })

                }

            } else {

                filtered = this.items_archived

            }

            return filtered
            
        },

    },

    methods: {

        getChatPartnerName(item) {

            var partner = ''
            
            item.users.forEach(element => {

                if (element.username != this.$store.getters['store_auth/get_user_username']) {

                    partner = element.username

                    if (this.$store.getters['store_client/get_client_coach'].id == element.id) {

                        if (this.$store.getters['store_client/get_client_coach'].coach_gender == 1) {
                            partner = 'Coach'
                        } else {
                            partner = 'Coachin'
                        }
                    } else if (this.$store.getters['store_client/get_client_supervisor'].id == element.id) {
                       partner = this.$store.getters['store_client/get_client_supervisor'].firstname + ' ' + this.$store.getters['store_client/get_client_supervisor'].last_name
                    }

                } 
                
            });

            return partner

        },

        convertedSender(sender) {

            if (sender.username == this.$store.getters['store_auth/get_user_username']) {

                return 'Ich'

            } else {

                if (this.$store.getters['store_client/get_client_coach'].id == sender.id) {
                    if (this.$store.getters['store_client/get_client_coach'].coach_gender == 1) {
                        return 'Coach'
                    } else {
                        return'Coachin'
                    }
                } else if (this.$store.getters['store_client/get_client_supervisor'].id == sender.id) {
                    return this.$store.getters['store_client/get_client_supervisor'].firstname + ' ' + this.$store.getters['store_client/get_client_supervisor'].last_name
                } else {
                    return sender.username
                }

            }

        },

        openChat(item) {

            var data = {
                'current_thread_id': item.id,
            }

            ClientApi.SetThreadAsRead(data).then(() => {

                this.$router.push({ name: 'client-messages-detailed', params: { thread_id: item.id }})

            })

        },

        checkIfUnread(item) {

            var val = false

            item.user_threads.forEach(element => {

                if (element.user.username == this.$store.getters['store_auth/get_user_username']) {

                    if (element.unread) {

                        val = true

                    }
                }
                
            });

            return val

        },

        is_archived(item) {

            var tmp_archived = false

            for (var i = 0; i < item.user_threads.length; i++) {

                if (item.user_threads[i].deleted == true && item.user_threads[i].user.username == this.$store.getters['store_auth/get_user_username']) {

                    tmp_archived = true

                }
            }

            return tmp_archived

        },

        get_converted_time(item) {

            return DateTimeHelpers.convertDatabaseDateTimeString('full', item)
        },

        getCorrectDateTime(item) {
            return DateTimeHelpers.convertDatabaseDateTimeString('full', item)
        },

    },

}
</script>

<style scoped>

</style>