<template>

    <div class="mx-3">

        <v-row>

            <v-col cols="12" sm="12">

                <v-card
                    class="mx-auto"
                    outlined
                    min-height="120px"
                >

                    <v-card-text class="text-center headline primary--text font-weight-bold">Meine Reihenfolge:</v-card-text>

                    <v-divider />
                    
                    <v-card-text>

                        <v-row justify="center" align="center">

                            <v-col 
                                cols="12" 
                                sm="12"
                                v-for="(item, i) in data.hierarchyList"
                                :key="i"
                            >

                                <v-card class="mx-3" outlined flat>

                                    <v-card-text class="text-center">

                                        <v-avatar color="accent" size="36" class="mb-3">
                                            <b>{{i + 1}}</b>
                                        </v-avatar>  

                                        <v-card>
                                            <v-alert outlined color="primary" class="pa-0">
                                                <v-card-text class="text-center body-1 pa-2">{{ data.hierarchyList[i].assignmentText }}</v-card-text>
                                            </v-alert>
                                        </v-card>
                                        
                                    </v-card-text>

                                    
                                </v-card>

                            </v-col>

                        </v-row>

                    </v-card-text>

                </v-card>

            </v-col>

        </v-row>

    </div>

</template>

<script>
export default {
    name: 'default-hierarchy-assignment-form-data',
    props: ['data'],
}
</script>

<style scoped>

</style>


