<template>
    <v-container>

        <v-row justify="center">

            <v-card flat width="80%">
                <v-alert outlined color="primary" class="pa-0">

                    <v-card flat>

                        <v-card-text class="text-center primary--text display-3">
                            Leider nicht möglich.
                        </v-card-text>

                        <v-card-text class="text-center black--text">
                            Sie können diese Seite nicht über eine direkte Eingabe der URL ansteuern oder diese Seite über den Browser neu laden.
                        </v-card-text>

                        <v-card-text class="text-center">
                            Da es sich bei @myTabu um eine wissenschaftliche Studie handelt, ist es leider noch nicht möglich, dass Sie frei 
                            auswählen, welche Inhalte des Online-Programms Sie bearbeiten oder welchen der Fragebögen Sie beantworten wollen. 
                            Es ist für die Aussagekraft der wissenschaftlichen Studie wichtig, dass alle Teilnehmer*innen die Lektionen in der gleichen 
                            Reihenfolge bearbeiten. Ebenso ist es notwendig, die Fragebögen in der vorgesehenen Reihenfolge zu beantworten. Vielen 
                            Dank für Ihr Verständnis!

                            <p>Bitte gehen Sie um weiterzumachen zurück auf die Startseite oder wählen Sie im Menü den entsprechenden Link aus.</p>
                        </v-card-text>

                    </v-card>

                </v-alert>
            </v-card>

        </v-row>

    </v-container>
</template>

<script>
export default {

    data() {
        return {

        }
    },
    
    created() {
        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)
    },

}
</script>