<template>
    <v-container fluid v-if="loaded">

        <!-- KLIENTEN -->

        <v-card flat v-if="is_coach_home">
        
            <v-card-title>
                Alle Klienten
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="fas fa-search"
                    label="Suche"
                    single-line
                    hide-details
                ></v-text-field>
            </v-card-title>
            
            <v-data-table
                :headers="headers"
                :items="clients"
                :search="search"
            >

                <template v-slot:item.username="{ item }">
                    <span class="subtitle-2">{{ item.username }}</span>
                </template>

                <template v-slot:item.date_joined="{ item }">
                    <span class="subtitle-2">{{ getCorrectDate(item.date_joined) }}</span>
                </template>

                <template v-slot:item.status="{ item }">
                    <span class="subtitle-2" v-if="item.status == 1">Registriert</span>
                    <span class="subtitle-2" v-if="item.status == 2">Aktiv</span>
                    <span class="subtitle-2" v-if="item.status == 3">Inaktiv</span>
                    <span class="subtitle-2" v-if="item.status == 4">Abgeschlossen</span>
                    <span class="subtitle-2" v-if="item.status == 5">Ausgeschlossen</span>
                </template>

                <template v-slot:item.client_new_messages="{ item }">
                    <v-badge
                        :color="item.client_new_messages > 0 ? 'error' : 'success'"
                        dot
                        
                        bordered
                        overlap
                    >
                        <v-icon
                            color="primary"
                            @click="goToChats(item)"
                        >
                            fas fa-comments
                        </v-icon>
                    </v-badge>
                </template>

                <template v-slot:item.client_new_tasks="{ item }">
                    <v-badge
                        :color="item.client_new_tasks > 0 ? 'error' : 'success'"
                        dot
                        
                        bordered
                        overlap
                    >
                        <v-icon
                            color="primary"
                            @click="goToTasks(item)"
                        >
                            fas fa-tasks
                        </v-icon>
                    </v-badge>
                </template>

                <template v-slot:item.last_login="{ item }">
                    <span class="subtitle-2">{{ getCorrectDateTime(item.last_login) }}</span>

                    <span class="ml-3" v-if="item.show_remember_btn && !item.email_sent_last_month">

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">

                                <v-btn 
                                    icon 
                                    color="error" 
                                    @click="send_client_has_to_login_mail(item)"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>
                                        fas fa-envelope
                                    </v-icon>
                                </v-btn>

                            </template>
                            <span>Eine Erinnerungsmail an den Bewährungshelfenden schicken.</span>
                            <span v-if="item.login_remember_emails_count == 0"> Bisher wurde noch keine Erinnerungsmail verschickt.</span>
                            <span v-if="item.login_remember_emails_count >= 1">(Bisher wurden bereits {{ item.login_remember_emails_count }} Erinnerungsmails verschickt, zuletzt am {{ getCorrectDate(item.last_login_remember_email_sent_at) }}).</span>
                        </v-tooltip>

                    </span>

                    <span class="ml-3" v-if="!item.show_remember_btn && item.email_sent_last_month">

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">

                                <v-btn 
                                    icon 
                                    color="grey" 
                                    @click="send_client_has_to_login_mail(item)"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>
                                        fas fa-envelope
                                    </v-icon>
                                </v-btn>

                            </template>
                            <span>Am {{ getCorrectDate(item.last_login_remember_email_sent_at) }} wurde zuletzt eine Email an den Bewährungshelfenden geschickt (Insgesamt wurden bereits {{ item.login_remember_emails_count }} Erinnerungsmails verschickt).</span>
                        </v-tooltip>

                    </span>

                </template>

                <template v-slot:item.client_progress_module="{ item }">
                    <span class="subtitle-2">{{ item.client_progress_module }}</span>
                </template>

                <template v-slot:item.client_progress_session="{ item }">
                    <span class="subtitle-2">{{ item.client_progress_session }}</span>
                </template>

                <template v-slot:item.client_progress_lection="{ item }">
                    <span class="subtitle-2">{{ item.client_progress_lection }}</span>
                </template>

                <template v-slot:no-data>

                    <v-alert
                        outlined
                        type="error"
                        prominent
                        border="left"
                        class="mt-3"
                    >
                        Sie haben noch keine Klienten*Klientinnen.
                    </v-alert>

                </template>

            </v-data-table>

        </v-card>


        <router-view v-else />

        <!-- Sen Email Dialog -->
        <v-dialog
            v-model="dialog_send_remember_email"
            width="500"
        >

            <v-card>

                <v-card-title
                    class="headline"
                >
                    Email an den Bewährungshelfer senden
                </v-card-title>

                <v-divider class="mb-5"/>

                <v-card-text v-if="current_client.show_remember_btn && !current_client.email_sent_last_month">
                    Wenn Sie auf "Email senden" klicken, wird eine Email an den zuständigen Bewährungshelfenden des*der Klienten*Klientin <i>{{ current_client.username }}</i> 
                    geschickt, in der der Bewährungshelfende gebeten wird abzuklären, weshalb sich der*die Klient*in seit (mehr) als vier Wochen nicht angemeldet hat.
                </v-card-text>

                <v-card-text v-if="!current_client.show_remember_btn && current_client.email_sent_last_month">
                    An den zuständigen Bewährungshelfer des*der Klienten*Klientin <i>{{ current_client.username }}</i> wurde bereits eine Email versendet, um Ihn darauf hinzuweisen,
                    dass sich der*die Klient*in seit (mehr) als vier Wochen nicht mehr angemeldet hat.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>

                    <v-spacer v-if="!current_client.show_remember_btn && current_client.email_sent_last_month" />

                    <v-btn
                        outlined
                        @click="dialog_send_remember_email_close()"
                    >
                        Schließen
                    </v-btn>

                    <v-spacer v-if="current_client.show_remember_btn && !current_client.email_sent_last_month" />

                    <v-btn
                        v-if="current_client.show_remember_btn && !current_client.email_sent_last_month"
                        color="primary"
                        outlined
                        @click="dialog_send_remember_email_finally()"
                        :loading="loading"
                        :disabled="loading"
                    >
                        Email senden
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>

</template>

<script>
import CoachApi from '@/_api/coach_api'
import DateTimeHelpers from '@/_helpers/date_time_helpers'

export default {
    name: 'coach-home',

    data: () => ({

        loaded: false,

        dialog_send_remember_email: false,

        current_client: {},

        loading: false,

        search: '',

        clients: [],

        headers: [
          {
            text: 'Klient*in',
            align: 'center',
            sortable: false,
            value: 'username',
            class: "primary--text subtitle-1 font-weight-bold"
          },
          { 
              text: 'Chats', 
              value: 'client_new_messages',
              align: 'center',
              class: "primary--text subtitle-1 font-weight-bold"
          },
          { 
              text: 'Übungen', 
              value: 'client_new_tasks',
              align: 'center',
              class: "primary--text subtitle-1 font-weight-bold"
          },
          { 
              text: 'Eingeschlossen', 
              value: 'date_joined',
              align: 'center',
              class: "primary--text subtitle-1 font-weight-bold"
          },
          { 
              text: 'Status', 
              value: 'status',
              align: 'center',
              class: "primary--text subtitle-1 font-weight-bold"
          },
          { 
              text: 'Letzter Login', 
              value: 'last_login',
              align: 'center',
              class: "primary--text subtitle-1 font-weight-bold"
          },
          { 
              text: 'Modul', 
              value: 'client_progress_module',
              align: 'center',
              class: "primary--text subtitle-1 font-weight-bold"
          },
          { 
              text: 'Sitzung', 
              value: 'client_progress_session',
              align: 'center',
              class: "primary--text subtitle-1 font-weight-bold"
          },
          { 
              text: 'Lektion', 
              value: 'client_progress_lection',
              align: 'center',
              class: "primary--text subtitle-1 font-weight-bold"
          },
        ],

    }),

    created() {

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        CoachApi.GetCoachInfo().then(() => {

            CoachApi.GetCoachAllClientsData().then((resp) => {

                this.clients = resp.data

                this.$store.dispatch('store_coach/UPDATE_COACH_CLIENTS', resp.data)

                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                this.loaded = true

            })

        })

    },

    watch: {

        '$route' () { 

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

            CoachApi.GetCoachInfo().then(() => {

                CoachApi.GetCoachAllClientsData().then((resp) => {

                    this.clients = resp.data

                    this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                    this.loaded = true

                })

            })
        }

    },

    computed: {

        update_date() {

            var currentdate = new Date();
            var hours = '00'
            var minutes = '00'
            var seconds = '00'

            if (currentdate.getHours() < 10) {
                hours = '0' + currentdate.getHours()
            } else {
                hours = currentdate.getHours()
            }

            if (currentdate.getMinutes() < 10) {
                minutes = '0' + currentdate.getMinutes()
            } else {
                minutes = currentdate.getMinutes()
            }

            if (currentdate.getSeconds() < 10) {
                seconds = '0' + currentdate.getSeconds()
            } else {
                seconds = currentdate.getSeconds()
            }


            return hours + ":" + minutes + ":" + seconds;

        },

        show_cookies_dialog() {

            return !this.$store.getters['store_coach/get_coach'].cookies_accepted

        },

        is_coach_home() {

            if (this.$route.path == '/coach') {

                return true

            } else {

                return false

            }

        },

    },

    methods: {

        getCorrectDateTime(item) {
            return DateTimeHelpers.convertDatabaseDateTimeString('full', item)
        },

        getCorrectDate(item) {
            return DateTimeHelpers.convertDatabaseDateTimeString('only_date', item)
        },

        getColorStatus (status) {
            if (status == 'aktiv') return 'success'
            else if (status == 'inaktiv') return 'error'
            else if (status == 'abgeschlossen') return 'accent'
            else return 'primary'
        },

        goToChats(item) {

            this.$router.push({ name: 'coach-messages', params: { search_prop: item.username } })

        },

        goToTasks(item) {

            this.$router.push({ name: 'coach-tasks', params: { search_prop: item.username} })
            
        },

        dialog_send_remember_email_close() {

            this.current_client = {}

            this.dialog_send_remember_email = false

        },

        send_client_has_to_login_mail(item) {

            this.current_client = item

            this.dialog_send_remember_email = true
        },

        dialog_send_remember_email_finally() {

            this.loading = true

            var data = {
                client_user_id: this.current_client.client_user_id
            }

            CoachApi.ReportCriticalEventDelay(data).then(() => {

                CoachApi.GetCoachAllClientsData().then((resp) => {

                    this.clients = resp.data

                    this.current_client = {}
                    this.dialog_send_remember_email = false
                    this.loading = false

                })

            })

        }

        
    }

}
</script>

<style scoped>

</style>
