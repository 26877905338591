<template>
    <div>

        <v-row v-if="loaded">

            <v-card
                color="grey lighten-5"
                flat
                height="auto"
                min-height="250px"
                tile
                width="100%"
                id="fooAnchor"
            >
                <v-card-text
                    color="grey lighten-5"
                >

                    <v-chip color="accent" class="black--text font-weight-bold">{{ getChatPartnerName(current_chat) }}</v-chip>

                    <div class="title black--text mt-3">
                        <b>{{ get_short_question(current_chat) }}</b><small > (Modul {{ current_chat.current_module_nr}} - Sitzung {{ current_chat.current_session_nr }} - Lektion {{ current_chat.current_lession_nr}})</small>
                    </div>

                </v-card-text>

                <v-divider />
                
                <!-- CONTENT (of TASK) -->
                <v-card-text>

                        <v-row>

                            <v-container fluid style="width: 100% !important;">

                                <v-row
                                    v-for="(msg, index) in current_chat.all_messages"
                                    :key="index"
                                >

                                    <v-col
                                        :cols="$vuetify.breakpoint.mdAndUp ? '10' : '12'"
                                        :sm="$vuetify.breakpoint.mdAndUp ? '10' : '12'"
                                        :offset-sm="msg.sender.username !== $store.getters['store_auth/get_user_username'] ? '0': '2'" 
                                    >

                                        <v-card outlined :id="'fooAnchor' + index + 5">

                                            <v-card-title>

                                                <v-chip 
                                                    :color="msg.sender.username !== $store.getters['store_auth/get_user_username'] ? 'accent' : 'primary'"
                                                    :class="msg.sender.username !== $store.getters['store_auth/get_user_username'] ? 'black--text' : 'white--text'"
                                                >
                                                    {{ convertedSender(msg.sender.username) }}
                                                </v-chip>

                                                <v-spacer />

                                                <v-tooltip bottom v-if="msg.sender.username !== $store.getters['store_auth/get_user_username'] && msg.critical_event">
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon color="error" dark v-on="on">far fa-bell</v-icon>
                                                    </template>
                                                    <span>Kritisches Ereignis wurde am {{ getCorrectDateTime(msg.critical_event_report_time) }} gemeldet.</span>
                                                </v-tooltip>

                                            </v-card-title>

                                            <v-divider />

                                            <v-card-text class='black--text' v-if="msg.revision_number != 0 & msg.sender.username !== $store.getters['store_auth/get_user_username']">

                                                <v-card outlined v-if="msg.content != 'null'">

                                                    <v-toolbar flat>

                                                        <v-toolbar-title class="font-weight-medium">
                                                            Nachricht
                                                        </v-toolbar-title>

                                                    </v-toolbar>

                                                    <v-divider />

                                                    <v-card-text>

                                                        <v-container fluid class="pa-0">

                                                            <v-row
                                                                align="center"
                                                                justify="start"
                                                                class="pa-0"
                                                            >

                                                                <v-col cols="12" sm="12" class="note py-0">
                                                                    <div class="body-1">{{ msg.content }}</div>
                                                                </v-col>

                                                            </v-row>

                                                        </v-container>

                                                    </v-card-text>

                                                </v-card>

                                            </v-card-text>

                                            <v-card-text class='black--text' v-if="msg.sender.username === $store.getters['store_auth/get_user_username']">

                                                <v-card outlined>

                                                    <v-card-title>
                                                        Nachricht
                                                    </v-card-title>

                                                    <v-divider />

                                                    <v-card-text>

                                                        <v-container fluid class="pa-0">

                                                            <v-row
                                                                align="center"
                                                                justify="start"
                                                                class="pa-0"
                                                            >

                                                                <v-col cols="12" sm="12" class="note py-0">
                                                                    <div class="body-1">{{ msg.content }}</div>
                                                                </v-col>

                                                            </v-row>

                                                        </v-container>

                                                    </v-card-text>

                                                </v-card>

                                            </v-card-text>

                                            <v-card-text v-if="msg.revision_number == 0 & msg.sender.username !== $store.getters['store_auth/get_user_username']">

                                                <GuidedTaskData 
                                                    :data="JSON.parse(current_chat.original_task_data)" 
                                                    :first="true"
                                                    :title="'Ursprüngliche Bearbeitung'"
                                                />

                                            </v-card-text>

                                            <v-card-text v-if="msg.revision_number != 0 & msg.sender.username !== $store.getters['store_auth/get_user_username']">

                                                <GuidedTaskData 
                                                    :data="msg.current_task_data" 
                                                    :first="false"
                                                    :title="'Revision ' + msg.revision_number"
                                                />

                                            </v-card-text>

                                            <v-card-subtitle v-if="msg.revision_number != 0">
                                                <small class='overline'>{{ getCorrectDateTime(msg.sent_at) }} | <b>Revision {{ msg.revision_number }}</b></small>
                                            </v-card-subtitle>

                                            <v-card-subtitle v-else>
                                                <small class='overline'>{{ getCorrectDateTime(msg.sent_at) }} | <b>Ursprüngliche Bearbeitung</b></small>
                                            </v-card-subtitle>

                                        </v-card>

                                    </v-col>

                                </v-row>

                            </v-container>

                        </v-row>

                        <v-divider class="my-3" v-if="!is_finished(current_chat) && current_chat.waiting_for_coach"></v-divider>

                        <v-card flat v-if="!is_finished(current_chat) && current_chat.waiting_for_coach">

                            <v-toolbar flat>

                                <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp && current_chat.latest_message.revision_number != 0">
                                    Feedback zu Revision Nr. {{ current_chat.latest_message.revision_number }}
                                </v-toolbar-title>

                                <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp && current_chat.latest_message.revision_number == 0">
                                    Feedback zur Ursprüngliche Bearbeitung
                                </v-toolbar-title>

                                <v-spacer v-if="$vuetify.breakpoint.smAndUp" />

                                <v-menu
                                    v-model="menu_help"
                                    :close-on-content-click="false"
                                    :nudge-width="350"
                                    :nudge-left="400"
                                    :nudge-bottom="45"
                                    bottom
                                    y-offset
                                    
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on" @click="get_coach_help">
                                            <v-icon>far fa-question-circle</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-card>

                                        <v-card-text class="title primary--text">Hinweise:</v-card-text>

                                        <v-card-text v-html="help_txt" class="body-1"></v-card-text>

                                        <v-card-text class="body-1 primary--text">
                                            <b>Beachten Sie bitte:</b> In der Regel müssen die Vorlagen noch individuell an die Antwort des*der Klient*in angepasst werden! Unterschiedliche Möglichkeiten werden in [...] angezeigt. Jedoch auch eine inhaltliche individuelle Anpassung sollte erfolgen.
                                        </v-card-text>

                                        <v-divider />

                                        <v-card-actions>
                                            <v-spacer></v-spacer>

                                            <v-btn text @click="menu_help = false" color="primary" outlined>Schließen</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>

                                <v-menu
                                    v-model="menu_supervisor"
                                    :close-on-content-click="false"
                                    :nudge-width="350"
                                    :nudge-left="400"
                                    :nudge-bottom="40"
                                    bottom
                                    y-offset
                                    
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on">
                                            <v-icon>fas fa-user-shield</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-card>

                                        <v-card-title>
                                            {{  $store.getters['store_coach/get_coach'].all_clients[getChatPartnerName(current_chat)].supervisor_first_name }} {{  $store.getters['store_coach/get_coach'].all_clients[getChatPartnerName(current_chat)].supervisor_last_name }}
                                        </v-card-title>

                                        <v-divider />

                                        <v-list>
                                            <v-list-item>
                                                <v-list-item-icon>
                                                    <v-icon large color="primary">fas fa-phone-square-alt</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    {{  $store.getters['store_coach/get_coach'].all_clients[getChatPartnerName(current_chat)].supervisor_phone }}
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>

                                        <v-divider />

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn text @click="menu_supervisor = false">Schließen</v-btn>
                                        </v-card-actions>

                                    </v-card>
                                </v-menu>

                                <v-divider vertical class="mx-2"/>

                                <v-menu
                                    v-model="menu_template"
                                    :close-on-content-click="false"
                                    :nudge-width="200"
                                    :nudge-left="250"
                                    :nudge-bottom="40"
                                    bottom
                                    y-offset
                                    
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            color="primary"
                                            outlined
                                            dark
                                            v-on="on"
                                        >
                                            Template
                                        </v-btn>
                                    </template>

                                    <v-card>

                                        <v-list>
                                            <v-list-item>
                                                <v-list-item-action>
                                                    <v-switch v-model="answer_correct" color="primary"></v-switch>
                                                </v-list-item-action>
                                                <v-list-item-title>Antwort korrekt</v-list-item-title>
                                            </v-list-item>

                                            <v-list-item>
                                                <v-list-item-action>
                                                    <v-switch v-model="worked_on_task" color="primary"></v-switch>
                                                </v-list-item-action>
                                                <v-list-item-title>Übung (ernsthaft) bearbeitet</v-list-item-title>
                                            </v-list-item>

                                        </v-list>

                                        <v-divider />

                                        <v-card-actions>
                                            <v-spacer></v-spacer>

                                            <v-btn text @click="menu_template = false">Abbrechen</v-btn>
                                            <v-btn color="primary" text @click="insert_template()">Einfügen</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>

                                <v-divider vertical class="mx-2" v-if="current_chat.latest_message.sender.username !== $store.getters['store_auth/get_user_username']" />

                                <v-menu
                                    v-if="current_chat.latest_message.sender.username !== $store.getters['store_auth/get_user_username']"
                                    v-model="menu_critical_event"
                                    :close-on-content-click="false"
                                    :nudge-width="300"
                                    :nudge-left="380"
                                    :nudge-bottom="60"
                                    bottom
                                    y-offset    
                                    offset-x                                
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            color="error"
                                            icon
                                            v-on="on"
                                        >
                                            <v-icon>fas fa-exclamation-circle</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-card>

                                        <v-card-title>
                                            Kritisches Ereignis melden
                                        </v-card-title>

                                        <v-card-subtitle>
                                            Wenn Sie auf SENDEN klicken wird der in der SOP festgelegte Prozess automatisch starten.
                                        </v-card-subtitle>

                                        <v-divider />

                                        <v-list>

                                            <v-list-item>
                                                <v-list-item-action>
                                                    <v-select
                                                        :items="['Plan von Suizid', 'aktuell fortbestehende, gegenwärtige und konkrete Fremdgefährdungslage', 'Plan einer Straftat i.S.d. §§ 138, 139 StGB', 'begangene Sexualstraftat während der Teilnahme an @myTabu']"
                                                        label="Art des Ereignisses"
                                                        v-model="event_type"
                                                        required
                                                        :error-messages="error_message_s1"
                                                        @change="change_s1"
                                                    ></v-select>
                                                </v-list-item-action>
                                            </v-list-item>

                                        </v-list>

                                        <v-textarea
                                            name="input-7-1"
                                            label="Zusätzliche Beschreibung des Problems innerhalb der Übung"
                                            v-model="event_coach_content_critical_items"
                                            rows="3"
                                            auto-grow
                                            :error-messages="error_message_s2"
                                            required
                                            outlined
                                            color="primary"
                                            class='mx-3'
                                        ></v-textarea>

                                        <v-divider />

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn text @click="menu_critical_event = false">Schließen</v-btn>
                                            <v-btn 
                                                color="error" 
                                                text 
                                                @click="send_critical_event()"
                                                :loading="loading_send_critical_event"
                                                :disabled="loading_send_critical_event"
                                            >
                                                Ereignis melden
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>

                                </v-menu>

                            </v-toolbar>

                            <v-divider />

                            <v-card-text>

                                <v-form ref="new_message_form" v-model="new_message_form_valid" lazy-validation>

                                    <v-checkbox
                                        v-model="checkbox_revision_finished"
                                        label='Aufgabe zufriedenstellend bearbeitet'
                                        v-if="!is_finished(current_chat)"
                                    ></v-checkbox>

                                    <v-textarea
                                        name="input-7-1"
                                        label="Neue Nachricht:"
                                        v-model="newMessageValue"
                                        :rules="new_message_form_content_rules"
                                        rows="3"
                                        auto-grow
                                        required
                                        outlined
                                        color="primary"
                                        v-if="!is_finished(current_chat)"
                                    ></v-textarea>

                                </v-form>

                                <v-col cols="12" sm="12" class="pa-0">

                                    <div class="caption"><b>Bitte beachten Sie:</b> Zum Schutz der Daten anderer Personen, vermeiden Sie es unbedingt, persönliche Daten von jemand anderem in Ihren Nachrichten zu nennen, z.B. vollständige Namen oder Adressen von Menschen, die Sie kennen.</div>

                                </v-col>

                                <v-toolbar
                                    flat
                                    short
                                    id="fooAnchor_attachment"
                                    v-if="!is_finished(current_chat)"
                                >

                                    <v-spacer />

                                    <v-btn 
                                        @click="sendMessage"
                                        :loading="sending"
                                        color='primary'
                                        outlined
                                        class="mt-3"
                                    >
                                        <v-icon left color="primary">far fa-paper-plane</v-icon><span class="ml-3">Versenden</span>
                                    </v-btn>

                                </v-toolbar>

                            </v-card-text>

                        </v-card>

                </v-card-text>

            </v-card>

        </v-row>

    </div>

</template>

<script>
import CoachApi from '@/_api/coach_api'
import DateTimeHelpers from '@/_helpers/date_time_helpers'
import HelperGuidedTask from '@/_helpers/helper_guided_task'

import GuidedTaskData from '@/components/_guided_task/guided_task_DATA'

export default {
    name: 'coach-tasks-detailed',

    components: {

        GuidedTaskData

    },

    data: () => ({

        menu_attachment: false,
        current_chat: {},
        loaded: false,

        new_thread_form_valid: true,
        new_thread_form_subject_rules: [
                v => !!v || 'Sie müssen einen Betreff angeben!',
                v => (v && v.length <= 120) || 'Der Betreff darf nicht länger als 120 Zeichen sein!'
            ],
        new_thread_form_content_rules: [
                v => !!v || 'Der Inhalt der Nachricht darf nicht leer sein!'],


        new_message_form_valid: true,
        new_message_form_content_rules: [
                v => !!v || 'Der Inhalt der Nachricht darf nicht leer sein!'],

        newMessageValue: null,

        // send message
        to_user: [],
        receiver: [],
        receiver_names: [],
        subject: null,
        content: null,

        file: null,
        attachmentName1: '',
        attachmentUrl1: '',
        attachmentFile1: '',
        
        attachmentName2: '',
        attachmentUrl2: '',
        attachmentFile2: '',
        
        attachmentName3: '',
        attachmentUrl3: '',
        attachmentFile3: '',

        sending: false,

        loading_attachment: false,

        checkbox_revision_finished: false,

        fav: true,
        menu_template: false,
        answer_correct: false,
        worked_on_task: false,
        additional_hint: false,

        menu_help: false,
        help_txt: 'test',

        menu_critical_event: false,
        loading_send_critical_event: false,
        event_type: null,
        event_time: null,
        error_message_s1: null,
        error_message_s2: null,
        event_coach_content_critical_items: null,

        menu_supervisor: false,

    }),

    created() {

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        this.new_thread_form_valid = true
        this.newMessageValue = null
        this.checkbox_revision_finished = null

        CoachApi.GetGuidedTaskById(this.$route.params.task_id).then((resp) => {

            this.current_chat = resp.data

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

            this.loaded = true

        })

    },

    watch: {

        '$route' () {

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

            this.new_thread_form_valid = true
            this.newMessageValue = null
            this.checkbox_revision_finished = null

            CoachApi.GetGuidedTaskById(this.$route.params.task_id).then((resp) => {

                this.current_chat = resp.data

                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                this.loaded = true

            })

        }


    },

    computed: {

        usergroup() {
            return this.$store.getters['store_auth/get_user_group']
        },

    },

    methods: {

        send_critical_event() {

            if (this.event_type != null && this.event_coach_content_critical_items != null) {

                this.loading_send_critical_event = true

                var event_type = null

                if (this.event_type == 'Plan von Suizid'){
                    event_type = 1
                } else if (this.event_type == 'aktuell fortbestehende, gegenwärtige und konkrete Fremdgefährdungslage'){
                    event_type = 2
                } else if (this.event_type == 'Plan einer Straftat i.S.d. §§ 138, 139 StGB') {
                    event_type = 3
                } else {
                    event_type = 4
                }

                var data = {

                    client_user_id: this.current_chat.latest_message.sender.id,
                    event_type: event_type,
                    event_guided_task_id: this.current_chat.latest_message.guided_task,
                    event_message_guided_task_id: this.current_chat.latest_message.id,
                    event_coach_content_critical_items: this.event_coach_content_critical_items,
                    event_guided_task_revision_nr: this.current_chat.latest_message.revision_number,

                }

                CoachApi.ReportCriticalEventGuidedTask(data).then(() => {

                    CoachApi.GetCoachInfo().then(() => {

                        CoachApi.GetGuidedTaskById(this.$route.params.task_id).then((resp) => {

                            this.current_chat = resp.data

                            this.menu_critical_event = false
                            this.loading_send_critical_event = false
                            this.event_type = null

                            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)



                            this.loaded = true

                            this.sending = false

                        })

                    })

                })

            } else {

                if (this.event_type == null) {
                    this.error_message_s1 = 'Sie müssen eine Auswahl treffen.'
                } else {
                    this.error_message_s1 = null
                }

                if (this.event_coach_content_critical_items == null) {
                    this.error_message_s2 = 'Sie müssen eine Beschreibung einfügen.'
                } else {
                    this.error_message_s2 = null
                }
                
            }

        },

        change_s1() {
            this.error_message_s1 = null
        },

        insert_template() {

            if (this.answer_correct == true) {
                this.checkbox_revision_finished = true
            } else if (this.current_chat.latest_message.revision_number == 3 && this.worked_on_task) {
                this.checkbox_revision_finished = true
            } else {
                this.checkbox_revision_finished = false
            }

            var rev_number = this.current_chat.latest_message.revision_number
            var task_id = this.current_chat.original_task_id
            var client_username = this.getChatPartnerName(this.current_chat)

            var template = JSON.parse(HelperGuidedTask.get_correct_template(task_id, rev_number, this.answer_correct, this.worked_on_task, this.additional_hint, client_username, this.$store.getters['store_coach/get_coach'].coach_gender))

            this.newMessageValue = template
            this.menu_template = false

        },

        get_coach_help() {

            var task_id = this.current_chat.original_task_id

            this.help_txt = HelperGuidedTask.get_correct_help(task_id)

        },

        openTask(item) {

            this.msg_app_state = 'msg'

            this.menu = false
            this.menu_archive = false

            this.current_chat = item

        },

        get_short_question(item) {

            var tmp = JSON.parse(item.original_task_data)

            return tmp.wagtail_task_data.value.shortQuestion

        },

        getAttachmentName(att) {

            var tmp = att.split('/')

            return tmp[tmp.length - 1]

        },

        getChatPartnerName(item) {

            var partner = ''
            
            item.users.forEach(element => {

                if (element.username != this.$store.getters['store_auth/get_user_username']) {

                    partner = element.username

                } 
                
            });

            return partner

        },

        getClientUserId(item) {

            var id = null
            
            item.users.forEach(element => {

                if (element.username != this.$store.getters['store_auth/get_user_username']) {

                    id = element.id

                } 
                
            });

            return id

        },

        convertedSender(username) {

            if (username == this.$store.getters['store_auth/get_user_username']) {

                return 'Ich'

            } else {

                return username

            }


        },


        changeState() {

            if (this.msg_app_state == 'msg') {
                this.msg_app_state = 'menu'
            } else {
               this.msg_app_state = 'msg' 
            }
            
        },

        checkIfActionNesseary(item) {

            if (item.waiting_for_coach) {

                return true

            } else {

                return false
                
            }

        },

        get_converted_time(item) {

            return DateTimeHelpers.convertDatabaseDateTimeString('full', item)
        },

        getCorrectDateTime(item) {
            return DateTimeHelpers.convertDatabaseDateTimeString('full', item)
        },

        is_finished(current_chat) {

            if (current_chat.revision_finished) {
                return true
            } else {
                return false
            }
        },

        getNumberOfAttachments(msg) {

            var nr_msg = 0

            if (msg.attachment1) {

                nr_msg = nr_msg + 1

            } 

            if (msg.attachment2) {

                nr_msg = nr_msg + 1

            } 

            if (msg.attachment3) {

                nr_msg = nr_msg + 1

            } 

            return nr_msg

        },

        onAttachmentPicked (i) {  

            this.loading_attachment = false

            if (i == 1) {
                this.attachmentFile1 = this.file;
                this.attachmentName1 = this.file.name
            } else if (i == 2) {
                this.attachmentFile2 = this.$refs.attachment2.files[0];
                this.attachmentName2 = this.$refs.attachment2.files[0].name
            } else if (i == 3) {
                this.attachmentFile3 = this.$refs.attachment3.files[0];
                this.attachmentName3 = this.$refs.attachment3.files[0].name
            }
        },

        sendMessage() {

            if (this.$refs.new_message_form.validate()) {

                this.sending = true

                var data = {
                    'content': this.newMessageValue, 
                    'guided_task': this.current_chat.id,
                    'revision_number': this.current_chat.latest_message.revision_number, 
                    'current_task_data': null,    // always null if coach sends the message
                    'current_thread_id': this.current_chat.id,
                    'attachmentFile1': this.attachmentFile1,
                    'attachmentFile2': this.attachmentFile2,
                    'attachmentFile3': this.attachmentFile3,
                }

                CoachApi.AddNewMessageToGuidedTask(data).then(() => {

                    CoachApi.UpdateGuidedTaskWaitingForClient(this.current_chat.id).then(() => {

                        if (this.checkbox_revision_finished) {

                            var final_task_data;

                            // get correct final task data
                            if (this.current_chat.latest_message.revision_number == 0) {

                                final_task_data = JSON.parse(this.current_chat.original_task_data)

                            } else {

                                final_task_data = this.current_chat.latest_message.current_task_data
                            }

                            CoachApi.UpdateGuidedTaskFinalData(this.current_chat.id, final_task_data).then(() => {

                                CoachApi.MarkGuidedTaskRevFinished(this.current_chat.id).then(() => {

                                    CoachApi.GetCoachInfo().then(() => {

                                        CoachApi.GetGuidedTaskById(this.$route.params.task_id).then((resp) => {

                                            this.current_chat = resp.data

                                            var last = ''
                                            if (this.$store.getters['store_coach/get_coach'].coach_gender == 1) {
                                                last = 'Ihr Coach'
                                            } else {
                                                last = 'Ihre Coachin'
                                            }

                                            var data_tmp = {
                                                "content": 'Hallo ' + this.getChatPartnerName(this.current_chat) + ',\n\nSie haben die Übung "' + this.get_short_question(this.current_chat) + '" in Modul ' + this.current_chat.current_module_nr +', Sitzung ' + this.current_chat.current_session_nr + ', Lektion ' + this.current_chat.current_lession_nr + ' erfolgreich abgeschlossen.\n\nSie können sich den gesamten Verlauf der Übung nochmals auf der Seite "Übungen" unter "Bearbeitete Übungen" ansehen. Bitte sehen Sie sich dort meine letzte Rückmeldung zu dieser Übung an.\n\nViele Grüße,\n' + last,
                                                "receiver": this.getClientUserId(this.current_chat),
                                                "subject": 'Sie haben eine Übung erfolgreich bearbeitet!',
                                                "is_technical_help": 'False',
                                                'attachmentFile1': '',
                                                'attachmentFile2': '',
                                                'attachmentFile3': '',
                                            }
                                            
                                            CoachApi.CreateNewThread(data_tmp).then(()=>{ 
                                                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)
                                                this.loaded = true
                                                this.sending = false
                                            })

                                        })

                                    })

                                })
                            })

                        } else {

                            CoachApi.GetCoachInfo().then(() => {

                                CoachApi.GetGuidedTaskById(this.$route.params.task_id).then((resp) => {

                                    this.current_chat = resp.data

                                    this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                                    this.loaded = true

                                    this.sending = false

                                })

                            })

                        }

                    })

                })

            }

        }, 
        
    },

}
</script>

<style scoped>
.note {
  white-space: pre-line; 
  word-wrap: break-word;
  font-family: inherit;
  text-align: left !important;
}
</style>