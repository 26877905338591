<template>

    <v-card flat color="grey lighten-5">

        <v-card-text>

            <v-row>
                <v-col cols="12" sm="12" v-if="finished">  
                    <v-card-text class="display-1 primary--text text-center">
                        Gut gemacht!
                    </v-card-text>
                </v-col>
                <v-col cols="12" sm="12" v-if="!finished">
                    <v-card-text class="display-1 primary--text text-center">
                        {{ get_correct_to_do_headline }}
                    </v-card-text>
                </v-col>
                <v-col cols="12" sm="12" v-if="finished">
                    <v-card-text class="headline black--text text-center">
                        {{ get_correct_headline }}
                    </v-card-text>
                </v-col>
            </v-row>

        </v-card-text>

        <v-card-text v-if="!finished">

            <v-row v-if="$store.getters['store_client/get_client_progress'].has_to_wait_questionnaires">

                <v-col cols="12" sm="12">
                        
                        <v-card flat outlined>

                            <v-card-text class="title error--text text-center">
                                {{ get_correct_q_title }}
                            </v-card-text>

                            <v-divider />

                            <v-card-text class="text-center">
                                Da es sich bei @myTabu um eine wissenschaftliche Studie handelt, müssen wir Sie leider in regelmäßigen Abständen darum 
                                bitten, Fragebögen zu beantworten. Klicken Sie bitte auf den untenstehenden Button um die noch ausstehenden Fragebögen 
                                auszufüllen.
                            </v-card-text>

                            <v-card-actions>

                                <v-spacer />

                                <v-btn
                                    @click="go_to_q()"
                                    color="error"
                                >
                                    Zu den Fragebögen
                                </v-btn>

                                <v-spacer />

                            </v-card-actions>

                        </v-card>

                </v-col>

            </v-row>

            <v-row v-if="$store.getters['store_client/get_client_progress'].has_to_wait_tasks">

                <v-col cols="12" sm="12">
                        
                        <v-card flat outlined>

                            <v-card-text class="title error--text text-center">
                                {{ get_correct_task_title }}
                            </v-card-text>

                            <v-divider />

                            <v-card-text class="text-center">
                                Das Online-Programm beinhaltet einige Übungen, die Sie zusammen mit <span v-if="$store.getters['store_client/get_client_coach'].coach_gender == 1">Ihrem Coach</span><span v-else>Ihrer Coachin</span> bearbeiten. Leider sind noch nicht alle dieser Übungen
                                abgeschlossen, entweder weil Sie nochmals eine oder mehrere Übungen überarbeiten müssen oder weil Sie zu einer oder mehreren Übungen noch keine 
                                Rückmeldung von <span v-if="$store.getters['store_client/get_client_coach'].coach_gender == 1">Ihrem Coach</span><span v-else>Ihrer Coachin</span> erhalten haben. Bitte klicken Sie auf den Button "Zu den Übungen", um nachzusehen, um welche Übungen es sich 
                                handelt.
                            </v-card-text>

                            <v-card-actions>

                                <v-spacer />

                                <v-btn
                                    @click="go_to_task()"
                                    color="error"
                                >
                                    Zu den Übungen
                                </v-btn>

                                <v-spacer />

                            </v-card-actions>

                        </v-card>

                </v-col>

            </v-row>

        </v-card-text>

        <v-card-text v-if="$store.getters['store_client/get_client_progress'].has_to_wait_time">
            <v-row>

                <v-col cols="12" sm="12">
                        
                        <v-card flat outlined>

                            <v-card-text class="primary--text title text-center">
                                Sie müssen noch
                            </v-card-text>

                            <v-card-text>

                                <v-row justify="center" align="center">

                                    <v-card class="mr-1">
                                        <v-card-text class="text-center title">
                                            {{days}}
                                        </v-card-text>

                                        <v-divider />

                                        <v-card-text class="caption">
                                            Tage
                                        </v-card-text>
                                    </v-card>

                                    <v-card class="mr-1">
                                        <v-card-text class="text-center title">
                                            {{hours}}
                                        </v-card-text>

                                        <v-divider />

                                        <v-card-text class="caption">
                                            Stdn
                                        </v-card-text>
                                    </v-card>

                                    <v-card class="mr-1">
                                        <v-card-text class="text-center title">
                                            {{minutes}}
                                        </v-card-text>

                                        <v-divider />

                                        <v-card-text class="caption">
                                            Min
                                        </v-card-text>
                                    </v-card>

                                    <v-card>
                                        <v-card-text class="text-center title">
                                            {{seconds}}
                                        </v-card-text>

                                        <v-divider />

                                        <v-card-text class="caption">
                                            Sek
                                        </v-card-text>
                                    </v-card>

                                </v-row>
                            </v-card-text>

                            <v-card-text class="primary--text title text-center">
                                warten bevor Sie weitermachen können.
                            </v-card-text>

                            <v-card-text class="text-center">
                                Da es sich bei @myTabu um eine wissenschaftliche Studie handelt, müssen wir Sie leider bitten, einen bestimmten Zeitabstand zwischen 
                                den einzelnen Sitzungen einzuhalten. Um die Daten auswerten zu können, muss sichergestellt sein, dass alle Teilnehmenden die 
                                einzelnen Lektionen mit gleichen Zeitabständen bearbeiten.
                            </v-card-text>

                        </v-card>

                </v-col>

            </v-row>
        </v-card-text>

        <v-card-text>
            <v-row v-if="finished && current_countdown == '00 : 00 : 00'">

                <v-col cols="12" sm="12">
                    <v-row align="center" justify="center">
                        
                        <v-btn
                            color="primary"
                            @click="go_next()"
                        >
                            Weiter
                        </v-btn>

                    </v-row>

                </v-col>

            </v-row>
        </v-card-text>

    </v-card>

</template>

<script>
import ClientApi from '@/_api/client_api'

export default {
    name: 'client-has-to-wait',

    data: () => ({
        
        loaded: false,

        current_countdown: '00 : 00 : 00',

        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',

        value_progress_circular: 0,

    }),

    created() {

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        ClientApi.GetClientInfo().then(() => {

            if (this.$store.getters['store_client/get_client_progress'].current_module_nr == 7) {

                this.$router.push({ name: 'client-final'})

            } 

            this.update_countdown()

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

        })

    },

    computed: {

        get_correct_q_title() {

            var output = 'Einige Fragebögen sind noch unbeantwortet.'

            if (this.$store.getters['store_client/get_client_questionnaires'].unfinished_questionnaires_count == 1) {

                output = 'Ein Fragebogen ist noch unbeantwortet.'

            } else {
               output = 'Einige Fragebögen sind noch unbeantwortet.' 
            }

            return output

        },

        finished() {

            if (this.$store.getters['store_client/get_client_progress'].has_to_wait_questionnaires || this.$store.getters['store_client/get_client_progress'].has_to_wait_tasks) {
                return false
            } else {
                return true
            }

        },

        get_correct_task_title() {

            var output = 'Einige Übungen sind noch nicht abgeschlossen.'

            if (this.$store.getters['store_client/get_client_questionnaires'].unfinished_questionnaires_count == 1) {

                if (this.$store.getters['store_client/get_client_coach'].coach_gender == 1) {
                    output = 'Eine Übung muss noch bearbeitet werden oder es fehlt eine Rückmeldung durch Ihren Coach.'
                } else {
                    output = 'Eine Übung muss noch bearbeitet werden oder es fehlt eine Rückmeldung durch Ihre Coachin.'
                }

            } else {

                if (this.$store.getters['store_client/get_client_coach'].coach_gender == 1) {
                    output = 'Einige Übungen müssen noch bearbeitet werden oder es fehlen Rückmeldungen durch Ihren Coach.'
                } else {
                    output = 'Einige Übungen müssen noch bearbeitet werden oder es fehlen Rückmeldungen durch Ihre Coachin.'
                }

            }

            return output

        },

        get_correct_to_do_headline() {

            var output = ''

            var last_change_type = this.$store.getters['store_client/get_client_progress'].last_change_type

            if (last_change_type == 'introduction') {

                output = 'Es müssen noch einige wenige Dinge erledigt werden, damit Sie die Einführung abzuschließen können.'

            } else if (last_change_type == 'module') {

                output = 'Es müssen noch einige wenige Dinge erledigt werden, damit Sie das ' + (this.$store.getters['store_client/get_client_progress'].current_module_nr - 1) + '. Modul abschließen können.'

            } else if (last_change_type == 'session') {

                output = 'Es müssen noch einige wenige Dinge erledigt werden, damit Sie die ' + (this.$store.getters['store_client/get_client_progress'].current_session_nr - 1) + '. Sitzung in Modul ' + this.$store.getters['store_client/get_client_progress'].current_module_nr + ' abschließen können.'

            } else if (last_change_type == 'lection') {

                output = 'Es müssen noch einige wenige Dinge erledigt werden, damit Sie die ' + this.$store.getters['store_client/get_client_progress'].current_lession_nr + '. Lektion in der ' + this.$store.getters['store_client/get_client_progress'].current_session_nr + '. Sitzung des ' + this.$store.getters['store_client/get_client_progress'].current_module_nr + '. Moduls abschließen können.'

            } else {

                output = 'last_change is None!'

            }

            return output

        },

        get_correct_headline() {

            var output = ''

            var last_change_type = this.$store.getters['store_client/get_client_progress'].last_change_type

            if (last_change_type == 'introduction') {

                output = 'Sie haben die Einführung erfolgreich abgeschlossen.'

            } else if (last_change_type == 'module') {

                output = 'Sie haben das ' + (this.$store.getters['store_client/get_client_progress'].current_module_nr - 1) + '. Modul erfolgreich abgeschlossen.'

            } else if (last_change_type == 'session') {

                output = 'Sie haben die ' + (this.$store.getters['store_client/get_client_progress'].current_session_nr - 1) + '. Sitzung in Modul ' + this.$store.getters['store_client/get_client_progress'].current_module_nr + ' erfolgreich abgeschlossen.'

            } else if (last_change_type == 'lection') {

                output = 'Sie haben die ' + this.$store.getters['store_client/get_client_progress'].current_lession_nr + '. Lektion in der ' + this.$store.getters['store_client/get_client_progress'].current_session_nr + '. Sitzung des ' + this.$store.getters['store_client/get_client_progress'].current_module_nr + '. Moduls erfolgreich abgeschlossen.'

            } else {

                output = 'last_change is None!'

            }

            return output
        }

    },

    methods: {

        go_next(){

            // set page allowed to true
            console.log('UPDATE_PAGE_ALLOWED: client_assessment')
            this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

            this.$router.push({ name: 'client-intervention', params: { module: this.$store.getters['store_client/get_client_progress'].current_module_nr, session: this.$store.getters['store_client/get_client_progress'].current_session_nr, lection: this.$store.getters['store_client/get_client_progress'].current_lession_nr } })

        },

        go_to_q() {

            this.$router.push({name: 'client-polls'})

        },

        go_to_task() {

            this.$router.push({name: 'client-tasks', props: { task_id: null }})

        },

        update_countdown() {

            var countDownDate = new Date(this.$store.getters['store_client/get_client_progress'].has_to_wait_until_time).getTime()

            // Update the count down every 1 second
            var x = setInterval(() => {

                // Get today's date and time
                var now = new Date().getTime();
                    
                // Find the distance between now and the count down date
                var distance = countDownDate - now;
                    
                // Time calculations for days, hours, minutes and seconds
                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                if (days < 10) {
                    this.days = '0' + days
                } else {
                    this.days = days
                }

                if (hours < 10) {
                    this.hours = '0' + hours
                } else {
                    this.hours = hours
                }

                if (minutes < 10) {
                    this.minutes = '0' + minutes
                } else {
                    this.minutes = minutes
                }

                if (seconds < 10) {
                    this.seconds = '0' + seconds
                } else {
                    this.seconds = seconds
                }

                this.value_progress_circular = (100/60)*seconds

                this.current_countdown = this.days + ':' + this.hours + ':' + this.minutes + ':' + this.seconds
                    
                // If the count down is over, write some text 
                if (distance < 0) {
                    clearInterval(x);
                    this.current_countdown = '00 : 00 : 00'
                    this.days = '00'
                    this.hours = '00'
                    this.minutes = '00'
                    this.seconds = '00'
                }

            }, 1000);


        }

    }
}
</script>

<style scoped>

</style>