<template>

    <div>

        <v-row v-if="loaded">

            <v-card
                color="grey lighten-5"
                flat
                height="auto"
                min-height="250px"
                tile
                width="100%"
                id="fooAnchor"
            >

                <v-card-text
                    color="grey lighten-5"
                >

                    <v-chip color="accent" class="black--text font-weight-bold">{{ getChatPartnerName(current_chat) }}</v-chip>
                    
                    <span class="float-right">

                        <v-menu
                            v-model="menu_chat_options"
                            offset-y 
                            left
                            origin="center center" 
                            :nudge-bottom="9"  
                            transition="slide-y-transition"
                            attach="#fooAnchor"
                            z-index="1"
                            min-width="250"
                        >

                            <template v-slot:activator="{ on }">
                                <v-btn 
                                    icon
                                    v-on="on"
                                    :color="menu_chat_options ? 'primary' : ''"
                                >
                                    <v-icon>fas fa-ellipsis-v</v-icon>
                                </v-btn>

                            </template>

                            <v-list>
                                <v-list-item
                                    @click="toArchive(current_chat)"
                                    v-if="!is_archived(current_chat)"
                                >

                                    <v-list-item-icon>
                                        <v-icon>fas fa-archive</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-title>
                                        Chat archivieren
                                    </v-list-item-title>

                                </v-list-item>

                                <v-list-item
                                    @click="undoThreadDeletion(current_chat)"
                                    v-if="is_archived(current_chat)"
                                >

                                    <v-list-item-icon>
                                        <v-icon>fas fa-inbox</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-title>
                                        Archivierung aufheben
                                    </v-list-item-title>

                                </v-list-item>
                            </v-list>

                        </v-menu>

                    </span>

                    <div class="title black--text mt-3">
                        <b>Betreff: </b>{{ current_chat.subject }}
                    </div>

                </v-card-text>

                <v-divider />
                
                <!-- CONTENT (of CHAT) -->
                <v-card-text>

                        <v-row>

                            <v-container fluid style="width: 100% !important;">

                                <v-row
                                    v-for="(msg, index) in current_chat.all_messages"
                                    :key="index"
                                >

                                    <v-col
                                        :cols="$vuetify.breakpoint.smAndUp ? '10' : '12'"
                                        :sm="$vuetify.breakpoint.smAndUp ? '10' : '12'"
                                        :offset-sm="msg.sender.username !== $store.getters['store_auth/get_user_username'] ? '0': '2'" 
                                    >

                                        <v-card outlined :id="'fooAnchor' + index + 5">

                                            <v-card-title>

                                                <v-chip 
                                                    :color="msg.sender.username !== $store.getters['store_auth/get_user_username'] ? 'accent' : 'primary'"
                                                    :class="msg.sender.username !== $store.getters['store_auth/get_user_username'] ? 'black--text' : 'white--text'"
                                                >
                                                    {{ convertedSender(msg.sender.username) }}
                                                </v-chip>

                                                <v-spacer />

                                                <v-menu
                                                    v-if="msg.sender.username !== $store.getters['store_auth/get_user_username'] && msg.critical_event"
                                                    v-model="menu_supervisor[index]"
                                                    :close-on-content-click="false"
                                                    :nudge-width="350"
                                                    :nudge-left="400"
                                                    :nudge-bottom="40"
                                                    bottom
                                                    y-offset
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn icon v-on="on">
                                                            <v-icon color="error">far fa-bell</v-icon>
                                                        </v-btn>
                                                    </template>

                                                    <v-card>

                                                        <v-card-subtitle>
                                                            Kritisches Ereignis wurde am {{ getCorrectDateTime(msg.critical_event_report_time) }} gemeldet.
                                                        </v-card-subtitle>

                                                        <v-card-subtitle>
                                                            Haben Sie den zuständigen Bewährungshelfer bereits telefonisch informiert?
                                                        </v-card-subtitle>

                                                        <v-divider />

                                                        <v-list>
                                                            <v-list-item>
                                                                <v-list-item-icon>
                                                                    <v-icon large color="primary">fas fa-phone-square-alt</v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-content>
                                                                    {{  $store.getters['store_coach/get_coach'].all_clients[getChatPartnerName(current_chat)].supervisor_phone }} ({{  $store.getters['store_coach/get_coach'].all_clients[getChatPartnerName(current_chat)].supervisor_first_name }} {{  $store.getters['store_coach/get_coach'].all_clients[getChatPartnerName(current_chat)].supervisor_last_name }})
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-list>

                                                        <v-divider />

                                                        <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <v-btn text @click="close_menu_supervisor(index)">Schließen</v-btn>
                                                        </v-card-actions>

                                                    </v-card>
                                                </v-menu>

                                            </v-card-title>

                                            <v-divider />

                                            <v-card-text class='black--text'>

                                                <v-container fluid class="pa-0">

                                                    <v-row
                                                        align="center"
                                                        justify="start"
                                                        class="pa-0"
                                                    >

                                                        <v-col cols="12" sm="12" class="note py-0">
                                                            <div class="body-1">{{ msg.content }}</div>
                                                        </v-col>

                                                    </v-row>

                                                </v-container>

                                            </v-card-text>

                                            <v-card-subtitle>
                                                <small class='overline font-weight-thin'><b>{{ getCorrectDateTime(msg.sent_at) }}</b></small>
                                            </v-card-subtitle>

                                        </v-card>

                                    </v-col>

                                </v-row>

                            </v-container>

                        </v-row>

                        <v-divider class="my-3" v-if="!is_archived(current_chat)"></v-divider>

                        <v-card flat v-if="!is_archived(current_chat)">

                            <v-toolbar flat>

                                <v-toolbar-title>
                                    Antwort verfassen
                                </v-toolbar-title>

                                <v-spacer />

                                <v-menu
                                    v-if="current_chat.latest_message.sender.username !== $store.getters['store_auth/get_user_username']"
                                    v-model="menu_critical_event"
                                    :close-on-content-click="false"
                                    :nudge-width="300"
                                    :nudge-left="380"
                                    :nudge-bottom="60"
                                    bottom
                                    y-offset                                    
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            color="error"
                                            icon
                                            v-on="on"
                                        >
                                            <v-icon>fas fa-exclamation-circle</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-card>

                                        <v-card-title>
                                            Kritisches Ereignis melden
                                        </v-card-title>

                                        <v-card-subtitle>
                                            Wenn Sie auf SENDEN klicken wird der in der SOP festgelegte Prozess automatisch starten.
                                        </v-card-subtitle>

                                        <v-divider />

                                        <v-list>

                                            <v-list-item>
                                                <v-list-item-action>
                                                    <v-select
                                                        :items="['Plan von Suizid', 'aktuell fortbestehende, gegenwärtige und konkrete Fremdgefährdungslage', 'Plan einer Straftat i.S.d. §§ 138, 139 StGB', 'begangene Sexualstraftat während der Teilnahme an @myTabu']"
                                                        label="Art des Ereignisses"
                                                        v-model="event_type"
                                                        required
                                                        :error-messages="error_message_s1"
                                                        @change="change_s1"
                                                    ></v-select>
                                                </v-list-item-action>
                                            </v-list-item>

                                        </v-list>

                                        <v-divider />

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn text @click="menu_critical_event = false">Schließen</v-btn>
                                            <v-btn 
                                                color="error" 
                                                text 
                                                @click="send_critical_event()"
                                                :loading="loading_send_critical_event"
                                                :disabled="loading_send_critical_event"
                                            >
                                                Ereignis melden
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>

                                </v-menu>

                            </v-toolbar>

                            <v-divider />

                            <v-card-text>

                                <v-form ref="new_message_form" v-model="new_message_form_valid" lazy-validation>
                                    <v-textarea
                                        name="input-7-1"
                                        label="Neue Nachricht:"
                                        v-model="newMessageValue"
                                        :rules="new_message_form_content_rules"
                                        rows="3"
                                        auto-grow
                                        required
                                        outlined
                                        color="primary"
                                        v-if="!is_archived(current_chat)"
                                    >
                                    
                                    </v-textarea>
                                </v-form>

                                <v-col cols="12" sm="12" class="pa-0">

                                    <div class="caption"><b>Bitte beachten Sie:</b> Zum Schutz der Daten anderer Personen, vermeiden Sie es unbedingt, persönliche Daten von jemand anderem in Ihren Nachrichten zu nennen, z.B. vollständige Namen oder Adressen von Menschen, die Sie kennen.</div>

                                </v-col>

                                <v-toolbar
                                    flat
                                    short
                                    id="fooAnchor_attachment"
                                    v-if="!is_archived(current_chat)"
                                >

<!--                                     <v-file-input 
                                        v-if="usergroup == 'Coach'"
                                        hide-details
                                        show-size 
                                        accept=".pdf"
                                        label="Wählen Sie einen Anhang aus (.pdf)"
                                        v-model="file"
                                        @change="onAttachmentPicked(1)"
                                        :loading="loading_attachment"
                                        @click="loading_attachment = true"
                                    ></v-file-input> -->

                                    <v-spacer />

                                    <v-btn 
                                        @click="sendMessage"
                                        :loading="sending"
                                        color='primary'
                                        outlined
                                        class="mt-3"
                                    >
                                        <v-icon left color="primary">far fa-paper-plane</v-icon><span class="ml-3">Versenden</span>
                                    </v-btn>

                                </v-toolbar>

                            </v-card-text>

                        </v-card>

                </v-card-text>

            </v-card>

        </v-row>

    </div>

</template>

<script>
import CoachApi from '@/_api/coach_api'
import DateTimeHelpers from '@/_helpers/date_time_helpers'

export default {
    name: 'coach-messages',

    data: () => ({

        menu_chat_options: false,

        menu_attachment: false,

        current_chat: {},

        loaded: false,

        new_thread_form_valid: true,
        new_thread_form_subject_rules: [
                v => !!v || 'Sie müssen einen Betreff angeben!',
                v => (v && v.length <= 120) || 'Der Betreff darf nicht länger als 120 Zeichen sein!'
            ],
        new_thread_form_content_rules: [
                v => !!v || 'Der Inhalt der Nachricht darf nicht leer sein!'],


        new_message_form_valid: true,
        new_message_form_content_rules: [
                v => !!v || 'Der Inhalt der Nachricht darf nicht leer sein!'],

        newMessageValue: null,

        // send message
        to_user: [],
        receiver: [],
        receiver_names: [],
        subject: null,
        content: null,

        file: null,
        attachmentName1: '',
        attachmentUrl1: '',
        attachmentFile1: '',
        
        attachmentName2: '',
        attachmentUrl2: '',
        attachmentFile2: '',
        
        attachmentName3: '',
        attachmentUrl3: '',
        attachmentFile3: '',

        sending: false,

        loading_attachment: false,

        search: null,
        search_archive: null,

        menu_critical_event: false,
        loading_send_critical_event: false,
        event_type: null,
        event_time: null,
        error_message_s1: null,
        error_message_s2: null,

        menu_supervisor: []

    }),

    created() {

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        // fill in menu_supervisor array with a theoretical max number of messages in chat: max 500 messages
        for (var i = 0; i < 500; i++) {
            this.menu_supervisor.push(false);
        }

        CoachApi.GetCoachInfo().then(() => {

            CoachApi.GetCoachInfo().then(() => {

                CoachApi.GetThreadById(this.$route.params.thread_id).then((resp) => {

                    this.current_chat = resp.data

                    this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                    this.loaded = true

                })

            })

        })

    },

    watch: {

        '$route' () {

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

            // fill in menu_supervisor array with a theoretical max number of messages in chat: max 500 messages
            for (var i = 0; i < 500; i++) {
                this.menu_supervisor.push(false);
            }

            CoachApi.GetCoachInfo().then(() => {

                CoachApi.GetThreadById(this.$route.params.thread_id).then((resp) => {

                    this.current_chat = resp.data

                    this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                    this.loaded = true

                })

            })

        }


    },

    computed: {

        usergroup() {
            return this.$store.getters['store_auth/get_user_group']
        },

    },

    methods: {

        close_menu_supervisor(index) {

            // use Vue.set to make it reactiv
            this.$set(this.menu_supervisor, index, false)

        },

        send_critical_event() {

            if (this.event_type != null) {

                this.loading_send_critical_event = true

                var event_type = null

                if (this.event_type == 'Plan von Suizid'){
                    event_type = 1
                } else if (this.event_type == 'aktuell fortbestehende, gegenwärtige und konkrete Fremdgefährdungslage'){
                    event_type = 2
                } else if (this.event_type == 'Plan einer Straftat i.S.d. §§ 138, 139 StGB') {
                    event_type = 3
                } else {
                    event_type = 4
                }

                var data = {
                    client_user_id: this.current_chat.latest_message.sender.id,
                    event_type: event_type,
                    event_thread_id: this.current_chat.latest_message.thread,
                    event_message_id: this.current_chat.latest_message.id,
                    event_message_content: this.current_chat.latest_message.content,
                }

                CoachApi.ReportCriticalEventMessage(data).then(() => {

                    CoachApi.GetCoachInfo().then(() => {

                        CoachApi.GetThreadById(this.$route.params.thread_id).then((resp) => {

                            this.current_chat = resp.data

                            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                            this.loaded = true

                            this.$refs.new_message_form.reset()

                            this.attachmentName1 = ''
                            this.attachmentUrl1 = ''
                            this.attachmentFile1 = ''
                            
                            this.attachmentName2 = ''
                            this.attachmentUrl2 = ''
                            this.attachmentFile2 = ''
                            
                            this.attachmentName3 = ''
                            this.attachmentUrl3 = ''
                            this.attachmentFile3 = ''

                            this.file = null

                            this.loading_send_critical_event = false
                            this.menu_critical_event = false
                            this.event_type = null

                        })

                    })

                })

            } else {

                if (this.event_type == null) {
                    this.error_message_s1 = 'Sie müssen eine Auswahl treffen.'
                }
                
            }

        },

        change_s1() {
            this.error_message_s1 = null
        },

        getAttachmentName(att) {

            var tmp = att.split('/')

            return tmp[tmp.length - 1]

        },

        getChatPartnerName(item) {

            var partner = ''
            
            item.users.forEach(element => {

                if (element.username != this.$store.getters['store_auth/get_user_username']) {

                    partner = element.username

                } 
                
            });

            return partner

        },

        convertedSender(username) {

            if (username == this.$store.getters['store_auth/get_user_username']) {

                return 'Ich'

            } else {

                return username

            }


        },

        checkIfUnread(item) {

            var val = false

            item.user_threads.forEach(element => {

                if (element.user.username == this.$store.getters['store_auth/get_user_username']) {

                    if (element.unread) {

                        val = true

                    }
                }
                
            });

            return val

        },

        getNumberOfAttachments(msg) {

            var nr_msg = 0

            if (msg.attachment1) {

                nr_msg = nr_msg + 1

            } 

            if (msg.attachment2) {

                nr_msg = nr_msg + 1

            } 

            if (msg.attachment3) {

                nr_msg = nr_msg + 1

            } 

            return nr_msg

        },

        is_archived(item) {

            var tmp_archived = false

            for (var i = 0; i < item.user_threads.length; i++) {

                if (item.user_threads[i].deleted == true && item.user_threads[i].user.username == this.$store.getters['store_auth/get_user_username']) {

                    tmp_archived = true

                }
            }

            return tmp_archived

        },

        getCorrectDateTime(item) {
            return DateTimeHelpers.convertDatabaseDateTimeString('full', item)
        },
		
		onAttachmentPicked (i) {  

            this.loading_attachment = false

            if (i == 1) {
                this.attachmentFile1 = this.file;
                this.attachmentName1 = this.file.name
            } else if (i == 2) {
                this.attachmentFile2 = this.$refs.attachment2.files[0];
                this.attachmentName2 = this.$refs.attachment2.files[0].name
            } else if (i == 3) {
                this.attachmentFile3 = this.$refs.attachment3.files[0];
                this.attachmentName3 = this.$refs.attachment3.files[0].name
            }
		},

        sendMessage() {

            if (this.$refs.new_message_form.validate()) {

                this.sending = true

                var data = {
                    'newMessageValue': this.newMessageValue,
                    'current_thread_id': this.current_chat.id,
                    'attachmentFile1': this.attachmentFile1,
                    'attachmentFile2': this.attachmentFile2,
                    'attachmentFile3': this.attachmentFile3,
                }

                CoachApi.SendMessage(data).then(() => {

                    CoachApi.GetCoachInfo().then(() => {

                        CoachApi.GetThreadById(this.$route.params.thread_id).then((resp) => {

                            this.current_chat = resp.data

                            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                            this.loaded = true

                            this.$refs.new_message_form.reset()

                            this.attachmentName1 = ''
                            this.attachmentUrl1 = ''
                            this.attachmentFile1 = ''
                            
                            this.attachmentName2 = ''
                            this.attachmentUrl2 = ''
                            this.attachmentFile2 = ''
                            
                            this.attachmentName3 = ''
                            this.attachmentUrl3 = ''
                            this.attachmentFile3 = ''

                            this.file = null

                            this.sending = false

                        })

                    })

                })

            }

        }, 

        toArchive(current_chat) {

            /**
             * Summary. Updates the specified MyThread with regard to the field 'deleted'. 
             * */
            
            var data_toArchive = {
                'current_thread_id': current_chat.id,
            }

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

            CoachApi.ThreadToArchive(data_toArchive).then(() => {

                CoachApi.GetCoachInfo().then(() => {

                    CoachApi.GetThreadById(this.$route.params.thread_id).then((resp) => {

                        this.current_chat = resp.data

                        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                        this.loaded = true

                    })

                })

            })
             
        },

        undoThreadDeletion(current_chat) {
            /**
             * Summary. Updates the specified MyThread with regard to the field 'deleted'. 
             * */

            var data_undoToArchive = {
                'current_thread_id': current_chat.id,
            }

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

            CoachApi.UndoThreadToArchive(data_undoToArchive).then(() => {

                CoachApi.GetCoachInfo().then(() => {

                    CoachApi.GetThreadById(this.$route.params.thread_id).then((resp) => {

                        this.current_chat = resp.data

                        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                        this.loaded = true

                    })

                })

            })
        },

    },

}
</script>

<style scoped>
.note {
  white-space: pre-line; 
  word-wrap: break-word;
  font-family: inherit;
  text-align: left !important;
}
</style>