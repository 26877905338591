<template>

<div>

    <div>
        <!-- instruction -->
        <v-row>
            <v-col sm="12" cols="12">
                <v-card color="transparent" raised >
                    <v-card-text>
                        
                            
                            <div class="title mb-2"><span><v-icon large class="mr-3">far fa-clipboard</v-icon></span>Anleitung</div>

                            <div 
                                class="subtitle-1 mb-0" 
                                v-html="data.instruction"
                            ></div>

                    </v-card-text>
                </v-card>
            </v-col>


            <v-col sm="12" cols="12">

                <v-card 
                    color="transparent" 
                    raised 
                    width="100%" 
                    :class="getClass(i)"
                    v-for="(task, i) in data.task"
                    :key="i"
                >

                    <v-card-text>

                        <multiple-choice-pick-n-task v-if="task.type == 'multipleChoicePickNTask'" :id = i :taskID = id :question = "data.task[i].value.shortQuestion" :taskChoices = "data.task[i].value.taskChoices" />

                        <fill-in-the-gap-assignment-task v-if="task.type == 'fillInTheGapAssignment'" :id = i :taskID = id :question = "data.task[i].value.shortQuestion" :sentence = "data.task[i].value.sentence" />

                        <simple-assignment-task v-if="task.type == 'simpleAssignment'" :id = i :taskID = id :question = "data.task[i].value.shortQuestion" :assignmentList = "data.task[i].value.assignmentList" />
                            
                    </v-card-text>

                </v-card>

            </v-col>

           
        </v-row>

        <!-- check -->
        <v-row v-if="!$vuetify.breakpoint.mdAndDown">
            <v-col sm="12" cols="12">
                <v-card color="transparent" raised height="100%">
                    <v-card-text>
                        <v-row  align="center" class="ma-2">
                            <div v-if="!thisBtnDisabled">
                                <v-icon medium>fas fa-pencil-alt</v-icon>
                            </div>
                            <div v-else>
                                <v-icon medium>fas fa-check</v-icon>
                            </div>

                            <v-divider class="mx-4"/>

                            <div class="overline error--text" v-html="hint" v-if="hint == '<b>Hinweis:</b> Sie müssen alle Fragen richtig beantworten.'"></div>
                            <div class="overline success--text" v-html="hint" v-else></div>

                            <v-divider class="mx-4"/>

                            <v-btn 
                                rounded
                                outlined 
                                large 
                                color="primary" 
                                @click="save"
                                :disabled="thisBtnDisabled"
                            >Fertig</v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row v-else>
            <v-col sm="12" cols="12">
                <v-card color="transparent" raised height="100%">
                    <v-card-text>

                        <div class="overline error--text" v-html="hint" v-if="hint == '<b>Hinweis:</b> Sie müssen alle Fragen richtig beantworten.'"></div>
                        <div class="overline success--text" v-html="hint" v-else></div>

                        <v-row  align="center" class="ma-2">
                            <div v-if="!thisBtnDisabled">
                                <v-icon medium>fas fa-pencil-alt</v-icon>
                            </div>
                            <div v-else>
                                <v-icon medium>fas fa-check</v-icon>
                            </div>

                            <v-divider class="mx-4"/>

                            <v-btn 
                                rounded
                                outlined 
                                large 
                                color="primary" 
                                @click="save"
                                :disabled="thisBtnDisabled"
                            >Fertig</v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- DEV DIALOG -->
        <v-dialog v-model="dialog" persistent max-width="320">

            <v-card>
                <v-card-title class="headline">Bitte beachten Sie:</v-card-title>
                <v-card-text>
                    Da dieser Klient als Development-Klient definiert ist, haben sie die Wahl, 
                    ob sie die Daten speichern möchten oder nicht. Solange Sie an dieser Lektion 
                    arbeiten, wird empfohlen, die Daten <b>nicht</b> zu speichern.
                </v-card-text>
                <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="primary" text @click="doNotSave()">Nicht speichern</v-btn>
                <v-btn color="primary" text @click="saveDev()">Speichern</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

    </div>

</div>

    
</template>

<script>
import HelperClientIntervention from '@/_helpers/helper_client_intervention'

// tasks
import multipleChoicePickNTask from '@/components/_client/_intervention/_basicQuizBlocks/quiz_multipleChoicePickNTask.vue'
import fillInTheGapAssignmentTask from '@/components/_client/_intervention/_basicQuizBlocks/quiz_fillInTheGapAssignmentTask.vue'
import simpleAssignmentTask from '@/components/_client/_intervention/_basicQuizBlocks/quiz_simpleAssignmentTask.vue'



export default {
    name: 'basicQuiz',
    props: ['data', 'id'],
    components: {

      multipleChoicePickNTask,
      fillInTheGapAssignmentTask,
      simpleAssignmentTask,
      

    },

    data () {
        return {

            model: 0,

            dialog: false,

            dev_save: false,

            dev_checked: false,
            
        }
    },

    created() {

        // reset store
        this.$store.dispatch('store_quiz/RESET_STATE')

    },

    computed: {

        

        correct_answers_in_current_quiz() {

            return this.$store.getters['store_quiz/get_correct_answers_in_current_quiz']

        },
        
        thisBtnDisabled () {

                var tmp = this.$store.getters['store_lection/get_saved_lection_data']
                
                if (tmp.data[this.id].finished == false) {

                    // if all questions are answered correct:
                    if (this.$store.getters['store_quiz/get_correct_answers_in_current_quiz'] == this.data.task.length) {

                        return false

                    } else {

                        return true
                    }

                } else {

                    return true
                }

        },

        hint() {

            var tmp = this.$store.getters['store_lection/get_saved_lection_data']

            if (tmp.data[this.id].finished == false) {

                if (this.$store.getters['store_quiz/get_correct_answers_in_current_quiz'] == this.data.task.length) {

                    return '<b>Hinweis:</b> Sie haben alle Fragen richtig beantwortet.'

                } else {

                    return '<b>Hinweis:</b> Sie müssen alle Fragen richtig beantworten.'
                }

            } else {

                return '<b>Hinweis:</b> Sie haben alle Fragen richtig beantwortet.'
            }

        }

    },    

    methods: {

        getClass(i) {

            // if last task
            if (i == this.data.task.length) {
                return ''
            } else {
                return 'mb-3'
            }

        },

        // DEV ---
        doNotSave() {

            this.dev_save = false
            this.dev_checked = true
            this.dialog = false

            this.$store.dispatch('store_quiz/RESET_STATE')

            this.save()

        },

        saveDev() {

            this.dev_save = true
            this.dev_checked = true
            this.dialog = false

            this.save()

        },

        // DEV END ---

        // save data
       save() {

           if (this.$store.getters['store_client/get_client'].is_dev && this.dev_checked == false) {

               this.dialog = true

           } else if (this.$store.getters['store_client/get_client'].is_dev && this.dev_checked == true) {

               if (this.dev_save == true) {

                   const data = {
                        'save_lection_data': true,
                        'module': this.$route.params.module,
                        'session': this.$route.params.session,
                        'lection': this.$route.params.lection,
                        'id': this.id,
                        'type': 'quiz',
                        'reward': parseInt(this.data.reward) 
                    }

                    HelperClientIntervention.InterventionNext(data).then(() => {

                        // reset store
                        this.$store.dispatch('store_quiz/RESET_STATE')

                    })


               } else {

                   const data = {
                        'save_lection_data': false,
                        'module': this.$route.params.module,
                        'session': this.$route.params.session,
                        'lection': this.$route.params.lection,
                        'id': this.id,
                        'type': 'quiz',
                        'reward': parseInt(this.data.reward) 
                    }


                    HelperClientIntervention.InterventionNext(data).then(() => {

                        // reset store
                        this.$store.dispatch('store_quiz/RESET_STATE')

                        console.log('NEXT')

                    })

               }

           } else {

               const data = {
                   'save_lection_data': true,
                   'module': this.$route.params.module,
                   'session': this.$route.params.session,
                   'lection': this.$route.params.lection,
                   'id': this.id,
                   'type': 'quiz',
                   'reward': parseInt(this.data.reward) 
               }

               HelperClientIntervention.InterventionNext(data).then(() => {

                   // reset store
                    this.$store.dispatch('store_quiz/RESET_STATE')

               })

           }

       }

    },


}
</script>

<style scoped>
.quiz-content {
    margin-left: 3%;
    margin-right: 3%;
}
</style>

