<template>
     <v-container fluid class="pa-0">

        <v-row v-if="loaded">

            <v-col cols="12" sm="12">

                <v-row justify="center" align="center" v-if="msg_send">

                        <v-col cols="12" sm="12">

                            <v-alert 
                                
                                border="left"
                                colored-border
                                prominent
                                type="success"
                            >
                                
                                <v-row align="center">
                                    <v-col cols="auto" class="mr-auto">Der Chat wurde erfolgreich eröffnet.</v-col>
                                    <v-col cols="auto">
                                        <v-btn color="primary" outlined @click="msg_send = !msg_send">Neuer Chat</v-btn>
                                    </v-col>
                                </v-row>

                            </v-alert>

                        </v-col>

                </v-row>

                <v-form ref="new_message_form" v-model="new_message_form_valid" lazy-validation v-if="!msg_send" class="pa-0">

                    <v-row justify="center" align="center" class="mt-0 pt-0">

                        <v-col cols="12" sm="12" class="pa-0">

                            <v-select
                                v-model="to_user"
                                :items="receiver_names"
                                :rules="[v => v.length > 0 || 'Sie müssen eine*n Empfänger*in auswählen!']"
                                chips
                                label="An"
                                outlined
                                deletable-chips
                                required
                                color="primary"
                            ></v-select>

                        </v-col>
                    </v-row>
                
                    <v-row justify="center" align="center" class="pa-0">

                        <v-col cols="12" sm="12" class="pa-0">

                            <v-text-field
                                label="Betreff"
                                v-model="subject"
                                counter="120"
                                :rules="new_thread_form_subject_rules"
                                outlined  
                                required 
                                color="primary" 
                            ></v-text-field>

                        </v-col>

                    </v-row>

                    <v-row justify="center" align="center" class="pa-0">

                        <v-col cols="12" sm="12" class="pa-0">

                            <v-textarea
                                name="input-7-1"
                                label="Neue Nachricht:"
                                v-model="newMessageValue"
                                :rules="new_message_form_content_rules"
                                auto-grow
                                required
                                outlined
                                color="primary"
                            ></v-textarea>

                        </v-col>

                        <v-col cols="12" sm="12" class="pa-0">

                            <div class="caption"><b>Bitte beachten Sie:</b> Zum Schutz der Daten anderer Personen, vermeiden Sie es unbedingt, persönliche Daten von jemand anderem in Ihren Nachrichten zu nennen, z.B. vollständige Namen oder Adressen von Menschen, die Sie kennen.</div>

                        </v-col>

                        <v-col>

                            <v-toolbar
                                flat
                                short
                                id="fooAnchor_attachment"
                            >

                                <v-file-input 
                                    v-if="usergroup == 'Coach'"
                                    hide-details
                                    show-size 
                                    accept=".pdf"
                                    label="Wählen Sie einen Anhang aus (.pdf)"
                                    v-model="file"
                                    @change="onAttachmentPicked(1)"
                                    :loading="loading_attachment"
                                    @click="loading_attachment = true"
                                ></v-file-input>

                                <v-spacer />

                                <v-btn 
                                    @click="sendMessage"
                                    :loading="sending"
                                    color='primary'
                                    outlined
                                    class="ml-3"
                                >
                                    <v-icon left color="primary">far fa-paper-plane</v-icon><span class="ml-3" v-if="$vuetify.breakpoint.smAndUp">Versenden</span>
                                </v-btn>

                            </v-toolbar>

                        </v-col>

                    </v-row>
                </v-form>

            </v-col>

        </v-row>

    </v-container>

</template>

<script>
import SupervisorApi from '@/_api/supervisor_api'

export default {
    name: 'supervisor-new-message',

    data: () => ({

        msg_send: false,
        
        new_thread_form_valid: true,
        new_thread_form_subject_rules: [
                v => !!v || 'Sie müssen einen Betreff angeben!',
                v => (v && v.length <= 120) || 'Der Betreff darf nicht länger als 120 Zeichen sein!'
            ],
        new_thread_form_content_rules: [
                v => !!v || 'Der Inhalt der Nachricht darf nicht leer sein!'],


        new_message_form_valid: true,
        new_message_form_content_rules: [
                v => !!v || 'Der Inhalt der Nachricht darf nicht leer sein!'],

        newMessageValue: null,

        // send message
        to_user: [],
        receiver: [],
        receiver_names: [],
        subject: null,
        content: null,

        add_attachment_menu: false,

        file: null,

        attachmentName1: '',
        attachmentUrl1: '',
        attachmentFile1: '',
        
        attachmentName2: '',
        attachmentUrl2: '',
        attachmentFile2: '',
        
        attachmentName3: '',
        attachmentUrl3: '',
        attachmentFile3: '',

        sending: false,

        loading_attachment: false,

        loaded: false

    }),

    created() {

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        SupervisorApi.GetSupervisorInfo().then(() => {

            SupervisorApi.GetSupervisorAllClientsData().then((resp) => {

                var client = {username: null, id: null}

                for (var i = 0; i < resp.data.length; i++) {

                    client = {username: null, id: null}

                    client.username = resp.data[i].username
                    client.id = resp.data[i].client_user_id

                    if (resp.data[i].status != 7) {

                        this.receiver.push(client)
                        this.receiver_names.push(client.username)

                    }

                }

                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                this.loaded = true

            })

        })

    },

    computed: {

        usergroup() {
            return this.$store.getters['store_auth/get_user_group']
        },

    },

    methods: {

        pickAttachment (i) {
            if (i == 1) {
                this.$refs.attachment1.click ()
            } else if (i == 2) {
                this.$refs.attachment2.click ()
            } else if (i == 3) {
                this.$refs.attachment3.click ()
            }
            
        },
		
		onAttachmentPicked (i) {  

            this.loading_attachment = false

            if (i == 1) {
                this.attachmentFile1 = this.file;
                this.attachmentName1 = this.file.name
            } else if (i == 2) {
                this.attachmentFile2 = this.$refs.attachment2.files[0];
                this.attachmentName2 = this.$refs.attachment2.files[0].name
            } else if (i == 3) {
                this.attachmentFile3 = this.$refs.attachment3.files[0];
                this.attachmentName3 = this.$refs.attachment3.files[0].name
            }
		},
        
        getIdsOfReceiver() {

            var j;
            var tmp = []

                
            for (j = 0; j < this.receiver.length; j++) {
                
                if (this.to_user == this.receiver[j].username) {
                    tmp.push(this.receiver[j].id)
                }
            }

            return tmp

        },

        sendMessage() {

              /**
             * Summary. Creates a new thread with a message. Automatically creates necessary MyThreads for
             * all receiver of the message.
             * */

             if (this.$refs.new_message_form.validate()) {

                 this.sending = true


                var data_tmp = {

                    "content": this.newMessageValue,
                    "receiver": this.getIdsOfReceiver(),
                    "subject": this.subject,

                    "is_technical_help": 'False',

                    'attachmentFile1': this.attachmentFile1,
                    'attachmentFile2': this.attachmentFile2,
                    'attachmentFile3': this.attachmentFile3,

                }
                

                SupervisorApi.CreateNewThread(data_tmp).then(()=>{ 

                        // close dialogue
                        this.$refs.new_message_form.reset()

                        this.attachmentName1 = ''
                        this.attachmentUrl1 = ''
                        this.attachmentFile1 = ''
                        
                        this.attachmentName2 = ''
                        this.attachmentUrl2 = ''
                        this.attachmentFile2 = ''
                        
                        this.attachmentName3 = ''
                        this.attachmentUrl3 = ''
                        this.attachmentFile3 = ''

                        this.sending = false

                        this.msg_send = true
                        
                    })
             }

        }

    }
}
</script>

<style scoped>

</style>