<template>

    <v-container>

        <v-row>

            <v-col>
                <v-card
                    class="pa-2"
                    height="100%"
                    outlined
                    tile
                >
                    <v-img
                        class="ma-3 align-end"
                        height="80px"
                        src="/images/main/phone-square-solid-error.png"
                        contain
                    ></v-img>

                    <v-card-text class="text-center title">
                        Telefonseelsorge
                    </v-card-text>

                    <v-card-text class="pb-0">
                        
                            <div class="title primary--text text-center">Sorgen kann man teilen</div>

                            <div class="headline primary--text my-3 font-weight-bold text-center">
                                <span>
                                    <v-icon large color="error">fas fa-phone-volume</v-icon>
                                </span>
                                0800-111 0 111
                            </div>

                            <div class="overline my-3 text-center">Erreichbarkeit:<br/>24 Stunden an 365 Tagen</div>

                    </v-card-text>

                </v-card>
            </v-col>

            <v-col>
                <v-card
                    class="pa-2"
                    height="100%"
                    outlined
                    tile
                >
                    <v-img
                        class="ma-3 align-end"
                        height="80px"
                        src="/images/main/phone-square-solid-error.png"
                        contain
                    ></v-img>

                    <v-card-text class="text-center title">
                        Notruf
                    </v-card-text>

                    <v-card-text class="pb-0">

                            <div class="title primary--text text-center">Polizei</div>

                            <div class="headline primary--text my-3 font-weight-bold text-center">
                                <span>
                                    <v-icon large color="error">fas fa-phone-volume</v-icon>
                                </span>
                                110
                            </div>

                            <div class="overline my-3 text-center">Erreichbarkeit:<br/>24 Stunden an 365 Tagen</div>

                    </v-card-text>

                </v-card>
            </v-col>

            <v-col>
                <v-card
                    class="pa-2"
                    height="100%"
                    outlined
                    tile
                >
                    <v-img
                        class="ma-3 align-end"
                        height="80px"
                        src="/images/main/phone-square-solid-error.png"
                        contain
                    ></v-img>

                    <v-card-text class="text-center title">
                        Notruf
                    </v-card-text>

                    <v-card-text class="pb-0">
                        
                            <div class="title primary--text text-center">Rettungsdienst</div>

                            <div class="headline primary--text my-3 font-weight-bold text-center">
                                <span>
                                    <v-icon large color="error">fas fa-phone-volume</v-icon>
                                </span>
                                112
                            </div>

                            <div class="overline my-3 text-center">Erreichbarkeit:<br/>24 Stunden an 365 Tagen</div>

                    </v-card-text>

                </v-card>
            </v-col>



            <v-col>
                <v-card
                    class="pa-2"
                    height="100%"
                    outlined
                    tile
                >
                    <v-img
                        class="ma-3 align-end"
                        height="80px"
                        src="/images/main/phone-square-solid-error.png"
                        contain
                    ></v-img>

                    <v-card-text class="text-center title">
                        Bewährungshilfe
                    </v-card-text>

                    <v-card-text class="pb-0">
                        
                            <div class="title primary--text text-center">{{ supervisor_name }}</div>

                            <div class="headline primary--text my-3 font-weight-bold text-center">
                                <span>
                                    <v-icon large color="error">fas fa-phone-volume</v-icon>
                                </span>
                                {{ supervisor_tel }}
                            </div>

                            <div class="overline my-3 text-center">Erreichbarkeit:<br/>entsprechend der Geschäftszeiten Ihrer Bewährungshilfe</div>

                    </v-card-text>

                </v-card>
            </v-col>

        </v-row>

    </v-container>

</template>

<script>
//import ClientApi from '@/_api/client_api'

export default {
    name: 'client-emergency-error',

    data: () => ({
        //
    }),

    created() {

    },

    computed: {

        supervisor_name() {
            var s_first_name = this.$store.getters['store_client/get_client_supervisor'].firstname
            var s_last_name = this.$store.getters['store_client/get_client_supervisor'].last_name

            return s_first_name + ' ' + s_last_name
        },

        supervisor_tel() {
            return this.$store.getters['store_client/get_client_supervisor'].telephone
        }

    },

    methods: {

        emergency_accent() {

            this.$router.push('/client/emergency/accent')
            
        },

    }
}
</script>

<style scoped>

</style>