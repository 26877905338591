<template>

        <v-container fluid v-if="loaded">

        <!-- KLIENTEN -->

        <v-card flat>
        
            <v-card-title class="display-1">
                Blitzlicht (Prämessung)
            </v-card-title>

            <v-card-text>

                <v-card flat outlined>

                    <v-expansion-panels flat>

                        <v-expansion-panel>

                            <v-expansion-panel-header>

                                <v-card flat class="py-0 my-0">

                                    <v-card-text class="pl-0 title py-0 my-0">
                                        Informationen zum Blitzlicht (Prämessung)
                                    </v-card-text>

                                </v-card>

                                <template v-slot:actions>
                                    <v-icon color="primary">$expand</v-icon>
                                </template>
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>

                                <v-card flat class="mt-3">

                                    <v-card-text class="black--text px-0 mx-0">
                                        <p>
                                            Das sog. Blitzlicht ist ein häufig in der Verhaltenstherapie eingesetztes Verfahren, um die aktuelle Stimmung 
                                            eines*einer Klienten*Klientin zu erfassen. Das Blitzlicht (Prämessung) wird vor jeder Sitzung erhoben und 
                                            erfasst ausschließlich den Zeitraum zwischen zwei Sitzungen (i. d. R. eine Woche). Anhand der Werte der Prämessung des
                                            Blitzlichtes können demnach Rückschlüsse auf eine Veränderung bzgl. der Items zwischen zwei Sitzungen gezogen werden. 
                                        </p>
                                        <p>
                                            Drei Fragen des Blitzlichtes beziehen sich auf die emotionale und psychische Verfassung des Klienten:
                                        </p>
                                        <p>
                                            <i>"Ich fühle mich aktuell aufgrund seelischer Probleme in meinem Verhalten und Erleben beeinträchtigt."</i> Diese 
                                            Frage erhebt aktuell bestehenden seelische Probleme. Auf einer fünf-stufigen Skala soll der*die Klient*in angeben, ob 
                                            Sie sich aktuell in Ihrem verhalten und Erleben aufgrund psychischer Probleme beeinträchtigt fühlt. Die Skala 
                                            geht dabei von 'stimmt gar nicht' bis 'stimmt völlig'.
                                        </p>
                                        <p>
                                            <i>"Wie fühlen Sie sich im Augenblick?"</i> Das Item fragt auf einer sechs-stufigen Skala die aktuelle Stimmung ab. Die Skala 
                                            geht dabei von 'sehr gut' bis 'sehr schlecht'.
                                        </p>
                                        <p>
                                            <i>"Hatten Sie in den letzten Tagen das Gefühl, Belastungen nicht mehr aushalten zu können?"</i> Diese 
                                            Frage bezieht sich auf aktuelle Belastungssituationen. Auf einer drei-stufigen Skala soll soll der*die Klient*in angeben, wie oft das Gefühl auftrat,
                                            aktuelle Belastungen nicht mehr aushalten zu können. Die Skala reicht von 'Nie' bis 'Oft'.
                                        </p>
                                        <p>
                                            Für @myTabu wurde, zusätzlich zu den üblicherweise eingesetzten Fragen zur Stimmung und psychischen Verfassung, Fragen ergänzt, 
                                            die sich explizit auf das sexuelle Erleben beziehen. Diese Items entsprechen einem Teil der Items aus dem Acute-2007-SR und sollen 
                                            diese Aspekte in einer höheren Frequenz abbilden als der Acute-2007-SR. 
                                        </p>
                                    </v-card-text>

                                </v-card>

                            </v-expansion-panel-content>

                        </v-expansion-panel>

                    </v-expansion-panels>

                </v-card>

                <v-card flat outlined class="mt-5">

                    <v-card-text class="primary--text body-1">
                        <b>Wichtig:</b> Das Blitzlicht stellt kein Instrument zur Risikoprognose dar! Es kann aber einen Hinweis geben, dass es Ihrem*r Klienten*Klientin 
                        im Augenblick emotional oder seelisch nicht so gut geht oder ob sich sein*ihr sexuelles Erleben möglicherweise auf deviante Inhalte einengt. Steht die Ampel auf rot, 
                        sollten Sie auf jeden Fall in Ihrem nächsten Gespräch einmal nachfragen. Beachten Sie bitte auch die juristischen Hinweise zu einigen der Items.
                    </v-card-text>

                </v-card>

            </v-card-text>

            <v-card-text>
                <v-card flat outlined>

                    <v-card-text>
                        <v-row>

                            <v-col cols="12" sm="12" md="6">
                                <v-autocomplete
                                    v-model="dropdown_client_value"
                                    :items="dropdown_client"
                                    dense
                                    label="Welche*r Klient*in"
                                    @change="update_client"
                                    :loading="loading_dropdown_client"
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="12" sm="12" md="6">
                                <v-autocomplete
                                    :items="dropdown_timepoints"
                                    label="Messzeitpunkt"
                                    dense
                                    v-model="dropdown_timepoints_value"
                                    @change="update_timepoint"
                                ></v-autocomplete>
                            </v-col>

                        </v-row>

                    </v-card-text>

                </v-card>
            </v-card-text>

            <v-card-text>
                <v-card flat outlined>
            
                    <v-data-table
                        :headers="headers"
                        :items="current_data"
                        :items-per-page="10"
                        hide-default-footer
                        no-data-text="Für diese*diesen Klienten*Klientin liegen noch keine Daten vor."
                    >

                        <template v-slot:item.item="{ item }">
                            <span class="subtitle-1 primary--text">{{ item.item }}</span>
                        </template>

                         <template v-slot:item.raw_value_2="{ item }">
                            <span class="subtitle-1 text-center">{{item.raw_value}}</span>
                        </template>

                        <template v-slot:item.risk_value="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    
                                    <span v-bind="attrs" v-on="on">
                                        <v-row align="center" justify="center">
                                            <v-icon :color="item.risk_value | getIconColorOne()">{{ item.risk_value | getIconOne() }}</v-icon>
                                            <v-icon :color="item.risk_value | getIconColorTwo()">{{ item.risk_value | getIconTwo() }}</v-icon>
                                            <v-icon :color="item.risk_value | getIconColorThree()">{{ item.risk_value | getIconThree() }}</v-icon>
                                        </v-row>
                                    </span>

                                </template>

                                <!-- CHOICES_1 (Acute-Items) -->
                                <span v-if="item.item != 'Wie fühlen Sie sich im Augenblick?' && item.item != 'Ich fühle mich aktuell aufgrund seelischer Probleme in meinem Verhalten und Erleben beeinträchtigt.'">
                                    <v-icon color="green lighten-1" small>fas fa-circle</v-icon><span class="ml-1 mr-3 body-2">Nein</span>
                                    <v-icon color="orange lighten-1" small>fas fa-circle</v-icon><span class="ml-1 mr-3 body-2">Manchmal</span>
                                    <v-icon color="red lighten-1" small>fas fa-circle</v-icon><span class="ml-1 body-2">Oft</span>
                                </span>

                                <span v-if="item.item == 'Wie fühlen Sie sich im Augenblick?'">
                                    <v-icon color="green lighten-1" small>fas fa-circle</v-icon><span class="ml-1 mr-3 body-2">sehr gut / gut / eher gut</span>
                                    <v-icon color="orange lighten-1" small>fas fa-circle</v-icon><span class="ml-1 mr-3 body-2">eher schlecht / schlecht</span>
                                    <v-icon color="red lighten-1" small>fas fa-circle</v-icon><span class="ml-1 body-2">sehr schlecht</span>
                                </span>

                                <span v-if="item.item == 'Ich fühle mich aktuell aufgrund seelischer Probleme in meinem Verhalten und Erleben beeinträchtigt.'">
                                    <v-icon color="green lighten-1" small>fas fa-circle</v-icon><span class="ml-1 mr-3 body-2">stimmt gar nicht / stimmt eher nicht</span>
                                    <v-icon color="orange lighten-1" small>fas fa-circle</v-icon><span class="ml-1 mr-3 body-2">stimmt teilweise / stimmt eher</span>
                                    <v-icon color="red lighten-1" small>fas fa-circle</v-icon><span class="ml-1 body-2">stimmt völlig</span>
                                </span>

                            </v-tooltip>
                        </template>

                        <template v-slot:item.help_btn="{ item }">
                            <span>
                                <v-tooltip bottom v-if="show_help_btn(item)">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn 
                                            icon 
                                            color="primary'"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="go_to_help(item)"
                                        >
                                            <v-icon>far fa-question-circle</v-icon>
                                        </v-btn>
                                    </template>
                                <span>Handlungsempfehlung ansehen</span>
                                </v-tooltip>
                            </span>
                        </template>

                        <template v-slot:item.raw_value="{ item }">
                            <span><v-btn color="primary" outlined @click="open_curve(item.item)">Verlauf</v-btn></span>
                        </template>

                    </v-data-table>

                </v-card>
            </v-card-text>

        </v-card>

        <!-- MONEY HAS PAYED DIALOG -->
    <v-dialog
        v-model="show_curve"
        persistent
        max-width="800"
    >
        <v-card outlined>

            <v-card-text class="title primary--text text-center mt-5">
                {{ show_curve_title }}
            </v-card-text>

            <v-divider />

            <v-card-text class="mt-5 caption text-center">

                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left title">
                                    Messzeitpunkt
                                </th>
                                <th class="text-center title">
                                    Wert
                                </th>
                                <th class="text-center title">
                                    Einschätzung
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, i) in current_curve_data.labels"
                                :key="item.name"
                            >
                                <td class="text-left">{{ item }}</td>
                                <td class="text-center">{{ current_curve_data.values[i] }}</td>
                                <td class="text-center">
                                    <v-row align="center" justify="center">
                                        <v-icon :color="current_curve_data.values[i] | getIconColorOne()">{{ current_curve_data.values[i] | getIconOne() }}</v-icon>
                                        <v-icon :color="current_curve_data.values[i] | getIconColorTwo()">{{ current_curve_data.values[i] | getIconTwo() }}</v-icon>
                                        <v-icon :color="current_curve_data.values[i] | getIconColorThree()">{{ current_curve_data.values[i] | getIconThree() }}</v-icon>
                                    </v-row>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                
            </v-card-text>

            <v-divider />

            <v-card-actions class="my-3">
                
                <v-spacer />

                <v-btn
                    outlined
                    rounded
                    @click="show_curve = false"
                >
                    Schließen
                </v-btn>
            </v-card-actions>
            
        </v-card>

    </v-dialog>

    <!-- HelpDIALOG -->
        <v-dialog
            v-model="dialog_help"
            persistent
        >
            <v-card outlined>

                <v-card-text class="title primary--text text-center mt-5">
                    Handlungsempfehlung
                </v-card-text>

                <v-card-text class="black--text text-center">
                    zu Item
                </v-card-text>

                <v-card-text class="subtitle-2 primary--text text-center">
                    "{{ help_item.item }}"
                </v-card-text>

                <v-divider />

                <v-card-text class="mt-5">
                    
                    <FlashlightHelp :data="dialog_help_content" :federal_state="$store.getters['store_supervisor/get_supervisor'].federal_state" />
                    
                </v-card-text>

                <v-divider />

                <v-card-actions class="my-3">
                    
                    <v-spacer />

                    <v-btn
                        outlined
                        rounded
                        color="primary"
                        @click="dialog_help = false"
                    >
                        Schließen
                    </v-btn>
                </v-card-actions>
                
            </v-card>

        </v-dialog>

    </v-container>

</template>

<script>
import SupervisorApi from '@/_api/supervisor_api'

import AcuteHelper from '@/_helpers/helper_supervisor'

import FlashlightHelp from '@/components/_supervisor/risk_help/dhpol_guides'

export default {
    name: 'supervisor-risk-flashlight-pre',

    components: {
        FlashlightHelp
    },

    data: () => ({

        loaded: false,

        dialog_help: false,
        dialog_help_content: '',
        help_item: '',

        search: '',

        all_data: {},

        loading_dropdown_client: false,

        dropdown_client: [],
        dropdown_client_value: null,
        dropdown_timepoints: [],
        dropdown_timepoints_value: null,
        
        headers: [
          {
            text: 'Item',
            align: 'left',
            sortable: false,
            value: 'item',
            class: "black--text subtitle-1 font-weight-bold"
          },
          { 
              text: 'Rohwert', 
              value: 'raw_value_2',
              sortable: false,
              align: 'center',
              class: "black--text subtitle-1 font-weight-bold"
          },
          { 
              text: 'Einschätzung', 
              value: 'risk_value',
              align: 'center',
              class: "black--text subtitle-1 font-weight-bold"
          },
          { 
              text: 'Hilfe', 
              value: 'help_btn',
              sortable: false,
              align: 'center',
              class: "black--text subtitle-1 font-weight-bold"
          },
          { 
              text: 'Verlauf', 
              value: 'raw_value',
              sortable: false,
              align: 'center',
              class: "black--text subtitle-1 font-weight-bold"
          },
        ],

        current_data: [],

        current_curve_data: {},
        show_curve: false,
        show_curve_title: ''

    }),

    created() {

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        if (this.$route.params.search_prop == 'null') {
            this.dropdown_client_value = this.clients[0].username
        } else {
            this.dropdown_client_value = this.$route.params.search_prop
        }

        var client = this.clients.find(e => e.username === this.dropdown_client_value);

        SupervisorApi.GetRiskAssessmentFlashlightPre(client.client_id).then((resp) => {

            this.all_data = resp.data

            this.dropdown_client = resp.data.clients

            this.dropdown_timepoints = resp.data.flashlight['timepoints']

            this.dropdown_timepoints_value = this.dropdown_timepoints[0]

            this.current_data = resp.data.flashlight[this.dropdown_timepoints_value]

            this.loaded = true
            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

        })

    },

    computed: {

        clients() {
            return this.$store.getters['store_supervisor/get_supervisor_clients']
        },

    },

    filters: {

        getIconOne(value) {

            if (value == 0) {
                return 'fas fa-circle'
            }
            else {
                return 'far fa-circle'
            }
            
        },

        getIconColorOne(value) {
            
            if (value == 0) {
                return 'green lighten-1'
            }
            else {
                return ''
            }
            
        },

        getIconTwo(value) {
            
            if (value == 1) {
                return 'fas fa-circle'
            }
            else {
                return 'far fa-circle'
            }

        },

        getIconColorTwo(value) {
            
            if (value == 1) {
                return 'orange lighten-1'
            }
            else {
                return ''
            }

        },

        getIconThree(value) {
            
            if (value == 2) {
                return 'fas fa-circle'
            }
            else {
                return 'far fa-circle'
            }

        },

        getIconColorThree(value) {
            
            if (value == 2) {
                return 'red lighten-1'
            }
            else {
                return ''
            }

        },
    },

    methods: {

        show_help_btn(item) {

            if (item.item == "Haben Sie in den letzten Tagen darüber nachgedacht, wie Sie am besten mit einem Kind in Kontakt kommen können?") {
                return true
            } else if (item.item == "Haben Sie in den letzten Tagen Vorbereitungen getroffen, um mit einem Kind in Kontakt kommen zu können?") {
                return true
            } else if (item.item == "Hatten Sie in den letzten Tagen das Gefühl, eine sexuelle Handlung mit einem Kind begehen zu müssen?") {
                return true
            } else if (item.item == "Haben Sie in den letzten Tagen daran gedacht, sich das Leben zu nehmen?") {
                return true
            } else {
                return false
            }

        },

        go_to_help(item) {

            this.help_item = item

            var data = AcuteHelper.get_help_flashlight_pre(item.item)

            this.dialog_help = true
            this.dialog_help_content = data.content

        },

        update_timepoint() {
            this.current_data = this.all_data.flashlight[this.dropdown_timepoints_value]
        },

        update_client() {

            this.loading_dropdown_client = true

            var client = this.clients.find(e => e.username === this.dropdown_client_value);

            SupervisorApi.GetRiskAssessmentFlashlightPre(client.client_id).then((resp) => {

                this.all_data = resp.data

                this.dropdown_client = resp.data.clients

                this.dropdown_timepoints = resp.data.flashlight['timepoints']

                this.dropdown_timepoints_value = this.dropdown_timepoints[0]

                this.current_data = resp.data.flashlight[this.dropdown_timepoints_value]

                this.loading_dropdown_client = false
                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

            })

        },

        open_curve(item){

            this.show_curve_title = item
            this.current_curve_data = this.all_data.flashlight[item]
            this.show_curve = true

        }
    }
}
</script>

<style scoped>

</style>