import store from '@/store/store'
import ClientApi from '@/_api/client_api'

//import _ from 'lodash'



const InterventionNext = (data) => new Promise ((resolve) => {
    /**
   * Summary. Function to control the saving of intervention.
   */

    // update currentLessionData.finished
    store.dispatch('store_lection/UPDATE_SAVED_LECTION_DATA_FINISHED', {'id': data.id, 'data': true})
    
    // disable buttons
    store.dispatch('store_lection/UPDATE_SAVED_LECTION_DATA_BTN_DISABLED', {'id': data.id, 'data': true})

    // es existiert kein weiterer Block mehr === LETZTER BLOCK
    if (data.id == store.getters['store_lection/get_saved_lection_data'].data.length - 1) {

        // set currentLessionData.lession_finished to true
        store.dispatch('store_lection/UPDATE_SAVED_LECTION_DATA_LECTION_FINISHED', {'id': data.id, 'data': true})

    // es existiert noch genau ein weiterer Block
    } else if (data.id == store.getters['store_lection/get_saved_lection_data'].data.length - 2) {

        // check if next block is optionalTask or optionalInfo
        if (store.getters['store_lection/get_saved_lection_data'].data[data.id + 1].type == 'optionalTask' || store.getters['store_lection/get_saved_lection_data'].data[data.id + 1].type == 'optionalInfo') {

            store.dispatch('store_lection/UPDATE_SAVED_LECTION_DATA_LECTION_FINISHED', {'id': data.id, 'data': true})
            store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 1, 'data': true})

        } else {

            store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 1, 'data': true})

        }

    // es existieren noch genau zwei weitere Blöcke
    } else if (data.id == store.getters['store_lection/get_saved_lection_data'].data.length - 3) {

        // check if block + 1 is optionalTask or optionalInfo
        if (store.getters['store_lection/get_saved_lection_data'].data[data.id + 1].type == 'optionalTask' || store.getters['store_lection/get_saved_lection_data'].data[data.id + 1].type == 'optionalInfo') {

            store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 1, 'data': true})

            // check if block + 2 is optionalTask or optionalInfo
            if (store.getters['store_lection/get_saved_lection_data'].data[data.id + 2].type == 'optionalTask' || store.getters['store_lection/get_saved_lection_data'].data[data.id + 2].type == 'optionalInfo') {
                
                store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 2, 'data': true})

                store.dispatch('store_lection/UPDATE_SAVED_LECTION_DATA_LECTION_FINISHED', {'id': data.id, 'data': true})
    
            } else {
    
                store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 2, 'data': true})
    
            }

        } else {

            store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 1, 'data': true})

        }


    // es existieren noch genau drei weitere Blöcke
    } else if (data.id == store.getters['store_lection/get_saved_lection_data'].data.length - 4) {

        // check if block + 1 is optionalTask or optionalInfo
        if (store.getters['store_lection/get_saved_lection_data'].data[data.id + 1].type == 'optionalTask' || store.getters['store_lection/get_saved_lection_data'].data[data.id + 1].type == 'optionalInfo') {

            store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 1, 'data': true})

            // check if block + 2 is optionalTask or optionalInfo
            if (store.getters['store_lection/get_saved_lection_data'].data[data.id + 2].type == 'optionalTask' || store.getters['store_lection/get_saved_lection_data'].data[data.id + 2].type == 'optionalInfo') {
                
                store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 2, 'data': true})

                // check if block + 3 is optionalTask or optionalInfo
                if (store.getters['store_lection/get_saved_lection_data'].data[data.id + 3].type == 'optionalTask' || store.getters['store_lection/get_saved_lection_data'].data[data.id + 3].type == 'optionalInfo') {

                    store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 3, 'data': true})

                    store.dispatch('store_lection/UPDATE_SAVED_LECTION_DATA_LECTION_FINISHED', {'id': data.id, 'data': true})
        
                } else {
        
                    store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 3, 'data': true})
        
                }
    
            } else {
    
                store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 2, 'data': true})
    
            }

        } else {

            store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 1, 'data': true})

        }


    // es existieren noch genau vier weitere Blöcke
    } else if (data.id == store.getters['store_lection/get_saved_lection_data'].data.length - 5) {

        // check if block + 1 is optionalTask or optionalInfo
        if (store.getters['store_lection/get_saved_lection_data'].data[data.id + 1].type == 'optionalTask' || store.getters['store_lection/get_saved_lection_data'].data[data.id + 1].type == 'optionalInfo') {

            store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 1, 'data': true})

            // check if block + 2 is optionalTask or optionalInfo
            if (store.getters['store_lection/get_saved_lection_data'].data[data.id + 2].type == 'optionalTask' || store.getters['store_lection/get_saved_lection_data'].data[data.id + 2].type == 'optionalInfo') {

                store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 2, 'data': true})

                // check if block + 3 is optionalTask or optionalInfo
                if (store.getters['store_lection/get_saved_lection_data'].data[data.id + 3].type == 'optionalTask' || store.getters['store_lection/get_saved_lection_data'].data[data.id + 3].type == 'optionalInfo') {

                    store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 3, 'data': true})

                    // check if block + 4 is optionalTask or optionalInfo
                    if (store.getters['store_lection/get_saved_lection_data'].data[data.id + 4].type == 'optionalTask' || store.getters['store_lection/get_saved_lection_data'].data[data.id + 4].type == 'optionalInfo') {

                        store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 4, 'data': true})

                        store.dispatch('store_lection/UPDATE_SAVED_LECTION_DATA_LECTION_FINISHED', {'id': data.id, 'data': true})
            
                    } else {

                        store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 4, 'data': true})
            
                    }
        
                } else {

                    store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 3, 'data': true})
        
                }
    
            } else {

                store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 2, 'data': true})
    
            }

        } else {

            store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 1, 'data': true})

        }
    
    // es existieren noch mehr als vier weitere Blöcke
    } else {

        // check if block + 1 is optionalTask or optionalInfo
        if (store.getters['store_lection/get_saved_lection_data'].data[data.id + 1].type == 'optionalTask' || store.getters['store_lection/get_saved_lection_data'].data[data.id + 1].type == 'optionalInfo') {

            store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 1, 'data': true})

            // check if block + 2 is optionalTask or optionalInfo
            if (store.getters['store_lection/get_saved_lection_data'].data[data.id + 2].type == 'optionalTask' || store.getters['store_lection/get_saved_lection_data'].data[data.id + 2].type == 'optionalInfo') {

                store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 2, 'data': true})

                // check if block + 3 is optionalTask or optionalInfo
                if (store.getters['store_lection/get_saved_lection_data'].data[data.id + 3].type == 'optionalTask' || store.getters['store_lection/get_saved_lection_data'].data[data.id + 3].type == 'optionalInfo') {

                    store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 3, 'data': true})

                    // check if block + 4 is optionalTask or optionalInfo
                    if (store.getters['store_lection/get_saved_lection_data'].data[data.id + 4].type == 'optionalTask' || store.getters['store_lection/get_saved_lection_data'].data[data.id + 4].type == 'optionalInfo') {

                        store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 4, 'data': true})

                        store.dispatch('store_lection/UPDATE_SAVED_LECTION_DATA_LECTION_FINISHED', {'id': data.id, 'data': true})
            
                    } else {

                        store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 4, 'data': true})
            
                    }
        
                } else {

                    store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 3, 'data': true})
        
                }
    
            } else {

                store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 2, 'data': true})
    
            }

        } else {

            store.dispatch('store_lection/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': data.id + 1, 'data': true})

        }

    }

    // save lection data
    if (data.save_lection_data == true) {

        store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        // if guided-task
        if (data.type == 'guided_task') {

            var original_task_data_tmp = store.getters['store_lection/get_saved_lection_task_data_by_identifier'](data.wagtail_data.taskID)

            var wagtail_task_data = data.wagtail_data.task[0]
            wagtail_task_data["goal"] = data.wagtail_data.goal
            wagtail_task_data["instruction"] = data.wagtail_data.instruction
            original_task_data_tmp['wagtail_task_data'] = wagtail_task_data     

            const faked_data = {

                waiting_for_client: false,
                waiting_for_coach: true,
                original_task_data: original_task_data_tmp,
                revision_finished: false

            }
            
            store.dispatch('store_lection/DEFINE_SAVED_LECTION_GUIDED_TASK_DATA_NEW_DATA_IDENTIFIER', {'taskID': data.wagtail_data.taskID})
            store.dispatch('store_lection/ADD_SAVED_LECTION_GUIDED_TASK_DATA_PROPERTY_AND_DATA', {'identifier':data.wagtail_data.taskID, 'property_name': 'data', 'data': faked_data})

        }

        const tmp = {
            'module_nr': parseInt(data.module),
            'session_nr': parseInt(data.session),
            'lesson_rank': parseInt(data.lection)
        }
        
        ClientApi.SaveLectionData(tmp).then(() => {

            store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

            if (store.getters['store_client/get_client_progress'].introduction_finished) {
                var intervention_started = true
            } else {
                intervention_started = false
            }
    
            const progress_data = {
                'module_nr': parseInt(data.module),
                'session_nr': parseInt(data.session),
                'lesson_rank': parseInt(data.lection),
                "intervention_started": intervention_started,
                "intervention_finished": store.getters['store_client/get_client_progress'].intervention_finished,
                "introduction_finished": store.getters['store_client/get_client_progress'].introduction_finished
            }
    
            ClientApi.SaveInterventionProgress(progress_data).then(() => {

                store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

                if (data.type == 'guided_task') {

                    var title = data.module + '-' + data.session + '-' + data.lection + '-' + data.wagtail_data.taskID

                    var original_task_data_tmp = store.getters['store_lection/get_saved_lection_task_data_by_identifier'](data.wagtail_data.taskID)
                    var wagtail_task_data = data.wagtail_data.task[0]
                    wagtail_task_data["goal"] = data.wagtail_data.goal
                    wagtail_task_data["instruction"] = data.wagtail_data.instruction
                    original_task_data_tmp['wagtail_task_data'] = wagtail_task_data            

                    const guided_task_data = {
                        module_nr: data.module, 
                        session_nr: data.session, 
                        lesson_nr: data.lection, 
                        title: title, 
                        task_data: original_task_data_tmp, 
                        task_id: data.wagtail_data.taskID, 
                        content: data.reward, 
                        revision_nr: 0, 
                        attachmentFile1: '', 
                        attachmentFile2: '', 
                        attachmentFile3: ''
                    }

                    ClientApi.CreateNewGuidedTask(guided_task_data).then(() => {

                        store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                        resolve()

                    })

                } else {


                    store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                    resolve()

                }

            })
       
        })

    } else {

        store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

        resolve()

    }

})


export default {
    InterventionNext
}