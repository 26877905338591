import store from '@/store/store'
import Vue from 'vue'
import axios from 'axios'

function ClearEntireStore() {

    Vue.prototype.$session.remove('access_token') // if the request fails, remove any possible user token if possible
    Vue.prototype.$session.remove('expires_in')
    Vue.prototype.$session.remove('expires_at')
    Vue.prototype.$session.remove('user_username')
    Vue.prototype.$session.remove('user_group')
    Vue.prototype.$session.remove('password_changed')

    // remove the axios default header
    delete axios.defaults.headers.common['Authorization']

    // set store
    store.dispatch('store_auth/UPDATE_REDIRECT_URL', '/')
    store.dispatch('store_auth/AUTHOR_SUCCESS', '')

    // clear stores
    store.dispatch('store_assessment/RESET_STATE')
    store.dispatch('store_client/RESET_STATE')
    store.dispatch('store_dialogs/RESET_STATE')
    store.dispatch('store_lection/RESET_STATE')
    store.dispatch('store_loader/RESET_STATE')
    store.dispatch('store_main_messages/RESET_STATE')
    store.dispatch('store_menu/RESET_STATE')
    store.dispatch('store_messages/RESET_STATE')
    store.dispatch('store_quiz/RESET_STATE')
    store.dispatch('store_task/RESET_STATE')

}

export default {
    ClearEntireStore
  }