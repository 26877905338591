<template>
    <div class="mx-3" v-if="ready">

        <v-row>
            <div class="headline mt-5 primary--text">{{ question }}</div>
        </v-row>

        <v-row>
            <div class="overline mb-3"><b>Tipp:</b> Sie müssen alle Wörter richtig zuordnen.</div>
        </v-row>

        <v-row>

            <v-col cols="12" sm="12">

                <v-card
                    class="mx-auto"
                    outlined
                >

                    <v-card-text v-if="$vuetify.breakpoint.mdAndUp">

                        <v-row 
                            v-for="(item, i) in itemList" 
                            :key="item.name"
                            class="my-3"
                            align="center"
                        >
                            <v-card color="primary" class="mx-auto">
                                <v-card-text class="pa-1 text-center body-1 white--text">
                                    {{ item }}
                                </v-card-text>
                            </v-card>

                            <v-divider class="mx-4"/>

                            <v-card
                                class="mx-auto"
                                flat
                            >

                                <v-menu offset-y min-width="800" z-index="1">

                                    <template v-slot:activator="{ on, attrs }">
                                        <v-card
                                            v-bind="attrs"
                                            v-on="on"
                                            v-if="dropLists[i].length == 0"
                                            :disabled="thisBtnDisabled"
                                        >

                                            <v-alert outlined color="primary" class="pa-0">
                                                <v-card-text class="text-center body-1 pa-2">Wort einfügen</v-card-text>
                                            </v-alert>
                                        </v-card>

                                        <v-card
                                            v-bind="attrs"
                                            v-on="on"
                                            v-else
                                            :disabled="thisBtnDisabled"
                                        >
                                            <v-alert outlined :color="getCorrectColor(i, dropLists[i][0])" class="pa-0">
                                                <v-card-text class="text-center body-1 pa-2">{{ dropLists[i][0] }}</v-card-text>
                                            </v-alert>
                                        </v-card>
                                    </template>

                                    <v-list subheader two-line v-if="dropLists[i].length == 0">

                                        <v-list-item
                                            v-for="(item_main, index) in mainList"
                                            :key="index"
                                            @click="add_item(item_main, index, dropLists[i])"
                                        >

                                            <v-list-item-avatar>
                                                <v-icon color="primary">far fa-plus-square</v-icon>
                                            </v-list-item-avatar>
                                            
                                            <v-list-item-content>
                                                <div class="font-weight-medium primary--text">{{ item_main }}</div>
                                                <div class="body-2 font-weight-light"><i>Klicken</i> um diesem Feld zuzuordnen.</div>
                                            </v-list-item-content>

                                        </v-list-item>

                                    </v-list>

                                    <v-list subheader two-line v-else>

                                        <v-list-item
                                            v-for="(item_drop, index) in dropLists[i]"
                                            :key="index"
                                            @click="delete_item(item_drop, index, dropLists[i])"
                                        >

                                            <v-list-item-avatar>
                                                <v-icon :color="getCorrectColor(i, item_drop)">far fa-times-circle</v-icon>
                                            </v-list-item-avatar>
                                            
                                            <v-list-item-content>
                                                <div class="font-weight-medium primary--text">{{ item_drop }}</div>
                                                <div class="body-2 font-weight-light"><i>Klicken</i> um aus diesem Feld zu entfernen.</div>
                                            </v-list-item-content>

                                        </v-list-item>

                                    </v-list>

                                </v-menu>

                            </v-card>

                        </v-row>

                    </v-card-text>

                    <v-card-text class="pt-0" v-else>

                        <v-row 
                            v-for="(item, i) in itemList" 
                            :key="item.name"
                            :class="i > 0 ? 'my-3' : 'mb-3'"
                            justify="center" align="center"
                        >
                            <v-col cols="12" sm="12">
                                <v-divider v-if="i > 0" />
                            </v-col>
                                
                            <v-col cols="12" sm="12">

                                <v-row align="center" justify="center">
                                    <v-card color="primary" class="mx-auto">
                                        <v-card-text class="pa-1 text-center body-1 white--text">
                                            {{ item }}
                                        </v-card-text>
                                    </v-card>
                                </v-row>

                            </v-col>

                            <v-col cols="12" sm="12">

                                <div class="vl" />

                            </v-col>

                            <v-card
                                class="mx-auto"
                                flat
                            >

                                <v-menu offset-y min-width="300" z-index="1">

                                    <template v-slot:activator="{ on, attrs }">
                                        <v-card
                                            v-bind="attrs"
                                            v-on="on"
                                            v-if="dropLists[i].length == 0"
                                            :disabled="thisBtnDisabled"
                                        >

                                            <v-alert outlined color="primary" class="pa-0">
                                                <v-card-text class="text-center body-1 pa-2">Wort einfügen</v-card-text>
                                            </v-alert>
                                        </v-card>

                                        <v-card
                                            v-bind="attrs"
                                            v-on="on"
                                            v-else
                                            :disabled="thisBtnDisabled"
                                        >
                                            <v-alert outlined :color="getCorrectColor(i, dropLists[i][0])" class="pa-0">
                                                <v-card-text class="text-center body-1 pa-2">{{ dropLists[i][0] }}</v-card-text>
                                            </v-alert>
                                        </v-card>
                                    </template>

                                    <v-list subheader two-line v-if="dropLists[i].length == 0">

                                        <v-list-item
                                            v-for="(item_main, index) in mainList"
                                            :key="index"
                                            @click="add_item(item_main, index, dropLists[i])"
                                        >

                                            <v-list-item-avatar>
                                                <v-icon color="primary">far fa-plus-square</v-icon>
                                            </v-list-item-avatar>
                                            
                                            <v-list-item-content>
                                                <div class="font-weight-medium primary--text">{{ item_main }}</div>
                                                <div class="body-2 font-weight-light"><i>Klicken</i> um diesem Feld zuzuordnen.</div>
                                            </v-list-item-content>

                                        </v-list-item>

                                    </v-list>

                                    <v-list subheader two-line v-else>

                                        <v-list-item
                                            v-for="(item_drop, index) in dropLists[i]"
                                            :key="index"
                                            @click="delete_item(item_drop, index, dropLists[i])"
                                        >

                                            <v-list-item-avatar>
                                                <v-icon :color="getCorrectColor(i, item_drop)">far fa-times-circle</v-icon>
                                            </v-list-item-avatar>
                                            
                                            <v-list-item-content>
                                                <div class="font-weight-medium primary--text">{{ item_drop }}</div>
                                                <div class="body-2 font-weight-light"><i>Klicken</i> um aus diesem Feld zu entfernen.</div>
                                            </v-list-item-content>

                                        </v-list-item>

                                    </v-list>

                                </v-menu>

                            </v-card>

                        </v-row>

                    </v-card-text>

                </v-card>

            </v-col>

        </v-row>

        <v-row 
            align="center" 
            justify="center"
        >

            <v-alert
                :value="alert"
                :color="alert_color"
                dark
                :icon="alert_icon"
                border="top"
                width="100%"
                class="mt-3"
            >
                <div class="" v-html="alert_text"></div>
            </v-alert>

        </v-row>

    </div>
    
</template>

<script>
import _ from 'lodash'

export default {
    name: 'simple-assignment-task',
    props: ['taskID', 'id', 'question', 'assignmentList'],

    data: () => ({
      
        mainList: [],
        itemList: [],
        dropLists: [],
        answerList: [],
        correctList: {},

        ready: false,

        alert: false,
        alert_icon: 'far fa-check-circle',
        alert_color: 'success',
        alert_text: '<b>Sehr gut!</b> Sie haben alle Wörter richtig zugeordnet.',

    }),

    created () {
  
        this.ready = false
        this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false})  

        if (_.isEmpty(this.$store.getters['store_lection/get_saved_lection_task_data_by_identifier'](this.taskID))) {

            // fill the main list based on prop assignmentList
            var i;

            for (i = 0; i < this.assignmentList.length; i++) {
                this.mainList.push(this.assignmentList[i].dependendItem)
                this.itemList.push(this.assignmentList[i].item)
                this.dropLists.push([])

                if (this.correctList[this.assignmentList[i].dependendItem]) {
                    this.correctList[this.assignmentList[i].dependendItem].correct_index.push(i)
                } else {
                    _.set(this.correctList, this.assignmentList[i].dependendItem, {})
                    this.correctList[this.assignmentList[i].dependendItem].correct_index = []
                    this.correctList[this.assignmentList[i].dependendItem].correct_index.push(i)
                }

            }  

            // sort mainList in order to change the start list
            this.mainList.sort()

            // define task data store
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'mainList', 'data': this.mainList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'itemList', 'data': this.itemList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'dropLists', 'data': this.dropLists})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'correctList', 'data': this.correctList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert', 'data': this.alert})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert_icon', 'data': this.alert_icon})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert_color', 'data': this.alert_color})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert_text', 'data': this.alert_text})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'type', 'data': 'simpleAssignmentTask'})

            this.ready = true

        } else {

            // get data
            var data = this.$store.getters['store_lection/get_saved_lection_task_data_by_identifier'](this.taskID)
            
            this.mainList = data.mainList
            this.dropLists = data.dropLists
            this.itemList = data.itemList
            this.correctList = data.correctList
            this.alert = data.alert
            this.alert_icon= data.alert_icon
            this.alert_color = data.alert_color
            this.alert_text = data.alert_text

            this.ready = true
        }
    },

    watch: {


      itemList: function(val) {

          if (this.mainList.length != 0) {
              this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false})  
          }

        // update store variable
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'itemList', 'data': val})
      },

      dropLists: function(val) {

        // update store variable
        this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'dropLists', 'data': val})
      },

    },

    computed: {
        
        thisBtnDisabled () {

            var tmp = this.$store.getters['store_lection/get_saved_lection_data']
                
            if (tmp.data[this.id].finished == false) {
                return false
            } else {
                return true
            }
 
        },

    },

    methods: {

        add_item(item, index, which_list) {

            which_list.push(item)
            this.mainList.splice(this.mainList.indexOf(item), 1)

            if (this.mainList.length == 0) {

                var correctList = []

                // get items of relevance in dropLists
                var x

                for (x = 0; x < this.dropLists.length; x++) {

                    if (this.dropLists[x] == this.assignmentList[x].dependendItem) {

                        correctList.push(true)

                    } else {

                        correctList.push(false)
                    }
                }

                if (correctList.includes(false)) {

                    this.alert_icon = 'fas fa-exclamation-triangle'
                    this.alert_color = "error"
                    this.alert_text = '<b>Das ist leider falsch.</b> Falsch zugeordnete Wörter können Sie an der roten Farbe erkennen.'
                    this.alert = true

                    this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false})  

                } else {

                    this.alert_icon = 'far fa-check-circle'
                    this.alert_color = 'success'
                    this.alert_text = '<b>Sehr gut!</b> Sie haben alle Wörter richtig zugeordnet.'
                    this.alert = true

                    this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': true})  

                }
                
            } else {

                this.alert = false
                this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false})  

            }
            
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'mainList', 'data': this.mainList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'itemList', 'data': this.itemList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'dropLists', 'data': this.dropLists})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'correctList', 'data': this.correctList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert', 'data': this.alert})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert_icon', 'data': this.alert_icon})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert_color', 'data': this.alert_color})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert_text', 'data': this.alert_text})

        },

        delete_item(item, index, which_list) {

            which_list.splice(which_list.indexOf(item), 1)
            this.mainList.push(item)

            if (this.mainList.length == 0) {

                var correctList = []

                // get items of relevance in dropLists
                var x

                for (x = 0; x < this.dropLists.length; x++) {

                    if (this.dropLists[x] == this.assignmentList[x].dependendItem) {

                        correctList.push(true)

                    } else {

                        correctList.push(false)
                    }
                }

                if (correctList.includes(false)) {

                    this.alert_icon = 'fas fa-exclamation-triangle'
                    this.alert_color = "error"
                    this.alert_text = '<b>Das ist leider falsch.</b> Falsch zugeordnete Wörter können Sie an der roten Farbe erkennen.'
                    this.alert = true

                    this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false})  

                } else {

                    this.alert_icon = 'far fa-check-circle'
                    this.alert_color = 'success'
                    this.alert_text = '<b>Sehr gut!</b> Sie haben alle Wörter richtig zugeordnet.'
                    this.alert = true

                    this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': true})  

                }
                
            } else {

                this.alert = false
                this.$store.dispatch('store_task/ADD_FINISHED_BY_IDENTIFIER', {'identifier': this.taskID, 'property_name': 'finished', 'data': false})  

            }
            
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'mainList', 'data': this.mainList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'itemList', 'data': this.itemList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'dropLists', 'data': this.dropLists})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'correctList', 'data': this.correctList})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert', 'data': this.alert})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert_icon', 'data': this.alert_icon})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert_color', 'data': this.alert_color})
            this.$store.dispatch('store_lection/ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA', {'identifier': this.taskID, 'property_name': 'alert_text', 'data': this.alert_text})

        },

        getCorrectColor(i, item) {

            if (this.correctList[item].correct_index.includes(i)) {

                return 'success'

            } else {

                return 'error'

            }

        },

  }

}
</script>

<style scoped>

.vl {
  border-left: 1px solid rgb(148, 148, 148);
  height: 24px;
  position: relative;
  left: 50%;
  top: 0;
}

.custom-chip {

    display: inline-flex;
    flex-direction: row;
    background-color: #004b8c;

    max-width: 50%;

    height: auto;

    outline: none;

    padding-top: 5px;
    padding-right: 12px;
    padding-bottom: 5px;
    padding-left: 12px;

    line-height: 20px;

    white-space: pre-wrap;

    align-items: center;

    border-radius: 16px;
    font-size: 14px;

    vertical-align: middle;
    text-decoration: none;
    justify-content: center;

}

.drop-item {
    top: 50%;
    transform: translateY(-50%);
}

.fill-divider {

    display: flex !important;
    justify-content: center !important;
    flex-direction: row !important;

    min-width: 80px;
    min-height: 45px;

}

.fill-layout {
    display: flex;
    justify-content: center;
    flex-direction: row;

    width: auto; 
    min-width: 80px;
    min-height: 45px;

    background: #EEEEEE;

    border-style: solid;
    border-width: 2px;
    border-radius: 5px !important;
    border-color: rgb(148, 148, 148) !important;
}

.fill-p {
    display: flex;
    justify-content: center;
    flex-direction: column;

    width: auto;
    height: 100%;
    min-height: 40px;
}

.fill-box {
    display: flex;
    justify-content: center;
    flex-direction: column;

    min-height: 45px;
    min-width: 100px;

    background: #EEEEEE;

    border-style: solid;
    border-width: 2px;
    border-radius: 5px !important;
    border-color: rgb(148, 148, 148) !important;
}

.drop-main {
    display: flex;
    justify-content:flex-start;
    flex-direction: row;
    flex-wrap: wrap;

    min-width: 100%;
    min-height: 45px;

    padding-top: 12px;
    padding-bottom: 12px;

    background: #EEEEEE;

    border-style: solid;
    border-width: 1px;
    border-radius: 5px !important;
    border-color: rgb(148, 148, 148) !important;
}

.sentence {
    
    width: 100%;
    
}

.sentence-main-row {

    border-style: solid;
    border-width: 1px;
    border-radius: 5px !important;
    border-color: rgb(148, 148, 148) !important;
    padding: 10px;

}
</style>


