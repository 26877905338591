<template>

    <div class="mx-3">

        <v-row v-if="!$vuetify.breakpoint.mdAndDown">

            <v-col cols="2" sm="2"></v-col>

            <v-col cols='5' sm='5'>

                <v-card
                    class="mx-auto"
                    outlined
                >

                    <v-card-text class="text-center headline primary--text font-weight-bold">{{ data.headerColumn1 }}</v-card-text>

                </v-card>

            </v-col>

            <v-col cols='5' sm='5'>

                <v-card
                    class="mx-auto"
                    outlined
                >

                    <v-card-text class="text-center headline primary--text font-weight-bold">{{ data.headerColumn2 }}</v-card-text>

                </v-card>

            </v-col>

        </v-row>

        <v-row>

            <v-col v-if="!$vuetify.breakpoint.mdAndDown" :cols="$vuetify.breakpoint.mdAndDown ? '12':'2'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'2'">

                <v-card
                    class="mx-auto"
                    outlined
                    min-height="120px"
                    height="100%"
                >

                    <v-row align="center" justify="center">

                            <v-col cols="12">

                                <v-card-text class="text-center headline primary--text font-weight-bold">{{ data.headerRow1 }}</v-card-text>

                            </v-col>

                    </v-row>

                </v-card>

            </v-col>


            <!-- FIELD 1 -->
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12':'5'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'5'">

                <v-card
                    class="mx-auto"
                    outlined
                    min-height="120px"
                    height="100%"
                >

                    <v-card-text v-if="$vuetify.breakpoint.mdAndDown" class="text-center headline primary--text font-weight-bold">{{ data.headerColumn1 }} | {{ data.headerRow1}}</v-card-text>

                    <v-divider v-if="$vuetify.breakpoint.mdAndDown" />
                    
                    <v-card-text>

                        <v-row
                            v-for="(item, i) in data.field1List"
                            :key="i"
                            align="center" 
                            justify="center"
                        >

                            <v-card :color="getCorrectColor('field1', item)" class="mb-2">
                                <v-card-text class="pa-1 text-center body-1 white--text">
                                    {{ item.assignmentText }}
                                </v-card-text>
                            </v-card>

                        </v-row>

                    </v-card-text>

                </v-card>

            </v-col>

            <!-- FIELD 2 -->
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12':'5'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'5'">

                <v-card
                    class="mx-auto"
                    outlined
                    min-height="120px"
                    height="100%"
                >

                    <v-card-text v-if="$vuetify.breakpoint.mdAndDown" class="text-center headline primary--text font-weight-bold">{{ data.headerColumn2 }} | {{ data.headerRow1}}</v-card-text>

                    <v-divider v-if="$vuetify.breakpoint.mdAndDown" />
                    
                    <v-card-text>

                        <v-row
                            v-for="(item, i) in data.field2List"
                            :key="i"
                            align="center" 
                            justify="center"
                        >

                            <v-card :color="getCorrectColor('field2', item)" class="mb-2">
                                <v-card-text class="pa-1 text-center body-1 white--text">
                                    {{ item.assignmentText }}
                                </v-card-text>
                            </v-card>

                        </v-row>

                    </v-card-text>

                </v-card>

            </v-col>

            <v-col v-if="!$vuetify.breakpoint.mdAndDown" :cols="$vuetify.breakpoint.mdAndDown ? '12':'2'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'2'">

                <v-card
                    class="mx-auto"
                    outlined
                    min-height="120px"
                    height="100%"
                >

                    <v-row align="center" justify="center">

                            <v-col cols="12">

                                <v-card-text class="text-center headline primary--text font-weight-bold">{{ data.headerRow2 }}</v-card-text>

                            </v-col>

                    </v-row>

                </v-card>

            </v-col>

            <!-- FIELD 3 -->
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12':'5'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'5'">

                <v-card
                    class="mx-auto"
                    outlined
                    min-height="120px"
                    height="100%"
                >

                    <v-card-text v-if="$vuetify.breakpoint.mdAndDown" class="text-center headline primary--text font-weight-bold">{{ data.headerColumn1 }} | {{ data.headerRow2}}</v-card-text>

                    <v-divider v-if="$vuetify.breakpoint.mdAndDown" />
                    
                    <v-card-text>

                        <v-row
                            v-for="(item, i) in data.field3List"
                            :key="i"
                            align="center" 
                            justify="center"
                        >

                            <v-card :color="getCorrectColor('field3', item)" class="mb-2">
                                <v-card-text class="pa-1 text-center body-1 white--text">
                                    {{ item.assignmentText }}
                                </v-card-text>
                            </v-card>

                        </v-row>

                    </v-card-text>

                </v-card>

            </v-col>

            <!-- FIELD 4 -->
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? '12':'5'" :sm="$vuetify.breakpoint.mdAndDown ? '12':'5'">

                <v-card
                    class="mx-auto"
                    outlined
                    min-height="120px"
                    height="100%"
                >

                    <v-card-text v-if="$vuetify.breakpoint.mdAndDown" class="text-center headline primary--text font-weight-bold">{{ data.headerColumn2 }} | {{ data.headerRow2}}</v-card-text>

                    <v-divider v-if="$vuetify.breakpoint.mdAndDown" />
                    
                    <v-card-text>

                        <v-row
                            v-for="(item, i) in data.field4List"
                            :key="i"
                            align="center" 
                            justify="center"
                        >

                            <v-card :color="getCorrectColor('field4', item)" class="mb-2">
                                <v-card-text class="pa-1 text-center body-1 white--text">
                                    {{ item.assignmentText }}
                                </v-card-text>
                            </v-card>

                        </v-row>

                    </v-card-text>

                </v-card>

            </v-col>

        </v-row>

    </div>
    
</template>

<script>
export default {
    name: 'pro-contra-assignment-task-data',

    props: ['data'],

    methods: {

        getCorrectColor(where, item) {

            if (where == 'field1' && item.assignmentType == 'field1') {

                return '#4CAF50'

            } else if (where == 'field2' && item.assignmentType == 'field2') {

                return '#4CAF50'

            } else if (where == 'field3' && item.assignmentType == 'field3') {

                return '#4CAF50'

            } else if (where == 'field4' && item.assignmentType == 'field4') {

                return '#4CAF50'

            } else if (item.assignmentType == 'all') {

                return '#4CAF50'
            
            } else {
                return '#FF5252'
            }

        },

    }

}
</script>

<style scoped>

</style>


