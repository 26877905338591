<template>

    <v-container v-if="loaded">
        <v-row>
            <v-col cols="12" sm="12">

                <v-row justify="center" align="center" v-if="msg_send">

                        <v-col cols="12" sm="12">

                            <v-alert 
                                
                                border="left"
                                colored-border
                                prominent
                                type="success"
                            >
                                
                                <v-row align="center">
                                    <v-col class="grow">Der technische Support wird kontaktiert.</v-col>
                                </v-row>

                            </v-alert>

                        </v-col>

                </v-row>
                
                <v-card
                    outlined
                    flat
                    v-if="!msg_send"
                >

                    <v-card-text class="title primary--text">
                        Haben Sie technische Probleme oder funktioniert etwas nicht?
                    </v-card-text>

                    <v-card-text>
                        Bitte beschreiben Sie möglichst genau, welches Problem bei Ihnen auftritt. <span v-if="$store.getters['store_client/get_client_coach'].coach_gender == 1">Ihr Coach</span><span v-else>Ihre Coachin</span> leitet die Beschreibung an das technische Team weiter
                        und wird sich, so bald er/sie eine Rückmeldung erhalten hat, bei Ihnen melden. Sie können den Verlauf - wie auch bei anderen Nachrichten - in Ihren 
                        Chats verfolgen.
                    </v-card-text>

                    <v-card-text>

                        <v-form
                            ref="new_message_form"
                            v-model="new_message_form_valid"
                            lazy-validation
                        >
                            <v-textarea
                                outlined
                                v-model="newMessageValue"
                                :rules="new_message_form_content_rules"
                                label="Mein Problem"
                                placeholder="Bitte beschreiben Sie hier möglichst genau, welches technische Problem bei Ihnen auftritt:"
                                required
                            ></v-textarea>

                        </v-form>

                    </v-card-text>

                    <v-card-text class="caption pt-1">
                        <b>Bitte beachten Sie:</b> Zum Schutz der Daten anderer Personen, vermeiden Sie es unbedingt, persönliche Daten von jemand anderem in Ihren Nachrichten zu nennen, z.B. vollständige Namen oder Adressen von Menschen, die Sie kennen.
                    </v-card-text>


                    <v-card-actions>
                        <v-spacer />
                        <v-btn color="primary" outlined @click="sendMessage()">
                            Technisches Problem melden
                        </v-btn>
                    </v-card-actions>

                </v-card>

            </v-col>
        </v-row>
    </v-container>

</template>

<script>
import ClientApi from '@/_api/client_api'

export default {
    name: 'client-technical-help',

    components: {
        
    },

    data: () => ({
        
        loaded: true,

        msg_send: false,

        new_message_form_valid: true,
        new_message_form_content_rules: [
                v => !!v || 'Der Inhalt der Nachricht darf nicht leer sein!'],

        newMessageValue: null,

        sending: false,

    }),

    created() {

    },

    methods: {

        sendMessage() {

              /**
             * Summary. Creates a new thread with a message. Automatically creates necessary MyThreads for
             * all receiver of the message.
             * */

             if (this.$refs.new_message_form.validate()) {

                 this.sending = true


                var data_tmp = {

                    "content": this.newMessageValue,
                    "receiver": [this.$store.getters['store_client/get_client_coach'].id],
                    "subject": 'Anfrage an Technischen Support',
                    "is_technical_help": 'True',

                    'attachmentFile1': '',
                    'attachmentFile2': '',
                    'attachmentFile3': '',

                }
                

                ClientApi.CreateNewThread(data_tmp).then(()=>{ 

                    // close dialogue
                    this.$refs.new_message_form.reset()

                    this.sending = false

                    this.msg_send = true
                    
                })
             }

        }

    }
}
</script>

<style scoped>

</style>