<template>
    <div>

        <!-- ONE ROW -->

        <!-- type: one-row only text-->
        <v-row v-if="data.type == 'oneColumnOnlyText'">

            <v-col sm="12" cols="12" class="py-0">

                <v-card 
                    flat
                    :color="getCorrectColor(data.value.column_background)"
                >                                
                    <v-card-text> 
                        <div class="headline mb-2" v-if="data.value.title !=''">{{ data.value.title }}</div>  
                        <div 
                            v-html="data.value.text"
                            class="subtitle-1 mb-0" 
                        ></div>
                    </v-card-text>
                </v-card>

            </v-col>

        </v-row>

        <!-- type: one-row only image-->
        <v-row 
            v-if="data.type == 'oneColumnOnlyImage'"
        >
            <v-col sm="12" cols="12" class="py-0">
                <v-card 
                    flat 
                    :color="getCorrectColor(data.value.column_background)"
                >   
                    <image-media 
                        :alt="data.value.img_alt"
                        :img_id="data.value.img_chooser" 
                        :border="'none'"
                    />
                </v-card>                
            </v-col>
        </v-row>

        <!-- type: one-row only video-->
        <v-row 
            v-if="data.type == 'oneColumnOnlyVideo'"
        >
            <v-col sm="12" cols="12" class="py-0">
                <v-card 
                    flat 
                    :color="getCorrectColor(data.value.column_background)"
                >   
                    <video-media 
                        :source="data.value.vid_src" 
                        :border="'none'"
                        :video_id="data.value.vid_chooser"
                    />
                    
                </v-card> 
            </v-col>
        </v-row>

        <!-- type: one-row text and image-->
        <v-row 
            v-if="data.type == 'oneColumnTextAndImage'"
            >
                <v-col sm="12" cols="12" class="py-0">
                    <v-card 
                        flat 
                        :color="getCorrectColor(data.value.column_background)"
                    >  
                        <image-media 
                            v-if="data.value.img_pos == 'top'"
                            :alt="data.value.img_alt"
                            :border="'top'"
                            :img_id="data.value.img_chooser" 
                        />

                        <v-card-text> 
                            <div class="headline mb-2" v-if="data.value.title !=''">{{ data.value.title }}</div>  
                            <div 
                                v-html="data.value.text"
                                class="subtitle-1 mb-0" 
                            ></div>
                        </v-card-text>

                        <image-media 
                            v-if="data.value.img_pos == 'bottom'"
                            :alt="data.value.img_alt"
                            :border="'bottom'"
                            :img_id="data.value.img_chooser" 
                        />

                    </v-card>

                </v-col>
        </v-row>

        <!-- TWO ROWS -->


        <!-- type: twoColumnsOnlyImageOnlyText -->
        
            <v-row 
                v-if="data.value.content_order == 'text-image' && data.type == 'twoColumnsOnlyImageOnlyText'"
                
            >
                <v-col :sm="getLeftColumnWidth(data.value.column_width)" cols="12" class="py-0">

                    <v-card 
                        flat 
                        :color="getCorrectColor(data.value.text_column_background)"
                        :height="data.value.height_adjustment"

                    >  
                        <v-card-text> 
                            <div class="headline mb-2">{{ data.value.text_column_title }}</div>  
                            <div 
                                v-html="data.value.text_column_text"
                                class="subtitle-1  mb-0" 
                            ></div>
                        </v-card-text>
                    </v-card>

                </v-col>

                <v-col :sm="getRightColumnWidth(data.value.column_width)" cols="12" class="py-0">

                    <v-card 
                        flat 
                        :color="getCorrectColor(data.value.img_column_background)"
                        :height="data.value.height_adjustment"
                        class="d-flex flex-wrap"
                    >  
                        <image-media 
                            :alt="data.value.img_column_img_alt" 
                            :border="'none'"
                            :img_id="data.value.img_column_img_chooser" 
                        />
                    </v-card>

                </v-col>
            </v-row>

            <v-row 
                v-if="data.value.content_order == 'image-text' && data.type == 'twoColumnsOnlyImageOnlyText'"
            >
                <v-col :sm="getLeftColumnWidth(data.value.column_width)" cols="12" class="py-0">
                    <v-card 
                        flat 
                        :color="getCorrectColor(data.value.img_column_background)"
                        :height="data.value.height_adjustment"
                        :class="get_d_flex"
                    >  
                        <image-media 
                            :alt="data.value.img_column_img_alt" 
                            :border="'none'"
                            :img_id="data.value.img_column_img_chooser" 
                        />
                    </v-card>
                </v-col>
                <v-col :sm="getRightColumnWidth(data.value.column_width)" cols="12" class="py-0">
                    <v-card 
                        flat 
                        :color="getCorrectColor(data.value.text_column_background)"
                        :height="data.value.height_adjustment"
                    >  
                        <v-card-text> 
                            <div class="headline mb-2">{{ data.value.text_column_title }}</div>  
                            <div 
                                v-html="data.value.text_column_text"
                                class="subtitle-1  mb-0" 
                            ></div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        

        <!-- type: twoColumnsOnlyVideoOnlyText -->
        <div v-if="data.type == 'twoColumnsOnlyVideoOnlyText'">
            <v-row 
                v-if="data.value.content_order == 'text-image'"
            >
                <v-col :sm="getLeftColumnWidth(data.value.column_width)" cols="12" class="py-0">
                    <v-card 
                        flat 
                        :color="getCorrectColor(data.value.text_column_background)"
                        :height="data.value.height_adjustment"
                        class="mr-2"
                    >  
                        <v-card-text> 
                            <div class="headline mb-2">{{ data.value.text_column_title }}</div>  
                            <div 
                                v-html="data.value.text_column_text"
                                class="subtitle-1  mb-0" 
                            ></div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col :sm="getRightColumnWidth(data.value.column_width)" cols="12" class="py-0">
                    <v-card 
                        flat 
                        :color="getCorrectColor(data.value.vid_column_background)"
                        :height="data.value.height_adjustment"
                    >  
                        <video-media :source="data.value.vid_column_vid_src" :border="'none'" :video_id="data.value.vid_column_chooser"/>

                    </v-card>
                </v-col>
            </v-row>

            <v-row 
                v-if="data.value.content_order == 'image-text'"
            >
                <v-col :sm="getLeftColumnWidth(data.value.column_width)" cols="12" class="py-0">
                    <v-card 
                        flat 
                        :color="getCorrectColor(data.value.vid_column__background)"
                        :height="data.value.height_adjustment"
                        class="mr-2"
                    >  
                        <video-media :source="data.value.vid_column_vid_src" :border="'none'" :video_id="data.value.vid_column_chooser"/>
                    </v-card>
                </v-col>
                <v-col :sm="getRightColumnWidth(data.value.column_width)" cols="12" class="py-0">
                    <v-card 
                        flat 
                        :color="getCorrectColor(data.value.text_column_background)"
                        :height="data.value.height_adjustment"
                    >  
                        <v-card-text> 
                            <div class="headline mb-2">{{ data.value.text_column_title }}</div>  
                            <div 
                                v-html="data.value.text_column_text"
                                class="subtitle-1  mb-0" 
                            ></div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>

        <!-- type: twoColumnsBothOnlyText -->
        <v-row v-if="data.type == 'twoColumnsBothOnlyText'">
            <v-col :sm="getLeftColumnWidth(data.value.column_width)" cols="12" class="py-0">
                <v-card 
                    flat 
                    :color="getCorrectColor(data.value.left_column_background)"
                    :height="data.value.height_adjustment"
                    class="mr-1"
                >  
                    <v-card-text> 
                        <div class="headline mb-2">{{ data.value.left_column_title }}</div>  
                        <div 
                            v-html="data.value.left_column_text"
                            class="subtitle-1  mb-0"
                        ></div>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col :sm="getRightColumnWidth(data.value.column_width)" cols="12" class="py-0">
                <v-card 
                    flat 
                    :color="getCorrectColor(data.value.right_column_background)"
                    :height="data.value.height_adjustment"
                    class="ml-1"
                >  
                    <v-card-text> 
                        <div class="headline mb-2">{{ data.value.right_column_title }}</div>  
                        <div 
                            v-html="data.value.right_column_text"
                            class="subtitle-1  mb-0" 
                        ></div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- type: twoColumnsOnlyTextTextAndImage -->
        <div v-if="data.type == 'twoColumnsOnlyTextTextAndImage'">
            <v-row 
                v-if="data.value.content_order == 'text-textandimage'"
            >
                <v-col :sm="getLeftColumnWidth(data.value.column_width)" cols="12" class="py-0">
                    <v-card 
                        flat 
                        :color="getCorrectColor(data.value.text_column_background)"
                        :height="data.value.height_adjustment"
                        class="mr-1"
                    >  
                        <v-card-text> 
                            <div class="headline mb-2">{{ data.value.text_column_title }}</div>  
                            <div 
                                v-html="data.value.text_column_text"
                                class="subtitle-1  mb-0" 
                            ></div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col :sm="getRightColumnWidth(data.value.column_width)" cols="12" class="py-0">
                    <v-card 
                        flat 
                        :color="getCorrectColor(data.value.textandimage_column_background)"
                        :height="data.value.height_adjustment"
                        class="ml-1"
                    >  
                        <image-media 
                            v-if="data.value.textandimage_column_img_pos == 'top'"
                            :alt="data.value.textandimage_column_img_alt"
                            :border="'top'"
                            :img_id="data.value.textandimage_column_img_chooser" 
                        />
                        <v-card-text> 
                            <div class="headline mb-2">{{ data.value.textandimage_column_title }}</div>  
                            <div 
                                v-html="data.value.textandimage_column_text"
                                class="subtitle-1  mb-0" 
                            ></div>
                        </v-card-text>
                        <image-media 
                            v-if="data.value.textandimage_column_img_pos == 'bottom'"
                            :alt="data.value.textandimage_column_img_alt"
                            :border="'bottom'"
                            :img_id="data.value.textandimage_column_img_chooser" 
                        />
                    </v-card>
                </v-col>
            </v-row>

            <v-row 
                v-if="data.value.content_order == 'textandimage-text'"
            >
                <v-col :sm="getLeftColumnWidth(data.value.column_width)" cols="12" class="py-0">
                    <v-card 
                        flat 
                        :color="getCorrectColor(data.value.textandimage_column_background)"
                        :height="data.value.height_adjustment"
                        class="mr-1"
                    >  
                        <image-media 
                            v-if="data.value.textandimage_column_img_pos == 'top'"
                            :alt="data.value.textandimage_column_img_alt"
                            :border="'top'"
                            :img_id="data.value.textandimage_column_img_chooser" 
                        />
                        <v-card-text> 
                            <div class="headline mb-2">{{ data.value.textandimage_column_title }}</div>  
                            <div 
                                v-html="data.value.textandimage_column_text"
                                class="subtitle-1  mb-0" 
                            ></div>
                        </v-card-text>
                        <image-media 
                            v-if="data.value.textandimage_column_img_pos == 'bottom'"
                            :alt="data.value.textandimage_column_img_alt"
                            :border="'bottom'"
                            :img_id="data.value.textandimage_column_img_chooser" 
                        />
                    </v-card>
                </v-col>
                <v-col :sm="getRightColumnWidth(data.value.column_width)" cols="12" class="py-0">
                    <v-card 
                        flat 
                        :color="getCorrectColor(data.value.text_column_background)"
                        :height="data.value.height_adjustment"
                        class="ml-1"
                    >  
                        <v-card-text> 
                            <div class="headline mb-2">{{ data.value.text_column_title }}</div>  
                            <div 
                                v-html="data.value.text_column_text"
                                class="subtitle-1  mb-0" 
                            ></div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>

        <!-- type: twoColumnsOnlyImageTextAndImage -->
        <div v-if="data.type == 'twoColumnsOnlyImageTextAndImage'">
            <v-row 
                v-if="data.value.content_order == 'text-image'"
            >
                <v-col :sm="getLeftColumnWidth(data.value.column_width)" cols="12" class="py-0">
                    <v-card 
                        flat 
                        :color="getCorrectColor(data.value.textandimage_column_background)"
                        :height="data.value.height_adjustment"
                        class="mr-1"
                    >  
                        <image-media 
                            v-if="data.value.textandimage_column_img_pos == 'top'"
                            :alt="data.value.textandimage_column_img_alt"
                            :border="'top'"
                            :img_id="data.value.textandimage_column_img_chooser" 
                        />
                        <v-card-text> 
                            <div class="headline mb-2">{{ data.value.textandimage_column_title }}</div>  
                            <div 
                                v-html="data.value.textandimage_column_text"
                                class="subtitle-1  mb-0" 
                            ></div>
                        </v-card-text>
                        <image-media 
                            v-if="data.value.textandimage_column_img_pos == 'bottom'"
                            :alt="data.value.textandimage_column_img_alt"
                            :border="'bottom'"
                            :img_id="data.value.textandimage_column_img_chooser" 
                        />
                    </v-card>
                </v-col>
                <v-col :sm="getRightColumnWidth(data.value.column_width)" cols="12" class="py-0">
                    <v-card 
                        flat 
                        :color="getCorrectColor(data.value.img_column_background)"
                        :height="data.value.height_adjustment"
                        class="ml-1"
                    >  
                        <image-media 
                            :alt="data.value.img_column_img_alt" 
                            :border="'none'"
                            :img_id="data.value.img_column_img_chooser" 
                        />
                    </v-card>
                </v-col>
            </v-row>

            <v-row 
                v-if="data.value.content_order == 'image-text'"
            >
                <v-col :sm="getLeftColumnWidth(data.value.column_width)" cols="12" class="py-0">
                    <v-card 
                        flat 
                        :color="getCorrectColor(data.value.img_column_background)"
                        :height="data.value.height_adjustment"
                        class="mr-1"
                    >  
                        <image-media 
                            :alt="data.value.img_column_img_alt" 
                            :border="'none'"
                            :img_id="data.value.img_column_img_chooser" 
                        />
                    </v-card>
                </v-col>
                <v-col :sm="getRightColumnWidth(data.value.column_width)" cols="12" class="py-0">
                    <v-card 
                        flat 
                        :color="getCorrectColor(data.value.textandimage_column_background)"
                        :height="data.value.height_adjustment"
                        class="ml-1"
                    >  
                        <image-media 
                            v-if="data.value.textandimage_column_img_pos == 'top'"
                            :alt="data.value.textandimage_column_img_alt"
                            :border="'top'"
                            :img_id="data.value.textandimage_column_img_chooser" 
                        />
                        <v-card-text> 
                            <div class="headline mb-2">{{ data.value.textandimage_column_title }}</div>  
                            <div 
                                v-html="data.value.textandimage_column_text"
                                class="subtitle-1  mb-0" 
                            ></div>
                        </v-card-text>
                        <image-media 
                            v-if="data.value.textandimage_column_img_pos == 'bottom'"
                            :alt="data.value.textandimage_column_img_alt"
                            :border="'bottom'"
                            :img_id="data.value.textandimage_column_img_chooser" 
                        />
                    </v-card>
                </v-col>
            </v-row>
        </div>

        <!-- type: twoColumnsOnlyVideoTextAndImage -->
        <div v-if="data.type == 'twoColumnsOnlyVideoTextAndImage'">
            <v-row 
                
                v-if="data.value.content_order == 'text-image'"
            >
                <v-col :sm="getLeftColumnWidth(data.value.column_width)" cols="12" class="py-0">
                    <v-card 
                        flat 
                        :color="getCorrectColor(data.value.textandimage_column_background)"
                        :height="data.value.height_adjustment"
                        class="mr-1"
                    >  
                        <image-media 
                            v-if="data.value.textandimage_column_img_pos == 'top'"
                            :alt="data.value.textandimage_column_img_alt"
                            :border="'big'"
                            :img_id="data.value.textandimage_column_img_chooser" 
                        />
                        <v-card-text> 
                            <div class="headline mb-2">{{ data.value.textandimage_column_title }}</div>  
                            <div 
                                v-html="data.value.textandimage_column_text"
                                class="subtitle-1  mb-0" 
                            ></div>
                        </v-card-text>
                        <image-media 
                            v-if="data.value.textandimage_column_img_pos == 'bottom'"
                            :alt="data.value.textandimage_column_img_alt"
                            :border="'big-bottom'"
                        />
                    </v-card>
                </v-col>
                <v-col :sm="getRightColumnWidth(data.value.column_width)" cols="12" class="py-0">
                    <v-card 
                        flat 
                        :color="getCorrectColor(data.value.vid_column_background)"
                        :height="data.value.height_adjustment"
                        class="ml-1"
                    >  
                        <video-media :source="data.value.vid_column_vid_src" :border="'none'" :video_id="data.value.vid_column_chooser"/>
                    </v-card>
                </v-col>
            </v-row>

            <v-row 
                v-if="data.value.content_order == 'image-text'"
            >
                <v-col :sm="getLeftColumnWidth(data.value.column_width)" cols="12" class="py-0">
                    <v-card 
                        flat 
                        :color="getCorrectColor(data.value.vid_column_background)"
                        :height="data.value.height_adjustment"
                        class="mr-1"
                    >  
                        <video-media :source="data.value.vid_column_vid_src" :border="'none'" :video_id="data.value.vid_column_chooser"/>
                    </v-card>
                </v-col>
                <v-col :sm="getRightColumnWidth(data.value.column_width)" cols="12" class="py-0">
                    <v-card 
                        flat 
                        :color="getCorrectColor(data.value.textandimage_column_background)"
                        :height="data.value.height_adjustment"
                        class="ml-1"
                    >  
                        <image-media 
                            v-if="data.value.textandimage_column_img_pos == 'top'"
                            :alt="data.value.textandimage_column_img_alt"
                            :border="'big'"
                            :img_id="data.value.textandimage_column_img_chooser" 
                        />
                        <v-card-text> 
                            <div class="headline mb-2">{{ data.value.textandimage_column_title }}</div>  
                            <div 
                                v-html="data.value.textandimage_column_text"
                                class="subtitle-1  mb-0" 
                            ></div>
                        </v-card-text>
                        <image-media 
                            v-if="data.value.textandimage_column_img_pos == 'bottom'"
                            :alt="data.value.textandimage_column_img_alt"
                            :border="'big-bottom'"
                            :img_id="data.value.textandimage_column_img_chooser" 
                        />
                    </v-card>
                </v-col>
            </v-row>
        </div>

        <!-- type: twoColumnsBothTextAndImage -->
        <div v-if="data.type == 'twoColumnsBothTextAndImage'">
            <v-row>
                <v-col :sm="getLeftColumnWidth(data.value.column_width)" cols="12" class="py-0">
                    <v-card 
                        flat 
                        :color="getCorrectColor(data.value.left_column_background)"
                        :height="data.value.height_adjustment"
                        class="mr-1"
                    >  
                        <image-media 
                            v-if="data.value.left_column_img_pos == 'top'"
                            :alt="data.value.left_column_img_alt"
                            :border="'top'"
                            :img_id="data.value.left_column_img_chooser" 
                        />
                        <v-card-text> 
                            <div class="headline mb-2">{{ data.value.left_column_title }}</div>  
                            <div 
                                v-html="data.value.left_column_text"
                                class="subtitle-1  mb-0" 
                            ></div>
                        </v-card-text>
                        <image-media 
                            v-if="data.value.left_column_img_pos == 'bottom'"
                            :alt="data.value.left_column_img_alt"
                            :border="'bottom'"
                            :img_id="data.value.left_column_img_chooser" 
                        />
                    </v-card>
                </v-col>
                <v-col :sm="getRightColumnWidth(data.value.column_width)" cols="12" class="py-0">
                    <v-card 
                        flat 
                        :color="getCorrectColor(data.value.right_column_background)"
                        :height="data.value.height_adjustment"
                        class="ml-1"
                    >  
                        <image-media 
                            v-if="data.value.right_column_img_pos == 'top'"
                            :alt="data.value.right_column_img_alt"
                            :border="'top'"
                            :img_id="data.value.right_column_img_chooser" 
                        />
                        <v-card-text> 
                            <div class="headline mb-2">{{ data.value.right_column_title }}</div>  
                            <div 
                                v-html="data.value.right_column_text"
                                class="subtitle-1  mb-0" 
                            ></div>
                        </v-card-text>
                        <image-media 
                            v-if="data.value.right_column_img_pos == 'bottom'"
                            :alt="data.value.right_column_img_alt"
                            :border="'bottom'"
                            :img_id="data.value.right_column_img_chooser" 
                        />
                    </v-card>
                </v-col>
            </v-row>
        </div>

    </div>
</template>

<script>
import imageMedia from '@/components/_client/_intervention/_basicMediaBlocks/imageMedia.vue'
import videoMedia from '@/components/_client/_intervention/_basicMediaBlocks/videoMedia.vue'

export default {
    name: 'basic-help',

    props: ['data'],

    components: {
        imageMedia,
        videoMedia
    },

    data () {
        return {

            //

        }
    },

    mounted() {

        // ensure that all external links are save and open in a new div

        var linkList = document.querySelectorAll('#id a');

        var external = RegExp('^((f|ht)tps?:)?//(?!' + location.host + ')');

        for(var i in linkList){

            if (external.test(linkList[i].href)) {
                linkList[i].setAttribute('target', '_blank');
                linkList[i].setAttribute('rel', 'noopener noreferrer');
            }
            
        }

    },

    computed: {

        get_d_flex() {

            if (this.$vuetify.breakpoint.smAndDown == true) {
                return ''
            } else {
                return 'd-flex flex-wrap'
            }

        },    

    }, 

    methods: {

        getCorrectColor(input) {

            switch (input) {

              case 'transparent': return 'transparent'
              case 'primary': return 'primary'      // old value: "primary": "#0277bd"
              case 'secondary lighten-5': return 'grey lighten-5'
              case 'accent': return '#82B1FF'

            }

        },

        getLeftColumnWidth(keyword) {

            if (this.$vuetify.breakpoint.smAndDown == true) {
                return '12'
            } else {
                if (keyword == 'equal') {
                    return '6'
                } else if (keyword == 'smaller-left') {
                    return '4'
                } else if (keyword == 'smaller-right') {
                    return '8'
                }
            }

        },

        getRightColumnWidth(keyword) {

            if (this.$vuetify.breakpoint.smAndDown == true) {
                return '12'
            } else {
                if (keyword == 'equal') {
                    return '6'
                } else if (keyword == 'smaller-left') {
                    return '8'
                } else if (keyword == 'smaller-right') {
                    return '4'
                }
            }
        },

    }
}
</script>

<style scoped>

</style>

