import { render, staticRenderFns } from "./multipleChoicePickOneFormPlus.vue?vue&type=template&id=d5504644&scoped=true&"
import script from "./multipleChoicePickOneFormPlus.vue?vue&type=script&lang=js&"
export * from "./multipleChoicePickOneFormPlus.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5504644",
  null
  
)

export default component.exports