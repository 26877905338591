<template>
    <v-container fluid v-if="loaded">

        <v-row v-if="is_client_home" align="center" justify="center">

            <v-col
                cols="12"
                sm="6"
                md="4"
                v-if="!introduction_finished"
            >
                <v-card
                    class="pa-2"
                    outlined
                    tile
                    color="primary"
                    dark
                    @click="got_to_introduction"
                    fill-height
                >
                    <v-img
                        class="mx-3 mt-3 align-end"
                        height="100px"
                        src="/images/main/compass-regular-accent.png"
                        contain
                    ></v-img>

                    <v-card-text class="text-center title">Einführung ansehen.</v-card-text>

                    <v-card-text class="text-center">
                        Eine Anleitung, wie es geht.
                    </v-card-text>

                </v-card>
            </v-col>

            <v-col
                cols="12"
                sm="6"
                md="4"
                v-if="!introduction_finished && !client_is_dev"
            >
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    <v-img
                        class="mx-3 mt-3 align-center"
                        height="100px"
                        src="/images/main/play-circle-regular-grey.png"
                        contain
                    ></v-img>

                    <v-card-text class="title align-center grey--text text--lighten-1 text-center">Beginnen.</v-card-text>

                    <v-card-text class="text-center grey--text text--lighten-1" v-if="!intervention_started">
                        Das Online-Programm beginnen.
                    </v-card-text>

                </v-card>
            </v-col>

            <v-col
                cols="12"
                sm="6"
                md="4"
                v-if="introduction_finished || client_is_dev"
            >
                <v-card
                    class="pa-2"
                    outlined
                    tile
                    @click="startClicked()"
                    color="primary"
                    dark
                >
                    <v-img
                        class="mx-3 mt-3 align-center"
                        height="100px"
                        src="/images/main/play-circle-regular-accent.png"
                        contain
                        v-if="introduction_finished || client_is_dev"
                    ></v-img>

                    <v-card-text class="text-center title" v-if="!intervention_started && $store.getters['store_client/get_client_progress'].current_module_nr < 7">Beginnen.</v-card-text>
                    <v-card-text class="text-center title" v-if="intervention_started && $store.getters['store_client/get_client_progress'].current_module_nr < 7">Weitermachen.</v-card-text>
                    <v-card-text class="text-center title" v-if="$store.getters['store_client/get_client_progress'].current_module_nr == 7 && $store.getters['store_client/get_client'].status != 5">Abschließen.</v-card-text>
                    <v-card-text class="text-center title" v-if="$store.getters['store_client/get_client_progress'].current_module_nr == 7 && $store.getters['store_client/get_client'].status == 5">Ansehen.</v-card-text>

                    <v-card-text class="text-center" v-if="!intervention_started && $store.getters['store_client/get_client_progress'].current_module_nr < 7">
                        Das Online-Programm beginnen.
                    </v-card-text>

                    <v-card-text class="text-center" v-if="intervention_started && $store.getters['store_client/get_client_progress'].current_module_nr < 7">
                        Zur aktuellen Lektion.
                    </v-card-text>

                    <v-card-text class="text-center" v-if="$store.getters['store_client/get_client_progress'].current_module_nr == 7 && $store.getters['store_client/get_client'].status != 5">
                        Vielen Dank für Ihr Engagement!
                    </v-card-text>

                    <v-card-text class="text-center" v-if="$store.getters['store_client/get_client_progress'].current_module_nr == 7 && $store.getters['store_client/get_client'].status == 5">
                        Inhalte nochmals ansehen.
                    </v-card-text>

                </v-card>
            </v-col>

            <v-col
                cols="12"
                sm="6"
                md="4"
                v-if="!introduction_finished && !client_is_dev"
            >
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >

                    <v-img
                        class="mx-3 mt-3 align-center"
                        height="100px"
                        src="/images/main/grip-horizontal-solid-grey.png"
                        contain
                    ></v-img>

                    <v-card-text class="title text-center grey--text text--lighten-1">Überblick gewinnen.</v-card-text>

                    <v-card-text class="text-center grey--text text--lighten-1">
                        Das Online-Programm im Überblick.
                    </v-card-text>

                </v-card>
            </v-col>

            <v-col
                cols="12"
                sm="6"
                md="4"
                v-if="introduction_finished || client_is_dev"
            >
                <v-card
                    class="pa-2"
                    outlined
                    tile
                    color="primary"
                    dark
                    @click="go_to_overview()"
                >
                   
                    <v-img
                        class="mx-3 mt-3 align-end"
                        height="100px"
                        src="/images/main/grip-horizontal-solid-accent.png"
                        contain
                    ></v-img>

                    <v-card-text class="title text-center">Überblick gewinnen.</v-card-text>

                    <v-card-text class="text-center">
                        Das Online-Programm im Überblick.
                    </v-card-text>

                </v-card>
            </v-col>

            <v-col
                cols="12"
                sm="6"
                md="4"
                v-if="introduction_finished"
            >
                <v-card
                    class="pa-2"
                    outlined
                    tile
                    color="primary"
                    dark
                    @click="got_to_introduction"
                >
                    <v-img
                        class="mx-3 mt-3 align-end"
                        height="100px"
                        src="/images/main/compass-regular-accent.png"
                        contain
                    ></v-img>

                    <v-card-text class="title text-center">Einführung wiederholen.</v-card-text>

                    <v-card-text class="text-center">
                        Eine Anleitung, wie es geht.
                    </v-card-text>

                </v-card>
            </v-col>

            <v-col
                cols="12"
                sm="6"
                md="4"
                v-if="nr_new_messages > 0"
            >
                <v-card
                    class="pa-2"
                    outlined
                    tile
                    color="primary"
                    dark
                    to="/client/messages/null"
                >
                   
                    <v-img
                        class="mx-3 mt-3 align-end"
                        height="100px"
                        src="/images/main/comments-regular-accent.png"
                        contain
                    ></v-img>

                    <v-card-text class="title text-center">Meine Chats.</v-card-text>

                    <v-card-text class="text-center">
                        <span>Sie haben ungelesene Chats.</span>
                    </v-card-text>

                </v-card>
            </v-col>

            <v-col
                cols="12"
                sm="6"
                md="4"
                v-if="nr_new_tasks > 0"
            >
                <v-card
                    class="pa-2"
                    outlined
                    tile
                    color="primary"
                    dark
                    to="/client/tasks"
                >
                   

                    <v-img
                        class="mx-3 mt-3 align-end"
                        height="100px"
                        src="/images/main/tasks-solid-accent.png"
                        contain
                    ></v-img>

                    <v-card-text class="title text-center">To-Do.</v-card-text>

                    <v-card-text class="text-center">
                        Es gibt nicht bearbeitete Übungen.
                    </v-card-text>

            </v-card>
        </v-col>

        <v-col
            cols="12"
            sm="6"
            md="4"
            v-if="nr_new_polls > 0"
        >
            <v-card
                class="pa-2"
                outlined
                tile
                color="primary"
                dark
                to="/client/polls"
            >
                
                <v-img
                    class="mx-3 mt-3 align-end"
                    height="100px"
                    src="/images/main/poll-solid-accent.png"
                    contain
                ></v-img>           

                <v-card-text class="title text-center">Fragebögen.</v-card-text>

                <v-card-text class="text-center">
                    Es gibt nicht ausgefüllte Fragebögen.
                </v-card-text>

            </v-card>
        </v-col>

        <v-col
            cols="12"
            sm="6"
            md="4"
        >
            <v-card
                class="pa-2"
                outlined
                tile
                color="primary"
                dark
                to="/client/awards"
            >
                
                <v-img
                    class="mx-3 mt-3 align-end"
                    height="100px"
                    src="/images/awards/award_0_1_2_3_4_5_6_7_8_9.png"
                    contain
                ></v-img>

                <v-card-text class="title text-center">
                    <span class="overline mr-1 mt-2">Mein Neues</span>
                    Ich.
                </v-card-text>

                <v-card-text class="text-center">
                    Die Auszeichnungen im Überblick.
                </v-card-text>

            </v-card>
        </v-col>

        <v-col
            cols="12"
            sm="6"
            md="4"
        >
            <v-card
                class="pa-2"
                outlined
                tile
                color="primary"
                dark
                to="/client/bank"
            >
                
                <v-img
                    class="mx-3 mt-3 align-end"
                    height="100px"
                    src="/images/main/hand-holding-usd-solid-accent.png"
                    contain
                ></v-img>            

                <v-card-text class="title text-center">Mein Konto.</v-card-text>

                <v-card-text class="text-center">
                    <span>{{ getCurrentEuros.toFixed(2) }}</span>
                    <span class="mr-2 ml-1">
                        <v-icon small>fas fa-euro-sign</v-icon>
                    </span>
                    <span class="mr-2">|</span>
                    <span>
                        {{ getCurrentCoins }}
                    </span>
                    <span class="ml-1">
                        <v-icon small>fas fa-coins</v-icon>
                    </span>
                </v-card-text>

            </v-card>
        </v-col>

        <v-col
            cols="12"
            sm="6"
            md="4"
        >
            <v-card
                class="pa-2"
                outlined
                tile
                color="primary"
                dark
                to="/client/emergency"
            >
                
                <v-img
                    class="mx-3 mt-3 align-end"
                    height="100px"
                    src="/images/main/first-aid-solid-error.png"
                    contain
                ></v-img>

                <v-card-text class="title text-center">Mein Notfallkoffer.</v-card-text>

                <v-card-text class="text-center">
                    Ich brauche Hilfe.
                </v-card-text>

            </v-card>
        </v-col>

    </v-row>

    <router-view v-else />

    <!-- SNACKBAR -->
    <v-snackbar
        v-model="snackbar"
        top
        color="primary"
    >
        {{ snackbar_text }}

        <v-btn outlined fab x-small color="accent" @click="snackbar = false" left>
            <v-icon color="accent">fas fa-times</v-icon>
        </v-btn>

    </v-snackbar>

    <!-- MONEY HAS PAYED DIALOG -->
    <v-dialog
        v-model="show_payed_dialog"
        persistent
        max-width="500"
    >
        <v-card outlined>

            <v-toolbar flat color="transparent" class="my-3">
                <v-icon x-large color="accent">fas fa-hand-holding-usd</v-icon>
                <v-card-text class="headline ml-4 primary--text">Vielen Dank für Ihre Mitarbeit!</v-card-text>
            </v-toolbar>

            <v-divider />

                <v-card-text class="text-center mt-5">
                    <v-icon color="primary" size="96px">fas fa-coins</v-icon>
                    <div class="primary--text display-1 font-weight-bold mt-3">{{ $store.getters['store_client/get_amount_money'] }} Coins</div>
                </v-card-text>

                <v-card-text class="text-center title">
                    wurden Ihnen auf Ihrem Konto gutgeschrieben.
                </v-card-text>

            <v-divider />

            <v-card-text>

                <v-row>

                    <v-col cols>
                
                        <v-btn
                            outlined
                            rounded
                            @click="close_payed_dialog"
                        >
                            Schließen
                        </v-btn>

                    </v-col>

                    <v-spacer />

                    <v-col cols>

                        <v-btn
                            outlined
                            rounded
                            color="primary"
                            @click="go_to_bank"
                        >
                            Zu meinem Konto
                        </v-btn>

                    </v-col>

                </v-row>

            </v-card-text>
            
        </v-card>

    </v-dialog>

    <!-- NEW AWARD DIALOG -->
    <v-dialog
        v-model="show_award_dialog"
        persistent
        max-width="500"

    >
        <v-card outlined>

            <v-toolbar flat color="transparent" class="my-3">
                <v-card-text class="headline ml-4 primary--text text-center">Herzlichen Glückwunsch!</v-card-text>
            </v-toolbar>

            <v-divider />
            
                <v-card-text class="text-center mt-5">
                    Sie haben eine neue Auszeichnung erhalten:
                </v-card-text>

                <v-card-text class="text-center">
                    <v-row align="center" justify="center">
                        <v-img :src="'/images/awards/' + award_image" aspect-ratio="1"></v-img>
                    </v-row>
                </v-card-text>

            <v-divider />

            <v-card-text>
                
                <v-btn
                    outlined
                    rounded
                    @click="close_award_dialog"
                    :style="{left: '50%', transform:'translateX(-50%)'}"
                    class="mt-3"
                >
                    Schließen
                </v-btn>

            </v-card-text>
            
        </v-card>

    </v-dialog>

    <!-- FINISHED DIALOG -->
    <v-dialog
        v-model="show_finished_dialog"
        persistent
        max-width="1200"

    >
        <v-card outlined>

            <v-toolbar flat color="transparent" class="my-3">
                <v-card-text class="headline ml-4 primary--text text-center">Herzlichen Glückwunsch!</v-card-text>
            </v-toolbar>

            <v-divider />
            
                <v-card-text class="text-center mt-5 title accent--text">
                    Sie haben das Programm @myTabu erfolgreich abgeschlossen!
                </v-card-text>

                <v-card-text>
                    <v-row>

                        <v-col cols="12" md="6" sm="12">

                            <v-card flat outlined>

                                <v-card-text class="primary--text title text-center">
                                    Ihr Notfallkoffer zum Download
                                </v-card-text>

                                <v-card-text class="text-center">
                                    <v-btn @click="exportToPDF">Download</v-btn>
                                </v-card-text>

                                <v-card-text class="text-center">
                                    Sie haben in den letzten Wochen für sich Techniken, Gedanken oder Fantasien erarbeitet, die Ihnen in schwierigen Situationen helfen 
                                    können. Damit Sie diese auch nach Ihrer Teilnahme bei @myTabu nachlesen können, haben wir Ihren Notfallkoffer als PDF-Dokument zusammengestellt.
                                    Klicken Sie auf den Button um sich das PDF-Dokument herunterladen. Am besten Sie drucken sich das Dokument hinterher aus oder speichern 
                                    das PDF-Dokument auf Ihrem Handy oder Computer.
                                </v-card-text>

                                <div v-show="always_false">

                                    <div ref="document">

                                        <EmergencyToPdf />

                                    </div>

                                </div>

                            </v-card>

                        </v-col>

                        <v-col cols="12" md="6" sm="12">
                                
                            <v-card flat outlined>

                                <v-card-text class="primary--text title text-center">
                                    Ihr Account wird in
                                </v-card-text>

                                <v-card-text>

                                    <v-row justify="center" align="center">

                                        <v-card class="mr-1">
                                            <v-card-text class="text-center title">
                                                {{days}}
                                            </v-card-text>

                                            <v-divider />

                                            <v-card-text class="caption">
                                                Tage
                                            </v-card-text>
                                        </v-card>

                                        <v-card class="mr-1">
                                            <v-card-text class="text-center title">
                                                {{hours}}
                                            </v-card-text>

                                            <v-divider />

                                            <v-card-text class="caption">
                                                Stdn
                                            </v-card-text>
                                        </v-card>

                                        <v-card class="mr-1">
                                            <v-card-text class="text-center title">
                                                {{minutes}}
                                            </v-card-text>

                                            <v-divider />

                                            <v-card-text class="caption">
                                                Min
                                            </v-card-text>
                                        </v-card>

                                        <v-card>
                                            <v-card-text class="text-center title">
                                                {{seconds}}
                                            </v-card-text>

                                            <v-divider />

                                            <v-card-text class="caption">
                                                Sek
                                            </v-card-text>
                                        </v-card>

                                    </v-row>
                                </v-card-text>

                                <v-card-text class="primary--text title text-center">
                                    deaktiviert.
                                </v-card-text>

                                <v-card-text class="text-center">
                                    Nachdem Ihr Account deaktiviert wird, können Sie sich nicht mehr bei @myTabu einloggen. Wenn Sie es vorher nicht geschafft haben, 
                                    sich Ihre Aufwandsentschädigung auszahlen zu lassen, wird dies nach Schließung des Accounts automatisch durch die Studienleitung 
                                    vorgenommen.
                                </v-card-text>

                            </v-card>

                        </v-col>

                    </v-row>
                </v-card-text>

            <v-divider />

            <v-card-text>
                
                <v-btn
                    outlined
                    rounded
                    @click="close_finished_dialog"
                    :style="{left: '50%', transform:'translateX(-50%)'}"
                    class="mt-3"
                >
                    Schließen
                </v-btn>

            </v-card-text>
            
        </v-card>

    </v-dialog>
 

  </v-container>

  
</template>

<script>
import ClientApi from '@/_api/client_api'

import EmergencyToPdf from '@/components/_client/_emergency/emergency_pdf.vue'

import html2pdf from 'html2pdf.js'

export default {

    name: 'client-home',

    data: () => ({

        loaded: false,

        current_countdown: '00 : 00 : 00',

        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',

        always_false: false

    }),

    components: {
        EmergencyToPdf
    },

    mounted() {

    },

    created() {

        this.loaded = false

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        ClientApi.GetClientInfo().then(() => {

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

            if (this.$store.getters['store_client/get_client'].account_open_until != '') {
                this.update_countdown()
            }

            this.loaded = true

        })

    },

    watch: {

        '$route' () {

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

            ClientApi.GetClientInfo().then(() => {

                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

            })

        }

    },

    computed: {

        show_payed_dialog: {

            set(value) {

                console.log(value)

            },

            get() {

                return this.$store.getters['store_client/get_money_has_payed']

            }

        },

        show_award_dialog: {

            set(value) {

                console.log(value)

            },

            get() {

                return this.$store.getters['store_client/get_client_progress'].new_award_flag

            }

        },

        show_finished_dialog: {

            set(value) {

                console.log(value)

            },

            get() {

                if (this.$store.getters['store_client/get_client'].account_open_until != '' && !this.$store.getters['store_dialogs/get_finish_dialog_has_been_shown']) {
                    return true
                } else {
                    return false
                }

            }

        },

        award_image() {

            var award_number =  this.$store.getters['store_client/get_client_progress'].new_award_number

            if (award_number == 1){
                return 'HerosuitTN.png'
            } else if (award_number == 2){
                return 'CapeTN.png'
            } else if (award_number == 3){
                return 'BootsTN.png'
            } else if (award_number == 4){
                return 'BrilleTN.png'
            } else if (award_number == 5){
                return 'HuskyTN.png'
            } else if (award_number == 6){
                return 'ComputerTN.png'
            } else if (award_number == 7){
                return 'HelmTN.png'
            } else if (award_number == 8){
                return 'SchildTN.png'
            } else {
                return 'SchlüsselTN.png'
            }

        },

        show_cookies_dialog() {

            return !this.$store.getters['store_client/get_client'].cookies_accepted

        },

        snackbar: {

            get () {
                return this.$store.getters['store_dialogs/get_show_client_snackbar']
            },
            set (value) {
                this.$store.dispatch('store_dialogs/UPDATE_SHOW_CLIENT_SNACKBAR', value)
            }

        },

        snackbar_text() {
            return this.$store.getters['store_dialogs/get_client_snackbar_text']
        },

        is_client_home() {
            if(this.$route.path == '/client') {
                return true
            } else {
                return false
            }
        },

        loading() {
            return this.$store.getters['store_loader/get_show_main_loader']
        },

        intervention_started() {
            return this.$store.getters['store_client/get_client_progress'].intervention_started
        },

        intervention_finished() {
            return this.$store.getters['store_client/get_client_progress'].intervention_finished
        },

        introduction_finished() {
            return this.$store.getters['store_client/get_client_progress'].introduction_finished
        },

        getCurrentEuros() {

            var tmp = this.$store.getters['store_client/get_client_reward'].current_euros

            return Math.round((tmp + Number.EPSILON) * 100) / 100
        },

        getCurrentCoins() {
            return this.$store.getters['store_client/get_client_reward'].current_coins
        },

        nr_new_tasks() {
            return this.$store.getters['store_client/get_client_tasks'].unfinished_tasks_count
        },

        nr_new_polls() {
            return this.$store.getters['store_client/get_client_questionnaires'].unfinished_questionnaires_count
        },

        nr_new_messages() {
            return this.$store.getters['store_client/get_client_messages'].unread_message_count
        },

        client_is_dev() {
            return this.$store.getters['store_client/get_client'].is_dev
        },

    },

    methods: {

        // pdf2html
        exportToPDF () {
            
            html2pdf(this.$refs.document, {
                margin: 1,
                filename: 'Mein_Notfallkoffer.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { dpi: 300, letterRendering: true, allowTaint: true },
                jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait', lang: 'de' },
                pagebreak: { before: '#page2el' }
            })

        },


        // client home

        close_payed_dialog() {

            ClientApi.UpdateMoneyPaidFlag().then(() => {

                this.$store.dispatch('store_client/UPDATE_MONEY_HAS_PAYED', false)
                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

            })
            
        },

        close_finished_dialog() {

            this.$store.dispatch('store_dialogs/UPDATE_FINISH_DIALOG_HAS_BEEN_SHOWN', true)
            
        },

        close_award_dialog() {

            ClientApi.UpdateNewAwardFlag().then(() => {

                this.$store.dispatch('store_client/UPDATE_NEW_AWARD_FLAG')
                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

            })

        },

        got_to_introduction() {

            // set page allowed to true
            console.log('UPDATE_PAGE_ALLOWED: client_home')
            this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

            if (this.$store.getters['store_client/get_client_progress'].introduction_finished) {
                this.$router.push({ name: 'client-introduction', params: { rank: 1 } })
            } else {
                this.$router.push({ name: 'client-introduction', params: { rank: this.$store.getters['store_client/get_client_progress'].current_lession_nr } })
            }

        },

        go_to_bank() {

            ClientApi.UpdateMoneyPaidFlag().then(() => {

                this.$store.dispatch('store_client/UPDATE_MONEY_HAS_PAYED', false)
                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                this.$router.push({name: 'client-bank' })

            })

        },

        go_to_awards() {

            ClientApi.UpdateNewAwardFlag().then(() => {

                this.$router.push({name: 'client-awards' })
                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

            })

        },

        close_money_dialog() {

            this.$store.dispatch('store_client/UPDATE_MONEY_HAS_PAYED', false) 

        },

        startClicked() {

            // set page allowed to true
            console.log('UPDATE_PAGE_ALLOWED: client_home')
            this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

            if (this.$store.getters['store_client/get_client'].is_dev) { 

                this.$router.push({ name: 'client-intervention', params: { module: this.$store.getters['store_client/get_client_progress'].current_module_nr, session: this.$store.getters['store_client/get_client_progress'].current_session_nr, lection: this.$store.getters['store_client/get_client_progress'].current_lession_nr } })

            } else {

                if (!this.introduction_finished) {

                    this.dialog_intervention = true

                } else {

                    if (this.intervention_started) {

                        if (this.$store.getters['store_client/get_client_progress'].current_module_nr == 7 && this.$store.getters['store_client/get_client'].status != 5) {
                            this.$router.push({ name: 'client-final'})

                        } else if (this.$store.getters['store_client/get_client_progress'].current_module_nr == 7 && this.$store.getters['store_client/get_client'].status == 5) {

                            // set page allowed to true
                            console.log('UPDATE_PAGE_ALLOWED: client_home')
                            this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

                            this.$router.push({ name: 'client-intervention', params: { module: 1, session: 1, lection: 1 } })

                        } else {

                            // set page allowed to true
                            console.log('UPDATE_PAGE_ALLOWED: client_home')
                            this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

                            this.$router.push({ name: 'client-intervention', params: { module: this.$store.getters['store_client/get_client_progress'].current_module_nr, session: this.$store.getters['store_client/get_client_progress'].current_session_nr, lection: this.$store.getters['store_client/get_client_progress'].current_lession_nr } })
                        }
                        
                    } else {

                        // set page allowed to true
                        console.log('UPDATE_PAGE_ALLOWED: client_home')
                        this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

                        this.$router.push({ name: 'client-intervention', params: { module: 1, session: 1, lection: 1 } })
                    }


                }

            }
    
        },

        go_to_overview() {

            if (this.$store.getters['store_client/get_client'].is_dev) { 

                this.$router.push('/client/overview')

            } else {

                if (!this.introduction_finished) {
                    this.dialog_intervention = true
                } else {
                    this.$router.push('/client/overview')
                }

            }

        },

        update_countdown() {

            var countDownDate = new Date(this.$store.getters['store_client/get_client'].account_open_until).getTime()

            // Update the count down every 1 second
            var x = setInterval(() => {

                // Get today's date and time
                var now = new Date().getTime();
                    
                // Find the distance between now and the count down date
                var distance = countDownDate - now;
                    
                // Time calculations for days, hours, minutes and seconds
                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                if (days < 10) {
                    this.days = '0' + days
                } else {
                    this.days = days
                }

                if (hours < 10) {
                    this.hours = '0' + hours
                } else {
                    this.hours = hours
                }

                if (minutes < 10) {
                    this.minutes = '0' + minutes
                } else {
                    this.minutes = minutes
                }

                if (seconds < 10) {
                    this.seconds = '0' + seconds
                } else {
                    this.seconds = seconds
                }

                this.value_progress_circular = (100/60)*seconds

                this.current_countdown = this.days + ':' + this.hours + ':' + this.minutes + ':' + this.seconds
                    
                // If the count down is over, write some text 
                if (distance < 0) {
                    clearInterval(x);
                    this.current_countdown = '00 : 00 : 00'
                    this.days = '00'
                    this.hours = '00'
                    this.minutes = '00'
                    this.seconds = '00'
                }

            }, 1000);


        }

    }
    
}
</script>

<style scoped>

</style>

