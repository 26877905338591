<template>

    <v-expansion-panels 
        v-if="content.length > 0"
        v-model="panel"
        multiple
    >

        <v-expansion-panel
            v-for="item in content"
            :key="item.name"
            class="my-3"
        >

            <v-expansion-panel-header color="grey lighten-5">

                <v-card flat color="grey lighten-5" class="py-0">

                    <v-card-text class="pl-0 title">
                        {{ item.title }}
                    </v-card-text>

                </v-card>

                <template v-slot:actions>
                    <v-icon color="primary">$expand</v-icon>
                </template>
            </v-expansion-panel-header>

            <v-divider />

            <v-expansion-panel-content>

                <v-row
                    v-for="(block, index) in item.body[0].value.columns"
                    :key="index"
                >

                    <v-card 
                        class="my-3" 
                        color="grey lighten-5" 
                        flat
                    >

                        <BasicHelp :data="item.body[0].value.columns[index]" />

                    </v-card>

                </v-row>
            </v-expansion-panel-content>

        </v-expansion-panel>

    </v-expansion-panels>

</template>

<script>
import SupervisorApi from '@/_api/supervisor_api'

import BasicHelp from '@/components/_common/help/basic_help'

export default {
    name: 'supervisor-help',

    components: {
        BasicHelp
    },

    data: () => ({

        panel: [],
        content: []

    }),

    created() {

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        SupervisorApi.GetSupervisorInfo().then(() => {

            SupervisorApi.GetHelpPages().then((resp) => {

                this.content = resp.data.items

                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

            })

        })

    },
}
</script>

<style scoped>

</style>