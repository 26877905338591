<template>
  <div class="mx-3" v-if="ready">

        <v-row>
            <div class="headline primary--text">{{ question }}</div>
        </v-row>

        <v-row>
            <div class="overline mb-3" v-if="minKeyWords > 1"><b>Tipp:</b> Sie müssen mindestens {{ minKeyWords }} Stichwörter erstellen.</div>
            <div class="overline mb-3" v-if="minKeyWords == 1"><b>Tipp:</b> Sie müssen mindestens {{ minKeyWords }} Stichwort erstellen.</div>
        </v-row>

        <v-row class="mt-3 mx-1" v-if="!thisBtnDisabled">

            <v-text-field
                v-model="message"
                outlined
                clear-icon="far fa-plus-square"
                clearable
                label="Klicken Sie hier, um ein Stichwort hinzuzufügen!"
                type="text"
                @click:clear="addChoice"
                
                :disabled="thisBtnDisabled"
            ></v-text-field>

        </v-row>

        <v-row>

            <v-col cols="12" sm="12"> 

              <v-card
                  class="mx-auto"
                  outlined
                  min-height="120px"
              >

                  <v-card-text class="subtitle-1 font-weight-medium">
                      Meine Stichwörter:
                  </v-card-text>

                  <v-card-text class="py-0">

                    <v-row>

                          <v-row
                              v-for="(item, i) in keywordList"
                              :key="i"
                              align="center" 
                              justify="center"
                          >

                            <v-card color="accent" class="my-3 mx-3">

                                <v-card-text class="body-1">
                                {{ item }}
                                <v-btn icon :disabled = "thisBtnDisabled" @click="closeChip(i)">
                                    <v-icon color="black">far fa-times-circle</v-icon>
                                </v-btn>
                                </v-card-text>
                                                    
                            </v-card>

                          </v-row>

                    </v-row>

                  </v-card-text>

              </v-card>

          </v-col>

      </v-row>

  </div>
</template>

<script>
export default {
    name: 'collect-keywords-form-rev',
    props: ['taskID', 'question', 'id', 'minKeyWords'],

    data: () => ({

      message: null,
      keywordList: [],

      ready: true,

      thisBtnDisabled: false,

    }),

    created () {

      this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'type', 'val': 'collectKeywordsForm'})
      this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'keywordList', 'val': this.keywordList})

      this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'success', 'val': false})
      
    },

    watch: {

      // watch the checkbox-state and update the store immediatly on change
      keywordList: function(val) {

        this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'keywordList', 'val': val})

        if (val.length >= this.minKeyWords) {

          this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'success', 'val': true})
        
        } else {

          this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'success', 'val': false})
        
        }
      
      }

    },

    computed: {

        cssProps () {

          return {
              '--primary-color': this.$vuetify.theme.primary
          }

        }
    },

    methods: {

      // add a item to the list and tick it
      addChoice () {

        this.keywordList.push(this.message)
        this.message = null

      },

      closeChip(i) {

          this.keywordList.splice(i, 1)

      },
     
    }
}
</script>

<style scoped>
.custom-chip {

    display: inline-flex;
    flex-direction: row;
    background-color: #004b8c;

    width: auto;

    height: auto;

    outline: none;

    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-left: 12px;

    margin: 12px;

    line-height: 20px;

    white-space: pre-wrap;

    align-items: center;

    border-radius: 20px;
    font-size: 14px;

    vertical-align: middle;
    text-decoration: none;
    justify-content: center;

}

.drop-main {
    display: flex;
    justify-content: left;
    flex-direction: row;
    flex-wrap: wrap;

    min-width: 100%;
    min-height: 45px;

    padding-top: 12px;
    padding-bottom: 12px;

    background: #EEEEEE;

    border-style: solid;
    border-width: 1px;
    border-radius: 5px !important;
    border-color: rgb(148, 148, 148) !important;
}

.chip {
    display: inline-flex;
    margin: 3px;
    padding: 0 10px;
    min-height: 32px;
    font-size: 16px;
    
    border-radius: 25px;
    background-color: #004b8c;;
    word-break: break-all;
}

.chip-text{
  align-items: center;
  display: inline-flex;
  vertical-align: middle;
  z-index:1;


}
</style>
