<template>

        <v-row align="center" justify="center" class="px-3">

            <v-col cols="12" sm="12">
                
                <video controls class="videoPlayer" v-if="loaded">
                    <source 
                        :src="video_http"
                        type="video/mp4">
                    Your browser does not support HTML5 video.
                </video>

                <div class="errorDiv text-center my-5 py-5" v-if="!loaded">
                    <p class="overline mx-3 my-3">Das Video konnte leider nicht geladen werden.</p>
                </div>

            </v-col>

        </v-row>

</template>


<script>
import axios from 'axios'

export default {
  name: 'videoMedia',
  props: ['source', 'border', 'video_id'],
  data() {
        return {

            loaded: false,

            video_http: ''  // http://localhost:8000/media/media/SampleVideo_1280x720_1mb.mp4

        }
        
    },

    mounted() {
      this.getVideoUrl()
  },

    methods: {
        getVideoUrl() {

            this.loaded = false

            var endpoint = 'api-wagtail/media/' + this.video_id + '/'

            axios.get(endpoint)
            .then((response)=>{

                this.video_http = '/videos/' + response.data.title + '.mp4'
                this.loaded = true
  
                })
            .catch(()=>{
               
               this.loaded = false
                
            })
        },
    },

    computed: {

    }
}
</script>

<style>
.videoPlayer {
    max-width: 100% !important;
    max-height: 100% !important;
    display: inline-block !important;
    padding: 0px;
    margin: 0px;
    border-radius:5px;
}

.videoParent {
    border-style: solid;
    border-width: 1px;
    border-radius:5px;
    border-color: transparent !important;
    padding: 0px;
    margin: 0px;
}

.errorDiv {
    min-height: 100px !important;
    min-width: 100px !important;
    border-style: solid;
    border-width: 1px;
    border-color: var(--v-primary-base) !important;
    background-color: var(--v-secondary-lighten5);
}
</style>