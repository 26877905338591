<template>
    <v-card outlined>

        <v-card-text class="title">
            {{ title }}
        </v-card-text>

        <v-divider />

        <v-card-text v-if="first">

            <div class="title  mb-2"><span><v-icon large class="mr-3">far fa-compass</v-icon></span>Ziel</div>

            <div 
                class="subtitle-1  mb-0" 
                v-html="data.wagtail_task_data.goal"
            ></div>

        </v-card-text>

        <v-card-text v-if="first">           
                            
            <div class="title  mb-2"><span><v-icon large class="mr-3">far fa-clipboard</v-icon></span>Anleitung</div>

            <div 
                class="subtitle-1  mb-0" 
                v-html="data.wagtail_task_data.instruction"
            ></div>

        </v-card-text>

        <v-card-text>

            <!-- {{dataType}} -->

            <!-- TASKS -->
            <div v-if="data.type == 'multipleChoicePickNFormPlus'">
                <multipleChoicePickNFormPlus-data :data = "data"/>
            </div>

            <div v-if="data.type == 'multipleChoicePickNForm'">
                <multipleChoicePickNForm-data :data = "data"/>
            </div>

            <div v-if="data.type == 'multipleChoicePickOneFormPlus'">
                <multipleChoicePickOneFormPlus-data :data = "data"/>
            </div>

            <div v-if="data.type == 'multipleChoicePickOneForm'">
                <multipleChoicePickOneForm-data :data = "data"/>
            </div>

            <div v-if="data.type == 'freeTextForm'">
                <free-text-form-data :data = "data"/>
            </div>

            <div v-if="data.type == 'rightWrongForm'">
                <right-wrong-form-data :data = "data"/>
            </div>

            <div v-if="data.type == 'proContraInputTask'">
                <proContraInputTask-data :data = "data"/>
            </div>

            <div v-if="data.type == 'traditionalLikertScaleForm'">
                <traditional-likert-scale-form-data :data = "data"/>
            </div>

            <div v-if="data.type == 'collectKeywordsForm'">
                <collect-keywords-form-data :data = "data"/>
            </div>

            <div v-if="data.type == 'defaultHierarchyAssignmentForm'">
                <default-hierarchy-assignment-form-data :data = "data"/>
            </div>

            <div v-if="data.type == 'fourFieldInputForm'">
                <four-field-input-form-data :data = "data"/>
            </div>

            <div v-if="data.type == 'dataHierarchyAssignmentForm'">
                <data-hierarchy-assignment-form-data :data = "data"/>
            </div>

            <!-- TASKS -->
            <div v-if="data.type == 'proContraAssignmentTask'">
                <pro-contra-assignment-task-data :data = "data"/>
            </div>

            <div v-if="data.type == 'multipleChoicePickNTask'">
                <multiple-choice-pick-n-task-data :data = "data"/>
            </div>

            <div v-if="data.type == 'fillInTheGapAssignmentTask'">
                <fill-in-the-gap-assignment-task-data :data = "data"/>
            </div>

            <div v-if="data.type == 'simpleAssignmentTask'">
                <simple-assignment-task-data :data = "data"/>
            </div>

            <div v-if="data.type == 'fourFieldAssignmentTask'">
                <four-field-assignment-task-data :data = "data"/>
            </div>

            </v-card-text>
        </v-card>
</template>

<script>
// forms
import multipleChoicePickNFormPlusData from '@/components/_client/_intervention/_basicDataBlocks/multipleChoicePickNFormPlusData.vue'
import multipleChoicePickNFormData from '@/components/_client/_intervention/_basicDataBlocks/multipleChoicePickNFormData.vue'
import multipleChoicePickOneFormPlusData from '@/components/_client/_intervention/_basicDataBlocks/multipleChoicePickOneFormPlusData.vue'
import multipleChoicePickOneFormData from '@/components/_client/_intervention/_basicDataBlocks/multipleChoicePickOneFormData.vue'
import freeTextFormData from '@/components/_client/_intervention/_basicDataBlocks/freeTextFormData.vue'
import rightWrongFormData from '@/components/_client/_intervention/_basicDataBlocks/rightWrongFormData.vue'
//import sliderLikertScaleFormData from '@/components/_client/_intervention/_basicDataBlocks/sliderLikertScaleFormData.vue'
import traditionalLikertScaleFormData from '@/components/_client/_intervention/_basicDataBlocks/traditionalLikertScaleFormData.vue'
import proContraInputTaskData from '@/components/_client/_intervention/_basicDataBlocks/proContraInputTaskData.vue'
import collectKeywordsFormData from '@/components/_client/_intervention/_basicDataBlocks/collectKeywordsFormData.vue'
import defaultHierarchyAssignmentFormData from '@/components/_client/_intervention/_basicDataBlocks/defaultHierarchyAssignmentFormData.vue'
import fourFieldInputFormData from '@/components/_client/_intervention/_basicDataBlocks/fourFieldInputFormData.vue'
import dataHierarchyAssignmentFormData from '@/components/_client/_intervention/_basicDataBlocks/dataHierarchyAssignmentFormData.vue'

// tasks
//import rightWrongTaskData from '@/components/_client/_intervention/_basicDataBlocks/rightWrongTaskData.vue'
import proContraAssignmentTaskData from '@/components/_client/_intervention/_basicDataBlocks/proContraAssignmentTaskData.vue'
import multipleChoicePickNTaskData from '@/components/_client/_intervention/_basicDataBlocks/multipleChoicePickNTaskData.vue'
//import multipleChoicePickOneTaskData from '@/components/_client/_intervention/_basicDataBlocks/multipleChoicePickOneTaskData.vue'
import fillInTheGapAssignmentTaskData from '@/components/_client/_intervention/_basicDataBlocks/fillInTheGapAssignmentTaskData.vue'
import simpleAssignmentTaskData from '@/components/_client/_intervention/_basicDataBlocks/simpleAssignmentTaskData.vue'
import fourFieldAssignmentTaskData from '@/components/_client/_intervention/_basicDataBlocks/fourFieldAssignmentTaskData.vue'

export default {

    name: 'guided-task-data',

    props: ['data', 'first', 'title'],

    components: {
      multipleChoicePickNFormPlusData,
      multipleChoicePickNFormData,
      multipleChoicePickOneFormPlusData,
      multipleChoicePickOneFormData,
      freeTextFormData,
      rightWrongFormData,
      //sliderLikertScaleFormData,
      traditionalLikertScaleFormData,
      proContraInputTaskData,
      collectKeywordsFormData,
      defaultHierarchyAssignmentFormData,
      fourFieldInputFormData,
      dataHierarchyAssignmentFormData,

      //rightWrongTaskData,
      proContraAssignmentTaskData,
      //multipleChoicePickOneTaskData,
      multipleChoicePickNTaskData,
      fillInTheGapAssignmentTaskData,
      simpleAssignmentTaskData,
      fourFieldAssignmentTaskData
      
    },

    data: () => ({

    })
}
</script>