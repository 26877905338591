<template>
    
    <v-container>

        <v-row justify="center" align="center">

            <v-col>
                <v-card
                    class="pa-2"
                    height="100%"
                    outlined
                    tile
                    @click="new_msg"
                >
                    <v-img
                        class="ma-3 align-end"
                        height="80px"
                        src="/images/main/comment-regular-success.png"
                        contain
                    ></v-img>

                    <v-card-text class="text-center title">
                        <span v-if="$store.getters['store_client/get_client_supervisor'].supervisor_gender == 'm'">Mein Bewährungshelfer</span>
                        <span v-else>Meine Bewährungshelferin</span>
                    </v-card-text>

                    <v-card-text class="pa-0 text-center">
                        <div class="title primary--text my-3">Neue Nachricht</div>
                        <div class="overline my-3">Antwort innerhalb der Geschäftszeiten Ihrer Bewährungshilfe</div>
                    </v-card-text>

                </v-card>
            </v-col>

            <v-col>
                <v-card
                    class="pa-2"
                    height="100%"
                    outlined
                    tile
                    @click="new_msg"
                >
                    <v-img
                        class="ma-3 align-end"
                        height="80px"
                        src="/images/main/comment-regular-success.png"
                        contain
                    ></v-img>

                    <v-card-text class="text-center title">
                        <span v-if="$store.getters['store_client/get_client_coach'].coach_gender == 1">Mein Coach</span>
                        <span v-else>Meine Coachin</span>
                    </v-card-text>

                    <v-card-text class="pa-0 text-center">
                        <div class="title primary--text my-3">Neue Nachricht</div>
                        <div class="overline my-3">Antwort innerhalb von 36 Stunden (Mo - Fr)</div>
                    </v-card-text>

                </v-card>
            </v-col>

        </v-row>

    </v-container>
</template>

<script>
//import ClientApi from '@/_api/client_api'

export default {
    name: 'client-emergency-success',

    data: () => ({
        //
    }),

    created() {

    },

    methods: {

        new_msg() {
            this.$router.push({ name: 'client-new-messages' })
        }
    }
}
</script>

<style scoped>

</style>