<template>

    <v-container fluid v-if="loaded">

        <v-card flat>
        
            <v-card-title class="display-1">
                Acute-2007-SR
            </v-card-title>

            <v-card-text>

                <v-card flat outlined>

                    <v-expansion-panels flat>

                        <v-expansion-panel>

                            <v-expansion-panel-header>

                                <v-card flat class="py-0 my-0">

                                    <v-card-text class="pl-0 title py-0 my-0">
                                        Informationen zum Acute-2007-SR
                                    </v-card-text>

                                </v-card>

                                <template v-slot:actions>
                                    <v-icon color="primary">$expand</v-icon>
                                </template>
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>

                                <v-card flat class="mt-3">

                                    <v-card-text class="black--text px-0 mx-0">
                                        <p>
                                            Der Acute-2007-Self-Report (Acute-2007-SR) ist ein Selbstbeschreibungsverfahren zur Erfassung von akut-dynamischen Rückfallfaktoren. Akut-dynamische 
                                            Risikofaktoren sind Faktoren, die sich innerhalb von Stunden oder Tagen verändern können. Der Acute-2007-SR orientiert sich an dem Fremdbeurteilungsverfahren 
                                            Acute-2007 und besteht aus insgesamt 43 Items, die mithilfe einer dreistufigen Antwortkategorie beantwortet werden. Diese geben das Erleben der Person in den 
                                            letzten Tagen wieder. Während der klinischen Studie wird der Acute-2007-SR nach jeder abgeschlossenen Sitzung erfasst (i. d. R. einmal pro Monat).
                                        </p>
                                        <p>
                                            Die unterschiedlichen Items lassen sich in sieben Themenbereiche zusammenfassen: (1) Beschäftigung mit potentiellen Opfern, 
                                            (2) Feindseligkeit, (3) Hypersexualität, (4) Ablehnung von Kontrollmaßnahmen, (5) Emotionale Krisensituation, (6) Wegfall 
                                            sozialer Unterstützung und (7) Substanzkonsum. Diese werden im Folgenden näher beschrieben:
                                        </p>
                                        <p>
                                            <i>Beschäftigung mit potentiellen Opfern:</i> Diese Fragen betreffen das Ausmaß und die Intensität, mit dem sich eine Person gedanklich mit potentiellen 
                                            Opfern und konkreten Plänen und Vorbereitungen zur Kontaktaufnahme beschäftigt. Darüber hinaus wird ganz konkret nach dem 
                                            Bedürfnis gefragt, bald (wieder) sexuelle Handlungen mit einem Kind zu begehen bzw. Kinderpornographie zu konsumieren. 
                                        </p>
                                        <p>
                                            <i>Feindseligkeit:</i> Diese Fragen betreffen das emotionale und kognitive Feindseligkeitserleben der Person. 
                                            Dies kann als Prädisposition für aggressives bzw. kriminelles Verhalten gesehen werden. Beispiele hierfür sind Misstrauen und 
                                            Rachegedanken.
                                        </p>
                                        <p>
                                            <i>Hypersexualität:</i> Diese Fragen betreffen die sexuelle Dranghaftigkeit und Sexualität als Coping als zwei wesentliche Bestandteile der Hypersexualität. 
                                            Beispiele hierfür sind sexuelle Handlungen in Folge von Stress oder Einsamkeitserleben.
                                        </p>
                                        <p>
                                            <i>Ablehnung von Kontrollmaßnahmen:</i> Diese Fragen betreffen das beobachtbare Widerstandsverhalten einer Person gegenüber Kontrollmaßnahmen. 
                                            Beispiele hierfür sind das Zuspätkommen oder Verpassen von Terminen oder das Verheimlichen wichtiger Informationen gegenüber Einrichtungen. 
                                        </p>
                                        <p>
                                            <i>Emotionale Krisensituation:</i> Diese Fragen erfassen ein emotionales Krisenerleben der Person. Beispiele hierfür sind das Gefühl, 
                                            Belastungen nicht mehr aushalten zu können, eine wahrgenommene Gleichgültigkeit angesichts der eigenen Zukunft sowie Suizidgedanken. 
                                        </p>
                                        <p>
                                            <i>Wegfall sozialer Unterstützung:</i> Diese Fragen betreffen negative Veränderungen sozialer Kontakte und auch das subjektive Gefühl der Einsamkeit. 
                                            Beispiele hierfür sind Kontaktabbrüche oder Konflikte mit nahestehenden Personen.
                                        </p>
                                        <p>
                                            <i>Substanzkonsum:</i> Diese Fragen betreffen den aktuellen Substanzkonsum der Person. Neben konkretem Konsumverhalten werden auch das Kontrollerleben sowie das 
                                            Vorliegen von möglichen, aus dem Substanzkonsum resultierenden Probleme erfasst. Die Skala umfasst insbesondere den Alkoholkonsum, da dieser bei Kindesmissbrauchstätern eine deutlich 
                                            größere Rolle spielt als der Konsum anderer Drogen.
                                        </p>
                                        <p>
                                            Es ist zu beachten, dass das Item "Gibt es momentan jemanden, mit dem Sie reden können, wenn es Ihnen schlecht geht?" invertiert ist, 
                                            d. h. bei diesem Item werden zwei Punkte für die Antwort "nein" vergeben und null Punkte für die Antwort "oft". Dies wurde in der unten stehenden 
                                            Tabelle bereits berücksichtigt.
                                        </p>
                                    </v-card-text>

                                </v-card>

                            </v-expansion-panel-content>

                        </v-expansion-panel>

                    </v-expansion-panels>

                </v-card>

                <v-card flat outlined class="mt-5">

                    <v-card-text class="primary--text body-1">
                        <b>Wichtig:</b> Nutzen Sie diese Daten keinesfalls als alleinige Basis für eine Entscheidung. Die Daten stammen aus einer laufenden Studie, 
                        es ist wichtig, dass Sie die erhaltenen Informationen erst im Gespräch mit Ihrem*Ihrer Klient*in oder mithilfe eines Ihrer bereits 
                        etablierten Instrumente überprüfen, bevor Sie sich für ein weiteres Vorgehen entscheiden.
                    </v-card-text>

                </v-card>

            </v-card-text>

            <v-card-text>
                <v-card flat outlined>

                    <v-card-text>
                        <v-row>

                            <v-col cols="12" sm="12" md="6">
                                <v-autocomplete
                                    v-model="dropdown_client_value"
                                    :items="dropdown_client"
                                    dense
                                    label="Welche*r Klient*in"
                                    @change="update_client"
                                    :loading="loading_dropdown_client"
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="12" sm="12" md="6">
                                <v-autocomplete
                                    :items="dropdown_timepoints"
                                    label="Messzeitpunkt"
                                    dense
                                    v-model="dropdown_timepoints_value"
                                    @change="update_timepoint"
                                ></v-autocomplete>
                            </v-col>

                        </v-row>

                    </v-card-text>

                </v-card>
            </v-card-text>

            <v-card-text>
                <v-card flat outlined>
            
                    <v-data-table
                        :headers="headers"
                        :items="current_data"
                        item-key="item"
                        :items-per-page="43"
                        hide-default-footer
                        no-data-text="Für diese*diesen Klienten*Klientin liegen noch keine Daten vor."
                    >

                        <template v-slot:item.item="{ item }">
                            <span class="subtitle-1 primary--text">{{ item.item }}</span>
                        </template>

                         <template v-slot:item.raw_value_2="{ item }">
                            <span class="subtitle-1 text-center">
                                <span v-if="item.risk_value == -999">-</span>
                                <span v-else>{{item.risk_value}}</span>
                            </span>
                        </template>

                        <template v-slot:item.risk_value="{ item }">


                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">

                                    <span v-bind="attrs" v-on="on">
                                        <v-row align="center" justify="center">
                                            <v-icon :color="item.risk_value | getIconColorOne">{{ item.risk_value | getIconOne }}</v-icon>
                                            <v-icon :color="item.risk_value | getIconColorTwo">{{ item.risk_value | getIconTwo }}</v-icon>
                                            <v-icon :color="item.risk_value | getIconColorThree">{{ item.risk_value | getIconThree }}</v-icon>
                                        </v-row>
                                    </span>

                                </template>

                                <!-- CHOICES_1_Reverse -->
                                <span v-if="item.item == 'Gibt es momentan jemanden, mit dem Sie reden können, wenn es Ihnen schlecht geht?'">
                                    <v-icon color="green lighten-1" small>fas fa-circle</v-icon><span class="ml-1 mr-3 body-2">Oft</span>
                                    <v-icon color="orange lighten-1" small>fas fa-circle</v-icon><span class="ml-1 mr-3 body-2">Manchmal</span>
                                    <v-icon color="red lighten-1" small>fas fa-circle</v-icon><span class="ml-1 body-2">Nein</span>
                                </span>

                                <span v-else>

                                    <!-- CHOICES_2 -->
                                    <span v-if="item.item == 'Sind in den letzten Tagen wichtige Bezugspersonen (z. B. enges Familienmitglied oder guter Freund) von Ihnen verstorben?'">
                                        <v-icon color="green lighten-1" small>fas fa-circle</v-icon><span class="ml-1 mr-3 body-2">Nein</span>
                                        <v-icon color="red lighten-1" small>fas fa-circle</v-icon><span class="ml-1 body-2">Ja</span>
                                    </span>

                                    <span v-else>

                                        <!-- CHOICES_3 -->
                                        <span v-if="item.item == 'Kommt es in letzter Zeit vor, dass Sie Termine mit Ihrem Therapeuten (m/w/d) verpassen?' || item.item == 'Kommt es in letzter Zeit vor, dass Sie zu spät zu Terminen mit Ihrem Therapeuten (m/w/d) kommen?'">
                                            <v-icon color="green lighten-1" small>fas fa-circle</v-icon><span class="ml-1 mr-3 body-2">Nein</span>
                                            <v-icon color="orange lighten-1" small>fas fa-circle</v-icon><span class="ml-1 mr-3 body-2">Manchmal</span>
                                            <v-icon color="red lighten-1" small>fas fa-circle</v-icon><span class="ml-1 mr-3 body-2">Oft</span>
                                            <v-icon small>fas fa-circle</v-icon><span class="ml-1 body-2">kein Therapeut</span>
                                        </span>

                                        <span v-else>

                                            <!-- CHOICES_1 -->
                                            <span>
                                                <v-icon color="green lighten-1" small>fas fa-circle</v-icon><span class="ml-1 mr-3 body-2">Nein</span>
                                                <v-icon color="orange lighten-1" small>fas fa-circle</v-icon><span class="ml-1 mr-3 body-2">Manchmal</span>
                                                <v-icon color="red lighten-1" small>fas fa-circle</v-icon><span class="ml-1 body-2">Oft</span>
                                            </span>

                                        </span>

                                    </span>

                                </span>
                            </v-tooltip>
                        </template>

                        <template v-slot:item.help_btn="{ item }">
                            <span>
                                <v-tooltip bottom v-if="show_help_btn(item)">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn 
                                            icon 
                                            color="primary'"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="go_to_help(item)"
                                        >
                                            <v-icon>far fa-question-circle</v-icon>
                                        </v-btn>
                                    </template>
                                <span>Handlungsempfehlung ansehen</span>
                                </v-tooltip>
                            </span>
                        </template>

                        <template v-slot:item.raw_value="{ item }">
                            <span><v-btn color="primary" outlined @click="open_curve(item.item)">Verlauf</v-btn></span>
                        </template>

                    </v-data-table>

                </v-card>
            </v-card-text>

        </v-card>

        <!-- Verlauf DIALOG -->
        <v-dialog
            v-model="show_curve"
            persistent
            max-width="800"
        >
            <v-card outlined>

                <v-card-text class="title primary--text text-center mt-5">
                    {{ show_curve_title }}
                </v-card-text>

                <v-divider />

                <v-card-text class="mt-5 caption text-center">

                    <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left title">
                                    Messzeitpunkt
                                </th>
                                <th class="text-center title">
                                    Wert
                                </th>
                                <th class="text-center title">
                                    Einschätzung
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, i) in current_curve_data.labels"
                                :key="item.name"
                            >
                                <td class="text-left">{{ item }}</td>
                                <td class="text-center">{{ current_curve_data.values[i] }}</td>
                                <td class="text-center">
                                    <v-row align="center" justify="center">
                                        <v-icon :color="current_curve_data.values[i] | getIconColorOne()">{{ current_curve_data.values[i] | getIconOne() }}</v-icon>
                                        <v-icon :color="current_curve_data.values[i] | getIconColorTwo()">{{ current_curve_data.values[i] | getIconTwo() }}</v-icon>
                                        <v-icon :color="current_curve_data.values[i] | getIconColorThree()">{{ current_curve_data.values[i] | getIconThree() }}</v-icon>
                                    </v-row>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                    
                </v-card-text>

                <v-divider />

                <v-card-actions class="my-3">
                    
                    <v-spacer />

                    <v-btn
                        outlined
                        rounded
                        @click="show_curve = false"
                    >
                        Schließen
                    </v-btn>
                </v-card-actions>
                
            </v-card>

        </v-dialog>

        <!-- HelpDIALOG -->
        <v-dialog
            v-model="dialog_help"
            persistent
        >
            <v-card outlined>

                <v-card-text class="title primary--text text-center mt-5">
                    Handlungsempfehlung
                </v-card-text>

                <v-card-text class="black--text text-center">
                    zu Item
                </v-card-text>

                <v-card-text class="subtitle-2 primary--text text-center">
                    "{{ help_item.item }}"
                </v-card-text>

                <v-divider />

                <v-card-text class="mt-5">
                    
                    <AcuteHelp :data="dialog_help_content" :federal_state="$store.getters['store_supervisor/get_supervisor'].federal_state" />
                    
                </v-card-text>

                <v-divider />

                <v-card-actions class="my-3">
                    
                    <v-spacer />

                    <v-btn
                        outlined
                        rounded
                        color="primary"
                        @click="dialog_help = false"
                    >
                        Schließen
                    </v-btn>
                </v-card-actions>
                
            </v-card>

        </v-dialog>

    </v-container>

</template>

<script>
import SupervisorApi from '@/_api/supervisor_api'

import AcuteHelper from '@/_helpers/helper_supervisor'

import AcuteHelp from '@/components/_supervisor/risk_help/dhpol_guides'

export default {
    name: 'supervisor-risk-acute',

    components: {
        AcuteHelp
    },

    data: () => ({

        loaded: false,

        search: '',

        all_data: {},

        loading_dropdown_client: false,

        dropdown_client: [],
        dropdown_client_value: null,
        dropdown_timepoints: [],
        dropdown_timepoints_value: null,
        
        headers: [
          {
            text: 'Item',
            align: 'left',
            sortable: false,
            value: 'item',
            class: "black--text subtitle-1 font-weight-bold"
          },
          { 
              text: 'Rohwert', 
              value: 'raw_value_2',
              align: 'center',
              class: "black--text subtitle-1 font-weight-bold"
          },
          { 
              text: 'Einschätzung', 
              value: 'risk_value',
              align: 'center',
              class: "black--text subtitle-1 font-weight-bold"
          },
          { 
              text: 'Hilfe', 
              value: 'help_btn',
              sortable: false,
              align: 'center',
              class: "black--text subtitle-1 font-weight-bold"
          },
          { 
              text: 'Verlauf', 
              value: 'raw_value',
              sortable: false,
              align: 'center',
              class: "black--text subtitle-1 font-weight-bold"
          },
        ],

        current_data: [],

        current_curve_data: {},
        show_curve: false,
        show_curve_title: '',

        dialog_help: false,
        dialog_help_content: '',
        help_item: ''

    }),

    created() {

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        if (this.$route.params.search_prop == 'null') {
            this.dropdown_client_value = this.clients[0].username
        } else {
            this.dropdown_client_value = this.$route.params.search_prop
        }

        var client = this.clients.find(e => e.username === this.dropdown_client_value);

        SupervisorApi.GetRiskAssessmentAcute(client.client_id).then((resp) => {

            this.all_data = resp.data

            this.dropdown_client = resp.data.clients

            this.dropdown_timepoints = resp.data.acute['timepoints']

            this.dropdown_timepoints_value = this.dropdown_timepoints[0]

            this.current_data = resp.data.acute[this.dropdown_timepoints_value]

            this.loaded = true
            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

        })

        


    },

    computed: {

        clients() {
            return this.$store.getters['store_supervisor/get_supervisor_clients']
        },

    },

    filters: {

        getIconOne(value) {
            if (value == 0) {
                return 'fas fa-circle'
            }
            else if (value == 1) {
                return 'far fa-circle'
            }
            else if (value == 2) {
                return 'far fa-circle'
            }
            else {
                return 'far fa-circle'
            }
        },

        getIconColorOne(value) {
            if (value == 0) {
                return 'green lighten-1'
            }
            else if (value == 1) {
                return ''
            }
            else if (value == 2) {
                return ''
            }
            else {
                return ''
            }
        },

        getIconTwo(value) {
            if (value == 0) {
                return 'far fa-circle'
            }
            else if (value == 1) {
                return 'fas fa-circle'
            }
            else if (value == 2) {
                return 'far fa-circle'
            }
            else {
                return 'far fa-circle'
            }
        },

        getIconColorTwo(value) {
            if (value == 0) {
                return ''
            }
            else if (value == 1) {
                return 'orange lighten-1'
            }
            else if (value == 2) {
                return ''
            }
            else {
                return ''
            }
        },

        getIconThree(value) {
            if (value == 0) {
                return 'far fa-circle'
            }
            else if (value == 1) {
                return 'far fa-circle'
            }
            else if (value == 2) {
                return 'fas fa-circle'
            }
            else {
                return 'far fa-circle'
            }
        },

        getIconColorThree(value) {
            if (value == 0) {
                return ''
            }
            else if (value == 1) {
                return ''
            }
            else if (value == 2) {
                return 'red lighten-1'
            }
            else {
                return ''
            }
        },
    },

    methods: {

        show_help_btn(item) {

            var show = AcuteHelper.get_help_acute(item.item)

            return show.value

        },

        go_to_help(item) {

            this.help_item = item

            var data = AcuteHelper.get_help_acute(item.item)

            this.dialog_help = true
            this.dialog_help_content = data.content

        },

        update_timepoint() {
            this.current_data = this.all_data.acute[this.dropdown_timepoints_value]
        },

        update_client() {

            this.loading_dropdown_client = true

            var client = this.clients.find(e => e.username === this.dropdown_client_value);

            SupervisorApi.GetRiskAssessmentAcute(client.client_id).then((resp) => {

                this.all_data = resp.data

                this.dropdown_client = resp.data.clients

                this.dropdown_timepoints = resp.data.acute['timepoints']

                this.dropdown_timepoints_value = this.dropdown_timepoints[0]

                this.current_data = resp.data.acute[this.dropdown_timepoints_value]

                this.loading_dropdown_client = false
                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

            })

        },

        open_curve(item){

            this.show_curve_title = item
            this.current_curve_data = this.all_data.acute[item]
            this.show_curve = true

        }
    }
}
</script>

<style scoped>

</style>