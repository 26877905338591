import AcuteArray from '@/_helpers/helper_supervisor_acute_array'
import CMCArray from '@/_helpers/helper_supervisor_cmc_array'
import FlashlightPreArray from '@/_helpers/helper_supervisor_flashlight_pre_array'
import FlashlightPostArray from '@/_helpers/helper_supervisor_flashlight_post_array'

function get_help_acute(item) {

    var items = [
        'Haben Sie in den letzten Tagen Alkohol getrunken?',
        'Haben Sie in den letzten Tagen festgestellt, dass Sie mehr Alkohol getrunken haben, als Sie eigentlich wollten?',
        'Hat ihr Alkoholkonsum in den letzten Tagen zu Problemen geführt?',
        'Haben Sie in den letzten Tagen Drogen genommen?',
        'Trinken Sie in der letzten Zeit Alkohol bevor Sie zu Terminen mit den Einrichtungen (Bewährungshilfe, Ambulanz) gehen?',
        'Haben Sie in den letzten Tagen die Kontrolle verloren, wenn Sie Alkohol getrunken haben?',
        'Kommt es in letzter Zeit vor, dass Sie Termine mit Ihrem Therapeuten (m/w/d) verpassen?',
        'Kommt es in letzter Zeit vor, dass Sie zu spät zu Terminen mit Ihrem Therapeuten (m/w/d) kommen?',
        'Haben Sie in den letzten Tagen daran gedacht, sich das Leben zu nehmen?',
        'Haben Sie in den letzten Tagen darüber nachgedacht, wie Sie am besten mit einem Kind in Kontakt kommen können?',
        'Gab es in den letzten Tagen konkrete Pläne, wie sie mit einem Kind in Kontakt kommen können?',
        'Hatten Sie in den letzten Tagen das Gefühl, eine sexuelle Handlung mit einem Kind begehen zu müssen?',
        'Haben Sie in den letzten Tagen Vorbereitungen getroffen, um mit einem Kind in Kontakt kommen zu können?',
        'Kommt es in letzter Zeit vor, dass Sie Termine mit Ihrem Bewährungshelfer (m/w/d) verpassen?'
    ]

    var data = {
        value: false,
        content: ''
    }
    
    if (items.includes(item)) {
        data.value = true
        data.content = AcuteArray.help_object[item]
    }

    return data

}

function get_help_cmc(item) {

    var items = [
        'Ich habe etwas gefälscht (z.B. Unterschrift oder Zeugnis).',
        'Ich habe online Sachen eingekauft und nicht bezahlt.',
        'Ich habe etwas gestohlen.',
        'Ich habe etwas absichtlich beschädigt oder zerstört, das mir nicht gehört.',
        'Ich habe mir unerlaubt Zugang zu einer Wohnung oder einem Haus verschafft.',
        'Ich habe jemanden bedroht.',
        'Ich habe jemanden mit einer Waffe bedroht.',
        'Ich habe jemanden eingesperrt.',
        'Ich habe jemanden geschlagen.',
        'Ich habe jemanden absichtlich so schwer verletzt, dass er/sie zum Arzt musste.',
        'Ich habe jemanden getötet.',
        'Ich habe ein Kind angesprochen.',
        'Ich war an einem Kindergarten oder einer Schule.',
        'Ich war an einem Spielplatz oder im Schwimmbad.',
        'Ich habe ein Kind zu mir nach Hause eingeladen.',
        'Ich habe ein Kind im Internet kontaktiert.',
        'Ich hatte Geschlechtsverkehr mit einem Kind oder habe es versucht.',
        'Ich hatte Oralverkehr mit einem Kind.',
        'Ich habe das Geschlechtsteil eines Kindes angefasst.',
        'Ich habe mich selbst befriedigt, als ein Kind in meiner Nähe war.',
        'Ich habe mir Pornos angeschaut, als Kinder im Raum waren.',
        'Ich habe mir Kinderpornos angesehen.',
        'Ich habe Fotos von Kindern gemacht.',
        'Ich habe Alkohol getrunken.',
        'Ich habe Drogen genommen.',
        'Ich habe gegen meine Weisungen verstoßen.'
    ]

    var data = {
        value: false,
        content: ''
    }
    
    if (items.includes(item)) {
        data.value = true
        data.content = CMCArray.help_object[item]
    }

    return data

}

function get_help_flashlight_pre(item) {


    var items = [
        'Haben Sie in den letzten Tagen darüber nachgedacht, wie Sie am besten mit einem Kind in Kontakt kommen können?',
        'Hatten Sie in den letzten Tagen das Gefühl, eine sexuelle Handlung mit einem Kind begehen zu müssen?',
        'Haben Sie in den letzten Tagen Vorbereitungen getroffen, um mit einem Kind in Kontakt kommen zu können?',
        'Haben Sie in den letzten Tagen daran gedacht, sich das Leben zu nehmen?'
    ]

    var data = {
        value: false,
        content: ''
    }
    

    if (items.includes(item)) {
        data.value = true
        data.content = FlashlightPreArray.help_object[item]
    }

    return data

}

function get_help_flashlight_post(item) {


    var items = [
        'Haben Sie, während Sie an dieser Sitzung gearbeitet haben, darüber nachgedacht, wie Sie am besten mit einem Kind in Kontakt kommen können?',
        'Hatten Sie, während Sie an dieser Sitzung gearbeitet haben, das Gefühl, eine sexuelle Handlung mit einem Kind begehen zu müssen?',
        'Haben Sie, während Sie an dieser Sitzung gearbeitet haben, Vorbereitungen getroffen, um mit einem Kind in Kontakt kommen zu können?',
        "Haben Sie, während Sie an dieser Sitzung gearbeitet haben, daran gedacht, sich das Leben zu nehmen?"
    ]

    var data = {
        value: false,
        content: ''
    }
    

    if (items.includes(item)) {
        data.value = true
        data.content = FlashlightPostArray.help_object[item]
    }

    return data

}

// export
export default { get_help_acute, get_help_cmc, get_help_flashlight_pre, get_help_flashlight_post }