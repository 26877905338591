<template>
    <v-card outlined>

        <v-card-text>

            <div v-if="data.type == 'multipleChoicePickNFormPlus'">
                <multipleChoicePickNFormPlus :id = this.id :taskID = "data.taskID" :taskChoices = "data.wagtail_task_data.value.taskChoices" :question = "data.wagtail_task_data.value.shortQuestion" />
            </div>

            <div v-if="data.type == 'multipleChoicePickNForm'">
                <multipleChoicePickNForm :id = this.id :taskID = "data.taskID" :taskChoices = "data.wagtail_task_data.value.taskChoices" :question = "data.wagtail_task_data.value.shortQuestion" />
            </div>

            <div v-if="data.type == 'multipleChoicePickOneFormPlus'">
                <multipleChoicePickOneFormPlus :id = this.id :taskID = "data.taskID" :taskChoices = "data.wagtail_task_data.value.taskChoices" :question = "data.wagtail_task_data.value.shortQuestion"/>
            </div>

            <div v-if="data.type == 'multipleChoicePickOneForm'">
                <multipleChoicePickOneForm :id = this.id :taskID = "data.taskID" :taskChoices = "data.wagtail_task_data.value.taskChoices" :question = "data.wagtail_task_data.shortQuestion"/>
            </div>

            <div v-if="data.type == 'freeTextForm'">
                <free-text-form :id = "1" :taskID = "data.taskID" :question = "data.wagtail_task_data.value.shortQuestion"/>
            </div>

            <div v-if="data.type == 'rightWrongForm'">
                <right-wrong-form :id = "1" :taskID = "data.taskID" :question = "data.wagtail_task_data.value.question" :correctAnswer = "data.wagtail_task_data.value.correctAnswer"/>
            </div>

            <div v-if="data.type == 'proContraInputTask'">
                <proContraInputTask :id = "1" :taskID = "data.taskID" :minItemsPro = "data.wagtail_task_data.value.minItemsPro" :minItemsContra = "data.wagtail_task_data.value.minItemsContra" :headerPro = "data.wagtail_task_data.value.headerPro" :headerContra = "data.wagtail_task_data.value.headerContra" :question = "data.wagtail_task_data.value.shortQuestion"/>
            </div>

            <div v-if="data.type == 'traditionalLikertScaleForm'">
                <traditional-likert-scale-form :id = "1" :taskID = "data.taskID"  :maxLabel = "data.wagtail_task_data.value.maxLabel"  :minLabel = "data.wagtail_task_data.value.minLabel" :iconSet = "data.wagtail_task_data.value.iconSet" :question = "data.wagtail_task_data.value.shortQuestion" :assignmentList = "data.wagtail_task_data.value.assignmentList"/>
            </div>

            <div v-if="data.type == 'collectKeywordsForm'">
                <collect-keywords-form :id = "1" :taskID = "data.taskID" :question = "data.wagtail_task_data.value.shortQuestion" :minKeyWords = 'data.wagtail_task_data.value.minKeywords'/>
            </div>

            <div v-if="data.type == 'defaultHierarchyAssignmentForm'">
                <default-hierarchy-assignment-form :id = "1" :taskID = "data.taskID" :question = "data.wagtail_task_data.value.shortQuestion" :assignmentList = "data.wagtail_task_data.value.assignmentList" />
            </div>

            <div v-if="data.type == 'fourFieldInputForm'">
                <four-field-input-form :id = "1" :taskID = "data.taskID" :minItemsField1 = "data.wagtail_task_data.value.minItemsField1" :minItemsField2 = "data.wagtail_task_data.value.minItemsField2" :minItemsField3 = "data.wagtail_task_data.value.minItemsField3" :minItemsField4 = "data.wagtail_task_data.value.minItemsField4" :headerColumn1 = "data.wagtail_task_data.value.headerColumn1" :headerColumn2 = "data.wagtail_task_data.value.headerColumn2" :headerRow1 = "data.wagtail_task_data.value.headerRow1" :headerRow2 = "data.wagtail_task_data.value.headerRow2" :question = "data.wagtail_task_data.value.shortQuestion"/>
            </div>

            <div v-if="data.type == 'dataHierarchyAssignmentForm'">
                <data-hierarchy-assignment-form :id = "1" :question = "data.wagtail_task_data.value.shortQuestion" :assignmentList = "data.wagtail_task_data.value.assignmentList" />
            </div>

        </v-card-text>
        
    </v-card>
</template>

<script>
import multipleChoicePickNFormPlus from '@/components/_guided_task/guided_task_INPUT_FORMS/multipleChoicePickNFormPlus_rev.vue'
import multipleChoicePickNForm from '@/components/_guided_task/guided_task_INPUT_FORMS/multipleChoicePickNForm_rev.vue'
import multipleChoicePickOneFormPlus from '@/components/_guided_task/guided_task_INPUT_FORMS/multipleChoicePickOneFormPlus_rev.vue'
import multipleChoicePickOneForm from '@/components/_guided_task/guided_task_INPUT_FORMS/multipleChoicePickOneForm_rev.vue'
import freeTextForm from '@/components/_guided_task/guided_task_INPUT_FORMS/freeTextForm_rev.vue'
import rightWrongForm from '@/components/_guided_task/guided_task_INPUT_FORMS/rightWrongForm_rev.vue'
import traditionalLikertScaleForm from '@/components/_guided_task/guided_task_INPUT_FORMS/traditionalLikertScaleForm_rev.vue'
import proContraInputTask from '@/components/_guided_task/guided_task_INPUT_FORMS/proContraInputTask_rev.vue'
import collectKeywordsForm from '@/components/_guided_task/guided_task_INPUT_FORMS/collectKeywordsForm_rev.vue'
import defaultHierarchyAssignmentForm from '@/components/_guided_task/guided_task_INPUT_FORMS/defaultHierarchyAssignmentForm_rev.vue'
import fourFieldInputForm from '@/components/_guided_task/guided_task_INPUT_FORMS/fourFieldInputForm_rev.vue'
import dataHierarchyAssignmentForm from '@/components/_guided_task/guided_task_INPUT_FORMS/dataHierarchyAssignmentForm_rev.vue'

export default {

    name: 'guided-task-input',

    props: ['data'],

    components: {

        multipleChoicePickNFormPlus,
        multipleChoicePickNForm,
        multipleChoicePickOneFormPlus,
        multipleChoicePickOneForm,
        freeTextForm,
        rightWrongForm,
        traditionalLikertScaleForm,
        proContraInputTask,
        collectKeywordsForm,
        defaultHierarchyAssignmentForm,
        fourFieldInputForm,
        dataHierarchyAssignmentForm,      
      
    },

    data: () => ({

    })
}
</script>