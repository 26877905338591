import { render, staticRenderFns } from "./dataHierarchyAssignmentForm_rev.vue?vue&type=template&id=307d8aea&scoped=true&"
import script from "./dataHierarchyAssignmentForm_rev.vue?vue&type=script&lang=js&"
export * from "./dataHierarchyAssignmentForm_rev.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "307d8aea",
  null
  
)

export default component.exports