<template>

  <div class="mx-3">

    <v-row no-gutters>

        <v-col 
          :cols="responsiveColumn" 
          v-for="(label, i) in data.labels" 
          :key="label.name"
        >

          <v-checkbox
              v-model="data.checkbox[i]"   
              :label="label"
              data-vv-name="checkbox"
              type="checkbox"
              disabled
              class="mt-0 pl-0 mb-2"
              color="accent"
              hide-details
          ></v-checkbox>

        </v-col>

    </v-row>


  </div>

</template>

<script>
export default {
    name: 'rightWrongTaskData',

    props: ['data'],

    computed: {

      responsiveColumn() {

          if (this.data.checkbox.length <= 5) {

            return '12'

          } else {

              if (this.$vuetify.breakpoint.name == 'xs' || this.$vuetify.breakpoint.name == 'sm') {

                return '12'

              } else {

                return '6'

              }

            }
        
        },

    }

}   
</script>

<style scoped>

</style>