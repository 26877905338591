<template>

  <div>

    <v-row class=" mb-3 mx-1">
      <div class="overline"><b>Tipp:</b> Sie müssen mindestens vier Zeichen eingeben.</div>
    </v-row>

    <v-textarea
        v-model="textInput"
        label="Meine Antwort"
        :placeholder="question"
        :disabled="thisBtnDisabled"
        color="primary"
        auto-grow
        outlined
        hide-details
    ></v-textarea>

  </div>

</template>

<script>

export default {
    name: 'freeTextForm-rev',
    props: ['taskID', 'question', 'id'],

    data: () => ({

      textInput: null,
      
      thisBtnDisabled: false,


    }),

    created() {

      this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'type', 'val': 'freeTextForm'})
      this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'question', 'val': this.question})
      this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'textInput', 'val': this.textInput})
      this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'success', 'val': false})
      
    },

    computed: {
      

    },

    watch: {

      // watch the checkbox-state and update the store immediatly on change
      textInput: function(val) {

        this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'textInput', 'val': val})

        if (val.length > 3) {

          this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'success', 'val': true})

        } else {

          this.$store.dispatch('store_guided_task/UPDATE_CURRENT_GUIDED_TASK_BY_KEY', {'key': 'success', 'val': false})

        }
        

      }

    },

    methods: {
     
    }
}   
</script>

<style scoped>
.checkbox-test >>> label {
  color: black !important
}
</style>