<template>
        <v-container fluid>

        <v-row v-if="$store.getters['store_client/get_client'].client_group == 1">

            <v-col cols="12" sm="12" v-if="!show_m6_s1_l5_t1">

                <v-card outlined>

                    <v-card-text class="primary--text title">
                        Risiko-Situationen meiden
                    </v-card-text>

                    <v-card-text class="body-1">
                        Sie können Ihre sexuellen Gedanken und Handlungen womöglich besser kontrollieren, wenn Sie diese Risiko-Situationen vermeiden. 
                        Denn diese fördern bei Ihnen sexuelle Gedanken und Handlungen.
                    </v-card-text>

                    <v-card-text>
                        <v-list>
                            <v-list-item
                                v-for="item in emergency_data.m6_s1_l5_t1.keywordList"
                                :key="item.name"
                            >
                                <v-list-item-icon>
                                    <v-icon color="accent">far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content class="font-weight-bold body-1">{{ item }}</v-list-item-content>
                            </v-list-item>

                        </v-list>
                    </v-card-text>

                </v-card>

            </v-col>

            <v-col cols="12" sm="12" v-if="!show_m6_s1_l5_t2">

                <v-card outlined>

                    <v-card-text class="primary--text title">
                        Sexuelle Gedanken und Handlungen besser kontrollieren
                    </v-card-text>

                    <v-card-text class="body-1">
                        Sie haben sich in Sitzung 1 im Modul 6 für diesen Weg entschieden, um Ihre sexuellen Gedanken und Handlungen besser zu kontrollieren. 
                        Sie können diesen Weg nun anwenden, um wieder Herr über Ihre sexuellen Gedanken und Handlungen zu werden.
                    </v-card-text>

                    <v-card-text>
                        <v-list>
                            <v-list-item
                                v-for="item in m6_s1_l5_t2_items"
                                :key="item.name"
                            >
                                <v-list-item-icon>
                                    <v-icon color="accent">far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content class="font-weight-bold body-1">{{ item }}</v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>

                </v-card>

            </v-col>

            <v-col cols="12" sm="12" v-if="!show_m6_s2_l4_t1">

                <v-card outlined>

                    <v-card-text class="primary--text title">
                        Emotionaler und räumlicher Abstand zu Kindern
                    </v-card-text>

                    <v-card-text class="body-1">
                        Wenn Sie sexuelle Fantasien über Kinder haben, die Sie nur schwer kontrollieren können und/oder kurz davor stehen eine erneute sexuelle 
                        Straftat zu begehen, dann kann es helfen, mehr Abstand zu Kindern zu gewinnen. Sie haben sich in Sitzung 2 im Modul 6 dazu entschieden, 
                        Kinder über die unten stehenden Wege emotional und räumlich auf Abstand zu halten. Verfolgen Sie diese Wege weiter!
                    </v-card-text>

                    <v-card-text>
                        <v-list>
                            <v-list-item
                                v-for="item in m6_s2_l4_t1_items"
                                :key="item.name"
                            >
                                <v-list-item-icon>
                                    <v-icon color="accent">far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content class="font-weight-bold body-1">{{ item }}</v-list-item-content>
                            </v-list-item>

                        </v-list>
                    </v-card-text>

                </v-card>

            </v-col>

            <v-col cols="12" sm="12" v-if="!show_m6_s3_l4_t2">

                <v-card outlined>

                    <v-card-text class="primary--text title">
                        Sexuelle Fantasien über Kinder und/oder das Risiko einer erneuten sexuellen Straftat besser kontrollieren
                    </v-card-text>

                    <v-card-text class="body-1">
                        Sie haben sich in Sitzung 4 im Modul 6 für diese Wege entschieden, um Ihre sexuellen Fantasien über Kinder und/oder das Risiko 
                        einer erneuten sexuellen Straftat besser zu kontrollieren. Wenden Sie einen dieser Wege doch sofort an, wenn Sie das Gefühl haben, 
                        dass Sie die Kontrolle verlieren! 
                    </v-card-text>

                    <v-card-text>
                        <v-list>
                            <v-list-item
                                v-for="item in m6_s3_l4_t2_items"
                                :key="item.name"
                            >
                                <v-list-item-icon>
                                    <v-icon color="accent">far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content class="font-weight-bold body-1">{{ item }}</v-list-item-content>
                            </v-list-item>

                        </v-list>
                    </v-card-text>

                </v-card>

            </v-col>

            <v-col cols="12" sm="12" v-if="!show_m6_s4_l5_t1">

                <v-card outlined>

                    <v-card-text class="primary--text title">
                        Meine unproblematische sexuelle Fantasie
                    </v-card-text>

                    <v-card-text class="body-1">
                        In Sitzung 4 des Moduls 6 haben Sie sich diese unproblematische sexuelle Fantasie ausgedacht. Wenn Sie sich selber befriedigen möchten, 
                        können Sie diese Fantasie nutzen. Wenn sich sexuelle Fantasien über Kinder während der Selbstbefriedigung aufdrängen und/oder Sie kurz 
                        davor stehen, eine erneute sexuelle Straftat zu begehen, lenken Sie Ihre Aufmerksamkeit auf diese unproblematische sexuelle Fantasie.  
                    </v-card-text>

                    <v-card-text>
                        <blockquote class="blockquote black--text note">
                            {{ emergency_data.m6_s4_l5_t1.textInput }}
                        </blockquote>
                    </v-card-text>

                </v-card>

            </v-col>

        </v-row>

        <v-row v-else>

            <v-col cols="12" sm="12">

                <v-card
                    outlined
                    
                >

                    <v-card-text class="primary--text title">
                        Tipps um meine sexuellen Fantasien (über Kinder) besser zu kontrollieren
                    </v-card-text>

                    <v-card-text class="body-1">
                        Ihr Risiko für erneute Straftaten kann steigen, wenn Sie die Kontrolle über Ihre sexuellen Gedanken und Handlungen verlieren. Ganz 
                        besonders gefährlich wird es natürlich, wenn Sie die Kontrolle über Ihre sexuellen Fantasien über Kinder verlieren. Ich habe Ihnen 
                        hier Wege, die anderen Menschen helfen, sexuelle Gedanken und Fantasien besser zu kontrollieren, aufgeschrieben. Manche Menschen 
                        gewinnen die Kontrolle über ihre sexuellen Fantasien wieder, indem sie…
                    </v-card-text>

                    <v-card-text>
                        <v-list>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="accent">far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content class="font-weight-bold body-1">sich ablenken (z. B. eiskalt duschen).</v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="accent">far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content class="font-weight-bold body-1">Risikosituationen möglichst vermeiden (z. B. zu viel im Internet surfen).</v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="accent">far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content class="font-weight-bold body-1">sich mit etwas belohnen, dass ihnen ebenfalls Spaß macht (z. B. eine*n Freund*in treffen).</v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="accent">far fa-dot-circle</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content class="font-weight-bold body-1">die Herausforderung annehmen und das Verlangen wie eine Welle kommen und gehen lassen.</v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>

                </v-card>

            </v-col>

        </v-row>

    </v-container>
</template>

<script>
//import ClientApi from '@/_api/client_api'
import _ from 'lodash'

export default {
    name: 'client-emergency-accent-control',

    data: () => ({
        //
    }),

    created() {


    },

    computed: {

        emergency_data() {

            return this.$store.getters['store_client/get_client_emergency_data']

        },

        m6_s1_l5_t2_items() {

            var out = []

            for (var i = 0; i < this.emergency_data.m6_s1_l5_t2.checkbox.length; i++) {

                if (this.emergency_data.m6_s1_l5_t2.checkbox[i] == true) {

                    out.push(this.emergency_data.m6_s1_l5_t2.labels[i])

                }
            }

            return out

        },

        m6_s3_l4_t2_items() {

            var out = []

            for (var i = 0; i < this.emergency_data.m6_s3_l4_t2.checkbox.length; i++) {

                if (this.emergency_data.m6_s3_l4_t2.checkbox[i] == true) {

                    out.push(this.emergency_data.m6_s3_l4_t2.labels[i])

                }
            }

            return out

        },

        m6_s2_l4_t1_items() {

            var out = []

            for (var i = 0; i < this.emergency_data.m6_s2_l4_t1.checkbox.length; i++) {

                if (this.emergency_data.m6_s2_l4_t1.checkbox[i] == true) {

                    out.push(this.emergency_data.m6_s2_l4_t1.labels[i])

                }
            }

            return out

        },

        show_m6_s1_l5_t1() {
            return _.isEmpty(this.emergency_data.m6_s1_l5_t1)
        },

        show_m6_s1_l5_t2() {
            return _.isEmpty(this.emergency_data.m6_s1_l5_t2)
        },

        show_m6_s2_l4_t1() {
            return _.isEmpty(this.emergency_data.m6_s2_l4_t1)
        },

        show_m6_s3_l4_t2() {
            return _.isEmpty(this.emergency_data.m6_s3_l4_t2)
        },

        show_m6_s4_l5_t1() {
            return _.isEmpty(this.emergency_data.m6_s4_l5_t1)
        }

    }

}
</script>

<style scoped>
.note {
  white-space: pre-line; 
  word-wrap: break-word;
  font-family: inherit;
  text-align: left !important;
}
</style>