<template>

    <v-container fluid class="py-0" v-if="post">

        <div v-if="content_exist">

            <!-- Title -->
            <v-row class="mb-5 pa-0">
                <h1 class="display-2 font-weight-bold primary--text">{{ content.title }}</h1>
            </v-row>

            <!-- Sub-Title -->
            <v-row class="mb-3 pt-0">
                <h1 class="display-1">{{ content.subTitle }}</h1>
            </v-row>

            <!-- timeline-like optic for lection -->
            <div :id="!$vuetify.breakpoint.mdAndDown ? 'timeline' : 'timeline-mobile'" :style="cssVars" v-if="post">

                <!-- main for-loop for a blocks in the lection -->
                <div 
                    v-for="(block, i) in content.body" 
                    :key="block.name"
                >

                    <!-- ************* -->
                    <!-- BASIC-INFO  OK -->
                    <!-- ************* -->
                    <div v-if="block.type == 'basicInfo'">
                        <necessary-info :data="block.value" :id="i" />
                    </div>
                    

                    <!-- ************* -->
                    <!-- OPTIONAL-INFO OK -->
                    <!-- ************* -->
                    <div v-if="block.type == 'optionalInfo'">
                        <optional-info :data="block.value" :id="i"/>
                    </div>

                </div>
            </div>

            <!-- LAST ITEM -->
            <div :id="!$vuetify.breakpoint.mdAndDown ? 'timeline-last' : 'timeline-last-mobile'" v-if="post">

                <v-row>

                    <v-col class="justify-content-center" >

                        <div class="timeline-last-item">

                            <v-row >

                                <v-avatar v-if="!$vuetify.breakpoint.mdAndDown" color="primary" size="50" class="elevation-5" style="margin-left: -12px;">
                                    <div v-if="!thisLessionFinished">
                                    <v-icon medium color="white">fas fa-ban</v-icon>
                                    </div>
                                    <div v-else>
                                    <v-icon medium color="white">fas fa-check</v-icon>
                                    </div>
                                </v-avatar>

                                <!-- Navigation -->

                                <v-btn 
                                    @click="goBack()"
                                    outlined
                                    rounded
                                    large
                                    color="secondary" 
                                    :class="!$vuetify.breakpoint.mdAndDown ? 'headline ml-4 elevation-5' : 'headline elevation-5'" 
                                    style="text-transform: none !important"
                                    :disabled="parseInt(this.$route.params.rank) == 1"
                                >
                                    Zurück
                                </v-btn>

                                <v-spacer />

                                <v-btn 
                                    @click="goForward()"
                                    outlined
                                    rounded
                                    large
                                    color="primary" 
                                    class="headline ml-2 elevation-5" 
                                    style="text-transform: none !important"
                                    :disabled="!thisLessionFinished"
                                    :loading="loading_btn"
                                >
                                    Weiter
                                </v-btn>

                            </v-row>

                        </div>

                    </v-col>

                </v-row>

            </div>

        </div>

        <div v-if="!content_exist">
            
            <v-alert
                border="left"
                elevation="1"
                colored-border
                prominent
                type="error"
            >
                <v-row align="center">

                    <v-col cols="auto" class="mr-auto">

                        <div>
                            <small>Fehler</small>
                        </div>
                        
                        <div class="black--text mt-3">
                            Diese Einführungsseite existiert leider nicht.
                        </div>


                    </v-col>

                    <v-col cols="auto">
                        <v-btn
                            outlined
                            color="error"
                            @click="goToCurrentLection"
                        >
                            Einführungsseite
                        </v-btn>
                    </v-col>

                </v-row>

            </v-alert>
        </div>

        <!-- DEV -->
        <v-dialog v-model="dialog" persistent max-width="320">

            <v-card>
                <v-card-title class="headline">Fragebögen müssen ausgefüllt werden.</v-card-title>
                <v-card-text>
                    Da dieser Klient als Development-Klient definiert ist, haben sie die Wahl, 
                    ob sie die Fragebögen ausfüllen möchten, oder nicht.
                </v-card-text>
                <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="primary" text @click="dialog = false">Nicht ausfüllen</v-btn>
                <v-btn color="primary" text @click="goToAssessment">Ausfüllen</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

        <v-dialog v-model="dialog_dev" persistent max-width="320">

            <v-card>
                <v-card-title class="headline">Fragebögen müssen ausgefüllt werden.</v-card-title>
                <v-card-text>
                    Da dieser Klient als Development-Klient definiert ist, haben sie die Wahl, 
                    ob sie die Fragebögen ausfüllen möchten, oder nicht.
                </v-card-text>
                <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="primary" text @click="dev_next">Nicht ausfüllen</v-btn>
                <v-btn color="primary" text @click="goToAssessment">Ausfüllen</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

        <v-dialog v-model="dialog_dev_next" persistent max-width="450">

            <v-card>
                <v-card-title class="headline">Bitte nicht speichern während der Entwicklung.</v-card-title>
                <v-card-text>
                    Dieser Klient ist als Development-Klient definiert. Solange sie an dieser Lektion die Inhalte bearbeiten, sollten sie nicht auf
                    Weiter klicken, da dann die Daten in der Datenbank gespeichert werden. Dies kann bei späteren Änderungen in Wagtail zu einem 
                    unvorhergesehenen Verhalten der Anwendung führen. In diesem Falle müssten sie dann den Klienten vollständig zurücksetzen.
                </v-card-text>
                <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="primary" text @click="doNotSave">Nicht speichern</v-btn>
                <v-btn color="primary" text @click="saveDev">Speichern und weiter</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

    </v-container>

    
</template>

<script>
import ClientApi from '@/_api/client_api'
import router from '@/router'
import store from '@/store/store'
import axios from 'axios'

import optionalInfo from '@/components/_client/_introduction/introduction_optional_info.vue'
import necessaryInfo from '@/components/_client/_introduction/introduction_necessary_info.vue'


export default {
    name: 'client-introduction',

    data: () => ({
        
        post: false,

        dialog: false,

        dialog_dev: false,

        dialog_dev_next: false,
        dev_go_on: false,
        dev_checked: false,

        timepoint_for_assessment: 1,

        loading_btn: false,

        already_finished: null,

        content_exist: false,

    }),

    components: {
        necessaryInfo,
        optionalInfo,
    },

    beforeRouteEnter(to, from, next){

        ClientApi.GetClientInfo().then(() => {

            if (store.getters['store_client/get_page_allowed'] || store.getters['store_client/get_client'].is_dev) {

                console.log('allowed to enter page (beforeRouteEnter)')

                // if it is the first page in introduction
                if (parseInt(to.params.rank) == 1) {


                    console.debug('CHECK QUESTIONNAIRES INTRO')

                    //all params 0
                    var endpoint = 'api-wagtail/get-assessment/?module_nr=0&session_nr=0&lection_nr=0&timepoint=1'

                    axios.get(endpoint)

                    .then(resp => {

                        var show = false

                        resp.data.questionnaires.forEach(element => {

                            if (element.already_saved == false) {
                                show = true
                            }
                            
                        });

                        if (show == true) {

                            console.debug('THERE ARE QUESTIONNAIRES TO SHOW (INTRO)')

                            if (store.getters['store_client/get_client'].is_dev) {

                                next(vm => { 

                                    vm.timepoint_for_assessment = 1

                                    vm.dialog = true

                                })

                            } else {

                                // all params 0

                                // set page allowed to true
                                store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)
                    
                                router.push({ name: 'client-assessment', params: { module: 0, session: 0, lection: 0, timepoint: 1 , step: 0} })

                            }

                        } else {

                            console.debug('THERE ARE NO QUESTIONNAIRES (INTRO)')

                            next()

                        }

                    })

                    .catch(err => {

                        console.log(err)
                    
                    })
        
                } else {

                    next()


                }

            } else {

                console.log('not_allowed (beforeRouteEnter)')

                next(vm => { 

                    vm.$router.push({ name: 'client-not-allowed'})
                    
                })

            }

        })
        
    },

    beforeRouteUpdate(to, from, next){

        ClientApi.GetClientInfo().then(() => {

            if (this.$store.getters['store_client/get_page_allowed'] || this.$store.getters['store_client/get_client'].is_dev) {

                console.log('allowed to enter page (beforeRouteUpdate)')

                // if it is the first page in introduction
                if (parseInt(to.params.rank) == 1) {


                    console.debug('CHECK QUESTIONNAIRES INTRO')

                    //all params 0
                    var endpoint = 'api-wagtail/get-assessment/?module_nr=0&session_nr=0&lection_nr=0&timepoint=1'

                    axios.get(endpoint)

                    .then(resp => {

                        var show = false

                        resp.data.questionnaires.forEach(element => {

                            if (element.already_saved == false) {
                                show = true
                            }
                            
                        });

                        if (show == true) {

                            console.debug('THERE ARE QUESTIONNAIRES TO SHOW (INTRO)')

                            if (store.getters['store_client/get_client'].is_dev) {

                                next(vm => { 

                                    vm.timepoint_for_assessment = 1

                                    vm.dialog = true

                                })

                            } else {

                                // set page allowed to true
                                this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

                                // all params 0
                                router.push({ name: 'client-assessment', params: { module: 0, session: 0, lection: 0, timepoint: 1 , step: 0} })

                            }

                        } else {

                            console.debug('THERE ARE NO QUESTIONNAIRES (INTRO)')

                            next()

                        }

                    })

                    .catch(err => {

                        console.log(err)
                    
                    })
        
                } else {

                    next()


                }

            } else {

                console.log('not_allowed (beforeRouteEnter)')

                next(vm => { 

                    vm.$router.push({ name: 'client-not-allowed'})
                    
                })

            }

        })
        
    },

    created() {

        if (this.$store.getters['store_client/get_page_allowed'] || this.$store.getters['store_client/get_client'].is_dev) {

            console.log('allowed to enter page (created)')

            // set page allowed to false
            this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', false)

            this.loading_btn = false

            const data = {

                'module_nr': 0,
                'session_nr': 0,
                'lesson_rank': parseInt(this.$route.params.rank)

            }

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

            this.post = false

            // clear relevant stores
            this.$store.dispatch('store_lection/RESET_STATE')
            this.$store.dispatch('store_task/RESET_STATE')
            this.$store.dispatch('store_quiz/RESET_STATE')

            // get content of lection
            ClientApi.GetIntroductionPages(data).then(() => {

                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

                if (this.$store.getters['store_lection/get_lection_wagtail_content'].length == 0) {

                    this.content_exist = false

                    this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                    this.post = true

                } else {

                    this.content_exist = true

                    ClientApi.GetSavedLectionData(data).then(() => {

                        ClientApi.CheckIfDetailedProgressExists(data).then((resp) => {

                            if (resp.data.length == 0) {

                                this.already_finished = null

                                if (this.$store.getters['store_client/get_client'].is_dev) {

                                    this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                                    this.post = true

                                } else {

                                    var new_detail_data = {

                                        "module_nr": 0,
                                        "session_nr": 0,
                                        "lession_nr": parseInt(this.$route.params.rank),

                                    }

                                    ClientApi.CreateNewDetailedProgress(new_detail_data).then(() => {

                                        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                                        this.post = true

                                    })

                                }

                            } else {

                                this.already_finished = resp.data[0].lession_finished

                                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                                this.post = true

                            }

                        })

                    })

                }

            })

        } else {
           
           console.log('not_allowed (created)')

            this.$router.push({ name: 'client-not-allowed'})
                
        }

    },

    watch: {

        '$route' () {

            this.loading_btn = false

            const data = {

                'module_nr': 0,
                'session_nr': 0,
                'lesson_rank': parseInt(this.$route.params.rank)

            }

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

            this.post = false

            // clear relevant stores
            this.$store.dispatch('store_lection/RESET_STATE')
            this.$store.dispatch('store_task/RESET_STATE')
            this.$store.dispatch('store_quiz/RESET_STATE')

            // get content of lection
            ClientApi.GetIntroductionPages(data).then(() => {

                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

                if (this.$store.getters['store_lection/get_lection_wagtail_content'].length == 0) {

                    this.content_exist = false

                    this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                    this.post = true

                } else {

                    this.content_exist = true

                    ClientApi.GetSavedLectionData(data).then(() => {

                        ClientApi.CheckIfDetailedProgressExists(data).then((resp) => {

                            if (resp.data.length == 0) {

                                this.already_finished = null

                                if (this.$store.getters['store_client/get_client'].is_dev) {

                                    this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                                    this.post = true

                                } else {

                                    var new_detail_data = {

                                        "module_nr": 0,
                                        "session_nr": 0,
                                        "lession_nr": parseInt(this.$route.params.rank),

                                    }

                                    ClientApi.CreateNewDetailedProgress(new_detail_data).then(() => {

                                        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                                        this.post = true

                                    })

                                }
                                

                            } else {

                                this.already_finished = resp.data[0].lession_finished

                                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                                this.post = true

                            }

                        })

                    })

                }

            })

        },

    },

    methods: {

        goToCurrentLection() {

            // set page allowed to true
            this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

            if (this.$store.getters['store_client/get_client_progress'].introduction_finished) {
                if (this.$store.getters['store_client/get_client_progress'].intervention_started) {
                    this.$router.push({ name: 'client-intervention', params: { module: this.$store.getters['store_client/get_client_progress'].current_module_nr, session: this.$store.getters['store_client/get_client_progress'].current_session_nr, lection: this.$store.getters['store_client/get_client_progress'].current_lession_nr } })
                } else {
                    this.$router.push({ name: 'client-intervention', params: { module: 1, session: 1, lection: 1 } })
                }
            } else {
                this.$router.push({ name: 'client-introduction', params: { rank: 1 } })
            }
        },

        goToAssessment() {

            // set page allowed to true
            this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)


            router.push({ name: 'client-assessment', params: { module: 0, session: 0, lection: 0, timepoint: this.timepoint_for_assessment, step: 0 } })

        },

        // DEV ---

        dev_next() {

            this.dialog_dev = false

            // go to overview
            this.$router.push({ name: 'client-overview'})

        },

        doNotSave() {

            this.dialog_dev_next = false
            this.dev_go_on = false
            this.dev_checked = true

        },

        saveDev() {

            this.dev_checked = true
            this.dialog_dev_next = false
            this.dev_go_on = true

            this.goForward()

        },

        // DEV END ---

        goBack() {

            // set page allowed to true
            this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

            this.$router.push('/client/introduction/' + (parseInt(this.$route.params.rank) - 1))

        },

        goForward() {

            this.loading_btn = true

            // if the lection is not already saved
            if (this.already_finished == null) {

                // client is dev and dialog not shown yet -> SHOW DIALOG
                if (this.$store.getters['store_client/get_client'].is_dev && this.dev_checked == false) {

                    this.dialog_dev_next = true

                // client is dev and dialog already shown
                } else if (this.$store.getters['store_client/get_client'].is_dev && this.dev_checked == true) {

                    if (this.dev_go_on == true) {

                        this.save_and_next(true)

                    //
                    // DO NOT SAVE AS DEV -> go forward without saving
                    //
                    } else {

                        this.next_without_saving()

                    }

                //
                // CLIENT IS NOT DEV
                //
                } else {

                    this.save_and_next(false)

                }

            //
            // Already saved
            //
            } else {

                this.next_without_saving()
            }

        },

        next_without_saving() {

            // !!!! 2 Lektionen in der Einführung !!!!
            if (this.$route.params.rank == 2) {

                // go to overview
                this.$router.push('/client')

            } else {

                // set page allowed to true
                this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

                this.$router.push('/client/introduction/' + (parseInt(this.$route.params.rank) + 1))

            }

        },

        save_and_next() {

            if (this.$route.params.rank == 2) {

                if (!this.$store.getters['store_client/get_client_progress'].introduction_finished) {

                    //save progress -> introduction_finished

                    const progress_data = {
                        'module_nr': 1,
                        'session_nr': 1,
                        'lesson_rank': 1,
                        "intervention_started": this.$store.getters['store_client/get_client_progress'].intervention_started,
                        "intervention_finished": this.$store.getters['store_client/get_client_progress'].intervention_finished,
                        "introduction_finished": true
                    }
        
                    ClientApi.SaveInterventionProgress(progress_data).then(() => {


                        // save first award
                        ClientApi.UpdateAwardsGetted({award_nr: 1}).then(() => {

                            //this.$store.dispatch('store_dialogs/UPDATE_SHOW_AWARD_DIALOG', { show: true, image: 'HerosuitTN.png'})

                            if (!this.$store.getters['store_client/get_client'].is_dev) {

                                var new_detail_data = {

                                    "module_nr": 0,
                                    "session_nr": 0,
                                    "lession_nr": parseInt(this.$route.params.rank),

                                }

                                ClientApi.PatchDetailedProgress(new_detail_data).then(() => {

                                    // check if post-questionnaires have to be filled in

                                    //timepoint 1 == pre; timepoint 2 == post
                                    var endpoint = 'api-wagtail/get-assessment/?module_nr=0&session_nr=0&lection_nr=0&timepoint=2'

                                    axios.get(endpoint)

                                    .then(resp => {

                                        if (resp.data.questionnaires.length != 0) {

                                            if (this.$store.getters['store_client/get_client'].is_dev) {

                                                    this.timepoint_for_assessment = 2

                                                    this.dialog_dev = true

                                            } else {

                                                // set page allowed to true
                                                this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

                                                this.$router.push({ name: 'client-assessment', params: { module: 0, session: 0, lection: 0, timepoint: 2, step: 0 } })

                                            }

                                        } else {

                                            // go to overview
                                            this.$router.push('/client')

                                        }

                                    })

                                })

                            } else {

                                new_detail_data = {

                                    "module_nr": 0,
                                    "session_nr": 0,
                                    "lession_nr": parseInt(this.$route.params.rank),

                                }

                                ClientApi.PatchDetailedProgress(new_detail_data).then(() => {

                                    // check if post-questionnaires have to be filled in

                                    //timepoint 1 == pre; timepoint 2 == post
                                    var endpoint = 'api-wagtail/get-assessment/?module_nr=0&session_nr=0&lection_nr=0&timepoint=2'

                                    axios.get(endpoint)

                                    .then(resp => {

                                        if (resp.data.questionnaires.length != 0) {

                                            if (this.$store.getters['store_client/get_client'].is_dev) {

                                                    this.timepoint_for_assessment = 2

                                                    this.dialog_dev = true

                                            } else {

                                                // set page allowed to true
                                                this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

                                                this.$router.push({ name: 'client-assessment', params: { module: 0, session: 0, lection: 0, timepoint: 2, step: 0 } })

                                            }

                                        } else {

                                            // go to overview
                                            this.$router.push('/client')

                                        }

                                    })

                                })

                            }

                        })

                    })

                } else {

                    var new_detail_data = {

                        "module_nr": 0,
                        "session_nr": 0,
                        "lession_nr": parseInt(this.$route.params.rank),

                    }

                    if (!this.$store.getters['store_client/get_client'].is_dev) {

                        ClientApi.PatchDetailedProgress(new_detail_data).then(() => {

                            this.$router.push('/client')

                        })

                    } else {

                        this.$router.push('/client')

                    }

                }

            } else {

                new_detail_data = {

                    "module_nr": 0,
                    "session_nr": 0,
                    "lession_nr": parseInt(this.$route.params.rank),

                }

                if (!this.$store.getters['store_client/get_client'].is_dev) {

                    ClientApi.PatchDetailedProgress(new_detail_data).then(() => {

                        // set page allowed to true
                        this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

                        this.$router.push('/client/introduction/' + (parseInt(this.$route.params.rank) + 1))

                    })

                } else {

                    // set page allowed to true
                    this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

                    this.$router.push('/client/introduction/' + (parseInt(this.$route.params.rank) + 1))
                }

            }

        }
    },

    computed: {

        cssVars() {

            if (this.content.body.length >= 3) {

                return {
                    '--timeline-before-height': '99%'
                }
                   
            } else {

                return {
                    '--timeline-before-height': '96%'
                }

            }
            
        },

        loading() {
            return this.$store.getters['store_loader/get_show_main_loader']
        },

        client_is_dev() {
            return this.$store.getters['store_client/get_client'].is_dev
        },

        content() {
            return this.$store.getters['store_lection/get_lection_wagtail_content']
        },

        savedLectionData() {
            return this.$store.getters['store_lection/get_saved_lection_data']
        },

        thisLessionFinished() {
            return this.$store.getters['store_lection/get_saved_lection_data'].lession_finished
        },

    }
}
</script>

<style scoped>
.timeline-title {
  border-style: solid;
  border-width: 1px;
  border-radius:15px !important;
  border-color: #004b8c !important;
  padding: 10px;
  width: 100%;
  margin-left: 68px;

}

#cardbadge { 
  float: left;
  left: -25px;
  top: -25px; 
}

#timeline {
  position: relative;
  padding: 0 10px;
}

#timeline-mobile {
  position: relative;
  padding: 0 10px;
}

#timeline-last {
  width: 100%;
  position: relative;
  padding: 0 10px;
  margin-top: 20px;
}

#timeline-last-mobile {
  width: 100%;
  position: relative;
  padding: 0 10px;
  margin-top: 20px;
}

/* line between icons */
#timeline:before {
  content: "";
  width: 2px;
  height: var(--timeline-before-height);
  background: #616161;
  /* background: #1976D2; */
  left: 10px;
  top: 90px;
  position: absolute;
  
}

#timeline:after {
  content: "";
  clear: both;
  display: table;
  width: 0%;
}

#timeline-last .timeline-last-item {
  margin-bottom: 10px;
  position: relative;
}

#timeline-last .timeline-last-item .timeline-last-content {
  width: 100%;
  margin-left: 68px;
}

#timeline-last-mobile .timeline-last-item {
  margin-bottom: 10px;
  position: relative;
}

#timeline-last-mobile .timeline-last-item .timeline-last-content {
  width: 100%;
}

#timeline-last:before {
  content: "";
  width: 2px;
  height: 100%;
  background: #616161;
  /* background: #1976D2; */
  left: 10px;
  bottom: 50px;
  position: absolute;
}
</style>


