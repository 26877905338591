<template>
    <v-container fluid v-if="loaded">

        <v-row v-if="is_supervisor_home && clients.length < 1">

            <v-col cols="12">

                <v-alert 
                    border="left"
                    colored-border
                    prominent
                    type="success"
                >

                    <v-row align="center">
                        <v-col class="grow">Sie haben noch keine Klienten, die an der Studie teilnehmen.</v-col>
                    </v-row>

                </v-alert>

            </v-col>

        </v-row>

        <v-row v-if="is_supervisor_home">

            <v-col
                cols="12"
                sm="12"
                md="6"
                lg="4"
                v-for="client in clients"
                :key="client.client_id"
            >
                 <v-card 
                    class="mx-auto"
                    color="grey lighten-5"
                    
                >

                    <v-toolbar flat color="grey lighten-5">

                        <v-toolbar-title>

                            <div class="title font-weight-light primary--text" v-if="client.is_active">{{ client.username }}</div>
                            <div class="title font-weight-light grey--text" v-else>{{ client.username }}</div>

                        </v-toolbar-title>

                        <v-spacer />

                        <div v-if="client.status == 6 || client.status == 7 || !client.is_active">

                            <v-icon color="grey" class="mx-3" v-if="client.not_logged_in_for_at_least_one_month">fas fa-exclamation-circle</v-icon>

                        </div>

                        <div v-else>

                            <v-tooltip bottom v-if="client.not_logged_in_for_at_least_one_month">

                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="error" class="mx-3">fas fa-exclamation-circle</v-icon>
                                    </template>

                                    <span><span v-if="client.client_gender == 1">Klient</span><span v-else>Klientin</span> hat sich seit mehr als einen Monat nicht eingelogged. Bitte klären Sie die Ursache ab.</span>

                            </v-tooltip>

                        </div>

                        <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn @click="exclude_client(client)" v-on="on" icon small outlined color='error' class="mx-2" :disabled="client.status == 6 || client.status == 7 || !client.is_active">
                                        <v-icon small>fas fa-user-slash</v-icon>
                                    </v-btn>
                                </template>

                                <span><span v-if="client.client_gender == 1">Klient</span><span v-else>Klientin</span> von der Studie ausschließen.</span>

                        </v-tooltip>

                    </v-toolbar>

                    <v-divider class="mb-2"></v-divider>

                    <v-row align="center" justify="center" class="pt-5">

                            <v-menu offset-y z-index="1" min-width="300">

                                <template v-slot:activator="{ on: onMenu }">

                                    <v-tooltip bottom>

                                        <template v-slot:activator="{ on: onTooltip }">

                                            <v-btn 
                                                fab 
                                                medium 
                                                v-on="{ ...onMenu, ...onTooltip }"
                                                class="mx-2" 
                                                size="42" 
                                                color="primary" 
                                                :disabled="!client.is_active"
                                            >
                                                <v-icon dark>fas fa-balance-scale</v-icon>
                                            </v-btn>

                                        </template>

                                        <span>Risikoeinschätzung</span>

                                    </v-tooltip>

                                </template>

                                <v-list subheader>

                                    <v-list-item
                                        @click="$router.push({name: 'supervisor-risk-acute', params: {search_prop: client.username}})"
                                    >
                                        <v-list-item-content>
                                            <div class="font-weight-medium primary--text">Acute-2007-SR</div>
                                            <div class="body-2 font-weight-light">Akut-dynamische Risikofaktoren</div>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item
                                        @click="$router.push({name: 'supervisor-risk-cmc', params: {search_prop: client.username}})"
                                    >
                                        <v-list-item-content>
                                            <div class="font-weight-medium primary--text">CMC</div>
                                            <div class="body-2 font-weight-light">Sexuell deviante und kriminelle Verhaltensweisen</div>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item
                                        @click="$router.push({name: 'supervisor-risk-cte', params: {search_prop: client.username}})"
                                    >
                                        <v-list-item-content>
                                            <div class="font-weight-medium primary--text">CTE</div>
                                            <div class="body-2 font-weight-light">Behandlungsbezogene Veränderungen</div>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item
                                        @click="$router.push({name: 'supervisor-risk-flashlight-pre', params: {search_prop: client.username}})"
                                    >
                                        <v-list-item-content>
                                            <div class="font-weight-medium primary--text">Blitzlicht (Prä)</div>
                                            <div class="body-2 font-weight-light">Stimmung und sexuelles Verhalten zwischen Sitzungen</div>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item
                                        @click="$router.push({name: 'supervisor-risk-flashlight-post', params: {search_prop: client.username}})"
                                    >
                                        <v-list-item-content>
                                            <div class="font-weight-medium primary--text">Blitzlicht (Post)</div>
                                            <div class="body-2 font-weight-light">Stimmung und sexuelles Verhalten während Sitzungen</div>
                                        </v-list-item-content>
                                    </v-list-item>

                                </v-list>

                            </v-menu>



                            <v-tooltip bottom v-if="client.is_active">
                                <template v-slot:activator="{ on }">
                                    <v-badge
                                        :color="client.critical_events == 0 ? 'success' : 'error'"
                                        dot
                                        offset-y="10"
                                        offset-x="10"
                                    >
                                        <v-btn fab @click="go_to_critical_events(client.username)" v-on="on" class="mx-2" size="42" color="primary">
                                            <v-icon dark>fas fa-user-md</v-icon>
                                        </v-btn>
                                    </v-badge>
                                </template>
                                <span v-if="client.critical_events == 0">Keine potentiellen kritischen Ereignisse <span v-if="client.coach_gender == 1">vom Coach</span><span v-else>von der Coachin</span> gemeldet.</span>
                                <span v-if="client.critical_events == 1">Ein kritisches Ereignis <span v-if="client.coach_gender == 1">vom Coach</span><span v-else>von der Coachin</span> gemeldet.</span>
                                <span v-if="client.critical_events > 1">{{client.critical_events}} kritische Ereignise <span v-if="client.coach_gender == 1">vom Coach</span><span v-else>von der Coachin</span> gemeldet.</span>
                            </v-tooltip>

                            <v-tooltip bottom v-if="!client.is_active">
                                <template v-slot:activator="{ on }">
                                        <v-btn fab @click="go_to_critical_events(client.username)" v-on="on" class="mx-2" size="42" color="primary" disabled>
                                            <v-icon dark>fas fa-user-md</v-icon>
                                        </v-btn>
                                </template>
                            </v-tooltip>

                            <v-tooltip bottom v-if="client.is_active">
                                <template v-slot:activator="{ on }">
                                    <v-badge
                                        :color="client.client_new_messages == 0 ? 'success' : 'error'"
                                        dot
                                        offset-y="10"
                                        offset-x="10"
                                    >
                                        <v-btn fab @click="go_to_chats(client.username)" v-on="on" class="mx-2" size="42" color="primary">
                                            <v-icon dark>fas fa-comments</v-icon>
                                        </v-btn>
                                    </v-badge>
                                </template>
                                <span v-if="client.client_new_messages == 0">Keine ungelesenen Nachrichten <span v-if="client.client_gender == 1">des Klienten</span><span v-else>der Klientin</span> vorhanden.</span>
                                <span v-if="client.client_new_messages == 1">Eine ungelesene Nachricht <span v-if="client.client_gender == 1">des Klienten</span><span v-else>der Klientin</span> vorhanden.</span>
                                <span v-if="client.client_new_messages > 1">{{client.client_new_messages}} ungelesene Nachrichten <span v-if="client.client_gender == 1">des Klienten</span><span v-else>der Klientin</span> vorhanden.</span>
                            </v-tooltip>

                            <v-tooltip bottom v-if="!client.is_active">
                                <template v-slot:activator="{ on }">
                                        <v-btn fab @click="go_to_chats(client.username)" v-on="on" class="mx-2" size="42" color="primary" disabled>
                                            <v-icon dark>fas fa-comments</v-icon>
                                        </v-btn>
                                </template>
                            </v-tooltip>

                            <v-tooltip bottom v-if="client.is_active">
                                <template v-slot:activator="{ on }">
                                    <v-badge
                                        :color="client.supervisor_new_polls == 0 ? 'success' : 'error'"
                                        dot
                                        offset-y="10"
                                        offset-x="10"
                                    >
                                        <v-btn fab @click="go_to_questionnaires(client.username)" v-on="on" class="mx-2" size="42" color="primary">
                                            <v-icon dark>fas fa-poll</v-icon>
                                        </v-btn>
                                    </v-badge>
                                </template>
                                <span v-if="client.supervisor_new_polls == 0">Keine unbeantworteten Fragebögen vorhanden.</span>
                                <span v-if="client.supervisor_new_polls == 1">Ein unbeantworteter Fragebogen vorhanden.</span>
                                <span v-if="client.supervisor_new_polls > 1">{{client.supervisor_new_polls}} unbeantwortete Fragebögen vorhanden.</span>
                            </v-tooltip>

                            <v-tooltip bottom v-if="!client.is_active">
                                <template v-slot:activator="{ on }">
                                        <v-btn fab @click="go_to_questionnaires(client.username)" v-on="on" class="mx-2" size="42" color="primary" disabled>
                                            <v-icon dark>fas fa-poll</v-icon>
                                        </v-btn>
                                </template>
                            </v-tooltip>

                            <v-tooltip bottom v-if="client.is_active">
                                <template v-slot:activator="{ on }">
                                    <v-badge
                                        :color="client.supervisor_open_payments == 0 ? 'success' : 'error'"
                                        dot
                                        offset-y="10"
                                        offset-x="10"
                                    >
                                        <v-btn fab @click="go_to_open_payments(client.username)" v-on="on" class="mx-2" size="42" color="primary">
                                            <v-icon dark>fas fa-hand-holding-usd</v-icon>
                                        </v-btn>
                                    </v-badge>
                                </template>
                                <span v-if="client.supervisor_open_payments == 0">Keine offene Aufwandsentschädigung vorhanden.</span>
                                <span v-if="client.supervisor_open_payments == 1">Eine offene Aufwandsentschädigung vorhanden.</span>
                                <span v-if="client.supervisor_open_payments > 1">{{client.supervisor_open_payments}} offene Aufwandsentschädigungen vorhanden.</span>
                            </v-tooltip>

                            <v-tooltip bottom v-if="!client.is_active">
                                <template v-slot:activator="{ on }">
                                        <v-btn fab v-on="on" class="mx-2" size="42" color="primary" disabled>
                                            <v-icon dark>fa-hand-holding-usd</v-icon>
                                        </v-btn>
                                </template>
                            </v-tooltip>

                    </v-row>

                    <v-row align="center" justify="center" class="pt-5 mx-2">

                        <span class="" v-if="client.status == 1"><b>Registriert</b></span>
                        <span class="" v-if="client.status == 2"><b>Aktiv</b></span>
                        <span class="" v-if="client.status == 3"><b>Inaktiv</b></span>
                        <span class="" v-if="client.status == 4"><b>Abgeschlossen</b></span>
                        <span class="" v-if="client.status == 5"><b>Ausgeschlossen</b></span>

                    </v-row>

                    <v-row align="center" justify="center" class="mt-1 mx-2 caption">
                        <span v-if="client.client_progress_module != 0 && client.client_progress_module != 7">Modul {{ client.client_progress_module }} - Sitzung {{ client.client_progress_session }} - Lektion {{ client.client_progress_lection }}</span>
                        <span v-if="client.client_progress_module == 7">alle Inhalte wurden bearbeitet</span>
                        <span v-if="client.client_progress_module == 0">Einführung</span>
                    </v-row>

                    <v-row align="center" justify="center" class="mt-1 mx-2 caption">
                        <span class="caption grey--text font-weight-light mb-2 ml-1"><small>(registriert am {{ getCorrectDate(client.date_joined) }})</small></span>
                    </v-row>

                    <v-divider class="my-2"></v-divider>

                    <v-row align="center" justify="center">
                        
                        <v-icon
                            class="mr-2 mb-2"
                            small
                        >
                            mdi-clock
                        </v-icon>
                        <span class="caption grey--text font-weight-light mb-2" v-if="client.last_login">Letzter Login {{ client.last_login }}</span>
                        <span class="caption grey--text font-weight-light mb-2" v-else>noch nicht eingeloggt</span>
                    </v-row>
                </v-card>
            </v-col>

        </v-row>

        <router-view v-else />

    </v-container>
</template>

<script>
import SupervisorApi from '@/_api/supervisor_api'
import DateTimeHelpers from '@/_helpers/date_time_helpers'

export default {
    
    name: 'supervisor_home',

    data: () => ({

        loaded: false,


    }),

    created() {

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        SupervisorApi.GetSupervisorInfo().then(() => {

            SupervisorApi.GetSupervisorAllClientsData().then(() => {

                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                this.loaded = true

            })

        })

    },

    beforeRouteUpdate(to, from, next){

        SupervisorApi.GetSupervisorInfo().then(() => {

            SupervisorApi.GetSupervisorAllClientsData().then(() => {

                next(vm => { 
                    vm.loaded = true
                })

            })

        })

    },

    computed: {

        clients() {
            return this.$store.getters['store_supervisor/get_supervisor_clients']
        },



        show_cookies_dialog() {

            return !this.$store.getters['store_supervisor/get_supervisor'].cookies_accepted

        },

        is_supervisor_home() {

            if (this.$route.path == '/supervisor') {

                return true

            } else {

                return false

            }

        },

        update_date() {

            var currentdate = new Date();
            var hours = '00'
            var minutes = '00'
            var seconds = '00'

            if (currentdate.getHours() < 10) {
                hours = '0' + currentdate.getHours()
            } else {
                hours = currentdate.getHours()
            }

            if (currentdate.getMinutes() < 10) {
                minutes = '0' + currentdate.getMinutes()
            } else {
                minutes = currentdate.getMinutes()
            }

            if (currentdate.getSeconds() < 10) {
                seconds = '0' + currentdate.getSeconds()
            } else {
                seconds = currentdate.getSeconds()
            }


            return hours + ":" + minutes + ":" + seconds;

        },

    },

    methods: {

        getCorrectDateTime(item) {
            return DateTimeHelpers.convertDatabaseDateTimeString('full', item)
        },

        getCorrectDate(item) {
            return DateTimeHelpers.convertDatabaseDateTimeString('only_date', item)
        },

        go_to_open_payments(prop) {
            this.$router.push({ name: 'supervisor-payments', params: { search_prop: prop } })
        },

        go_to_risk_assessment(prop) {

            this.$router.push({ name: 'supervisor-risk', params: { search_prop: prop } })

        },

        go_to_chats(prop) {

            this.$router.push({ name: 'supervisor-messages', params: { search_prop: prop } })

        },

        go_to_questionnaires(prop) {

            this.$router.push({ name: 'supervisor-assessment', params: { search_prop: prop } })

        },

        go_to_critical_events(prop) {
            this.$router.push({ name: 'supervisor-critical-event', params: { search_prop: prop } })
        },

        exclude_client(client) {

            this.$router.push({ name: 'supervisor-exclude-client', params: { client_id: client.client_id, client_user_id: client.client_user_id} })
        }

    },
    
}
</script>

<style scoped>

</style>