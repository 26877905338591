<template>  
   
   <div v-if="loaded">

        <v-card
            color="grey lighten-5"
            flat
            height="auto"
        >

            <v-toolbar 
                flat
                short
                color="grey lighten-5"
                
            >

                <v-icon color="primary">fas fa-poll</v-icon>

                <v-toolbar-title class="primary--text ml-3">Offene Fragebögen</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn 
                    icon
                    @click="openPanelOpen()"
                    color="primary"
                >
                    <v-icon v-if="!panel_open">fas fa-angle-down</v-icon>
                    <v-icon v-else>fas fa-angle-up</v-icon>
                </v-btn>

            </v-toolbar>

            <v-divider />

            <v-card-text v-if="panel_open">
                
                <v-alert
                    v-if="show_unfinished_panel_null_alert()"
                    border="left"
                    elevation="1"
                    colored-border
                    prominent
                    type="success"
                >
                    Es liegen keine Fragebögen, die ausgefüllt werden müssen, vor.
                </v-alert>

                <div
                    v-for="task in unfinished_polls"
                    :key="task.id"
                >

                    <v-alert
                        border="left"
                        elevation="1"
                        colored-border
                        prominent
                        type="error"
                    >
                        <v-row align="center">

                            <v-col cols="auto" class="mr-auto">

                                <div>
                                    <small>Fragebogen</small>
                                </div>
                                
                                <div class="black--text mt-3">
                                    <div  v-if="task.module_nr != 0">
                                        <b>{{ getReadableTaskName(task.assessment_name) }}</b><small> (Modul {{ task.module_nr}} - Sitzung {{ task.session_nr }} - Lektion {{ task.lession_nr}})</small>
                                    </div>
                                    <div v-else>
                                        <b>{{ getReadableTaskName(task.assessment_name) }}</b><small> (Einführung)</small>
                                    </div>
                                </div>

                                <div class="overline mt-3">
                                    <b>Der Fragebogen wurde am {{ get_converted_time(task.create_time) }} nicht ausgefüllt.</b>
                                </div>

                            </v-col>

                            <v-col cols="auto">
                                <v-btn
                                    outlined
                                    color="error"
                                    @click="goToPollDetails(task)"
                                >
                                    Zum Fragebogen
                                </v-btn>
                            </v-col>

                        </v-row>

                    </v-alert>

                </div>

            </v-card-text>

        </v-card>

        <v-divider class="my-5"/>

        <!-- ABGESCHLOSSENE FRAGEBÖGEN -->
        <v-card
            color="grey lighten-5"
            flat
            height="auto"
        >

            <v-toolbar 
                flat
                short
                color="grey lighten-5"
                
            >

                <v-icon color="primary">fas fa-check</v-icon>

                <v-toolbar-title class="primary--text ml-3">Ausgefüllte Fragebögen</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn 
                    icon
                    @click="openPanelFinished()"
                    color="primary"
                >
                    <v-icon v-if="!panel_finished">fas fa-angle-down</v-icon>
                    <v-icon v-else>fas fa-angle-up</v-icon>
                </v-btn>

            </v-toolbar>

            <v-divider />

            <v-card-text v-if="panel_finished">
                
                <v-alert
                    v-if="show_finished_panel_null_alert()"
                    border="left"
                    elevation="1"
                    colored-border
                    prominent
                    type="success"
                >
                    Es liegen keine beantworteten Fragebögen vor.
                </v-alert>

                <div
                    v-for="task in finished_polls"
                    :key="task.id"
                >

                    <v-alert
                        border="left"
                        elevation="1"
                        colored-border
                        prominent
                        type="success"
                    >
                        <v-row align="center">

                            <v-col cols="auto" class="mr-auto">

                                <div>
                                    <small>Fragebogen</small>
                                </div>
                                
                                <div class="black--text mt-3">
                                    <div  v-if="task.module_nr != 0">
                                        <b>{{ getReadableTaskName(task.assessment_name) }}</b><small> (Modul {{ task.module_nr}} - Sitzung {{ task.session_nr }} - Lektion {{ task.lession_nr}})</small>
                                    </div>
                                    <div v-else>
                                        <b>{{ getReadableTaskName(task.assessment_name) }}</b><small> (Einführung)</small>
                                    </div>
                                </div>

                                <div class="overline mt-3">
                                    <b>Sie haben den Fragebogen am {{ get_converted_time(task.update_time) }} ausgefüllt.</b>
                                </div>

                            </v-col>

                        </v-row>

                    </v-alert>

                </div>

            </v-card-text>

        </v-card>

    </div>

</template>

<script>
import ClientApi from '@/_api/client_api'
import DateTimeHelpers from '@/_helpers/date_time_helpers'

export default {
    name: 'client-open-polls',

    data: () => ({
        
        panel_open: true,
        panel_finished: false,

        unfinished_polls: [],
        finished_polls: [],

        loaded: false,

    }),

    created() {

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        ClientApi.GetUnfinishedPolls().then((resp) => {

            this.unfinished_polls = resp.data

            ClientApi.GetFinishedPolls().then((resp) => {

                this.finished_polls = resp.data

                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                this.loaded = true

            })

        })

    },

    methods: {

        getReadableTaskName(name) {

            var readable = ''

            if (name == 'aoo') {
                readable = 'AOO'
            } else if (name == 'acute') {
                readable = 'Acute-2007-SR'
            } else if (name == 'bis') {
                readable = 'BIS-15'
            } else if (name == 'cmc') {
                readable = 'CMC'
            } else if (name == 'cte') {
                readable = 'CTE'
            } else if (name == 'ctq') {
                readable = 'CTQ'
            } else if (name == 'cusi') {
                readable = 'CUSI'
            } else if (name == 'cvtrq') {
                readable = 'CVTRQ'
            } else if (name == 'ders') {
                readable = 'DERS'
            } else if (name == 'ekk') {
                readable = 'EKK-R'
            } else if (name == 'esiq') {
                readable = 'ESIQ'
            } else if (name == 'exsik') {
                readable = 'ExSIK'
            } else if (name == 'fsozu') {
                readable = 'F-Sozu'
            } else if (name == 'flashlight_pre') {
                readable = 'Blitzlicht Prä'
            } else if (name == 'flashlight_post') {
                readable = 'Blitzlicht Post'
            } else if (name == 'flexsik') {
                readable = 'FlexSIK'
            } else if (name == 'hbi') {
                readable = 'HBI-19'
            } else if (name == 'icd') {
                readable = 'ICD-11 Screening Fragebogen'
            } else if (name =='kvm') {
                readable = 'KVM'
            } else if (name == 'motsik') {
                readable = 'MotSIK'
            } else if (name == 'narq') {
                readable = 'NARQ'
            } else if (name == 'ors') {
                readable = 'ORS'
            } else if (name == 'phq') {
                readable = 'PHQ-D'
            } else if (name == 'rcq') {
                readable = 'RCQ'
            } else if (name == 'sample_client') {
                readable =  'Stichprobenbeschreibung'
            } else if (name == 'sbv') {
                readable = 'SBV'
            } else if (name == 'soi') {
                readable = 'SOI-R'
            } else if (name == 'spsi') {
                readable = 'SPSI-R'
            } else if (name == 'ssik') {
                readable = 'SSIK'
            } else if (name == 'ucla') {
                readable = 'UCLA'
            } else if (name == 'utaut') {
                readable = 'UTAUT'
            } else if (name == 'wai') {
                readable = 'WAI-SR'
            } else if (name == 'wai_sv') {
                readable = 'WAI-SR-SV'
            } else if (name == 'who') {
                readable = 'WHO-5'
            } else {
                readable = 'NaN'
            } 



            return readable
        },

        openPanelOpen() {

            if (this.panel_open) {
                this.panel_open = false
            } else {
                this.panel_open = true
            }
        },

        openPanelFinished() {

            if (this.panel_finished) {
                this.panel_finished = false
            } else {
                this.panel_finished = true
            }
        },

        show_unfinished_panel_null_alert() {

            var tmp = false;

            if (this.unfinished_polls.length == 0) {

                tmp = true

            } else {

                tmp = false

            }

            return tmp

        },

        show_finished_panel_null_alert() {

            var tmp = false;

            if (this.finished_polls.length == 0) {
                tmp = true
            } else {
                tmp = false
            }

            return tmp
        
        },

        get_converted_time(item) {

            return DateTimeHelpers.convertDatabaseDateTimeString('full', item)
        },

        goToPollDetails(poll) {

            this.$router.push({ name: 'client-polls-single', params: { module: poll.module_nr, session: poll.session_nr, lection: poll.lession_nr, timepoint: poll.timepoint, step: 0, name: poll.assessment_name } })

        },

    }
}
</script>

<style scoped>

</style>