<template>

    <div v-if="loaded">

        <!-- UNREAD CRITICAL EVENTS -->

        <v-card
            color="grey lighten-5"
            flat
            outlined
            height="auto"
            width="100%"
        >

            <v-expansion-panels flat v-model="panel_open">

                <v-expansion-panel>

                    <v-expansion-panel-header color="grey lighten-5">

                        <v-card flat class="py-0 my-0" color="grey lighten-5">

                            <v-card-text class="pl-0 title primary--text py-0 my-0">
                                Ungelesene kritische Ereignisse
                            </v-card-text>

                        </v-card>

                        <template v-slot:actions>
                            <v-icon color="primary">$expand</v-icon>
                        </template>
                    </v-expansion-panel-header>

                    <v-divider />

                    <v-expansion-panel-content color="grey lighten-5">

                        <v-card-text v-if="filteredOpenPolls.length > 0" class="mx-0 px-0 py-0 my-0">

                            <v-card flat outlined color="grey lighten-5" class="mx-0 px-0">

                                <v-card-text class="mx-0 px-0">

                                    <v-autocomplete
                                        v-model="search_open_questionnaires"
                                        :items="all_clients"
                                        label="Ungelesene kritische Ereignisse nach Klient*in filtern"
                                        prepend-icon="fas fa-search"
                                        chips
                                        deletable-chips
                                        hide-details
                                        small-chips
                                    ></v-autocomplete>

                                </v-card-text>

                            </v-card>

                            <v-divider class="my-3" />
                            
                            <v-alert
                                v-if="show_unfinished_panel_null_alert()"
                                border="left"
                                elevation="1"
                                colored-border
                                prominent
                                type="success"
                            >
                                Es liegen keine ungelesenen kritischen Ereignisse vor.
                            </v-alert>

                            <div
                                v-for="task in filteredOpenPolls"
                                :key="task.id"
                            >

                                <v-alert
                                    text
                                    color="error"
                                >

                                    <div class="headline">
                                        Potentiell kritisches Ereignis
                                    </div>

                                    <div class="mt-1">
                                        <small>
                                            Das Ereignis wurde am {{ get_converted_time(task.event_report_time) }} gemeldet. 
                                            <span v-if="task.email_sent">Am {{ get_converted_time(task.email_sent_time) }} wurde eine Email an Sie versendet.</span>
                                        </small>
                                    </div>

                                    <v-divider
                                        class="my-4 error"
                                        style="opacity: 0.22"
                                    ></v-divider>
                                    
                                    <div class="mt-3">
                                        <div v-if="task.event_message_content">

                                            Folgende Nachricht erhielt <span v-if="get_coach_gender(task.client__user__username) == 1">der Coach</span><span v-else>die Coachin</span> von <span v-if="get_client_gender(task.client__user__username) == 1">Klient</span><span v-else>Klientin</span> <b>{{ task.client__user__username}}</b>: 
                                            <blockquote class="blockquote mt-3">{{ task.event_message_content }}</blockquote>
                                            
                                        </div>
                                        <div v-if="task.event_coach_content_critical_items">

                                            Während der Bearbeitung einer Übung <span v-if="get_client_gender(task.client__user__username) == 1">Ihres Klienten</span><span v-else>Ihrer Klientin</span> <b>{{ task.client__user__username}}</b> ist <span v-if="get_coach_gender(task.client__user__username) == 1">dem Coach</span><span v-else>der Coachin</span> folgendes aufgefallen: 
                                            <blockquote class="blockquote mt-3">{{ task.event_coach_content_critical_items }}</blockquote>

                                        </div>
                                        <div v-if="!task.event_coach_content_critical_items && !task.event_message_content">

                                            <span v-if="get_client_gender(task.client__user__username) == 1">Ihr Klient</span><span v-else>Ihre Klientin</span> <b>{{ task.client__user__username}}</b> hat sich seit (mehr als) vier Wochen nicht mehr im Online-Programm @myTabu eingeloggt hat. 
                                            Bitte kontaktieren Sie ggf. <span v-if="get_client_gender(task.client__user__username) == 1">Ihren Klienten</span><span v-else>Ihre Klientin</span>. Sollte sich herausstellen, dass <span v-if="get_client_gender(task.client__user__username) == 1">Ihr Klient</span><span v-else>Ihre Klientin</span> neue Login-Daten benötigt, geben Sie der 
                                            Studienleitung dazu bitte per E-Mail Rückmeldung (<a href="mailto:mytabu@med.uni-goettingen.de">Studienleitung</a>). 
                                            Beachten Sie dabei bitte, nur die Nutzerkennung und nicht den Klarnamen <span v-if="get_client_gender(task.client__user__username) == 1">Ihres Klienten</span><span v-else>Ihrer Klientin</span> zu nennen. Sollte <span v-if="get_client_gender(task.client__user__username) == 1">Ihr Klient</span><span v-else>Ihre Klientin</span> nicht weiter an der 
                                            Studie teilnehmen wollen, können Sie ihn direkt im Online-Programm aus der Studie ausschließen. 

                                        </div>
                                    </div>

                                    <div class="mt-3" v-if="task.event_coach_content_critical_items || task.event_message_content">

                                        <b>Einschätzung des @myTabu-Teams</b>: {{ get_correct_hint(task) }}

                                    </div>

                                    <v-divider
                                        class="my-4 error"
                                        style="opacity: 0.22"
                                    ></v-divider>

                                    <v-row
                                        align="center"
                                        no-gutters
                                    >
                                        <v-col cols="auto" class="mr-auto">
                                            <div class="caption">
                                                Bitte beurteilen Sie selbst, ob es sich dabei um ein kritisches Ereignis handelt und ob ein persönlicher Termin mit <span v-if="get_client_gender(task.client__user__username) == 1">Ihrem Klienten</span><span v-else>Ihrer Klientin</span> dadurch notwendig wird! <span v-if="get_coach_gender(task.client__user__username) == 1">Der Coach</span><span v-else>Die Coachin</span> kann selbst keine Maßnahmen ergreifen.
                                            </div>
                                        </v-col>
                                        <v-spacer></v-spacer>
                                        <v-col class="shrink">
                                            <v-btn
                                                color="error"
                                                outlined
                                                @click="critical_event_read(task)"
                                            >
                                                Ok
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-alert>

                            </div>

                        </v-card-text>

                        <v-card-text v-if="filteredOpenPolls.length == 0" class="mx-0 px-0">
                            
                            <v-alert
                                border="left"
                                elevation="1"
                                colored-border
                                prominent
                                type="success"
                            >
                                Es liegen keine ungelesenen kritischen Ereignisse für Klient*in {{ search_open_questionnaires }} vor.
                            </v-alert>

                        </v-card-text>

                    </v-expansion-panel-content>

                </v-expansion-panel>

            </v-expansion-panels>

        </v-card>



        <v-divider class="my-5"/>

        <!-- Gelesene kritische Ereignisse -->
        <v-card
            color="grey lighten-5"
            flat
            height="auto"
        >

            <v-expansion-panels flat>

                <v-expansion-panel>

                    <v-expansion-panel-header color="grey lighten-5">

                        <v-card flat class="py-0 my-0" color="grey lighten-5">

                            <v-card-text class="pl-0 title primary--text py-0 my-0">
                                Gelesene kritische Ereignisse
                            </v-card-text>

                        </v-card>

                        <template v-slot:actions>
                            <v-icon color="primary">$expand</v-icon>
                        </template>
                    </v-expansion-panel-header>

                    <v-divider />

                    <v-expansion-panel-content color="grey lighten-5">

                        <v-card-text v-if="filteredClosedPolls.length > 0" class="mx-0 px-0 py-0 my-0">

                            <v-card flat outlined color="grey lighten-5" class="mx-0 px-0">

                                <v-card-text class="mx-0 px-0">

                                    <v-autocomplete
                                        v-model="search_closed_questionnaires"
                                        :items="all_clients"
                                        label="Gelesene kritische Ereignisse nach Klient*in filtern"
                                        prepend-icon="fas fa-search"
                                        chips
                                        deletable-chips
                                        hide-details
                                        small-chips
                                    ></v-autocomplete>

                                </v-card-text>

                            </v-card>

                            <v-divider class="my-3"/>
                            
                            <v-alert
                                v-if="show_finished_panel_null_alert()"
                                border="left"
                                elevation="1"
                                colored-border
                                prominent
                                type="success"
                            >
                                Es liegen keine gelesenen kritischen Ereignisse vor.
                            </v-alert>

                            <div
                                v-for="task in filteredClosedPolls"
                                :key="task.id"
                            >

                                <v-alert
                                    text
                                    color="success"
                                >

                                    <div class="headline">
                                        Potentiell kritisches Ereignis
                                    </div>

                                    <div class="mt-1">
                                        <small>
                                            Das Ereignis wurde am {{ get_converted_time(task.event_report_time) }} gemeldet. 
                                            <span v-if="task.email_sent">Am {{ get_converted_time(task.email_sent_time) }} wurde eine Email an Sie versendet.</span>
                                        </small>
                                    </div>

                                    <v-divider
                                        class="my-4 success"
                                        style="opacity: 0.22"
                                    ></v-divider>
                                    
                                    <div class="mt-3">
                                        <div v-if="task.event_message_content">

                                            Folgende Nachricht erhielt <span v-if="get_coach_gender(task.client__user__username) == 1">der Coach</span><span v-else>die Coachin</span> von <span v-if="get_client_gender(task.client__user__username) == 1">Klient</span><span v-else>Klientin</span> <b>{{ task.client__user__username}}</b>: 
                                            <blockquote class="blockquote mt-3">{{ task.event_message_content }}</blockquote>
                                            
                                        </div>
                                        <div v-if="task.event_coach_content_critical_items">

                                            Während der Bearbeitung einer Übung <span v-if="get_client_gender(task.client__user__username) == 1">Ihres Klienten</span><span v-else>Ihrer Klientin</span> <b>{{ task.client__user__username}}</b> ist <span v-if="get_coach_gender(task.client__user__username) == 1">dem Coach</span><span v-else>der Coachin</span> folgendes aufgefallen: 
                                            <blockquote class="blockquote mt-3">{{ task.event_coach_content_critical_items }}</blockquote>

                                        </div>
                                        <div v-if="!task.event_coach_content_critical_items && !task.event_message_content">

                                            <span v-if="get_client_gender(task.client__user__username) == 1">Ihr Klient</span><span v-else>Ihre Klientin</span> <b>{{ task.client__user__username}}</b> hat sich seit (mehr als) vier Wochen nicht mehr im Online-Programm @myTabu eingeloggt hat. 
                                            Bitte kontaktieren Sie ggf. <span v-if="get_client_gender(task.client__user__username) == 1">Ihren Klienten</span><span v-else>Ihre Klientin</span>. Sollte sich herausstellen, dass <span v-if="get_client_gender(task.client__user__username) == 1">Ihr Klient</span><span v-else>Ihre Klientin</span> neue Login-Daten benötigt, geben Sie der 
                                            Studienleitung dazu bitte per E-Mail Rückmeldung (<a href="mailto:mytabu@med.uni-goettingen.de">Studienleitung</a>). 
                                            Beachten Sie dabei bitte, nur die Nutzerkennung und nicht den Klarnamen <span v-if="get_client_gender(task.client__user__username) == 1">Ihres Klienten</span><span v-else>Ihrer Klientin</span> zu nennen. Sollte <span v-if="get_client_gender(task.client__user__username) == 1">Ihr Klient</span><span v-else>Ihre Klientin</span> nicht weiter an der 
                                            Studie teilnehmen wollen, können Sie ihn direkt im Online-Programm aus der Studie ausschließen. 

                                        </div>
                                    </div>

                                    <div class="mt-3" v-if="task.event_thread || task.event_message_content">

                                        <b>Einschätzung des @myTabu-Teams</b>: {{ get_correct_hint(task) }}

                                    </div>

                                    <v-divider
                                        class="my-4 success"
                                        style="opacity: 0.22"
                                    ></v-divider>

                                    <v-row
                                        align="center"
                                        no-gutters
                                    >
                                        <v-col cols="auto" class="mr-auto">
                                            <div class="caption">
                                                Sie haben den Hinweis auf dieses potentiell kritische Ereignis am {{ get_converted_time(task.inapp_alert_read_time) }} zur Kenntnis genommen.
                                            </div>
                                        </v-col>
                                    </v-row>

                                </v-alert>

                            </div>

                        </v-card-text>

                        <v-card-text v-if="filteredClosedPolls.length == 0">
                            
                            <v-alert
                                border="left"
                                elevation="1"
                                colored-border
                                prominent
                                type="success"
                            >
                                Es liegen keine gelesenen kritischen Ereignisse für Klient*in {{ search_closed_questionnaires }} vor.
                            </v-alert>

                        </v-card-text>

                    </v-expansion-panel-content>

                </v-expansion-panel>

            </v-expansion-panels>

        </v-card>

    </div>

</template>

<script>
import SupervisorApi from '@/_api/supervisor_api'
import DateTimeHelpers from '@/_helpers/date_time_helpers'

export default {
    
    name: 'supervisor_critical_event',

    data: () => ({

        loaded: false,

        panel_open: 0,

        unfinished_polls: [],
        finished_polls: [],

        search_open_questionnaires: null,
        search_closed_questionnaires: null,

        all_clients: []


    }),

    created() {

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        SupervisorApi.GetSupervisorInfo().then(() => {

            SupervisorApi.GetCriticalEvents().then((resp) => {

                this.unfinished_polls = resp.data.data_critical_events_unread

                this.finished_polls = resp.data.data_critical_events_read

                if (this.$route.params.search_prop == 'null') {
                    this.search_open_questionnaires = null
                    this.search_closed_questionnaires = null
                } else {
                    this.search_open_questionnaires = this.$route.params.search_prop
                    this.search_closed_questionnaires = this.$route.params.search_prop
                }

                for (var i = 0; i < this.$store.getters['store_supervisor/get_supervisor_clients'].length; i++) {

                    this.all_clients.push(this.$store.getters['store_supervisor/get_supervisor_clients'][i].username)
                }

                this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                this.loaded = true

                })

            })

    },

    computed: {

        filteredOpenPolls() {

            var filtered = []

            if (this.search_open_questionnaires != null) {

                for (var i=0; i < this.unfinished_polls.length; i++) {
                    

                    if (this.unfinished_polls[i].client__user__username === this.search_open_questionnaires) {

                        filtered.push(this.unfinished_polls[i]);

                    }

                }

            } else {

                filtered = this.unfinished_polls

            }

            return filtered
             
        },

        filteredClosedPolls() {

            var filtered = []

            if (this.search_closed_questionnaires != null) {

                for (var i=0; i < this.finished_polls.length; i++) {

                    if (this.finished_polls[i].client__user__username === this.search_closed_questionnaires) {

                        filtered.push(this.finished_polls[i]);

                    }

                }

            } else {

                filtered = this.finished_polls

            }

            return filtered
             
        },

    },

    methods: {

        get_client_gender(username) {

            var client = this.$store.getters['store_supervisor/get_supervisor_clients'].find(e => e.username === username);

            return client.client_gender

        },

        get_coach_gender(username) {

            var client = this.$store.getters['store_supervisor/get_supervisor_clients'].find(e => e.username === username);

            return client.coach_gender

        },

        get_correct_hint(task) {

            var r = ''


            if (task.event_type == 1) {
                r = 'Plan eines Suizids'
            } else if (task.event_type == 2) {
                r = 'aktuell fortbestehende, gegenwärtige und konkrete Fremdgefährdungslage (wegen begangener Sexualstraftat oder Plan einer Sexualstraftat)'
            } else if (task.event_type == 3) {
                r = 'Plan einer Straftat i.S.d. §§ 138, 139 StGB'
            } else {
                r = 'begangene Sexualstraftat während der Teilnahme an @myTabu'
            }

            return r
        },

        getReadableTaskName(name) {

            var readable = ''

            if (name == 'supervisor_sample') {
                readable = 'Beschreibung des*der Klienten*Klientin'
            } else if (name == 'repeated_supervisor_sample') {
                readable = 'Verlaufsmessung'
            }

            return readable

        },

        openPanelOpen() {

            if (this.panel_open) {
                this.panel_open = false
            } else {
                this.panel_open = true
            }
        },

        openPanelFinished() {

            if (this.panel_finished) {
                this.panel_finished = false
            } else {
                this.panel_finished = true
            }
        },

        show_unfinished_panel_null_alert() {

            var tmp = false;

            if (this.unfinished_polls.length == 0) {

                tmp = true

            } else {

                tmp = false

            }

            return tmp

        },

        show_finished_panel_null_alert() {

            var tmp = false;

            if (this.finished_polls.length == 0) {
                tmp = true
            } else {
                tmp = false
            }

            return tmp
        
        },

        get_converted_time(item) {

            return DateTimeHelpers.convertDatabaseDateTimeString('full', item)
        },

        critical_event_read(task) {

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

            var type = ''

            if (task.event_message_content) {
                type = 'message'
            } else if (task.event_coach_content_critical_items){
                type = 'guided_task'
            } else {
                type = 'delay'
            }

            var data = {
                type: type,
                event_id: task.id,
            }

            SupervisorApi.UpdateCriticalEvent(data).then(() => {

                SupervisorApi.GetSupervisorInfo().then(() => {

                    SupervisorApi.GetCriticalEvents().then((resp) => {

                    this.unfinished_polls = resp.data.data_critical_events_unread

                    this.finished_polls = resp.data.data_critical_events_read

                    if (this.$route.params.search_prop == 'null') {
                        this.search_open_questionnaires = null
                        this.search_closed_questionnaires = null
                    } else {
                        this.search_open_questionnaires = this.$route.params.search_prop
                        this.search_closed_questionnaires = this.$route.params.search_prop
                    }

                    this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                    this.loaded = true

                    })

                })

            })

        }


    },
    
}
</script>

<style scoped>

</style>