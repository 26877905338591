<template>

    <v-row>

        <div class="timeline-item">

            <!-- icon and headline -->
            <v-row class="pa-0 ma-0">

                <v-col cols="12" sm="12" class="pa-0">

                    <v-row>

                        <v-avatar :v-show="$vuetify.breakpoint.mdAndDown" color="primary" size="50" class="elevation-5" style="top: 70px;">
                            <v-icon medium color="white">fas fa-lightbulb</v-icon>
                        </v-avatar>

                        <div :class="!$vuetify.breakpoint.mdAndDown ? 'timeline-title' : 'timeline-title-mobile'">

                            <v-avatar v-if="thisFinished" color="success" size="40" class="elevation-5" id="cardbadge">
                                <v-icon small color="white">fas fa-check</v-icon>
                            </v-avatar>

                            <v-avatar v-else color="success" size="40" class="elevation-5" id="cardbadge">
                                <v-icon small color="white">fas fa-pencil-alt</v-icon>
                            </v-avatar>

                            <v-card flat>

                                <v-expansion-panels flat>

                                    <v-expansion-panel :readonly="!thisShow">

                                        <v-expansion-panel-header class="pa-3 timeline-expansion-panel elevation-5">

                                            <v-card flat class="ml-3 mr-3">

                                                <div class="title primary--text">Zusätzliche Information</div>
                                                <div class="headline font-weight-bold">{{ data.title }}</div>
                                                <div class="subtitle-1 primary--text">{{ data.subtitle }}</div>
                                                
                                            </v-card>

                                            <template v-slot:actions>
                                                <v-icon :color="thisShow ? 'primary' : 'grey'">$expand</v-icon>
                                            </template>

                                        </v-expansion-panel-header>

                                        <v-expansion-panel-content>

                                            <!-- content -->
                                            <v-row class="pa-0">
                                                

                                                    <v-card flat color="transparent">

                                                        <!-- basic example component -->
                                                        <basic-info :data="data.columns" :id='id' :reward="data.reward"/>

                                                    </v-card>

                                                
                                            </v-row>

                                        </v-expansion-panel-content>

                                    </v-expansion-panel>

                                </v-expansion-panels>

                            </v-card>

                        </div>

                    </v-row>

                </v-col>

            </v-row>

        </div>

    </v-row>
</template>

<script>
import basicInfo from '@/components/_client/_introduction/introduction_basic_info.vue'

export default {
    name: 'optionalInfo',
    props: ['data', 'id'],
    data () {
        return {
            show: false
        }
    },

    mounted() {
        // check if it is the first block in lesson; if yes, then show = true
        if (this.id == 0) {
            this.$store.dispatch('client_lession/UPDATE_NEXT_LESSION_DATA_SHOW', {'id': this.id + 1, 'data': true})
        }
    },

    computed: {
        thisFinished () {

            var tmp = this.$store.getters['store_lection/get_saved_lection_data']

            if (tmp.lession_finished) {
                return true
            } else {
                return tmp.data[this.id].finished 
            }

        },

        thisShow () {
            var tmp = this.$store.getters['store_lection/get_saved_lection_data']
            return tmp.data[this.id].show
        },

        // add colors to css
        cssProps () {
            return {
                '--primary-color': this.$vuetify.theme.primary
            }
        },

    },

    components: {
        basicInfo
    }
}
</script>

<style scoped>
.timeline-title {
  width: calc(100% - 68px);
  margin-left: 68px;
  position: relative;
}

.timeline-title-mobile {
  width: 100%;
  position: relative;
}

#cardbadge { 
  left: -20px;
  top: -20px; 
  position: absolute;
  z-index: 2;
}

#timeline .timeline-item {
  margin-bottom: 10px;
  position: relative;
  width: 100%;
}

#timeline .timeline-item .timeline-content {
  width: calc(100% - 68px);
  margin-left: 68px;
}

#timeline .timeline-item .timeline-content-optional {
  width: 100%;
  margin-left: 0px;
}

#timeline-mobile .timeline-item {
  margin-bottom: 10px;
  position: relative;
  width: 100%;
}

#timeline-mobile .timeline-item .timeline-content {
  width: 100%;
}

.timeline-expansion-panel {
  border-style: solid;
  border-width: 1px;
  border-radius: 5px !important;
  border-color: #004b8c !important;
  padding-left: 5px;
  width: 100%;
}

.v-expansion-panel-content >>> .v-expansion-panel-content__wrap { 
    padding: 0 12px 16px;
}
</style>

