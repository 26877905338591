import { render, staticRenderFns } from "./client_bank.vue?vue&type=template&id=d3d46816&scoped=true&"
import script from "./client_bank.vue?vue&type=script&lang=js&"
export * from "./client_bank.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3d46816",
  null
  
)

export default component.exports