var help_object = {

    'Haben Sie in den letzten Tagen darüber nachgedacht, wie Sie am besten mit einem Kind in Kontakt kommen können?': {

        description:
            '<p>' +
                'Dieses Item stammt aus dem Acute-2007-Self-Report, einem Selbstbeschreibungsverfahren zur Erfassung von akut-dynamischen Rückfallfaktoren. Akut-dynamische ' +
                'Risikofaktoren sind Faktoren, die sich innerhalb von Stunden oder Tagen verändern können. Die Items werden mit Hilfe einer dreistufigen Antwortkategorie ' +
                'beantwortet (nein, manchmal, oft) und geben das Erleben der Person in den letzten Tagen wieder. Die Subskala der Beschäftigung mit potentiellen Opfern erfasst ' +
                'dabei das Ausmaß und die Intensität, mit dem sich eine Person gedanklich mit potentiellen Opfern und konkreten Plänen und Vorbereitungen zur Kontaktaufnahme ' +
                'beschäftigt. ' +
            '</p><p>' +
                'Bitte beachten Sie, dass Tatpläne und Vorbereitungshandlungen bereits das Stadium zur Versuchsstrafbarkeit überschreiten <i>können</i> und zwar dann, wenn der*die ' +
                'Täter*in nach seiner*ihrer Vorstellung von der Tat zur Verwirklichung des Tatbestandes unmittelbar ansetzt (§ 22 StGB) und die vielfach vom BGH definierte Schwelle ' +
                'zum „jetzt geht´s los“ überschreitet. Solche Vorbereitungshandlungen <i>können</i> insbesondere auch im Zusammenspiel mit anderen Items der Selbstbeschreibungsverfahren ' +
                'bedenklich werden, bspw. wenn Ihr*e Klient*in zusätzlich im Rahmen der Fragebögen davon berichtet, in den letzten Tagen „oft“ das Gefühl gehabt zu haben, eine ' +
                'sexuelle Handlung mit einem Kind begehen zu müssen oder nicht der These zustimmt, dass er*sie bereit sei, Kontakte zu Kindern zu vermeiden oder sein sexuelles ' +
                'Interesse an Kindern nicht verändern möchte. ' +
            '</p><p>' +
                'Hat Ihr*e Klient*in gleichzeitig auch von potentiell sexuell deviantem Verhalten berichtet, wie bspw. an einem Spielplatz oder einer Schule gewesen zu sein oder ' +
                'ein Kind nach Hause eingeladen zu haben, <i>kann</i> hierin nicht nur ein Weisungsverstoß liegen, sondern dies <i>kann</i> einen Hinweis darauf geben, dass der Tatplan oder ' +
                'die Vorbereitungshandlung bereits so weit in das Versuchsstadium gelangt sind, dass eine gegenwärtige Gefahr für Leib, Leben, Gesundheit oder die sexuelle ' +
                'Selbstbestimmung eines Kindes besteht. Um dies zu überprüfen, können Sie sich die letzten Angaben Ihrer Klientin oder Ihres Klienten im Risk-Assessment "Acute-2007-SR" anschauen.' +
            '</p>',
        nds:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern (KURS Niedersachsen)? </b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei verläuft, eine ' +
                'unverzügliche Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). Im Falle von rückfallgefährdenden ' +
                'Situationen, Anzeichen einer ernsthaften Gefährdung Dritter oder Verstößen gegen Weisungen ergeht ebenfalls eine Meldung nachrichtlich an die zuständige ' +
                'Fachberaterin oder den zuständigen Fachberater Risikomanagement. Bei krisenhafter Zuspitzung ist das weitere Vorgehen unverzüglich mit der ' +
                'Führungsaufsichtsstelle und der Fachberatung Risikomanagement abzustimmen. ' +
            '</p><p>' +
                'In Fällen der möglichen Gefährdung Dritter sieht die Konzeption nach einer Abwägung im Einzelfall eine zusätzliche Information der KURS-Sachbearbeiterinnen ' +
                'oder KURS-Sachbearbeiter vor. Dies <i>kann</i> bspw. unter den Voraussetzungen des § 34 StGB bei einer „gegenwärtigen, nicht anders abwendbaren Gefahr für Leben, ' +
                'Freiheit, Ehre, Eigentum oder ein anderes Rechtsgut“ der Fall sein, wenn das geschützte Interesse die datenschutzrechtliche Beeinträchtigung überwiegt.“ ' +
                '<i>Hier sind alle gesammelten Erkenntnisse in die Entscheidung miteinzubeziehen.</i>' +
            '</p>',
        bw:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten unverzüglich der Führungsaufsichtsstelle zu melden sind, mit ' +
                'dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu überprüfen und entsprechend anzupassen. Außerdem wird angeordnet, dass der ' +
                'Bewährungshelfende im Falle einer gegenwärtigen Gefahr für Leib oder Leben, Gesundheit, persönliche Freiheit oder die sexuelle Selbstbestimmung einer ' +
                'Person sowie – soweit gesetzlich zulässig – in anderen Fällen einer gefährlichen und andere Personen gefährdenden Entwicklung des Risikoprobanden, ' +
                'ungeachtet anderer Unterrichtungspflichten, unverzüglich auch die für den Wohnort des Risikoprobanden zuständige Polizeidienststelle unterrichtet. Den ' +
                'am Wohnort zuständigen Polizeidienststellen obliegt die Festlegung und Koordinierung der gefahrenabwehrrechtlichen Maßnahmen bei den Risikoprobanden ' +
                '(Ziffer 5.8.1).' +
            '</p><p>' +
                'Als gefahrenabwehrrechtliche Maßnahmen kommen nach Ziffer 5.8.2 <i>bspw.</i> die Gefährderansprache, Gefährdetenansprache, die Feststellung von Verstößen gegen ' +
                'Weisungen (bspw. wenn Ihr*e Klient*in sich entgegen seine*ihrer Weisung an Spielplätzen aufhält) oder schließlich auch die Observation in Betracht.' +
            '</p>',
        bayern: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. Es <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, ' +
                'wenn das gezeigte Verhalten (Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +   
                '<b>Wichtig:</b> Gemäß Ziffer 4.3 der Konzeption HEADS ist bei <i>Gefahr im Verzug</i>  unverzüglich die für den Wohnort des*r Proband*in örtlich zuständigen Polizeidienststelle  zu verständigen, ' +
                'die zunächst über die aktuell notwendigen Maßnahmen (Gefahrenabwehr/Strafverfolgung) entscheidet und die Kriminalpolizeidienststelle, das zuständige Polizeipräsidium ' +
                'sowie die Zentralstelle HEADS zu benachrichtigen hat.' + 
            '</p><p>' + 
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe? </b>' +
            '</p><p>' +
                'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 ' +
                'die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist. ' +
            '</p>'

    },

    'Hatten Sie in den letzten Tagen das Gefühl, eine sexuelle Handlung mit einem Kind begehen zu müssen?': {

        description:
            '<p>' +
                'Dieses Item stammt aus dem Acute-2007-Self-Report, einem Selbstbeschreibungsverfahren zur Erfassung von akut-dynamischen Rückfallfaktoren. Akut-dynamische ' +
                'Risikofaktoren sind Faktoren, die sich innerhalb von Stunden oder Tagen verändern können. Die Items werden mit Hilfe einer dreistufigen Antwortkategorie ' +
                'beantwortet (nein, manchmal, oft) und geben das Erleben der Person in den letzten Tagen wieder. Die Subskala der Beschäftigung mit potentiellen Opfern ' +
                'erfasst dabei das Ausmaß und die Intensität, mit dem sich eine Person gedanklich mit potentiellen Opfern und konkreten Plänen und Vorbereitungen zur ' +
                'Kontaktaufnahme beschäftigt. ' +
            '</p><p>' +
                'Bitte beachten Sie, dass Tatpläne und Vorbereitungshandlungen bereits das Stadium zur Versuchsstrafbarkeit überschreiten <i>können</i> und zwar dann, wenn ' +
                'der*die Täter*in nach seiner*ihrer Vorstellung von der Tat zur Verwirklichung des Tatbestandes unmittelbar ansetzt (§ 22 StGB) und die vielfach vom ' +
                'BGH definierte Schwelle zum „jetzt geht´s los“ überschreitet. Solche Vorbereitungshandlungen <i>können</i> insbesondere auch im Zusammenspiel mit anderen ' +
                'Items der Selbstbeschreibungsverfahren bedenklich werden, bspw. wenn Ihr*e Klient*in zusätzlich im Rahmen der Fragebögen nicht der These zustimmt, ' +
                'dass er*sie bereit sei, Kontakte zu Kindern zu vermeiden oder sein sexuelles Interesse an Kindern nicht verändern möchte. ' +
            '</p><p>' +
                'Hat Ihr*e Klient*in gleichzeitig auch von potentiell sexuell deviantem Verhalten berichtet, wie bspw. an einem Spielplatz oder Schule gewesen zu ' +
                'sein oder ein Kind nach Hause eingeladen zu haben, <i>kann</i> hierin nicht nur ein Weisungsverstoß liegen, sondern dies <i>kann</i> einen Hinweis darauf geben, ' +
                'dass der Tatplan oder die Vorbereitungshandlung bereits so weit in das Versuchsstadium gelangt sind, dass eine gegenwärtige Gefahr für Leib, Leben, ' +
                'Gesundheit oder die sexuelle Selbstbestimmung eines Kindes besteht. Um dies zu überprüfen, können Sie sich die letzten Angaben Ihrer Klientin ' +
                'oder Ihres Klienten im Risk-Assessment "Acute-2007-SR" anschauen.' +
            '</p>',
        nds: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern (KURS Niedersachsen)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei verläuft, eine unverzügliche ' +
                'Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). Im Falle von rückfallgefährdenden Situationen, ' +
                'Anzeichen einer ernsthaften Gefährdung Dritter oder Verstößen gegen Weisungen ergeht ebenfalls eine Meldung nachrichtlich an die zuständige Fachberaterin ' +
                'oder den zuständigen Fachberater Risikomanagement. Bei krisenhafter Zuspitzung ist das weitere Vorgehen unverzüglich mit der Führungsaufsichtsstelle und der ' +
                'Fachberatung Risikomanagement abzustimmen. ' +
            '</p><p>' +
                'In Fällen der möglichen Gefährdung Dritter sieht die Konzeption nach einer Abwägung im Einzelfall eine zusätzliche Information der KURS-Sachbearbeiterinnen ' +
                'oder KURS-Sachbearbeiter vor. Dies <i>kann</i> bspw. unter den Voraussetzungen des § 34 StGB bei einer „gegenwärtigen, nicht anders abwendbaren Gefahr für Leben, ' +
                'Freiheit, Ehre, Eigentum oder ein anderes Rechtsgut“ der Fall sein, wenn das geschützte Interesse die datenschutzrechtliche Beeinträchtigung überwiegt.“ ' +
                '<i>Hier sind alle gesammelten Erkenntnisse in die Entscheidung miteinzubeziehen.</i>' +
            '</p>',
        bw:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten unverzüglich der Führungsaufsichtsstelle zu melden sind, mit ' +
                'dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu überprüfen und entsprechend anzupassen. Außerdem wird angeordnet, dass der ' +
                'Bewährungshelfende im Falle einer gegenwärtigen Gefahr für Leib oder Leben, Gesundheit, persönliche Freiheit oder die sexuelle Selbstbestimmung einer ' +
                'Person sowie – soweit gesetzlich zulässig – in anderen Fällen einer gefährlichen und andere Personen gefährdenden Entwicklung des Risikoprobanden, ' +
                'ungeachtet anderer Unterrichtungspflichten, unverzüglich auch die für den Wohnort des Risikoprobanden zuständige Polizeidienststelle unterrichtet. Den ' +
                'am Wohnort zuständigen Polizeidienststellen obliegt die Festlegung und Koordinierung der gefahrenabwehrrechtlichen Maßnahmen bei den Risikoprobanden ' +
                '(Ziffer 5.8.1).' +
            '</p><p>' +
                'Als gefahrenabwehrrechtliche kommen nach Ziffer 5.8.2 <i>bspw.</i> die Gefährderansprache, Gefährdetenansprache, die Feststellung von Verstößen gegen Weisungen ' +
                '(bspw. wenn Ihr*e Klient*in sich entgegen seine*ihrer Weisung an Spielplätzen aufhält) oder schließlich auch die Observation in Betracht.' +
            '</p>',
        bayern: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. Es <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, ' +
                'wenn das gezeigte Verhalten (Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +   
                '<b>Wichtig:</b> Gemäß Ziffer 4.3 der Konzeption HEADS ist bei <i>Gefahr im Verzug</i>  unverzüglich die für den Wohnort des*r Proband*in örtlich zuständigen Polizeidienststelle  zu verständigen, ' +
                'die zunächst über die aktuell notwendigen Maßnahmen (Gefahrenabwehr/Strafverfolgung) entscheidet und die Kriminalpolizeidienststelle, das zuständige Polizeipräsidium ' +
                'sowie die Zentralstelle HEADS zu benachrichtigen hat.' + 
            '</p><p>' + 
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe? </b>' +
            '</p><p>' +
                'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 ' +
                'die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist. ' +
            '</p>'


    },

    'Haben Sie in den letzten Tagen Vorbereitungen getroffen, um mit einem Kind in Kontakt kommen zu können?': {

        description:
            '<p>' +
                'Dieses Item stammt aus dem Acute-2007-Self-Report, einem Selbstbeschreibungsverfahren zur Erfassung von akut-dynamischen Rückfallfaktoren. Akut-dynamische ' +
                'Risikofaktoren sind Faktoren, die sich innerhalb von Stunden oder Tagen verändern können. Die Items werden mit Hilfe einer dreistufigen Antwortkategorie ' +
                'beantwortet (nein, manchmal, oft) und geben das Erleben der Person in den letzten Tagen wieder. Die Subskala der Beschäftigung mit potentiellen Opfern ' +
                'erfasst dabei das Ausmaß und die Intensität, mit dem sich eine Person gedanklich mit potentiellen Opfern und konkreten Plänen und Vorbereitungen zur ' +
                'Kontaktaufnahme beschäftigt. '+
            '</p><p>' +
                'Bitte beachten Sie, dass Tatpläne und Vorbereitungshandlungen bereits das Stadium zur Versuchsstrafbarkeit überschreiten <i>können</i> und zwar dann, wenn ' +
                'der*die Täter*in nach seiner*ihrer Vorstellung von der Tat zur Verwirklichung des Tatbestandes unmittelbar ansetzt (§ 22 StGB) und die vielfach vom ' +
                'BGH definierte Schwelle zum „jetzt geht´s los“ überschreitet. Solche Vorbereitungshandlungen <i>können</i> insbesondere auch im Zusammenspiel mit anderen ' +
                'Items der Selbstbeschreibungsverfahren bedenklich werden, bspw. wenn Ihr*e Klient*in zusätzlich im Rahmen der Fragebögen nicht der These zustimmt, ' +
                'dass er*sie bereit sei, Kontakte zu Kindern zu vermeiden oder sein sexuelles Interesse an Kindern nicht verändern möchte. ' +
            '</p><p>' +
                'Hat Ihr*e Klient*in gleichzeitig auch von potentiell sexuell deviantem Verhalten berichtet, wie bspw. an einem Spielplatz oder Schule gewesen zu sein ' +
                'oder ein Kind nach Hause eingeladen zu haben, <i>kann</i> hierin nicht nur ein Weisungsverstoß liegen, sondern dies <i>kann</i> einen Hinweis darauf geben, dass der ' +
                'Tatplan oder die Vorbereitungshandlung bereits so weit in das Versuchsstadium gelangt sind, dass eine gegenwärtige Gefahr für Leib, Leben, Gesundheit ' +
                'oder die sexuelle Selbstbestimmung eines Kindes besteht. Um dies zu überprüfen, können Sie sich die letzten Angaben Ihrer Klientin oder Ihres Klienten ' +
                'im Risk-Assessment "Acute-2007-SR" anschauen.' +
            '</p>',
        nds:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit rückfallgefährdeten Sexualstraftäterinnen und Sexualstraftätern (KURS Niedersachsen)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei verläuft, eine ' +
                'unverzügliche Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). Im Falle von rückfallgefährdenden ' +
                'Situationen, Anzeichen einer ernsthaften Gefährdung Dritter oder Verstößen gegen Weisungen ergeht ebenfalls eine Meldung nachrichtlich an die zuständige ' +
                'Fachberaterin oder den zuständigen Fachberater Risikomanagement. Bei krisenhafter Zuspitzung ist das weitere Vorgehen unverzüglich mit der ' +
                'Führungsaufsichtsstelle und der Fachberatung Risikomanagement abzustimmen. ' +
            '</p><p>' +
                'In Fällen der möglichen Gefährdung Dritter sieht die Konzeption nach einer Abwägung im Einzelfall eine zusätzliche Information der KURS-Sachbearbeiterinnen ' +
                'oder KURS-Sachbearbeiter vor. Dies <i>kann</i> bspw. unter den Voraussetzungen des § 34 StGB bei einer „gegenwärtigen, nicht anders abwendbaren Gefahr für Leben, ' +
                'Freiheit, Ehre, Eigentum oder ein anderes Rechtsgut“ der Fall sein, wenn das geschützte Interesse die datenschutzrechtliche Beeinträchtigung überwiegt.“ ' +
                '<i>Hier sind alle gesammelten Erkenntnisse in die Entscheidung miteinzubeziehen.</i>' +
            '</p>',
        bw:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)? </b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten unverzüglich der Führungsaufsichtsstelle zu melden ' +
                'sind, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu überprüfen und entsprechend anzupassen. Außerdem wird ' +
                'angeordnet, dass der Bewährungshelfende im Falle einer gegenwärtigen Gefahr für Leib oder Leben, Gesundheit, persönliche Freiheit oder die sexuelle ' +
                'Selbstbestimmung einer Person sowie – soweit gesetzlich zulässig – in anderen Fällen einer gefährlichen und andere Personen gefährdenden Entwicklung ' +
                'des Risikoprobanden, ungeachtet anderer Unterrichtungspflichten, unverzüglich auch die für den Wohnort des Risikoprobanden zuständige ' +
                'Polizeidienststelle unterrichtet. Den am Wohnort zuständigen Polizeidienststellen obliegt die Festlegung und Koordinierung der gefahrenabwehrrechtlichen ' +
                'Maßnahmen bei den Risikoprobanden (Ziffer 5.8.1).' +
            '</p><p>' +
                'Als gefahrenabwehrrechtliche Maßnahmen kommen nach Ziffer 5.8.2 <i>bspw.</i> die Gefährderansprache, Gefährdetenansprache, die Feststellung von Verstößen gegen ' +
                'Weisungen (bspw. wenn Ihr*e Klient*in sich entgegen seine*ihrer Weisung an Spielplätzen aufhält) oder schließlich auch die Observation in Betracht.' +
            '</p>',
        bayern: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. Es <i>kann</i> eine Veränderung der Einstufungskategorie in Betracht kommen, ' +
                'wenn das gezeigte Verhalten (Weisungsverstöße, Erkennung von destabilisierenden Faktoren wie Verlust des Arbeitsplatzes, Beziehung, soziale Kontakte etc.) Grund für eine weitergehende Intervention bietet (Ziffer 3.4 der Konzeption). ' +
            '</p><p>' +   
                '<b>Wichtig:</b> Gemäß Ziffer 4.3 der Konzeption HEADS ist bei <i>Gefahr im Verzug</i>  unverzüglich die für den Wohnort des*r Proband*in örtlich zuständigen Polizeidienststelle  zu verständigen, ' +
                'die zunächst über die aktuell notwendigen Maßnahmen (Gefahrenabwehr/Strafverfolgung) entscheidet und die Kriminalpolizeidienststelle, das zuständige Polizeipräsidium ' +
                'sowie die Zentralstelle HEADS zu benachrichtigen hat.' + 
            '</p><p>' + 
                '<b>Verbringt Ihr*e Klient*in aktuell seine*ihre Bewährungszeit mit Ihrer Hilfe? </b>' +
            '</p><p>' +
                'Hat sich Ihr*e Klient*in ggf. während der Bewährungsunterstellung zu einem Risikoproband mit erhöhtem Rückfallrisiko entwickelt? Bitte beachten Sie, dass die Konzeption HEADS in Bewährungsfällen gem. Ziffer 4.1 ' +
                'die unverzügliche Übermittlung eines Berichts an die Vollstreckungsbehörde erfordert, wenn bei einer zuvor positiven Sozialprognose das gezeigte Verhalten auf ein erhöhtes Rückfallrisiko hinweist. ' +
            '</p>'

    },

    'Haben Sie in den letzten Tagen daran gedacht, sich das Leben zu nehmen?': {

        description:
            '<p>' +
                'Grundsätzlich hat jeder Mensch das Recht auf freie Verfügung über das eigene Leben. Für die praktische Rechtsanwendung ist die Selbsttötung unerheblich, ' +
                'da sie straflos ist. Die strafbaren Tötungsdelikte richten sich gegen einen anderen Menschen. Straflos sind daher auch der Versuch, die Teilnahme und die ' +
                'fahrlässige Mitverantwortung einer Selbsttötung oder einer sonstigen fahrlässigen Veranlassung des eigenverantwortlichen Handelns eines Selbstschädigers, ' +
                'weil den Mitverursacher bei einem eigenverantwortlichen Suizid keine Sorgfaltspflicht trifft. Jeder Mensch hat aber auch zumindest einen Anspruch darauf, ' +
                'vor einem eigenverantwortlichen Suizid Hilfe in Anspruch zu nehmen. ' +
            '</p><p>' +
                'Sie <i>könnten</i> im Einzelfall angehalten sein, ihre Schweigepflicht (§ 203 StGB) zu brechen (§ 34 StGB – Rechtfertigender Notstand). Das Leben des potentiellen ' +
                'Suizidenten kann gegenwärtig gefährdet sein, wenn ein Zustand besteht, in dem aufgrund tatsächlicher Umstände die Wahrscheinlichkeit des Eintritts des ' +
                'schädigenden Ereignisses gegeben ist. Dies ist der Fall, wenn die Möglichkeit nahe liegt oder die begründete Besorgnis besteht, dass sich Ihr*e Klient*in ' +
                'suizidiert und bei natürlicher Weiterentwicklung der Dinge, der Eintritt eines Schadens sicher oder doch höchstwahrscheinlich ist, falls nicht ' +
                'Abwehrmaßnahmen ergriffen werden. Sofortiges Handeln kann auch erforderlich sein, wenn der ungewöhnliche Zustand nach menschlicher Erfahrung und natürlicher ' +
                'Weiterentwicklung der gegebenen Sachlage jederzeit in einen Schaden umschlagen kann. Hat Ihr*e Klient*in ggf. schon einmal während Ihrer Termine über ' +
                'Suizidgedanken gesprochen?' +
            '</p>',
        nds: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Bitte beachten Sie, dass die Konzeption bei Probandinnen und Probanden der Kategorie A und B, deren Unterstellung nicht problemfrei verläuft, ' +
                'eine unverzügliche Meldung per Fax an die Führungsaufsichtsstelle und das aufsichtsführende Gericht vorsieht (Ziffer 8.3). Die Führungsaufsichtsstelle ' +
                'wird sich dann unverzüglich mit der Zentralstelle KURS im LKA Niedersachsen in Verbindung setzen. Im Falle einer krisenhaften Zuspitzung ist unverzüglich ' +
                'das weitere Vorgehen mit der Führungsaufsichtsstelle und der Fachberatung Risikomanagement abzustimmen, insbesondere um das mögliche Hilfepotential ' +
                'auszuschöpfen.' +
            '</p>',
        bw:
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption zum Umgang mit besonders rückfallgefährdeten Sexualstraftätern (VwV KURS)?</b>' +
            '</p><p>' +
                'Beachten Sie, dass die Konzeption unter Ziffer 5.5 vorsieht, dass Verhaltensauffälligkeiten, Verstöße gegen Weisungen und neue Straftaten unverzüglich der ' +
                'Führungsaufsichtsstelle zu melden sind, mit dem Ziel, die führungsaufsichtsrechtlichen Maßnahmen auf ihre Wirksamkeit zu überprüfen und entsprechend anzupassen. ' +
                'Im Falle einer gegenwärtigen Gefahr für Leib, Leben oder Gesundheit einer Person, sowie – soweit gesetzlich zulässig – in anderen Fällen einer gefährlichen und ' +
                'andere Personen gefährdenden Entwicklung des Risikoprobanden verständigt der Bewährungshelfer, ungeachtet anderer Unterrichtungspflichten, unverzüglich auch die ' +
                'für den Wohnort des Risikoprobanden zuständige Polizeidienststelle. ' +
            '</p>',
        bayern: 
            '<p>' +
                '<b>Gehört Ihr*e Klient*in zur Zielgruppe der Konzeption HEADS – Haft-Entlassenen-Auskunfts-Datei-Sexualstraftäter?</b>' +
            '</p><p>' +
                'Bitte beachten Sie die mit der regionalen Kriminaldienststelle getätigten Absprachen der polizeilichen Maßnahmen bzgl. Ihres*r Klient*in. ' +
            '</p><p>' +   
                '<b>Wichtig:</b> Gemäß Ziffer 4.3 der Konzeption HEADS ist bei <i>Gefahr im Verzug</i>  unverzüglich die für den Wohnort des*r Proband*in örtlich zuständigen Polizeidienststelle  zu verständigen, ' +
                'die zunächst über die aktuell notwendigen Maßnahmen (Gefahrenabwehr/Strafverfolgung) entscheidet und die Kriminalpolizeidienststelle, das zuständige Polizeipräsidium ' +
                'sowie die Zentralstelle HEADS zu benachrichtigen hat.' + 
            '</p>'
        
    }

}

// export
export default { help_object }