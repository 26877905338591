<template>

    <v-container fluid v-if="loaded">

                <v-row>

                    <v-col cols="12" sm="12">

                        <v-card
                            flat
                            outlined
                            height="100%"
                        >
                            <v-card-text class="primary--text title">
                                Verdiente Coins
                            </v-card-text>
                            
                            <v-divider />

                            <v-card-text>

                                <v-row>

                                    <v-col cols>
                                        <div class="mb-3 text-center">Gesamteinnahmen:</div>
                                        <div class="text-center">
                                            <span class="headline primary--text">{{ sum_positive_coins }}</span>
                                            <v-icon large class="ml-3">fas fa-coins</v-icon>
                                            <span class="subtitle-2 ml-3">Coins</span>
                                        </div>
                                    </v-col>

                                    <v-col cols>
                                        <div class="mb-3 text-center">Bisher Umgetauscht:</div>
                                        <div class="text-center">
                                            <span class="headline primary--text">{{ sum_negative_coins }}</span>
                                            <v-icon large class="ml-3">fas fa-coins</v-icon>
                                            <span class="subtitle-2 ml-3">Coins</span>
                                        </div>
                                    </v-col>

                                    <v-col cols>
                                        <div class="mb-3 text-center">Aktueller Kontostand:</div>
                                        <div class="text-center">
                                            <span class="headline primary--text">{{ sum_coins }}</span>
                                            <v-icon large class="ml-3">fas fa-coins</v-icon>
                                            <span class="subtitle-2 ml-3">Coins</span>
                                        </div>
                                    </v-col>

                                    <v-col cols>
                                        <div class="text-center mt-3">
                                        <v-btn outlined rounded color="primary" :disabled="sum_coins < 10" @click.stop="dialog = true">Coins in Euro tauschen</v-btn>
                                        <p class="caption mt-3 text-center">
                                            <b>Hinweis:</b> 
                                            Um sich die Aufwandsentschädigung auszahlen zu lassen, müssen Sie zunächst die verdienten Coins in 
                                            Euro umtauschen. Wechselkurs: 100 Coins = 1 Euro
                                        </p>
                                        </div>
                                    </v-col>

                                </v-row>
                                
  
                            </v-card-text>

                            <v-divider />

                            <v-expansion-panels flat class="py-0">

                                <v-expansion-panel flat class="py-0">

                                    <v-expansion-panel-header color="grey lighten-5" class="py-0">

                                        <v-card flat color="grey lighten-5" class="py-0">

                                            <v-card-text class="pl-0 py-0 primary--text">
                                                <b>Details</b>
                                            </v-card-text>

                                        </v-card>

                                        <template v-slot:actions>
                                            <v-icon small color="primary">$expand</v-icon>
                                        </template>
                                    </v-expansion-panel-header>

                                    <v-divider />

                                    <v-expansion-panel-content>

                                        <v-card flat>

                                            <v-card-text>

                                                <v-data-table
                                                    :headers="headers_income"
                                                    :items="items_income"
                                                    :items-per-page="250"
                                                    :sort-by="'update_time'"
                                                    hide-default-footer
                                                >

                                                    <template v-slot:item.update_time="{ item }">
                                                        <span>{{ getCorrectDateTime(item.update_time) }}</span>
                                                    </template>

                                                    <template v-slot:item.coins_payed="{ item }">
                                                        <span class="subtitle-2" v-if="item.module_nr == -999">{{ item.coins_payed }}</span>
                                                        <span class="subtitle-2" v-else>+{{ item.coins_payed }}</span>
                                                    </template>

                                                    <template v-slot:item.module_nr="{ item }">
                                                        <span class="subtitle-2" v-if="item.module_nr == 0">Einführung</span>
                                                        <span class="subtitle-2" v-if="item.module_nr == -999">Coins getauscht</span>
                                                        <span class="subtitle-2" v-if="item.module_nr != 0 && item.module_nr != -999">Modul {{ item.module_nr }} - Sitzung {{ item.session_nr }}</span>
                                                    </template>

                                                    <template v-slot:no-data>

                                                        <v-alert
                                                            outlined
                                                            type="error"
                                                            prominent
                                                            border="left"
                                                            class="mt-3"
                                                        >
                                                            Sie haben noch keine Coins erhalten.
                                                        </v-alert>

                                                    </template>

                                                </v-data-table>

                                            </v-card-text>

                                        </v-card>

                                    </v-expansion-panel-content>

                                </v-expansion-panel>

                            </v-expansion-panels>

                        </v-card>

                    </v-col>

                </v-row>

                <v-row>

                    <v-col cols="12" sm="12">

                        <v-card
                            flat
                            outlined
                            height="100%"
                        >
                            <v-card-text class="primary--text title">
                                Eingetauschte Euros
                            </v-card-text>
                            
                            <v-divider />

                            <v-card-text>

                                <v-row>

                                    <v-col cols>
                                        <div class="mb-3 text-center">Bisher Umgetauscht:</div>
                                        <div class="text-center">
                                            <span class="headline primary--text">{{ sum_positive_euros.toFixed(2) }}</span>
                                            <v-icon large class="ml-3">fas fa-euro-sign</v-icon>
                                            <span class="subtitle-2 ml-3">Euro</span>
                                        </div>
                                    </v-col>

                                    <v-col cols>
                                        <div class="mb-3 text-center">Bisher Ausgezahlt:</div>
                                        <div class="text-center">
                                            <span class="headline primary--text">{{ sum_negative_euros.toFixed(2) }}</span>
                                            <v-icon large class="ml-3">fas fa-euro-sign</v-icon>
                                            <span class="subtitle-2 ml-3">Euro</span>
                                        </div>
                                    </v-col>

                                    <v-col cols>
                                        <div class="mb-3 text-center">Aktueller Kontostand:</div>
                                        <div class="text-center">
                                            <span class="headline primary--text">{{ sum_euros.toFixed(2) }}</span>
                                            <v-icon large class="ml-3">fas fa-euro-sign</v-icon>
                                            <span class="subtitle-2 ml-3">Euro</span>
                                        </div>
                                    </v-col>

                                    <v-col cols>
                                        <div class="text-center mt-3">
                                        <v-btn outlined rounded  @click="get_money()" color="primary" :disabled="sum_euros < 15">Gutschein beantragen</v-btn>
                                        <p class="caption mt-3 text-center">
                                            <b>Hinweis:</b> 
                                            Gutscheine können nur über einen Mindestbetrag von 15 Euro beantragt werden. Eine Auszahlung ist daher nur in 15 Euro - Schritten möglich.
                                        </p>
                                        </div>
                                    </v-col>

                                </v-row>
                                
                            </v-card-text>

                            <v-divider />

                            <v-expansion-panels flat class="py-0">

                                <v-expansion-panel flat class="py-0">

                                    <v-expansion-panel-header color="grey lighten-5" class="py-0">

                                        <v-card flat color="grey lighten-5" class="py-0">

                                            <v-card-text class="pl-0 py-0 primary--text">
                                                <b>Details</b>
                                            </v-card-text>

                                        </v-card>

                                        <template v-slot:actions>
                                            <v-icon small color="primary">$expand</v-icon>
                                        </template>
                                    </v-expansion-panel-header>

                                    <v-divider />

                                    <v-expansion-panel-content>

                                        <v-card flat>

                                            <v-card-text>

                                                <v-data-table
                                                    :headers="headers_euros"
                                                    :items="items_euros"
                                                    :items-per-page="250"
                                                    :sort-by="'update_time'"
                                                    hide-default-footer
                                                >

                                                    <template v-slot:item.update_time="{ item }">
                                                        <span class="subtitle-2">{{ getCorrectDateTime(item.update_time) }}</span>
                                                    </template>

                                                    <template v-slot:item.euros="{ item }">
                                                        <span class="subtitle-2" v-if="item.euros < 0">{{ item.euros.toFixed(2) }}</span>
                                                        <span class="subtitle-2" v-else>+{{ item.euros.toFixed(2) }}</span>
                                                    </template>

                                                    <template v-slot:item.euros_process="{ item }">
                                                        <span class="subtitle-2" v-if="item.euros < 0">Auszahlung</span>
                                                        <span class="subtitle-2" v-if="item.euros > 0">Getauscht</span>
                                                    </template>

                                                    <template v-slot:no-data>

                                                        <v-alert
                                                            outlined
                                                            type="error"
                                                            prominent
                                                            border="left"
                                                            class="mt-3"
                                                        >
                                                            Sie haben noch keine Euros eingetauscht.
                                                        </v-alert>

                                                    </template>

                                                </v-data-table>

                                            </v-card-text>

                                        </v-card>

                                    </v-expansion-panel-content>

                                </v-expansion-panel>

                            </v-expansion-panels>

                        </v-card>

                    </v-col>

                </v-row>

                <v-row>

                    <v-col cols="12" sm="12">

                        <v-card
                            flat
                            outlined
                            height="100%"
                        >
                            <v-card-text class="primary--text title">
                                Beantragte Gutscheine (Verfolgung)
                            </v-card-text>

                            <v-divider />

                            <v-card-text>

                                <v-data-table
                                    :headers="headers_payment"
                                    :items="items_payment"
                                    :items-per-page="100"
                                    :sort-by="'order_received'"
                                    hide-default-footer
                                >

                                    <template v-slot:item.order_received="{ item }">
                                        <span class="subtitle-2">{{ getCorrectDateTime(item.order_received) }}</span>
                                    </template>

                                    <template v-slot:item.spended_euros="{ item }">
                                        <span class="subtitle-2">{{ item.spended_euros }}</span>
                                    </template>

                                    <template v-slot:item.coupon_sended="{ item }">
                                        <span class="subtitle-2" v-if="item.coupon_sended">
                                            <v-icon color="success">fas fa-check</v-icon>
                                            <span class="ml-3">(am {{ getCorrectDateTime(item.coupon_sended_time) }})</span>
                                        </span>
                                        <span class="subtitle-2" v-else>
                                            <v-icon color="error">fas fa-times</v-icon>
                                        </span>
                                    </template>

                                    <template v-slot:item.supervisor_coupon_received="{ item }">
                                        <span class="subtitle-2" v-if="item.supervisor_coupon_received">
                                            <v-icon color="success">fas fa-check</v-icon>
                                            <span class="ml-3">(am {{ getCorrectDateTime(item.supervisor_coupon_received_time) }})</span>
                                        </span>
                                        <span class="subtitle-2" v-else>
                                            <v-icon color="error">fas fa-times</v-icon>
                                        </span>
                                    </template>

                                    <template v-slot:item.client_coupon_received="{ item }">
                                        <span class="subtitle-2" v-if="item.client_coupon_received">
                                            <v-icon color="success">fas fa-check</v-icon>
                                            <span class="ml-3">(am {{ getCorrectDateTime(item.client_coupon_received_time) }})</span>
                                        </span>
                                        <span class="subtitle-2" v-else>
                                            <v-icon color="error">fas fa-times</v-icon>
                                        </span>
                                    </template>

                                    <template v-slot:no-data>

                                        <v-alert
                                            outlined
                                            type="error"
                                            prominent
                                            border="left"
                                            class="mt-3"
                                        >
                                            Sie haben noch keine Auszahlung beantragt.
                                        </v-alert>

                                    </template>

                                </v-data-table>

                            </v-card-text>

                            <v-divider />

                            <v-card flat color="grey lighten-5" class="py-0">

                            <v-card-text color="grey lighten-5" class="">
                                <p class="caption mt-3" color="grey lighten-5">
                                    <b>Hinweis:</b> 
                                    Wenn Sie einen Gutschein beantragt haben, dann wird dieser an <span v-if="$store.getters['store_client/get_client_supervisor'].supervisor_gender == 'm'">Ihren Bewährungshelfer</span><span v-else>Ihre Bewährungshelferin</span> mit der Post 
                                    geschickt. <span v-if="$store.getters['store_client/get_client_supervisor'].supervisor_gender == 'm'">Ihr Bewährungshelfer</span><span v-else>Ihre Bewährungshelferin</span> wird Ihnen den Gutschein dann bei Ihrem nächsten Treffen aushändigen, 
                                    wenn dieser angekommen ist. Sollte ein Gutschein nicht ankommen, können Sie gerne auch jederzeit <span v-if="$store.getters['store_client/get_client_coach'].coach_gender == 1">Ihren Coach</span><span v-else>Ihre Coachin</span> 
                                    fragen, was da los ist. <span v-if="$store.getters['store_client/get_client_coach'].coach_gender == 1">Ihr Coach</span><span v-else>Ihre Coachin</span> wird die Anfrage dann an die Studienleitung umgehend weiterleiten.
                                </p>
                            </v-card-text>

                            </v-card>

                        </v-card>

                    </v-col>

                </v-row>


        <!-- DIALOG TO CHANE COINS -->
        <v-dialog
            v-model="dialog"
            persistent
            max-width="800"
        >
            <v-card>

                <v-toolbar flat color="transparent">
                    <v-icon large color="accent">fas fa-exchange-alt</v-icon>
                    <div class="headline ml-4 primary--text">Coins in Euro umtauschen</div>
                </v-toolbar>

                <v-divider></v-divider>

                <v-card-text class="mt-5">

                    <v-row align="center" justify="space-between">

                        <div text-left>
                            <span class="title">{{ choosenCoins }}</span>
                            <span class="subheading font-weight-light ml-2">COINS</span>
                        </div>

                        <div text-right>
                            <span class="title">{{ calculatedEuros }}</span>
                            <span class="subheading font-weight-light ml-2">EURO</span>
                        </div>

                    </v-row>

                    <v-slider
                        v-model="choosenCoins"
                        color="primary"
                        track-color="grey"
                        always-dirty
                        step="10"
                        min="0"
                        :max="sum_coins"
                    >
                        <template v-slot:prepend>
                            <v-icon
                                color="primary"
                                @click="decrementChangeCoinsForm"
                                class="mr-2 mt-1"
                                small
                            >
                                fas fa-minus
                            </v-icon>
                        </template>

                        <template v-slot:append>
                            <v-icon
                                color="primary"
                                @click="incrementChangeCoinsForm"
                                class="ml-2 mt-1"
                                small
                            >
                                fas fa-plus
                            </v-icon>
                        </template>
                    </v-slider>

                    <v-row justify="center" align="center">
                        <small>(100 Coins = 1 Euro)</small>
                    </v-row>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-text class="mt-4">

                    <v-row>

                        <v-col cols>
                            <v-btn
                                rounded 
                                outlined
                                @click="dialog = !dialog"
                            >
                                Abbrechen
                            </v-btn>
                        </v-col>

                        <v-spacer></v-spacer>

                        <v-col cols>
                            <v-btn
                                :disabled="choosenCoins == 0"
                                outlined
                                rounded
                                @click="resetChangeCoinsForm"
                            >
                                Zurücksetzen
                            </v-btn>
                        </v-col>

                        <v-spacer></v-spacer>

                        <v-col cols>
                            <v-btn
                                :disabled="choosenCoins == 0"
                                outlined
                                rounded
                                color="primary"
                                @click="changeCoinsToEuro"
                                :loading="loading_exchange"
                            >
                                Tauschen
                            </v-btn>
                        </v-col>

                    </v-row>
                
                </v-card-text>
                
            </v-card>

        </v-dialog>

        <!-- DIALOG GET MONEY -->
        <v-dialog
            v-model="dialog_get_money"
            persistent
            max-width="800"
        >
            <v-card>

                <v-toolbar flat color="transparent">
                    <v-icon large color="accent">fas fa-euro-sign</v-icon>
                    <div class="headline ml-4 primary--text">Aufwandsentschädigung auszahlen</div>
                </v-toolbar>

                <v-divider></v-divider>

                <v-card-text class="mt-5">
                    <div>
                        <p class="subtitle-2 mb-3">Sie können sich aktuell folgenden Betrag auszahlen lassen:</p>
                        <p>
                            <span class="headline primary--text">{{ euros_to_get.toFixed(2) }}</span>
                            <v-icon large class="ml-3">fas fa-euro-sign</v-icon>
                            <span class="subtitle-2 ml-3">Euro</span>
                        </p>
                        <p class="caption mt-3">
                            <b>Hinweis: </b>Wenn Sie auf "Auszahlung in Auftrag geben" klicken, dann wird automatisch die Studienleitung darüber informiert,
                            dass Sie sich den eingegebenen Betrag in Form eines Gutscheines auszahlen lassen wollen. Die Studienleitung verschickt umgehend den
                            Gutschein an <span v-if="$store.getters['store_client/get_client_supervisor'].supervisor_gender == 'm'">Ihren Bewährungshelfer</span><span v-else>Ihre Bewährungshelferin</span>. Diese bestätigt den Eingang und übergibt Ihnen bei dem nächsten persönlichen Termin den Gutschein.
                        </p>
                    </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="mt-4">
                    

                    <v-btn
                        rounded 
                        outlined
                        @click="dialog_get_money = !dialog_get_money"
                    >
                        Abbrechen
                    </v-btn>

                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        rounded 
                        outlined
                        @click="accept_clicked()"
                        :loading="loading_get_money"
                    >
                        Auszahlung
                    </v-btn>
                </v-card-actions>

            </v-card>

        </v-dialog>

    </v-container>
    
</template>

<script>
import ClientApi from '@/_api/client_api'
import DateTimeHelpers from '@/_helpers/date_time_helpers'

export default {
    name: 'client-bank',

    data: () => ({
        
        choosenCoins: 0,

        dialog: false,

        dialog_get_money: false,
        euros_to_get: 0,

        loading_exchange: false,
        loading_get_money: false,

        headers_income: [
            {
                text: 'Datum',
                align: 'center',
                sortable: false,
                value: 'update_time',
                class: "body-1 primary--text"
            },
            {
                text: 'Betrag (Coins)',
                align: 'center',
                sortable: false,
                value: 'coins_payed',
                class: "body-1 primary--text"
            },
            {
                text: 'Erhalten für',
                align: 'center',
                sortable: false,
                value: 'module_nr',
                class: "body-1 primary--text"
            },
        ],

        items_income: [],

        headers_payment: [
            {
                text: 'Beantragt am',
                align: 'center',
                sortable: false,
                value: 'order_received',
                class: "body-1 primary--text"
            },
            {
                text: 'Betrag (Euro)',
                align: 'center',
                sortable: false,
                value: 'spended_euros',
                class: "body-1 primary--text"
            },
            {
                text: 'Gutschein verschickt',
                align: 'center',
                sortable: false,
                value: 'coupon_sended',
                class: "body-1 primary--text"
            },
            {
                text: 'Gutschein bei Bewährungshelfer*in',
                align: 'center',
                sortable: false,
                value: 'supervisor_coupon_received',
                class: "body-1 primary--text"
            },
            {
                text: 'Gutschein erhalten',
                align: 'center',
                sortable: false,
                value: 'client_coupon_received',
                class: "body-1 primary--text"
            },
        ],

        headers_euros: [
            {
                text: 'Datum',
                align: 'center',
                sortable: false,
                value: 'update_time',
                class: "body-1 primary--text"
            },
            {
                text: 'Betrag (Euro)',
                align: 'center',
                sortable: false,
                value: 'euros',
                class: "body-1 primary--text"
            },
            {
                text: 'Vorgang',
                align: 'center',
                sortable: false,
                value: 'euros_process',
                class: "body-1 primary--text" 
            }
        ],

        items_payment: [],

        items_euros: [],

        loaded: false,

    }),

    created() {

        this.loaded = false

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        ClientApi.GetClientPaymentDetailed().then((resp) => {

            this.items_income = resp.data.income
            this.items_payment = resp.data.payment
            this.items_euros = resp.data.money

            this.loaded = true

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

        })

    },

    computed: {

        sum_coins() {

            var sum = this.items_income.reduce(function(sum, current) {
                return sum + current.coins_payed;
            }, 0);

            return sum
        },

        sum_positive_coins() {

            var sum = this.items_income.reduce(function(sum, current) {
                if (current.coins_payed > 0) {
                    return sum + current.coins_payed;
                } else {
                    return sum
                }
                
            }, 0);

            return sum
        },

        sum_negative_coins() {

            var sum = this.items_income.reduce(function(sum, current) {
                if (current.coins_payed < 0) {
                    return sum + current.coins_payed;
                } else {
                    return sum
                }
                
            }, 0);

            return sum
        },

        sum_euros() {

            var sum = this.items_euros.reduce(function(sum, current) {
                return sum + current.euros;
            }, 0);

            return sum
        },

        sum_positive_euros() {

            var sum = this.items_euros.reduce(function(sum, current) {
                if (current.euros > 0) {
                    return sum + current.euros;
                } else {
                    return sum
                }
                
            }, 0);

            return sum
        },

        sum_negative_euros() {

            var sum = this.items_euros.reduce(function(sum, current) {
                if (current.euros < 0) {
                    return sum + current.euros;
                } else {
                    return sum
                }
                
            }, 0);

            return sum
        },

        calculatedEuros() {
            return (this.choosenCoins*0.01).toFixed(2)
        }

    },

    methods: {

        getCorrectDateTime(item) {
            return DateTimeHelpers.convertDatabaseDateTimeString('only_date', item)
        },

        changeCoinsToEuro() {

            this.loading_exchange = true

            var euros_to_add = this.choosenCoins/100

            ClientApi.ExchangeCoins(-Math.abs(this.choosenCoins.toFixed(2))).then(() => {

                ClientApi.AddEuros(euros_to_add.toFixed(2)).then(() => {

                    ClientApi.GetClientPaymentDetailed().then((resp) => {

                        this.items_income = resp.data.income
                        this.items_payment = resp.data.payment
                        this.items_euros = resp.data.money

                        this.loaded = true

                        this.loading_exchange = false

                        // reset from values
                        this.choosenCoins = 0

                        // close dialog
                        this.dialog = false

                        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                    })

                })

            })

        },

        resetChangeCoinsForm () {
            this.choosenCoins = 0
        },

        decrementChangeCoinsForm () {
            this.choosenCoins = this.choosenCoins - 10            
        },

        incrementChangeCoinsForm () {
            this.choosenCoins = this.choosenCoins + 10 
        },

        get_money() {

            // 15/30/45/60/75/90/105/120 

            if (this.sum_euros < 15) {
                this.euros_to_get = 0
            } else if (this.sum_euros < 30) {
                this.euros_to_get = 15
            } else if (this.sum_euros < 45) {
                this.euros_to_get = 30
            } else if (this.sum_euros < 60) {
                this.euros_to_get = 45
            } else if (this.sum_euros < 75) {
                this.euros_to_get = 60
            } else if (this.sum_euros < 90) {
                this.euros_to_get = 75
            } else if (this.sum_euros < 105) {
                this.euros_to_get = 90
            } else if (this.sum_euros < 120) {
                this.euros_to_get = 105
            } else {
                this.euros_to_get = 120
            }

            this.dialog_get_money = true

        },

        accept_clicked() {

            this.loading_get_money = true

            var money = (this.euros_to_get*-100)/100

            ClientApi.AddEuros(money.toFixed(2)).then(() => {

                ClientApi.GetClientPaymentDetailed().then((resp) => {

                    this.items_income = resp.data.income
                    this.items_payment = resp.data.payment
                    this.items_euros = resp.data.money

                    // close dialog
                    this.dialog_get_money = false

                    this.loading_get_money = false

                    this.euros_to_get = 0

                    this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                })

            })

        }

    }
}
</script>

<style scoped>

</style>