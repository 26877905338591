<template>

    <v-row>

      <div class="timeline-item">

        <!-- icon and headline -->
        <v-row class="pa-0 ma-0">

            <v-col cols="12" sm="12" class="pa-0">

                <v-row>

                    <v-avatar :v-show="$vuetify.breakpoint.mdAndDown" color="primary" size="50" class="elevation-5" style="top: 70px;">
                        <v-icon medium color="white">fas fa-info</v-icon>
                    </v-avatar>

                    <div :class="!$vuetify.breakpoint.mdAndDown ? 'timeline-title elevation-5' : 'timeline-title-mobile elevation-5'">

                        <v-avatar v-if="thisFinished" color="success" size="40" class="elevation-5" id="cardbadge">
                            <v-icon small color="white">fas fa-check</v-icon>
                        </v-avatar>

                        <v-avatar v-else color="error" size="40" class="elevation-5" id="cardbadge">
                            <v-icon small color="white">fas fa-pencil-alt</v-icon>
                        </v-avatar>

                        <v-card flat class="pa-3">

                          <v-card flat class="ml-3 mr-3">

                              <div class="title primary--text">Information</div>
                              <div class="headline font-weight-bold">{{ data.title }}</div>
                              <div class="subtitle-1 primary--text">{{ data.subtitle }}</div>
                              
                          </v-card>

                        </v-card>

                    </div>

                </v-row>

            </v-col>

        </v-row>

        <!-- content -->
        <v-row>
            <div class="timeline-content float-right">

                <basic-info v-show="thisShow" :data="data.columns" :id='id' :reward="data.reward"/>

            </div>
        </v-row>

      </div>
       
    </v-row>
</template>

<script>
import basicInfo from '@/components/_client/_intervention/_basicBlocks/basicInfo.vue'

export default {
    name: 'necessaryInfo',
    props: ['data', 'id'],
    data () {
        return {

        }
    },

    computed: {
        thisFinished () {
            var tmp = this.$store.getters['store_lection/get_saved_lection_data']
            return tmp.data[this.id].finished
        },

        thisShow () {
            var tmp = this.$store.getters['store_lection/get_saved_lection_data']
            return tmp.data[this.id].show
        },

        // add colors to css
        cssProps () {
            return {
                '--primary-color': this.$vuetify.theme.primary
            }
        },

    },

    components: {
        basicInfo
    }
}
</script>

<style scoped>
.timeline-title {
  width: calc(100% - 68px);
  margin-left: 68px;
  position: relative;

  border-style: solid;
  border-width: 1px;
  border-radius: 5px !important;
  border-color: #004b8c !important;
  padding-left: 5px;
}

.timeline-title-mobile {
  width: 100%;
  position: relative;

  border-style: solid;
  border-width: 1px;
  border-radius: 5px !important;
  border-color: #004b8c !important;
  padding-left: 5p
}

#cardbadge { 
  left: -20px;
  top: -20px; 
  position: absolute;
  z-index: 2;
}

#timeline .timeline-item {
  margin-bottom: 10px;
  position: relative;
  width: 100%;
}

#timeline .timeline-item .timeline-content {
  width: calc(100% - 68px);
  margin-left: 68px;
}

#timeline-mobile .timeline-item {
  margin-bottom: 10px;
  position: relative;
  width: 100%;
}

#timeline-mobile .timeline-item .timeline-content {
  width: 100%;
}
</style>

