<template>

    <div>

        <v-row v-if="loaded">

            <v-card
                color="grey lighten-5"
                flat
                height="auto"
                min-height="250px"
                tile
                width="100%"
                id="fooAnchor"
            >
                <v-card-text
                    color="grey lighten-5"
                >

                    <v-chip color="accent" class="black--text font-weight-bold">{{ getChatPartnerName(current_chat) }}</v-chip>
                    
                    <span class="float-right">

                        <v-menu
                            v-model="menu_chat_options"
                            offset-y 
                            left
                            origin="center center" 
                            :nudge-bottom="9"  
                            transition="slide-y-transition"
                            attach="#fooAnchor"
                            z-index="1"
                            min-width="250"
                        >

                            <template v-slot:activator="{ on }">
                                <v-btn 
                                    icon
                                    v-on="on"
                                    :color="menu_chat_options ? 'primary' : ''"
                                >
                                    <v-icon>fas fa-ellipsis-v</v-icon>
                                </v-btn>

                            </template>

                            <v-list>
                                <v-list-item
                                    @click="toArchive(current_chat)"
                                    v-if="!is_archived(current_chat)"
                                >

                                    <v-list-item-icon>
                                        <v-icon>fas fa-archive</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-title>
                                        Chat archivieren
                                    </v-list-item-title>

                                </v-list-item>

                                <v-list-item
                                    @click="undoThreadDeletion(current_chat)"
                                    v-if="is_archived(current_chat)"
                                >

                                    <v-list-item-icon>
                                        <v-icon>fas fa-inbox</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-title>
                                        Archivierung aufheben
                                    </v-list-item-title>

                                </v-list-item>
                            </v-list>

                        </v-menu>

                    </span>

                    <div class="title black--text mt-3">
                        <b>Betreff: </b>{{ current_chat.subject }}
                    </div>

                </v-card-text>

                <v-divider />
                
                <!-- CONTENT (of CHAT) -->
                <v-card-text>

                    <v-row>

                        <v-container fluid style="width: 100% !important;">

                            <v-row
                                v-for="(msg, index) in current_chat.all_messages"
                                :key="index"
                            >

                                <v-col
                                    :cols="$vuetify.breakpoint.smAndUp ? '10' : '12'"
                                    :sm="$vuetify.breakpoint.smAndUp ? '10' : '12'"
                                    :offset-sm="msg.sender.username !== $store.getters['store_auth/get_user_username'] ? '0': '2'" 
                                >

                                    <v-card outlined :id="'fooAnchor' + index + 5">

                                        <v-card-title>

                                            <v-chip 
                                                v-if="msg.sender.username !== $store.getters['store_auth/get_user_username']"
                                                color="accent"
                                                class="black--text"
                                            >
                                                {{ convertedSender(msg.sender) }}
                                            </v-chip>

                                            <v-chip 
                                                v-if="msg.sender.username == $store.getters['store_auth/get_user_username']"
                                                color="primary"
                                                class="white--text"
                                            >
                                                Ich
                                            </v-chip>

                                        </v-card-title>

                                        <v-divider />

                                        <v-card-text class='black--text'>

                                            <v-container fluid class="pa-0">

                                                <v-row
                                                    align="center"
                                                    justify="start"
                                                    class="pa-0"
                                                >

                                                    <v-col cols="12" sm="12" class="note py-0">
                                                        <div class="body-1">{{ msg.content }}</div>
                                                    </v-col>

                                                </v-row>

                                            </v-container>

                                        </v-card-text>

                                        <v-card-subtitle>
                                            <small class='overline font-weight-thin'><b>{{ getCorrectDateTime(msg.sent_at) }}</b></small>
                                        </v-card-subtitle>

                                    </v-card>

                                </v-col>

                            </v-row>

                        </v-container>

                    </v-row>

                    <v-divider class="my-3" v-if="!is_archived(current_chat)"></v-divider>

                    <v-form ref="new_message_form" v-model="new_message_form_valid" lazy-validation>
                        <v-textarea
                            name="input-7-1"
                            label="Neue Nachricht:"
                            v-model="newMessageValue"
                            :rules="new_message_form_content_rules"
                            rows="3"
                            auto-grow
                            required
                            outlined
                            color="primary"
                            v-if="!is_archived(current_chat)"
                        >
                        
                        </v-textarea>
                    </v-form>

                    <v-col cols="12" sm="12" class="pa-0">

                        <div class="caption"><b>Bitte beachten Sie:</b> Zum Schutz der Daten anderer Personen, vermeiden Sie es unbedingt, persönliche Daten von jemand anderem in Ihren Nachrichten zu nennen, z.B. vollständige Namen oder Adressen von Menschen, die Sie kennen.</div>

                    </v-col>

                    <v-toolbar
                        flat
                        short
                        color="grey lighten-5"
                        id="fooAnchor_attachment"
                        v-if="!is_archived(current_chat)"
                    >

                        <v-file-input 
                            v-if="usergroup == 'Coach'"
                            hide-details
                            show-size 
                            accept=".pdf"
                            label="Wählen Sie einen Anhang aus (.pdf)"
                            v-model="file"
                            @change="onAttachmentPicked(1)"
                            :loading="loading_attachment"
                            @click="loading_attachment = true"
                        ></v-file-input>

                        <v-spacer />

                        <v-btn 
                            @click="sendMessage"
                            :loading="sending"
                            color='primary'
                            outlined
                            class="mt-3"
                        >
                            <v-icon left color="primary">far fa-paper-plane</v-icon><span class="ml-3">Versenden</span>
                        </v-btn>

                    </v-toolbar>

                </v-card-text>

            </v-card>

        </v-row>

    </div>

</template>

<script>
import ClientApi from '@/_api/client_api'
import DateTimeHelpers from '@/_helpers/date_time_helpers'

export default {
    name: 'client-messages-detailed',

    data: () => ({

        menu_new_message: false,

        menu_chat_options: false,

        menu_attachment: false,

        current_chat: {},

        loaded: false,


        new_thread_form_valid: true,
        new_thread_form_subject_rules: [
                v => !!v || 'Sie müssen einen Betreff angeben!',
                v => (v && v.length <= 120) || 'Der Betreff darf nicht länger als 120 Zeichen sein!'
            ],
        new_thread_form_content_rules: [
                v => !!v || 'Der Inhalt der Nachricht darf nicht leer sein!'],


        new_message_form_valid: true,
        new_message_form_content_rules: [
                v => !!v || 'Der Inhalt der Nachricht darf nicht leer sein!'],

        newMessageValue: null,

        // send message
        to_user: [],
        receiver: [],
        receiver_names: [],
        subject: null,
        content: null,

        file: null,
        attachmentName1: '',
        attachmentUrl1: '',
        attachmentFile1: '',
        
        attachmentName2: '',
        attachmentUrl2: '',
        attachmentFile2: '',
        
        attachmentName3: '',
        attachmentUrl3: '',
        attachmentFile3: '',

        sending: false,

        loading_attachment: false,

    }),

    created() {

        this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

        ClientApi.GetThreadById(this.$route.params.thread_id).then((resp) => {

            this.current_chat = resp.data

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

            this.loaded = true

        })

    },

    watch: {

        '$route' () {

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

            ClientApi.GetClientInfo().then(() => {

                ClientApi.GetThreadById(this.$route.params.thread_id).then((resp) => {

                    this.current_chat = resp.data

                    this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                    this.loaded = true

                })

            })

        }


    },

    computed: {

        usergroup() {
            return this.$store.getters['store_auth/get_user_group']
        },

    },

    methods: {

        getAttachmentName(att) {

            var tmp = att.split('/')

            return tmp[tmp.length - 1]

        },

        getChatPartnerName(item) {

            var partner = ''
            
            item.users.forEach(element => {

                if (element.username != this.$store.getters['store_auth/get_user_username']) {

                    partner = element.username

                    if (this.$store.getters['store_client/get_client_coach'].id == element.id) {

                        if (this.$store.getters['store_client/get_client_coach'].coach_gender == 1) {
                            partner = 'Coach'
                        } else {
                            partner = 'Coachin'
                        }

                    } else if (this.$store.getters['store_client/get_client_supervisor'].id == element.id) {
                       partner = this.$store.getters['store_client/get_client_supervisor'].firstname + ' ' + this.$store.getters['store_client/get_client_supervisor'].last_name
                    }

                } 
                
            });

            return partner

        },

        convertedSender(sender) {

            if (sender.username == this.$store.getters['store_auth/get_user_username']) {

                return 'Ich'

            } else {

                if (this.$store.getters['store_client/get_client_coach'].id == sender.id) {

                    if (this.$store.getters['store_client/get_client_coach'].coach_gender == 1) {
                        return 'Coach'
                    } else {
                        return 'Coachin'
                    }

                } else if (this.$store.getters['store_client/get_client_supervisor'].id == sender.id) {
                    return this.$store.getters['store_client/get_client_supervisor'].firstname + ' ' + this.$store.getters['store_client/get_client_supervisor'].last_name
                } else {
                    return sender.username
                }

            }

        },

        checkIfUnread(item) {

            var val = false

            item.user_threads.forEach(element => {

                if (element.user.username == this.$store.getters['store_auth/get_user_username']) {

                    if (element.unread) {

                        val = true

                    }
                }
                
            });

            return val

        },

        getNumberOfAttachments(msg) {

            var nr_msg = 0

            if (msg.attachment1) {

                nr_msg = nr_msg + 1

            } 

            if (msg.attachment2) {

                nr_msg = nr_msg + 1

            } 

            if (msg.attachment3) {

                nr_msg = nr_msg + 1

            } 

            return nr_msg

        },

        is_archived(item) {

            var tmp_archived = false

            for (var i = 0; i < item.user_threads.length; i++) {

                if (item.user_threads[i].deleted == true && item.user_threads[i].user.username == this.$store.getters['store_auth/get_user_username']) {

                    tmp_archived = true

                }
            }

            return tmp_archived

        },

        get_converted_time(item) {

            return DateTimeHelpers.convertDatabaseDateTimeString('full', item)
        },

        getCorrectDateTime(item) {
            return DateTimeHelpers.convertDatabaseDateTimeString('full', item)
        },
		
		onAttachmentPicked (i) {  

            this.loading_attachment = false

            if (i == 1) {
                this.attachmentFile1 = this.file;
                this.attachmentName1 = this.file.name
            } else if (i == 2) {
                this.attachmentFile2 = this.$refs.attachment2.files[0];
                this.attachmentName2 = this.$refs.attachment2.files[0].name
            } else if (i == 3) {
                this.attachmentFile3 = this.$refs.attachment3.files[0];
                this.attachmentName3 = this.$refs.attachment3.files[0].name
            }
		},

        sendMessage() {

            if (this.$refs.new_message_form.validate()) {

                this.sending = true

                var data = {
                    'newMessageValue': this.newMessageValue,
                    'current_thread_id': this.current_chat.id,
                    'attachmentFile1': this.attachmentFile1,
                    'attachmentFile2': this.attachmentFile2,
                    'attachmentFile3': this.attachmentFile3,
                }

                ClientApi.SendMessage(data).then(() => {

                    ClientApi.GetClientInfo().then(() => {

                        ClientApi.GetThreadById(this.$route.params.thread_id).then((resp) => {

                            this.current_chat = resp.data

                            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                            this.loaded = true

                            this.$refs.new_message_form.reset()

                            this.attachmentName1 = ''
                            this.attachmentUrl1 = ''
                            this.attachmentFile1 = ''
                            
                            this.attachmentName2 = ''
                            this.attachmentUrl2 = ''
                            this.attachmentFile2 = ''
                            
                            this.attachmentName3 = ''
                            this.attachmentUrl3 = ''
                            this.attachmentFile3 = ''

                            this.file = null

                            this.sending = false

                        })

                    })

                })

            }

        }, 

        toArchive(current_chat) {

            /**
             * Summary. Updates the specified MyThread with regard to the field 'deleted'. 
             * */
            
            var data_toArchive = {
                'current_thread_id': current_chat.id,
            }

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

            ClientApi.ThreadToArchive(data_toArchive).then(() => {

                ClientApi.GetClientInfo().then(() => {

                    ClientApi.GetThreadById(this.$route.params.thread_id).then((resp) => {

                            this.current_chat = resp.data

                            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                            this.loaded = true

                    })

                })

            })
             
        },

        undoThreadDeletion(current_chat) {
            /**
             * Summary. Updates the specified MyThread with regard to the field 'deleted'. 
             * */

            var data_undoToArchive = {
                'current_thread_id': current_chat.id,
            }

            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)


            ClientApi.UndoThreadToArchive(data_undoToArchive).then(() => {

                ClientApi.GetClientInfo().then(() => {

                    ClientApi.GetThreadById(this.$route.params.thread_id).then((resp) => {

                            this.current_chat = resp.data

                            this.$store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

                            this.loaded = true

                    })

                })

            })

        },

    },

}
</script>

<style scoped>
.note {
  white-space: pre-line; 
  word-wrap: break-word;
  font-family: inherit;
  text-align: left !important;
}

</style>